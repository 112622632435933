import React, { useMemo } from 'react'
import PageLayout from '@ui/PageLayout'
import { projectPath } from '@ui/ProjectsContext'
import { SettingsBreadCrumb } from '@ui/SettingsBreadCrumb'
import { Stack } from '@chakra-ui/react'
import { Check } from './show'
import { OnboardingTask } from './components/SetupBadge'
import PageTitle from '@ui/PageTitle'
import SettingsHeader from '@ui/SettingsHeader'
import PageDescription from '@ui/PageDescription'
import { useOnboardingGoals } from '../../data/use-onboarding-goals'

interface Props {
  id: string
  steps: OnboardingTask[]
}

export default function Show(props: Props) {
  const { data: goals } = useOnboardingGoals()

  const title = useMemo(() => {
    const goal = goals.find((goal) => goal.id === props.id)

    return goal?.title
  }, [goals, props.id])

  return (
    <PageLayout size="md">
      <SettingsBreadCrumb
        paths={[
          { path: projectPath('/setup'), title: 'Workspace Setup' },
          { path: window.location.toString(), title: title || '' }
        ]}
      />

      <SettingsHeader divider={false}>
        <PageTitle size="md">{title}</PageTitle>
        <PageDescription>Complete the steps to finish the onboarding goal.</PageDescription>
      </SettingsHeader>

      <Stack spacing={10}>
        <Stack>
          {props.steps.map((step) => (
            <Check check={step} key={step.key} />
          ))}
        </Stack>
      </Stack>
    </PageLayout>
  )
}
