import React from 'react'
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import PageDescription from '../../ui/PageDescription'
import { projectPath } from '../../ui/ProjectsContext'
import { CreditReport } from './components/CreditReport'

export default function CreditReportPage() {
  return (
    <PageLayout>
      <Box mb={8}>
        <Breadcrumb fontSize="sm" mb={2}>
          <BreadcrumbItem>
            <BreadcrumbLink href={projectPath('settings/billing')}>Billing</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Credit Report</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle>Credit Usage Report</PageTitle>
        <PageDescription>Track and analyze your credit usage across different operations</PageDescription>
      </Box>

      <CreditReport />
    </PageLayout>
  )
}
