import { FormControl, FormHelperText } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import React, { useMemo } from 'react'
import { useIntentSignals } from '../../../data/use-intent-signals'
import { SalesforceFieldMapping } from '../../follow_rules/components/salesforce/salesforce-field-mapper'
import { SalesforceSetup } from '../../follow_rules/components/salesforce/salesforce-setup'
import { FollowRule } from '../../../../types/FollowRule'

export interface SalesforceRulesSetupProps {
  ruleId?: string
  deliveryRules?: FollowRule['delivery_rules']
  kqlDefinitionIds: string[]
  setSaveDisabled?: (value) => void
}

export const SalesforceRulesSetup = (props: SalesforceRulesSetupProps) => {
  const signals = useIntentSignals()

  const kqlDefinitions = useMemo(() => {
    const allDefs = signals.data?.definitions ?? []
    return allDefs.filter((def) => def.id && props.kqlDefinitionIds.includes(def.id))
  }, [signals.data?.definitions, props.kqlDefinitionIds])

  const signalMappings: SalesforceFieldMapping[] = [
    {
      id: nanoid(),
      koala: 'signal.name',
      mode: 'mapped'
    },
    {
      id: nanoid(),
      koala: 'signal.timestamp',
      mode: 'mapped'
    },
    {
      id: nanoid(),
      koala: 'signal.signal_type',
      mode: 'mapped'
    },
    {
      id: nanoid(),
      koala: 'signal.intent_summary',
      mode: 'mapped'
    }
  ]

  const defaultContactMappings: SalesforceFieldMapping[] = [
    {
      id: nanoid(),
      salesforce: 'Email',
      koala: 'visitor.email',
      mode: 'mapped'
    },
    ...signalMappings
  ]

  const defaultCompanyMappings: SalesforceFieldMapping[] = [
    {
      id: nanoid(),
      salesforce: 'Website',
      koala: 'account.company.domain',
      mode: 'mapped'
    },
    ...signalMappings
  ]

  return (
    <FormControl>
      <FormHelperText mb="0">Define how you want to send data into Salesforce</FormHelperText>
      <SalesforceSetup
        skipBoxes
        colorScheme={'blue'}
        targetType="Profile"
        delivery_rules={props.deliveryRules}
        skipBatchFrequency
        suggestedMappings={{
          company: defaultCompanyMappings,
          contact: defaultContactMappings
        }}
        kqlDefinitions={kqlDefinitions}
        actions={{
          import_account: true,
          import_contact: true,
          import_lead: true,
          add_to_campaign: true
        }}
        setSaveDisabled={props.setSaveDisabled}
      />
    </FormControl>
  )
}
