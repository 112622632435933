import React from 'react'
import { Box } from '@chakra-ui/react'
import CompanyAvatar from './CompanyAvatar'
import Avatar from './Avatar'

interface DoubleAvatarProps {
  domain?: string
  companyName?: string | null
  name?: string | null
  email?: string | null
  src?: string | null
}

export default function DoubleAvatar({ domain, name, companyName, email, src }: DoubleAvatarProps) {
  return (
    <Box flex="none" position="relative" p={1.5} ml={-1}>
      {domain ? (
        <>
          <CompanyAvatar domain={domain} name={companyName} size="sm" />
          <Avatar
            src={src}
            name={name || email}
            size="20px"
            position="absolute"
            right={-0.25}
            bottom={-0.25}
            border="1.5px solid white"
          />
        </>
      ) : (
        <Avatar src={src} name={name || email} size="sm" />
      )}
    </Box>
  )
}
