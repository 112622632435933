import { Box, Button, Flex, HStack, Link, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import { LightBgCard } from '../../../ui/Card'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { MiddotDivider } from '../../../ui/Middot'
import { TimeAgo } from '../../../ui/TimeAgo'
import { fields } from '../../accounts/components/SuggestionsModal'
import { Suggestion } from '../types'
import { useAcceptChange } from './useAcceptChange'
import { useRejectChange } from './useRejectChange'

export function SuggestedChange({ compact, ...suggestion }: Suggestion & { compact?: boolean }) {
  const [acceptedAt, setAcceptedAt] = React.useState(suggestion.accepted_at)
  const [rejectedAt, setRejectedAt] = React.useState(suggestion.rejected_at)
  const { isPending: rejecting, mutateAsync: rejectChange } = useRejectChange()
  const { isPending: accepting, mutateAsync: acceptChange } = useAcceptChange()

  const reject = React.useCallback(() => {
    rejectChange({ id: suggestion.id }).then(() => {
      setRejectedAt(new Date().toISOString())
    })
  }, [suggestion.id, rejectChange])

  const accept = React.useCallback(() => {
    acceptChange({ id: suggestion.id }).then(() => {
      setAcceptedAt(new Date().toISOString())
    })
  }, [suggestion.id, acceptChange])

  const from = suggestion.from_content || {}
  const to = suggestion.to_content || {}
  const flagged = suggestion.flagged_fields || Object.keys(from) || []

  const hasSuggestedValue = suggestion.to_content && Object.keys(suggestion.to_content).length > 0

  return (
    <LightBgCard p={3} gap={3} rounded="lg" bg="white" flexDirection={compact ? 'column-reverse' : 'column'}>
      <Flex alignItems="center" gap={8}>
        <HStack spacing={2}>
          {!compact && <CompanyAvatar size="sm" name={suggestion.company?.name} domain={suggestion.company?.domain} />}
          <Box>
            {!compact && (
              <Link
                href={suggestion.company?.domain ? `/admin/kdb/${suggestion.company.domain}` : ''}
                isExternal
                fontSize="sm"
                fontWeight="medium"
              >
                Company: {suggestion.company?.name || suggestion.company?.domain}
              </Link>
            )}
            <HStack spacing={1} fontSize="xs" color="gray.600" divider={<MiddotDivider />}>
              <Text flex="none">
                Suggested <TimeAgo time={suggestion.created_at} /> by {suggestion.user?.name || suggestion.user?.email}
              </Text>
              {acceptedAt ? (
                <Text flex="none" color="green.500">
                  Accepted <TimeAgo time={acceptedAt} />
                </Text>
              ) : rejectedAt ? (
                <Text flex="none" color="red.500">
                  Rejected <TimeAgo time={rejectedAt} />
                </Text>
              ) : null}
            </HStack>
          </Box>
        </HStack>
        {!acceptedAt && !rejectedAt && (
          <HStack flex="none" spacing={2} marginLeft="auto">
            <Button
              size={compact ? 'xs' : 'sm'}
              variant="outline"
              color="red.500"
              onClick={reject}
              isLoading={rejecting}
              isDisabled={accepting || !!acceptedAt || !!rejectedAt}
            >
              Reject
            </Button>
            <Button
              size={compact ? 'xs' : 'sm'}
              colorScheme="green"
              onClick={accept}
              isLoading={accepting}
              isDisabled={rejecting || !!acceptedAt}
            >
              {hasSuggestedValue ? 'Apply' : 'Mark Complete'}
            </Button>
          </HStack>
        )}
      </Flex>
      {hasSuggestedValue ? (
        <Box paddingLeft={compact ? 0 : 10}>
          {Object.entries(from).map(([key, value]) => (
            <React.Fragment key={key}>
              <Text flex="none" fontSize="xs" fontWeight="medium" color="gray.800">
                {fields.find((f) => f.key === key)?.label}
              </Text>
              <Stack alignItems="flex-start" fontSize="sm" fontWeight="medium" spacing={0}>
                <Text
                  paddingY={0.5}
                  color="gray.600"
                  bg="red.50"
                  borderLeftWidth="2px"
                  borderLeftColor="red.200"
                  paddingLeft={2}
                  paddingRight={2.5}
                >
                  {String(value)}
                </Text>
                <Text
                  paddingY={0.5}
                  color="green.700"
                  bg="green.50"
                  borderLeftWidth="2px"
                  borderLeftColor="green.200"
                  paddingLeft={2}
                  paddingRight={2.5}
                >
                  {String(to[key] || null)}
                </Text>
              </Stack>
            </React.Fragment>
          ))}
        </Box>
      ) : (
        <Box paddingLeft={compact ? 0 : 10} color="gray.800">
          {suggestion.comments && (
            <Text fontSize="sm" mb={4}>
              {suggestion.comments}
            </Text>
          )}

          <Text fontSize="xs" color="gray.800">
            Flagged:
          </Text>

          <UnorderedList>
            {flagged.map((key) => (
              <React.Fragment key={key}>
                <ListItem>
                  <Text flex="none" fontSize="sm" fontWeight="medium">
                    {fields.find((f) => f.key === key)?.label || key}
                  </Text>
                </ListItem>
              </React.Fragment>
            ))}
          </UnorderedList>
        </Box>
      )}
    </LightBgCard>
  )
}
