import { useDomains } from '@app/components/ui/DomainContext'
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Img,
  Input,
  Link,
  Stack,
  Text,
  useBoolean
} from '@chakra-ui/react'
import * as React from 'react'
import Marquee from 'react-fast-marquee'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { GoogleIcon } from '../../../ui/icons'
import KoalaLogo from '../../../ui/KoalaLogo'
import CoverflexLogo from './components/coverflex.svg'
import intentCRMImg from './components/crm-intent.png'
import DolbyLogo from './components/dolbyio.svg'
import GoogleLogo from './components/google.svg'
import HightouchLogo from './components/hightouch.svg'
import NumericLogo from './components/numeric.svg'
import OneSignalLogo from './components/onesignal.svg'
import RetoolLogo from './components/retool.svg'
import SanityLogo from './components/sanity.svg'
import SegmentLogo from './components/segment.svg'
import SitecheckerLogo from './components/sitechecker.svg'
import StatsigLogo from './components/statsig.svg'
import StytchLogo from './components/stytch.svg'
import VercelLogo from './components/vercel.svg'

interface Props {
  invite_token?: string
  invited?: '1'
  email?: string | null
  name?: string | null
}

export default function New(props: Props) {
  const [useEmail, setUseEmail] = useBoolean(Boolean(props.email && props.invited === '1'))
  const domains = useDomains()

  return (
    <Flex w="100%" minH="100vh" flexWrap="wrap">
      <Stack
        flex="1 0 50%"
        justifyContent="space-between"
        alignItems="center"
        minH="100vh"
        p="8"
        maxW="100vw"
        minW="max(50vw, min(500px, 100vw))"
      >
        <HStack w="100%" justifyContent={'space-between'} alignItems="center">
          <KoalaLogo size="100px" />
          <Flex fontSize="sm" gap={1} justifyContent="center">
            <Text as="span" color="gray.600">
              Already using Koala?{' '}
            </Text>
            <Link variant="primary" href="/login">
              Log in
            </Link>
          </Flex>
        </HStack>

        <Stack width="100%" maxWidth="400px" marginX="auto" spacing="12">
          <Stack justifyContent={'center'} alignItems="center">
            <Heading size="lg" textAlign="center">
              Welcome to Koala! 👋🏻
            </Heading>
            <Text fontSize="sm" color="gray.500">
              Let's get your account set up.
            </Text>
          </Stack>

          <Stack spacing={useEmail ? '8' : '4'}>
            <form action={`/auth/google_oauth2?origin=login&login_hint=${props.email || ''}`} method="post">
              <AuthenticityToken />
              <Button
                variant={useEmail ? 'outline' : 'solid'}
                colorScheme="purple"
                leftIcon={useEmail ? <Img src={GoogleLogo} /> : <GoogleIcon />}
                size="lg"
                fontSize="md"
                width="full"
                type="submit"
              >
                Continue with Google
              </Button>
            </form>
            <Collapse in={useEmail} style={{ margin: '3px -3px', padding: '3px' }} unmountOnExit>
              <Box as={'form'} action="/users" method="post" pt="8">
                <AuthenticityToken />
                {props.invite_token && <input type="hidden" name="invite_token" value={props.invite_token} />}
                <Divider />
                <Stack spacing={'12'} pt="8">
                  <Stack spacing="6">
                    <FormControl id="email">
                      <FormLabel>Your work email</FormLabel>
                      <Input
                        autoFocus
                        type="email"
                        name="user[email]"
                        placeholder="name@company.com"
                        _placeholder={{
                          fontSize: 'sm'
                        }}
                        defaultValue={props.email || ''}
                        // only make this readonly if coming from an invite token, otherwise allow the email to be modified
                        readOnly={Boolean(props.email && props.invite_token)}
                        isRequired
                      />
                    </FormControl>

                    <FormControl id="name">
                      <FormLabel>Your name</FormLabel>
                      <Input name="user[name]" defaultValue={props.name || ''} required />
                    </FormControl>

                    <FormControl id="password">
                      <FormLabel>Password</FormLabel>
                      <Input
                        type="password"
                        name="user[password]"
                        pattern=".{12,64}"
                        minLength={12}
                        _placeholder={{
                          fontSize: 'sm'
                        }}
                        placeholder="Min. 12 characters"
                        title={'Passwords must have a minimum of 12 and a maximum of 64 characters'}
                        isRequired
                      />
                    </FormControl>
                  </Stack>

                  <Button
                    type="submit"
                    size="lg"
                    fontSize="md"
                    colorScheme="purple"
                    variant={useEmail ? 'solid' : 'outline'}
                  >
                    Sign up with email
                  </Button>
                </Stack>
              </Box>
            </Collapse>

            {!useEmail && (
              <Button
                type="button"
                size="lg"
                fontWeight={'normal'}
                fontSize="md"
                onClick={setUseEmail.on}
                variant={useEmail ? 'solid' : 'outline'}
              >
                Sign up with email
              </Button>
            )}
          </Stack>
          <Text textAlign="center" fontSize="sm" color="gray.600">
            By signing up, you agree to our{' '}
            <Link color={'purple.500'} href={`https://${domains.domain}/legal/privacy`}>
              privacy policy
            </Link>{' '}
            and{' '}
            <Link color={'purple.500'} href={`https://${domains.domain}/legal/terms`}>
              terms
            </Link>
            .
          </Text>
        </Stack>
        <Box />
      </Stack>

      <Flex
        flex="1 0 50%"
        minH="100vh"
        justifyContent="center"
        alignItems="stretch"
        maxW="100vw"
        minW="max(50vw, min(500px, 100vw))"
      >
        <Stack w="100%" justifyContent={'space-evenly'} p="8" alignItems="center" m="8" bg="purple.600" rounded="md">
          <Img src={intentCRMImg} w="95%" maxW="min(90vw, 500px)" />
          <Box display="flex" flexDirection="column" alignItems="center" w="100%">
            <Text textTransform="uppercase" letterSpacing="widest" fontSize="xs" fontWeight="medium" color="white">
              Trusted by world-class sales teams at
            </Text>
            <Box w="80%" maxW="600px" pt={6}>
              <svg width="0" height="0">
                <filter id="desaturate">
                  <feColorMatrix
                    type="matrix"
                    values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0"
                  />
                </filter>
              </svg>

              <Marquee speed={30} gradient gradientColor={[77, 55, 228]} gradientWidth={100}>
                {[
                  VercelLogo,
                  RetoolLogo,
                  HightouchLogo,
                  SegmentLogo,
                  SanityLogo,
                  DolbyLogo,
                  StatsigLogo,
                  StytchLogo,
                  OneSignalLogo,
                  CoverflexLogo,
                  NumericLogo,
                  SitecheckerLogo
                ].map((logo, i) => (
                  <Box
                    as="img"
                    key={i}
                    height="22px"
                    marginX={5}
                    width="auto"
                    src={logo}
                    filter={`url(#desaturate) invert(100)`}
                  />
                ))}
              </Marquee>
            </Box>
          </Box>
        </Stack>
      </Flex>
    </Flex>
  )
}
