import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import {
  IconArrowRight,
  IconBriefcase,
  IconExternalLink,
  IconShare3,
  IconUsers,
  IconWorldPin,
  IconWorldShare
} from '@tabler/icons-react'
import { compact, uniq } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMountedState } from 'react-use'
import { concurrentCachedGET } from '../../../../lib/api'
import { Apps } from '../../../../types/App'
import { KQLByDay } from '../../../../types/KQL'
import type { ProfileRecord } from '../../../../types/Profile'
import { AISummaryCard } from '../../../ui/AISummaryCard'
import Avatar from '../../../ui/Avatar'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { CompanyBubble } from '../../../ui/CompanyBubble'
import EmptyState from '../../../ui/EmptyState'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { BuildingIcon, LinkedinBoxIcon, SalesforceIcon } from '../../../ui/icons'
import { HubSpotIcon } from '../../../ui/icons/HubspotIcons'
import { LastEngagementHoverCard } from '../../../ui/LastEngagement'
import { projectPath } from '../../../ui/ProjectsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { ActivityCard } from '../../accounts/components/DetailsV2/ActivityCard'
import { Analytics } from '../../accounts/components/DetailsV2/Analytics'
import { CompanyDetailsCard } from '../../accounts/components/DetailsV2/CompanyDetailsCard'
import { TechStackCard } from '../../accounts/components/DetailsV2/TechStackCard'
import { TrafficSources } from '../../accounts/components/TrafficSources'
import { accountPath } from '../../accounts/lib/account-path'
import { KqlDefinition } from '../../kql_definitions/types'
import { profilePath } from '../lib/path'
import { AddToCrmButton } from './AddToCRMButton'
import { EmailMessages } from './EmailMessages'
import { VisitorIntentFeedV2 } from './profile-feed'

interface Props extends UseDisclosureProps {
  apps?: Apps
  profileId?: string
  redacted?: boolean
}

interface Res {
  profile: ProfileRecord
  apps: Apps
  kqls_by_day: KQLByDay[]
  kql_definitions: KqlDefinition[]
}

export function ProfilePanelV2({ profileId, redacted, ...props }: Props) {
  const disclosure = useDisclosure(props)
  const [apps, setApps] = React.useState<Apps | undefined>(props.apps)
  const [profile, setProfile] = useState<ProfileRecord | undefined>()
  const [signalsByDay, setSignalsByDay] = useState<KQLByDay[]>([])
  const [signalDefinitions, setSignalDefinitions] = useState<KqlDefinition[]>([])
  const [loading, setLoading] = useState(false)
  const mounted = useMountedState()
  const isOpen = disclosure.isOpen

  profileId = profileId || profile?.id

  useEffect(() => {
    if (loading || profile || !profileId || !isOpen) {
      return
    }

    setLoading(true)
    concurrentCachedGET<Res>(profilePath({ id: profileId }))
      .then((prof) => {
        if (mounted()) {
          setProfile(prof.profile)
          setApps(prof.apps)
          setSignalsByDay(prof.kqls_by_day)
          setSignalDefinitions(prof.kql_definitions)
          setLoading(false)
        }
      })
      .catch(() => {
        if (mounted()) {
          setLoading(false)
        }
      })
  }, [loading, profile, profileId, isOpen, mounted])

  if (!profileId) {
    return null
  }

  return (
    <Drawer {...disclosure} placement="right" size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody py={0}>
          {loading ? (
            <Flex alignItems="center" justifyContent="center" height="100%" py={3}>
              <Spinner color="gray.400" size="lg" />
            </Flex>
          ) : profile ? (
            <ProfilePanelDetails
              profile={profile}
              apps={apps}
              onUpdateProfile={setProfile}
              signalsByDay={signalsByDay}
              signalDefinitions={signalDefinitions}
            />
          ) : (
            <Text fontSize="sm" color="gray.500" py={3}>
              Profile not found
            </Text>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

interface ProfilePanelDetailsProps {
  profile: ProfileRecord
  apps?: Apps
  onUpdateProfile?: (profile: ProfileRecord) => void
  signalsByDay: KQLByDay[]
  signalDefinitions: KqlDefinition[]
}

function ProfilePanelDetails(props: ProfilePanelDetailsProps) {
  const profile = props.profile
  const company = profile.company
  const sfAccount = (profile.account as any)?.salesforce_account_cache
  const hsCompany = (profile.account as any)?.hubspot_company_cache
  const geo =
    company?.location || uniq(compact([company?.geo?.city, company?.geo?.stateCode, company?.geo?.country])).join(', ')
  const employees = company?.metrics?.employeesRange || company?.metrics?.employees

  const emailCount = profile.email_messages?.length ?? 0

  // figure out last engagement (whether synced in Koala, or from SF / HS)
  const directActivity = profile?.last_activity
  const lastActivity = [directActivity?.created_at, sfAccount?.last_activity_date, hsCompany?.last_activity_date]
    .filter(Boolean)
    .sort()
    .reverse()[0]

  return (
    <Flex flexDirection="column" gap={6} py={2}>
      <Flex direction="column" gap={3}>
        {/* Profile Summary */}
        <Stack spacing={0} pb={2}>
          <Flex flex="none" justifyContent="space-between" gap={4} py={4}>
            <Flex flex="1" alignItems="flex-start" gap={3}>
              <Box display="flex" flex="none" position="relative">
                <Avatar
                  src={profile.avatar || profile.image}
                  name={profile.name || profile.full_name || profile.display_name}
                  size="40px"
                />
                <Box position="absolute" transform="translate(18%, 18%)" right={0} bottom={0}>
                  <CompanyAvatar
                    domain={profile.company?.domain}
                    size="18px"
                    rounded="full"
                    boxShadow="0 0 0 2px white"
                    background="white"
                    objectFit="contain"
                  />
                </Box>
              </Box>
              <Stack spacing={0}>
                <Flex alignItems="center" gap={1.5}>
                  <TextEllipsis
                    as={!profile.email ? Text : Link}
                    href={profilePath(profile)}
                    isExternal
                    fontSize="15px"
                    fontWeight="semibold"
                    maxWidth="300px"
                    tooltip
                  >
                    {profile.name || profile.full_name || profile.display_name || profile.email}
                  </TextEllipsis>
                  {/* {profile.contact_stage === 'warm' && <WarmLeadBadge />} */}
                  {/* {profile.contact_stage === 'cold' && <ColdLeadBadge />} */}
                  {/* {profile.contact_stage === 'cold' && <ColdLeadBadge />} */}
                </Flex>
                <Text fontSize="13px" color="gray.600" lineHeight="22px">
                  {[profile.title || profile.simple_location, profile.company?.name].filter(Boolean).join(' @ ')}
                </Text>

                {lastActivity && lastActivity == directActivity?.created_at ? (
                  <LastEngagementHoverCard engagement={directActivity}>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={IconShare3} boxSize={4} color="gray.400" fill="currentColor" />
                      <Text fontSize="13px" color="gray.500">
                        {directActivity?.metadata?.direction === 'inbound' ? 'Replied' : 'Sent message'}{' '}
                        <TimeAgo time={lastActivity} canToggle={false} />
                      </Text>
                    </Flex>
                  </LastEngagementHoverCard>
                ) : lastActivity ? (
                  <Text fontSize="13px" color="gray.500">
                    Last sales activity <TimeAgo time={lastActivity} canToggle={false} />
                  </Text>
                ) : null}
              </Stack>
            </Flex>

            <Flex flex="none" alignItems="flex-start" gap={1.5}>
              {(profile.contact_urls?.salesforce || profile.contact_urls?.salesforce_lead) && (
                <Tooltip label={profile.contact_urls?.salesforce || profile.contact_urls?.salesforce_lead}>
                  <IconButton
                    aria-label="View in Salesforce"
                    as={Link}
                    href={profile.contact_urls?.salesforce || profile.contact_urls?.salesforce_lead}
                    isExternal
                    icon={<SalesforceIcon size={14} color="salesforce.500" />}
                    size="sm-compact"
                    variant="outline"
                  />
                </Tooltip>
              )}

              {profile.contact_urls?.hubspot && (
                <Tooltip label={profile.contact_urls?.hubspot}>
                  <IconButton
                    aria-label="View in HubSpot"
                    as={Link}
                    href={profile.contact_urls.hubspot}
                    isExternal
                    icon={<HubSpotIcon size={14} color="hubspot" />}
                    size="sm-compact"
                    variant="outline"
                  />
                </Tooltip>
              )}

              {profile.linkedin_url && (
                <Tooltip label={`https://${profile.linkedin_url.replace(/https?:\/\//, '')}`}>
                  <IconButton
                    as={Link}
                    href={`https://${profile.linkedin_url.replace(/https?:\/\//, '')}`}
                    isExternal
                    aria-label="LinkedIn"
                    icon={<LinkedinBoxIcon boxSize={18} color="linkedin.700" />}
                    size="sm-compact"
                    variant="outline"
                  />
                </Tooltip>
              )}
              <Button
                as={Link}
                href={profilePath(profile)}
                isExternal
                variant="outline"
                size="sm-compact"
                rightIcon={<IconArrowRight size={14} />}
                iconSpacing={1.5}
              >
                View profile
              </Button>
            </Flex>
          </Flex>

          {/* action buttons */}
          <Flex gap={2} alignItems="center">
            <AddToCrmButton profile={profile} apps={props.apps} onUpdateProfile={props.onUpdateProfile}>
              {({ isLoading }) => (
                <Button variant="outline" size="sm" isLoading={isLoading}>
                  Add to CRM
                </Button>
              )}
            </AddToCrmButton>
          </Flex>
        </Stack>

        {/* Company Summary */}
        {company && (
          <Box bg="gray.50" border="1px solid" borderColor="gray.200" p={3} pl={3.5} rounded="lg">
            <Stack spacing={3}>
              <Flex width="100%" gap={2} alignItems="center">
                <CompanyBubble
                  domain={company?.domain}
                  name={company?.name}
                  href={accountPath({ company })}
                  target="_blank"
                />

                <HStack spacing={2} ml="auto">
                  <Flex alignItems="center" gap={0.5}>
                    {hsCompany?.permalink && (
                      <Tooltip label={hsCompany.permalink}>
                        <IconButton
                          as={Link}
                          href={hsCompany.permalink}
                          isExternal
                          aria-label="View HubSpot Account"
                          icon={<HubSpotIcon boxSize={4} color="hubspot" />}
                          size="xs"
                          variant="ghost"
                        />
                      </Tooltip>
                    )}
                    {sfAccount?.permalink && (
                      <Tooltip label={sfAccount.permalink}>
                        <IconButton
                          as={Link}
                          href={sfAccount.permalink}
                          isExternal
                          aria-label="View Salesforce Account"
                          icon={<SalesforceIcon boxSize={4} color="salesforce.500" />}
                          size="xs"
                          variant="ghost"
                        />
                      </Tooltip>
                    )}
                    {company?.linkedin_url && (
                      <Tooltip label={`https://${company.linkedin_url.replace(/https?:\/\//, '')}`}>
                        <IconButton
                          as={Link}
                          href={`https://${company.linkedin_url.replace(/https?:\/\//, '')}`}
                          isExternal
                          aria-label="LinkedIn"
                          icon={<LinkedinBoxIcon boxSize={18} color="linkedin.700" />}
                          size="xs"
                          variant="ghost"
                        />
                      </Tooltip>
                    )}
                    {company?.domain && (
                      <Tooltip label={`https://${company.domain}`}>
                        <IconButton
                          as={Link}
                          href={`https://${company.domain}`}
                          isExternal
                          aria-label="Website"
                          icon={<IconWorldShare size={16} />}
                          size="xs"
                          variant="ghost"
                        />
                      </Tooltip>
                    )}
                  </Flex>
                </HStack>
              </Flex>

              {company?.description && (
                <Text fontSize="13px" color="gray.600" noOfLines={2}>
                  {company.description}
                </Text>
              )}

              <Flex columnGap={5} rowGap={2} color="gray.600" flexWrap="wrap">
                {geo && (
                  <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                    <IconWorldPin size={16} />
                    <Text>{geo}</Text>
                  </HStack>
                )}
                {company?.category?.industry && (
                  <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                    <IconBriefcase size={16} />
                    <Text>{company.category.industry}</Text>
                  </HStack>
                )}
                {!!employees && (
                  <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                    <IconUsers size={16} />
                    <Text>{employees} employees</Text>
                  </HStack>
                )}
              </Flex>
            </Stack>
          </Box>
        )}
      </Flex>

      <Tabs size="sm" variant="line" isLazy lazyBehavior="keepMounted">
        <TabList position="sticky" top={0} bg="white" zIndex={1} mx={-1} px={1}>
          <Tab px={3}>Activity</Tab>
          <Tab px={3}>
            <Flex alignItems="center" gap={1.5}>
              Emails
              {emailCount > 0 && (
                <Badge variant="pill" colorScheme="gray">
                  {emailCount.toLocaleString()}
                </Badge>
              )}
            </Flex>
          </Tab>
          <Tab px={3}>AI Research</Tab>
          <Tab px={3}>Company</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <ActivityTab {...props} />
          </TabPanel>
          <TabPanel px={0}>
            <EmailMessages emails={props.profile.email_messages ?? []} />
          </TabPanel>
          <TabPanel px={0}>
            <AIAgentsTab {...props} />
          </TabPanel>
          <TabPanel px={0}>
            <CompanyTab {...props} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

function ActivityTab({ profile, signalsByDay, signalDefinitions }: ProfilePanelDetailsProps) {
  return (
    <Flex flexDirection="column" gap={4}>
      {profile?.focus_time_trend && profile.page_views_trend && (
        <ActivityCard
          focusTrend={profile.focus_time_trend}
          pageviewTrend={profile.page_views_trend}
          signalsByDay={signalsByDay}
          signalDefns={signalDefinitions}
          colorScheme="blue"
          stacked
        />
      )}

      {(profile.utm_page_views?.length ?? 0) > 0 && (
        <Stack w="100%">
          <HStack>
            <Heading size="xs">Recent UTM Traffic</Heading>
            <HelpTooltip>
              <Stack fontSize={'xs'}>
                <Heading size="xs">UTM Traffic</Heading>
                <Text>
                  This table shows the most recent UTM parameters for this visitor and the pages they visited.
                </Text>

                <Text>
                  You can see a full report of all UTM parameters by clicking on the parameter, or accessing your{' '}
                  <Button
                    as={Link}
                    fontSize="xs"
                    fontWeight={'semibold'}
                    variant="link"
                    isExternal
                    href={projectPath('/utm-reports')}
                    colorScheme="purple"
                    rightIcon={<IconExternalLink size="12" />}
                  >
                    UTM Content Report
                  </Button>
                  .
                </Text>
              </Stack>
            </HelpTooltip>
          </HStack>
          <TrafficSources hover={false} utm_page_views={profile.utm_page_views!} />
        </Stack>
      )}

      <VisitorIntentFeedV2 profile={profile} scopedTo="profile" />
    </Flex>
  )
}

function CompanyTab({ profile }: ProfilePanelDetailsProps) {
  const account = profile.account

  if (!account) {
    return (
      <EmptyState
        size="sm"
        icon={BuildingIcon}
        heading="No company match found"
        description="We couldn't find any association with a company yet."
      />
    )
  }

  return (
    <Flex flexDirection="column" gap={4}>
      <CompanyDetailsCard account={account} />
      {account.company_technologies && (
        <TechStackCard
          account={{
            company_technologies: account.company_technologies
          }}
        />
      )}
      <Analytics account={account} />
    </Flex>
  )
}

function AIAgentsTab({ profile }: ProfilePanelDetailsProps) {
  // TODO sort the ones with answers to the top
  // TODO hide the ones without answers behind a "show all"
  // TODO sort the most commonly used ones to the top
  // TODO show the ones tied to the Play context first
  return <AISummaryCard profile={profile} target="people" />
}
