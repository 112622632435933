import {
  Button,
  HStack,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconArrowRight, IconCheck, IconChevronDown, IconChevronRight } from '@tabler/icons-react'
import React, { useMemo, useRef, useState } from 'react'
import { useAIAgents } from '../../../data/use-ai-agents'
import { AiSparklesIcon } from '../../../ui/icons'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { Toggle } from '../../accounts/components/Toggle'
import { humanize } from '../../accounts/facets/filter-cloud'

interface AIAgentFieldProps {
  agent_key: string
  targetType: 'account' | 'visitor'
  onChange: (object: { slug: string; answer_type: string; agent_field: string }) => void
  children?: React.ReactNode
  skipArrow?: boolean
}

export function AIAgentField(props: AIAgentFieldProps) {
  const { data: aiAgents } = useAIAgents()

  const [search, setSearch] = useState('')

  const myAgents = useMemo(() => aiAgents?.my_agents ?? [], [aiAgents])
  const filteredAgents = useMemo(() => {
    return myAgents.filter((agent) => agent.name.toLowerCase().includes(search.toLowerCase()))
  }, [myAgents, search])

  const agentSelection = useMemo(() => {
    const [target, slug, answer_type] = props.agent_key.replace('agents.', '').split('.')
    return { target, slug, answer_type }
  }, [props.agent_key])

  const selectedAgent = useMemo(
    () => aiAgents?.my_agents?.find((agent) => agent.slug === agentSelection.slug),
    [aiAgents, agentSelection]
  )

  const initialFocusRef = useRef<HTMLInputElement>()
  const answerType = useMemo(() => {
    return props.agent_key.replace('agents.', '').split('.').pop()
  }, [props.agent_key])

  return (
    <>
      <HStack flex="1" bg="white" w="100%" alignItems="center">
        <Popover>
          <PopoverTrigger>
            <Button
              w="100%"
              rightIcon={<Icon as={IconChevronDown} />}
              size="sm"
              justifyContent="space-between"
              variant="outline"
              fontWeight={'normal'}
              bg="white"
            >
              {selectedAgent && (
                <HStack w="100%" fontSize="xs">
                  <Icon as={AiSparklesIcon} color="purple.500" />
                  <TextEllipsis tooltip>{selectedAgent.name}</TextEllipsis>
                  <TextEllipsis as="span" fontSize="xs" color="gray.500" tooltip>
                    {humanize(answerType ?? '')}
                    {answerType === 'typed_answer' ? ` (${selectedAgent.response_type})` : null}
                  </TextEllipsis>
                </HStack>
              )}
              {!selectedAgent && (
                <HStack w="100%" fontSize="xs">
                  <Icon as={AiSparklesIcon} color="purple.500" />
                  <Text w="100%" color="gray.500" textAlign={'left'} fontSize="xs">
                    Select an agent
                  </Text>
                </HStack>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody pt="3" as={Stack} spacing="4">
              <Input
                ref={initialFocusRef as any}
                size="sm"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Text
                fontSize="12px"
                fontWeight="medium"
                color="gray.500"
                letterSpacing="0.04em"
                textTransform="uppercase"
                paddingX={3}
              >
                Agents
              </Text>

              <Stack maxH="400px" overflowY="auto">
                {filteredAgents.map((agent) => (
                  <Toggle
                    key={agent.id}
                    showIcon={false}
                    defaultIsOpen={search.length > 0 || selectedAgent?.slug === agent.slug}
                    title={(isOpen) => (
                      <Button
                        w="100%"
                        size="sm"
                        justifyContent={'space-between'}
                        variant="ghost"
                        rightIcon={<Icon as={isOpen ? IconChevronDown : IconChevronRight} />}
                        color="gray.700"
                        _hover={{ color: 'purple.500', bg: 'purple.100' }}
                        colorScheme={'purple'}
                        flex="1"
                        isActive={isOpen}
                        _active={{ bg: 'gray.200' }}
                      >
                        <HStack spacing="2">
                          <Icon as={AiSparklesIcon} color="purple.500" />
                          <Text>{agent.name}</Text>
                        </HStack>
                      </Button>
                    )}
                  >
                    <Stack borderLeftWidth="thin" pl="1" ml="0.5">
                      {[
                        { key: 'typed_answer', label: 'Typed answer' },
                        { key: 'short_answer', label: 'Short answer' },
                        { key: 'detailed_answer', label: 'Detailed answer' },
                        { key: 'reasoning', label: 'Reasoning' }
                      ].map((answer) => {
                        const isActive = props.agent_key.includes(answer.key) && selectedAgent?.slug === agent.slug

                        return (
                          <Button
                            key={answer.key}
                            color="gray.700"
                            w="100%"
                            justifyContent={'flex-start'}
                            _hover={{ color: 'purple.500', bg: 'purple.100' }}
                            _active={{ bg: 'purple.50', color: 'purple.500' }}
                            variant="ghost"
                            colorScheme={'purple'}
                            rightIcon={isActive ? <Icon as={IconCheck} /> : undefined}
                            fontWeight={isActive ? 'medium' : 'normal'}
                            size="sm"
                            isActive={isActive}
                            onClick={() => {
                              props.onChange({
                                slug: agent.slug,
                                answer_type: answer.key,
                                agent_field: `${props.targetType}.agents.${agent.slug}.${answer.key}`
                              })
                            }}
                          >
                            {answer.label} {answer.key === 'typed_answer' ? `(${agent.response_type})` : null}
                          </Button>
                        )
                      })}
                    </Stack>
                  </Toggle>
                ))}
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>

      {!props.skipArrow && <Icon as={IconArrowRight} />}

      {props.children}
    </>
  )
}
