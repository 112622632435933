import {
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useAppDep } from '../../../../data/use-app-dep'
import { GrayCard, LightBgCard } from '../../../../ui/Card'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { HubspotActionProps } from './hubspot-setup'

type HubspotOwner = {
  id: string
  hubspot_id: string
  email: string
  payload: {
    id: string
    email: string
    userId: number
    archived: boolean
    lastName: string
    firstName: string
  }
}

export function CreateTask(
  props: HubspotActionProps & {
    targetType?: 'Profile' | 'Account'
  }
) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.hubspot?.create_task?.enabled))

  const { data: usersData, isLoading: loadingUsers } = useAppDep<'owners', HubspotOwner[]>('Hubspot', 'owners')

  const [ownerOption, setOwnerOption] = React.useState<'account_owner' | 'specific_user' | 'unset'>(
    props.delivery_rules?.hubspot?.create_task?.ownership_option ?? 'unset'
  )

  const [dedupeStrategy, setDedupeStrategy] = React.useState<
    'always_create' | 'create_if_closed' | 'never_create' | 'create_if_none_since'
  >(props.delivery_rules?.hubspot?.create_task?.dedupe_strategy ?? 'never_create')

  const [selectedOwnerId, setSelectedOwnerId] = React.useState<string | null>(
    props.delivery_rules?.hubspot?.create_task?.owner_id ?? null
  )

  const selectedOwner = useMemo(
    () =>
      usersData?.data?.owners?.find((s) => {
        return s.hubspot_id.toString() === selectedOwnerId?.toString()
      }) ?? null,
    [usersData, selectedOwnerId]
  )

  return (
    <Stack
      borderLeftWidth={props.compact ? 'none' : 'thick'}
      borderLeftColor={`${enabled ? props.colorScheme : 'gray'}.400`}
      px={props.compact ? '0' : '6'}
      py={props.compact ? '0' : '2'}
    >
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][hubspot][create_task][enabled]"
        >
          Create Task
        </Switch>
        {!props.compact && (
          <FormHelperText>
            {`Create a task for the current ${props.targetType === 'Profile' ? 'Visitor' : 'Account'} in HubSpot if it doesn't already exist.`}
          </FormHelperText>
        )}
      </FormControl>

      {enabled && (
        <Stack as={GrayCard} spacing="4" p="8">
          <FormControl>
            <FormLabel>Subject</FormLabel>
            <Input
              bg="white"
              type="text"
              size="sm"
              rounded="md"
              placeholder="Subject"
              name="follow_rule[delivery_rules][hubspot][create_task][subject]"
              defaultValue={props.delivery_rules?.hubspot?.create_task?.subject}
            />
          </FormControl>

          <HStack>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                size="sm"
                bg="white"
                rounded="md"
                name="follow_rule[delivery_rules][hubspot][create_task][type]"
                defaultValue={props.delivery_rules?.hubspot?.create_task?.type ?? 'TODO'}
              >
                <option value="TODO">TODO</option>
                <option value="EMAIL">Email</option>
                <option value="CALL">Call</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Priority</FormLabel>
              <Select
                size="sm"
                rounded="md"
                name="follow_rule[delivery_rules][hubspot][create_task][priority]"
                bg="white"
                defaultValue={props.delivery_rules?.hubspot?.create_task?.priority ?? 'MEDIUM'}
              >
                <option value="HIGH">High</option>
                <option value="MEDIUM">Medium</option>
                <option value="LOW">Low</option>
              </Select>
            </FormControl>
          </HStack>

          <FormControl>
            <FormLabel>Ownership</FormLabel>
            <LightBgCard bg="white">
              <Stack>
                <RadioGroup
                  value={ownerOption}
                  onChange={(value) => setOwnerOption(value as 'account_owner' | 'specific_user' | 'unset')}
                  size="sm"
                  fontSize="xs"
                  name="follow_rule[delivery_rules][hubspot][create_task][ownership_option]"
                >
                  <HStack w="100%">
                    <Radio bg="white" value="account_owner">
                      Current Account / Contact Owner
                    </Radio>
                    <Divider orientation="vertical" />

                    <Radio bg="white" value="specific_user">
                      Select a User
                    </Radio>
                    <Divider orientation="vertical" />

                    <Radio bg="white" value="unset">
                      Unset
                    </Radio>
                  </HStack>
                </RadioGroup>

                {ownerOption !== 'unset' && <Divider pt="2" />}

                {ownerOption === 'account_owner' && (
                  <>
                    <Text fontSize="sm" pt="2">
                      The Task owner will be inherited from the owner of the Account or Contact. Note: this means
                      Unowned Accounts will create un-assigned Tasks.
                    </Text>
                  </>
                )}

                {ownerOption === 'specific_user' && (
                  <>
                    <FormLabel pt="2">Choose HubSpot Owner</FormLabel>
                    {loadingUsers && <Spinner size="sm" />}
                    {selectedOwnerId && (
                      <input
                        type="hidden"
                        name="follow_rule[delivery_rules][hubspot][create_task][owner_id]"
                        value={selectedOwnerId}
                      />
                    )}
                    <ComboboxWithSearch
                      items={usersData?.data?.owners ?? []}
                      selectedItem={selectedOwner}
                      onChange={(selectedItem) => {
                        setSelectedOwnerId(selectedItem?.hubspot_id ?? null)
                      }}
                      filterItem={(a, val) => a.payload.email.toLowerCase().includes(val)}
                      itemToString={(item) => item?.payload.email ?? ''}
                    />
                    <Text fontSize="sm" pt="2">
                      All Tasks will be assigned to the selected User.
                    </Text>
                  </>
                )}
              </Stack>
            </LightBgCard>
          </FormControl>
          <FormControl>
            <FormLabel>Due Date</FormLabel>
            <Select
              size="sm"
              rounded="md"
              bg="white"
              name="follow_rule[delivery_rules][hubspot][create_task][due_date]"
              defaultValue={props.delivery_rules?.hubspot?.create_task?.due_date}
            >
              <option value="unset">Not set</option>
              <option value="day">1 day</option>
              <option value="week">1 week</option>
              <option value="two_weeks">2 weeks</option>
              <option value="month">1 month</option>
            </Select>
            <FormHelperText>How long since Task creation to set the due date for.</FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel>Deduplication Strategy</FormLabel>
            <LightBgCard bg="white" pt="2">
              <FormHelperText>How should Koala handle duplicate tasks for the same Contact or Company</FormHelperText>
              <RadioGroup
                pt="1"
                value={dedupeStrategy}
                size="sm"
                fontSize="xs"
                name="follow_rule[delivery_rules][hubspot][create_task][dedupe_strategy]"
                as={Stack}
                spacing="1"
                onChange={(value) =>
                  setDedupeStrategy(
                    value as 'always_create' | 'create_if_closed' | 'never_create' | 'create_if_none_since'
                  )
                }
              >
                <Radio bg="white" value="always_create">
                  Always create a new Task
                </Radio>

                <Radio bg="white" value="create_if_closed">
                  Create new Task if most recent one is completed
                </Radio>

                <Radio bg="white" value="never_create">
                  Never create a new Task
                </Radio>

                <Radio bg="white" value="create_if_none_since">
                  Create new Task if none were created in the past...
                </Radio>

                {dedupeStrategy === 'create_if_none_since' && (
                  <Select
                    size="xs"
                    rounded="md"
                    bg="white"
                    name="follow_rule[delivery_rules][hubspot][create_task][dedupe_days]"
                    defaultValue={props.delivery_rules?.hubspot?.create_task?.dedupe_days ?? 7}
                  >
                    <option value={7}>7 days</option>
                    <option value={14}>14 days</option>
                    <option value={30}>30 days</option>
                    <option value={60}>90 days</option>
                    <option value={180}>6 months</option>
                  </Select>
                )}
              </RadioGroup>
            </LightBgCard>
          </FormControl>
        </Stack>
      )}
    </Stack>
  )
}
