import { Box, Button, Card, CardBody, Heading, HStack, Icon, Link, Progress, Text } from '@chakra-ui/react'
import { IconCheck, IconClock } from '@tabler/icons-react'
import { truncate } from 'lodash'
import React, { useMemo } from 'react'
import { Goal } from '../../../data/use-onboarding-goals'
import { projectPath } from '../../../ui/ProjectsContext'

interface OnboardingCardProps {
  goal: Goal
  completedSteps: string[]
  isEnabled: boolean
  onAction: () => void
}

export const OnboardingCard: React.FC<OnboardingCardProps> = ({ goal, completedSteps, isEnabled, onAction }) => {
  const totalSteps = goal.steps.length
  const progress = totalSteps ? ((completedSteps?.length || 0) / totalSteps) * 100 : 0

  const isCompleted = useMemo(() => {
    return completedSteps?.length == totalSteps
  }, [completedSteps, totalSteps])

  const actionLabel = useMemo(() => {
    if (isCompleted) {
      return 'Explore'
    }
    if (completedSteps?.length == 0) {
      return 'Get started'
    }

    return 'Continue'
  }, [isCompleted, completedSteps])

  return (
    <Card
      border="1px solid"
      borderColor={isEnabled ? 'purple.500' : 'gray.200'}
      p={0}
      boxShadow="none"
      transition="all 0.2s ease-in-out"
      _hover={
        isEnabled
          ? {
              transform: 'translateY(-2px)'
            }
          : {}
      }
      cursor={!isEnabled ? 'not-allowed' : 'default'}
    >
      <CardBody display="flex" flexDirection="column" p={0}>
        <Box
          minHeight="100px"
          bg="gray.50"
          mb={4}
          borderRadius="md"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            bg="white"
            p={4}
            width="48px"
            height="48px"
            borderRadius="full"
            borderColor={isEnabled ? 'gray.200' : 'gray.100'}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {React.cloneElement(goal.icon, {
              boxSize: '24px',
              color: isEnabled ? 'purple.600' : 'gray.200'
            })}
          </Box>
        </Box>

        <Box px={3} display="flex" flexDirection="column" flex={1}>
          <Heading size="md" mb={2} fontWeight="600" fontSize="md">
            {goal.title}
          </Heading>

          <Text mb={4} fontSize="xs" lineHeight="20px" color="gray.600">
            {truncate(goal.description, { length: 45 })}
          </Text>

          {isEnabled && isCompleted && (
            <Text color="purple.600" fontWeight="500" fontSize="sm" mb={4} display="flex" alignItems="center" gap={1}>
              <Icon as={IconCheck} /> Completed
            </Text>
          )}

          {isEnabled && !isCompleted && completedSteps?.length !== undefined && totalSteps && (
            <>
              <Progress value={progress} size="xs" colorScheme="purple" mb={2} borderRadius="full" />
              <HStack justify="space-between" mb={2}>
                <Text fontSize="xs" color="gray.500">
                  {completedSteps.length} of {totalSteps} steps
                </Text>
                {goal.timeApproximation && (
                  <Text fontSize="xs" color="gray.600" display="flex" alignItems="center" gap={1}>
                    <Icon as={IconClock} /> {goal.timeApproximation} min
                  </Text>
                )}
              </HStack>
            </>
          )}

          <Button
            width="full"
            colorScheme={isEnabled ? 'purple' : 'gray'}
            variant={'outline'}
            onClick={onAction}
            disabled={!isEnabled}
            mt="auto"
            mb={2}
            as={Link}
            href={projectPath(`setup/${goal.id}`)}
          >
            {actionLabel}
          </Button>
        </Box>
      </CardBody>
    </Card>
  )
}
