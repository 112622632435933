import { validFilter } from '../../pages/accounts/facets/categories'
import { AdvancedFilters } from './types'

export function filtersForType(filters: AdvancedFilters, listType?: string) {
  if (listType === undefined) {
    return filters
  }

  if (filters === null || filters === undefined) {
    return filters
  }

  // filter out any rules where the category doesn't match
  const kind = listType.toLowerCase()
  const filtered: AdvancedFilters = {}

  Object.keys(filters).forEach((facet) => {
    if (facet === '_or' || facet === '_and') {
      const values = (filters[facet] as any)
        ?.map((group) => filtersForType(group, listType))
        ?.filter((g) => g && Object.keys(g).length > 0)
      if (values && values.length > 0) {
        filtered[facet] = values
      }
    } else if (validFilter(kind, facet)) {
      filtered[facet] = filters[facet]
    }
  })

  return filtered
}
