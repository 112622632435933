import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { toast } from 'sonner'
import { Apps } from '../../../../types/App'
import { ProfileRecord } from '../../../../types/Profile'
import { useImportProfileToCrm } from '../../../data/use-import-to-crm'
import { OutreachIcon, SalesforceIcon } from '../../../ui/icons'
import { HubSpotIcon } from '../../../ui/icons/HubspotIcons'
import useLatestRef from '../../../ui/useLatestRef'
interface AddToCrmMenuProps {
  profile: ProfileRecord
  apps?: Apps | null
  onUpdateProfile?: (profile: ProfileRecord) => void
  children?: React.ReactNode | ((props: { isLoading: boolean }) => React.ReactNode)
}

export function AddToCrmButton({ children, profile, apps, onUpdateProfile }: AddToCrmMenuProps) {
  const updateProfileRef = useLatestRef(onUpdateProfile)

  const hubspot = apps?.['Apps::Hubspot::App']
  const salesforce = apps?.['Apps::Salesforce::App']
  const outreach = apps?.['Apps::Outreach::App']

  const canImportToOutreach = useMemo(
    () => outreach?.connected && outreach?.actions?.['outreach_import_contacts']?.enabled,
    [outreach]
  )

  const canImportToHubspot = useMemo(
    () => hubspot?.connected && hubspot?.actions?.['hs_import_contacts']?.enabled,
    [hubspot]
  )

  const canImportToSalesforceContact = useMemo(
    () => salesforce?.connected && salesforce?.actions?.['sf_import_contacts']?.enabled,
    [salesforce]
  )

  const canImportToSalesforceLead = useMemo(
    () => salesforce?.connected && salesforce?.actions?.['sf_import_leads']?.enabled,
    [salesforce]
  )

  const profileName = profile?.name || profile?.full_name || profile?.display_name

  const { mutateAsync: importProfileToCrm, isPending: importing } = useImportProfileToCrm({
    onSuccess: (res, params) => {
      toast.success(`${profileName} was imported into ${params.app_module}`)

      if (res.profile) {
        updateProfileRef.current?.(res.profile)
      }
    },
    onError: (err: any, params) => {
      toast.error(`Failed to import ${profileName} into ${params.app_module}`, {
        description: err?.body ? JSON.stringify(err?.body) : undefined
      })
    }
  })

  const onImportSalesforceContact = useCallback(() => {
    if (!profile?.id) return
    if (!canImportToSalesforceContact) return

    importProfileToCrm({
      profile_id: profile.id,
      app_module: 'Salesforce',
      type: 'contact'
    })
  }, [canImportToSalesforceContact, importProfileToCrm, profile?.id])

  const onImportSalesforceLead = useCallback(() => {
    if (!profile?.id) return
    if (!canImportToSalesforceLead) return

    importProfileToCrm({
      profile_id: profile.id,
      app_module: 'Salesforce',
      type: 'lead'
    })
  }, [canImportToSalesforceLead, importProfileToCrm, profile?.id])

  const onImportHubspotContact = useCallback(() => {
    if (!profile?.id) return
    if (!canImportToHubspot) return

    importProfileToCrm({
      profile_id: profile.id,
      app_module: 'Hubspot'
    })
  }, [canImportToHubspot, importProfileToCrm, profile?.id])

  const onImportOutreachProspect = useCallback(() => {
    if (!profile?.id) return
    if (!canImportToOutreach) return

    importProfileToCrm({
      profile_id: profile.id,
      app_module: 'Outreach'
    })
  }, [canImportToOutreach, importProfileToCrm, profile?.id])

  const showMenu =
    canImportToSalesforceContact || canImportToSalesforceLead || canImportToHubspot || canImportToOutreach

  if (!showMenu || !profile) return null

  return (
    <Menu size="sm" closeOnSelect autoSelect={false} matchWidth>
      {typeof children === 'function' ? (
        <MenuButton>{children({ isLoading: importing })}</MenuButton>
      ) : children ? (
        <MenuButton as="div">{children}</MenuButton>
      ) : (
        <MenuButton as={Button} variant="outline" size="md" isLoading={importing}>
          Add to CRM
        </MenuButton>
      )}
      <MenuList zIndex="popover" minWidth="250px">
        {/* if you already have a lead, salesforce will complain you have a dupe record in most SF instances */}
        {canImportToSalesforceContact && !profile.salesforce_contact_person && !profile.salesforce_lead_person && (
          <MenuItem
            isDisabled={importing}
            icon={<SalesforceIcon color="salesforce.500" size={16} />}
            onClick={onImportSalesforceContact}
          >
            Add Salesforce Contact
          </MenuItem>
        )}
        {/* if you already have a contact, salesforce will complain you have a dupe record in most SF instances */}
        {canImportToSalesforceLead && !profile.salesforce_contact_person && !profile.salesforce_lead_person && (
          <MenuItem
            isDisabled={importing}
            icon={<SalesforceIcon color="salesforce.500" size={16} />}
            onClick={onImportSalesforceLead}
          >
            Add Salesforce Lead
          </MenuItem>
        )}
        {canImportToHubspot && !profile.hubspot_contact_person && (
          <MenuItem
            isDisabled={importing}
            icon={<HubSpotIcon color="hubspot" size={16} />}
            onClick={onImportHubspotContact}
          >
            Add HubSpot Contact
          </MenuItem>
        )}
        {canImportToOutreach && !profile.outreach_prospect_person && (
          <MenuItem
            isDisabled={importing}
            icon={<OutreachIcon color="outreach" size={16} />}
            onClick={onImportOutreachProspect}
          >
            Add Outreach Prospect
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}
