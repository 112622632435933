import { format as friendlyNumber } from 'friendly-numbers'
import {
  Button,
  Code,
  Divider,
  Flex,
  FormControl,
  Heading,
  HStack,
  Icon,
  Link,
  ListItem,
  Spinner,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UnorderedList
} from '@chakra-ui/react'
import {
  IconAlertTriangle,
  IconCalendar,
  IconCircleXFilled,
  IconPlayerPause
} from '@tabler/icons-react'
import { IconTable, IconLockCheck } from '@tabler/icons-react'
import { CheckboxCircleIcon } from './icons'
import React, { useEffect, useState } from 'react'
import { projectPath } from './ProjectsContext'
import { post, concurrentGET } from '../../lib/api'
import { Toggle } from '../pages/accounts/components/Toggle'
import { toast } from 'sonner'
import { LightBgCard } from './Card'
import { TextEllipsis } from './text-ellipsis'
import { TimeAgo } from './TimeAgo'
import { nanoid } from 'nanoid'

interface IntegrationSync {
  sync_type: 'full' | 'incremental'
  status: string
  started_at: string
  stopped_at?: string
  pending_count: number
  synced_count: number
  failed_count: number
  skipped_count: number
}

export interface DataWrite {
  id: string
  enabled: boolean
  model: string
  title: string
  size: string
  sync_type: 'full' | 'incremental'
  last_sync?: IntegrationSync
}

interface DataWriteProps {
  appName: string
  available?: boolean
  requested?: boolean
  models: DataWrite[]
  username?: string
  database?: string
}

export function DataWriteSettings(props: DataWriteProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [available, setAvailable] = useState(props.available)
  const [requested, setRequested] = useState(props.requested)
  const [models, setModels] = useState(props.models)

  const handleRequestDataWrites = async () => {
    try {
      setIsLoading(true)
      const response = await post<DataWriteProps>(
        projectPath(`/apps/${props.appName?.toLowerCase()}/request-data-write`)
      )
      setAvailable(response.available)
      setRequested(response.requested)
      setModels(response.models)
      toast.success(`Activation requested successfully`, {
        description: `You will be notified when Data Write is available to use`
      })
    } catch ({ message, body }) {
      toast.error(`Error requesting activation`, {
        description: (body && body['error']) || message
      })
    } finally {
      setIsLoading(false)
    }
  }

  const enabledModels = () => models.filter((m) => m.enabled)

  const handleEnableModel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModels((models) =>
      models.map((m) => {
        return m.model == event.target.value ? { ...m, enabled: event.target.checked } : m
      })
    )
  }

  return (
    <Toggle
      defaultIsOpen={true}
      title={
        <HStack>
          <IconTable size={16} />
          <Heading size="sm" fontWeight={'semibold'}>
            Send data to {props.appName}
          </Heading>
        </HStack>
      }
    >
      <Stack as={LightBgCard} spacing={8} mt="2">
        <Toggle
          title={
            <Heading size="xs" fontWeight={'semibold'}>
              💡 How data is loaded into {props.appName}:
            </Heading>
          }
        >
          <Stack spacing={2} fontSize={'sm'} borderLeftWidth="3px" pt="2" pl="8" px="4" ml="10px">
            <UnorderedList spacing="2">
              <ListItem>
                <b>Overview:</b> an automated replication process that send data from your Koala's workspace to
                respective tables in your {props.appName} instance.
              </ListItem>
              {props.appName == 'Snowflake' && (
                <ListItem>
                  <b>Frequency</b>: the sync process runs two times per day, at 9:30am and 9:30pm (UTC). After enabling
                  a model, it will be loaded on the next sync window mentioned.
                </ListItem>
              )}
              {props.appName == 'BigQuery' && (
                <ListItem>
                  <b>Frequency</b>: the sync process runs two times per day, at 10:00am and 10:00pm (UTC). After
                  enabling a model, it will be loaded on the next sync window mentioned.
                </ListItem>
              )}
              <ListItem>
                <b>Strategy</b>: we offer two strategies: <Code>full</Code>: copy the whole data each time and{' '}
                <Code>incremental</Code>: copy just the data generated between sync windows.
              </ListItem>
              <ListItem>
                <b>Costs</b>: Koala will perform a full data dump based on the models you select. The initial data load
                will likely incur {props.appName} credits. You can verify the size of the initial data dump of each
                model in order to estimate the initial data loading costs.
              </ListItem>
              {props.appName == 'Snowflake' && (
                <ListItem>
                  <b>Requirements</b>: this process uses the{' '}
                  <Link href="https://docs.snowflake.com/en/user-guide/data-load-s3" target="_blank">
                    Bulk loading from Amazon S3
                  </Link>{' '}
                  strategy. So, please make sure that user <Code>{props.username}</Code> have granted privileges on{' '}
                  <Code>{props.database}</Code> to:
                  <br />- <Code>CREATE SCHEMA</Code>
                  <br />- <Code>CREATE | ALTER | DROP TABLE</Code> (on the schema)
                  <br />- <Code>CREATE STAGE</Code>
                  <br />- <Code>COPY INTO TABLE</Code> (from stage)
                </ListItem>
              )}
              {props.appName == 'BigQuery' && (
                <ListItem>
                  <b>Requirements</b>: this process uses the{' '}
                  <Link href="https://cloud.google.com/bigquery/docs/s3-transfer" target="_blank">
                    Amazon S3 transfer
                  </Link>{' '}
                  strategy. So, please make sure that user <Code>{props.username}</Code> have the following permissions:
                  <br />- <Code>bigquery.datasets.get</Code> (to manage target dataset)
                  <br />- <Code>bigquery.datasets.update</Code> (to manage target dataset)
                  <br />- <Code>bigquery.transfers.get</Code> (to manage data transfers)
                  <br />- <Code>bigquery.transfers.update</Code> (to manage data transfers)
                </ListItem>
              )}
            </UnorderedList>
          </Stack>
        </Toggle>

        <Stack spacing={8} divider={<Divider />}>
          {!available && (
            <>
              {requested ? (
                <p>
                  Your request has been received. We are configuring the connection between your Koala data and
                  {props.appName} instance. A confirmation notification will be sent within the next few hours.
                </p>
              ) : (
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="blue"
                  onClick={handleRequestDataWrites}
                  isLoading={isLoading}
                  loadingText="Requesting..."
                  leftIcon={<IconLockCheck size={16} />}
                >
                  Request Activation
                </Button>
              )}
            </>
          )}

          {available && (
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>
                      <b>Enabled</b>
                    </Th>
                    <Th>
                      <b>Data Model</b>
                    </Th>
                    <Th>
                      <b>Last sync</b>
                    </Th>
                    <Th>
                      <b>Stats</b>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {models.map((dataWrite) => (
                    <DataWriteModel
                      key={dataWrite.model}
                      appName={props.appName}
                      model={dataWrite}
                      onChange={handleEnableModel}
                    />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Stack>
        {enabledModels().length === 0 && (
          <input type="hidden" name={`app_instance_settings[data_writes][]`} value={''} />
        )}
      </Stack>
    </Toggle>
  )
}

function DataWriteModel(props: {
  appName: string
  model: DataWrite
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) {
  const shouldFetchSync = (status: any): boolean => {
    return status == 'running' || status == 'paused'
  }

  const [model, setModel] = useState<DataWrite>(props.model)
  const [isRunning, setIsRunning] = useState(shouldFetchSync(props.model?.last_sync?.status))
  const [tick, setTick] = useState(nanoid())


  const fetchSyncStatus = async () => {
    try {
      const dataWrite = await concurrentGET<DataWrite>(
        projectPath(`/apps/${props.appName?.toLowerCase()}/data-write?model=${props.model.model}`)
      )
      setModel(dataWrite)
      setIsRunning(shouldFetchSync(dataWrite.last_sync?.status))

      if (isRunning) {
        setTimeout(() => {
          setTick(nanoid())
        }, 5000)
      }
    } catch ({ message, body }) {
    }
  }

  useEffect(() => {
    if (isRunning) {
      fetchSyncStatus()
    }
  }, [isRunning, tick])

  return (
    <Tr>
      <Td>
        <FormControl flex="1" gap="2">
          <Switch
            isChecked={props.model.enabled}
            onChange={props.onChange}
            colorScheme="purple"
            name={`app_instance_settings[data_writes][]`}
            value={model.model}
          />
        </FormControl>
      </Td>
      <Td>
        <Stack spacing={0.5} flex="1" alignItems="flex-start" isTruncated>
          <Flex alignItems="center" maxW="100%" gap={1.5}>
            <TextEllipsis fontWeight="medium" maxW="100%" tooltip>
              {model.title}
            </TextEllipsis>
          </Flex>

          <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
            Type: {model.sync_type}, size: {model.size}
          </TextEllipsis>
        </Stack>
      </Td>
      <Td>
        {model.last_sync ? (
          <>
            <SyncStatusIcon status={model.last_sync.status} />
            {' '}{model.last_sync?.status}
            <br />
            <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
              <TimeAgo time={model.last_sync?.started_at} />
            </TextEllipsis>
          </>
        ) : props.model.enabled && (
          <>
            <SyncStatusIcon status={'scheduled'} />
            {' '}scheduled
            <br />
            <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
              sync will start after save
            </TextEllipsis>
          </>
        )}
      </Td>
      <Td>
        {model.last_sync && (
          <>
            Loaded: {friendlyNumber(model.last_sync?.synced_count)}
            <br />
            <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
              Skipped: {friendlyNumber(model.last_sync?.skipped_count)}, Errors:{' '}
              {friendlyNumber(model.last_sync?.failed_count)}
            </TextEllipsis>
          </>
        )}
      </Td>
    </Tr>
  )
}

function SyncStatusIcon(props: {
  status: string
}) {
  switch (props.status) {
    case 'running':
      return <Spinner colorScheme="purple" size="sm" verticalAlign={'top'} />
    case 'finished':
      return <CheckboxCircleIcon color="green.500" boxSize={5} verticalAlign={'top'} />
    case 'stopped':
      return <Icon as={IconCircleXFilled} color="red.500" boxSize={5} verticalAlign={'top'} />
    case 'paused':
      return <Icon as={IconPlayerPause} color="yellow.500" boxSize={5} verticalAlign={'top'} />
    case 'failed':
      return <Icon as={IconAlertTriangle} color="red.500" boxSize={5} verticalAlign={'top'} />
    case 'scheduled':
      return <Icon as={IconCalendar} color="blue.500" boxSize={5} verticalAlign={'top'} />
    default:
      return null
  }
}
