import { Box, Button, ButtonGroup, Heading, HStack, Icon, IconButton, Stack, Text } from '@chakra-ui/react'
import { keyframes } from '@emotion/react'
import {
  IconBrowser,
  IconCheck,
  IconCopy,
  IconFileTypePdf,
  IconPlus,
  IconSearch,
  IconChevronLeft,
  IconChevronRight
} from '@tabler/icons-react'
import Autolinker from 'autolinker'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'
import { ToolUsage } from '../../data/use-ai-recommendations'
import { Toggle } from '../../pages/accounts/components/Toggle'
import { DetailsCard } from '../Card'
import { EmailProps } from '../EmailComposer'
import { ThumbsDownIcon, ThumbsUpIcon } from '../icons'
import MarkdownText from '../MarkdownText'
import { TextEllipsis } from '../text-ellipsis'
import { useHover } from '../use-hover'
import { useCopyToClipboard } from '../useCopyToClipboard'

interface EmailSuggestionsProps {
  itemId: string
  suggestedEmails: EmailProps[]
  onReplaceEmail: (email: EmailProps) => void
  onSelectEmail: (email: EmailProps, feedbackType: string) => void
  mode?: 'vertical' | 'horizontal'
  skipHeader?: boolean
}

const pulseKeyframes = keyframes`
  0% { background-position: 100% 50% }
  50% { background-position: 0% 50% }
  100% { background-position: -100% 50% }
`

const PulsingText = ({ children }: { children: React.ReactNode }) => (
  <Text
    as="span"
    background="linear-gradient(90deg, var(--chakra-colors-gray-400) 0%, var(--chakra-colors-gray-700) 20%, var(--chakra-colors-gray-400) 40%, var(--chakra-colors-gray-400) 100%)"
    backgroundSize="300% 100%"
    animation={`${pulseKeyframes} 3s ease infinite`}
    backgroundClip="text"
    sx={{
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      display: 'inline-block'
    }}
  >
    {children}
  </Text>
)

export function EmailStrategy({ strategy, isLoading }: { strategy: string; isLoading: boolean }) {
  const streamRef = useRef<HTMLDivElement>(null)
  const [hoverRef, isHovered] = useHover()
  const hoveredOnce = useRef(false)

  useEffect(() => {
    if (hoveredOnce.current) {
      return
    }

    // Do not scroll if the user is hovering over the strategy
    if (isHovered && streamRef.current) {
      hoveredOnce.current = true
      return
    }

    if (streamRef.current) {
      streamRef.current.scrollTop = streamRef.current.scrollHeight
    }
  }, [strategy, isHovered])

  return (
    <Toggle
      defaultIsOpen={true}
      title={
        <Heading size="xs" fontWeight={isLoading ? 'semibold' : 'normal'}>
          {isLoading ? <PulsingText>Planning...</PulsingText> : 'Email strategy'}
        </Heading>
      }
    >
      <Box ref={hoverRef as any}>
        <Stack
          borderLeftWidth="4px"
          borderColor="gray.200"
          ml={2}
          px={3}
          py={2.5}
          maxH="240px"
          overflow="auto"
          ref={streamRef}
          fontSize="xs"
          color="gray.500"
        >
          <MarkdownText>{strategy.replace('<email_strategy>', '').replace('</email_strategy>', '')}</MarkdownText>
        </Stack>
      </Box>
    </Toggle>
  )
}

export function ResearchResults({
  researchResults,
  isLoading
}: {
  researchResults: Array<{ question: string; short_answer: string }>
  isLoading: boolean
}) {
  const streamRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (streamRef.current) {
      streamRef.current.scrollTop = streamRef.current.scrollHeight
    }
  }, [researchResults])

  return (
    <Toggle
      defaultIsOpen={false}
      title={
        <Heading size="xs" fontWeight={isLoading ? 'semibold' : 'normal'}>
          {isLoading ? <PulsingText>Researching...</PulsingText> : 'Research'}
        </Heading>
      }
    >
      <Stack
        borderLeftWidth="4px"
        borderColor="gray.200"
        ml={2}
        px={3}
        py={2.5}
        maxH="200px"
        overflow="auto"
        ref={streamRef}
        fontSize="xs"
        color="gray.500"
        spacing={4}
      >
        {researchResults.map((result) => (
          <Stack key={result.question} spacing={1}>
            <Text fontWeight="semibold">{result.question}</Text>
            <Text>{result.short_answer}</Text>
          </Stack>
        ))}
      </Stack>
    </Toggle>
  )
}

export function ToolStream({
  toolStream,
  isLoading,
  mostRecentTool
}: {
  toolStream: ToolUsage[]
  isLoading: boolean
  mostRecentTool: ToolUsage
}) {
  return (
    <Toggle
      defaultIsOpen={false}
      title={
        <HStack w="100%">
          <Heading size="xs" fontWeight={isLoading ? 'semibold' : 'normal'}>
            {isLoading ? (
              <PulsingText>
                {mostRecentTool?.tool_name !== 'search_engine' ? 'Reading...' : 'Researching...'}
              </PulsingText>
            ) : (
              'Research'
            )}
          </Heading>
          {isLoading && mostRecentTool && (
            <HStack spacing={0.5}>
              {mostRecentTool?.tool_name === 'search_engine' && <Icon as={IconSearch} boxSize={3} />}
              {mostRecentTool?.tool_name === 'web_scraper' && <Icon as={IconBrowser} boxSize={3} />}
              {mostRecentTool?.tool_name === 'pdf_reader' && <Icon as={IconFileTypePdf} boxSize={3} />}
              <TextEllipsis maxW="150px" color="gray.500" fontSize="xs">
                {JSON.stringify(mostRecentTool?.tool_args)}
              </TextEllipsis>
            </HStack>
          )}
        </HStack>
      }
    >
      <Stack maxH="200px" overflow="auto" borderLeftWidth="4px" borderColor="gray.200" ml={2} pl={3} py={2}>
        <Stack
          maxH="200px"
          spacing={2}
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'var(--chakra-colors-gray-300)',
              borderRadius: '24px'
            }
          }}
        >
          {toolStream.map((tool) => (
            <HStack key={JSON.stringify(tool.content)} fontSize="xs">
              {tool.tool_name === 'search_engine' && <Icon as={IconSearch} boxSize={3} />}
              {tool.tool_name === 'web_scraper' && <Icon as={IconBrowser} boxSize={3} />}
              {tool.tool_name === 'pdf_reader' && <Icon as={IconFileTypePdf} boxSize={3} />}

              {tool.tool_name !== 'search_engine' && (
                <Text
                  sx={{
                    '.url-description': {
                      textDecoration: 'underline',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      flex: 1,
                      maxWidth: '100%'
                    }
                  }}
                  isTruncated
                  dangerouslySetInnerHTML={{
                    __html: Autolinker.link(String(tool.tool_args), {
                      truncate: { length: 64, location: 'end' },
                      newWindow: true,
                      sanitizeHtml: true,
                      stripPrefix: false,
                      stripTrailingSlash: true,
                      email: false,
                      phone: false,
                      mention: false,
                      hashtag: false,
                      urls: {
                        ipV4Matches: false,
                        tldMatches: true,
                        schemeMatches: true
                      },
                      className: 'url-description'
                    })
                  }}
                />
              )}
              {tool.tool_name === 'search_engine' && (
                <TextEllipsis maxW="80%" color="gray.500">
                  {tool.tool_args}
                </TextEllipsis>
              )}
            </HStack>
          ))}
        </Stack>
      </Stack>
    </Toggle>
  )
}

export function EmailSuggestions({
  suggestedEmails,
  onReplaceEmail,
  onSelectEmail,
  mode = 'vertical',
  skipHeader = false
}: EmailSuggestionsProps) {
  const { copy, copied } = useCopyToClipboard()
  const [copiedEmail, setCopiedEmail] = useState<string>()
  const [thumbedUp, setThumbedUp] = useState<string[]>([])
  const [thumbedDown, setThumbedDown] = useState<string[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (copied) {
      toast.success('Email copied to clipboard')
      setTimeout(() => {
        setCopiedEmail(undefined)
      }, 1500)
    }
  }, [copied])

  const onThumbUp = (email: EmailProps) => {
    setThumbedUp((thumbedUp) => [...thumbedUp, email.body || ''])
    setThumbedDown((thumbedDown) => [...thumbedDown.filter((body) => body !== email.body)])

    window.ko?.track('Composer Positive Feedback', {
      email: email.body,
      subject: email.subject,
      feedback: 'good'
    })

    onSelectEmail(email, 'positive')
  }

  const onThumbDown = (email: EmailProps) => {
    setThumbedDown((thumbedDown) => [...thumbedDown, email.body || ''])
    setThumbedUp((thumbedUp) => [...thumbedUp.filter((body) => body !== email.body)])

    window.ko?.track('Composer Negative Feedback', {
      email: email.body,
      subject: email.subject,
      feedback: 'bad'
    })

    onSelectEmail(email, 'negative')
  }

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % suggestedEmails.length)
  }

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev - 1 + suggestedEmails.length) % suggestedEmails.length)
  }

  const renderEmailCard = (email: EmailProps) => (
    <DetailsCard
      key={JSON.stringify(email.subject)}
      borderColor="gray.200"
      mb={4}
      cursor="pointer"
      _hover={{
        borderColor: 'purple.500'
      }}
    >
      <Heading size="xs" fontWeight="semibold">
        {email.subject}
      </Heading>
      <Box>
        <Text fontSize={'sm'} whiteSpace="pre-wrap">
          {(email.body || '').replace(/\n/, '\n\n').replace(/\n{2,}/, '  \n  \n')}
        </Text>
      </Box>
      <ButtonGroup justifyContent="space-between">
        <Button
          size="xs"
          colorScheme="purple"
          leftIcon={<Icon as={IconPlus} boxSize={3.5} />}
          pr="2"
          onClick={() => onReplaceEmail(email)}
        >
          Insert
        </Button>
        <HStack spacing={1}>
          <IconButton
            size="xs"
            variant="ghost"
            icon={<Icon as={copiedEmail === email.body ? IconCheck : IconCopy} boxSize={3.5} />}
            aria-label="Copy"
            onClick={() => {
              copy(email.body || '')
              setCopiedEmail(email.body || '')
            }}
          />
          <IconButton
            size="xs"
            variant="ghost"
            icon={<ThumbsUpIcon boxSize={3.5} />}
            isActive={thumbedUp.includes(email.body || '')}
            aria-label="Looks good"
            onClick={() => onThumbUp(email)}
            colorScheme={thumbedUp.includes(email.body || '') ? 'purple' : 'gray'}
          />
          <IconButton
            size="xs"
            variant="ghost"
            icon={<ThumbsDownIcon boxSize={3.5} />}
            isActive={thumbedDown.includes(email.body || '')}
            aria-label="Looks bad"
            onClick={() => onThumbDown(email)}
            colorScheme={thumbedDown.includes(email.body || '') ? 'purple' : 'gray'}
          />
        </HStack>
      </ButtonGroup>
    </DetailsCard>
  )

  return (
    <Stack>
      {!skipHeader && suggestedEmails.length > 0 && (
        <Heading size="xs" fontWeight="semibold" mb={2}>
          Email Suggestions
        </Heading>
      )}

      {mode === 'horizontal' ? (
        <Box position="relative">
          <Box opacity={0.9}>{renderEmailCard(suggestedEmails[currentIndex])}</Box>
          {suggestedEmails.length > 1 && (
            <HStack justify="center" mt={2} spacing={2}>
              <IconButton
                size="xs"
                variant="ghost"
                icon={<Icon as={IconChevronLeft} boxSize={4} />}
                aria-label="Previous email"
                onClick={handlePrevious}
                colorScheme="purple"
              />
              <HStack spacing={1}>
                {suggestedEmails.map((_, index) => (
                  <Box
                    key={index}
                    w={2}
                    h={2}
                    borderRadius="full"
                    bg={index === currentIndex ? 'purple.500' : 'gray.200'}
                    transition="background-color 0.2s"
                  />
                ))}
              </HStack>
              <IconButton
                size="xs"
                variant="ghost"
                icon={<Icon as={IconChevronRight} boxSize={4} />}
                aria-label="Next email"
                onClick={handleNext}
                colorScheme="purple"
              />
            </HStack>
          )}
        </Box>
      ) : (
        <Stack>{(suggestedEmails || []).map((email) => renderEmailCard(email))}</Stack>
      )}
    </Stack>
  )
}
