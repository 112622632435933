import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { AccountView } from '../../types/AccountView'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export type SpaceView = Pick<AccountView, 'id' | 'name' | 'slug' | 'kind' | 'project_id' | 'permalink'>

interface Data {
  views: SpaceView[]
}

interface Props {
  // you can seed the query, but it's not required
  // this will allow us to have immediate data but allow refetching
  views?: SpaceView[]
  spaceId?: string | null
}

export function useSpaceViews(props: Props = {}) {
  const project = useCurrentProject()
  const path = projectPath(`/spaces/${props.spaceId}/views.json`)

  return useQuery<Data>({
    queryKey: ['space-views', { projectId: project?.id, spaceId: props.spaceId }],
    queryFn: () => concurrentGET<Data>(path),
    enabled: Boolean(project?.id),
    initialData: () => {
      return props.views ? { views: props.views } : undefined
    }
  })
}
