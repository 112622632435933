import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { AutoOutboundRule } from '../../types/AutoOutbound'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface ResponseData {
  outreach_sequence_stats: AutoOutboundRule['outreach_sequence_stats']
}

export function useOutboundSequenceStats(ruleId: string) {
  const project = useCurrentProject()

  return useQuery({
    queryKey: ['outboundSequenceStats', { projectId: project?.id, ruleId }],
    queryFn: () => concurrentGET<ResponseData>(projectPath(`/auto-outbound/${ruleId}/sequence-stats`)),
    enabled: Boolean(project?.id && ruleId)
  })
}
