import { Box, Button, Flex, HStack, Link } from '@chakra-ui/react'
import { IconLogout } from '@tabler/icons-react'
import React from 'react'
import { Crm } from '@type/Crm'
import { Project } from '@type/Project'
import PageLayout from '@ui/PageLayout'
import PageTitle from '@ui/PageTitle'
import { TopNavBox } from '@ui/TopNav'
import useLocation from '@ui/useLocation'
import { Plan } from '../billing/show'
import { CreateProject } from './components/CreateProject'
import { IntegrationButtonProps } from './components/IntegrationButton'
import { SetupProject } from './components/SetupProject'
import { createFlow, OnboardingFlow, InitialStepFn } from './components/OnboardingFlow'
import { useCurrentUser } from '@ui/UserContext'

export interface OnboardingCheck {
  name: string
  description: string
  required: boolean
  href: string
  skipped: boolean
  complete: boolean
  progress: [number, number]
  value: any
}

export type ChecksRecord = Record<string, OnboardingCheck>

type Proj = Pick<Project, 'name' | 'slug' | 'domain' | 'potential_domain' | 'member_count'>

interface Props {
  crm?: Crm
  sync_status?: {
    total: number
    synced: number
    percent: number
    last_synced: string
  }
  catalog: Record<'salesforce' | 'hubspot' | 'slack', IntegrationButtonProps>
  project?: Pick<Project, 'id' | 'name' | 'slug'>
  members?: number
  authorized_projects?: Proj[]
  existing_projects?: Proj[]
  suggested_authorized_domain?: string
  referral_code?: string
  onboarding_checks: ChecksRecord
  errors?: {
    [key: string]: string[]
  }

  selected_plan: Plan
}

export type StepType =
  | 'goals_set'
  | 'crm_connected'
  | 'pixel_installed'
  | 'clearbit_connected'
  | 'signals_created'
  | 'slack_connected'
  | 'done'

function StepItem(props: { completed?: boolean; onClick?: () => void }) {
  return (
    <Box
      w="6"
      h="5px"
      bg={props.completed ? 'purple.500' : 'gray.200'}
      rounded="full"
      onClick={props.onClick}
      cursor={props.onClick ? 'pointer' : 'default'}
    />
  )
}

export default function Show(props: Props) {
  const location = useLocation()
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search])
  const user = useCurrentUser()

  const currentFlow = React.useMemo<OnboardingFlow>(() => {
    const stepSequence: StepType[] = [
      'crm_connected',
      'pixel_installed',
      'clearbit_connected',
      'signals_created',
      'slack_connected',
      'done'
    ]

    // Define initial step logic
    const initialStepFn: InitialStepFn = (checks: ChecksRecord) => {
      const crmConnected = checks.crm_connected
      const pixelInstalled = checks.pixel_installed
      const clearbitConnected = checks.clearbit_connected
      const signalsCreated = checks.signals_created
      const slackConnected = checks.slack_connected

      if (!crmConnected?.skipped && !crmConnected?.complete) {
        return 'crm_connected'
      }

      if (!pixelInstalled?.skipped && !pixelInstalled?.complete) {
        return 'pixel_installed'
      }

      if (!clearbitConnected?.complete && !slackConnected?.complete && !signalsCreated?.complete) {
        return 'clearbit_connected'
      }

      if (!signalsCreated?.complete) {
        return 'signals_created'
      }

      if (!slackConnected?.complete) {
        return 'slack_connected'
      }

      return 'done'
    }

    // Create the flow with all context directly
    return createFlow(stepSequence, initialStepFn, params, props.onboarding_checks, location.pathname)
  }, [params, props.onboarding_checks, location.pathname])

  const nextFlow = React.useMemo<OnboardingFlow>(() => {
    const stepSequence: StepType[] = ['goals_set', 'crm_connected', 'done']

    const initialStepFn: InitialStepFn = (checks: ChecksRecord) => {
      const crmConnected = checks.crm_connected
      const goalsSet = checks.goals_set

      if (!goalsSet?.skipped && !goalsSet?.complete) {
        return 'goals_set'
      }

      if (!crmConnected?.skipped && !crmConnected?.complete) {
        return 'crm_connected'
      }

      return 'done'
    }

    // Create the flow with all context directly
    return createFlow(stepSequence, initialStepFn, params, props.onboarding_checks, location.pathname)
  }, [params, props.onboarding_checks, location.pathname])

  const flow = user.featureEnabled('newOnboarding') ? nextFlow : currentFlow

  const selectedStepIndex = flow.selectedStepIndex()
  const currentStep = flow.currentStep()

  return (
    <>
      <TopNavBox
        // hideLogo={step === 'done'}
        boxProps={{ bg: 'white' }}
        links={
          <>
            <Flex position="absolute" right="50%" transform={'translateX(50%)'}>
              {selectedStepIndex !== undefined && currentStep !== 'done' && (
                <HStack>
                  {flow.steps().map((name, i) => (
                    <StepItem
                      key={i}
                      completed={i <= selectedStepIndex}
                      onClick={
                        i <= selectedStepIndex
                          ? () => {
                              flow.goTo(name as StepType)
                            }
                          : undefined
                      }
                    />
                  ))}
                </HStack>
              )}
            </Flex>
          </>
        }
        rightContent={
          <>
            {currentStep !== 'done' && (
              <Button
                as={Link}
                href="/logout"
                onClick={() => {
                  window.ko?.reset()
                }}
                variant="outline"
                size="xs"
                flex="none"
                rightIcon={<IconLogout size="14" />}
              >
                Log out
              </Button>
            )}
          </>
        }
      />
      <PageLayout
        size="full"
        background={'gray.100'}
        css={`
          --max-container-width: 100vw;
        `}
      >
        <PageTitle skipRendering>Welcome</PageTitle>
        {props.project?.id ? (
          <SetupProject {...props} flow={flow} />
        ) : (
          <CreateProject {...props} showLogo={!props.existing_projects?.length} />
        )}
      </PageLayout>
    </>
  )
}
