import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import { Company } from '../../../../types/Profile'

interface Variables {
  domain: string
}

interface Res {
  company: Company
}

export function useUnpublish() {
  const onSuccess = React.useCallback(() => {
    toast.success('This company was successfully removed from public KDB.')
  }, [])

  const onError = React.useCallback(() => {
    toast.error('There was an issue unlisting this company.')
  }, [])

  return useMutation<Res, unknown, Variables>({
    mutationFn: ({ domain }) => post(`/admin/kdb/${domain}/unpublish`),
    onSuccess,
    onError
  })
}
