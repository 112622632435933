import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Stack
} from '@chakra-ui/react'
import { IconAlertCircle, IconCheck } from '@tabler/icons-react'
import dayjs from 'dayjs'
import orderBy from 'lodash/orderBy'
import React, { useMemo } from 'react'
import { ListSync, StaticList } from '../../../../types/StaticList'
import { Sync } from './Sync'

interface Props {
  list: StaticList
  syncs: ListSync[]
  onUpdated: (sync: ListSync) => void
  buttonProps?: ButtonProps
}

export function ListSyncs(props: Props) {
  const syncs = useMemo(() => orderBy(props.syncs, (s) => dayjs(s.created_at).unix(), 'desc'), [props.syncs])

  const hasSyncs = useMemo(() => syncs.length > 0, [syncs])
  const hasIncompleteSyncs = useMemo(() => syncs.some((sync) => sync.status === 'incomplete'), [syncs])
  const hasUnfinishedSyncs = useMemo(() => syncs.some((sync) => sync.status !== 'completed'), [syncs])

  if (!hasSyncs) {
    return null
  }

  return (
    <Popover placement="left" isLazy lazyBehavior="keepMounted">
      <PopoverTrigger>
        <Button
          aria-label="Sync Status"
          leftIcon={
            hasIncompleteSyncs ? (
              <IconAlertCircle size={16} />
            ) : hasUnfinishedSyncs ? (
              <Spinner size="sm" />
            ) : (
              <IconCheck size={16} />
            )
          }
          size="sm"
          variant="outline"
          colorScheme={hasIncompleteSyncs ? 'orange' : hasUnfinishedSyncs ? 'gray' : 'green'}
          color={hasIncompleteSyncs ? 'orange.500' : hasUnfinishedSyncs ? 'gray.500' : undefined}
          {...props.buttonProps}
        >
          {!hasIncompleteSyncs && (hasUnfinishedSyncs ? 'Importing Data...' : 'Import Complete')}
          {hasIncompleteSyncs && 'Sync Incomplete'}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Heading size="xs" fontWeight="semibold" p="2" pt="2" pb="2">
            Import Status
          </Heading>
          <Stack
            spacing="4"
            p="2"
            divider={<Divider />}
            maxH="calc(70vh)"
            overflow={'auto'}
            overscrollBehavior="contain"
          >
            {syncs.map((sync) => (
              <Box key={sync.id}>
                <Sync
                  sync={sync}
                  onUpdated={(sync) => {
                    props.onUpdated(sync)
                  }}
                />
              </Box>
            ))}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
