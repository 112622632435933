import {
  Box,
  Card,
  Flex,
  Heading,
  Stack,
  Text,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TagLeftIcon,
  TagLabel,
  Link,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  HStack,
  Tag,
  TagCloseButton,
  useToast,
  Skeleton
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Breadcrumb } from '../../ui/Breadcrumb'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { JSONTree } from '../../ui/json-tree'
import { TimeAgo } from '../../ui/TimeAgo'
import { BubbleTag } from '../../ui/BubbleTag'
import { IconCheck, IconUserOff, IconUserSearch, IconZoom, IconSearch } from '@tabler/icons-react'
import { accountPath } from '../accounts/lib/account-path'
import { profilePath } from '../profiles/lib/path'
import DoubleAvatar from '../../ui/DoubleAvatar'
import { TableFooter } from '../../ui/TableFooter'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { get } from '../../../lib/api'

interface Props {
  play: any
  stats: Record<string, number>
  play_sources: any[]
  logs: Array<{
    id: string
    status: string
    created_at: string
    error_message?: string
    metadata: any
    play_item: {
      id: string
      record_type: string
      record_id: string
      record?: {
        id: string
        name?: string
        email?: string
        display_name?: string
      }
    }
  }>
  page_meta: {
    total_count: number
    current_page: number
    total_pages: number
    per_page: number
    next_page: number | null
    prev_page: number | null
    is_first_page: boolean
    is_last_page: boolean
  }
}

const playStatusColors = {
  pending: 'yellow',
  processing: 'purple',
  completed: 'green',
  qualified: 'blue',
  dismissed: 'red',
  no_longer_qualifies: 'gray',
  failed: 'red',
  snoozed: 'orange'
}

const playStatusLabels = {
  pending: 'Pending',
  processing: 'Processing',
  completed: 'Completed',
  qualified: 'Qualified',
  dismissed: 'Dismissed',
  no_longer_qualifies: 'No Longer Qualifies',
  failed: 'Failed',
  snoozed: 'Snoozed'
}

const logStatusColors = {
  entry: 'blue',
  're-entry': 'purple',
  exit: 'gray',
  failed: 'red'
}

const logStatusLabels = {
  entry: 'Entry',
  're-entry': 'Re-entry',
  exit: 'Exit',
  failed: 'Failed'
}

const playStatuses = [
  'pending',
  'processing',
  'completed',
  'qualified',
  'dismissed',
  'no_longer_qualifies',
  'failed',
  'snoozed'
]

const logStatuses = ['entry', 're-entry', 'exit', 'failed']

const getPlayStatusBadge = (status: string) => {
  switch (status) {
    case 'completed':
    case 'qualified':
      return (
        <BubbleTag colorScheme="green" variant="subtle">
          <TagLeftIcon as={IconCheck} boxSize={3} />
          <TagLabel>{playStatusLabels[status]}</TagLabel>
        </BubbleTag>
      )
    case 'failed':
    case 'dismissed':
      return (
        <BubbleTag colorScheme="red" variant="subtle">
          <TagLeftIcon as={IconUserOff} boxSize={3} />
          <TagLabel>{playStatusLabels[status]}</TagLabel>
        </BubbleTag>
      )
    case 'processing':
    case 'pending':
      return (
        <BubbleTag colorScheme="purple" variant="subtle">
          <TagLeftIcon as={IconUserSearch} boxSize={3} />
          <TagLabel>{playStatusLabels[status]}</TagLabel>
        </BubbleTag>
      )
    case 'no_longer_qualifies':
      return (
        <BubbleTag colorScheme="gray" variant="subtle">
          <TagLabel>{playStatusLabels[status]}</TagLabel>
        </BubbleTag>
      )
    case 'snoozed':
      return (
        <BubbleTag colorScheme="orange" variant="subtle">
          <TagLabel>Snoozed</TagLabel>
        </BubbleTag>
      )
    default:
      return (
        <BubbleTag colorScheme={playStatusColors[status] || 'gray'} variant="subtle">
          <TagLabel>{playStatusLabels[status] || status.toUpperCase()}</TagLabel>
        </BubbleTag>
      )
  }
}

const getLogStatusBadge = (status: string) => {
  switch (status) {
    case 'entry':
      return (
        <BubbleTag colorScheme="blue" variant="subtle">
          <TagLabel>Entry</TagLabel>
        </BubbleTag>
      )
    case 'exit':
      return (
        <BubbleTag colorScheme="gray" variant="subtle">
          <TagLabel>Exit</TagLabel>
        </BubbleTag>
      )
    case 're-entry':
      return (
        <BubbleTag colorScheme="purple" variant="subtle">
          <TagLabel>Re-entry</TagLabel>
        </BubbleTag>
      )
    case 'failed':
      return (
        <BubbleTag colorScheme="red" variant="subtle">
          <TagLeftIcon as={IconUserOff} boxSize={3} />
          <TagLabel>Failed</TagLabel>
        </BubbleTag>
      )
    default:
      return (
        <BubbleTag colorScheme={logStatusColors[status] || 'gray'} variant="subtle">
          <TagLabel>{logStatusLabels[status] || status.toUpperCase()}</TagLabel>
        </BubbleTag>
      )
  }
}

const getRecordDisplay = (record_type: string, record: any) => {
  if (!record) return null

  if (record_type === 'Account') {
    const domain = record.domain || record.company?.domain
    const name = record.name || record.company?.name || domain || 'Unknown Company'

    return (
      <Flex alignItems="center" gap={2}>
        <Link href={accountPath({ company: { domain } })} isExternal>
          <Flex alignItems="center" gap={2}>
            <CompanyAvatar size="sm" domain={domain} name={name} />
            <Text fontSize="sm" fontWeight="medium">
              {name}
            </Text>
          </Flex>
        </Link>
      </Flex>
    )
  }

  // For profiles
  return (
    <Flex alignItems="center" gap={2}>
      <Link href={profilePath(record)} isExternal>
        <Flex alignItems="center" gap={2}>
          <DoubleAvatar
            domain={record.company?.domain}
            companyName={record.company?.name}
            name={record.name || record.full_name || record.display_name}
            email={record.email}
            src={record.image}
          />
          <Stack spacing={0.5}>
            <Text fontSize="sm" fontWeight="medium">
              {record.name || record.full_name || record.display_name || record.email || 'Anonymous'}
            </Text>
            {record.company?.name && (
              <Text fontSize="13px" color="gray.500">
                {record.company.name}
              </Text>
            )}
          </Stack>
        </Flex>
      </Link>
    </Flex>
  )
}

interface LogsTableProps {
  _play: any
  initialLogs: Array<{
    id: string
    status: string
    created_at: string
    error_message?: string
    metadata: any
    play_item: {
      id: string
      record_type: string
      record_id: string
      record?: {
        id: string
        name?: string
        email?: string
        display_name?: string
      }
    }
  }>
  initialPageMeta: {
    total_count: number
    current_page: number
    total_pages: number
    per_page: number
    next_page: number | null
    prev_page: number | null
    is_first_page: boolean
    is_last_page: boolean
  }
}

const LogsTable = ({ _play, initialLogs, initialPageMeta }: LogsTableProps) => {
  const [logs, setLogs] = useState(initialLogs)
  const [pageMeta, setPageMeta] = useState(initialPageMeta)
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedMetadata, setSelectedMetadata] = React.useState<any>(null)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [statusFilter, setStatusFilter] = React.useState('')
  const toast = useToast()

  const fetchLogs = async (page: number, statusOverride?: string, searchQueryOverride?: string) => {
    setIsLoading(true)
    try {
      const url = new URL(window.location.toString())
      url.searchParams.set('page', page.toString())

      // Handle search query - use override if provided
      const searchQueryToUse = searchQueryOverride !== undefined ? searchQueryOverride : searchQuery
      if (searchQueryToUse) {
        url.searchParams.set('play_item_id', searchQueryToUse)
      } else {
        url.searchParams.delete('play_item_id')
      }

      // Handle status filter - use override if provided
      const statusToUse = statusOverride !== undefined ? statusOverride : statusFilter
      if (statusToUse) {
        url.searchParams.set('status', statusToUse)
      } else {
        url.searchParams.delete('status')
      }

      const response = await get<{
        logs: typeof logs
        page_meta: typeof initialPageMeta
      }>(url.pathname + url.search)

      setLogs(response.logs)
      setPageMeta(response.page_meta)
    } catch (error) {
      toast({
        title: 'Error loading logs',
        description: error instanceof Error ? error.message : 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleZoomClick = (metadata: any) => {
    setSelectedMetadata(metadata)
    onOpen()
  }

  const handleSearch = (query: string) => {
    setSearchQuery(query)
    fetchLogs(1)
  }

  const handleStatusChange = (status: string) => {
    setStatusFilter(status)
    fetchLogs(1, status)
  }

  const handleRemoveFilter = (type: 'play_item_id' | 'status') => {
    if (type === 'play_item_id') {
      setSearchQuery('')
      fetchLogs(1, undefined, '')
    } else if (type === 'status') {
      setStatusFilter('')
      fetchLogs(1, '')
    }
  }

  const handlePlayItemClick = (playItemId: string) => {
    setSearchQuery(playItemId)
    fetchLogs(1, undefined, playItemId)
  }

  const currentFilters = React.useMemo(() => {
    return {
      play_item_id: searchQuery,
      status: statusFilter
    }
  }, [searchQuery, statusFilter])

  return (
    <Card p={4}>
      <Stack spacing={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Recent Logs</Heading>
          <Badge colorScheme="gray" px={3} py={1} borderRadius="full" fontSize="sm">
            Total: {isLoading ? <Skeleton height="20px" width="40px" /> : pageMeta.total_count}
          </Badge>
        </Flex>
        <Stack spacing={2}>
          <Flex gap={4} alignItems="center">
            <InputGroup maxW="300px">
              <InputLeftElement pointerEvents="none">
                <IconSearch size={16} />
              </InputLeftElement>
              <Input
                placeholder="Search by Play Item ID"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch(searchQuery)
                  }
                }}
                size="sm"
                isDisabled={isLoading}
              />
            </InputGroup>
            <Select
              placeholder="Filter by Status"
              value={statusFilter}
              onChange={(e) => handleStatusChange(e.target.value)}
              size="sm"
              maxW="200px"
              isDisabled={isLoading}
            >
              {logStatuses.map((status) => (
                <option key={status} value={status}>
                  {logStatusLabels[status]}
                </option>
              ))}
            </Select>
          </Flex>
          <HStack spacing={2} wrap="wrap">
            {currentFilters.play_item_id && (
              <Tag size="sm" borderRadius="full" variant="subtle" colorScheme="blue">
                <TagLabel>Play Item ID: {currentFilters.play_item_id}</TagLabel>
                <TagCloseButton onClick={() => handleRemoveFilter('play_item_id')} isDisabled={isLoading} />
              </Tag>
            )}
            {currentFilters.status && (
              <Tag size="sm" borderRadius="full" variant="subtle" colorScheme="purple">
                <TagLabel>Status: {logStatusLabels[currentFilters.status]}</TagLabel>
                <TagCloseButton onClick={() => handleRemoveFilter('status')} isDisabled={isLoading} />
              </Tag>
            )}
          </HStack>
        </Stack>
        <Box overflowX="auto" position="relative">
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Time</Th>
                <Th>Status</Th>
                <Th>Record</Th>
                <Th>Play Item ID</Th>
                <Th>Metadata</Th>
              </Tr>
            </Thead>
            <Tbody>
              {logs.map((log) => (
                <Tr key={log.id}>
                  <Td>
                    <TimeAgo time={log.created_at} />
                  </Td>
                  <Td>{getLogStatusBadge(log.status)}</Td>
                  <Td minW="300px">{getRecordDisplay(log.play_item.record_type, log.play_item.record)}</Td>
                  <Td maxW="200px">
                    <Link
                      color="blue.500"
                      cursor="pointer"
                      onClick={() => handlePlayItemClick(log.play_item.id)}
                      _hover={{ textDecoration: 'underline' }}
                      display="block"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {log.play_item.id}
                    </Link>
                  </Td>
                  <Td>
                    <Flex alignItems="center" gap={2}>
                      <IconButton
                        aria-label="View metadata"
                        icon={<IconZoom size={16} />}
                        size="xs"
                        variant="ghost"
                        onClick={() =>
                          handleZoomClick({
                            ...log.metadata,
                            error_message: log.error_message
                          })
                        }
                      />
                      <Text fontSize="sm" color="gray.500">
                        {Object.keys(log.metadata || {}).length} fields
                        {log.error_message && ' • Error'}
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {isLoading && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg="whiteAlpha.800"
              display="flex"
              alignItems="center"
              justifyContent="center"
              zIndex={1}
            >
              <Stack spacing={4} align="center">
                <Skeleton height="20px" width="200px" />
                <Text fontSize="sm" color="gray.500">
                  Loading logs...
                </Text>
              </Stack>
            </Box>
          )}
        </Box>
        <Flex justifyContent="center" mt={4}>
          <TableFooter pageMeta={pageMeta} page={pageMeta.current_page} setPage={fetchLogs} isLoading={isLoading} />
        </Flex>
      </Stack>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Details</DrawerHeader>
          <DrawerBody>
            {selectedMetadata?.error_message && (
              <Box mb={4} p={4} bg="red.50" borderRadius="md">
                <Text color="red.700" fontWeight="medium">
                  Error Message
                </Text>
                <Text color="red.600">{selectedMetadata.error_message}</Text>
              </Box>
            )}
            <JSONTree data={selectedMetadata} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Card>
  )
}

export default function Debug(props: Props) {
  const { play, stats, play_sources, logs, page_meta } = props

  return (
    <PageLayout size="full">
      <Breadcrumb
        paths={[
          { path: projectPath('/plays'), title: 'Plays' },
          { path: projectPath(`/plays/${play.id}`), title: play.name },
          { path: window.location.toString(), title: 'Debug' }
        ]}
      />
      <PageTitle skipRendering>Play Debug</PageTitle>

      <Stack spacing={6}>
        {/* Play Info */}
        <Card p={4}>
          <Stack spacing={4}>
            <Flex alignItems="center" gap={3}>
              <Box>
                <Heading size="md">{play.name}</Heading>
                <Text color="gray.500">{play.description}</Text>
              </Box>
              <Link
                href={`/admin/change_logs?loggable_type=Play&loggable_id=${play.id}`}
                color="blue.500"
                ml="auto"
                cursor="pointer"
                _hover={{ textDecoration: 'underline' }}
              >
                View Change Logs
              </Link>
            </Flex>

            <Flex gap={2} alignItems="center" flexWrap="wrap">
              <Grid templateColumns="repeat(7, 1fr)" gap={2} width="100%">
                <GridItem>
                  <Box p={2} borderWidth="1px" borderRadius="md" height="full">
                    <Text
                      fontSize="xs"
                      color="gray.500"
                      mb={1}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      Total
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                      {Object.values(stats).reduce((a, b) => a + b, 0)}
                    </Text>
                  </Box>
                </GridItem>
                {playStatuses.map((status) => (
                  <GridItem key={status}>
                    <Box p={2} borderWidth="1px" borderRadius="md" height="full">
                      <Text
                        fontSize="xs"
                        color="gray.500"
                        mb={1}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {playStatusLabels[status]}
                      </Text>
                      <Text fontSize="lg" fontWeight="bold">
                        {stats[status] || 0}
                      </Text>
                    </Box>
                  </GridItem>
                ))}
              </Grid>
            </Flex>
          </Stack>
        </Card>

        {/* Play Sources */}
        <Card p={4}>
          <Stack spacing={4}>
            <Heading size="md">Play Sources</Heading>
            <Stack spacing={4}>
              {play_sources.map((source) => (
                <Box key={source.id} p={4} borderWidth="1px" rounded="md">
                  <Flex justifyContent="space-between" alignItems="center" mb={2}>
                    <Box>
                      <Text fontWeight="medium">{source.source_type}</Text>
                      <Text fontSize="sm" color="gray.500">
                        Last run: {source.last_run_at ? <TimeAgo time={source.last_run_at} /> : 'Never'}
                      </Text>
                    </Box>
                    <Flex alignItems="center" gap={3}>
                      {getPlayStatusBadge(source.status)}
                      <Link
                        href={`/admin/change_logs?loggable_type=PlaySource&loggable_id=${source.id}`}
                        color="blue.500"
                        cursor="pointer"
                        _hover={{ textDecoration: 'underline' }}
                        fontSize="sm"
                      >
                        View Changes
                      </Link>
                    </Flex>
                  </Flex>
                  <Grid templateColumns="repeat(8, 1fr)" gap={2} width="100%">
                    <GridItem>
                      <Box p={2} borderWidth="1px" borderRadius="md" height="full">
                        <Text
                          fontSize="xs"
                          color="gray.500"
                          mb={1}
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          Total
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          {source.total_items}
                        </Text>
                      </Box>
                    </GridItem>
                    {playStatuses.map((status) => (
                      <GridItem key={status}>
                        <Box p={2} borderWidth="1px" borderRadius="md" height="full">
                          <Text
                            fontSize="xs"
                            color="gray.500"
                            mb={1}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {playStatusLabels[status]}
                          </Text>
                          <Text fontSize="lg" fontWeight="bold">
                            {source.items_by_status[status] || 0}
                          </Text>
                        </Box>
                      </GridItem>
                    ))}
                  </Grid>
                  <Box mt={4}>
                    <Text fontSize="sm" color="gray.500" mb={2}>
                      Source Data
                    </Text>
                    <JSONTree data={source.source_data} />
                  </Box>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Card>

        {/* Play Item Logs */}
        <LogsTable _play={play} initialLogs={logs} initialPageMeta={page_meta} />
      </Stack>
    </PageLayout>
  )
}
