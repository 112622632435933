import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'

import { useCreateAIAgentMetric } from '@app/components/data/use-ai-agent-metrics'
import { AgentResponseDrawer } from '@app/components/ui/AISummaryCard'
import { useEntitlements } from '@app/components/ui/useEntitlements'
import { useSearchParams } from '@app/components/ui/useSearchState'
import { Account } from '@app/types/Account'
import {
  IconChevronDown,
  IconFileTextSpark,
  IconFlare,
  IconPlayerPlay,
  IconPlayerPlayFilled,
  IconPlayerTrackNext,
  IconPlus,
  IconSettings,
  IconUsers,
  IconX
} from '@tabler/icons-react'
import pMap from 'p-map'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { concurrentCachedGET, concurrentGET, expireCachedGET } from '../../../../lib/api'
import { AIAgent } from '../../../../types/AIAgent'
import { ProfileRecord } from '../../../../types/Profile'
import { expireAIAgentsCache, useAIAgents } from '../../../data/use-ai-agents'
import { AIStaleState } from '../../../ui/AIStaleState'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { BuildingIcon } from '../../../ui/icons'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useHover } from '../../../ui/use-hover'
import { humanize } from '../../accounts/facets/filter-cloud'
import { SearchBar } from '../../accounts/facets/search-bar'
import { AIAgentCTA } from './AIAgentCTA'
import { EditAgentDrawer, EditAgentModal } from './AIAgentForm'
import { agentMetricOrigin } from '@app/components/data/use-ai-agent-metrics'
import { usePlayItemAIRecommendationsStream } from '@app/components/data/use-ai-recommendations'
import { ResearchProgress } from './ResearchProgress'
import { CastAnswer } from './CastAnswer'

interface AIAgentColumnsProps {
  onChange?: (agent: AIAgent) => void
  onColumnChange?: (columns: string[]) => void
  selectedColumns?: string[]
  target: 'companies' | 'people'
  children?: React.ReactNode
  popoverProps?: PopoverProps
  width?: string
}

interface AgentCheckboxProps {
  agent: AIAgent
  isSelected: boolean
  onToggle: (agent: AIAgent) => void
}

export function AgentCheckbox({ agent, isSelected, onToggle }: AgentCheckboxProps) {
  const [ref, isHovered] = useHover()

  return (
    <Box
      ref={ref as any}
      px={1}
      mx={-1}
      __css={{
        '& .chakra-checkbox': { maxWidth: '100%', overflow: 'hidden' },
        '& .chakra-checkbox__label': { minWidth: '100px' }
      }}
    >
      <Checkbox isChecked={isSelected} onChange={() => onToggle(agent)} alignItems="flex-start">
        <Stack spacing={0.5}>
          <HStack spacing={1.5}>
            <Flex gap={2} alignItems="center">
              <TextEllipsis tooltip maxWidth="250px" lineHeight="1.2" fontWeight="medium">
                {agent.name}{' '}
              </TextEllipsis>
              <Icon
                as={agent.target === 'companies' ? BuildingIcon : IconUsers}
                boxSize={3.5}
                color={agent.target === 'companies' ? 'purple.500' : 'blue.500'}
              />
            </Flex>
            <HelpTooltip mode="popover" visibility={isHovered ? 'visible' : 'hidden'}>
              <Stack spacing={4}>
                <Stack spacing={0}>
                  <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="semibold">
                    Agent
                  </Text>
                  <Heading size="xs">{agent.name}</Heading>
                </Stack>

                <Stack spacing={0}>
                  <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="semibold">
                    Question
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {agent.question}
                  </Text>
                </Stack>

                <Stack spacing={0}>
                  <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="semibold">
                    Research Target
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {humanize(agent.target)}
                  </Text>
                </Stack>
              </Stack>
            </HelpTooltip>
          </HStack>
          <Text fontSize="xs" color="gray.500" noOfLines={2}>
            {agent.question}
          </Text>
        </Stack>
      </Checkbox>
    </Box>
  )
}

export function AIAgentColumns(props: AIAgentColumnsProps) {
  const { data: aiAgents, refetch: refetchAgents } = useAIAgents(props.target)
  const ctaModal = useDisclosure()
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedAgents, setSelectedAgents] = useState<string[]>(props.selectedColumns ?? [])
  const [newAgent, setNewAgent] = useState<AIAgent | null>(null)
  const newAgentModal = useDisclosure()
  const [isOpen, setIsOpen] = useState(false)
  const entitlements = useEntitlements()

  const agents = useMemo(() => {
    if (!aiAgents) return []

    const filteredAgents = searchQuery
      ? aiAgents.my_agents.filter(
          (agent) =>
            agent.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
            agent.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : aiAgents.my_agents

    return filteredAgents.sort((a, b) => {
      if (a.target === props.target) return -1
      if (b.target === props.target) return 1
      return 0
    })
  }, [aiAgents, searchQuery, props.target])

  useEffect(() => {
    setSelectedAgents(props.selectedColumns ?? [])
  }, [props.selectedColumns])

  return (
    <>
      <Popover
        isOpen={isOpen}
        onOpen={() => {
          if (!entitlements?.ai_agents_beta) {
            ctaModal.onOpen()
            return
          }
          setIsOpen(true)
        }}
        onClose={() => setIsOpen(false)}
        placement="bottom-start"
        isLazy
        lazyBehavior="keepMounted"
        closeOnBlur={!newAgent}
        {...props.popoverProps}
      >
        <PopoverTrigger>{props.children}</PopoverTrigger>
        <Portal>
          <PopoverContent width={props.popoverProps?.matchWidth ? 'inherit' : 'min(320px, 95vw)'} zIndex="modal">
            <PopoverArrow />
            <PopoverBody>
              <Flex height="100%" w="100%" flexDirection="column">
                <Flex gap={3} flexDirection="column">
                  <Flex justify="space-between" align="center">
                    <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="semibold">
                      AI Agent Columns
                    </Text>

                    <Button
                      size="xs"
                      variant="ghost"
                      as={Link}
                      href={projectPath('/settings/agents')}
                      isExternal
                      leftIcon={<Icon color="gray.500" as={IconSettings} size="16px" />}
                      iconSpacing={1}
                    >
                      Manage Agents
                    </Button>
                  </Flex>

                  <HStack w="100%">
                    <Flex w="100%">
                      <SearchBar
                        placeholder="Search your AI agents"
                        size="sm"
                        onChange={(value) => setSearchQuery(value)}
                        debounce={100}
                        inputProps={{ autoFocus: true, autoCapitalize: 'off', autoComplete: 'off' }}
                      />
                    </Flex>
                    <Tooltip label="Create new agent">
                      <Button
                        size="sm"
                        variant="outline"
                        leftIcon={<IconPlus size="16px" />}
                        minWidth="74px"
                        aria-label="Create new agent"
                        onClick={() => {
                          setNewAgent({ name: '', question: '', slug: '', target: props.target })
                          newAgentModal.onOpen()
                          setIsOpen(false)
                        }}
                      >
                        New
                      </Button>
                    </Tooltip>
                  </HStack>

                  <Stack height="100%" maxHeight="300px" overflow="auto" py="2" px={3} mx={-3} spacing={4}>
                    {agents.map((agent) => (
                      <div key={agent.id}>
                        <AgentCheckbox
                          agent={agent}
                          isSelected={selectedAgents.includes(agent.slug)}
                          onToggle={(agent) => {
                            setSelectedAgents((prev) => {
                              if (prev.includes(agent.slug)) {
                                return prev.filter((a) => a !== agent.slug)
                              }
                              return [...prev, agent.slug]
                            })
                          }}
                        />
                      </div>
                    ))}
                  </Stack>
                </Flex>

                <Flex justifyContent="flex-end" gap={2} pt={2} borderTop="1px solid" borderColor="gray.200">
                  <Button
                    size="sm"
                    colorScheme="purple"
                    onClick={() => {
                      setIsOpen(false)
                      props.onColumnChange?.(selectedAgents)
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>

      {newAgent && (
        <EditAgentModal
          isOpen={newAgentModal.isOpen}
          onClose={newAgentModal.onClose}
          ai_agent={newAgent}
          onChange={async (agent) => {
            props.onChange?.(agent)
            await refetchAgents()
            setNewAgent(null)
          }}
        />
      )}

      <Modal isOpen={ctaModal.isOpen} onClose={ctaModal.onClose} isCentered size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="0" m="0">
            <AIAgentCTA />
            <IconButton
              aria-label="Close"
              icon={<IconX size={18} />}
              size="sm"
              variant="ghost"
              position="absolute"
              top={2}
              right={2}
              onClick={ctaModal.onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export function AIColumnHeader({
  column,
  tasks,
  runningTasks,
  loadingCache
}: {
  column: string
  tasks: AgentTask[]
  runningTasks: Record<string, boolean>
  loadingCache: boolean
}) {
  const agent = tasks.find((t) => t.agent.slug === column)?.agent
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutateAsync: recordAgentMetric } = useCreateAIAgentMetric()

  const runAllAgents = useCallback(
    (skipCache: boolean = false) => {
      tasks.forEach((t) => t.markAsRunning())
      return pMap(tasks, (t) => t.resolve(skipCache, agentMetricOrigin() as string), { concurrency: 5 })
    },
    [tasks]
  )

  const isRunning = useMemo(() => {
    return tasks.some((t) => runningTasks[t.id])
  }, [tasks, runningTasks])

  const trackRunMetric = useCallback(
    (metricType: string) => {
      recordAgentMetric({
        metric_type: metricType
      })
    },
    [recordAgentMetric]
  )

  if (!agent) return null

  return (
    <Flex alignItems="center" gap={2}>
      <Icon as={IconFlare} color="purple.500" boxSize={4} />
      <TextEllipsis flex="1 1 auto" tooltip maxWidth="100%">
        {agent?.name}
      </TextEllipsis>
      {agent?.question && <HelpTooltip variant="info">{agent?.question}</HelpTooltip>}
      {loadingCache && <Spinner size="xs" color="gray.500" thickness="1.5px" />}
      {!loadingCache && (
        <HStack spacing={0}>
          <Tooltip label="Run all agents">
            <IconButton
              size="xs"
              icon={<IconPlayerPlayFilled size="14px" />}
              colorScheme="gray"
              isLoading={isRunning}
              aria-label="Run all agents"
              variant="ghost"
              onClick={() => {
                runAllAgents(false)
                trackRunMetric('run_all')
              }}
            />
          </Tooltip>
          <Popover
            placement="bottom-end"
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            isLazy
            lazyBehavior="keepMounted"
          >
            <PopoverTrigger>
              <IconButton
                size="xs"
                icon={<Icon as={IconChevronDown} size="14px" />}
                colorScheme="gray"
                variant="ghost"
                isDisabled={isRunning}
                aria-label="Run options"
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent width="20ch">
                <PopoverArrow />
                <PopoverBody p={1}>
                  <Stack spacing={0}>
                    <Tooltip label="Run all agents that haven't run or had errors">
                      <Button
                        size="sm"
                        variant="ghost"
                        justifyContent="flex-start"
                        leftIcon={<Icon as={IconPlayerPlayFilled} size="14px" />}
                        onClick={() => {
                          runAllAgents(false)
                          trackRunMetric('run_all')
                          onClose()
                        }}
                        isDisabled={isRunning}
                      >
                        Run All
                      </Button>
                    </Tooltip>
                    <Tooltip label={`We'll ignored previously cached answers for all ${tasks.length} agents`}>
                      <Button
                        size="sm"
                        variant="ghost"
                        justifyContent="flex-start"
                        leftIcon={<Icon as={IconPlayerTrackNext} size="14px" />}
                        onClick={() => {
                          runAllAgents(true)
                          trackRunMetric('run_all_from_scratch')
                          onClose()
                        }}
                        isDisabled={isRunning}
                      >
                        Run all from scratch
                      </Button>
                    </Tooltip>
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </HStack>
      )}
    </Flex>
  )
}

export interface AgentTask {
  id: string
  domain?: string
  profileId?: string
  agent: AIAgent
  cache_target: string
  resolve: (skipCache: boolean, origin?: string) => Promise<void>
  markAsRunning: () => void
  runId: string
}

export function agentCacheKey(
  agent: AIAgent,
  format: 'json' | 'markdown',
  opts: { domain?: string; profileId?: string }
) {
  const target = opts.domain || opts.profileId
  if (!target) throw new Error('Either domain or profileId must be provided')
  return `${agent.project_id}|${agent.id}|${target}|${format}`
}

interface AgentResponseCache {
  agent_id: string
  cache_target: string
  response: any
  format: 'json' | 'markdown'
}

interface AgentProps {
  domains?: string[]
  profileIds?: string[]
  agentSlugs: string[]
  target: 'companies' | 'people'
}

export function useCachedAgents(props: AgentProps) {
  const project = useCurrentProject()
  const path = projectPath(
    `/assistants/cached-agents?agent_slugs=${props.agentSlugs.join(',')}&${
      props.target === 'companies' && props.domains ? `domains=${props.domains.join(',')}&` : ''
    }${props.target === 'people' && props.profileIds ? `profile_ids=${props.profileIds.join(',')}&` : ''}format=json`
  )

  return useQuery<{ cached_agents: AgentResponseCache[] }>({
    queryKey: ['cached-agents', { projectId: project?.id, agentSlugs: props.agentSlugs, domains: props.domains }],
    queryFn: () => concurrentCachedGET<{ cached_agents: AgentResponseCache[] }>(path)
  })
}

export function useAgentStore(props: AgentProps) {
  const { data: aiAgents, refetch } = useAIAgents(props.target)
  const { isLoading, data: cachedAgentData } = useCachedAgents({
    domains: props.domains,
    profileIds: props.profileIds,
    agentSlugs: props.agentSlugs || [],
    target: props.target
  })

  const [runningTasks, setRunningTasks] = useState<Record<string, boolean>>({})
  const [responses, setResponses] = useState<Record<string, any>>({})

  useEffect(() => {
    // fetches cached agents from server
    if (cachedAgentData && !isLoading) {
      const localResponses: Record<string, any> = {}
      cachedAgentData.cached_agents.forEach((cache) => {
        try {
          const response = JSON.parse(cache.response)
          // Add usage count to the response
          localResponses[cache.cache_target] = response
        } catch (e) {
          console.error(e)
        }
      })
      setResponses(localResponses)
    }
  }, [cachedAgentData, isLoading])

  const agents: AIAgent[] = useMemo(() => {
    return props.agentSlugs
      .map((slug) => aiAgents?.my_agents?.find((a) => a.slug === slug))
      .filter((a) => !!a) as AIAgent[]
  }, [aiAgents, props.agentSlugs])

  const tasks: AgentTask[] = useMemo(() => {
    let allTasks: AgentTask[] = []

    if (props.domains?.length) {
      const domainTasks = props.domains.flatMap((domain) => {
        return agents
          .filter((agent) => agent.target == 'companies')
          .map((agent) => {
            if (!agent) return undefined as unknown as AgentTask
            const cache_target = agentCacheKey(agent, 'json', { domain })
            const runId = window.btoa(encodeURIComponent(cache_target))

            return {
              id: cache_target,
              cache_target,
              agent,
              domain,
              runId,
              markAsRunning: () => {
                if (runningTasks[cache_target]) return
                if (responses[cache_target]) return

                setRunningTasks((prev) => ({ ...prev, [cache_target]: true }))
              },
              resolve: async (skipCache: boolean, origin?: string) => {
                if (!agent) return
                if (runningTasks[cache_target]) return
                if (!skipCache && responses[cache_target]) return

                setRunningTasks((prev) => ({ ...prev, [cache_target]: true }))

                try {
                  const path = projectPath(
                    `/assistants/${agent?.slug}/run-async?domain=${domain}&skip_cache=${skipCache}&origin=${origin || ''}&run_id=${runId}`
                  )
                  await concurrentGET(path)
                } catch {
                  setRunningTasks((prev) => ({ ...prev, [cache_target]: false }))
                }
              }
            }
          })
      })

      allTasks = [...allTasks, ...domainTasks]
    }

    if (props.profileIds?.length) {
      const profileTasks = props.profileIds.flatMap((profileId) => {
        return agents
          .filter((agent) => agent.target == 'people')
          .map((agent) => {
            if (!agent) return undefined as unknown as AgentTask
            const cache_target = agentCacheKey(agent, 'json', { profileId })
            const runId = window.btoa(encodeURIComponent(cache_target))

            return {
              id: cache_target,
              cache_target,
              agent,
              profileId,
              runId,
              markAsRunning: () => {
                if (runningTasks[cache_target]) return
                if (responses[cache_target]) return

                setRunningTasks((prev) => ({ ...prev, [cache_target]: true }))
              },
              resolve: async (skipCache: boolean, origin?: string) => {
                if (!agent) return
                if (runningTasks[cache_target]) return
                if (!skipCache && responses[cache_target]) return

                setRunningTasks((prev) => ({ ...prev, [cache_target]: true }))

                try {
                  const path = projectPath(
                    `/assistants/${agent?.slug}/run-async?profile_id=${profileId}&skip_cache=${skipCache}&origin=${origin || ''}&run_id=${runId}`
                  )
                  await concurrentGET(path)
                } catch {
                  setRunningTasks((prev) => ({ ...prev, [cache_target]: false }))
                }
              }
            }
          })
      })

      allTasks = [...allTasks, ...profileTasks]
    }

    return allTasks
  }, [agents, props.domains, props.profileIds, runningTasks, responses])

  const refetchAgents = useCallback(() => {
    expireCachedGET(projectPath(`/settings/agents.json?target=${props.target}`))
    refetch()
  }, [refetch, props.target])

  return {
    agents,
    tasks,
    runningTasks,
    setRunningTasks,
    setResponses,
    responses,
    loadingCache: isLoading,
    refetchAgents
  }
}

export function AIColumnCell({
  task,
  isRunning,
  setRunningTasks,
  setResponses,
  response,
  account,
  profile,
  refetchAgents
}: {
  task: AgentTask | undefined
  isRunning: boolean
  setRunningTasks: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  setResponses: (responses: Record<string, any>) => void
  response: any
  account?: Account
  profile?: ProfileRecord
  refetchAgents?: () => void
}) {
  const { searchParams, setSearchParam } = useSearchParams()
  const [isExpanded, setIsExpanded] = useState(() => {
    return searchParams.response_target === task?.cache_target
  })
  const [isEditingAgent, setIsEditingAgent] = useState(false)
  const [hoverRef, isHovered] = useHover()
  const { mutateAsync: recordAgentMetric } = useCreateAIAgentMetric()
  const [progressEvents, setProgressEvents] = useState<any[]>([])

  usePlayItemAIRecommendationsStream(task?.runId ?? '', {
    onContent: (message) => {
      if (message?.content_type === 'response' && message.content) {
        setProgressEvents([])
        if (task?.cache_target) {
          try {
            const parsedContent = JSON.parse(message.content)
            setResponses((prev) => ({ ...prev, [task.cache_target]: parsedContent }))
          } catch (error) {
            console.error('Failed to parse agent response:', error)
            setResponses((prev) => ({
              ...prev,
              [task.cache_target]: {
                error: 'Failed to parse response from agent',
                display_answer: 'Invalid response received'
              }
            }))
          }
        }
        if (task) {
          setRunningTasks((prev: Record<string, boolean>) => ({ ...prev, [task.cache_target]: false }))
        }
      } else {
        setProgressEvents((prev: any[]) => [...prev, message])
      }
    },
    onTool: (message) => {
      setProgressEvents((prev: any[]) => [...prev, message])
    }
  })

  const runAgent = useCallback(
    (skipCache: boolean) => {
      if (isRunning || !task) return

      task.resolve(skipCache, agentMetricOrigin() as string)

      recordAgentMetric({
        assistant_id: task.agent.id,
        metric_type: 'run_single',
        target_type: profile ? 'profile' : 'account',
        target_id: profile ? profile.id : account?.id
      })
    },
    [profile, account, recordAgentMetric, isRunning, task]
  )

  if (!task) {
    return null
  }

  return (
    <Flex justifyContent="center" w="100%" alignItems="center" ref={hoverRef as any}>
      {isRunning && progressEvents.length > 0 && (
        <Box position="relative" mt={2} mb={2} width="100%" overflow="hidden">
          <ResearchProgress toolEvents={progressEvents} onlyLast={true} minW="260px" maxW="260px" />
        </Box>
      )}
      {response && !isRunning && (
        <>
          {response.error ? (
            <Text lineHeight={'1.2'} fontSize="13px" color="red.500">
              {response.error}
            </Text>
          ) : (
            <Box w="100%" position="relative">
              <TextEllipsis lineHeight={'1.2'} fontSize="13px" maxWidth="100%">
                {response.stale && <AIStaleState marginRight={1} verticalAlign="top" />}

                {response.response_type ? <CastAnswer response={response} maxItems={2} /> : response.display_answer}
              </TextEllipsis>

              <Flex
                justify="flex-end"
                position="absolute"
                right={0}
                visibility={isHovered ? 'visible' : 'hidden'}
                bottom={0}
                gap={1}
              >
                <Tooltip label="View full report">
                  <Button
                    borderWidth={'thin'}
                    shadow="sm"
                    leftIcon={<Icon as={IconFileTextSpark} size="16px" />}
                    iconSpacing={1.5}
                    size="xs"
                    onClick={(e) => {
                      e.preventDefault()
                      setSearchParam('response_target', task?.cache_target || '')
                      setIsExpanded(true)
                    }}
                  >
                    {(response.sources || []).length === 0 && 'View report'}
                    {(response.sources || []).length > 0 && pluralize('source', response.sources?.length ?? 0, true)}
                  </Button>
                </Tooltip>

                <Tooltip
                  label={
                    <HStack spacing={1}>
                      <Text>Rerun from scratch. Last run</Text>
                      <TimeAgo time={response.response_updated_at} />
                    </HStack>
                  }
                >
                  <IconButton
                    variant="outline"
                    size="xs"
                    icon={<Icon as={IconPlayerPlay} boxSize={3.5} />}
                    onClick={() => runAgent(true)}
                    aria-label="Run agent"
                  />
                </Tooltip>
              </Flex>

              <EditAgentDrawer
                onClose={() => setIsEditingAgent(false)}
                isOpen={isEditingAgent}
                ai_agent={task.agent}
                showTarget={false}
                showDeleteOption={false}
                onChange={async (_agent, isShouldRefreshCache) => {
                  setIsExpanded(false)
                  runAgent(isShouldRefreshCache)
                  expireAIAgentsCache('people')
                  expireAIAgentsCache('companies')
                  refetchAgents?.()
                }}
                company={account?.company}
                profile={profile}
              />

              {isExpanded && task.agent && (
                <AgentResponseDrawer
                  response={response}
                  agent={task.agent}
                  isExpanded={isExpanded}
                  setIsExpanded={(expanded) => {
                    if (!expanded) {
                      setSearchParam('response_target', undefined)
                    }
                    setIsExpanded(expanded)
                  }}
                  setIsEditingAgent={setIsEditingAgent}
                  account={account}
                  profile={profile}
                />
              )}
            </Box>
          )}
        </>
      )}
      {!isRunning && !response && (
        <Button
          variant={'ghost'}
          size="xs"
          colorScheme={'gray'}
          leftIcon={<IconPlayerPlayFilled size="14px" />}
          onClick={() => runAgent(false)}
          isLoading={isRunning}
        >
          Run
        </Button>
      )}
    </Flex>
  )
}
