import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Img,
  List,
  ListItem,
  Spacer,
  Stack,
  StackProps,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack
} from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { IconChevronRight, IconChevronsRight, IconRepeatOff, IconX } from '@tabler/icons-react'
import { Card, GrayCard } from './Card'
import CompanyAvatar from './CompanyAvatar'
import { ComboboxWithSearch } from './ComboboxWithSearch'
import PlanTierAlert from './PlanTierAlert'
import { SalesforceIcon } from './icons/SalesforceIcons'
import { HubSpotIcon } from './icons/HubspotIcons'
import { channelLogos } from '../pages/follow_rules/components/delivery-setup'
import { SalesforceField } from '../pages/apps/salesforce/show'
import { HubspotProp } from '../pages/apps/hubspot/show'
import { Toggle } from '../pages/accounts/components/Toggle'
import { TimeAgo } from './TimeAgo'
import SyncDuration from '../pages/admin/components/SyncDuration'
import { SyncStatus, SyncStatusValues } from '../pages/admin/syncs/index'
import { DateTime } from '@app/types/Profile'
import Middot from './Middot'
import { WarningMessage } from '@ui/WarningMessage'

type CrmField = SalesforceField | HubspotProp

interface ManagedAccountScore {
  field: string
  name: string
  label: string
  type: string
  field_type: string
  properties: {
    values?: string[]
    default?: string | number
    precision?: number
  }
}

export interface ManagedAccountScores {
  definitions: ManagedAccountScore[]
  managed_package: {
    url: string
    version: string
  }
  available: boolean
  last_sync?: ManagedAccountScoresSyncStatus
  next_sync_time?: DateTime
}

export interface ManagedAccountScoresSyncStatus {
  type: string
  status: SyncStatusValues
  synced_count: number
  started_at: DateTime
  stopped_at: DateTime
  results: any[]
}

export interface AccountScoreMapping {
  intent_score: string
  intent_strength: string
  intent_trend: string
  icp_fit_score: string
  icp_fit_grade: string
}

interface Props<T> {
  accountScore: ManagedAccountScores
  settings: {
    account_scores_sync_enabled?: boolean
    account_scores_sync_mapping?: AccountScoreMapping
  }
  crmFields: T[] | undefined
  appModule?: string
  canEdit?: boolean
  children?: React.ReactNode
  reconnectDialog?: React.ReactNode
}

interface FieldPreviewProps<T> {
  item: T | null
  selectedItem?: T | null
  channel: string
}

export function FieldPreview<T extends CrmField>({ item, channel }: FieldPreviewProps<T>) {
  if (!item) {
    return (
      <HStack flex="1" fontSize={'sm'}>
        <IconRepeatOff size={14} />
        <Text color="gray.600">Do not sync</Text>
      </HStack>
    )
  }

  return (
    <HStack flex="1" fontSize={'sm'}>
      <Img src={channelLogos[channel]} w="4" />
      <Text>{item.label}</Text>
    </HStack>
  )
}

export function DisabledFieldPreview<T extends CrmField>({ item, channel }: FieldPreviewProps<T>) {
  const color = 'gray.500'

  if (!item) {
    return (
      <HStack flex="1" fontSize={'sm'}>
        <IconRepeatOff size={14} />
        <Text color="gray.600">Do not sync</Text>
      </HStack>
    )
  }

  return (
    <Tooltip label="Incompatible type">
      <List>
        <ListItem opacity={0.3} cursor="not-allowed" pointerEvents="none">
          <HStack flex="1" fontSize={'sm'}>
            {channel === 'salesforce' && <Icon as={SalesforceIcon} color={color} />}
            {channel === 'hubspot' && <Icon as={HubSpotIcon} color={color} />}

            <Text color={color}>{item.label}</Text>
          </HStack>
        </ListItem>
      </List>
    </Tooltip>
  )
}

export function AccountScoresSettings<T extends CrmField>({
  accountScore,
  settings,
  crmFields,
  appModule,
  canEdit,
  children,
  reconnectDialog
}: Props<T>) {
  const { definitions } = accountScore

  const isAvailable = accountScore.available
  const [isEnabled, setIsEnabled] = useState<boolean>(
    accountScore.available && (settings.account_scores_sync_enabled ?? false)
  )
  const mapping = settings.account_scores_sync_mapping
  const lastSync = accountScore.last_sync
  const nextSyncTime = accountScore.next_sync_time

  return (
    <React.Fragment>
      <Heading size="md" pt="8">
        <HStack justifyContent="space-between" width="100%">
          <HStack>
            <Text>Account Scores Sync</Text>
          </HStack>
          {!reconnectDialog && (
            <AccountScoresEnabledToggle
              isAvailable={isAvailable}
              isSettingEnabled={isEnabled}
              setIsSettingEnabled={setIsEnabled}
              canEdit={canEdit}
            />
          )}
        </HStack>
      </Heading>
      {reconnectDialog ? (
        reconnectDialog
      ) : (
        <>
          {!isAvailable && (
            <PlanTierAlert planTier={'Business Tier'} description="Upgrade your plan to sync account scores" />
          )}
          <Card paddingY={4} paddingX={6}>
            <Toggle
              showIcon={false}
              title={
                <HStack role="group" w="100%">
                  <HStack w="100%" spacing="3">
                    <CompanyAvatar domain={`${appModule}.com`} />
                    <Stack spacing="0">
                      <Text fontSize="sm" fontWeight="medium" color="gray.700">
                        Setup Instructions
                      </Text>
                      <Text fontSize="xs" color="gray.500">
                        Configure your CRM to receive Account scores
                      </Text>
                    </Stack>
                  </HStack>
                  <Icon as={IconChevronRight} boxSize={5} color="gray.400" _groupHover={{ color: 'purple.600' }} />
                </HStack>
              }
            >
              {children}
            </Toggle>
          </Card>
          <AccountScoresSyncStatus lastSync={lastSync} nextSyncTime={nextSyncTime} />
          {isAvailable && (
            <AccountScoresFixedMappings<T>
              isSettingEnabled={isEnabled}
              canEdit={canEdit}
              definitions={definitions}
              mapping={mapping}
              crmFields={crmFields}
              appModule={appModule}
            />
          )}
        </>
      )}
    </React.Fragment>
  )
}

interface AccountScoresSyncStatusProps {
  lastSync?: ManagedAccountScoresSyncStatus
  nextSyncTime?: DateTime
  containerProps?: StackProps
}

export function AccountScoresSyncStatus({ lastSync, nextSyncTime, containerProps }: AccountScoresSyncStatusProps) {
  return (
    <HStack spacing="2" fontSize="sm" justifyContent="flex-end" {...containerProps}>
      {lastSync && (
        <>
          <Text fontSize="sm" color="gray.600">
            Last Sync:
          </Text>
          <TimeAgo time={lastSync.stopped_at} />
          <SyncDuration
            start={lastSync.started_at}
            stop={lastSync.stopped_at}
            status={lastSync.status}
            textProps={{
              fontSize: 'sm',
              sx: { '&::before': { content: '"("' }, '&::after': { content: '")"' } },
              textTransform: 'lowercase'
            }}
          />
          <SyncStatus status={lastSync.status} />
          <Middot />
        </>
      )}
      <>
        <Text fontSize="sm" color="gray.600">
          Next sync
        </Text>
        <TimeAgo time={nextSyncTime} />
      </>

      <Spacer />
    </HStack>
  )
}

export function AccountScoresEnabledToggle({ isSettingEnabled, setIsSettingEnabled, isAvailable, canEdit }) {
  const handleToggle = () => {
    setIsSettingEnabled((prev) => !prev)
  }

  const inputName = 'app_instance_settings[account_scores_sync_enabled]'

  return (
    <React.Fragment>
      <input type={'hidden'} name={inputName} value={isSettingEnabled.toString()} />
      <Switch
        isDisabled={!canEdit || !isAvailable}
        isChecked={isSettingEnabled}
        onChange={handleToggle}
        name={inputName}
        value={isSettingEnabled.toString()}
      />
    </React.Fragment>
  )
}

export function AccountScoresDefinitionsTable(definitions: ManagedAccountScore[]) {
  return (
    <React.Fragment>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Label</Th>
            <Th>Type (Field Type)</Th>
            <Th>Details</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.values(definitions).map((definition) => (
            <Tr key={definition.name}>
              <Td>{definition.label}</Td>
              <Td>
                {definition.type}({definition.field_type})
              </Td>
              <Td>
                {Object.entries(definition.properties).map((p, index) => (
                  <Text key={index}>
                    {p[0]}: {Array.isArray(p[1]) ? p[1].join(', ') : p[1]}
                  </Text>
                ))}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </React.Fragment>
  )
}

export function AccountScoresFixedMappings<T extends CrmField>({
  isSettingEnabled,
  canEdit,
  appModule,
  definitions,
  mapping,
  crmFields
}) {
  const isDisabled = !canEdit || !isSettingEnabled
  const [isEnabled, setIsEnabled] = useState<boolean>(isSettingEnabled && (mapping ?? false))

  const [isCustomMappingEmpty, _] = useState<boolean>(
    isEnabled && mapping && Object.values(mapping).every((val) => val === '')
  )

  const handleToggle = () => {
    setIsEnabled((prev) => !prev)
  }

  return (
    <React.Fragment>
      <Box>
        <HStack justifyContent="space-between" width="100%" mb={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="enableToggle" mb="0">
              Enable Custom Mapping
            </FormLabel>
            <Switch
              id="enableToggle"
              isDisabled={isDisabled}
              isChecked={isEnabled ?? false}
              onChange={handleToggle}
              value={isEnabled === null ? '' : isEnabled.toString()}
            />
          </FormControl>
          {isCustomMappingEmpty && (
            <HStack display="flex" color="yellow.500" fontSize="sm" flexShrink={0} whiteSpace="nowrap">
              <WarningMessage>Sync will not work with empty mapping</WarningMessage>
            </HStack>
          )}
        </HStack>

        {!isEnabled && <input type="hidden" name={`app_instance_settings[account_scores_sync_mapping][]`} value="" />}
        {isEnabled && (
          <GrayCard paddingY={4} paddingX={6}>
            <Toggle disabled={isDisabled} defaultIsOpen={true} forceIsOpen={true} title="Custom Mapping">
              <VStack px="2" py="4" w="100%" alignItems={'normal'} spacing="4">
                <HStack w="100%" justifyContent={'space-around'}>
                  <Text fontWeight={'bold'} textTransform={'uppercase'} fontSize={'xs'} color="gray.500">
                    Koala
                  </Text>

                  <Text fontWeight={'bold'} textTransform={'uppercase'} fontSize={'xs'} color="gray.500">
                    {appModule}
                  </Text>
                </HStack>

                <VStack>
                  {Object.values(definitions).map((definition, index) => {
                    return (
                      <AccountScoreFieldMapping<T>
                        definition={definition}
                        crmFields={crmFields}
                        mapping={mapping}
                        key={index}
                        appModule={appModule}
                      />
                    )
                  })}
                </VStack>
              </VStack>
            </Toggle>
          </GrayCard>
        )}
      </Box>
    </React.Fragment>
  )
}

export function AccountScoreFieldMapping<T extends CrmField>({ crmFields, definition, mapping, appModule }) {
  const [selected, setSelected] = useState<T | null>(
    crmFields.find((f) => (mapping ? f.name === mapping[definition.field] : null))
  )

  const onCrmChange = useCallback(
    (crmField: T | null) => {
      setSelected(crmField)
    },
    [setSelected]
  )

  const matchType = (field, definition) => {
    if (!field) {
      return false
    }
    const accepted_type = definition.accepted_types.includes(field.type)

    // for Salesforce
    if (field.type === 'picklist' && definition.type === 'picklist') {
      const picklistValues = field.picklistValues.filter((p) => p.active).map((p) => p.value)
      const missingValue = definition.properties.values.some((p) => !picklistValues.includes(p))
      return accepted_type && !missingValue
    }

    // for Hubspot
    if (field.type === 'enumeration' && definition.type === 'enumeration') {
      const values = field.options.map((p) => p.value)
      const missingValue = definition.properties.values.some((d) => !values.includes(d))
      return accepted_type && !missingValue
    }
    return accepted_type
  }

  const renderField = (item, definition, appModule) => {
    if (matchType(item.item, definition)) {
      return <FieldPreview {...item} channel={appModule} />
    } else {
      return <DisabledFieldPreview {...item} channel={appModule} />
    }
  }

  return (
    <React.Fragment>
      <HStack w="100%" justifyContent="space-between" alignItems="center" mb={0}>
        <Text flex={2} textAlign="center">
          {definition.label}
        </Text>

        <IconChevronsRight size={14} />

        <Box flex={2}>
          <input
            type="hidden"
            name={`app_instance_settings[account_scores_sync_mapping][${definition.field}]`}
            value={selected?.name}
          />
          <ComboboxWithSearch
            items={crmFields}
            placeholder="Do not sync"
            selectedItem={selected}
            onChange={onCrmChange}
            filterItem={(a, val) => a.label.toLowerCase().includes(val)}
            itemToString={(item) => item?.label ?? ''}
            itemRenderer={(item) => renderField(item, definition, appModule)}
            selectButtonRenderer={(item) => renderField(item, definition, appModule)}
          />
        </Box>
        <IconButton
          flex={0}
          aria-label="Remove Field Mapping"
          size="xs"
          onClick={() => setSelected(null)}
          variant="ghost"
          color="gray.400"
          _hover={{ color: 'gray.800' }}
          icon={<IconX size={14} />}
        />
      </HStack>
    </React.Fragment>
  )
}
