import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data<T> {
  app_id: string
  connected: boolean
  valid?: boolean
  user_in_app?: boolean
  deps: T
}

export function useApp<T>(appId: string) {
  const project = useCurrentProject()
  const basePath = `/apps/${appId}.json`

  const path = projectPath(basePath)

  return useQuery<Data<T>>({
    queryKey: ['app', { projectId: project?.id, appId }],
    queryFn: () => concurrentGET<Data<T>>(path),
    enabled: Boolean(project?.id)
  })
}
