import { Button, Collapse, Flex, FormControl, Heading, HStack, Icon, Stack, Wrap } from '@chakra-ui/react'
import { IconCheck } from '@tabler/icons-react'
import { nanoid } from 'nanoid'
import React, { useCallback } from 'react'
import { Apps } from '../../../../types/App'
import { Play } from '../../../../types/Play'
import { Card } from '../../../ui/Card'
import { Iconify } from '../../../ui/Iconify'
import { humanize } from '../../accounts/facets/filter-cloud'
import { channelLogos } from '../../follow_rules/components/delivery-setup'
import { HubspotSetup } from '../../follow_rules/components/hubspot/hubspot-setup'
import { SalesforceSetup } from '../../follow_rules/components/salesforce/salesforce-setup'
import { WebhookSetup } from '../../follow_rules/components/webhook/webhook-setup'

interface PlayActionsProps {
  play: Partial<Play>
  targetType?: 'Profile' | 'Account'
  apps: Apps
}

export function PlayActions(props: PlayActionsProps) {
  const [actionConfig, setActionConfig] = React.useState({
    ...props.play.action_config,
    enabled: true
  })

  const toggleDestination = useCallback(
    (destination: string) => {
      const enabled = !!actionConfig.rules?.[destination]

      setActionConfig({
        ...actionConfig,
        rules: {
          ...actionConfig.rules,
          // if the destination is enabled, remove it from the rules
          [destination]: enabled ? undefined : {}
        }
      })
    },
    [actionConfig]
  )

  const destinations = ['salesforce', 'hubspot', 'webhook']

  return (
    <Stack w="100%">
      <FormControl as={Stack} spacing={4}>
        <Wrap>
          {destinations.map((destination) => (
            <Button
              key={destination}
              leftIcon={<Iconify icon={channelLogos[destination]} size={16} />}
              size="sm"
              variant={'outline'}
              rightIcon={actionConfig.rules?.[destination] ? <Icon flex="none" as={IconCheck} /> : undefined}
              isActive={actionConfig.rules?.[destination]}
              onClick={() => toggleDestination(destination)}
            >
              {humanize(destination)}
            </Button>
          ))}
        </Wrap>

        <Flex flexDirection="column" gap={4}>
          <Collapse in={actionConfig.rules?.salesforce !== undefined}>
            <Card>
              <HStack>
                <Iconify icon={channelLogos['salesforce']} size={16} />
                <Heading size="xs">Salesforce Setup</Heading>
              </HStack>

              <SalesforceSetup
                colorScheme={'blue'}
                targetType={props.targetType}
                delivery_rules={actionConfig.rules}
                skipBatchFrequency
                skipBoxes
                suggestedMappings={{
                  contact: [
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.recommended_email',
                      salesforce: 'Email',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.title',
                      salesforce: 'Title',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.first_name',
                      salesforce: 'FirstName',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.last_name',
                      salesforce: 'LastName',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.linkedin_url',
                      salesforce: 'LinkedinUrl',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.department',
                      salesforce: 'Department',
                      mode: 'mapped'
                    }
                  ]
                }}
                actions={{
                  import_account: true,
                  import_contact: true,
                  import_lead: true
                }}
              />
            </Card>
          </Collapse>

          <Collapse in={actionConfig.rules?.hubspot !== undefined}>
            <Card>
              <HStack>
                <Iconify icon={channelLogos['hubspot']} size={16} />
                <Heading size="xs">HubSpot Setup</Heading>
              </HStack>

              <HubspotSetup
                colorScheme={'orange'}
                targetType={props.targetType}
                delivery_rules={actionConfig.rules}
                compact
                suggestedMappings={{
                  contact: [
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.recommended_email',
                      hubspot: 'email',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.recommended_work_email',
                      hubspot: 'work_email',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.title',
                      hubspot: 'jobtitle',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.department',
                      hubspot: 'job_function',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.seniority',
                      hubspot: 'seniority',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.first_name',
                      hubspot: 'firstname',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.last_name',
                      hubspot: 'lastname',
                      mode: 'mapped'
                    },
                    {
                      id: nanoid(),
                      koala: 'visitor.enrichment.linkedin_url',
                      hubspot: 'hs_linkedin_url',
                      mode: 'mapped'
                    }
                  ]
                }}
                actions={{
                  import_company: true,
                  import_contact: true,
                  add_to_list: false,
                  create_task: false,
                  add_timeline_entry: false
                }}
              />
            </Card>
          </Collapse>

          <Collapse in={actionConfig.rules?.webhook !== undefined}>
            <Card as={Stack} spacing={4}>
              <HStack>
                <Iconify icon={channelLogos['webhook']} size={16} />
                <Heading size="xs">Webhook Setup</Heading>
              </HStack>
              <WebhookSetup
                delivery_rules={actionConfig.rules}
                skipPreview
                targetType={props.targetType}
                suggestedMappings={
                  props.targetType === 'Profile'
                    ? [
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.recommended_email',
                          webhook_key: 'enrichment.email',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.recommended_work_email',
                          webhook_key: 'enrichment.work_email',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.recommended_personal_email',
                          webhook_key: 'enrichment.personal_email',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.title',
                          webhook_key: 'enrichment.title',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.first_name',
                          webhook_key: 'enrichment.first_name',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.last_name',
                          webhook_key: 'enrichment.last_name',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.linkedin_url',
                          webhook_key: 'enrichment.linkedin_url',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.department',
                          webhook_key: 'enrichment.department',
                          mode: 'mapped'
                        }
                      ]
                    : undefined
                }
              />
            </Card>
          </Collapse>
        </Flex>
      </FormControl>
    </Stack>
  )
}
