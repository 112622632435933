import {
  Card,
  Checkbox,
  Flex,
  Text,
  Stack,
  Heading,
  Table,
  Tbody,
  Tr,
  Td,
  Thead,
  Th,
  Icon,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  Box,
  Tooltip
} from '@chakra-ui/react'
import React, { useEffect, useMemo } from 'react'
import { IconArrowRight, IconBlocks, IconEye, IconInfoCircle } from '@tabler/icons-react'
import type { Icon as IconType } from '@tabler/icons-react'
import { useTraits } from '../../../data/use-traits'
import { TextIcon } from '../../../ui/icons'

type AvailableField = {
  icon: IconType | typeof TextIcon
  id: string
  name: string
}

type ColumnMappingRowProps = {
  column: string | number
  availableFields: AvailableField[]
  onColumnHover: (column: string | number) => void
  onColumnLeave: () => void
  onColumnSelect: (column: string | number, field: AvailableField['id'] | null) => void
  selectedAttribute: AvailableField | null
}

const ColumnMappingRow = ({
  column,
  availableFields,
  selectedAttribute,
  onColumnHover,
  onColumnLeave,
  onColumnSelect
}: ColumnMappingRowProps) => {
  const [hovered, setHovered] = React.useState(false)
  const [openPopoverId, setOpenPopoverId] = React.useState<string | number | null>(null)

  const handleColumnHover = React.useCallback(() => {
    onColumnHover(column)
    setHovered(true)
  }, [column, onColumnHover])

  const handleColumnLeave = React.useCallback(() => {
    onColumnLeave()
    setHovered(false)
  }, [onColumnLeave])

  const handleColumnSelect = React.useCallback(
    (field: string | null) => {
      onColumnSelect(column, field)
      setOpenPopoverId(null)
    },
    [column, onColumnSelect]
  )

  return (
    <Tr
      cursor="pointer"
      bg={hovered ? 'gray.50' : 'transparent'}
      onMouseEnter={handleColumnHover}
      onMouseLeave={handleColumnLeave}
    >
      <Td padding={2}>
        <Text>{typeof column === 'number' ? `Column ${column + 1}` : column}</Text>
      </Td>

      <Td padding={2}>
        <Icon color="gray.400" as={IconArrowRight} />
      </Td>

      <Td padding={2}>
        <Popover
          placement="bottom-start"
          isLazy
          lazyBehavior="keepMounted"
          isOpen={openPopoverId === column}
          onOpen={() => setOpenPopoverId(column)}
          onClose={() => setOpenPopoverId(null)}
        >
          <PopoverTrigger>
            <Button
              size="sm"
              variant="outline"
              width={200}
              justifyContent="flex-start"
              textAlign="left"
              bg="gray.50"
              _hover={{ bg: 'gray.100' }}
              _active={{ bg: 'gray.100' }}
            >
              <Flex alignItems="center" justifyContent="flex-start" gap={1}>
                {selectedAttribute ? (
                  <>
                    <Icon as={selectedAttribute.icon} />
                    <Text as="span">{selectedAttribute.name}</Text>
                  </>
                ) : (
                  <>
                    <Icon as={IconBlocks} color="gray.400" />
                    <Text as="span" color="gray.400">
                      Select attribute
                    </Text>
                  </>
                )}
              </Flex>
            </Button>
          </PopoverTrigger>

          <Portal>
            <PopoverContent
              width="min(200px, 95vw)"
              overflow="hidden"
              rounded="lg"
              shadow="xl"
              _focus={{
                outline: 'none',
                boxShadow: 'xl'
              }}
            >
              <Box
                flex="1 1 auto"
                display="flex"
                flexDirection="column"
                height="min(300px, calc(50vh - var(--header-height)))"
              >
                <Box fontSize="sm" padding={2} overflow="auto">
                  <Box
                    key="select-attribute"
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                    p={2}
                    fontSize="sm"
                    rounded="md"
                    cursor="pointer"
                    _hover={{ bg: 'gray.100' }}
                    onClick={() => handleColumnSelect(null)}
                    color="gray.500"
                  >
                    <Icon as={IconBlocks} />
                    Select an attribute
                  </Box>

                  {availableFields.map((field, index) => {
                    const fieldId = field.id
                    return (
                      <Box
                        key={`${fieldId}-${index}`}
                        display="flex"
                        alignItems="center"
                        gap={1.5}
                        p={2}
                        fontSize="sm"
                        rounded="md"
                        cursor="pointer"
                        _hover={{ bg: 'gray.100' }}
                        onClick={() => handleColumnSelect(fieldId)}
                      >
                        <Icon as={field.icon} />
                        <Text>{field.name}</Text>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </PopoverContent>
          </Portal>
        </Popover>
      </Td>
    </Tr>
  )
}

type FilePreviewProps = {
  identifier: 'email' | 'domain'
  entries: string[][]
  columns: string[]
  hasHeader: boolean
  setHasHeader: (hasHeader: boolean) => void
  columnMappings: Record<string, string>
  onColumnMappingChange: (mapping: Record<string, string>) => void
}

export const FilePreview = ({
  identifier,
  entries,
  columns,
  hasHeader,
  setHasHeader,
  columnMappings,
  onColumnMappingChange
}: FilePreviewProps) => {
  const [previewedColumn, setPreviewedColumn] = React.useState<string | number | null>(null)
  const availableFields: AvailableField[] = useTraits(identifier === 'email' ? 'profile' : 'account')

  const columnsToBeMapped =
    columns.length > 0
      ? columns
      : Array(entries[0].length)
          .fill(0)
          .map((_, index) => index)

  // Try to detect the email column automatically based on entries
  useEffect(() => {
    if (!availableFields.length || !entries.length) return

    const entryWithEmail = entries.find((entry) => entry.some((value) => value.includes('@')))
    const emailColumn = entryWithEmail?.findIndex((value) => value.includes('@'))

    if (emailColumn !== undefined && emailColumn !== -1) {
      const emailAttribute = availableFields.find((field) => field.id === 'email')
      if (emailAttribute) {
        // Check if this mapping already exists before setting it
        const columnKey =
          hasHeader && columns.length > 0 && emailColumn < columns.length ? columns[emailColumn] : emailColumn

        // Only update if the mapping doesn't already exist
        if (!columnMappings[columnKey] || columnMappings[columnKey] !== emailAttribute.id) {
          onColumnMappingChange({ ...columnMappings, [columnKey]: emailAttribute.id })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFields, entries, hasHeader])

  const handleColumnHover = React.useCallback((column: string | number) => {
    setPreviewedColumn(column)
  }, [])

  const handleColumnLeave = React.useCallback(() => {
    setPreviewedColumn(null)
  }, [])

  const handleColumnSelect = React.useCallback(
    (column: string | number, fieldId: AvailableField['id'] | null) => {
      let newMapping = { ...columnMappings }

      if (fieldId === null) {
        // Unmapping a column
        const { [column]: _, ...rest } = newMapping
        newMapping = rest
      } else {
        // Prevent mapping the same column to multiple attributes
        newMapping = Object.fromEntries(Object.entries(newMapping).filter(([_, value]) => value !== fieldId))
        newMapping[column] = fieldId
      }

      onColumnMappingChange(newMapping)
    },
    [columnMappings, onColumnMappingChange]
  )

  const getSampleData = (column: string | number) => {
    if (!entries || !Array.isArray(entries) || entries.length === 0) return []

    // Get the correct column index
    let columnIndex: number
    if (typeof column === 'number') {
      columnIndex = column
    } else {
      columnIndex = columns.indexOf(column)
    }
    if (columnIndex < 0 || columnIndex >= entries[0].length) return []

    // Return 3 sample values from this column
    return entries.slice(0, 3).map((entry) => entry[columnIndex])
  }

  const columnToAttributeMap = useMemo(() => {
    return (columnsToBeMapped as Array<string | number>).reduce<Record<string | number, AvailableField | null>>(
      (acc, column) => {
        const attributeId = columnMappings[column]
        acc[column] = attributeId ? availableFields.find((field) => field.id === attributeId) || null : null
        return acc
      },
      {}
    )
  }, [columnsToBeMapped, columnMappings, availableFields])

  return (
    <Card flex="1" padding={4}>
      <Stack spacing={4} mb={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="sm">Mapping</Heading>

          <Checkbox size="md" isChecked={hasHeader} onChange={(e) => setHasHeader(e.target.checked)}>
            <Text color="gray.500">First row as header</Text>
          </Checkbox>
        </Flex>
      </Stack>

      <Flex gap={4}>
        <Flex flex="3" borderRight="1px solid" borderColor="gray.200">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th fontSize="xs" color="gray.500">
                  Column in CSV
                </Th>

                <Th />

                <Th fontSize="xs" color="gray.500">
                  <Flex alignItems="center" gap={1}>
                    Attribute in Koala
                    <Tooltip label="Unmapped columns will be ignored" placement="top">
                      <Icon as={IconInfoCircle} />
                    </Tooltip>
                  </Flex>
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {columnsToBeMapped.map((column) => (
                <ColumnMappingRow
                  key={column}
                  column={column}
                  availableFields={availableFields}
                  selectedAttribute={columnToAttributeMap[column]}
                  onColumnHover={handleColumnHover}
                  onColumnLeave={handleColumnLeave}
                  onColumnSelect={handleColumnSelect}
                />
              ))}
            </Tbody>
          </Table>
        </Flex>

        <Box flex="2" position="sticky" top="20px" alignSelf="flex-start" maxHeight="80vh" overflowY="auto">
          {previewedColumn !== null && (
            <Stack spacing={2} width="100%" overflow="hidden">
              <Heading size="xs" color="gray.600" display="flex" alignItems="center" gap={1}>
                <Icon as={IconEye} />
                Preview of "{typeof previewedColumn === 'number' ? `Column ${previewedColumn + 1}` : previewedColumn}"
              </Heading>

              {getSampleData(previewedColumn).map((value, idx) => (
                <Text
                  key={idx}
                  fontSize="sm"
                  fontFamily="mono"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  title={value}
                >
                  {value !== undefined && value !== '' ? (
                    value
                  ) : (
                    <Text as="span" color="gray.400">
                      empty
                    </Text>
                  )}
                </Text>
              ))}

              {getSampleData(previewedColumn).length === 0 && (
                <Text fontSize="sm" color="gray.500">
                  No sample data available
                </Text>
              )}
            </Stack>
          )}
        </Box>
      </Flex>
    </Card>
  )
}
