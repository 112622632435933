import { useQuery } from '@tanstack/react-query'
import { concurrentCachedGET, concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export interface User {
  id: string
  email: string
  emails?: string[]
  name: string
  image?: string
  avatar?: string
}

interface Data {
  users: User[]
}

export function useUsers(opts: { cached?: boolean; enabled?: boolean } = {}) {
  const project = useCurrentProject()
  const basePath = '/settings/members'

  const path = projectPath(basePath)

  const getter = opts.cached ? concurrentCachedGET : concurrentGET

  return useQuery<Data>({
    queryKey: ['users', { projectId: project?.id }],
    queryFn: () => getter<{ users: User[] }>(path),
    enabled: Boolean(project?.id) && opts?.enabled !== false
  })
}
