import React from 'react'
import { Box, Flex, Heading, Progress, Stack, Text, Button } from '@chakra-ui/react'
import { Card } from '@app/components/ui/Card'
import { formatDate } from '@app/lib/dayjs'
import { projectPath } from '@app/components/ui/ProjectsContext'

interface MonthlyPlanSummaryProps {
  plan: string
  creditPackPrice: number
  extraLicensesPrice: number
  includedLicenses: number
  extraLicenses: number
  licensePrice: number
  totalCredits: number
  usedCredits: number
  maxCredits: number
  creditsRefreshDate: string
  totalLicenses: number
  maxLicenses: number
}

export function MonthlyPlanSummary({
  plan = 'Pro',
  creditPackPrice = 500,
  extraLicensesPrice = 300,
  includedLicenses = 3,
  extraLicenses = 4,
  licensePrice = 75,
  totalCredits = 6856,
  usedCredits = 3144,
  maxCredits = 10000,
  creditsRefreshDate = '2024-04-03',
  totalLicenses = 7,
  maxLicenses = 7
}: MonthlyPlanSummaryProps) {
  const totalPrice = creditPackPrice + extraLicensesPrice
  const creditsUsedPercentage = Math.round((usedCredits / maxCredits) * 100)
  const licensesUsedPercentage = Math.round((totalLicenses / maxLicenses) * 100)

  const formattedRefreshDate = formatDate(creditsRefreshDate, 'MMMM D, YYYY')

  return (
    <Stack as={Card} spacing={3} p={4}>
      <Heading size="sm" fontWeight="semibold">
        Monthly {plan} Plan
      </Heading>

      {/* <Box borderBottomWidth="1px" pb={3}>
        <Flex justifyContent="space-between" py={1}>
          <Heading size="xs" fontWeight="medium">
            Credit Pack
          </Heading>
          <Heading size="xs" fontWeight="medium">
            ${creditPackPrice}
          </Heading>
        </Flex>

        <Flex justifyContent="space-between" py={1}>
          <Stack spacing={0}>
            <Heading size="xs" fontWeight="medium">
              Extra Licenses
            </Heading>
            <Text fontSize="xs" color="gray.600">
              {includedLicenses} licenses included, {extraLicenses} extra @ ${licensePrice}
            </Text>
          </Stack>
          <Heading size="xs" fontWeight="medium">
            ${extraLicensesPrice}
          </Heading>
        </Flex>
      </Box> */}

      {/* <Flex justifyContent="space-between" py={1}>
        <Heading size="xs" fontWeight="semibold">
          Total
        </Heading>
        <Heading size="xs" fontWeight="semibold">
          ${totalPrice}
        </Heading>
      </Flex> */}

      <Box pt={2}>
        <Flex justifyContent="space-between" alignItems="center" mb={1}>
          <Heading size="xs" fontWeight="medium">
            Available Credits
          </Heading>
          <Button variant="outline" colorScheme="purple" size="xs" as="a" href={projectPath('/settings/plans')}>
            Change Plan
          </Button>
        </Flex>
        <Heading size="md" fontWeight="semibold" mb={1}>
          {totalCredits.toLocaleString()} / {maxCredits.toLocaleString()}
        </Heading>
        <Progress value={creditsUsedPercentage} size="sm" colorScheme="purple" borderRadius="full" mb={1} />
        <Text fontSize="xs" color="gray.600">
          Credits will refresh on {formattedRefreshDate}
        </Text>
      </Box>

      <Box pt={2}>
        <Heading size="xs" fontWeight="medium" mb={1}>
          Team Licenses
        </Heading>
        <Heading size="md" fontWeight="semibold" mb={1}>
          {totalLicenses} / {maxLicenses}
        </Heading>
        <Progress value={licensesUsedPercentage} size="sm" colorScheme="purple" borderRadius="full" mb={1} />
        {/* <Text fontSize="xs" color="gray.600">
          Additional licenses cost ${licensePrice}/month
        </Text> */}
      </Box>
    </Stack>
  )
}
