import React, { useCallback, useEffect, useRef, useState } from 'react'

import { sendComposerFeedback, useEmailRecommendations } from '@app/components/data/use-ai-recommendations'
import {
  EmailStrategy,
  EmailSuggestions,
  ResearchResults,
  ToolStream
} from '@app/components/ui/composer/email-suggestions'
import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronLeft, IconSparkles, IconWand, IconX } from '@tabler/icons-react'
import { toast } from 'sonner'
import { PlayItem } from '../../../types/Play'
import { AutosizedTextarea } from '../AutosizedTextarea'
import { ComboboxWithSearch } from '../ComboboxWithSearch'
import { EmailProps } from '../EmailComposer'
import { TextEllipsis } from '../text-ellipsis'
import { ComposerFeedback } from './ComposerFeedback'
import { PulsingText } from './PulsingText'

interface RewritePanelProps {
  currentEmail?: EmailProps
  selectedText?: string
  onClose: () => void
  isLoading?: boolean
  itemId: string
  onSelect: (email: EmailProps) => void
  runId?: string
  prospects?: PlayItem[]
  selectedProspect?: PlayItem | null
  onSelectProspect?: (prospect: PlayItem | null) => void
}

export const RewritePanel = ({
  onClose,
  itemId,
  onSelect,
  runId: runIdProp,
  prospects,
  selectedProspect,
  onSelectProspect
}: RewritePanelProps) => {
  const [instructions, setInstructions] = useState('')
  const [feedback, setFeedback] = useState<string[]>([])
  const [shouldShowSettings, setShouldShowSettings] = useState(false)

  const runId = useRef<string>(runIdProp ?? crypto.randomUUID())

  const {
    suggestedEmails,
    emailStrategy,
    reset: resetEmailRecommendations,
    isLoading: loadingSuggestions,
    toolStream,
    mostRecentTool,
    researchResults
  } = useEmailRecommendations(itemId, {
    runId: runId.current,
    feedback
  })

  useEffect(() => {
    // if the runId is provided, we need to reset the email recommendations
    if (runIdProp && runIdProp !== runId.current) {
      setShouldShowSettings(false)
      resetEmailRecommendations()
      runId.current = runIdProp
    }
  }, [runIdProp, resetEmailRecommendations])

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      setShouldShowSettings(false)

      resetEmailRecommendations()
      runId.current = crypto.randomUUID()

      setFeedback([instructions])
    },
    [instructions, resetEmailRecommendations]
  )

  const presets = [
    { label: 'Increase urgency', instruction: 'Add a sense of urgency and time sensitivity to drive immediate action' },
    { label: 'Highlight value', instruction: 'Emphasize the specific value and ROI the prospect will receive' },
    { label: 'Address objections', instruction: 'Anticipate and address common objections the prospect might have' },
    { label: 'Clear next steps', instruction: 'Make the call-to-action and next steps extremely clear and actionable' },
    {
      label: 'Book a meeting',
      instruction: "Make it clear that you want to book a meeting to discuss the prospect's needs in more detail"
    },
    { label: 'Offer help', instruction: 'Offer to help the prospect with their needs or questions' }
  ]

  const handleReplaceEmail = (email: EmailProps) => {
    onSelect(email)
  }

  const [selectedEmail, setSelectedEmail] = useState<EmailProps>()
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  const onSubmitFeedback = useCallback(
    (feedbackType: string, feedbackComment: string) => {
      setShowFeedbackModal(false)

      sendComposerFeedback(itemId, feedbackComment, feedbackType, {
        email: selectedEmail?.body || ''
      })

      toast.info('Thanks for your feedback!', {
        description: 'We will use this to improve email suggestions for your team.',
        dismissible: true,
        closeButton: true,
        position: 'top-center'
      })
    },
    [itemId, selectedEmail]
  )

  return (
    <Box
      width="480px"
      height="100%"
      maxHeight="calc(100vh - 32px)"
      bg="white"
      border="1px solid"
      borderColor="gray.200"
      shadow="heavy"
      roundedTop="md"
      display="flex"
      flexDirection="column"
      as="form"
      onSubmit={handleSubmit}
      overflow="hidden"
    >
      {/* Header */}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        pl={4}
        pr={2}
        py={2}
        borderBottomWidth="1px"
        borderColor="gray.200"
        bg="gray.50"
        roundedTop="md"
        flex="none"
      >
        {onSelectProspect && (
          <IconButton
            icon={<Icon as={IconChevronLeft} boxSize={4} />}
            size="xs"
            variant="ghost"
            aria-label="Close"
            onClick={() => onSelectProspect(null)}
          />
        )}
        <Heading size="xs" fontWeight="semibold" display="flex" alignItems="center" gap={2}>
          <Icon as={IconSparkles} boxSize={4} />
          Email Suggestions
        </Heading>
        <IconButton
          icon={<Icon as={IconX} boxSize={4} />}
          size="xs"
          variant="ghost"
          aria-label="Close"
          onClick={onClose}
        />
      </Flex>

      {showFeedbackModal && (
        <Stack p="4" h="100%">
          <ComposerFeedback
            onSubmit={(feedbackType, feedbackComment) => onSubmitFeedback(feedbackType, feedbackComment)}
            onLeave={() => setShowFeedbackModal(false)}
          />
        </Stack>
      )}

      {!showFeedbackModal && (
        <Flex direction="column" height="100%" overflow="hidden">
          {/* Scrollable content */}
          <Box flex="1" overflowY="auto" px="4" pt="4">
            <Stack spacing={4} pb={4}>
              {prospects && prospects?.length > 0 && onSelectProspect && (
                <Stack spacing={1} pb="2">
                  <Heading size="xs" fontWeight="semibold">
                    Prospects
                  </Heading>
                  <ComboboxWithSearch
                    items={prospects}
                    selectedItem={selectedProspect}
                    onChange={(item) => onSelectProspect(item ?? null)}
                    itemRenderer={({ item }) => (
                      <Stack spacing={0}>
                        <Text fontSize="sm">{item?.record.display_name}</Text>
                        {item?.record.title ? (
                          <TextEllipsis fontSize="13px" color="gray.500" tooltip>
                            {[item?.record.title, item?.record.company?.name].filter(Boolean).join(' @ ')}
                          </TextEllipsis>
                        ) : (
                          <TextEllipsis fontSize="13px" color="gray.500" tooltip>
                            {item?.record.email ? item?.record.simple_location : item?.record.simple_location}
                          </TextEllipsis>
                        )}
                      </Stack>
                    )}
                    selectButtonRenderer={({ item }) => (
                      <Stack spacing={-1}>
                        <TextEllipsis fontSize="sm">{item?.record.display_name}</TextEllipsis>
                        {item?.record.title ? (
                          <TextEllipsis fontSize="13px" color="gray.500" tooltip>
                            {[item?.record.title, item?.record.company?.name].filter(Boolean).join(' @ ')}
                          </TextEllipsis>
                        ) : (
                          <TextEllipsis fontSize="13px" color="gray.500" tooltip>
                            {item?.record.email ? item?.record.simple_location : item?.record.simple_location}
                          </TextEllipsis>
                        )}
                      </Stack>
                    )}
                  />
                </Stack>
              )}

              <Stack spacing={4}>
                {loadingSuggestions && !emailStrategy && researchResults.length === 0 && toolStream.length === 0 && (
                  <Heading size="xs" fontWeight={loadingSuggestions ? 'semibold' : 'normal'}>
                    <PulsingText>Thinking...</PulsingText>
                  </Heading>
                )}

                {researchResults.length > 0 && (
                  <ResearchResults researchResults={researchResults} isLoading={loadingSuggestions} />
                )}

                {!researchResults.length && toolStream && toolStream.length > 0 && (
                  <ToolStream toolStream={toolStream} isLoading={loadingSuggestions} mostRecentTool={mostRecentTool} />
                )}

                {emailStrategy && <EmailStrategy strategy={emailStrategy} isLoading={loadingSuggestions} />}

                <EmailSuggestions
                  onSelectEmail={(email, feedbackType) => {
                    setSelectedEmail(email)

                    if (feedbackType === 'negative') {
                      setShowFeedbackModal(true)
                    } else {
                      onSubmitFeedback(feedbackType, '')
                    }
                  }}
                  itemId={itemId}
                  suggestedEmails={suggestedEmails}
                  onReplaceEmail={handleReplaceEmail}
                />
              </Stack>
            </Stack>
          </Box>

          {/* Fixed instructions form */}
          <Stack flex="none" p={4} borderTop="1px solid" borderColor="gray.200" spacing={4} bg="gray.50">
            <Button
              leftIcon={<Icon as={shouldShowSettings ? IconChevronDown : IconWand} boxSize={4} />}
              size="sm"
              variant="ghost"
              isLoading={loadingSuggestions && feedback.length > 0}
              loadingText="Rewriting..."
              colorScheme="purple"
              onClick={() => setShouldShowSettings(!shouldShowSettings)}
            >
              {shouldShowSettings ? '' : 'Rewrite'}
            </Button>

            <Collapse in={shouldShowSettings}>
              <FormControl>
                <FormLabel fontSize="13px">Quick Actions</FormLabel>
                <Flex gap={2} wrap="wrap" mb={4}>
                  {presets.map((preset) => (
                    <Button
                      key={preset.label}
                      size="xs"
                      variant="outline"
                      colorScheme="purple"
                      onClick={() => setInstructions(preset.instruction)}
                    >
                      {preset.label}
                    </Button>
                  ))}
                </Flex>

                <FormLabel fontSize="13px">Instructions</FormLabel>
                <AutosizedTextarea
                  size="sm"
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                  placeholder="How would you like to rephrase this email? (e.g. 'Make it more formal', 'Be more concise', 'Sound more friendly')"
                  minHeight="100px"
                  bg="white"
                  maxHeight="150px"
                  overflowY="auto"
                />
                <FormHelperText fontSize="xs">
                  Click a quick action above or provide custom instructions for how you'd like the email to be
                  rephrased.
                </FormHelperText>
              </FormControl>

              <Flex justify="flex-end">
                <Button
                  type="submit"
                  size="sm"
                  colorScheme="purple"
                  variant="solid"
                  leftIcon={<Icon as={IconWand} boxSize={3.5} />}
                  isLoading={loadingSuggestions && feedback.length > 0}
                  loadingText="Rewriting..."
                >
                  Rewrite
                </Button>
              </Flex>
            </Collapse>
          </Stack>
        </Flex>
      )}
    </Box>
  )
}
