import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconDotsVertical, IconPlus, IconTrash, IconUsers } from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import router from '../../lib/router'
import { useDeleteTemplateView } from '../data/use-delete-template-view'
import { useMissionControlTemplateViews } from '../data/use-mission-control-template-views'
import { ComboboxWithSearch } from './ComboboxWithSearch'
import { DeleteConfirmation } from './DeleteConfirmation'
import { BuildingIcon } from './icons'
import { projectPath } from './ProjectsContext'
import SquareIcon from './SquareIcon'

const emptyArray = []

export type PartialAccountView = {
  id?: string
  name: string
  kind?: 'account' | 'profile'
}

interface TemplateViewSelectorProps {
  templateId?: string
  selectedViewId?: string | null
  onChange?: (accountView: PartialAccountView | null) => void
  onCreateTemplateView?: () => void
}

export function TemplateViewSelector({
  templateId,
  selectedViewId,
  onChange,
  onCreateTemplateView
}: TemplateViewSelectorProps) {
  const params = new URLSearchParams(window.location.search)
  const userId = params.get('user')
  const { data, refetch, isLoading } = useMissionControlTemplateViews({ userId: userId, templateId })

  const selected = useMemo(() => {
    return data?.views?.find((u) => u.id === selectedViewId) || null
  }, [data?.views, selectedViewId])

  const { mutateAsync: deleteTemplateView, isPending: deleting } = useDeleteTemplateView()
  const [currentId, setCurrentId] = React.useState<string | null>(null)
  const deletion = useDisclosure()

  const onDeleteRequest = deletion.onOpen

  const handleDelete = useCallback(
    (item: PartialAccountView) => {
      setCurrentId(item.id!)
      onDeleteRequest()
    },
    [onDeleteRequest]
  )

  const wrappedItemRenderer = useCallback(
    (props) => <AccountViewRenderer {...props} onDelete={handleDelete} />,
    [handleDelete]
  )

  const handleChange = useCallback(
    (accountView) => {
      onChange?.(accountView || null)
    },
    [onChange]
  )

  useEffect(() => {
    const handleListRemoved = async () => {
      try {
        const res = await refetch()
        // current view can't be found
        if (!res.data?.views.find((u) => u.id === selectedViewId)) {
          router.visit(projectPath(`/mission-control/templates/${templateId}`) + window.location.search)
        }
      } catch (error) {
        console.error(error)
      }
    }

    window.addEventListener('templatelistremoved', handleListRemoved)

    return () => {
      window.removeEventListener('templatelistremoved', handleListRemoved)
    }
  }, [refetch, templateId, selectedViewId])

  return (
    <Box>
      <ComboboxWithSearch
        items={data?.views || emptyArray}
        selectedItem={selected || null}
        onChange={handleChange}
        filterItem={(a, val) => a.name?.toLowerCase().includes(val) || false}
        itemToString={(item) => item?.name || 'Unknown'}
        isLoading={isLoading}
        itemRenderer={wrappedItemRenderer}
        selectButtonRenderer={AccountViewRenderer}
        triggerProps={{
          py: 1,
          px: 1.5,
          border: 'none',
          gap: 1,
          _hover: { bg: 'gray.100' }
        }}
        popoverProps={{
          maxW: '300px'
        }}
        popperOptions={{
          matchWidth: false,
          placement: 'bottom-start'
        }}
        footer={
          onCreateTemplateView
            ? (combobox) => {
                return (
                  <Stack spacing={0}>
                    <Divider />

                    <Box padding={1}>
                      <Flex
                        as="button"
                        alignItems="center"
                        gap={1.5}
                        fontSize="sm"
                        fontWeight="medium"
                        rounded="md"
                        outline="none"
                        width="100%"
                        userSelect="none"
                        color="gray.700"
                        paddingX={2}
                        paddingY={1.5}
                        _focus={{ bg: 'gray.100' }}
                        _hover={{ bg: 'gray.100' }}
                        onClick={() => {
                          onCreateTemplateView()
                          combobox.closeMenu()
                        }}
                      >
                        <Icon as={IconPlus} boxSize={3.5} />
                        Create new template list
                      </Flex>
                    </Box>
                  </Stack>
                )
              }
            : undefined
        }
      />

      <DeleteConfirmation
        isCentered
        isOpen={deletion.isOpen && !!currentId}
        title={`Are you sure?`}
        confirmLabel="Delete template list"
        onClose={() => {
          deletion.onClose()
          setCurrentId(null)
        }}
        onConfirm={(event) => {
          event.preventDefault()

          if (!deleting && currentId && templateId) {
            deleteTemplateView({ templateId: templateId, viewId: currentId })
          }
        }}
      >
        <Text fontSize="sm" color="gray.600">
          Are you sure you want to delete this list?
        </Text>
      </DeleteConfirmation>
    </Box>
  )
}

interface AccountViewRendererProps {
  item: PartialAccountView | null
  selectedItem?: PartialAccountView | null
  isToggleButton?: boolean
  onDelete?: (item: PartialAccountView) => void
}

export function AccountViewRenderer({ item, isToggleButton, onDelete }: AccountViewRendererProps) {
  if (!item) {
    return null
  }

  const isAccountView = item.kind === 'account'
  const name = item.name || 'Unknown'

  return (
    <Flex width="100%" alignItems="center" gap={1.5} isTruncated>
      <HStack flex="1 1 auto" spacing={1.5} title={name} isTruncated>
        <SquareIcon
          colorScheme={isAccountView ? 'purple' : 'blue'}
          icon={isAccountView ? BuildingIcon : IconUsers}
          iconSize="14px"
          padding="2px"
        />
        <Text flex="1 1 auto" fontSize="sm" fontWeight="medium" isTruncated>
          {name}
        </Text>
      </HStack>
      {!isToggleButton && <OptionsMenu item={item} onDelete={() => onDelete?.(item)} />}
    </Flex>
  )
}

function OptionsMenu(props: { item: PartialAccountView; onDelete: () => void }) {
  return (
    <Menu placement="right-start">
      <MenuButton
        size="tiny"
        as={IconButton}
        icon={<IconDotsVertical size={15} />}
        variant="ghost"
        borderColor="gray.200"
        onClick={(e) => {
          e.stopPropagation()
        }}
      />
      <MenuList fontSize="sm" zIndex="popover">
        <MenuItem
          icon={<IconTrash size={16} />}
          color="red.500"
          onClick={(e) => {
            e.stopPropagation()
            props.onDelete()
          }}
        >
          Delete list
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
