import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { PageMeta } from '../../types/PageMeta'
import { HighlightedAccount } from '../pages/icps/types'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  accounts: HighlightedAccount[]
  page_meta: PageMeta
}

interface Props {
  perPage?: number
  page?: number
}

export function useMyAccounts(props: Props = {}) {
  const { perPage = 10, page = 1 } = props
  const project = useCurrentProject()

  const basePath = `/views/mine.json?per_page=${perPage}&page=${page}`
  const path = projectPath(basePath)

  return useQuery<Data>({
    queryKey: ['my_accounts', { projectId: project?.id }],
    queryFn: () => concurrentGET<Data>(path),
    enabled: Boolean(project?.id)
  })
}
