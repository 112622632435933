import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconArrowLeft, IconBrain, IconX } from '@tabler/icons-react'
import React, { useState } from 'react'
import { AutosizedTextarea } from '../AutosizedTextarea'
import { ThumbsDownIcon, ThumbsUpIcon } from '../icons'

export function ComposerFeedback({
  onSubmit,
  onLeave
}: {
  onSubmit: (feedbackType: string, feedbackComment: string) => void
  onLeave: () => void
}) {
  const [feedbackType, setFeedbackType] = useState<string>('')
  const [feedbackComment, setFeedbackComment] = useState<string>('')

  return (
    <Stack spacing={4} fontSize="sm" h="100%" alignItems="space-between">
      <Heading size="xs" fontWeight="semibold">
        Tell us what you think
      </Heading>

      <RadioGroup value={feedbackType} onChange={setFeedbackType}>
        <Stack spacing={3}>
          <Box
            onClick={() => setFeedbackType('helpful')}
            p={3}
            borderWidth="1px"
            borderRadius="md"
            cursor="pointer"
            position="relative"
            _hover={{ bg: 'gray.50' }}
            bg={feedbackType === 'helpful' ? 'blue.50' : 'white'}
            borderColor={feedbackType === 'helpful' ? 'blue.500' : 'gray.200'}
            boxShadow={feedbackType === 'helpful' ? '0 0 0 1px var(--chakra-colors-blue-500)' : 'none'}
          >
            <Radio value="helpful" position="absolute" opacity={0} />
            <HStack align="flex-start" spacing={3}>
              <Icon as={ThumbsUpIcon} color="green.500" boxSize={5} mt={1} />
              <Box>
                <Text fontWeight="medium">Helpful</Text>
                <Text fontSize="sm" color="gray.600">
                  The email was helpful and relevant to the play and prospect.
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box
            onClick={() => setFeedbackType('not_helpful')}
            p={3}
            borderWidth="1px"
            borderRadius="md"
            cursor="pointer"
            position="relative"
            _hover={{ bg: 'gray.50' }}
            bg={feedbackType === 'not_helpful' ? 'blue.50' : 'white'}
            borderColor={feedbackType === 'not_helpful' ? 'blue.500' : 'gray.200'}
            boxShadow={feedbackType === 'not_helpful' ? '0 0 0 1px var(--chakra-colors-blue-500)' : 'none'}
          >
            <Radio value="not_helpful" position="absolute" opacity={0} />
            <HStack align="flex-start" spacing={3}>
              <Icon as={ThumbsDownIcon} color="red.500" boxSize={5} mt={1} />
              <Box>
                <Text fontWeight="medium">Not Helpful</Text>
                <Text fontSize="sm" color="gray.600">
                  The email was not helpful or relevant to the play and prospect.
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box
            onClick={() => setFeedbackType('incorrect')}
            p={3}
            borderWidth="1px"
            borderRadius="md"
            cursor="pointer"
            position="relative"
            _hover={{ bg: 'gray.50' }}
            bg={feedbackType === 'incorrect' ? 'blue.50' : 'white'}
            borderColor={feedbackType === 'incorrect' ? 'blue.500' : 'gray.200'}
            boxShadow={feedbackType === 'incorrect' ? '0 0 0 1px var(--chakra-colors-blue-500)' : 'none'}
          >
            <Radio value="incorrect" position="absolute" opacity={0} />
            <HStack align="flex-start" spacing={3}>
              <Icon as={IconX} color="orange.500" boxSize={5} mt={1} />
              <Box>
                <Text fontWeight="medium">Incorrect Information</Text>
                <Text fontSize="sm" color="gray.600">
                  The email contains factual errors or wrong information
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box
            onClick={() => setFeedbackType('hallucinating')}
            p={3}
            borderWidth="1px"
            borderRadius="md"
            cursor="pointer"
            position="relative"
            _hover={{ bg: 'gray.50' }}
            bg={feedbackType === 'hallucinating' ? 'blue.50' : 'white'}
            borderColor={feedbackType === 'hallucinating' ? 'blue.500' : 'gray.200'}
            boxShadow={feedbackType === 'hallucinating' ? '0 0 0 1px var(--chakra-colors-blue-500)' : 'none'}
          >
            <Radio value="hallucinating" position="absolute" opacity={0} />
            <HStack align="flex-start" spacing={3}>
              <Icon as={IconBrain} color="purple.500" boxSize={5} mt={1} />
              <Box>
                <Text fontWeight="medium">Hallucinating</Text>
                <Text fontSize="sm" color="gray.600">
                  The email contains made-up or fabricated information
                </Text>
              </Box>
            </HStack>
          </Box>
        </Stack>
      </RadioGroup>

      <FormControl>
        <FormLabel>Additional Comments</FormLabel>
        <AutosizedTextarea
          value={feedbackComment}
          size="sm"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFeedbackComment(e.target.value)}
          placeholder="Add any additional comments..."
          minHeight="100px"
          h="100%"
        />
      </FormControl>

      <Flex flex="1" h="100%" />

      <ButtonGroup as={HStack} justify="flex-end" spacing="0.5">
        <Button leftIcon={<Icon as={IconArrowLeft} boxSize={4} />} variant="outline" size="sm" onClick={onLeave}>
          Go back
        </Button>
        <Button
          size="sm"
          disabled={!feedbackType}
          colorScheme={'purple'}
          onClick={() => onSubmit(feedbackType, feedbackComment)}
        >
          Submit
        </Button>
      </ButtonGroup>
    </Stack>
  )
}
