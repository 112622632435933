import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { concurrentGET, del, post, put } from '../../lib/api'
import { Persona } from '../pages/prospects/personas'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface PersonasResponse {
  personas: Persona[]
}

export function usePersonas() {
  const project = useCurrentProject()
  const basePath = `/personas.json`
  const path = projectPath(basePath)

  const queryClient = useQueryClient()

  const base = useQuery<PersonasResponse>({
    queryKey: ['personas', { projectId: project?.id }],
    queryFn: () => concurrentGET<PersonasResponse>(path),
    enabled: Boolean(project?.id)
  })

  return {
    ...base,
    reload: () => {
      queryClient.invalidateQueries({
        queryKey: ['personas', { projectId: project?.id }]
      })
      base.refetch()
    }
  }
}

interface PersonaResponse {
  persona: Persona
}

export function useSavePersona() {
  return useMutation({
    mutationFn: (persona: Partial<Persona>) => {
      const path = projectPath(`/personas/${persona.id || ''}`)

      const updates: Partial<Persona> = {}

      if (persona.name) {
        updates.name = persona.name
      }

      if (persona.description) {
        updates.description = persona.description
      }

      if (persona.creativity) {
        updates.creativity = persona.creativity
      }

      if (persona.persona_type) {
        updates.persona_type = persona.persona_type
      }

      if (persona.filters) {
        updates.filters = persona.filters
      }

      const payload = {
        persona: updates
      }

      if (persona.id) {
        return put<PersonaResponse>(path, payload)
      } else {
        return post<PersonaResponse>(path, payload)
      }
    },

    onError: (error: Error) => {
      toast.error(`Error saving persona: ${error?.message || 'Unknown error'}`)
    },

    onSuccess: () => {
      toast.success('Persona saved')
    }
  })
}

export function useDeletePersona() {
  return useMutation({
    mutationFn: (id: string) => {
      const path = projectPath(`/personas/${id}`)
      return del(path)
    },

    onError: (error: Error) => {
      toast.error(`Error deleting persona: ${error?.message || 'Unknown error'}`)
    },

    onSuccess: () => {
      toast.success('Persona deleted')
    }
  })
}
