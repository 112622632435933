import { useQuery } from '@tanstack/react-query'
import { concurrentCachedGET } from '../../lib/api'
import { AccountEvent } from '../../types/AccountEvent'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  data: AccountEvent[]
}

interface Props {
  accountId: string
  source?: 'g2'
  event_type?: string
  event?: string
  page?: number
  page_size?: number
  order?: 'asc' | 'desc'
}

function buildQueryString(params: Record<string, any>): string {
  const query = Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
  return query ? `?${query}` : ''
}

export function useAccountEvents(props: Props) {
  const project = useCurrentProject()
  const basePath = `/accounts/${props.accountId}/events.json`

  const queryString = buildQueryString({
    source: props.source,
    event_type: props.event_type,
    page: props.page,
    page_size: props.page_size,
    order: props.order
  })

  const path = projectPath(`${basePath}${queryString}`)

  return useQuery<Data>({
    queryKey: [
      'accountEvents',
      {
        projectId: project?.id,
        accountId: props.accountId,
        query: queryString
      }
    ],

    queryFn: () => concurrentCachedGET<Data>(path),
    enabled: Boolean(project?.id && props.accountId)
  })
}
