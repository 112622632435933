import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { AccountView } from '../../types/AccountView'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export type MissionControlTemplateView = Pick<AccountView, 'id' | 'name' | 'kind'> & {
  permalink: string
  position: number
}

interface Data {
  views: MissionControlTemplateView[]
}

interface Props {
  userId?: string | null
  templateId?: string
}

export function useMissionControlTemplateViews(props: Props = {}) {
  const project = useCurrentProject()
  const search = new URLSearchParams()

  if (props.userId) {
    search.set('user', props.userId)
  }

  const path = projectPath(`/mission-control/templates/${props.templateId}/views.json?${search.toString()}`)

  return useQuery<Data>({
    queryKey: ['mission-control-views', { projectId: project?.id, userId: props.userId, templateId: props.templateId }],
    queryFn: () => concurrentGET<Data>(path),
    enabled: Boolean(project?.id)
  })
}
