import { FormControl, FormHelperText, FormLabel, Input, Select, Stack, Switch } from '@chakra-ui/react'
import React from 'react'
import { SalesforceActionProps } from './salesforce-setup'

export function CreateTask(
  props: SalesforceActionProps & {
    targetType?: 'Account' | 'Profile'
  }
) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.salesforce?.create_task?.enabled))

  return (
    <Stack spacing="4">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              salesforce: {
                ...props.delivery_rules?.salesforce,
                create_task: {
                  ...props.delivery_rules?.salesforce?.create_task,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={enabled ? props.colorScheme : 'gray'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][salesforce][create_task][enabled]"
        >
          Create Task
        </Switch>
        {!props.compact && (
          <FormHelperText>
            {`Create a task for the current ${props.targetType === 'Profile' ? 'Visitor' : 'Account'} in Salesforce if it doesn't already exist.`}
          </FormHelperText>
        )}
      </FormControl>

      {enabled && (
        <>
          <FormControl>
            <FormLabel>Subject</FormLabel>
            <Input
              type="text"
              size="sm"
              rounded="md"
              placeholder="Subject"
              name="follow_rule[delivery_rules][salesforce][create_task][subject]"
              defaultValue={props.delivery_rules?.salesforce?.create_task?.subject}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Priority</FormLabel>
            <Select
              size="sm"
              rounded="md"
              name="follow_rule[delivery_rules][salesforce][create_task][priority]"
              defaultValue={props.delivery_rules?.salesforce?.create_task?.priority}
            >
              <option value="high">High</option>
              <option value="normal">Normal</option>
              <option value="low">Low</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Due Date</FormLabel>
            <Select
              size="sm"
              rounded="md"
              name="follow_rule[delivery_rules][salesforce][create_task][due_date]"
              defaultValue={props.delivery_rules?.salesforce?.create_task?.due_date}
            >
              <option value="unset">Not set</option>
              <option value="day">1 day</option>
              <option value="week">1 week</option>
              <option value="two_weeks">2 weeks</option>
              <option value="month">1 month</option>
            </Select>
            <FormHelperText>How long since Task creation to set the due date for.</FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel>Send Reminder</FormLabel>
            <Select
              size="sm"
              rounded="md"
              name="follow_rule[delivery_rules][salesforce][create_task][reminder_date]"
              defaultValue={props.delivery_rules?.salesforce?.create_task?.reminder_date}
            >
              <option value="unset">Not set</option>
              <option value="day">In 1 day</option>
              <option value="week">In 1 week</option>
              <option value="two_weeks">In 2 weeks</option>
              <option value="month">In 1 month</option>
            </Select>
            <FormHelperText>When should the Task reminder date be set to from Task creation time.</FormHelperText>
          </FormControl>
        </>
      )}
    </Stack>
  )
}
