import {
  ButtonProps,
  Flex,
  FlexProps,
  Input,
  InputProps,
  List,
  ListItem,
  ListProps,
  Portal,
  Text,
  usePopper,
  UsePopperProps
} from '@chakra-ui/react'
import { useCombobox, UseComboboxProps } from 'downshift'
import React, { useCallback, useEffect, useState } from 'react'

interface Props<T>
  extends Pick<
      UseComboboxProps<T>,
      | 'items'
      | 'itemToString'
      | 'isOpen'
      | 'inputValue'
      | 'initialInputValue'
      | 'onSelectedItemChange'
      | 'defaultIsOpen'
      | 'selectedItem'
      | 'onIsOpenChange'
      | 'stateReducer'
    >,
    FlexProps {
  renderItem?: (props: { item: T }) => React.ReactNode
  onInputValueChange?: ({ inputValue }: { inputValue?: string | undefined }) => void
  openOnFocus?: boolean
  itemFilter?: (item: T[] | null, inputValue: string | undefined) => T[]
  inputProps?: InputProps
  menuProps?: ListProps
  colorScheme?: ButtonProps['colorScheme']
  popperOptions?: UsePopperProps
  noResultsLabel?: string
}

function defaultItemToString<T>(item: T | null) {
  return String(item)
}

function defaultItemFilter<T>(
  input: T[] | null,
  inputValue: string | undefined,
  itemToString: (item: T | null) => string
) {
  return (input || []).filter((item) =>
    itemToString(item)
      .toLowerCase()
      .includes((inputValue || '').toLowerCase())
  )
}

function Combobox<T = string>({
  items: originalItems,
  itemToString = defaultItemToString,
  renderItem = ({ item }: { item: T }) => <Text paddingX="8px">{itemToString(item)}</Text>,
  itemFilter,
  inputProps,
  menuProps,
  onInputValueChange,
  onIsOpenChange,
  openOnFocus = false,
  defaultIsOpen = false,
  selectedItem,
  onSelectedItemChange,
  initialInputValue = '',
  inputValue: incomingInputValue,
  colorScheme = 'gray',
  isOpen: incomingIsOpen,
  stateReducer,
  popperOptions,
  noResultsLabel = 'No results found',
  ...rest
}: Props<T>) {
  const [items, setItems] = useState<T[]>(originalItems)

  const isUncontrolled = incomingInputValue === undefined || incomingInputValue === null

  const handleItemFilter = useCallback(
    (items: T[], inputValue: string | undefined) => {
      if (itemFilter) {
        return itemFilter(items, inputValue)
      }

      return defaultItemFilter(items, inputValue, itemToString)
    },
    [itemFilter, itemToString]
  )

  const handleInputValueChange = useCallback(
    ({ inputValue }: { inputValue?: string | undefined }) => {
      if (isUncontrolled) {
        setItems(handleItemFilter(originalItems, inputValue))
      }

      onInputValueChange?.({ inputValue })
    },
    [onInputValueChange, originalItems, isUncontrolled, handleItemFilter]
  )

  useEffect(() => {
    setItems(originalItems)
  }, [originalItems])

  const { popperRef, referenceRef } = usePopper({ placement: 'bottom-start', matchWidth: true, ...popperOptions })

  const { getComboboxProps, getInputProps, getMenuProps, highlightedIndex, isOpen, getItemProps, openMenu } =
    useCombobox({
      items,
      itemToString,
      initialInputValue,
      inputValue: incomingInputValue,
      selectedItem,
      defaultIsOpen,
      onInputValueChange: handleInputValueChange,
      onSelectedItemChange,
      onIsOpenChange,
      // downshift gets super mad if this is `undefined`
      ...(stateReducer && { stateReducer })
    })

  const onFocus = useCallback(() => {
    if (!isOpen) {
      openMenu()
    }
  }, [isOpen, openMenu])

  return (
    <div ref={referenceRef} style={{ width: '100%', position: 'relative' }}>
      <Flex width="100%" {...getComboboxProps()} {...rest}>
        <Input
          {...getInputProps({
            onClick: openOnFocus ? onFocus : undefined,
            onFocus: openOnFocus ? onFocus : undefined
          })}
          width="100%"
          {...inputProps}
          background="white"
          outline="none"
          _focus={{ border: undefined }}
        />
      </Flex>
      <Portal>
        <List
          {...getMenuProps({ ref: popperRef }, { suppressRefError: true })}
          marginTop={1}
          width="100%"
          maxHeight={400}
          zIndex="popover"
          position="absolute"
          overflowY="auto"
          scrollBehavior="smooth"
          overscrollBehavior="contain"
          visibility={isOpen ? 'visible' : 'hidden'}
          fontSize="sm"
          shadow="md"
          bg="white"
          rounded="md"
          borderWidth="1px"
          borderColor="gray.200"
          p={1}
          {...menuProps}
        >
          {isOpen &&
            items.slice(0, 50).map((item, i) => {
              const isHighlighted = i === highlightedIndex
              return (
                <Flex
                  key={i + itemToString(item)}
                  as="li"
                  {...getItemProps({ item, index: i })}
                  background={isHighlighted ? `${colorScheme}.100` : 'white'}
                  p={2}
                  cursor="pointer"
                  rounded="md"
                >
                  {renderItem({ item })}
                </Flex>
              )
            })}
          {isOpen && items.length === 0 && (
            <ListItem py={2} fontStyle="italic" textAlign="center" color="gray.500">
              {noResultsLabel}
            </ListItem>
          )}
        </List>
      </Portal>
    </div>
  )
}

export default Combobox
