import { projectPath } from '@app/components/ui/ProjectsContext'
import { post } from '@app/lib/api'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { ProfileRecord } from '../../types/Profile'
import { profilePath } from '../pages/profiles/lib/path'

interface ResponseData {
  crm_entity_id: string
  permalink: string
}

interface Params {
  domain: string
  app_module: string
}

export function useImportToCRM() {
  return useMutation({
    mutationFn: ({ domain, app_module }: Params) => {
      const path = projectPath(`/accounts/${domain}/import`)
      return post<ResponseData>(path, { app_module: app_module })
    }
  })
}

interface AccountResponseData {
  accounts: []
}

interface AccountParams {
  account_ids: string[]
  app_module: string
  type?: string
}

export function useImportAccountsToCrm() {
  return useMutation<AccountResponseData, unknown, AccountParams>({
    mutationFn: (params) => {
      const path = projectPath(`/accounts/import_multiple`)
      return post(path, params)
    }
  })
}

interface ProspectParams {
  prospect_ids: string[]
  app_module: 'Hubspot' | 'Salesforce'
  type?: 'contact' | 'lead' | undefined
}
interface ProspectResponseData {
  prospects: any[]
}

export function useImportProspectsToCrm() {
  return useMutation<ProspectResponseData, unknown, ProspectParams>({
    mutationFn: (params) => {
      const path = projectPath(`/prospects/import`)
      return post(path, params)
    }
  })
}

interface ImportProfilesParams {
  profile_ids: string[]
  app_module: 'Hubspot' | 'Salesforce' | 'Outreach'
  type?: 'contact' | 'lead' | undefined
}
interface ProfilesResponseData {
  profiles: ProfileRecord[]
}

export function useImportProfilesToCrm() {
  return useMutation<ProfilesResponseData, unknown, ImportProfilesParams>({
    mutationFn: (params) => {
      const path = projectPath(`/profiles/import`)
      return post(path, params)
    }
  })
}

interface ImportProfileParams {
  profile_id: string
  app_module: 'Hubspot' | 'Salesforce' | 'Outreach'
  type?: 'contact' | 'lead' | undefined
}

interface ImportProfileResponseData {
  profile: ProfileRecord
}

export function useImportProfileToCrm(
  opts?: UseMutationOptions<ImportProfileResponseData, unknown, ImportProfileParams>
) {
  return useMutation<ImportProfileResponseData, unknown, ImportProfileParams>({
    mutationFn: (params) => {
      const searchParams = new URLSearchParams({ app: params.app_module })

      if (params.type) {
        searchParams.set('type', params.type)
      }

      const path = profilePath({ id: params.profile_id }, `/actions/import?${searchParams.toString()}`)

      return post(path, params)
    },
    ...opts
  })
}
