import { projectPath } from '@app/components/ui/ProjectsContext'
import { post } from '@app/lib/api'
import { useMutation } from '@tanstack/react-query'

interface Params {
  domain: string
  app_module: string
  profile_emails: string[]
  crm_entity_id?: string | null
  outreach_sequences?: number[]
}

export function useImportContactsToCRM() {
  return useMutation({
    mutationFn: ({ domain, profile_emails, crm_entity_id, app_module, outreach_sequences }: Params) => {
      const path = projectPath(`/accounts/${domain}/import/contacts`)
      return post(path, { profile_emails, crm_entity_id, app_module, outreach_sequences })
    }
  })
}
