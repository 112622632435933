import React from 'react'
import {
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Heading,
  Box,
  Button,
  Icon,
  Link,
  HStack
} from '@chakra-ui/react'
import { projectPath } from '../../../ui/ProjectsContext'
import PageLayout from '../../../ui/PageLayout'
import dayjs from 'dayjs'
import { IconArrowLeft } from '@tabler/icons-react'

interface BigqueryDataSyncLog {
  id: string
  status: string
  rows_count: number
  created_at: string
  error_message?: string
}

export default function BigqueryDataSyncLogs(props: { logs: BigqueryDataSyncLog[] }) {
  const { logs } = props

  return (
    <PageLayout>
      <Stack spacing={6}>
        <HStack spacing={2}>
          <Button
            as={Link}
            variant="ghost"
            leftIcon={<Icon as={IconArrowLeft} />}
            href={projectPath('/apps/bigquery')}
            size="sm"
          />
          <Heading size="md">BigQuery Data Sync Logs</Heading>
        </HStack>

        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Timestamp</Th>
                <Th>Status</Th>
                <Th>Rows Count</Th>
              </Tr>
            </Thead>
            <Tbody>
              {logs.map((log) => (
                <Tr key={log.id}>
                  <Td>{dayjs(log.created_at).format('MMM D, YYYY HH:mm:ss')}</Td>
                  <Td>
                    {log.status === 'error' ? (
                      <Text color="red.500">
                        {log.status} | {log.error_message}
                      </Text>
                    ) : (
                      <Text color="green.500">{log.status}</Text>
                    )}
                  </Td>
                  <Td>{log.rows_count}</Td>
                </Tr>
              ))}
              {logs.length === 0 && (
                <Tr>
                  <Td colSpan={6} textAlign="center" py={8}>
                    <Text color="gray.500">No data sync logs found</Text>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </Stack>
    </PageLayout>
  )
}
