import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Button,
  Code,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  OrderedList,
  Stack,
  Text,
  Textarea,
  UnorderedList
} from '@chakra-ui/react'
import { IconCheck, IconExclamationMark, IconPlugConnected, IconTable } from '@tabler/icons-react'
import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import { post } from '../../../../lib/api'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import CircleIcon from '../../../ui/CircleIcon'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { Toggle } from '../../accounts/components/Toggle'
import { TestResult, DataSync, DataSyncSettings } from '../../../ui/DataSyncSettings'
import { DataWrite, DataWriteSettings } from '../../../ui/DataWriteSettings'

interface Props {
  app_id: string
  title: string
  description: string
  logo: string
  settings: {
    account_url?: string
    account?: string
    organization?: string
    username?: string
    warehouse?: string
    database?: string
    schema?: string
    private_key?: string
    koala_s3_aws_key_id?: string
    koala_s3_aws_secret_key?: string
    data_syncs?: DataSync[]
    data_writes?: DataWrite[]
  }
  secrets: string[]
  connected?: boolean
  deps: {
    last_successful_syncs: Record<string, string>
    data_write_requested: boolean
    data_write_available: boolean
    data_write_models: DataWrite[]
  }
}

export default function Show(props: Props) {
  const { hasPermission: canManageApps } = usePermission({ on: 'project', action: 'can_manage_apps' })
  const [isChangingPrivateKey, setIsChangingPrivateKey] = useState(false)

  const [accountUrl, setAccountUrl] = useState(props.settings['account_url'] ?? '')
  const [account, setAccount] = useState(props.settings['account'] ?? '')
  const [organization, setOrganization] = useState(props.settings['organization'] ?? '')

  const [queryInput, setQueryInput] = useState(
    'SELECT CURRENT_USER(), CURRENT_ACCOUNT(), CURRENT_REGION(), CURRENT_DATE()'
  )
  const [queryResult, setQueryResult] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [queryError, setQueryError] = useState<string | null>(null)
  const [dataSyncs, setDataSyncs] = useState<DataSync[]>(props.settings.data_syncs ?? [])

  const handleTestQuery = async () => {
    try {
      setIsLoading(true)
      const response = await post<{ result: any }>(projectPath('/apps/snowflake/query'), { query: queryInput })
      setQueryResult(JSON.stringify(response.result, null, 2))
      setQueryError(null)
    } catch (error) {
      setQueryResult(null)
      setQueryError(JSON.stringify(error, null, 2))
    } finally {
      setIsLoading(false)
    }
  }

  const handleTestDataSync = async (dataSync: DataSync) => {
    const type = dataSync.type
    return post<TestResult>(projectPath('/apps/snowflake/query'), { query: dataSync.query, type })
  }

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/snowflake'),
            title: 'Snowflake'
          }
        ]}
        offscreen
      />
      <HStack>
        <Image src={props.logo} maxW="6" />
        <PageTitle>{props.title}</PageTitle>

        {Object.keys(props.settings).length > 0 && (
          <Flex flex="1" justifyContent={'flex-end'}>
            <DisconnectAppDialog appTitle={'Snowflake'} showRemoveCachesOption={false} />
          </Flex>
        )}
      </HStack>
      <PageDescription>{props.description}</PageDescription>

      {props.connected && (
        <>
          <Toggle
            title={
              <HStack mb="2">
                <IconPlugConnected />
                <Heading size="sm" fontWeight={'semibold'}>
                  Connection Test
                </Heading>
              </HStack>
            }
          >
            <Stack as={LightBgCard} bg="gray.50" spacing="4">
              <HStack spacing="4" alignItems={'flex-start'}>
                <FormControl>
                  <FormLabel>Query</FormLabel>
                  <Textarea
                    bg="white"
                    fontSize={'sm'}
                    value={queryInput}
                    onChange={(e) => setQueryInput(e.target.value)}
                    placeholder="Enter your SQL query here..."
                    rows={5}
                    isDisabled={isLoading}
                  />
                  <FormHelperText fontSize={'xs'}>
                    Enter a SQL query to test your connection to Snowflake.
                  </FormHelperText>
                </FormControl>
                {(queryResult || queryError) && (
                  <FormControl>
                    <FormLabel>Result</FormLabel>
                    <Textarea rows={5} value={queryResult ?? queryError ?? ''} isReadOnly fontSize={'sm'} bg="white" />
                    <FormHelperText fontSize={'xs'}>
                      {queryError && (
                        <HStack>
                          <CircleIcon
                            icon={IconExclamationMark}
                            colorScheme="red"
                            p="0"
                            borderColor="red.200"
                            borderWidth="thin"
                          />
                          <Text>Your query returned an error.</Text>
                        </HStack>
                      )}
                      {queryResult && (
                        <HStack>
                          <CircleIcon
                            icon={IconCheck}
                            colorScheme="green"
                            p="0"
                            borderColor="green.200"
                            borderWidth="thin"
                          />
                          <Text>Success!</Text>
                        </HStack>
                      )}
                    </FormHelperText>
                  </FormControl>
                )}
              </HStack>

              <Button
                colorScheme="blue"
                onClick={handleTestQuery}
                mb="4"
                isDisabled={isLoading}
                loadingText="Running Query..."
                isLoading={isLoading}
                size="sm"
              >
                Run Query
              </Button>
            </Stack>
          </Toggle>
          <Divider />
        </>
      )}

      <form method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />

        <Stack spacing="4">
          <Stack spacing="8">
            <Toggle
              defaultIsOpen={!props.connected}
              title={
                <Heading size="sm" fontWeight={'semibold'}>
                  🔐 Auth Settings
                </Heading>
              }
            >
              <LightBgCard as={Stack}>
                <Stack spacing={'4'}>
                  <FormControl id="account_url">
                    <FormLabel>Account URL</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[account_url]"
                      placeholder="https://account-organization.snowflakecomputing.com"
                      type="url"
                      required
                      value={accountUrl}
                      onChange={(e) => {
                        setAccountUrl(e.target.value)
                        const url = new URL(e.target.value)
                        const org = url.hostname.split('.')[0]

                        const urlOrganization = org?.split('-')[0]
                        const urlAccount = org?.split('-')[1]

                        if (urlOrganization && urlAccount) {
                          if (account === '' || !account) {
                            setAccount(urlAccount)
                          }

                          if (organization === '' || !organization) {
                            setOrganization(urlOrganization)
                          }
                        }
                      }}
                    />
                    <FormHelperText>
                      Your Snowflake Account URL. e.g. https://account-organization.snowflakecomputing.com
                    </FormHelperText>
                  </FormControl>

                  <FormControl id="account">
                    <FormLabel>Account</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[account]"
                      placeholder="Snowflake Account"
                      required
                      value={account}
                      onChange={(e) => {
                        setAccount(e.target.value)
                      }}
                    />
                    <FormHelperText>
                      Your Snowflake account name. This is typically the first part of your account URL before the `-`.
                      e.g.
                    </FormHelperText>
                  </FormControl>

                  <FormControl id="organization">
                    <FormLabel>Organization</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[organization]"
                      placeholder="Snowflake Organization"
                      value={organization}
                      onChange={(e) => {
                        setOrganization(e.target.value)
                      }}
                    />
                    <FormHelperText>
                      Your Snowflake organization name (if applicable). This is typically the second part of your
                      account URL after the `-`.
                    </FormHelperText>
                  </FormControl>

                  <FormControl id="username">
                    <FormLabel>Username</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[username]"
                      placeholder="Snowflake Username"
                      defaultValue={props.settings['username'] ?? ''}
                      required
                    />
                  </FormControl>

                  <FormControl id="warehouse">
                    <FormLabel>Warehouse</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[warehouse]"
                      placeholder="Snowflake Warehouse"
                      defaultValue={props.settings['warehouse'] ?? ''}
                      required
                    />
                  </FormControl>

                  <FormControl id="database">
                    <FormLabel>Database Name</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[database]"
                      placeholder="Snowflake Database"
                      defaultValue={props.settings['database'] ?? ''}
                      required
                    />
                  </FormControl>

                  <FormControl id="database_schema">
                    <FormLabel>Database Schema</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[database_schema]"
                      placeholder="Snowflake Database Schema"
                      defaultValue={props.settings['database_schema'] ?? ''}
                    />
                  </FormControl>

                  <FormControl id="private_key">
                    <FormLabel>Private Key</FormLabel>
                    {!props.secrets.includes('private_key') || isChangingPrivateKey ? (
                      <Textarea
                        bg="gray.50"
                        w="100%"
                        name="app_instance_settings[private_key]"
                        placeholder="Snowflake Private Key"
                        defaultValue={props.settings['private_key'] ?? ''}
                        rows={16}
                        required
                      />
                    ) : (
                      <HStack>
                        <Input bg="gray.50" w="100%" type="password" isReadOnly value={'***********************'} />
                        <Button
                          variant={'ghost'}
                          colorScheme="purple"
                          size="sm"
                          onClick={() => setIsChangingPrivateKey(true)}
                        >
                          Change
                        </Button>
                      </HStack>
                    )}
                    <FormHelperText>Your Snowflake private key for authentication.</FormHelperText>
                  </FormControl>
                </Stack>
              </LightBgCard>
            </Toggle>

            <Divider />

            <Stack spacing="4">
              <Toggle
                title={
                  <Heading size="sm" fontWeight={'semibold'}>
                    How to set up Snowflake for Koala
                  </Heading>
                }
              >
                <OrderedList fontSize={'sm'} px="4" spacing="4">
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Generate an RSA key pair:
                    </Text>
                    <UnorderedList spacing={'4'}>
                      <li>
                        Open a terminal and run:{' '}
                        <Code p="4" my="2" display={'block'}>
                          openssl genrsa 2048 | openssl pkcs8 -topk8 -nocrypt -inform PEM -out rsa_key.p8
                        </Code>
                      </li>
                      <li>
                        Generate the public key:{' '}
                        <Code p="4" my="2" display={'block'}>
                          openssl rsa -in rsa_key.p8 -pubout -out rsa_key.pub
                        </Code>
                      </li>
                    </UnorderedList>
                  </li>
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Assign the public key to your Snowflake user:
                    </Text>
                    <UnorderedList spacing={'4'}>
                      <li>Log in to your Snowflake account with admin privileges</li>
                      <li>
                        Copy the contents of the public key file (rsa_key.pub), excluding the "BEGIN PUBLIC KEY" and
                        "END PUBLIC KEY" lines
                        <Code p="4" my="2" display={'block'}>
                          openssl rsa -pubin -in rsa_key.pub -outform DER | openssl base64 -A
                        </Code>
                      </li>
                      <li>
                        Execute the following SQL command in Snowflake:{' '}
                        <Code p="4" my="2" display={'block'}>
                          ALTER USER your_username SET RSA_PUBLIC_KEY='your_public_key_content';
                        </Code>
                        <Text fontSize={'sm'}>
                          <strong>Important:</strong> Snowflake does not like the % character in the public key. If you
                          have a # in your key, remove it before pasting it in.
                        </Text>
                      </li>
                    </UnorderedList>
                  </li>
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Enter the required information in the form above:
                    </Text>
                    <UnorderedList spacing={'4'}>
                      <li>
                        Account URL: Your Snowflake account URL (e.g., https://your_account.snowflakecomputing.com)
                      </li>
                      <li>Account: Your Snowflake account name</li>
                      <li>Username: Your Snowflake username</li>
                      <li>Warehouse: The default warehouse to use</li>
                      <li>Database: The default database to use</li>
                      <li>Organization: Your Snowflake organization name (if applicable)</li>
                      <li>
                        Private Key: The contents of your private key file (rsa_key.p8)
                        <UnorderedList spacing={'2'} mt={'2'}>
                          <li>Open the rsa_key.p8 file in a text editor</li>
                          <li>
                            Copy the entire contents, including the "BEGIN PRIVATE KEY" and "END PRIVATE KEY" lines
                          </li>
                          <li>Paste the copied content into the Private Key field in Koala</li>
                          <li>
                            Alternatively, if you're using macOS, you can use the following command to copy the content
                            directly to your clipboard:{' '}
                            <Code p="4" my="2" display={'block'}>
                              pbcopy &lt; rsa_key.p8
                            </Code>
                          </li>
                        </UnorderedList>
                      </li>
                    </UnorderedList>
                  </li>
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Click "Save" to store your Snowflake credentials securely in Koala
                    </Text>
                  </li>
                </OrderedList>
              </Toggle>
            </Stack>
            <Divider />

            {props.connected && (
              <Toggle
                defaultIsOpen={true}
                title={
                  <HStack>
                    <IconTable size={16} />
                    <Heading size="sm" fontWeight={'semibold'}>
                      Read data from Snowflake
                    </Heading>
                  </HStack>
                }
              >
                <Stack as={LightBgCard} spacing={8} mt="2">
                  <DataSyncSettings
                    appName="snowflake"
                    dataSyncs={dataSyncs}
                    setDataSyncs={(dataSyncs) => {
                      setDataSyncs(dataSyncs)
                    }}
                    onTest={handleTestDataSync}
                    lastSuccessfulSyncs={props.deps.last_successful_syncs}
                  />
                  <Button
                    colorScheme="purple"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      setDataSyncs([
                        ...dataSyncs,
                        { enabled: true, query: '', type: 'traits', object_type: 'Profile', id: nanoid() }
                      ])
                    }}
                  >
                    Add New Model
                  </Button>
                </Stack>
              </Toggle>
            )}

            {props.connected && (
              <DataWriteSettings
                appName="Snowflake"
                available={props.deps.data_write_available}
                requested={props.deps.data_write_requested}
                models={props.deps.data_write_models}
                database={props.settings.database}
                username={props.settings.username}
              />
            )}
          </Stack>
        </Stack>

        <Flex mt="8">
          <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canManageApps}>
            Save
          </Button>
        </Flex>
      </form>
    </PageLayout>
  )
}
