import { Box, Center, Flex, FormControl, FormLabel, HStack, Icon, Link, Spinner, Stack, Text } from '@chakra-ui/react'
import { IconCheck, IconExternalLink, IconUsers } from '@tabler/icons-react'
import React, { useEffect, useMemo } from 'react'
import { AccountView } from '../../../../types/AccountView'
import { Apps } from '../../../../types/App'
import { FollowRule } from '../../../../types/FollowRule'
import { useAccountViews } from '../../../data/use-account-views'
import { filtersAsText, useFacets } from '../../../data/use-facets'
import { ButtonRadioGroupProps, CardRadioGroup } from '../../../ui/CardRadioGroup'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { AdvancedFilterBuilder } from '../../../ui/filters/AdvancedFilterBuilder'
import { filtersForType } from '../../../ui/filters/filters-for-type'
import { AdvancedFilters } from '../../../ui/filters/types'
import { BuildingIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { useSearchParams } from '../../../ui/useSearchState'
import { FacetFilters } from '../../accounts'
import { FilterPreview } from '../../accounts/components/FilterPreview'
import { accountViewPath } from '../../account_views/lib/list-paths'

interface TerritorySetupProps {
  territoryRules?: FollowRule['territory_rules']
  followRule?: FollowRule
  targetType?: 'Profile' | 'Account'
  apps?: Apps
  onFacetsChange?: (facets: FacetFilters) => void
}

type TerritoryPreset = 'smart-list' | 'custom'

function defaultPreset(rule?: FollowRule): TerritoryPreset {
  if (Object.keys(rule?.territory_rules?.facets || {}).length > 0) {
    return 'custom'
  }

  return 'smart-list'
}

export function TSetup(props: TerritorySetupProps) {
  const targetType = props.targetType || 'Account'
  const { searchParams } = useSearchParams()

  const [advancedFilters, setAdvancedFilters] = React.useState<AdvancedFilters>(
    props.followRule?.territory_rules?.facets ?? {}
  )

  const facets = useFacets({
    facet_filters: advancedFilters,
    facetCloudPath: targetType === 'Profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud',
    onFilterChange: setAdvancedFilters
  })

  useEffect(() => {
    setAdvancedFilters(props.followRule?.territory_rules?.facets ?? {})
  }, [props.followRule?.territory_rules?.facets])

  useEffect(() => {
    if (advancedFilters) {
      const filtered = filtersForType(advancedFilters, targetType || 'Account')
      setAdvancedFilters(filtered)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetType])

  const [listId, setListId] = React.useState(
    props.followRule?.account_view_id || props.followRule?.territory_rules?.smart_list
  )
  const [preset, setPreset] = React.useState<TerritoryPreset>(defaultPreset(props.followRule))

  const formParams: FollowRule['territory_rules'] = useMemo(() => {
    if (preset === 'smart-list') {
      return {
        smart_list: listId
      }
    }

    return { facets: advancedFilters || {} }
  }, [preset, advancedFilters, listId])

  const targetLabel = targetType === 'Profile' ? 'People' : targetType === 'Account' ? 'Accounts' : 'Accounts or People'

  return (
    <Stack>
      <Stack pb="4">
        <CardRadioGroup
          size="sm"
          value={preset}
          onChange={(e) => {
            setPreset(e as TerritoryPreset)
          }}
          options={
            [
              {
                label: 'Lists',
                value: 'smart-list',
                description: `Use an existing list to define the ${targetLabel} that should match your filter criteria.`
              },
              {
                label: 'Custom Filters',
                value: 'custom',
                description: `Define your own criteria for filtering ${targetLabel}.`
              }
            ].filter(Boolean) as ButtonRadioGroupProps['options']
          }
        />
      </Stack>

      <Flex py="4" direction={'column'} gap="1">
        {preset !== 'smart-list' && (
          <Box bg="white" rounded="md" border="1px solid" borderColor="gray.200" p={0}>
            <AdvancedFilterBuilder
              filters={advancedFilters}
              onChangeAdvancedFilters={(filters) => {
                setAdvancedFilters(filters)
              }}
              kind={targetType.toLowerCase() as 'account' | 'profile'}
              apps={props.apps}
              // when we only use the advanced filter builder, we need to wrap the top level filters in an _and operator
              convertTopLevelFilters
              // hide intent / 3rd party filters since we want signals/intent triggers to be separate
              shouldShowIntentFilters={false}
              shouldShow3rdPartyFilters={false}
              {...facets}
            />
          </Box>
        )}
        {preset === 'smart-list' && (
          <SmartListSetup
            listId={listId}
            onListChange={(listId) => {
              setListId(listId)
            }}
          />
        )}

        {searchParams['debug'] && (
          <pre>
            <Text>{JSON.stringify(formParams, null, 2)}</Text>
          </pre>
        )}

        <input type="hidden" name="follow_rule[territory_rules]" value={JSON.stringify(formParams)} />
        <input type="hidden" name="follow_rule[account_view_id]" value={listId || ''} />
      </Flex>
    </Stack>
  )
}

interface SmartListSetupProps {
  listId?: string
  allowEveryone?: boolean
  onListChange: (listId?: string) => void
  onTargetTypeChange?: (targetType: 'Account' | 'Profile') => void
}

export function SmartListSetup(props: SmartListSetupProps) {
  const { data, isLoading } = useAccountViews()
  const lists = useMemo(() => data?.account_views ?? [], [data?.account_views])
  const selectedList = useMemo(() => lists.find((l) => l.id === props.listId), [props.listId, lists])
  const allowEveryone = props.allowEveryone ?? true

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Stack>
      <FormControl>
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <FormLabel>List</FormLabel>
          {selectedList && (
            <Link
              display="inline-flex"
              alignItems="center"
              gap={1}
              isExternal
              fontSize="xs"
              href={
                selectedList.record_type == 'StaticList'
                  ? projectPath(`/lists/${selectedList.id}`)
                  : accountViewPath(selectedList)
              }
              color="purple.500"
            >
              View "{selectedList.name}"
              <IconExternalLink size={14} />
            </Link>
          )}
        </Flex>
        <ComboboxWithSearch
          items={lists}
          selectedItem={selectedList || (allowEveryone ? everyone : null)}
          onChange={(selectedItem) => {
            props.onListChange(selectedItem?.id ?? undefined)
            props.onTargetTypeChange?.(selectedItem?.kind === 'profile' ? 'Profile' : 'Account')
          }}
          filterItem={(a, val) => a.name.toLowerCase().includes(val)}
          itemToString={(item) => item?.name || ''}
          itemRenderer={AudiencePreview}
          selectButtonRenderer={(props) => <AudiencePreview {...props} fallbackToEveryone={allowEveryone} />}
        />
      </FormControl>

      {selectedList && selectedList.record_type !== 'StaticList' && (
        <FormControl pt={4}>
          <FormLabel>Selected list filters</FormLabel>
          <Stack w="100%">
            <ListFilterPreview key={selectedList.id} {...selectedList} />
          </Stack>
        </FormControl>
      )}
    </Stack>
  )
}

const everyone: AudienceOption = {
  id: '',
  name: 'Everyone',
  summary: 'Any person that triggers one of your selected intent signals (except global exclusions)',
  kind: 'account' as const,
  filters: { facets: {} }
}

interface AudienceOption {
  id?: string
  name: string
  summary?: string
  filters: {
    facets?: {}
  }
  kind: 'account' | 'profile'
  class_name?: string
}

interface AudiencePreviewProps {
  item: AudienceOption | null
  selectedItem?: AudienceOption | null
  isToggleButton?: boolean
  fallbackToEveryone?: boolean
}

function AudiencePreview(props: AudiencePreviewProps) {
  const audience = props.item || (props.fallbackToEveryone ? everyone : null)
  const filterSummary = filtersAsText(audience?.filters ?? {})
  const isStaticList = audience?.class_name === 'StaticList'

  if (!audience) {
    return (
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Text fontSize="sm" fontWeight="medium" color="gray.500">
          Select a saved list
        </Text>
      </Flex>
    )
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" width="100%">
      <HStack width="100%" spacing={2}>
        <Center flex="none" width={8}>
          <Icon as={audience.kind === 'profile' ? IconUsers : BuildingIcon} color="gray.600" boxSize={5} />
        </Center>
        <Box flex="1">
          <Text fontSize="sm" fontWeight="medium">
            {audience.name}
          </Text>
          <Text fontSize="xs" fontStyle="italic" color="gray.500">
            {isStaticList
              ? audience.kind === 'profile'
                ? 'All people from this list'
                : 'All accounts from this list'
              : filterSummary ||
                audience.summary ||
                `No applicable filters (all ${audience.kind === 'profile' ? 'people' : 'accounts'})`}
          </Text>
        </Box>
      </HStack>
      {props.selectedItem?.id === audience.id && (
        <Icon as={IconCheck} flex="none" color="purple.500" boxSize={4} marginRight={2} />
      )}
    </Flex>
  )
}

function ListFilterPreview(list: AccountView) {
  const fakeFacets = useFacets({
    facet_filters: list.filters?.facets ?? {}
  })

  if (Object.keys(list.filters?.facets ?? {}).length === 0) {
    return (
      <Text fontSize="sm" color="gray.500">
        No additional filters applied
      </Text>
    )
  }

  return <FilterPreview {...fakeFacets} kind={list.kind} isEditable={false} />
}
