import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  Select,
  Stack,
  Text,
  Tooltip,
  UnorderedList
} from '@chakra-ui/react'
import { IconInfoCircle, IconX } from '@tabler/icons-react'
import update from 'immutability-helper'
import indefinite from 'indefinite'
import uniqBy from 'lodash/uniqBy'
import { nanoid } from 'nanoid'
import React, { useMemo } from 'react'
import { useTopK } from '../../../data/use-topk'
import Combobox from '../../../ui/Combobox'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { FilterIcon } from '../../../ui/icons'
import SelectInput from '../../../ui/SelectInput'
import { TopK } from '../../../ui/top-k-v2'
import { ConditionType, FrequencyConfig, JSONValue, WithConditionType } from '../types'
import { SignalType } from './SignalType'
import { ConditionProperty, operators, signalTypes, triggers } from './triggers'

interface ConditionProps {
  condition: ConditionType
  isRefinementCondition?: boolean
  onChangeCondition: (condition: Partial<ConditionType>) => void
  onRemoveCondition?: () => void
  isRemovable?: boolean
}

interface PerformedAtLeastMatcherProps {
  value: FrequencyConfig
  onChange: (value: any) => void
}

function PerformedAtLeastMatcher({ value, onChange }: PerformedAtLeastMatcherProps) {
  const timeUnits = [
    { value: 'hours', label: 'Hours' },
    { value: 'days', label: 'Days' },
    { value: 'weeks', label: 'Weeks' },
    { value: 'months', label: 'Months' }
  ]

  const initialValue = typeof value === 'object' ? value : { count: '', window: { value: 1, unit: 'weeks' } }

  const handleCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCount = e.target.value
    if (parseInt(newCount) >= 0 && parseInt(newCount) <= 1000) {
      onChange({ ...initialValue, count: newCount })
    }
  }

  const handleWindowValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value)
    if (newValue >= 1 && newValue <= 1000) {
      onChange({
        ...initialValue,
        window: {
          ...initialValue.window,
          value: newValue
        }
      })
    }
  }

  const handleWindowUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange({
      ...initialValue,
      window: {
        ...initialValue.window,
        unit: e.target.value as 'hours' | 'days' | 'weeks' | 'months'
      }
    })
  }

  return (
    <Flex alignItems="center" gap={2}>
      <Input
        size="xs"
        rounded="md"
        type="number"
        min={0}
        max={1000}
        bg="white"
        width="60px"
        placeholder="Times"
        value={initialValue.count || ''}
        onChange={handleCountChange}
      />
      <Text fontSize="xs" color="gray.600">
        times in
      </Text>
      <Input
        size="xs"
        rounded="md"
        type="number"
        min={1}
        max={1000}
        bg="white"
        width="60px"
        value={initialValue.window?.value || ''}
        onChange={handleWindowValueChange}
      />
      <Select
        size="xs"
        rounded="md"
        bg="white"
        width="100px"
        value={initialValue.window?.unit || 'weeks'}
        onChange={handleWindowUnitChange}
      >
        {timeUnits.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </Flex>
  )
}

export default function Condition({ condition, ...props }: ConditionProps) {
  const removable = useMemo(() => props.isRemovable ?? true, [props.isRemovable])
  const signalType = signalTypes.find((t) => t.kind === condition.kind)

  const outerCss = {
    background: '#FBFCFD',
    border: '1px solid',
    borderColor: 'gray.200',
    rounded: 'lg'
  }

  return (
    <Stack width="100%" flex="1" spacing={0} {...outerCss}>
      {signalType && (
        <Box
          background="white"
          roundedTop="lg"
          borderBottom="1px solid"
          borderBottomColor="gray.200"
          paddingX={4}
          paddingY={3}
        >
          <SignalType
            label={`When there's ${indefinite(signalType.label, { caseInsensitive: true })}…`}
            icon={signalType.icon}
            colorScheme={signalType.colorScheme}
            brandLogo={signalType.brandLogo}
            compact
          >
            {removable && (
              <IconButton
                aria-label="Change/Remove condition"
                size="xs"
                variant="ghost"
                icon={<Icon as={IconX} boxSize={4} />}
                color="gray.500"
                _hover={{ color: 'gray.800' }}
                onClick={props.onRemoveCondition}
              />
            )}
          </SignalType>
        </Box>
      )}

      <Stack spacing={2} paddingX={3} paddingY={3}>
        <ConditionRow condition={condition} {...props} />

        {condition.with && (
          <ConditionRow
            isRefinementCondition
            hideOperator={condition.with.property === 'count'}
            condition={{ ...condition.with, kind: condition.kind, parent: condition }}
            onChangeCondition={(withCondition) => {
              props.onChangeCondition({ with: { ...condition.with, ...(withCondition as WithConditionType) } })
            }}
            onRemoveCondition={() => props.onChangeCondition({ with: undefined })}
          />
        )}
      </Stack>
    </Stack>
  )
}

interface ConditionRowProps extends ConditionProps {
  hideOperator?: boolean
}

function ConditionRow({ condition, ...props }: ConditionRowProps) {
  const properties = React.useMemo(() => {
    return triggers.filter((t) => {
      if (t.requirements) {
        return t.requirements(condition) && t.kind === condition.kind
      }
      return t.kind === condition.kind
    })
  }, [condition])

  const selectedProperty = properties.find(
    (t) => t.property === condition.property || t.matchProperty?.(condition.property)
  )
  return (
    <Flex alignItems="flex-start" gap={1} flexWrap="wrap">
      <Text fontSize="xs" lineHeight="24px" whiteSpace="nowrap" width="60px">
        {condition.property == 'count' ? 'and' : props.isRefinementCondition ? 'and the' : 'When the'}
      </Text>

      <Flex flex={1} alignItems="flex-start" gap={2} flexWrap="wrap">
        <Property
          properties={uniqBy(properties, 'property')}
          selectedProperty={selectedProperty}
          propertyValue={condition.property}
          onChange={(property) => {
            const newProperty = properties.find((t) => t.property === property || t.matchProperty?.(property))
            if (!condition.operator && newProperty?.defaultOperator) {
              props.onChangeCondition({ property, operator: newProperty.defaultOperator })
            } else {
              props.onChangeCondition({ property })
            }
          }}
        />
        {!props.hideOperator && (
          <Operator
            {...condition}
            onChange={(newOperator) => {
              const updoots: Partial<ConditionType> = { operator: newOperator }

              // reset values for `between` operator
              if (newOperator === 'between') {
                updoots.value = [{ gte: '', lte: '' }]
              }

              // reset values for `changed` operator
              if (newOperator === 'changed') {
                updoots.value = [{ from: '', from_operator: '', to: '', to_operator: '' }]
              }

              // reset values from `changed` operator
              if (condition.operator === 'changed' && newOperator !== 'changed') {
                updoots.value = ['']
              }

              if (condition.operator === 'between' && newOperator !== 'between') {
                updoots.value = ['']
              }

              props.onChangeCondition(updoots)
            }}
          />
        )}
        <Values
          allowMultiple={selectedProperty?.dataTypes?.includes('string')}
          dataTypes={selectedProperty?.dataTypes}
          condition={condition}
          onChangeCondition={props.onChangeCondition}
        />
        <Flex flex="none" marginLeft="auto" paddingLeft={4} alignItems="center">
          {!props.isRefinementCondition && !condition.with && (
            <Tooltip label="Refine condition">
              <IconButton
                variant="ghost"
                aria-label="Refine event condition"
                size="sm"
                color="gray.500"
                minWidth={6}
                height={6}
                _hover={{ color: 'gray.800' }}
                icon={<FilterIcon size={16} />}
                onClick={() => {
                  props.onChangeCondition({
                    with: {
                      id: nanoid(),
                      property: ['account_trait', 'profile_trait'].includes(condition.kind) ? 'value' : '',
                      operator: '',
                      value: ''
                    }
                  })
                }}
              />
            </Tooltip>
          )}
          {props.isRefinementCondition && props.onRemoveCondition && (
            <Tooltip label="Remove condition">
              <IconButton
                variant="ghost"
                aria-label="Remove condition"
                size="sm"
                minWidth={6}
                height={6}
                color="gray.500"
                _hover={{ color: 'gray.800' }}
                icon={<Icon as={IconX} boxSize={4} />}
                onClick={props.onRemoveCondition}
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export type Change = {
  from_operator?: string
  from?: string | Bounds
  to_operator?: string
  to?: string | Bounds
}

interface ChangeOperatorProps {
  condition: ConditionType
  defaultValue?: Change
  onChange: (change: Change) => void
  property: string
}

const needsValue = (operator?: string) => {
  if (operator === undefined) {
    return true
  }

  return operators.find((o) => o.name === operator)?.needsValue
}
const changeOperators = operators.filter((o) => o.name !== 'changed')

export function ChangeOperator(props: ChangeOperatorProps) {
  const wasTopK = useTopK(`${props.property}.was`)
  const isTopK = useTopK(`${props.property}.is`)

  const fromBounds: Bounds = { gte: '', lte: '' }
  const toBounds: Bounds = { gte: '', lte: '' }

  if (props.defaultValue?.from_operator === 'between') {
    fromBounds.gte = (props.defaultValue?.from as any as Bounds)?.gte
    fromBounds.lte = (props.defaultValue?.from as any as Bounds)?.lte
  }

  if (props.defaultValue?.to_operator === 'between') {
    toBounds.gte = (props.defaultValue?.to as any as Bounds)?.gte
    toBounds.lte = (props.defaultValue?.to as any as Bounds)?.lte
  }

  return (
    <HStack flexWrap={'wrap'} gap="2" w="100%">
      <HStack w="100%">
        <Text w="8" fontSize={'xs'}>
          From
        </Text>
        <Select
          size="xs"
          rounded={'md'}
          value={props.defaultValue?.from_operator ?? 'is'}
          bg="white"
          onChange={(e) => {
            let from = needsValue(e.target.value) ? props.defaultValue?.from : '*'
            if (needsValue(e.target.value) && props.defaultValue?.from === '*') {
              props.onChange({ ...props.defaultValue, from })
            }

            if (!needsValue(e.target.value)) {
              from = undefined
            }

            props.onChange({ ...props.defaultValue, from_operator: e.target.value, from })
          }}
        >
          <option value="*">anything</option>
          {changeOperators.map((o) => (
            <option key={o.name} value={o.name}>
              {o.label}
            </option>
          ))}
        </Select>
        {needsValue(props.defaultValue?.from_operator) && props.defaultValue?.from_operator !== 'between' && (
          <Combobox
            items={['*', 'null'].concat(wasTopK.data?.top_k?.map((t) => JSON.stringify(t.value)) || [])}
            inputProps={{
              size: 'xs',
              rounded: 'md',
              placeholder: 'from',
              bg: 'white'
            }}
            inputValue={
              typeof props.defaultValue?.from === 'string' || typeof props.defaultValue?.from === 'number'
                ? props.defaultValue?.from
                : '*'
            }
            onInputValueChange={({ inputValue }) => {
              props.onChange({ ...props.defaultValue, from: inputValue })
            }}
            renderItem={({ item }) => {
              return <Text fontSize="xs">{item}</Text>
            }}
          />
        )}
        {props.defaultValue?.from_operator === 'between' && (
          <Flex alignItems="center" gap={1.5}>
            <Input
              size="xs"
              rounded="md"
              type="number"
              bg="white"
              value={fromBounds.gte || ''}
              onChange={(e) => props.onChange({ ...props.defaultValue, from: { ...fromBounds, gte: e.target.value } })}
            />
            <Text fontSize="xs" color="gray.500">
              &ndash;
            </Text>
            <Input
              size="xs"
              rounded="md"
              type="number"
              bg="white"
              value={fromBounds.lte || ''}
              onChange={(e) => props.onChange({ ...props.defaultValue, from: { ...fromBounds, lte: e.target.value } })}
            />
          </Flex>
        )}
      </HStack>

      <HStack w="100%">
        <Text w="12" fontSize={'xs'}>
          To
        </Text>
        <Select
          size="xs"
          rounded={'md'}
          value={props.defaultValue?.to_operator ?? 'is'}
          bg="white"
          onChange={(e) => {
            let to = needsValue(e.target.value) ? props.defaultValue?.to : '*'

            if (needsValue(e.target.value) && props.defaultValue?.to === '*') {
              props.onChange({ ...props.defaultValue, to })
            }

            if (!needsValue(e.target.value)) {
              to = undefined
            }

            props.onChange({ ...props.defaultValue, to_operator: e.target.value, to })
          }}
        >
          <option value="*">anything</option>
          {changeOperators.map((o) => (
            <option key={o.name} value={o.name}>
              {o.label}
            </option>
          ))}
        </Select>
        {needsValue(props.defaultValue?.to_operator) && props.defaultValue?.to_operator !== 'between' && (
          <Combobox
            items={['*', 'null'].concat(isTopK.data?.top_k?.map((t) => JSON.stringify(t.value)) || [])}
            inputProps={{
              size: 'xs',
              rounded: 'md',
              placeholder: 'to',
              bg: 'white'
            }}
            inputValue={
              typeof props.defaultValue?.to === 'string' || typeof props.defaultValue?.to === 'number'
                ? props.defaultValue?.to
                : '*'
            }
            onInputValueChange={({ inputValue }) => {
              props.onChange({ ...props.defaultValue, to: inputValue })
            }}
            renderItem={({ item }) => {
              return <Text fontSize="xs">{item}</Text>
            }}
          />
        )}
        {props.defaultValue?.to_operator === 'between' && (
          <Flex alignItems="center" gap={1.5}>
            <Input
              size="xs"
              rounded="md"
              type="number"
              bg="white"
              value={toBounds.gte || ''}
              onChange={(e) => props.onChange({ ...props.defaultValue, to: { ...toBounds, gte: e.target.value } })}
            />
            <Text fontSize="xs" color="gray.500">
              &ndash;
            </Text>
            <Input
              size="xs"
              rounded="md"
              type="number"
              bg="white"
              value={toBounds.lte || ''}
              onChange={(e) => props.onChange({ ...props.defaultValue, to: { ...toBounds, lte: e.target.value } })}
            />
          </Flex>
        )}
      </HStack>
    </HStack>
  )
}

const extractPath = (val: string): string => {
  try {
    const url = new URL(val, 'https://example.com')
    return url.pathname
  } catch {
    return ''
  }
}

const validUrl = (val: string): boolean => {
  try {
    new URL(val)
    return true
  } catch {
    return false
  }
}

const validWithHttp = (val: string): boolean => {
  try {
    new URL('https://' + val)
    return val.includes('.')
  } catch {
    return false
  }
}

function validateCondition(existing: JSONValue, condition: ConditionType): PossibleError | undefined {
  const expectsPath = ['path', 'page_path'].includes(condition.property)
  const expectsUrl = ['url', 'page_url'].includes(condition.property)
  const expectsHttp = expectsUrl && ['is', 'starts_with'].includes(condition.operator)

  if (typeof existing !== 'string') {
    return
  }

  if (condition.operator === 'exists' || condition.operator === 'not_exists') {
    return
  }

  if (expectsUrl || expectsPath) {
    if (!existing.trim()) {
      return {
        message: 'You entered an empty value, which will never match. Did you mean to use the `is empty` operator?',
        existing
      }
    }

    if (expectsPath && (existing.startsWith('http') || existing.startsWith('www.'))) {
      return {
        message: 'You entered a value that looks like an absolute URL. Do you want just the path?',
        existing,
        suggested: extractPath(existing)
      }
    }

    if (expectsHttp && !validUrl(existing) && !validWithHttp(existing)) {
      return {
        message: `You entered a value that isn't a valid URL. Did you mean to use something other than the \`${condition.operator}\` operator?`,
        existing
      }
    }
  }
}

interface PossibleError {
  message: string
  existing: string
  suggested?: string
}

type ValuesProps = Pick<ConditionProps, 'condition' | 'onChangeCondition'> & {
  allowMultiple?: boolean
  dataTypes?: string[]
}

function Values(props: ValuesProps) {
  const { allowMultiple, condition, dataTypes, onChangeCondition } = props
  const operator = operators.find((o) => o.name === condition.operator)
  const values = Array.isArray(condition.value) ? condition.value : [condition.value || '']

  if (!operator || operator.needsValue === false) {
    return null
  }

  const isNumeric = operator.supportedType === 'number'

  if (dataTypes?.length === 1 && dataTypes[0] === 'boolean') {
    return (
      <SelectInput
        variant="outline"
        placeholder="--"
        size="xs"
        items={['true', 'false']}
        selectedItem={String(values[0] ?? true)}
        onSelectedItemChange={(event) => {
          onChangeCondition({
            value: update(values, { [0]: { $set: event.selectedItem } })
          })
        }}
      />
    )
  }

  return (
    <Flex flex="1" direction="column" gap={2}>
      {values.map((value, index) => (
        <ConditionValue
          key={`${condition.id}.values[${index}/${values.length}]`}
          condition={condition}
          value={value}
          allowMultiple={allowMultiple}
          isNumeric={isNumeric}
          onChange={(val) => {
            onChangeCondition({
              value: update(values, { [index]: { $set: val } })
            })
          }}
          onRemove={
            values.length > 1 ? () => onChangeCondition({ value: values.filter((_v, i) => i !== index) }) : undefined
          }
          onAdd={index === values.length - 1 ? () => onChangeCondition({ value: values.concat('') }) : undefined}
        />
      ))}
    </Flex>
  )
}

interface Bounds {
  gte: string | number
  lte: string | number
}

interface ConditionValueProps extends Omit<ValuesProps, 'onChangeCondition'> {
  value: JSONValue
  isNumeric: boolean
  onAdd?: () => void
  onChange: (newValue: JSONValue) => void
  onRemove?: () => void
}

function ConditionValue({
  condition,
  value,
  allowMultiple,
  isNumeric,
  onChange,
  onAdd,
  onRemove
}: ConditionValueProps) {
  const [touched, setTouched] = React.useState(false)
  const [error, setError] = React.useState<PossibleError | undefined>(validateCondition(value, condition))

  React.useEffect(() => {
    setError(validateCondition(value, condition))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition.property, condition.operator, value])

  const bounds: Bounds = { gte: '', lte: '' }

  if (Array.isArray(value)) {
    bounds.gte = (value[0] || '') as string | number
    bounds.lte = (value[1] || '') as string | number
  } else if (typeof value === 'object') {
    const boundsValue = value as Bounds
    bounds.gte = boundsValue?.gte ?? ''
    bounds.lte = boundsValue?.lte ?? ''
  }

  return (
    <Stack spacing={1}>
      <Flex alignItems="center" gap={1}>
        <InputGroup size="xs">
          {condition.property === 'count' ? (
            <PerformedAtLeastMatcher value={value as FrequencyConfig} onChange={onChange} />
          ) : isNumeric ? (
            <>
              {condition.operator === 'between' ? (
                <Flex alignItems="center" gap={1.5}>
                  <Input
                    size="xs"
                    rounded="md"
                    type="number"
                    bg="white"
                    value={bounds.gte}
                    onChange={(e) => {
                      onChange({ gte: e.target.value, lte: bounds.lte })
                    }}
                  />
                  <Text fontSize="xs" color="gray.500">
                    &ndash;
                  </Text>
                  <Input
                    size="xs"
                    rounded="md"
                    type="number"
                    bg="white"
                    value={bounds.lte}
                    onChange={(e) => onChange({ gte: bounds.gte, lte: e.target.value })}
                  />
                </Flex>
              ) : (
                <Input
                  size="xs"
                  rounded="md"
                  minWidth="150px"
                  bg="white"
                  isInvalid={touched && !!error}
                  value={value?.toString()}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )}
            </>
          ) : condition.operator === 'changed' ? (
            <ChangeOperator
              condition={condition}
              property={[condition.kind, condition.parent?.value, condition.property].filter(Boolean).join('.')}
              defaultValue={value as Change}
              onChange={onChange}
            />
          ) : (
            <TopK
              property={[condition.kind, condition.property].filter(Boolean).join('.')}
              matcher={condition.operator}
              defaultValue={value?.toString()}
              inputValue={value?.toString()}
              onChange={(v) => {
                setTouched(true)
                onChange(v)
              }}
              size="xs"
              inputProps={{
                size: 'xs',
                rounded: 'md',
                minWidth: '150px',
                isInvalid: touched && !!error,
                onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => {
                  const pasted = e.clipboardData.getData('text')

                  // Transform the copied/cut text to just the pathname
                  if (['path', 'page_path'].includes(condition.property) && validUrl(pasted)) {
                    e.preventDefault()
                    onChange(extractPath(pasted))
                  }
                },
                onBlur: () => {
                  setTouched(true)
                  setError(validateCondition(value, condition))
                }
              }}
            />
          )}

          {onRemove && (
            <InputRightElement position={isNumeric ? 'absolute' : 'static'} marginLeft={1}>
              <IconButton
                variant="ghost"
                aria-label="Remove condition"
                size="xs"
                color="gray.500"
                icon={<IconX size={14} />}
                onClick={onRemove}
              />
            </InputRightElement>
          )}
        </InputGroup>

        {condition.property === 'focus_time' && (
          <HelpTooltip mode="popover">
            <Stack spacing={4}>
              <Heading size="xs">Quick Tip!</Heading>
              <Text>You can use durations for active session time. For example:</Text>
              <UnorderedList pl="4">
                <ListItem>5 seconds</ListItem>
                <ListItem>2 minutes</ListItem>
                <ListItem>1 hour</ListItem>
              </UnorderedList>
              <Text>
                You can also enter numbers. Note: when using numbers alone, they will be interpreted as the number of
                milliseconds.
              </Text>
            </Stack>
          </HelpTooltip>
        )}

        {allowMultiple &&
          (onAdd ? (
            <Button variant="ghost" size="xs" colorScheme="purple" onClick={onAdd}>
              or…
            </Button>
          ) : (
            <Text flex="none" fontSize="xs" color="gray.600" paddingX={2} width="35px">
              or
            </Text>
          ))}
      </Flex>

      {touched && error && (
        <Box fontSize="xs" color="red.500">
          <Text display="inline" marginRight={1}>
            {error.message}
          </Text>
          {error.suggested && (
            <Text
              display="inline"
              textDecoration="underline"
              textDecorationStyle="dotted"
              cursor="pointer"
              onClick={() => {
                setTouched(false)
                onChange(error.suggested!)
              }}
            >
              Click to use "{error.suggested}"
            </Text>
          )}
        </Box>
      )}
    </Stack>
  )
}

interface PropertyProps {
  selectedProperty?: ConditionProperty
  propertyValue?: string
  properties: ConditionProperty[]
  onChange: (property: string) => void
}

function Property({ selectedProperty, properties, propertyValue, onChange }: PropertyProps) {
  return (
    <Flex gap={2}>
      <Flex flex="none" position="relative">
        <SelectInput
          variant="outline"
          placeholder="--"
          size="xs"
          items={properties}
          itemToString={(item) => item?.label ?? item?.property}
          selectedItem={selectedProperty || null}
          onSelectedItemChange={(event) => {
            // Set default operator 'is' for count
            onChange(event.selectedItem.property)
          }}
        />
      </Flex>
      {/* Special handling for event properties */}
      {selectedProperty?.subKind === 'event-property' && (
        <Flex flex="none" position="relative">
          <Input
            autoFocus
            size="xs"
            rounded="md"
            minWidth="150px"
            bg="white"
            placeholder="Enter property name..."
            value={propertyValue?.replace(/^data\.properties\.?/, '') ?? ''}
            onChange={(e) => {
              onChange(['data.properties', e.target.value].join('.'))
            }}
          />
        </Flex>
      )}
      {/* Special handling for account event properties */}
      {selectedProperty?.subKind === 'account-event-property' && (
        <Flex flex="none" position="relative">
          <Input
            autoFocus
            size="xs"
            rounded="md"
            minWidth="150px"
            bg="white"
            placeholder="Enter property name..."
            value={propertyValue?.replace(/^properties\.?/, '') ?? ''}
            onChange={(e) => {
              onChange(['properties', e.target.value].join('.'))
            }}
          />
        </Flex>
      )}
    </Flex>
  )
}

type OperatorProps = Partial<Pick<ConditionType, 'kind' | 'property' | 'operator' | 'parent'>> & {
  onChange: (operator: string) => void
}

function Operator(props: OperatorProps) {
  const { kind, property, operator, onChange } = props
  const operatorsForType = React.useMemo(() => {
    const trigger = triggers.find((t) => t.kind === kind && (t.property === property || t.matchProperty?.(property)))

    if (trigger?.requirements) {
      if (!trigger.requirements(props as ConditionType)) {
        return []
      }
    }

    if (!trigger) {
      return []
    }

    return operators.filter((o) => {
      if (!o.supportedType) return true
      if (Array.isArray(o.supportedType)) {
        return o.supportedType.some((type) => trigger.dataTypes.includes(type))
      }
      return trigger.dataTypes.includes(o.supportedType)
    })
  }, [kind, property, props])

  const selected = operatorsForType.find((o) => o.name === operator)

  return (
    <Flex flex="none" position="relative">
      <SelectInput
        variant="outline"
        placeholder="--"
        size="xs"
        items={operatorsForType.filter((o) => !o.hidden)}
        itemToString={(item) => item?.label ?? item?.name}
        selectedItem={selected || null}
        onSelectedItemChange={(event) => {
          onChange(event.selectedItem.name)
        }}
        itemRenderer={(item) => {
          return (
            <Flex alignItems="center" gap={1}>
              <Text fontSize="xs">{item.label || item.name}</Text>
              {item.info && (
                <Tooltip label={item.info}>
                  <Icon as={IconInfoCircle} boxSize={3.5} color="gray.500" />
                </Tooltip>
              )}
            </Flex>
          )
        }}
      />
    </Flex>
  )
}
