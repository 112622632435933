import { projectPath, useCurrentProject } from '@app/components/ui/ProjectsContext'
import { concurrentGET, post } from '@app/lib/api'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'

export interface EmailPreferencesResponse {
  default_greeting: string
  signature: string
  email_instructions: string
}

const QUERY_KEY = 'email-preferences'

export function useEmailPreferences() {
  const project = useCurrentProject()
  const queryClient = useQueryClient()

  const path = projectPath(`/settings/ai-instructions/email-preferences`).toString()

  const { data, isLoading } = useQuery<{ email_preferences: EmailPreferencesResponse }>({
    queryKey: [QUERY_KEY, { projectId: project?.id }],
    queryFn: () => concurrentGET<{ email_preferences: EmailPreferencesResponse }>(path),
    enabled: Boolean(project?.id),

    // Keep the previous data visible while fetching new data
    placeholderData: keepPreviousData
  })

  const { mutate: savePreferences, isPending: isSaving } = useMutation({
    mutationFn: async (newPreferences: Partial<EmailPreferencesResponse>) =>
      post(path, {
        email_preferences: {
          default_greeting: newPreferences.default_greeting,
          signature: newPreferences.signature,
          email_instructions: newPreferences.email_instructions
        }
      }),

    onSuccess: (_, variables) => {
      queryClient.setQueryData([QUERY_KEY, { projectId: project?.id }], {
        default_greeting: variables.default_greeting,
        signature: variables.signature,
        email_instructions: variables.email_instructions
      })
      toast.success('Email preferences saved')
    },

    onError: (err) => {
      console.error(err)
      toast.error('Failed to save email preferences')
    }
  })

  return {
    preferences: data?.email_preferences ?? {},
    isLoading,
    isSaving,
    savePreferences
  }
}
