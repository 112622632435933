import { StepType } from '../show'
import { ChecksRecord } from '../show'
import router from '@lib/router'
import { mergeParams } from '../../icps/types'

// Type for the initial step determination function
export type InitialStepFn = (checks: ChecksRecord) => StepType | null

// Define the flow type
export type OnboardingFlow = {
  sequence: StepType[]

  // Navigation methods
  next: () => void
  prev: () => void
  goTo: (step: StepType) => void

  // Getters - dropped the 'get' prefix
  steps: () => StepType[]
  currentStep: () => StepType
  selectedStepIndex: () => number
}

// Function to create a flow
export function createFlow(
  sequence: StepType[],
  initialStepFn: InitialStepFn,
  urlParams: URLSearchParams,
  checks: ChecksRecord,
  pathName: string
): OnboardingFlow {
  // Determine the initial step during creation
  const stepFromUrl = urlParams.get('step') as StepType | null
  let step: StepType

  if (stepFromUrl && sequence.includes(stepFromUrl)) {
    step = stepFromUrl
  } else {
    // Use initialStepFn result or fall back to first step
    const initialStep = initialStepFn(checks)
    step = initialStep && sequence.includes(initialStep) ? initialStep : sequence[0]
  }

  return {
    sequence,

    next() {
      const currentIndex = this.sequence.indexOf(step)
      if (currentIndex !== -1 && currentIndex < this.sequence.length - 1) {
        this.goTo(this.sequence[currentIndex + 1])
      }
    },

    prev() {
      const currentIndex = this.sequence.indexOf(step)
      if (currentIndex > 0) {
        this.goTo(this.sequence[currentIndex - 1])
      }
    },

    goTo(newStep: StepType) {
      if (this.sequence.includes(newStep)) {
        step = newStep
        // Update URL
        router.visit(mergeParams(pathName, { step: newStep }), { fetch: false })
      }
    },

    steps(): StepType[] {
      return [...this.sequence]
    },

    currentStep() {
      return step
    },

    selectedStepIndex() {
      return this.sequence.indexOf(step)
    }
  }
}
