import { useMutation, useQuery } from '@tanstack/react-query'
import { concurrentGET, put } from '../../lib/api'
import { StaticList } from '../../types/StaticList'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface ListResponse {
  lists: StaticList[]
}

interface ListParams {
  kind?: 'account' | 'profile'
  recordId?: string
}

export function useLists({ kind, recordId }: ListParams = {}) {
  const project = useCurrentProject()
  const search = new URLSearchParams()

  if (kind) {
    search.set('kind', kind)
  }

  if (recordId) {
    search.set('record_id', recordId)
  }

  const path = projectPath(`/lists.json?${search.toString()}`)

  return useQuery<ListResponse>({
    queryKey: ['static_lists', { projectId: project?.id, kind, recordId }],
    queryFn: () => concurrentGET<ListResponse>(path),
    enabled: Boolean(project?.id)
  })
}

interface UpdateListParams {
  id: string
  name: string
  description?: string
}

interface UpdateListResponse {
  list: StaticList
}

export function useUpdateList() {
  return useMutation<UpdateListResponse, unknown, UpdateListParams>({
    mutationFn: ({ id, name, description }) => {
      const path = projectPath(`/lists/${id}`)
      return put(path, { static_list: { name, description } })
    }
  })
}
