import React from 'react'
import { Box, Button, Flex, Heading, Icon, Stack, Text, useToast } from '@chakra-ui/react'
import { IconCheck } from '@tabler/icons-react'
import { Step, SkipButton } from './SetupProject'
import { HeroImage } from './HeroImage'
import pixelImg from './pixel.png'
import { OnboardingFlow } from './OnboardingFlow'
import { patch } from '@lib/api'
import { projectPath } from '@ui/ProjectsContext'
import { Goal, useOnboardingGoals } from '../../../data/use-onboarding-goals'

interface GoalsStepProps {
  flow: OnboardingFlow
  selectedGoals: string[]
  onUpdateGoals: (goals: string[]) => void
}

export function GoalsStep({ flow, selectedGoals, onUpdateGoals }: GoalsStepProps) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const toast = useToast()
  const { data: goals } = useOnboardingGoals()

  // Convert array to Set for easier operations
  const selectedGoalsSet = React.useMemo(() => {
    return new Set<string>(selectedGoals)
  }, [selectedGoals])

  const toggleGoal = (goalId: string) => {
    const newSelectedGoals = new Set<string>(selectedGoalsSet)
    if (newSelectedGoals.has(goalId)) {
      newSelectedGoals.delete(goalId)
    } else if (newSelectedGoals.size < 3) {
      newSelectedGoals.add(goalId)
    }
    // Update the parent state
    onUpdateGoals(Array.from(newSelectedGoals))
  }

  const handleContinue = async () => {
    if (selectedGoals.length === 0) return

    try {
      setIsSubmitting(true)
      const url = `${projectPath('/setup')}.json`

      await patch(url, {
        check: {
          key: 'goals_set',
          value: selectedGoals
        }
      })

      flow.next()
    } catch (error: any) {
      toast({
        title: 'Failed to save goals',
        description: error?.message || 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Step cardProps={{ p: 0, maxW: '600px' }}>
      <HeroImage src={pixelImg} h="240px" objectFit="contain" objectPosition="top" />
      <Box pt={8} />
      <Stack px="8" spacing="8" pb="8">
        <Stack>
          <Heading size="md">Set Your Goals</Heading>
          <Text fontSize="sm" color="gray.600" marginY={4}>
            Choose what matters most to your team
          </Text>
          <Text fontSize="sm" color="gray.500">
            Choose up to 3 areas to focus on first. You can always adjust these later.
          </Text>
        </Stack>

        <Stack spacing={4}>
          {goals.map((goal) => (
            <GoalOption
              key={goal.id}
              goal={goal}
              isSelected={selectedGoalsSet.has(goal.id)}
              onToggle={() => toggleGoal(goal.id)}
              isDisabled={!selectedGoalsSet.has(goal.id) && selectedGoalsSet.size >= 3}
            />
          ))}
        </Stack>

        <Stack spacing={3}>
          <SkipButton step="goals_set">Skip for now</SkipButton>
          <Button
            colorScheme="purple"
            flex="none"
            size="lg"
            fontSize="md"
            width="full"
            onClick={handleContinue}
            isDisabled={selectedGoals.length === 0}
            isLoading={isSubmitting}
            loadingText="Saving..."
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </Step>
  )
}

interface GoalOptionProps {
  goal: Goal
  isSelected: boolean
  onToggle: () => void
  isDisabled?: boolean
}

function GoalOption({ goal, isSelected, onToggle, isDisabled }: GoalOptionProps) {
  return (
    <Box
      as="button"
      onClick={onToggle}
      width="100%"
      borderWidth="2px"
      borderRadius="md"
      borderColor={isSelected ? 'purple.500' : isDisabled ? 'gray.100' : 'gray.200'}
      bg={isDisabled && !isSelected ? 'gray.50' : 'white'}
      p={4}
      cursor={isDisabled && !isSelected ? 'not-allowed' : 'pointer'}
      _hover={{
        borderColor: isSelected ? 'purple.600' : isDisabled ? 'gray.100' : 'gray.300'
      }}
      disabled={isDisabled}
      position="relative"
      textAlign="left"
      opacity={isDisabled && !isSelected ? 0.6 : 1}
      transition="all 0.2s"
    >
      <Flex>
        <Box color={isSelected ? 'purple.500' : isDisabled ? 'gray.400' : 'purple.500'} mt="3px" mr={4} flexShrink={0}>
          {goal.icon}
        </Box>
        <Box width="calc(100% - 50px)">
          <Text fontWeight="semibold" color={isDisabled && !isSelected ? 'gray.500' : 'inherit'}>
            {goal.title}
          </Text>
          <Text fontSize="sm" color={isDisabled && !isSelected ? 'gray.400' : 'gray.600'} whiteSpace="normal">
            {goal.description}
          </Text>
        </Box>
      </Flex>

      <Box position="absolute" top={4} right={4}>
        {isSelected ? (
          <Box
            bg="purple.500"
            rounded="full"
            width="20px"
            height="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon as={IconCheck} color="white" boxSize={3} />
          </Box>
        ) : (
          <Box
            rounded="full"
            width="20px"
            height="20px"
            borderWidth="2px"
            borderColor={isDisabled ? 'gray.100' : 'gray.200'}
          />
        )}
      </Box>
    </Box>
  )
}
