import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
interface Props {
  children: React.ReactNode
  hasValues?: boolean
  onClick?: () => void
}

function Operator({ children, hasValues, onClick }: Props) {
  return (
    <Box
      as="span"
      flex="none"
      display="block"
      position="relative"
      alignSelf="center"
      userSelect="none"
      marginRight={1}
      cursor="pointer"
      rounded="md"
      onClick={onClick}
      {...(!hasValues && {
        mx: -0.5,
        paddingX: 1.5,
        borderWidth: '1px',
        borderColor: 'transparent',
        _hover: {
          '& > p': { opacity: 1 },
          background: `gray.100`,
          borderColor: 'gray.200'
        },
        _active: {
          '& > p': { opacity: 1 },
          background: `gray.100`,
          borderColor: 'gray.200'
        }
      })}
    >
      <Flex as="span" justifyContent="center" alignItems="center" pointerEvents="none">
        <Text as="span" fontWeight="medium" fontSize="sm" opacity={0.6}>
          {children}
        </Text>
      </Flex>
    </Box>
  )
}

export default Operator
