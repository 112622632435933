import { Button, Center, Flex, Heading, Img, Spinner, Stack, Text } from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { useApp } from '../../../../data/use-app'
import { PopupConnectDialog } from '../../../apps/components/ConnectOauthAppDialog'
import { ReconnectionRequiredWarning } from '../../../apps/components/ReconnectionRequiredWarning'
import { FollowRule } from '../../../../../types/FollowRule'
import { channelLogos, isChannelActive } from '../delivery-setup'
import { ActionSchema, useActionSchema } from '../slack-message-builder/use-action-schema'
import { WrapTask } from '../wrap-task'
import { AddToSequence } from './add-to-sequence'
import { ImportAccount } from './import-account'
import { ImportContact } from './import-contact'

interface OutreachSetupProps {
  delivery_rules?: FollowRule['delivery_rules']
  setSaveDisabled?: (value: boolean) => void
}

export interface OutreachActionProps {
  actionSchema?: ActionSchema
  deps: any
  delivery_rules: FollowRule['delivery_rules']
  setDeliveryRules: (rules: FollowRule['delivery_rules']) => void
}

export function OutreachSetup(props: OutreachSetupProps) {
  const outreach = useApp('Outreach')
  const disconnected = useMemo(() => !outreach.data?.connected, [outreach.data])
  const invalid = useMemo(() => !outreach.data?.valid, [outreach.data])
  const [deliveryRules, setDeliveryRules] = React.useState(props.delivery_rules)
  const active = isChannelActive(props.delivery_rules, 'outreach')
  const { schema } = useActionSchema()

  const onConnected = useCallback(() => {
    outreach.refetch()
  }, [outreach])

  if (outreach.isLoading) {
    props.setSaveDisabled?.(true)
    return <Spinner />
  } else {
    props.setSaveDisabled?.(false)
  }

  if (outreach.data && disconnected) {
    return (
      <Center w="100%">
        <Flex py="8">
          {outreach.data && disconnected && (
            <PopupConnectDialog app_id={'outreach'} onConnected={onConnected} active={active}>
              {({ onStart }) => (
                <Stack spacing="4">
                  <Stack spacing="0">
                    <Heading size="sm">Connect your Outreach Account</Heading>
                    <Text fontSize="sm" color="gray.600">
                      Please connect your Outreach account in order to get started.
                    </Text>
                  </Stack>
                  <Center>
                    <Button
                      leftIcon={<Img w="4" src={channelLogos.outreach} />}
                      size="sm"
                      variant={'outline'}
                      onClick={onStart}
                      colorScheme={'purple'}
                    >
                      Connect Outreach
                    </Button>
                  </Center>
                </Stack>
              )}
            </PopupConnectDialog>
          )}
        </Flex>
      </Center>
    )
  }

  if (!outreach.data) {
    return null
  }

  return (
    <Stack spacing="8" py="8">
      <Stack spacing="8">
        {invalid && <ReconnectionRequiredWarning appTitle={'Outreach'} variant="short" />}

        <WrapTask name={<Text>Import Account</Text>} enabled={deliveryRules?.outreach?.import_account?.enabled}>
          <ImportAccount delivery_rules={deliveryRules} setDeliveryRules={setDeliveryRules} deps={outreach.data.deps} />
        </WrapTask>
        <WrapTask name={<Text>Import Prospect</Text>} enabled={deliveryRules?.outreach?.import_contact?.enabled}>
          <ImportContact
            actionSchema={schema}
            delivery_rules={deliveryRules}
            setDeliveryRules={setDeliveryRules}
            deps={outreach.data.deps}
          />
        </WrapTask>

        <WrapTask name={<Text>Add to Sequence</Text>} enabled={deliveryRules?.outreach?.add_to_sequence?.enabled}>
          <AddToSequence delivery_rules={deliveryRules} setDeliveryRules={setDeliveryRules} deps={outreach.data.deps} />
        </WrapTask>
      </Stack>

      {props.delivery_rules?.outreach && (
        <input type="hidden" name="follow_rule[delivery_rules][outreach][trigger]" value="immediate" />
      )}
    </Stack>
  )
}
