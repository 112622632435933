import {
  Button,
  Divider,
  Flex,
  Link,
  Stack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Text,
  RadioGroup,
  Radio,
  VStack
} from '@chakra-ui/react'
import React, { useState, useRef, useCallback } from 'react'
import { Apps } from '../../../types/App'
import { Play } from '../../../types/Play'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TopBarContent } from '../../ui/TopBarContext'
import { PlayForm } from './components/play-form'

interface Props {
  play: Play
  apps: Apps
}

export default function Edit({ play, apps }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formIsValid, setFormIsValid] = useState(true)
  const formRef = useRef<HTMLFormElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const [selectedOption, setSelectedOption] = useState<'reassign' | 'future'>('future')

  const assignmentConfigValues = useCallback(() => {
    const assignmentConfigInputs = document.querySelectorAll<HTMLInputElement>('input[name^="play[assignment_config]"]')
    return Array.from(assignmentConfigInputs)
      .map((input) => input.value)
      .join(',')
  }, [])

  const [assignmentInitialConfigValues, setAssignmentInitialConfigValues] = useState('')
  React.useEffect(() => {
    setAssignmentInitialConfigValues(assignmentConfigValues())
  }, [assignmentConfigValues])

  const submitForm = useCallback((shouldReassign: boolean = false) => {
    setIsSubmitting(true)

    const form = formRef.current

    const existingReassignInput = form?.querySelector('input[name="play[reassign_leads]"]')
    if (existingReassignInput) {
      existingReassignInput.remove()
    }

    if (shouldReassign) {
      const reassignInput = document.createElement('input')
      reassignInput.type = 'hidden'
      reassignInput.name = 'play[reassign_leads]'
      reassignInput.value = 'true'
      form?.appendChild(reassignInput)
    }

    form?.submit()
  }, [])

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const currentAssignmentConfig = assignmentConfigValues()

      if (currentAssignmentConfig !== assignmentInitialConfigValues) {
        onOpen()
      } else {
        submitForm()
      }
    },
    [assignmentInitialConfigValues, assignmentConfigValues, onOpen, submitForm]
  )

  return (
    <PageLayout>
      <TopBarContent>
        <Breadcrumb
          paths={[
            { path: projectPath('/plays'), title: 'Plays' },
            { path: projectPath(`/plays/${play.id}`), title: play.name },
            { path: projectPath(`/plays/${play.id}/edit`), title: 'Edit', isCurrentPage: true }
          ]}
        />
      </TopBarContent>

      <PageTitle>Edit Play</PageTitle>
      <Divider />
      <Stack
        as="form"
        action={projectPath(`/plays/${play.id}`)}
        method="post"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
        w="100%"
        ref={formRef}
      >
        <PlayForm play={play} apps={apps} setFormIsValid={setFormIsValid} />

        <Flex alignItems="center" gap={2}>
          <Button
            colorScheme="purple"
            type="submit"
            size="sm"
            minWidth="100px"
            isLoading={isSubmitting}
            isDisabled={!formIsValid}
          >
            Save Changes
          </Button>
          <Button size="sm" variant="outline" as={Link} href={projectPath('/plays')}>
            Cancel
          </Button>
        </Flex>

        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />
      </Stack>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Lead routing rules changed
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text fontSize="sm" color="gray.700" mb={4}>
                Your routing rules have changed, but some leads are already assigned. Choose how you'd like to apply the
                new rules to leads in this play:
              </Text>
              <RadioGroup
                size="sm"
                value={selectedOption}
                onChange={(value) => setSelectedOption(value as 'reassign' | 'future')}
              >
                <VStack align="flex-start">
                  <Radio value="reassign">Reassign all existing leads</Radio>
                  <Radio value="future">Only assign new leads</Radio>
                </VStack>
              </RadioGroup>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} size="sm" onClick={onClose} mr={3} variant="outline">
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="purple"
                onClick={() => {
                  onClose()
                  submitForm(selectedOption === 'reassign')
                }}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </PageLayout>
  )
}
