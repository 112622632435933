import castArray from 'lodash/castArray'
import flatten from 'lodash/flatten'
import isUndefined from 'lodash/isUndefined'
import keyBy from 'lodash/keyBy'
import omitBy from 'lodash/omitBy'
import uniq from 'lodash/uniq'
import ms from 'ms'
import { useCallback, useMemo, useState } from 'react'
import { Facet, FacetValue, NumericFilter } from '../pages/accounts'
import { getItemDisplay } from '../pages/accounts/facets/categories'
import { getRangeValues, periodShorthands } from '../pages/accounts/facets/filter-cloud'
import { AdvancedFilters } from '../ui/filters/types'
import useLatestRef from '../ui/useLatestRef'
import useUpdateEffect from '../ui/useUpdateEffect'
import { useFacetCloud } from './use-field-mappings'

interface Props {
  facet_filters?: AdvancedFilters
  range?: 'day' | 'week' | 'month' | 'all' | 'any' | null
  focus_time?: NumericFilter
  onFilterChange?: (appliedFilters: AdvancedFilters, page: number) => void
  onClearFilters?: () => void
  query?: string
  sortBy?: string
  page?: number | string | null
  perPage?: number
  facetCloudPath?: string
}

export function getFacetValues(facet: Facet): FacetValue[] {
  if (typeof facet === 'string' || typeof facet === 'number' || typeof facet === 'boolean') {
    return [facet]
  }

  if (facet && 'not' in facet) return facet.not
  if (facet && 'all' in facet) return facet.all
  if (facet && 'gte' in facet) return castArray(facet.gte)
  if (facet && 'lte' in facet) return castArray(facet.lte)
  if (facet && 'not_exists' in facet) return castArray(facet.not_exists as FacetValue[])
  // invert this because we also invert the operator to `not_exists`
  if (facet && 'exists' in facet && (!facet.exists || facet.exists === 'false')) return ['true']
  if (facet && 'exists' in facet) return castArray(facet.exists)
  if (facet && 'prefix' in facet) return castArray(facet.prefix)
  if (facet && 'contains' in facet) return castArray(facet.contains)
  if (facet && 'not_contains' in facet) return castArray(facet.not_contains)
  if (typeof facet === 'object' && Object.keys(facet).length === 0) return []

  return facet as FacetValue[]
}

export type FacetOperator =
  | 'all'
  | 'not'
  | 'gte'
  | 'lte'
  | 'exists'
  | 'not_exists'
  | 'between'
  | 'prefix'
  | 'contains'
  | 'not_contains'
  | null

export function getFacetOperator(facet: Facet | undefined): FacetOperator {
  if (typeof facet === 'string') {
    if (/\d+\.\.\d+/.test(facet)) {
      return 'between'
    } else {
      return null
    }
  }

  if (typeof facet === 'number' || typeof facet === 'boolean') {
    return null
  }

  if (!facet) {
    return null
  }

  if ('not' in facet) return 'not'
  if ('all' in facet) return 'all'
  if ('gte' in facet) return 'gte'
  if ('lte' in facet) return 'lte'
  if ('not_exists' in facet) return 'not_exists'
  // invert to not_exists, so we can differentiate between "is empty" and "is not empty" operators
  if ('exists' in facet && (!facet.exists || facet.exists === 'false')) return 'not_exists'
  if ('exists' in facet) return 'exists'
  if ('prefix' in facet) return 'prefix'
  if ('contains' in facet) return 'contains'
  if ('not_contains' in facet) return 'not_contains'
  return null
}

export function getFacetOperatorLabel(facet: Facet | undefined, dataType?: string) {
  const operator = getFacetOperator(facet)
  let value: any

  let label = 'is'
  switch (operator) {
    case 'all':
      label = 'contains all'
      break
    case 'not':
      label = 'is not'
      break
    case 'not_exists':
      label = 'is empty'
      break
    case 'exists': {
      value = facet?.['exists']?.toString() == 'true'
      if (value) {
        // "is anytime" vs "is not empty"
        label = dataType === 'date' ? 'is' : 'is not empty'
      } else {
        // "is not anytime" vs "is empty"
        label = dataType === 'date' ? 'is not' : 'is empty'
      }
      break
    }
    case 'between':
      label = 'between'
      break
    case 'gte':
      label = dataType === 'date' ? 'is after' : '>'
      break
    case 'lte':
      label = dataType === 'date' ? 'is before' : '<'
      break
    case 'prefix':
      label = 'starts with'
      break
    case 'contains':
      label = 'contains'
      break
    case 'not_contains':
      label = 'does not contain'
      break
  }

  if (!operator?.includes('exists') && typeof facet === 'object' && facet['exists']) {
    return `${facet['exists'] === 'true' ? 'anytime' : 'not set'} or ${label}`
  }

  return label
}

// For backwards compat with old `focus_time=1000` which meant `focus_time[gte]=1000`
export function convertEqToGte(value: any) {
  if ((typeof value === 'string' && !value.includes('..') && value) || typeof value === 'number') {
    try {
      return { gte: Number(value) }
    } catch (_e) {
      return null
    }
  }

  return value
}

export function filtersAsText(filters: any) {
  const parts: string[] = []

  if (filters?.focus_time) {
    const { eq, gte, lte } = getRangeValues(filters.focus_time)
    let operator: string
    let friendly: string

    if (gte && lte) {
      operator = 'between'
      friendly = `${ms(gte)}-${ms(lte, { long: true })}`
    } else if (gte) {
      operator = '>'
      friendly = ms(gte, { long: true })
    } else if (lte) {
      operator = '<'
      friendly = ms(lte, { long: true })
    } else {
      operator = 'is'
      friendly = ms(eq || 0, { long: true })
    }

    parts.push(`Active session time ${operator} ${friendly}`)
  }

  for (const key of Object.keys(filters?.facets || {})) {
    const display = getItemDisplay(key, [])
    const operator = getFacetOperatorLabel(filters.facets[key])

    let value: FacetValue[] | FacetValue = getFacetValues(filters.facets[key]).map((v) => {
      if (['day', 'week', 'month'].includes(v as string) && operator) {
        return periodShorthands[v] || v
      }

      return v
    })

    if (value.length === 1) {
      value = value[0]
    }

    if (Array.isArray(value)) {
      parts.push(`${display.label}: ${operator && operator !== 'is' ? operator + ' ' : ''}${value.join(' or ')}`)
    } else {
      parts.push(`${display.label}: ${operator && operator !== 'is' ? operator + ' ' : ''}${value}`)
    }
  }

  return parts.join(', ')
}

export function facetQueryString(facetFilters: AdvancedFilters, prefix = 'facets'): string[] {
  // detect if these are advanced/complex filters
  // and return a json-encoded string instead of the bracket-style Rails querystring
  if ('_and' in facetFilters || '_or' in facetFilters) {
    const query = [prefix, encodeURIComponent(JSON.stringify(facetFilters))].filter(Boolean).join('=')
    // we need to return as an array
    return [query]
  }

  const facets = Object.entries(facetFilters).flatMap(([key, value]: [string, any]) => {
    const operator = getFacetOperator(value)

    const values = uniq(flatten(castArray(getFacetValues(value))))
    const [first, ...rest] = [prefix, key, operator].filter(Boolean)
    let query = first as string

    for (const part of rest) {
      if (part === 'between') {
        continue
      }

      query += `[${part}]`
    }

    // allows for overriding query params if there are merged defaults server-side, e.g. `facets[foo]=`
    if (values.length === 0) {
      return query + '='
    }

    // add `[]` the array bracket syntax to indicate this is a list of items
    // we'll loop over the values to include them
    // without this Rails will not properly parse the query as an array of values
    if (values.length > 1 || operator === 'all') {
      query += '[]'
    }

    return values.map((val) => {
      return `${query}=${encodeURIComponent(val as string)}`
    })
  })

  return uniq(facets)
}

export function toQueryString(params: Record<string, any>) {
  const { page, perPage, range, query, sortBy, focusTime, facetFilters } = params
  const parts: string[] = []

  if (page) {
    parts.push(`page=${page}`)
  }

  if (perPage) {
    parts.push(`page_size=${perPage}`)
  }

  if (range) {
    parts.push(`range=${range}`)
  }

  if (query) {
    parts.push(`query=${query}`)
  }

  if (sortBy) {
    parts.push(`sort_by=${sortBy}`)
  }

  if (focusTime) {
    const parts = facetQueryString({ focus_time: focusTime }, '')
    parts.push(...parts)
  }

  const facets = facetQueryString(facetFilters)
  if (facets.length > 0) {
    parts.push(...facets)
  }

  return parts.length > 0 ? `?${parts.join('&')}` : ''
}

export type FacetParams = ReturnType<typeof useFacets>

export function useFacets(props: Props) {
  const [page, setPage] = useState(props.page)
  const [range, setRange] = useState(props.range)
  const [focusTime, setFocusTime] = useState(convertEqToGte(props.focus_time) || null)
  const [query, setQuery] = useState(props.query)
  const [sortBy, setSortBy] = useState<string | undefined>(props.sortBy)
  const latestOnFilterChange = useLatestRef(props.onFilterChange)

  const [facetFilters, setFacetFilters] = useState(props.facet_filters ?? {})
  const facetCloudPath = props.facetCloudPath ?? '/accounts/facet-cloud'

  const resetPage = useCallback(() => {
    setPage((prev) => (typeof prev === 'undefined' ? undefined : 1))
  }, [])

  const applyFilters = useCallback(
    (appliedFilters: AdvancedFilters) => {
      setFacetFilters((existing) => {
        const merged = {
          ...existing,
          ...appliedFilters
        }

        return omitBy(merged, isUndefined) as AdvancedFilters
      })
      resetPage()
    },
    [resetPage]
  )

  const isFiltering = Object.keys(facetFilters).length > 0 || !!range
  const canClearFilters = Object.keys(facetFilters).length > 0

  const queryString = toQueryString({
    page,
    range,
    query,
    focusTime,
    facetFilters,
    sortBy,
    perPage: props.perPage
  })

  useUpdateEffect(() => {
    latestOnFilterChange.current?.(facetFilters, Number(page) || 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facetFilters, page])

  // load mappings and top filters
  const { data, isLoading: facetCloudLoading } = useFacetCloud(facetCloudPath, { cached: true })
  const topFilters = useMemo(() => data?.top_filters || [], [data])
  const facetMappings = useMemo(() => keyBy(data?.mappings || [], 'facet'), [data])

  const clearFilters = useCallback(() => {
    setFacetFilters({})
    setQuery(undefined)
    setRange(props.range !== null ? props.range || 'week' : null)
    setFocusTime(null)
    resetPage()
    props.onClearFilters?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.range, resetPage, props.onClearFilters])

  return {
    clearFilters,
    queryString,
    isFiltering,
    canClearFilters,
    setRange,
    setFocusTime,
    setQuery,
    range,
    focusTime,
    query,
    applyFilters,
    facetFilters,
    setFacetFilters,
    page,
    setPage,
    sortBy,
    setSortBy,
    facetMappings,
    topFilters,
    facetCloudLoading
  }
}

export type Facets = ReturnType<typeof useFacets>
