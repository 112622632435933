import {
  IconUser,
  IconAt,
  IconBrandLinkedin,
  IconBrandGithub,
  IconBrandX,
  IconBriefcase,
  IconPhoto,
  IconId,
  IconPhone,
  IconWorld
} from '@tabler/icons-react'
import { TextIcon } from '../ui/icons/TextIcon'

const PROFILE_TRAITS = [
  {
    id: 'email',
    name: 'Email',
    icon: IconAt
  },
  {
    id: 'name',
    name: 'Full Name',
    icon: TextIcon
  },
  {
    id: 'first_name',
    name: 'First Name',
    icon: TextIcon
  },
  {
    id: 'last_name',
    name: 'Last Name',
    icon: TextIcon
  },
  {
    id: 'linkedin_url',
    name: 'LinkedIn URL',
    icon: IconBrandLinkedin
  },
  {
    id: 'github_handle',
    name: 'GitHub Handle',
    icon: IconBrandGithub
  },
  {
    id: 'twitter_handle',
    name: 'Twitter Handle',
    icon: IconBrandX
  },
  {
    id: 'title',
    name: 'Job Title',
    icon: IconBriefcase
  },
  {
    id: 'avatar',
    name: 'Avatar',
    icon: IconPhoto
  },
  {
    id: 'id',
    name: 'ID',
    icon: IconId
  },
  {
    id: 'phone',
    name: 'Phone',
    icon: IconPhone
  },
  {
    id: 'website',
    name: 'Website',
    icon: IconWorld
  }
]

const ACCOUNT_TRAITS = [
  {
    id: 'domain',
    name: 'Domain',
    icon: IconAt
  },
  {
    id: 'name',
    name: 'Company Name',
    icon: IconUser
  }
]

export function useTraits(kind: 'profile' | 'account') {
  if (kind === 'profile') {
    return PROFILE_TRAITS
  }

  return ACCOUNT_TRAITS
}
