import { Box, BoxProps, Center, Heading, HStack, Icon, Stack, useDisclosure } from '@chakra-ui/react'
import { IconDatabase } from '@tabler/icons-react'
import React from 'react'
import { Apps } from '../../../../types/App'
import { useAccount } from '../../../data/use-account'
import { AISummaryCard } from '../../../ui/AISummaryCard'
import { Card } from '../../../ui/Card'
import { usePermission } from '../../../ui/PermissionsContext'
import { useEntitlements } from '../../../ui/useEntitlements'
import { FieldHighlights } from '../../accounts/components/DetailsV2/SummaryCard'
import { ProspectRecommendations } from '../types'

interface GradientBoxProps {
  children: React.ReactNode
  variant?: 'gradient' | 'purple' | 'alternative' | 'simple'
}

export const GradientBox: React.FC<GradientBoxProps & BoxProps> = ({ children, variant = 'gradient', ...props }) => {
  const bgStyle =
    variant === 'gradient'
      ? 'linear-gradient(135deg, rgb(255, 243, 245) 0%, rgb(246, 247, 255) 45%, rgb(240, 245, 255) 100%)'
      : variant === 'alternative'
        ? 'linear-gradient(225deg, rgb(255, 245, 235) 0%, rgb(246, 247, 255) 45%, rgb(240, 255, 240) 100%)'
        : variant === 'simple'
          ? 'linear-gradient(135deg, rgb(250, 245, 255) 0%, rgb(243, 232, 255) 45%, rgb(237, 233, 254) 100%)'
          : 'purple.50'

  const boxShadow = 'rgba(255, 255, 255, 0.5) 0px 1px 1px inset, rgba(15, 23, 42, 0.05) 0px 1px 2px'

  return (
    <Box bg={bgStyle} p={4} rounded="md" border="none" boxShadow={boxShadow} {...props}>
      {children}
    </Box>
  )
}

interface DataProps {
  recommendations?: ProspectRecommendations
  loading: boolean
  apps: Apps
}

export const Data: React.FC<DataProps> = ({ recommendations, apps }) => {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const entitlements = useEntitlements()
  const account = useAccount({ domain: recommendations?.company_data?.domain })
  const editFieldsModal = useDisclosure()

  return (
    <Stack as={Card} spacing={8}>
      <HStack>
        <Center boxSize={10} bg="purple.50" borderRadius="md">
          <Icon as={IconDatabase} color="purple.500" boxSize={5} />
        </Center>

        <Heading size="md" fontWeight="semibold">
          Data
        </Heading>
      </HStack>

      <Stack fontSize="sm" spacing={4}>
        {account.data?.account && (
          <FieldHighlights
            account={account.data?.account}
            canEditHighlights={canEditProject}
            apps={apps}
            scoringEnabled={entitlements?.icp_scoring}
            {...editFieldsModal}
          />
        )}
      </Stack>

      {account.data?.account && (
        <Box w="100%" position="relative">
          <AISummaryCard account={account.data?.account} target="companies" isGradient />
        </Box>
      )}
    </Stack>
  )
}
