import { Box, Flex, Stack, Text, Tooltip } from '@chakra-ui/react'
import { IconMail, IconMailSearch } from '@tabler/icons-react'
import React from 'react'
import { Engagement } from '../../../../types/Engagement'
import { LightBgCard } from '../../../ui/Card'
import CircleIcon from '../../../ui/CircleIcon'
import dayjs from '../../../../lib/dayjs'
import { TimeAgo } from '../../../ui/TimeAgo'
import { ParsedMailAddress, parseEmailAddress } from '../../../../lib/parse-name-addr'
import EmptyState from '../../../ui/EmptyState'

function arrayWrap<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : value ? [value] : []
}

interface Props {
  emails: Engagement[]
}

export function EmailMessages(props: Props) {
  if (!props.emails?.length) {
    return (
      <Box maxW="450px" mx="auto">
        <EmptyState
          size="sm"
          icon={IconMailSearch}
          heading="No emails yet"
          description="Once you have sent emails from Koala or synced emails with your mailbox they will appear here."
        />
      </Box>
    )
  }

  return <Stack spacing={3}>{props.emails?.map((email) => <EmailMessage key={email.id} email={email} />)}</Stack>
}

interface EmailMessageProps {
  email: Engagement
}

export function EmailMessage(props: EmailMessageProps) {
  const email = props.email
  const { from, to = [], cc = [], subject } = email.metadata || {}

  const participants: ParsedMailAddress[] = [from, to, cc].flatMap((value) => arrayWrap(value).map(parseEmailAddress))

  return (
    <LightBgCard
      w="100%"
      fontSize="xs"
      bg="white"
      p={0}
      borderColor="gray.50"
      shadow="base"
      borderLeftColor="purple.500"
      borderLeftWidth="2px"
    >
      <Flex alignItems="center" py={[3, 3, 3, 4]} px={[4, 4, 4, 5]} gap={3} ml="-1px">
        <CircleIcon flex="none" icon={IconMail} color="gray.600" bg="gray.100" padding={2.5} iconSize={5} />

        <Box flex={1} isTruncated>
          <Flex gap={4} alignItems="center" justifyContent="space-between">
            <Text fontSize="sm" fontWeight="medium" isTruncated>
              {subject}
            </Text>
            <Tooltip label={dayjs(email.created_at).format('ddd, MMM Do, YYYY HH:mm A')} openDelay={300}>
              <Text fontSize="xs" color="gray.500">
                {dayjs(email.created_at).isSame(dayjs(), 'day') ? (
                  <TimeAgo time={email.created_at} format="HH:mm A" />
                ) : (
                  <TimeAgo time={email.created_at} format="MMM D, YYYY" />
                )}
              </Text>
            </Tooltip>
          </Flex>
          <Text fontSize="xs" color="gray.500" isTruncated>
            {participants.map(({ name, email }) => name || email).join(', ')}
          </Text>
        </Box>
      </Flex>
    </LightBgCard>
  )
}
