import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
  Textarea,
  UnorderedList
} from '@chakra-ui/react'
import { IconCheck, IconExclamationMark, IconPlugConnected, IconTable } from '@tabler/icons-react'
import React, { useState } from 'react'
import { nanoid } from 'nanoid'
import { post } from '../../../../lib/api'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import CircleIcon from '../../../ui/CircleIcon'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { Toggle } from '../../accounts/components/Toggle'
import { TestResult, DataSync, DataSyncSettings } from '../../../ui/DataSyncSettings'
import { DataWrite, DataWriteSettings } from '../../../ui/DataWriteSettings'

interface Props {
  app_id: string
  title: string
  description: string
  logo: string
  settings: {
    project_id?: string
    client_email?: string
    private_key?: string
    data_syncs?: DataSync[]
    data_writes?: string[]
    database?: string
    username?: string
  }
  connected?: boolean
  valid?: boolean
  secrets: string[]
  deps: {
    last_successful_syncs: Record<string, string>
    data_write_requested: boolean
    data_write_available: boolean
    data_write_models: DataWrite[]
  }
}

export default function Show(props: Props) {
  const { hasPermission: canManageApps } = usePermission({ on: 'project', action: 'can_manage_apps' })
  const [isChangingPrivateKey, setIsChangingPrivateKey] = useState(false)

  const [queryInput, setQueryInput] = useState(
    'SELECT SESSION_USER() AS USER, CATALOG_NAME AS PROJECT_ID, SCHEMA_NAME AS DATASET, LOCATION FROM INFORMATION_SCHEMA.SCHEMATA;'
  )
  const [queryResult, setQueryResult] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [queryError, setQueryError] = useState<string | null>(null)
  const [dataSyncs, setDataSyncs] = useState<DataSync[]>(props.settings?.data_syncs ?? [])

  const handleTestQuery = async () => {
    try {
      setIsLoading(true)
      const response = await post<{ result: any }>(projectPath('/apps/bigquery/query'), { query: queryInput })
      setQueryResult(JSON.stringify(response.result, null, 2))
      setQueryError(null)
    } catch (error) {
      setQueryResult(null)
      setQueryError(JSON.stringify(error, null, 2))
    } finally {
      setIsLoading(false)
    }
  }

  const handleTestDataSync = async (dataSync: DataSync) => {
    const type = dataSync.type
    return post<TestResult>(projectPath('/apps/bigquery/query'), { query: dataSync.query, type })
  }

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/bigquery'),
            title: 'Google BigQuery'
          }
        ]}
        offscreen
      />
      <HStack>
        <Image src={props.logo} maxW="6" />
        <PageTitle>{props.title}</PageTitle>

        {Object.keys(props.settings).length > 0 && (
          <Flex flex="1" justifyContent={'flex-end'}>
            <DisconnectAppDialog appTitle={'Bigquery'} showRemoveCachesOption={false} />
          </Flex>
        )}
      </HStack>
      <PageDescription>{props.description}</PageDescription>

      {props.connected && (
        <>
          <Toggle
            title={
              <HStack mb="2">
                <IconPlugConnected />
                <Heading size="sm" fontWeight={'semibold'}>
                  Connection Test
                </Heading>
              </HStack>
            }
          >
            <Stack as={LightBgCard} bg="gray.50" spacing="4">
              <HStack spacing="4" alignItems={'flex-start'}>
                <FormControl>
                  <FormLabel>Query</FormLabel>
                  <Textarea
                    bg="white"
                    fontSize={'sm'}
                    value={queryInput}
                    onChange={(e) => setQueryInput(e.target.value)}
                    placeholder="Enter your SQL query here..."
                    rows={5}
                    isDisabled={isLoading}
                  />
                  <FormHelperText fontSize={'xs'}>
                    Enter a SQL query to test your connection to BigQuery.
                  </FormHelperText>
                </FormControl>
                {(queryResult || queryError) && (
                  <FormControl>
                    <FormLabel>Result</FormLabel>
                    <Textarea rows={5} value={queryResult ?? queryError ?? ''} isReadOnly fontSize={'sm'} bg="white" />
                    <FormHelperText fontSize={'xs'}>
                      {queryError && (
                        <HStack>
                          <CircleIcon
                            icon={IconExclamationMark}
                            colorScheme="red"
                            p="0"
                            borderColor="red.200"
                            borderWidth="thin"
                          />
                          <Text>Your query returned an error.</Text>
                        </HStack>
                      )}
                      {queryResult && (
                        <HStack>
                          <CircleIcon
                            icon={IconCheck}
                            colorScheme="green"
                            p="0"
                            borderColor="green.200"
                            borderWidth="thin"
                          />
                          <Text>Success!</Text>
                        </HStack>
                      )}
                    </FormHelperText>
                  </FormControl>
                )}
              </HStack>

              <Button
                colorScheme="blue"
                onClick={handleTestQuery}
                mb="4"
                isDisabled={isLoading}
                loadingText="Running Query..."
                isLoading={isLoading}
                size="sm"
              >
                Run Query
              </Button>
            </Stack>
          </Toggle>
          <Divider />
        </>
      )}

      <form method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />

        <Stack spacing="4">
          <Stack spacing="8">
            <Toggle
              defaultIsOpen={!props.connected}
              title={
                <Heading size="sm" fontWeight={'semibold'}>
                  🔐 Auth Settings
                </Heading>
              }
            >
              <Stack spacing="8">
                <LightBgCard as={Stack}>
                  <Stack spacing={'4'}>
                    <FormControl id="project_id">
                      <FormLabel>Project ID</FormLabel>
                      <Input
                        bg="gray.50"
                        w="100%"
                        name="app_instance_settings[project_id]"
                        placeholder="BigQuery Project ID"
                        defaultValue={props.settings['project_id'] ?? ''}
                        required
                      />
                      <FormHelperText>Your BigQuery project_id.</FormHelperText>
                    </FormControl>

                    <FormControl id="client_email">
                      <FormLabel>Client Email</FormLabel>
                      <Input
                        bg="gray.50"
                        w="100%"
                        name="app_instance_settings[client_email]"
                        placeholder="The client_email attribute inside JSON key file"
                        defaultValue={props.settings['client_email'] ?? ''}
                        required
                      />
                      <FormHelperText>The client_email attribute on JSON Key.</FormHelperText>
                    </FormControl>

                    <FormControl id="database">
                      <FormLabel>Dataset</FormLabel>
                      <Input
                        bg="gray.50"
                        w="100%"
                        name="app_instance_settings[database]"
                        placeholder="Dataset name in BigQuery"
                        defaultValue={props.settings['database'] ?? ''}
                        required
                      />
                      <FormHelperText>The target Dataset in BigQuery (optional).</FormHelperText>
                    </FormControl>

                    <FormControl id="private_key">
                      <FormLabel>Private Key</FormLabel>

                      {!props.connected || isChangingPrivateKey ? (
                        <Textarea
                          bg="gray.50"
                          w="100%"
                          name="app_instance_settings[private_key]"
                          placeholder="The whole content, including -----BEGIN PRIVATE KEY----- until -----END PRIVATE KEY----- tags"
                          defaultValue={props.settings['private_key'] ?? ''}
                          required
                        />
                      ) : (
                        <HStack>
                          <Input bg="gray.50" w="100%" type="password" isReadOnly value={'***********************'} />
                          <Button
                            variant={'ghost'}
                            colorScheme="purple"
                            size="sm"
                            onClick={() => setIsChangingPrivateKey(true)}
                          >
                            Change
                          </Button>
                        </HStack>
                      )}
                      <FormHelperText>The private_key attribute on JSON Key.</FormHelperText>
                    </FormControl>
                  </Stack>
                </LightBgCard>

                <Stack spacing="4">
                  <Heading size="sm" fontWeight={'semibold'}>
                    How to create a BigQuery JSON Key for Koala:
                  </Heading>
                  <OrderedList fontSize={'sm'} px="4" spacing="2">
                    <ListItem>
                      From the Navigation panel on the left, select <strong>IAM & admin</strong>, then{' '}
                      <strong>Service accounts</strong>.
                    </ListItem>
                    <ListItem>
                      Click <strong>Create Service Account</strong>.
                    </ListItem>
                    <ListItem>
                      Enter the name of your service account (e.g. koala-bigquery) and click <strong>Create</strong>.
                    </ListItem>
                    <ListItem>
                      <Text pb="2">Assign the service account the following roles:</Text>
                      <UnorderedList spacing="1" fontWeight={'semibold'}>
                        <ListItem>BigQuery Data Owner</ListItem>
                        <ListItem>BigQuery Job User</ListItem>
                      </UnorderedList>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="https://cloud.google.com/iam/docs/keys-create-delete"
                        isExternal
                        fontWeight={'semibold'}
                      >
                        Create a JSON key
                      </Link>
                      . The downloaded file will be used to connect to your BigQuery instance in the Koala app. If you
                      have trouble creating a new service account, refer to{' '}
                      <Link href="https://cloud.google.com/iam/docs/service-accounts-create" isExternal>
                        {' '}
                        Google Cloud's documentation
                      </Link>{' '}
                      about service accounts for more information.
                    </ListItem>
                  </OrderedList>
                </Stack>
              </Stack>
            </Toggle>
            <Divider />
          </Stack>

          {props.connected && props.valid && (
            <Toggle
              defaultIsOpen={true}
              title={
                <HStack>
                  <IconTable size={16} />
                  <Heading size="sm" fontWeight={'semibold'}>
                    Read data from BigQuery
                  </Heading>
                </HStack>
              }
            >
              <Stack as={LightBgCard} spacing={8} mt="2">
                <DataSyncSettings
                  appName="bigquery"
                  dataSyncs={dataSyncs}
                  setDataSyncs={(dataSyncs) => {
                    setDataSyncs(dataSyncs)
                  }}
                  onTest={handleTestDataSync}
                  lastSuccessfulSyncs={props.deps.last_successful_syncs}
                />
                <Button
                  colorScheme="purple"
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setDataSyncs([
                      ...dataSyncs,
                      { enabled: true, query: '', type: 'traits', object_type: 'Profile', id: nanoid() }
                    ])
                  }}
                >
                  Add New Model
                </Button>
              </Stack>
            </Toggle>
          )}

          {props.connected && (
            <DataWriteSettings
              appName="BigQuery"
              available={props.deps.data_write_available}
              requested={props.deps.data_write_requested}
              models={props.deps.data_write_models}
              database={props.settings.database}
              username={props.settings?.client_email?.split('@')[0]}
            />
          )}
        </Stack>

        <Stack spacing="8" mt="8">
          <HStack w="100%">
            <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canManageApps}>
              Save
            </Button>
          </HStack>
        </Stack>
      </form>
    </PageLayout>
  )
}
