import {
  Box,
  Button,
  Checkbox,
  Code,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Switch,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { PlaySourceProgressManager } from './components/PlaySourceProgressManager'
import {
  IconBell,
  IconBellRingingFilled,
  IconCheck,
  IconCircle,
  IconCircleDashed,
  IconDotsVertical,
  IconFlare,
  IconPlayerPlay,
  IconSettings,
  IconTrash,
  IconX
} from '@tabler/icons-react'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'sonner'
import { PlaySourceIcon } from '.'
import { concurrentGET, post } from '@app/lib/api'
import router from '../../../lib/router'
import { App, Apps } from '../../../types/App'
import { PageMeta } from '../../../types/PageMeta'
import { DisplayColumn, Play, PlayItem, PlayNotificationConfig } from '../../../types/Play'
import { useAIAgents } from '../../data/use-ai-agents'
import { useUpdatePlay } from '../../data/use-plays'
import { useSlack } from '../../data/use-slack'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { ColumnManagementModal } from '../../ui/ColumnSelector'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { InfoBox } from '../../ui/InfoBox'
import { MiddotDivider } from '../../ui/Middot'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { TopBarContent } from '../../ui/TopBarContext'
import { useCurrentUser } from '../../ui/UserContext'
import { UserSelector } from '../../ui/UserSelector'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { SearchBar } from '../accounts/facets/search-bar'
import { ChannelPicker } from '../account_view_subscriptions/components/Slack'
import { AIAgentColumns } from '../ai_agents/components/AIAgentColumns'
import { mergeParams } from '../icps/types'
import { PlayItemsTable } from './components/play-items-table'

interface Props {
  play: Play & {
    play_sources?: Array<{
      id: string
      name: string
      status: 'queued' | 'processing' | 'complete'
      source_type: string
      total_items: number
      processed_items: number
      updated_at: string
      play_id: string
    }>
  }
  play_items: PlayItem[]
  stats: {
    total: number
    pending: number
    completed: number
    dismissed: number
    qualified: number
  }
  status?: 'pending' | 'completed' | 'dismissed' | 'snoozed'
  assignee_id?: string
  page_meta: PageMeta
  apps: Apps
}

// UserSelector supports null (unassigned) and undefined (everyone / no selection)
function getAssigneeIdFromParam(assigneeId: string | null | undefined) {
  if (assigneeId === 'unassigned') {
    return null
  }

  return assigneeId || undefined
}

function getParamFromAssigneeId(assigneeId: string | null | undefined) {
  if (assigneeId === null) {
    return 'unassigned'
  }

  return assigneeId
}

export default function Show({ apps: appsObject, play, play_items, stats, status, assignee_id, page_meta }: Props) {
  const deletionModal = useDisclosure()
  const user = useCurrentUser()

  const params = new URLSearchParams(window.location.search)
  const selectedStatus = status || params.get('status') || 'pending'
  const statCount = stats[selectedStatus] || 0

  const [notificationConfig, setNotificationConfig] = useState<PlayNotificationConfig | undefined>(
    play.notification_config
  )
  const [assigneeId, setAssigneeId] = useState<string | null | undefined>(getAssigneeIdFromParam(assignee_id))
  const [searchQuery, setSearchQuery] = useState('')

  const { mutate: updatePlay } = useUpdatePlay()

  const [items, setItems] = useState<PlayItem[]>(play_items ?? [])
  const [currentStats, setCurrentStats] = useState(stats)
  const [currentPageMeta, setCurrentPageMeta] = useState(page_meta)
  const [displayColumns, setDisplayColumns] = useState<DisplayColumn[]>(play.display_columns ?? [])

  const refreshTimerRef = useRef<NodeJS.Timeout | null>(null)

  // Store play sources separately to avoid reloading the progress manager
  const [playSources, setPlaySources] = useState(play.play_sources || [])

  useUpdateEffect(() => {
    setDisplayColumns(play.display_columns ?? [])
  }, [play.display_columns])

  useUpdateEffect(() => {
    setPlaySources(play.play_sources || [])
  }, [play.play_sources])

  // Update items for incoming play_items changes
  useUpdateEffect(() => {
    setItems(play_items || [])
  }, [play_items])

  useUpdateEffect(() => {
    setCurrentPageMeta(page_meta)
  }, [page_meta])

  useUpdateEffect(() => {
    setCurrentStats(stats)
  }, [stats])

  // Remove item when the status is different from the selected status
  // If it's the same status, we don't need to remove it
  const onRemoveItem = useCallback(
    (itemId: string, status: string) => {
      if (status !== selectedStatus) {
        setItems((items) => items.filter((item) => item.id !== itemId))
      }
    },
    [selectedStatus]
  )

  const onUpdateItem = useCallback((itemId: string, updatedItem: PlayItem) => {
    setItems((items) => items.map((item) => (item.id === itemId ? { ...item, ...updatedItem } : item)))
  }, [])

  const changeStatus = useCallback((status: string) => {
    const url = mergeParams(window.location.toString(), { status, page: '1' })
    router.visit(url)
  }, [])

  const onChangeNotifications = useCallback(
    (notificationConfig: PlayNotificationConfig) => {
      setNotificationConfig(notificationConfig)

      updatePlay(
        { playId: play.id, attributes: { notification_config: notificationConfig } },
        {
          onSuccess: () => {
            toast.success('Notifications saved')
          },
          onError: () => {
            toast.error('Failed to update notifications')
            // revert the change
            setNotificationConfig(play.notification_config)
          }
        }
      )
    },
    [play.id, play.notification_config, updatePlay]
  )

  const onUpdateColumns = useCallback(
    (columns: DisplayColumn[]) => {
      const updoots = columns.map((c) => ({
        id: c.id,
        type: c.type
      }))

      updatePlay(
        { playId: play.id, attributes: { display_columns: updoots } },
        {
          onSuccess: (res) => {
            setDisplayColumns(res.display_columns ?? [])
            toast.success('Columns updated')

            // refetch the play items!
            router.visit(window.location.toString(), { fetch: true })
          },
          onError: () => {
            toast.error('Failed to update columns')
          }
        }
      )
    },
    [play.id, updatePlay]
  )

  // Check if any play sources are still processing
  const hasIncompletePlaySources = useMemo(() => {
    return playSources.some((source) => source.status !== 'complete') || false
  }, [playSources])

  // Refresh data every 10 seconds if any play sources are still processing
  useEffect(() => {
    const refreshData = async () => {
      try {
        // Use projectPath with play ID and .json extension
        const url = projectPath(`/plays/${play.id}.json`)

        const data = await concurrentGET<{
          play: Props['play']
          play_items: PlayItem[]
          stats: Props['stats']
          page_meta: PageMeta
        }>(url)

        // Check if all sources are now complete
        const allSourcesComplete = data.play.play_sources?.every((source) => source.status === 'complete') ?? true
        if (allSourcesComplete) {
          setPlaySources(data.play.play_sources || [])
        }

        setItems(data.play_items || [])
        setCurrentStats(data.stats)
        setCurrentPageMeta(data.page_meta)

        // Only continue polling if there are still incomplete sources
        if (!allSourcesComplete) {
          refreshTimerRef.current = setTimeout(refreshData, 10000)
        }
      } catch (error) {
        console.error('Failed to refresh play data:', error)
        // On error, continue polling if we still think there are incomplete sources
        if (hasIncompletePlaySources) {
          refreshTimerRef.current = setTimeout(refreshData, 10000)
        }
      }
    }

    // Only start polling if there are incomplete sources AND the user is an internal user
    if (hasIncompletePlaySources && user.isInternalUser) {
      refreshTimerRef.current = setTimeout(refreshData, 10000)
    }

    return () => {
      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current)
        refreshTimerRef.current = null
      }
    }
  }, [play.id, hasIncompletePlaySources, user.isInternalUser])

  useUpdateEffect(() => {
    const user = getParamFromAssigneeId(assigneeId)
    if (user !== assignee_id) {
      const path = mergeParams(window.location.toString(), { user })
      router.visit(path)
    }
  }, [assigneeId, user?.id, assignee_id])

  const { data: aiAgents, isLoading: isLoadingAiAgents } = useAIAgents(
    play.target_type === 'Account' ? 'companies' : 'people'
  )

  const aiColumns = useMemo(() => {
    return (
      play.display_columns
        ?.filter((column) => column.type === 'agent')
        ?.map((column) => column.id.replace('ai.', '')) ?? []
    )
  }, [play.display_columns])

  const allColumnsStringList = useMemo(() => {
    return displayColumns.map((c) => {
      if (c.type === 'agent') {
        return `ai.${c.id}`
      }

      return c.id
    })
  }, [displayColumns])

  const apps = useMemo(() => Object.values(appsObject), [appsObject])

  return (
    <PageLayout size="full">
      <TopBarContent>
        <Breadcrumb
          paths={[
            { path: projectPath('/plays'), title: 'Plays' },
            { path: projectPath(`/plays/${play.id}`), title: play.name, isCurrentPage: true }
          ]}
        />
      </TopBarContent>

      <PageTitle skipRendering>Play - {play.name}</PageTitle>

      <Flex gap={4} alignItems="flex-start" justifyContent="space-between" flexWrap="wrap">
        <Stack flex="1" minW="400px" spacing={2}>
          <Stack spacing={3}>
            <Flex alignItems="flex-start" gap={3}>
              <PlaySourceIcon
                targetType={play.target_type}
                targetSource={play.target_config?.source}
                iconSize={5}
                padding={3}
                rounded="lg"
              />

              <Flex flexDirection="column" gap={0}>
                <Heading flex="none" size="md" lineHeight={1.4}>
                  {play.name}
                </Heading>

                <HStack color="gray.600" fontSize="xs" spacing={1.5} divider={<MiddotDivider />}>
                  <Text>
                    Updated <TimeAgo time={play.updated_at} />
                  </Text>
                  {play.updated_by_user ? (
                    <Text>Updated by {play.updated_by_user.name || play.updated_by_user.email}</Text>
                  ) : play.created_by_user ? (
                    <Text>Created by {play.created_by_user.name || play.created_by_user.email}</Text>
                  ) : null}
                </HStack>
              </Flex>
            </Flex>

            {play.description && (
              <Text fontSize="sm" color="gray.600" noOfLines={2}>
                {play.description}
              </Text>
            )}
          </Stack>
        </Stack>

        <HStack flex="none">
          <Flex flex="none" alignItems="center" gap={1} ml="auto">
            <Text fontSize="sm" color="gray.600">
              Assignee:
            </Text>
            <UserSelector
              selectedUserId={assigneeId}
              onChange={setAssigneeId}
              showUnassignedOption
              showEveryoneOption
            />
          </Flex>

          <Button
            size="sm"
            leftIcon={<IconSettings size={15} />}
            iconSpacing={1.5}
            as={Link}
            href={projectPath(`/plays/${play.id}/edit`)}
            variant="outline"
          >
            Edit
          </Button>

          <NotificationsPopover notificationConfig={notificationConfig} onChange={onChangeNotifications} apps={apps} />

          <Menu size="sm" autoSelect={false} placement="bottom-end">
            <MenuButton
              as={IconButton}
              aria-label="Actions"
              icon={<IconDotsVertical size={16} />}
              variant="ghost"
              size="sm"
            />
            <MenuList zIndex="popover">
              <MenuItem icon={<IconTrash size={16} />} color="red.500" onClick={deletionModal.onOpen}>
                Delete Play
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>

      <Grid width="100%" templateColumns={['repeat(1, 1fr)', 'repeat(auto-fit, minmax(120px, 1fr))']} gap={2}>
        <Stat label="Total" value={currentStats.total} />
        <Stat label="Pending" value={currentStats.pending} />
        {play.qualifiable && <Stat label="Qualified" value={currentStats.qualified} />}
        <Stat label="Completed" value={currentStats.completed} />
        <Stat label="Dismissed" value={currentStats.dismissed} />
      </Grid>

      <Card p={0} flex="1" display="flex" flexDirection="column" overflow="hidden" maxH="95vh">
        <Flex
          flex="none"
          justifyContent="space-between"
          alignItems="center"
          gap={6}
          paddingX={4}
          paddingY={4}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
        >
          <Heading size="sm">
            {play.target_type === 'Account' ? 'Companies' : 'People'} ({statCount.toLocaleString()})
          </Heading>
        </Flex>

        <Flex flex="none" alignItems="center" gap={4} paddingX={4} paddingY={2.5}>
          <SegmentedControl size="sm">
            <Button
              isActive={selectedStatus === 'pending'}
              iconSpacing={1.5}
              leftIcon={<StatusIcon status="pending" />}
              onClick={() => changeStatus('pending')}
            >
              Pending
            </Button>
            {play.qualifiable && (
              <Button
                isActive={selectedStatus === 'qualified'}
                iconSpacing={1.5}
                leftIcon={<StatusIcon status="qualified" />}
                onClick={() => changeStatus('qualified')}
              >
                Qualified
              </Button>
            )}
            <Button
              isActive={selectedStatus === 'completed'}
              iconSpacing={1.5}
              leftIcon={<StatusIcon status="completed" />}
              onClick={() => changeStatus('completed')}
            >
              Completed
            </Button>
            <Button
              isActive={selectedStatus === 'dismissed'}
              iconSpacing={1.5}
              leftIcon={<StatusIcon status="dismissed" />}
              onClick={() => changeStatus('dismissed')}
            >
              Dismissed
            </Button>
          </SegmentedControl>

          <Box flex="none" minW="300px">
            <SearchBar size="sm" value={searchQuery} onChange={setSearchQuery} inputProps={{ isDisabled: true }} />
          </Box>

          <Flex alignItems="center" gap={2} marginLeft="auto">
            <AIAgentColumns
              onColumnChange={(agentSlugs) => {
                // Preserve existing columns (mix of field and agent columns)
                const existingColumns = displayColumns.filter((c) => {
                  return c.type === 'field' || agentSlugs.includes(c.id)
                })

                const existingSlugs = existingColumns.filter((c) => c.type === 'agent').map((c) => c.id)

                // Append net-new agent columns
                const newColumns = agentSlugs.filter((id) => !existingSlugs.includes(id))
                const newAiColumns = newColumns.map((id) => ({
                  id,
                  type: 'agent' as const
                }))

                onUpdateColumns([...existingColumns, ...newAiColumns])
              }}
              selectedColumns={aiColumns}
              target={play.target_type === 'Account' ? 'companies' : 'people'}
            >
              <Button
                colorScheme={'purple'}
                variant="ghost"
                leftIcon={<Icon as={IconFlare} color="purple.500" boxSize={4} />}
                size="sm"
              >
                Add AI Agent
              </Button>
            </AIAgentColumns>

            <ColumnManagementModal
              audienceKind={play.target_type === 'Account' ? 'account' : 'profile'}
              apps={apps}
              initialColumns={allColumnsStringList}
              aiAgents={aiAgents?.my_agents}
              key={[play.id, isLoadingAiAgents, displayColumns].join(',')}
              onApply={(columns) => {
                const newColumns = columns.map((c) => ({
                  id: c.key,
                  type: c.isAIColumn ? ('agent' as const) : ('field' as const)
                }))

                onUpdateColumns(newColumns)
              }}
            />
          </Flex>
        </Flex>

        <Box display="flex" flexDirection="column" flex="1 1 auto" maxH="100%" minH="300px" position="relative">
          {playSources.length > 0 && user.isInternalUser && false && (
            <PlaySourceProgressManager playSources={playSources} />
          )}
          <PlayItemsTable
            apps={apps}
            items={items}
            recordType={play.target_type}
            displayColumns={displayColumns}
            onUpdateColumns={onUpdateColumns}
            status={selectedStatus}
            assigneeId={assigneeId}
            onRemoveItem={onRemoveItem}
            onUpdateItem={onUpdateItem}
            showQualificationColumn={play.qualifiable}
          />
          {items.length > 0 && (
            <TableFooter
              pageMeta={currentPageMeta}
              page={currentPageMeta.current_page}
              nextPath={mergeParams(window.location.toString(), {
                page: (currentPageMeta.current_page + 1).toString()
              })}
              prevPath={mergeParams(window.location.toString(), {
                page: (currentPageMeta.current_page - 1).toString()
              })}
              px={4}
              sticky
            />
          )}
        </Box>
      </Card>

      <DeleteConfirmation
        isOpen={deletionModal.isOpen}
        onClose={deletionModal.onClose}
        title="Delete Play"
        deletePath={projectPath(`/plays/${play.id}`)}
        confirmLabel="Yes, delete this play"
      >
        Are you sure you want to delete this play?
      </DeleteConfirmation>
    </PageLayout>
  )
}

function Stat(props: { label: string; value?: number | null }) {
  return (
    <Card px={3} py={3}>
      <Flex flex="1 1 0%" gap={2} direction="column" justifyContent="space-between">
        <Stack spacing={1.5}>
          <Heading size="xs" fontWeight="normal" color="gray.600">
            {props.label}
          </Heading>
          <Text fontSize="xl" fontWeight="semibold" lineHeight={1}>
            {(props.value || 0).toLocaleString()}
          </Text>
        </Stack>
      </Flex>
    </Card>
  )
}

interface NotificationsPopoverProps {
  notificationConfig?: PlayNotificationConfig
  onChange: (notificationConfig: PlayNotificationConfig) => void
  apps: App[]
}

function NotificationsPopover(props: NotificationsPopoverProps) {
  const [notificationConfig, setNotificationConfig] = useState<PlayNotificationConfig | undefined>(
    props.notificationConfig
  )
  const isEnabled = useMemo(() => {
    return Object.values(notificationConfig || {}).some((notificationConfig) => {
      return notificationConfig.email?.enabled || notificationConfig.slack?.enabled
    })
  }, [notificationConfig])

  const NOTIFICATION_TYPES = [
    { key: 'new_assigned_leads', label: 'New assigned leads', avaliable_channels: ['email', 'slack'] },
    { key: 'new_enrollment', label: 'New enrollment', avaliable_channels: ['slack'] }
  ]

  const isSlackEnabled = useMemo(() => {
    return Object.values(notificationConfig || {}).some((notificationConfig) => notificationConfig?.slack?.enabled)
  }, [notificationConfig])

  const slackData = useSlack()
  const [newChannelName, setNewChannelName] = useState('')
  const [creatingChannel, setCreatingChannel] = useState(false)
  const slackApp = useMemo(() => props.apps.find((app) => app.app_module === 'Apps::Slack::App'), [props.apps])

  const setChannel = useCallback(
    (channel: { id: string; name: string }) => {
      const newConfig = {
        ...notificationConfig,
        slack_configuration: {
          ...(notificationConfig?.slack_configuration || {}),
          channel: {
            id: channel?.id ?? '',
            name: channel?.name ?? ''
          }
        }
      } as PlayNotificationConfig
      setNotificationConfig(newConfig)
      props.onChange(newConfig)
    },
    [notificationConfig, props]
  )

  const createNewChannel = useCallback(async () => {
    setCreatingChannel(true)

    await post<{ ok: boolean; channel: any }>(projectPath('/slack-alerts/create_channel'), {
      app_instance_id: slackData.data?.app_id,
      channel_name: newChannelName
    })
      .then((response) => {
        if (response?.channel?.id) {
          slackData.refetch()
          toast.success(
            <div>
              Channel <strong>{newChannelName}</strong> created successfully
            </div>
          )
          setChannel(response.channel)
        }
      })
      .catch(() => {
        toast.error("We couldn't create the channel. Try again later.")
      })

    setCreatingChannel(false)
  }, [newChannelName, slackData, setChannel])

  return (
    <Popover placement="bottom-end" isLazy lazyBehavior="keepMounted">
      <PopoverTrigger>
        <Button
          size="sm"
          leftIcon={isEnabled ? <IconBellRingingFilled size={15} /> : <IconBell size={15} />}
          iconSpacing={1.5}
          colorScheme={isEnabled ? 'lightPurple' : 'gray'}
          variant="outline"
          aria-label="Notification Settings"
        >
          Notifications
        </Button>
      </PopoverTrigger>
      <PopoverContent width="400px">
        <PopoverArrow />
        <PopoverBody padding={4}>
          <Stack spacing={6}>
            <Stack spacing={0.5}>
              <Text fontSize="15px" fontWeight="semibold">
                Notifications
              </Text>
              <Text fontSize="13px" color="gray.500">
                Manage notifications for your team across email and Slack.
              </Text>
            </Stack>

            <Stack spacing={6} divider={<Box borderBottom="1px solid" borderColor="gray.200" />}>
              {NOTIFICATION_TYPES.map((notificationType) => {
                return (
                  <Box key={notificationType.key}>
                    <Text fontSize="md" fontWeight="semibold" color="gray.700" mb={4}>
                      {notificationType.label}
                    </Text>
                    <Stack spacing={3}>
                      {notificationType.avaliable_channels.includes('email') && (
                        <FormControl display="flex" alignItems="center">
                          <Switch
                            size="sm"
                            id={`${notificationType.key}-email`}
                            isChecked={notificationConfig?.[notificationType.key]?.email?.enabled}
                            onChange={(e) => {
                              const newConfig = {
                                ...notificationConfig,
                                [notificationType.key]: {
                                  ...(notificationConfig?.[notificationType.key] || {}),
                                  email: {
                                    enabled: e.target.checked
                                  }
                                }
                              } as PlayNotificationConfig
                              setNotificationConfig(newConfig)
                              props.onChange(newConfig)
                            }}
                          />
                          <FormLabel
                            htmlFor={`${notificationType.key}-email`}
                            mb={0}
                            ml={2}
                            fontSize="sm"
                            color="gray.600"
                          >
                            Email notifications
                          </FormLabel>
                        </FormControl>
                      )}
                      {slackApp?.connected && (
                        <Stack>
                          <FormControl display="flex" alignItems="center">
                            <Switch
                              size="sm"
                              id={`${notificationType.key}-slack`}
                              isChecked={notificationConfig?.[notificationType.key]?.slack?.enabled}
                              isDisabled={!notificationType.avaliable_channels.includes('slack')}
                              onChange={(e) => {
                                const newConfig = {
                                  ...notificationConfig,
                                  [notificationType.key]: {
                                    ...(notificationConfig?.[notificationType.key] || {}),
                                    slack: {
                                      enabled: e.target.checked
                                    }
                                  }
                                } as PlayNotificationConfig
                                setNotificationConfig(newConfig)
                                props.onChange(newConfig)
                              }}
                            />
                            <FormLabel
                              htmlFor={`${notificationType.key}-slack`}
                              mb={0}
                              ml={2}
                              fontSize="sm"
                              color="gray.600"
                            >
                              Slack notifications
                            </FormLabel>
                          </FormControl>
                        </Stack>
                      )}
                    </Stack>
                  </Box>
                )
              })}

              {isSlackEnabled && (
                <FormControl>
                  <Text fontSize="xs" fontWeight="medium" color="gray.600" textTransform="uppercase" mb={2}>
                    Slack Configuration
                  </Text>
                  <ChannelPicker
                    allowCreateNewChannel
                    channels={slackData.data?.deps?.channels ?? []}
                    channel={notificationConfig?.slack_configuration?.channel}
                    setChannel={(channel) => {
                      if (channel?.newChannel) {
                        setNewChannelName(channel.name)
                        return
                      }

                      if (channel?.refreshChannels) {
                        slackData.refetch()
                        return
                      }

                      if (isEmpty(channel?.id)) {
                        return
                      }

                      if (channel) {
                        setChannel(channel)
                      }
                    }}
                    isNewChannelEntered={false}
                    chakraInputProps={{
                      size: 'sm',
                      rounded: 'md',
                      placeholder: 'Select a Slack channel'
                    }}
                  />

                  <Checkbox
                    size="sm"
                    colorScheme="purple"
                    mt={2}
                    isChecked={notificationConfig?.slack_configuration?.include_mentions}
                    onChange={(e) => {
                      const newConfig = {
                        ...notificationConfig,
                        slack_configuration: {
                          ...(notificationConfig?.slack_configuration || {}),
                          include_mentions: e.target.checked
                        }
                      } as PlayNotificationConfig
                      setNotificationConfig(newConfig)
                      props.onChange(newConfig)
                    }}
                  >
                    Notify users via @mentions
                  </Checkbox>

                  {newChannelName && (
                    <InfoBox colorScheme="blue" mt={4}>
                      <Text maxW="50%">
                        A new public channel named{' '}
                        <Code color="inherit" fontSize="xs" fontWeight="semibold" bg="transparent" marginX={0}>
                          #{newChannelName}
                        </Code>{' '}
                        will be created.
                      </Text>
                      <Button
                        aria-label="Create it now"
                        variant="outline"
                        size="xs"
                        style={{ marginLeft: 'auto' }}
                        onClick={createNewChannel}
                        isLoading={creatingChannel}
                        leftIcon={<IconPlayerPlay size={14} />}
                      >
                        Create now
                      </Button>
                    </InfoBox>
                  )}
                </FormControl>
              )}

              {!slackApp?.connected && (
                <Text fontSize="xs" color="gray.500">
                  Slack notifications are not available for this project. Please configure Slack in the{' '}
                  <Link href={projectPath('/apps')}>Apps</Link> page.
                </Text>
              )}
            </Stack>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

function StatusIcon(props: { status: string }) {
  switch (props.status) {
    case 'completed':
      return <CircleIcon icon={IconCheck} iconSize={3.5} size={5} colorScheme="green" />
    case 'qualified':
      return <CircleIcon icon={IconCheck} iconSize={3.5} size={5} colorScheme="blue" />
    case 'dismissed':
      return <CircleIcon icon={IconX} iconSize={3.5} size={5} colorScheme="red" />
    case 'pending':
      return <Icon as={IconCircleDashed} boxSize={5} color="gray.300" />
    default:
      return <Icon as={IconCircle} boxSize={5} color="gray.400" />
  }
}
