import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { format } from 'friendly-numbers'
import get from 'lodash/get'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { Company } from '../../../../types/Profile'
import { useCompanyFieldValues } from '../../../data/use-field-values'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import Combobox from '../../../ui/Combobox'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useArchive } from './useArchive'
import { useUnarchive } from './useUnarchive'
import { usePublish } from './usePublish'
import { useUnpublish } from './useUnpublish'

interface EditDetailsProps {
  isOpen: boolean
  onClose: () => void
  company: Company
  updateCompany: (company: Company) => void
}

const fieldOptions = {
  'metrics.employeesRange': ['1-10', '11-50', '51-250', '251-1K', '1K-5K', '5K-10K', '10K-50K', '50K-100K', '100K+'],
  'metrics.estimatedAnnualRevenue': [
    '$0-$1M',
    '$1M-$10M',
    '$10M-$50M',
    '$50M-$100M',
    '$100M-$250M',
    '$250M-$500M',
    '$500M-$1B',
    '$500M-$10B',
    '$1B-$10B',
    '$10B+'
  ]
}

export const fields = [
  { key: 'name', label: 'Name' },
  { key: 'description', label: 'Description' },
  { key: 'founded_year', label: 'Founded Year' },
  { key: 'logo', label: 'Logo URL' },
  { key: 'category.sector', label: 'Sector', type: 'select' },
  { key: 'category.industryGroup', label: 'Industry Group', type: 'select' },
  { key: 'category.industry', label: 'Industry', type: 'select' },
  { key: 'category.subIndustry', label: 'Sub Industry', type: 'select' },
  { key: 'metrics.raised', label: 'Amount Raised' },
  { key: 'metrics.employees', label: 'Employees' },
  { key: 'metrics.employeesRange', label: 'Employees Range', type: 'select' },
  { key: 'metrics.annualRevenue', label: 'Annual Revenue' },
  { key: 'metrics.estimatedAnnualRevenue', label: 'Estimated Annual Revenue', type: 'select' },
  { key: 'location', label: 'Location' },
  { key: 'geo.city', label: 'City', type: 'select' },
  { key: 'geo.state', label: 'State', type: 'select' },
  { key: 'geo.country', label: 'Country', type: 'select' },
  { key: 'linkedin.url', label: 'LinkedIn URL' },
  { key: 'twitter.url', label: 'Twitter URL' },
  { key: 'facebook.url', label: 'Facebook URL' },
  { key: 'crunchbase.url', label: 'Crunchbase URL' }
]

export function EditDetails({ isOpen, onClose, company, updateCompany }: EditDetailsProps) {
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const [submitting, setSubmitting] = React.useState(false)

  const formAction = `/admin/kdb/${company.domain}`

  const submit = React.useCallback(async () => {
    if (!formRef.current) {
      return
    }

    const formData = new FormData(formRef.current)

    try {
      await postForm(formAction, formData)
      toast.success('Manual update applied!')
      onClose()
    } catch (_err) {
      toast.error('Failed to save your update', {
        description: 'We have been notified of the error, but you can try again soon!'
      })
    }
  }, [onClose, formAction])

  const onSubmit = React.useCallback(
    async (event) => {
      event.preventDefault()
      setSubmitting(true)
      await submit()
      setSubmitting(false)
    },
    [submit]
  )

  const { isPending: archiving, mutateAsync: archive } = useArchive()
  const { isPending: unarchiving, mutateAsync: unarchive } = useUnarchive()
  const { isPending: publishing, mutateAsync: publish } = usePublish()
  const { isPending: unpublishing, mutateAsync: unpublish } = useUnpublish()

  const isArchiving = archiving || unarchiving

  const onArchive = useCallback(async () => {
    if (!company.domain) {
      return
    }

    try {
      const data = company.archived_at
        ? await unarchive({ domain: company.domain })
        : await archive({ domain: company.domain })
      updateCompany(data.company)
      onClose()
    } catch {
      // do nothing
    }
  }, [company.domain, company.archived_at, archive, unarchive, updateCompany, onClose])

  const onTogglePublish = useCallback(async () => {
    if (!company.domain) {
      return
    }

    const params = { domain: company.domain }

    try {
      const data = company.published ? await unpublish(params) : await publish(params)
      updateCompany(data.company)
    } catch {
      // do nothing
    }
  }, [company.domain, company.published, publish, unpublish, updateCompany])

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      preserveScrollBarGap
      closeOnEsc={!submitting && !isArchiving}
      closeOnOverlayClick={!submitting && !isArchiving}
    >
      <form ref={formRef} action={formAction} method="PUT" onSubmit={onSubmit}>
        <input type="hidden" name="_method" value="PUT" />
        <AuthenticityToken />

        <DrawerOverlay bg="blackAlpha.300" />
        <DrawerContent>
          <DrawerHeader>
            Update {company.name || company.domain}
            {company.archived_at && (
              <Text fontSize="sm" color="gray.500" ml="auto">
                Archived <TimeAgo time={company.archived_at} />
              </Text>
            )}
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <Stack spacing={8} marginBottom={8}>
              {fields.map((field) => (
                <Field key={field.key} field={field} value={get(company, field.key)} type={field.type} />
              ))}
            </Stack>
          </DrawerBody>

          <DrawerFooter gap={3}>
            <Button
              size="sm"
              variant="outline"
              colorScheme={company.archived_at ? undefined : 'red'}
              isLoading={isArchiving}
              onClick={onArchive}
            >
              {company.archived_at ? 'Unarchive' : 'Archive'}
            </Button>

            <Button size="sm" variant="outline" isLoading={publishing || unpublishing} onClick={onTogglePublish}>
              {company.published ? 'Unlist' : 'Publish'}
            </Button>

            {company.archived_at && (
              <Text fontSize="sm" color="gray.500">
                Archived <TimeAgo time={company.archived_at} />
              </Text>
            )}

            <Button size="sm" variant="outline" onClick={onClose} ml="auto" isDisabled={isArchiving || submitting}>
              Cancel
            </Button>

            <Button size="sm" type="submit" colorScheme="purple" isLoading={submitting} isDisabled={isArchiving}>
              Update Company
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  )
}

interface FieldProps {
  field: {
    key: string
    label: string
  }
  value?: string | null
  type?: string
}

function Field(props: FieldProps) {
  const initialValue = props.value || ''
  const [val, setVal] = useState(initialValue)

  return (
    <FormControl>
      <HStack justifyContent="space-between" mb={2}>
        <FormLabel mb={0}>{props.field.label}</FormLabel>
        {val !== initialValue && (
          <Button size="xs" variant="link" colorScheme="purple" onClick={() => setVal(initialValue)}>
            Reset
          </Button>
        )}
      </HStack>
      {fieldOptions[props.field.key] || props.type === 'select' ? (
        <SelectField {...props} value={val} onChange={setVal} />
      ) : (
        <Input type="text" name={`company[${props.field.key}]`} value={val} onChange={(e) => setVal(e.target.value)} />
      )}
    </FormControl>
  )
}

function SelectField(props: FieldProps & { onChange: (value: string) => void }) {
  const value = props.value || ''
  const { data, isLoading } = useCompanyFieldValues(props.field.key, '', {
    enabled: !fieldOptions[props.field.key]
  })

  const counts = useMemo(() => {
    return (data?.values || []).reduce((acc, val) => {
      acc[val.key] = val.doc_count || 0
      return acc
    }, {})
  }, [data?.values])

  const options = useMemo(() => {
    let opts = fieldOptions[props.field.key] || data?.values?.map((v) => v.key) || []

    // sort geo alphabetically
    if (props.field.key.includes('geo.')) {
      opts = opts.sort()
    }

    return opts
  }, [props.field.key, data?.values])

  if (isLoading) {
    return <Spinner size="sm" thickness="1.5px" color="gray.400" />
  }

  if (options.length === 0) {
    return (
      <Input
        type="text"
        name={`company[${props.field.key}]`}
        value={value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    )
  }

  // use a normal select if the options are defined here or small enough
  if (options.length <= 50) {
    return (
      <Select
        name={`company[${props.field.key}]`}
        value={value}
        onChange={(e) => props.onChange(e.target.value)}
        fontWeight="normal"
      >
        <option value="">--</option>

        {options.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Select>
    )
  }

  return (
    <>
      <input type="hidden" name={`company[${props.field.key}]`} value={value} />
      <Combobox
        items={options}
        initialInputValue={props.value || ''}
        selectedItem={value}
        onInputValueChange={({ inputValue }) => {
          props.onChange(inputValue || '')
        }}
        onSelectedItemChange={({ selectedItem }) => {
          props.onChange(selectedItem || '')
        }}
        renderItem={({ item }) => (
          <HStack width="100%" justifyContent="space-between">
            <Text fontSize="sm">{item}</Text>
            {counts[item] && (
              <Text fontSize="sm" color="purple.500">
                {format(counts[item])}
              </Text>
            )}
          </HStack>
        )}
      />
    </>
  )
}
