import {
  ButtonProps,
  Circle,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Square,
  Stack,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import {
  IconAlertCircle,
  IconBriefcase,
  IconChevronDown,
  IconChevronRight,
  IconClick,
  IconExternalLink,
  IconId,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconUser,
  IconWorld
} from '@tabler/icons-react'
import { AnimatePresence, motion } from 'framer-motion'
import throttle from 'lodash/throttle'
import uniqBy from 'lodash/uniqBy'
import React from 'react'
import { subscribeToChannel, SubscriptionEmitter } from '../../../../channels/generic_channel'
import { DateTime, Identify, PageView, ProfileEvent } from '../../../../types/Profile'
import { LightBgCard } from '../../../ui/Card'
import { BuildingIcon } from '../../../ui/icons'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import { TimeAgo } from '../../../ui/TimeAgo'
import { Toggle } from '../../accounts/components/Toggle'
import { accountPath } from '../../accounts/lib/account-path'
import { FormSubmission } from '../../forms/reports/submission-list'

interface Props {
  stream: {
    messages: Message[]
    pv: Message[]
    form: Message[]
    ev: Message[]
    identify: Message[]
    assoc: Message[]
    account_event: Message[]
  }
}

interface CompanyAssociation {
  profile_id: string
  company_name: string
  company_logo: string
  company_domain: string
  match_type: 'ip' | 'email'
  matched_at: DateTime
}

interface InstrumentationError {
  ip: string
  profile_id?: string
  errors:
    | Array<{
        message: string
        description: string
      }>
    | {
        message: string
        description: string
      }
  payload: object
  received_at: DateTime
}

interface AccountEvent {
  message_id: string
  event: string
  event_type: string
  timestamp: DateTime
  source?: string
  external_id?: string
  account_id?: string
  company_name?: string
  company_logo?: string
  company_domain?: string
  properties?: { [key: string]: any }
}

export interface Message {
  id: string
  action: string
  type: 'pv' | 'form' | 'ev' | 'account_event' | 'identify' | 'assoc' | 'id-form' | 'errors' | 'id' // id is the id for identifies
  s:
    | PageView
    | FormSubmission
    | ProfileEvent
    | AccountEvent
    | Identify
    | CompanyAssociation
    | InstrumentationError
    | object
    | null
}

export interface RawEvent {
  context?: {
    source?: string
  }
}

function label(message: Message) {
  let source = (message.s as RawEvent)?.context?.source
  if (source === 'identify') {
    source = undefined
  }

  switch (message.type) {
    case 'pv':
      return 'Pageview'
    case 'form':
      return 'Form'
    case 'ev':
      return 'Event'
    case 'identify':
      return `Identify ${source ? `(${source})` : ''}`
    case 'id':
      return `Identify ${source ? `(${source})` : ''}`
    case 'id-form':
      return `Identify ${source ? `(${source})` : ''}`
    case 'assoc':
      return `Company Match (via ${(message.s as CompanyAssociation)?.match_type})`
    case 'errors':
      return 'Error'
    case 'account_event':
      return 'Account Event'
    default:
      return 'Unknown'
  }
}

function getOriginPath(value?: string) {
  try {
    const url = new URL(value || '')
    return url.hostname + url.pathname
  } catch (_err) {
    return value
  }
}

function arrayWrap<T>(item: T): Array<T> {
  if (Array.isArray(item)) {
    return item
  }

  return [item]
}

function title(message: Message) {
  if (!message.s) {
    return null
  }

  switch (message.type) {
    case 'pv':
      return getOriginPath((message.s as PageView).url)
    case 'form':
      return getOriginPath((message.s as FormSubmission).page_url)
    case 'ev':
      return (message.s as ProfileEvent).event
    case 'identify':
      return (message.s as Identify).email || 'no email sent'
    case 'id-form':
      return (message.s as Identify).email || 'no email sent'
    case 'id':
      return (message.s as Identify).email || 'no email sent'
    case 'errors':
      return arrayWrap((message.s as any).errors)[0].message
    case 'assoc':
      return (message.s as CompanyAssociation).company_name ?? (message.s as CompanyAssociation).company_domain
    case 'account_event':
      return (message.s as AccountEvent).event
    default:
      return 'Unknown'
  }
}

function traits_count(message: Message) {
  if (!message.s) {
    return null
  }

  const count = (traits) => (traits ? Object.keys(traits).length : 0)

  switch (message.type) {
    case 'identify':
      return `${count((message.s as Identify)?.traits)} total traits`
    case 'id-form':
      return `${count((message.s as Identify)?.traits)} total traits`
    case 'id':
      return `${count((message.s as Identify)?.traits)} total traits`
    default:
      return null
  }
}

function ts(message: Message) {
  if (!message.s) {
    return null
  }

  switch (message.type) {
    case 'pv':
      return (message.s as PageView).visit_end ?? (message.s as PageView).visit_start
    case 'form':
      return (message.s as FormSubmission).updated_at
    case 'ev':
      return (message.s as ProfileEvent).sent_at
    case 'identify':
      return (message.s as Identify).sent_at
    case 'id':
      return (message.s as Identify).sent_at
    case 'id-form':
      return (message.s as Identify).sent_at
    case 'assoc':
      return (message.s as CompanyAssociation).matched_at
    case 'errors':
      return (message.s as InstrumentationError).received_at
    case 'account_event':
      return (message.s as AccountEvent).timestamp
    default:
      return 'Unknown'
  }
}

function colorScheme(message: Message): ButtonProps['colorScheme'] {
  if (!message.s) {
    return 'gray'
  }

  switch (message.type) {
    case 'pv':
      return 'cyan'
    case 'form':
      return 'green'
    case 'ev':
    case 'account_event':
      return 'teal'
    case 'identify':
      return 'blue'
    case 'id':
      return 'blue'
    case 'id-form':
      return 'blue'
    case 'assoc':
      return 'purple'
    case 'errors':
      return 'red'
    default:
      return 'gray'
  }
}

function icon(message: Message): React.ReactNode {
  const color = colorScheme(message)

  if (!message.s) {
    return null
  }

  switch (message.type) {
    case 'pv':
      return (
        <Circle alignSelf="center" bg={`${color}.50`} p={1.5} flex="none">
          <Icon as={IconWorld} boxSize={5} color={`${color}.500`} />
        </Circle>
      )
    case 'form':
      return (
        <Circle alignSelf="center" bg={`${color}.50`} p={1.5} flex="none">
          <Icon as={IconId} boxSize={5} color={`${color}.500`} />
        </Circle>
      )
    case 'ev':
    case 'account_event':
      return (
        <Circle alignSelf="center" bg={`${color}.50`} p={1.5} flex="none">
          <Icon as={IconClick} boxSize={5} color={`${color}.500`} />
        </Circle>
      )
    case 'identify':
      return (
        <Circle alignSelf="center" bg={`${color}.50`} p={1.5} flex="none">
          <Icon as={IconUser} boxSize={5} color={`${color}.500`} />
        </Circle>
      )
    case 'id':
      return (
        <Circle alignSelf="center" bg={`${color}.50`} p={1.5} flex="none">
          <Icon as={IconUser} boxSize={5} color={`${color}.500`} />
        </Circle>
      )
    case 'id-form':
      return (
        <Circle alignSelf="center" bg={`${color}.50`} p={1.5} flex="none">
          <Icon as={IconUser} boxSize={5} color={`${color}.500`} />
        </Circle>
      )
    case 'assoc':
      return (
        <Circle alignSelf="center" bg={`${color}.50`} p={1.5} flex="none">
          <Icon as={BuildingIcon} boxSize={5} color={`${color}.500`} />
        </Circle>
      )

    case 'errors':
      return (
        <Circle alignSelf="center" bg={`${color}.50`} p={1.5} flex="none">
          <Icon as={IconAlertCircle} boxSize={5} color={`${color}.500`} />
        </Circle>
      )
    default:
      return null
  }
}

function ProfileLink(props: { message: Message }) {
  const identifier = (props.message.s as any)?.email || (props.message.s as any)?.profile_id
  if (identifier) {
    return (
      <Link href={projectPath(`/profiles/${identifier}`)} color="gray.500" isExternal>
        <IconExternalLink size={14} />
      </Link>
    )
  }

  return null
}

function AccountLink(props: { message: Message }) {
  const domain = (props.message.s as any)?.company_domain
  if (domain) {
    return (
      <Link href={projectPath(`/accounts/${domain}`)} color="gray.500" isExternal>
        <IconExternalLink size={14} />
      </Link>
    )
  }

  return null
}

export function Stream(props: Props) {
  const project = useCurrentProject()
  const [messages, setMessages] = React.useState<Message[]>(props.stream.messages)
  const [paused, setPaused] = React.useState(false)

  const [filter, setFilter] = React.useState<Message['type'] | 'all'>('all')
  const [sampling, setSampling] = React.useState(1)

  const formatSpeed = (val: string) => val + 'x'
  const parseSpeed = (val: string) => parseFloat(val.replace('x', ''))

  const subscription = React.useRef<SubscriptionEmitter>()
  const debounceUpdate = React.useMemo(
    () =>
      throttle(
        (message: Message, setMessages) => {
          setMessages((msgs) => [message, ...msgs].slice(0, 100))
        },
        1000 * (1 / sampling),
        {
          trailing: true
        }
      ),
    [sampling]
  )

  const onData = React.useCallback(
    (message: Message) => {
      if (paused) {
        return
      }

      if (sampling < 1) {
        const random = Math.random()

        if (random > sampling) {
          return
        }
      }

      debounceUpdate(message, setMessages)
    },
    [paused, sampling, debounceUpdate]
  )

  React.useEffect(() => {
    if (!project?.slug) {
      return
    }

    subscription.current = subscribeToChannel({ channel: 'InstrumentationChannel', project_slug: project?.slug })
    subscription.current.on('received', onData)

    return () => {
      subscription.current?.off('received', onData)
      subscription.current?.unsubscribe()
      subscription.current = undefined
    }
  }, [project?.slug, onData])

  const visible = React.useMemo(() => {
    const filtered = messages.filter((m) => filter === 'all' || m.type === filter)
    return uniqBy([...filtered, ...(props.stream[filter] ?? [])], (i) => i.id) ?? []
  }, [messages, filter, props.stream])

  return (
    <Stack
      w="100%"
      spacing="6"
      paddingY={8}
      paddingX={6}
      maxW={{
        sm: '100%',
        md: '400px'
      }}
      height="100%"
      overflow="hidden"
    >
      <Stack>
        <Heading size="sm">Incoming data</Heading>
        <Text fontSize="sm">
          This is a live feed of events being sent to Koala. Use it to debug your instrumentation.
        </Text>
      </Stack>
      <HStack justifyContent={'space-between'} w="100%">
        {!paused && (
          <IconButton
            variant={'ghost'}
            aria-label="Pause"
            onClick={() => setPaused(true)}
            icon={<IconPlayerPauseFilled size="16" />}
            size="sm"
          />
        )}
        {paused && (
          <IconButton
            variant={'ghost'}
            aria-label="Play"
            onClick={() => setPaused(false)}
            icon={<IconPlayerPlayFilled size="16" />}
            size="sm"
          />
        )}
        <Select
          value={filter}
          onChange={(v) => setFilter(v.target.value as Message['type'] | 'all')}
          size="sm"
          rounded={'md'}
          w="220px"
        >
          <option value="all">All</option>
          <option value="pv">Pageviews</option>
          <option value="form">Forms</option>
          <option value="ev">Events</option>
          <option value="account_event">Account Events</option>
          <option value="identify">Identifies</option>
          <option value="assoc">Companies</option>
          <option value="errors">Errors</option>
        </Select>
        <HStack fontSize={'xs'} pl="2">
          <Text>Speed</Text>

          <NumberInput
            step={0.1}
            size="xs"
            onChange={(valueString) => setSampling(parseSpeed(valueString))}
            value={formatSpeed(sampling.toString())}
            min={0.1}
            max={1}
            w="80px"
          >
            <NumberInputField rounded="md" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </HStack>
      </HStack>
      {/* @ts-ignore the types are too strict on our version of framer-motion */}
      <AnimatePresence initial={false} exitBeforeEnter presenceAffectsLayout>
        <Stack listStyleType={'none'} spacing="3" w="100%" maxH="calc(100vh - 200px)" overflow={'auto'}>
          {visible.map((message) => {
            const company = message.type === 'assoc' ? (message.s as CompanyAssociation) : null

            return (
              <motion.li
                key={message.id}
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ type: 'spring' }}
                style={{
                  width: '100%'
                }}
              >
                <LightBgCard
                  key={message.id}
                  bg="white"
                  w="100%"
                  p={3}
                  _hover={{
                    bg: `gray.50`
                  }}
                >
                  <Flex alignItems="flex-start" color="gray.700" gap={3}>
                    {company ? (
                      <Link
                        href={accountPath({
                          company: {
                            domain: company.company_domain
                          }
                        })}
                        isExternal
                      >
                        <Square size={8} padding={1} rounded="md" bg="gray.50">
                          <Image
                            src={`https://logo.clearbit.com/${company.company_domain}`}
                            fallback={<IconBriefcase size="14" />}
                          />
                        </Square>
                      </Link>
                    ) : (
                      icon(message)
                    )}
                    <Stack flex="1" spacing="0.5">
                      <Text fontSize="11px" fontWeight="medium" textTransform="uppercase" letterSpacing={0.5}>
                        {label(message)}
                      </Text>

                      <Flex alignItems="center" gap={1.5}>
                        <Tooltip label={title(message)}>
                          {company ? (
                            <Link
                              href={accountPath({
                                company: {
                                  domain: company.company_domain
                                }
                              })}
                              isExternal
                              fontSize="11px"
                              maxW="48"
                              lineHeight={1.25}
                              isTruncated
                            >
                              {company.company_name ?? company.company_domain}
                            </Link>
                          ) : (
                            <VStack alignItems="flex-start" spacing={1}>
                              <Text fontSize="11px" lineHeight={1.25} maxW="48" isTruncated>
                                {title(message)}
                              </Text>
                              <Text fontSize="11px" lineHeight={1.25} maxW="48" isTruncated>
                                {traits_count(message)}
                              </Text>
                            </VStack>
                          )}
                        </Tooltip>
                      </Flex>
                      {message.type === 'errors' && (
                        <Stack>
                          <Text fontSize="11px" color="gray.500">
                            {JSON.stringify((message.s as InstrumentationError).errors)}
                          </Text>
                          <Divider />
                          <Toggle
                            title={<Text fontSize="11px">Payload</Text>}
                            toggleIcon={(isOpen) =>
                              isOpen ? <IconChevronDown size="11px" /> : <IconChevronRight size="11px" />
                            }
                          >
                            <Text as="pre" fontSize="11px" color="gray.500">
                              {JSON.stringify(
                                {
                                  profile_id: (message.s as InstrumentationError).profile_id,
                                  ...(message.s as InstrumentationError).payload
                                },
                                null,
                                2
                              )}
                            </Text>
                          </Toggle>
                        </Stack>
                      )}
                    </Stack>
                    <Stack justifyContent="flex-end" alignItems="flex-end">
                      <Text fontSize="11px" color="gray.500">
                        <TimeAgo time={ts(message)} />
                      </Text>
                      {message.type === 'account_event' ? (
                        <AccountLink message={message} />
                      ) : (
                        <ProfileLink message={message} />
                      )}
                    </Stack>
                  </Flex>
                </LightBgCard>
              </motion.li>
            )
          })}
        </Stack>
      </AnimatePresence>
    </Stack>
  )
}
