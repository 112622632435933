import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Portal,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import {
  IconAdjustmentsHorizontal,
  IconChevronDown,
  IconFlareFilled,
  IconPlayerPlay,
  IconPlus
} from '@tabler/icons-react'
import Fuse from 'fuse.js'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { del, get, post } from '../../../lib/api'
import { usePlayItemAIRecommendationsStream } from '@app/components/data/use-ai-recommendations'
import { ResearchProgress } from './components/ResearchProgress'
import router from '../../../lib/router'
import { AIAgent } from '../../../types/AIAgent'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { CompanySelector, PartialCompany } from '../../ui/CompanySelector'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import EmptyState from '../../ui/EmptyState'
import { useFeatureRequest } from '../../ui/FeedbackForm'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { TopBarContent } from '../../ui/TopBarContext'
import { PartialVisitor, VisitorSelector } from '../../ui/VisitorSelector'
import { titleize } from '../accounts/facets/filter-cloud'
import { SearchBar } from '../accounts/facets/search-bar'
import { AIAgentCard } from './components/AIAgentCard'
import { EditAgentModal } from './components/AIAgentForm'
import { AIAgentResponse } from './components/AIAgentResponse'

interface Props {
  my_agents: Array<AIAgent & { category?: string }>
  template_agents: Array<AIAgent & { category?: string }>
  access_requested?: boolean
  agents_entitlements: {
    enabled: boolean
    // TODO add agent limit
  }
}

export default function Index(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedAgent, setSelectedAgent] = React.useState<Props['template_agents'][0] | null>(null)
  const [agentToEdit, setAgentToEdit] = React.useState<AIAgent | null>(null)
  const [agentToDelete, setAgentToDelete] = React.useState<AIAgent | null>(null)
  const [selectedCompany, setSelectedCompany] = React.useState<PartialCompany | null>(null)
  const [isLoading, setIsLoading] = React.useState<Record<string, boolean>>({})
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(null)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [targetFilter, setTargetFilter] = React.useState<'companies' | 'people' | undefined>(undefined)
  const [selectedPerson, setSelectedPerson] = useState<PartialVisitor | null>(null)
  const [myAgents, setMyAgents] = useState(props.my_agents)
  const project = useCurrentProject()
  const canRequestAccess = useMemo(() => project?.koala_subscription?.plan !== 'free', [project])

  const { mutateAsync: requestFeature, isPending: requestingFeature } = useFeatureRequest()
  const [requested, setRequested] = React.useState(props.access_requested || false)

  const myAgentsSlugs = useMemo(() => myAgents.map((agent) => agent.slug), [myAgents])

  const handleCardClick = useCallback(
    (agent: Props['template_agents'][0]) => {
      setSelectedAgent(agent)
      onOpen()
    },
    [onOpen]
  )

  const onAdd = useCallback(
    (agent: Props['template_agents'][0]) => {
      // Prevent adding if already loading or already added
      if (isLoading[agent.slug] || myAgentsSlugs.includes(agent.slug)) {
        return
      }

      setIsLoading((prev) => ({
        ...prev,
        [agent.slug]: true
      }))

      post<{ agents: Props['template_agents'] }>(projectPath('/settings/agents/add.json'), {
        ai_agent: {
          slug: agent.slug,
          name: agent.name,
          question: agent.question,
          target: agent.target
        }
      })
        .then((res) => {
          toast.success('AI Agent was successfully added.')
          // Find the newly added agent in the response
          const newAgent = res.agents.find((a) => a.slug === agent.slug)
          if (newAgent) {
            setMyAgents((prev) => [...prev, newAgent])
          }
          onClose()
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setIsLoading((prev) => ({
            ...prev,
            [agent.slug]: false
          }))
        })
    },
    [onClose, isLoading, myAgentsSlugs]
  )

  const handleDelete = useCallback(
    (agent: Props['template_agents'][0]) => {
      setIsLoading((prev) => ({
        ...prev,
        [agent.slug]: true
      }))

      return del<{ agents: Props['template_agents'] }>(projectPath(`/settings/agents/${agent.slug}`))
        .then((res) => {
          toast.success('AI Agent was successfully removed.')
          setMyAgents(res.agents)
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setIsLoading((prev) => ({
            ...prev,
            [agent.slug]: false
          }))
        })
    },
    [setMyAgents]
  )

  const groupAgentsByCategory = (agents: Props['template_agents']) => {
    return agents.reduce(
      (acc, agent) => {
        const category = agent.category || 'My Agents'
        if (!acc[category]) {
          acc[category] = []
        }
        acc[category].push(agent)
        return acc
      },
      {} as Record<string, Props['template_agents']>
    )
  }

  const filteredByTarget = useCallback(() => {
    const allAgents = [...myAgents, ...props.template_agents]
    const agentsByTarget = targetFilter ? allAgents.filter((agent) => agent.target == targetFilter) : allAgents

    return agentsByTarget as Props['template_agents']
  }, [myAgents, props.template_agents, targetFilter])

  const filteredAgentsByCategoryAndTarget = useMemo(() => {
    const agentsByTarget = filteredByTarget()

    if (selectedCategory === 'My Agents') {
      return agentsByTarget.filter((agent) => !agent.category)
    }

    if (!selectedCategory || selectedCategory === 'All') {
      return agentsByTarget
    }

    return agentsByTarget.filter((agent) => agent.category === selectedCategory)
  }, [filteredByTarget, selectedCategory])

  const fuse = useCallback((agents) => {
    return new Fuse(agents, {
      keys: ['name', 'question'],
      minMatchCharLength: 3,
      ignoreLocation: true,
      threshold: 0.3
    })
  }, [])

  const filteredAgents = useMemo(() => {
    if (searchQuery.length < 3) {
      return filteredAgentsByCategoryAndTarget
    }

    return fuse(filteredAgentsByCategoryAndTarget)
      .search(searchQuery)
      .map((result) => result.item) as AIAgent[]
  }, [searchQuery, fuse, filteredAgentsByCategoryAndTarget])

  const groupedAgents = useMemo(
    () => groupAgentsByCategory(props.my_agents.concat(props.template_agents)),
    [props.my_agents, props.template_agents]
  )
  const categories = useMemo(() => ['All', ...Object.keys(groupedAgents)], [groupedAgents])

  const getCategoryCount = useCallback(
    (category: string) => {
      let agentsByTarget: any = filteredByTarget()

      if (searchQuery.length >= 3) {
        agentsByTarget = fuse(agentsByTarget)
          .search(searchQuery)
          .map((result) => result.item)
      }

      if (category === 'All') {
        return agentsByTarget.length
      }
      if (category === 'My Agents') {
        return agentsByTarget.filter((agent) => !agent.category).length
      }

      return agentsByTarget.filter((agent) => agent.category === category).length
    },
    [filteredByTarget, fuse, searchQuery]
  )

  if (!props.agents_entitlements?.enabled) {
    return (
      <PageLayout size="md" flush flex="1 1 auto" minHeight="300px">
        <TopBarContent>
          <Flex width="100%" alignSelf="center" gap={3} justifyContent="space-between">
            <SettingsBreadCrumb paths={[{ title: 'AI Agents', path: projectPath('/settings/agents') }]} />
          </Flex>
        </TopBarContent>

        <PageTitle skipRendering>AI Agents</PageTitle>

        <Flex flex="1 1 auto" maxHeight="100%" alignItems="flex-start" bg="gray.50">
          <Stack width="100%" spacing={8}>
            <Box maxWidth="600px" margin="auto">
              <EmptyState
                size="lg"
                icon={<CircleIcon icon={IconFlareFilled} iconSize={6} padding={2} colorScheme="purple" />}
                heading="Streamline your account research with AI Agents"
                description="Let AI agents do the heavy lifting of account research while your team focuses on building relationships and closing deals."
                // TODO make this say "Upgrade to Growth"
                ctaText={requested ? 'Access Requested' : 'Request Access'}
                onClick={async () => {
                  if (requestingFeature || requested) return
                  try {
                    await requestFeature({ feature: 'ai_agents' })
                    setRequested(true)
                  } catch (e) {
                    // do nothing
                  }
                }}
                isLoading={requestingFeature}
                isDisabled={requested || !canRequestAccess}
                ctaTooltip={canRequestAccess ? undefined : 'Not available on the free plan'}
              />
            </Box>
            <PreviewGrid agents={props.template_agents} />
          </Stack>
        </Flex>
      </PageLayout>
    )
  }

  return (
    <PageLayout size="md">
      <SettingsBreadCrumb paths={[{ title: 'AI Agents', path: projectPath('/settings/agents') }]} />

      <SettingsHeader>
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <PageTitle>AI Agents</PageTitle>
          <Button
            as={Link}
            href={projectPath('/settings/agents/new')}
            iconSpacing={1}
            leftIcon={<IconPlus size={16} />}
            marginLeft="auto"
            alignSelf="center"
            size="sm"
            flex="none"
            colorScheme="purple"
            onClick={(e) => {
              if (e.ctrlKey || e.metaKey || e.shiftKey) {
                return true
              }

              e.preventDefault()
              setAgentToEdit({
                name: '',
                question: '',
                slug: '',
                target: 'companies'
              })
              return false
            }}
          >
            New agent
          </Button>

          <IconButton
            aria-label="Configuration"
            icon={<IconAdjustmentsHorizontal size={16} />}
            as={Link}
            href={projectPath('/settings/agents/configuration')}
            variant="outline"
            size="sm"
          />
        </Flex>
        <PageDescription>
          Configure AI agents for your workspace to automatically perform account research and find key details to help
          your team.
        </PageDescription>
      </SettingsHeader>

      <Flex gap={4}>
        <SearchBar size="sm" placeholder="Search for agents…" value={searchQuery} onChange={setSearchQuery} />

        <SegmentedControl size="sm" display={'flex'}>
          <Button
            isActive={targetFilter === undefined}
            onClick={() => {
              setTargetFilter(undefined)
            }}
          >
            All
          </Button>

          <Button
            isActive={targetFilter === 'companies'}
            onClick={() => {
              setTargetFilter('companies')
            }}
          >
            Companies
          </Button>
          <Button
            isActive={targetFilter === 'people'}
            onClick={() => {
              setTargetFilter('people')
            }}
          >
            People
          </Button>
        </SegmentedControl>
      </Flex>

      <Flex gap={8}>
        <VStack
          align="stretch"
          spacing={0}
          minW="200px"
          position="sticky"
          top="24px"
          alignSelf="flex-start"
          display={{ base: 'none', md: 'flex' }}
        >
          {categories.map((category) => (
            <Button
              key={category}
              variant="ghost"
              justifyContent="flex-start"
              py={1.5}
              px={3}
              fontSize="sm"
              fontWeight="medium"
              color="gray.700"
              backgroundColor={selectedCategory === category ? 'gray.100' : 'transparent'}
              _hover={{ bg: 'gray.50' }}
              onClick={() => setSelectedCategory(category === 'All' ? null : category)}
              w="100%"
              minW="230px"
            >
              <HStack justify="space-between" width="100%" gap="4">
                <Text fontWeight={selectedCategory === category ? 'semibold' : 'normal'}>{titleize(category)}</Text>
                <Tag
                  size="sm"
                  fontSize="xs"
                  colorScheme={category === 'My Agents' ? 'purple' : 'gray'}
                  fontWeight={selectedCategory === category ? 'semibold' : 'normal'}
                >
                  {getCategoryCount(category)}
                </Tag>
              </HStack>
            </Button>
          ))}
        </VStack>

        <Stack spacing={8} flex={1}>
          <Grid
            gridAutoRows="1fr"
            gridTemplateColumns="repeat(auto-fill, minmax(250px, 300px))"
            alignItems="stretch"
            gap={3}
            justifyContent="start"
          >
            {filteredAgents.length === 0 ? (
              <Text>No agents found</Text>
            ) : (
              filteredAgents.map((agent) => (
                <AIAgentCard
                  agent={agent}
                  onAdd={onAdd}
                  setAgentToEdit={setAgentToEdit}
                  setAgentToDelete={setAgentToDelete}
                  isLoading={isLoading}
                  myAgentsSlugs={myAgentsSlugs}
                  handleCardClick={handleCardClick}
                  key={[agent.slug, agent.target, agent.id].join('-')}
                />
              ))
            )}
          </Grid>
        </Stack>
      </Flex>

      <AgentModal
        isOpen={isOpen}
        onClose={onClose}
        onAdd={() => {
          if (!selectedAgent) return
          onAdd(selectedAgent)
          router.visit(window.location.pathname, { fetch: true })
        }}
        selectedAgent={selectedAgent}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
        selectedPerson={selectedPerson}
        setSelectedPerson={setSelectedPerson}
      />

      {agentToEdit && (
        <EditAgentModal
          isOpen
          onClose={() => setAgentToEdit(null)}
          onChange={(agent) => {
            const isNetNew = !myAgents.some((a) => a.id === agent.id)

            if (isNetNew) {
              setMyAgents((prev) => [agent, ...prev])
            } else {
              setMyAgents((prev) => prev.map((a) => (a.id === agent.id ? agent : a)))
            }
          }}
          ai_agent={agentToEdit}
        />
      )}

      <DeleteConfirmation
        isOpen={!!agentToDelete}
        onClose={() => setAgentToDelete(null)}
        onConfirm={(event) => {
          event.preventDefault()
          if (agentToDelete) {
            return handleDelete(agentToDelete)
          }
        }}
        title="Delete AI Agent"
        confirmLabel="Delete Agent"
      >
        Are you sure you want to delete this AI agent? This action cannot be undone.
      </DeleteConfirmation>
    </PageLayout>
  )
}

interface AgentModalProps {
  isOpen: boolean
  onClose: () => void
  selectedAgent: AIAgent | null
  selectedCompany: PartialCompany | null
  setSelectedCompany: (company: PartialCompany | null) => void
  selectedPerson: PartialVisitor | null
  setSelectedPerson: (company: PartialVisitor | null) => void
  onAdd?: () => void
}

export function AgentModal({
  isOpen,
  onClose,
  selectedAgent,
  selectedCompany,
  setSelectedCompany,
  selectedPerson,
  setSelectedPerson,
  onAdd
}: AgentModalProps) {
  const [loading, setLoading] = React.useState(false)
  const [response, setResponse] = React.useState<any>(null)
  const [progressEvents, setProgressEvents] = useState<any[]>([])
  const runId = React.useRef<string>(crypto.randomUUID())

  usePlayItemAIRecommendationsStream(runId.current, {
    onContent: (message) => {
      if (message?.content_type === 'response') {
        setProgressEvents([])
        setLoading(false)
      } else {
        setProgressEvents((prev) => [...prev, message])
      }
    },
    onTool: (message) => {
      setProgressEvents((prev) => [...prev, message])
    }
  })

  useEffect(() => {
    setResponse(null)
    setLoading(false)
  }, [selectedAgent, selectedCompany])

  const runAgent = useCallback(
    async (skipCache = false) => {
      if (!selectedCompany?.domain && !selectedPerson?.id) return

      setLoading(true)
      setResponse(null)
      setProgressEvents([])

      let params = `skip_cache=${skipCache}&run_id=${runId.current}`
      if (selectedAgent?.target === 'people') {
        params = `${params}&profile_id=${selectedPerson?.id}`
      } else {
        params = `${params}&domain=${selectedCompany?.domain}`
      }

      const path = selectedAgent?.id
        ? projectPath(`/assistants/${selectedAgent?.slug}?${params}`)
        : projectPath(`/assistants/run-template/${selectedAgent?.slug}?${params}`)

      await get(path).then((res) => {
        setResponse(res)
      })
    },
    [selectedAgent, selectedCompany, selectedPerson]
  )

  return (
    <Modal size="2xl" isOpen={isOpen && selectedAgent !== null} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={6}>
          <Stack>
            <Stack spacing="4">
              <Stack>
                <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
                  Question
                </Text>
                <Text fontSize="sm" color="gray.700" fontWeight="normal">
                  {selectedAgent?.question}
                </Text>
              </Stack>

              {selectedAgent?.target === 'companies' && (
                <Stack>
                  <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
                    Select a company
                  </Text>
                  <CompanySelector
                    selectedCompany={selectedCompany}
                    onChange={(company) => {
                      setSelectedCompany(company)
                      setSelectedPerson(null)
                      setResponse(null)
                      setLoading(false)
                    }}
                  />
                </Stack>
              )}

              {selectedAgent?.target === 'people' && (
                <Stack>
                  <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
                    Select a person
                  </Text>
                  <VisitorSelector
                    selectedVisitor={selectedPerson}
                    onChange={(visitor) => {
                      setSelectedPerson(visitor)
                      setSelectedCompany(null)
                      setResponse(null)
                      setLoading(false)
                    }}
                  />
                </Stack>
              )}
            </Stack>

            {loading && progressEvents.length > 0 && (
              <Box position="relative" mt={2} mb={2} width="100%" overflow="hidden">
                <ResearchProgress toolEvents={progressEvents} />
              </Box>
            )}

            <AIAgentResponse response={response} isLoading={false} agent={selectedAgent} />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            {onAdd && (
              <Button size="sm" variant="outline" leftIcon={<IconPlus size={16} />} onClick={onAdd}>
                Enable Agent
              </Button>
            )}

            <ButtonGroup size="sm" isAttached>
              <Button
                size="sm"
                colorScheme="purple"
                onClick={() => runAgent(false)}
                isLoading={loading}
                isDisabled={!selectedCompany && !selectedPerson}
                leftIcon={<IconPlayerPlay size={16} />}
              >
                Test Agent
              </Button>
              <Menu>
                <MenuButton
                  as={IconButton}
                  minW={6}
                  icon={<IconChevronDown size={14} />}
                  isDisabled={(!selectedCompany && !selectedPerson) || loading}
                  flex="none"
                  colorScheme="purple"
                />
                <Portal>
                  <MenuList zIndex="popover">
                    <Tooltip label="We'll ignored previously cached answers for this agent">
                      <MenuItem
                        icon={<IconPlayerPlay size={16} />}
                        iconSpacing={1.5}
                        onClick={() => runAgent(true)}
                        isDisabled={!selectedCompany}
                        as={Button}
                      >
                        Test Agent from scratch
                      </MenuItem>
                    </Tooltip>
                  </MenuList>
                </Portal>
              </Menu>
            </ButtonGroup>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const PreviewGrid = ({ agents }: { agents: Props['template_agents'] }) => {
  return (
    <Box position="relative" maxHeight="500px" overflowY="hidden" padding={10}>
      <Box transform="perspective(800px) rotateX(24deg)" transformOrigin="top">
        <Grid
          gridAutoRows="1fr"
          gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))"
          alignItems="stretch"
          gap={3}
          justifyContent="start"
        >
          {agents.map((agent) => (
            <Card
              key={[agent.slug, agent.target].join('-')}
              position="relative"
              textAlign="center"
              p={5}
              gap={1}
              style={{ aspectRatio: '16 / 9' }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="300px"
              w="100%"
              opacity={0.85}
            >
              <Flex flex="1" flexDirection="column" justifyContent="center" alignItems="stretch" gap={2}>
                <Text fontSize="sm" fontWeight="medium">
                  {agent.question}
                </Text>
              </Flex>
            </Card>
          ))}
        </Grid>
      </Box>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        height="100%"
        bgGradient="linear(to-t, gray.50 0%, transparent 80%)"
        pointerEvents="none"
      />
    </Box>
  )
}
