import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { concurrentCachedGET, concurrentGET } from '../../lib/api'
import { FacetMapping } from '../pages/accounts'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface FacetCloudResponse {
  mappings: FacetMapping[]
  top_filters: string[]
}

// get the field mappings for the current workspace, for a given index
export function useFieldMappings(
  facetCloudPath = '/accounts/facet-cloud',
  opts?: { enabled?: boolean; cached?: boolean }
) {
  const res = useFacetCloud(facetCloudPath, opts)

  if (res.data) {
    return { ...res, data: res.data?.mappings || [] }
  }

  return res
}

export function useFacetCloud(
  facetCloudPath = '/accounts/facet-cloud',
  opts?: { enabled?: boolean; cached?: boolean }
) {
  const project = useCurrentProject()
  const path = projectPath(facetCloudPath)
  const getter = opts?.cached ? concurrentCachedGET : concurrentGET

  return useQuery<FacetCloudResponse>({
    queryKey: ['facet-cloud', { projectId: project?.id, path }],
    queryFn: () => getter<FacetCloudResponse>(path),
    enabled: Boolean(project?.id) && opts?.enabled !== false,
    staleTime: 1 * 60 * 1000, // Consider data fresh for 1 minute
    gcTime: 2 * 60 * 1000, // Keep unused data in cache for 2 minutes
    placeholderData: keepPreviousData
  })
}
