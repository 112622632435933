import { Stack, Box } from '@chakra-ui/react'
import { IconFlareFilled } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import EmptyState from '@app/components/ui/EmptyState'
import { useFeatureRequest, useFeatureAccess } from '@app/components/ui/FeedbackForm'
import CircleIcon from '@app/components/ui/CircleIcon'
import { useCurrentProject } from '../../../ui/ProjectsContext'

export function AIAgentCTA() {
  const [requested, setRequested] = useState(true)

  const project = useCurrentProject()
  const canRequestAccess = useMemo(() => project?.koala_subscription?.plan !== 'free', [project])

  const { mutateAsync: requestFeature, isPending: requestingFeature } = useFeatureRequest()
  const { mutateAsync: featureAccess, isPending: checkingFeatureAccess } = useFeatureAccess()

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = await featureAccess({ feature: 'ai_agents' })
        setRequested((response as { access_requested: boolean }).access_requested)
      } catch (error) {
        console.error('Error checking access:', error)
      }
    }
    checkAccess()
  }, [featureAccess])

  return (
    <Stack
      spacing={4}
      p={8}
      align="center"
      position="relative"
      bgGradient="linear(109.6deg, rgba(245, 239, 249, 0.6) 30.1%, rgba(207, 211, 236, 0.4) 100.2%)"
    >
      <Box>
        <EmptyState
          size="lg"
          icon={<CircleIcon icon={IconFlareFilled} iconSize={6} padding={2} colorScheme="purple" />}
          heading="Streamline your account research with AI Agents"
          description="Let AI agents do the heavy lifting of account research while your team focuses on building relationships and closing deals."
          // TODO make this say "Upgrade to Growth"
          ctaText={requested ? 'Access Requested' : 'Request Access'}
          onClick={async () => {
            if (requestingFeature || requested || checkingFeatureAccess) return
            try {
              await requestFeature({ feature: 'ai_agents' })
              setRequested(true)
            } catch (e) {
              // do nothing
            }
          }}
          isLoading={requestingFeature || checkingFeatureAccess}
          isDisabled={requested || !canRequestAccess}
          ctaTooltip={canRequestAccess ? undefined : 'Not available on the free plan'}
        />
      </Box>
    </Stack>
  )
}
