import React, { useState, useEffect, ReactElement } from 'react'
import { Liquid } from 'liquidjs'
import DOMPurify from 'dompurify'

const engine = new Liquid()

interface LiquidRendererProps {
  template?: string
  data?: Record<string, any>
  html?: boolean
}

interface LiquidResult {
  status: 'idle' | 'loading' | 'error'
  markup: string
}

export const LiquidRenderer = ({ template = '', data = {}, html = false }: LiquidRendererProps): ReactElement => {
  const [content, setContent] = useState<string>('')

  useEffect(() => {
    const renderTemplate = async () => {
      if (!template) {
        setContent('')
        return
      }

      try {
        const rendered = await engine.parseAndRender(template, data)
        setContent(rendered)
      } catch (error) {
        console.error('Error rendering liquid template:', error)
        setContent('Error rendering template')
      }
    }

    renderTemplate()
  }, [template, data])

  if (html) {
    return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
  }

  return <>{content}</>
}

export const useLiquidRenderer = (template?: string, data: Record<string, any> = {}): LiquidResult => {
  const [result, setResult] = useState<LiquidResult>({ status: 'loading', markup: '' })

  useEffect(() => {
    const renderTemplate = async () => {
      if (!template) {
        setResult({ status: 'idle', markup: '' })
        return
      }

      try {
        const rendered = await engine.parseAndRender(template, data)
        setResult({ status: 'idle', markup: rendered })
      } catch (error) {
        console.error('Error rendering liquid template:', error)
        setResult({ status: 'error', markup: 'Error rendering template' })
      }
    }

    renderTemplate()
  }, [template, data])

  return result
}
