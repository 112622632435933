import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  Stack,
  Text,
  Spinner
} from '@chakra-ui/react'
import { IconBrandGithub, IconPlus, IconSearch, IconX } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { toast } from 'sonner'
import { useDebounce } from 'use-debounce'
import { PlayTargetConfig } from '../../../../types/Play'
import { useAppDep } from '../../../data/use-app-dep'
import { useApp } from '../../../data/use-app'
import Avatar from '../../../ui/Avatar'
import { GrayCard } from '../../../ui/Card'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { PopupConnectDialog } from '../../apps/components/ConnectOauthAppDialog'
import { ReconnectionRequiredWarning } from '../../apps/components/ReconnectionRequiredWarning'

interface SearchResult {
  id: string
  name: string
  nameWithOwner: string
  description: string
  url: string
  stargazerCount: number
  forkCount: number
  owner: {
    __typename: 'Organization'
    login: string
    name: string
    websiteUrl: string
    email: string | null
    avatarUrl: string | null
  }
  cursor: null
}

const isValidGitHubUrl = (url: string): boolean => {
  if (!url) return true
  const regex = /[a-zA-Z0-9-]+\/[a-zA-Z0-9-_.]+\/?$/
  return regex.test(url)
}

export function GitHubColdSource({
  targetConfig,
  targetType: _targetType,
  onTargetTypeChange
}: {
  targetConfig: PlayTargetConfig | undefined
  targetType: 'Profile' | 'Account'
  onTargetTypeChange: (targetType: 'Profile' | 'Account') => void
}) {
  const [repos, setRepos] = React.useState<string[]>(targetConfig?.config?.repos ?? [])
  const [searchQuery, setSearchQuery] = useState<string | undefined>()
  const [query] = useDebounce(searchQuery, 300)

  const github = useApp('Github')
  const disconnected = !github.data?.connected
  const invalid = !github.data?.valid

  const { data: searchResultsData, isLoading: searchResultsLoading } = useAppDep<'search_repositories', SearchResult[]>(
    'github',
    'search_repositories',
    { query },
    true,
    Boolean(query) && !disconnected
  )

  const onConnected = useCallback(() => {
    github.refetch()
    onTargetTypeChange('Profile')
  }, [github, onTargetTypeChange])

  const removeRepo = (index: number) => {
    const newRepos = repos.filter((_, i) => i !== index)
    setRepos(newRepos)
    onTargetTypeChange('Profile')
  }

  const updateRepo = (index: number, value: string) => {
    const newRepos = [...repos]
    newRepos[index] = value
    setRepos(newRepos)
    onTargetTypeChange('Profile')
  }

  if (github.isLoading) {
    return <Spinner />
  }

  if (disconnected) {
    return (
      <GrayCard>
        <Center w="100%">
          <Flex py="8">
            <PopupConnectDialog app_id={'Github'} onConnected={onConnected} active={true}>
              {({ onStart }) => (
                <Stack spacing="4">
                  <Stack spacing="0">
                    <Center>
                      <Heading size="sm">Connect your GitHub Account</Heading>
                    </Center>
                    <Center>
                      <Text fontSize="sm" color="gray.600">
                        Please connect your GitHub account to search and add repositories.
                      </Text>
                    </Center>
                  </Stack>
                  <Center>
                    <Button
                      leftIcon={<Icon as={IconBrandGithub} />}
                      size="sm"
                      variant={'outline'}
                      onClick={onStart}
                      colorScheme={'purple'}
                    >
                      Connect GitHub
                    </Button>
                  </Center>
                </Stack>
              )}
            </PopupConnectDialog>
          </Flex>
        </Center>
      </GrayCard>
    )
  }

  if (!github.data) {
    return null
  }

  return (
    <GrayCard borderWidth={'thin'}>
      <FormControl>
        <FormLabel>GitHub Repositories</FormLabel>
        <Stack spacing="4">
          {invalid && <ReconnectionRequiredWarning appTitle={'GitHub'} variant="short" />}

          <Stack>
            <ComboboxWithSearch
              items={searchResultsData?.data?.search_repositories ?? []}
              selectedItem={null}
              isLoading={searchResultsLoading}
              selectButtonRenderer={() => (
                <HStack>
                  <IconSearch size={16} />
                  <IconBrandGithub size={16} />
                  <Text fontSize="sm">Search for a public repository...</Text>
                </HStack>
              )}
              onInputValueChange={(val) => {
                setSearchQuery(val)
              }}
              onChange={(repo) => {
                if (repo) {
                  setRepos([...repos, repo.nameWithOwner])
                }
              }}
              filterItem={(a, val) => a?.nameWithOwner.toLowerCase().includes(val) ?? false}
              inputProps={{
                onPasteCapture: (e) => {
                  const text = e.clipboardData.getData('text')
                  const match = text.match(/https:\/\/github\.com\/([^/]+)\/([^/]+)/)
                  if (match) {
                    e.preventDefault()
                    e.stopPropagation()
                    ;(e.target as HTMLInputElement).value = match[1] + '/' + match[2]
                    setSearchQuery(match[1] + '/' + match[2])
                  }
                }
              }}
              itemRenderer={({ item }) => (
                <Box
                  w="100%"
                  onClick={(e) => {
                    if (e.target instanceof HTMLAnchorElement) return

                    e.stopPropagation()
                    e.preventDefault()

                    toast.info(`Added ${item.nameWithOwner} to repository list`)

                    if (repos.includes(item.nameWithOwner)) return
                    setRepos([...repos, item.nameWithOwner])
                  }}
                >
                  <HStack w="100%">
                    <Avatar size="xs" src={item?.owner?.avatarUrl} />
                    <Link isExternal href={item?.url} fontSize="sm">
                      {item?.nameWithOwner}
                    </Link>
                    <Flex flex="1" justifyContent="flex-end">
                      <IconButton
                        size="xs"
                        variant={'ghost'}
                        aria-label="Add repository"
                        icon={<IconPlus size={16} />}
                      />
                    </Flex>
                  </HStack>
                </Box>
              )}
            />
          </Stack>

          {repos.length > 0 && (
            <Stack spacing={2}>
              {repos.map((repo, index) => (
                <Stack key={index}>
                  <HStack direction="row" alignItems="center">
                    <Icon as={IconBrandGithub} boxSize={4} />
                    <Input
                      value={repo}
                      bg="white"
                      onChange={(e) => updateRepo(index, e.target.value)}
                      size="sm"
                      placeholder="Repository name (owner/repo)"
                      isRequired
                      isInvalid={!isValidGitHubUrl(repo)}
                    />
                    <IconButton
                      variant="ghost"
                      icon={<Icon as={IconX} />}
                      size="sm"
                      aria-label="Remove repository"
                      onClick={() => removeRepo(index)}
                    />
                  </HStack>
                </Stack>
              ))}
            </Stack>
          )}

          {repos.some((repo) => !isValidGitHubUrl(repo)) && (
            <FormErrorMessage>Please enter a valid GitHub repository URL.</FormErrorMessage>
          )}

          <FormHelperText>
            Add GitHub repositories to enroll contributors, stargazers, and issue/PR participants as prospects.
          </FormHelperText>

          <FormHelperText>
            Note: Koala will only enroll profiles who have interacted with your repo in the past 6 months, and have an
            email address or company associated to their GitHub profile.
          </FormHelperText>
        </Stack>

        {repos.length > 0 && (
          <>
            <input type="hidden" name="play[target_config][config]" value={JSON.stringify({ repos })} />
            <input type="hidden" name="play[target_type]" value="Profile" />
          </>
        )}
      </FormControl>
    </GrayCard>
  )
}
