export interface ParsedMailAddress {
  name?: string
  email: string
}

// Match either "Name <email@example.com>" or "email@example.com"
export function parseEmailAddress(address: string): ParsedMailAddress {
  const match = address.match(/^(?:([^<]*?)\s*<(.+?)>|(.+))$/)

  if (!match) {
    return { email: address }
  }

  const [, name, emailInBrackets, emailOnly] = match
  const email = emailInBrackets || emailOnly

  return {
    name: name?.trim(),
    email: email.trim()
  }
}
