import React, { useMemo, useState } from 'react'
import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureProps,
  useDisclosure
} from '@chakra-ui/react'
import { NamedFilter } from '../../../ui/filters/types'
import NamedFilterForm from './form'

interface NamedFilterFormModalProps extends UseDisclosureProps {
  isOpen: boolean
  onClose: () => void
  onSave: (namedFilter: NamedFilter) => void
  targetType?: 'Account' | 'Profile'
  namedFilter?: NamedFilter | null
  modalTitle?: string
  canChangeTargetType?: boolean
}

export function NamedFilterFormModal({
  modalTitle,
  targetType,
  namedFilter,
  canChangeTargetType = true,
  ...props
}: NamedFilterFormModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onClose } = useDisclosure(props)

  const title = useMemo(() => {
    if (modalTitle) return modalTitle

    return namedFilter?.id ? 'Edit named filter' : 'Create named filter'
  }, [modalTitle, namedFilter?.id])

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      isCentered
    >
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent minWidth="600px">
        <ModalCloseButton isDisabled={isLoading} />
        <ModalHeader fontSize="lg">
          <Text fontWeight="medium" pb={1}>
            {title}
          </Text>
          <Divider />
        </ModalHeader>
        <ModalBody>
          <NamedFilterForm
            key={namedFilter?.id || 'new'}
            targetType={targetType}
            namedFilter={namedFilter}
            onSave={props.onSave}
            onCancel={onClose}
            canChangeTargetType={canChangeTargetType}
            setIsLoading={setIsLoading}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
