import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { OnboardingTask } from '../pages/onboarding/components/SetupBadge'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  checks: OnboardingTask[]
  remaining: OnboardingTask[]
  done: OnboardingTask[]
  complete: boolean
}

// These are project-level onboarding checks
export function useOnboardingChecks() {
  const project = useCurrentProject()

  return useQuery<Data>({
    queryKey: ['onboarding-checks', { projectId: project?.id }],
    queryFn: () => concurrentGET<Data>(projectPath('/setup')),
    enabled: Boolean(project?.id)
  })
}
