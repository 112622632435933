import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Textarea,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import React, { useCallback, useRef } from 'react'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'
import { post } from '../../lib/api'
import { projectPath, useCurrentProject } from './ProjectsContext'

export function openWishForm(detail: Omit<FeedbackFormProps, 'projectSlug'> = {}) {
  window.dispatchEvent(new CustomEvent('openfeedback', { detail }))
}

interface FeedbackPayload {
  message: string
  title: string
  preamble?: string
}

export function useFeedback() {
  const project = useCurrentProject()

  return useMutation<unknown, Error, FeedbackPayload>({
    mutationFn: (payload) => {
      const path = `${project?.slug ? `/projects/${project.slug}` : ''}/feedback`
      return post(path, payload)
    },

    onSuccess: () => {
      toast('🎉  Thanks, we got your message!')
    },

    onError: () => {
      toast.error('There was an issue receiving your message.')
    }
  })
}

interface FeatureRequestPayload {
  // required feature to request access to
  feature: string
  // optional message for users to share more context
  message?: string
}

export function useFeatureRequest() {
  const project = useCurrentProject()

  return useMutation<unknown, Error, FeatureRequestPayload>({
    mutationFn: (payload) => {
      if (!project) {
        return Promise.reject(new Error('Project is required'))
      }

      const path = projectPath(`/request-access`)
      return post(path, payload)
    },

    onSuccess: () => {
      toast('🎉  Thanks, we got your message!')
    },

    onError: () => {
      toast.error('There was an issue receiving your message.')
    }
  })
}

export function useFeatureAccess() {
  const project = useCurrentProject()

  return useMutation<unknown, Error, FeatureRequestPayload>({
    mutationFn: (payload) => {
      if (!project) {
        return Promise.reject(new Error('Project is required'))
      }

      const path = projectPath(`/check-access`)
      return post(path, payload)
    }
  })
}

interface FeedbackFormProps {
  projectSlug?: string
  title?: string
  placeholder?: string
  placement?: PopoverProps['placement']
  variant?: 'modal' | 'popover'
  preamble?: string
}

export function FeedbackForm(props: React.PropsWithChildren<FeedbackFormProps>) {
  const [message, setMessage] = React.useState('')
  const [title, setTitle] = React.useState(props.title || '')
  const [placeholder, setPlaceholder] = React.useState(props.placeholder || '')
  const [placement, setPlacement] = React.useState(props.placement || 'bottom-end')
  const [variant, setVariant] = React.useState(props.variant || 'popover')
  const [preamble, setPreamble] = React.useState(props.preamble || '')

  const { mutateAsync: submitFeedback, isPending: submitting } = useFeedback()

  React.useEffect(() => {
    if (props.title) setTitle(props.title)
    if (props.placeholder) setPlaceholder(props.placeholder)
    if (props.placement) setPlacement(props.placement)
    if (props.variant) setVariant(props.variant)
    if (props.preamble) setPreamble(props.preamble)
  }, [props.title, props.placeholder, props.variant, props.placement, props.preamble])

  // only listen if it's not a specific instance
  const shouldListen = !(props.variant || props.preamble || props.title)

  const { isOpen, onOpen, onClose } = useDisclosure({
    // reset to props on close
    onClose: () => {
      setTitle(props.title || '')
      setPlaceholder(props.placeholder || '')
      setPlacement(props.placement || 'bottom-end')
      setVariant(props.variant || 'popover')
      setPreamble(props.preamble || '')
    }
  })
  const initialFocusRef = useRef<HTMLTextAreaElement>(null)

  React.useEffect(() => {
    if (!shouldListen) return

    const onOpenFeedback = (event) => {
      const newProps = (event as CustomEvent<Omit<FeedbackFormProps, 'projectSlug'>>).detail ?? {}

      if (newProps.title) setTitle(newProps.title)
      if (newProps.placeholder) setPlaceholder(newProps.placeholder)
      if (newProps.placement) setPlacement(newProps.placement)
      if (newProps.variant) setVariant(newProps.variant)
      if (newProps.preamble) setPreamble(newProps.preamble)

      onOpen()
    }

    window.addEventListener('openfeedback', onOpenFeedback)

    return () => {
      window.removeEventListener('openfeedback', onOpenFeedback)
    }
  }, [shouldListen, onOpen])

  const formId = 'feedback-form'
  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      try {
        await submitFeedback({
          message: message,
          title: document.title,
          preamble: preamble || ''
        })
        setMessage('')
        onClose()
      } catch (_e) {
        toast.error('There was an issue receiving your message.')
      }
    },
    [onClose, submitFeedback, message, preamble]
  )

  const trigger = (
    <Box flex="0 0 auto" onClick={onOpen}>
      {props.children}
    </Box>
  )

  const form = (
    <form id={formId} onSubmit={onSubmit}>
      <Textarea
        ref={initialFocusRef}
        name="message"
        width="100%"
        variant="unstyled"
        resize="none"
        fontSize="sm"
        value={message}
        rows={6}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={placeholder || 'Ask for help or send us feedback to make Koala better.'}
      />
    </form>
  )

  const footer = (
    <>
      <Button variant="outline" size="sm" onClick={onClose}>
        Cancel
      </Button>
      <Button type="submit" form={formId} colorScheme="purple" size="sm" isDisabled={!message} isLoading={submitting}>
        Send
      </Button>
    </>
  )

  // use a modal if we are on small screens/mobile
  const isSmall = useBreakpointValue({ base: true, sm: true, md: false })
  if (isSmall || variant === 'modal') {
    return (
      <>
        {trigger}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{title || 'Share feedback'}</ModalHeader>
            <ModalBody fontSize="sm">{form}</ModalBody>
            <ModalFooter justifyContent="flex-end" gap={3}>
              {footer}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <Popover
      isOpen={isOpen}
      placement={placement || 'bottom-end'}
      isLazy
      closeOnBlur={false}
      initialFocusRef={initialFocusRef}
      onClose={onClose}
      onOpen={onOpen}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <Portal>
        <PopoverContent maxWidth="350px" fontSize="sm" shadow="md">
          <PopoverArrow />
          <PopoverBody>{form}</PopoverBody>
          <PopoverFooter
            display="flex"
            gap={3}
            bg="gray.50"
            borderBottomRadius="md"
            flexDirection="row-reverse"
            justifyContent="space-between"
          >
            {footer}
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
