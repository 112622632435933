import {
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Switch
} from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import React, { useMemo } from 'react'
import { Apps } from '../../../../types/App'
import { Play, PlayConfigPreset, PlayTargetType } from '../../../../types/Play'
import { AutosizedTextarea } from '../../../ui/AutosizedTextarea'
import { StepIcon } from '../../../ui/StepIcon'
import { AIQualificationSetup } from './ai-qualification-setup'
import { AISetup } from './ai-setup'
import { EnrichmentSetup } from './enrichment-setup'
import { ExclusionFiltersSetup } from './exclusion-filters'
import LeadRoutingRules from './LeadRoutingRules'
import { PlayActions } from './PlayActions'
import { ProspectingSetup } from './prospecting-setup'
import { SourceSetup } from './source-setup'

export function PlayForm(props: { play: Partial<Play>; apps: Apps; setFormIsValid: (isValid: boolean) => void }) {
  const [sourceType, setSourceType] = React.useState<PlayConfigPreset | undefined>(props.play.target_config?.source)
  const [targetType, setTargetType] = React.useState<PlayTargetType>(props.play.target_type ?? 'Account')
  const [enableActions, setEnableActions] = React.useState(props.play.action_config?.enabled ?? false)

  const canSeeActions = false

  // Allow us to see the exclusions page when we're editing a play
  const canSeeExclusions = new URLSearchParams(window.location.search).get('exclusions') == 'true'

  const shouldOfferProspecting = useMemo(() => {
    if (sourceType === undefined) {
      return false
    }

    // This means we're already prospecting for people
    if (sourceType === 'prospects' && targetType === 'Profile') {
      return false
    }

    return true
  }, [sourceType, targetType])

  const shouldOfferEnrichment = useMemo(() => {
    return targetType === 'Profile'
  }, [targetType])

  const initialRules = useMemo(() => {
    return (
      props.play.assignment_config?.rules?.map((rule) => ({
        id: rule.id || nanoid(),
        filters: rule.filters,
        title: rule.title,
        position: +rule.position,
        strategy: rule.strategy,
        assignees: rule.assignees,
        territories: rule.territories
      })) ?? []
    )
  }, [props.play.assignment_config?.rules])

  const initialFallback = useMemo(() => {
    return {
      strategy: props.play.assignment_config?.fallback?.strategy ?? 'unassigned',
      assignees: props.play.assignment_config?.fallback?.assignees ?? [],
      territories: props.play.assignment_config?.fallback?.territories ?? []
    }
  }, [props.play.assignment_config?.fallback])

  return (
    <Stack
      spacing={8}
      divider={<Divider />}
      paddingBottom={8}
      marginBottom={8}
      borderBottom="1px solid"
      borderColor="gray.100"
      w="100%"
    >
      <Flex alignItems="flex-start" gap={2} w="100%">
        <StepIcon step={1} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">Play Details</Heading>

          <FormControl>
            <Input
              size="sm"
              name="play[name]"
              defaultValue={props.play.name}
              required
              placeholder="Play name (e.g. Pricing page visitors)"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Description/Goal (optional)</FormLabel>
            <AutosizedTextarea
              size="sm"
              name="play[description]"
              defaultValue={props.play.description}
              placeholder="Research the company, then send async ballpark offer"
            />
            <FormHelperText>
              Describe the goal of the play, and overall instructions for your reps executing the play
            </FormHelperText>
          </FormControl>
        </Stack>
      </Flex>

      <Flex alignItems="flex-start" gap={2} w="100%">
        <StepIcon step={2} />
        <Stack flex="1 1 auto" spacing={4} maxWidth={sourceType === 'csv' ? '100%' : '700px'}>
          <Heading size="sm">Select an enrollment trigger</Heading>

          <SourceSetup
            apps={props.apps}
            targetType={targetType}
            targetConfig={props.play.target_config}
            triggerConfig={props.play.trigger_config}
            onSourceTypeChange={setSourceType}
            setTargetType={setTargetType}
          />
        </Stack>
      </Flex>

      {canSeeExclusions && (
        <Flex alignItems="flex-start" gap={2} w="100%">
          <StepIcon step={3} />
          <Stack flex="1 1 auto" spacing={4} maxWidth={sourceType === 'csv' ? '100%' : '700px'}>
            <Heading size="sm">Exclusions</Heading>
            <ExclusionFiltersSetup
              apps={props.apps}
              targetType={targetType}
              exclusionConfig={props.play.exclusion_config}
              setTargetType={setTargetType}
            />
          </Stack>
        </Flex>
      )}

      <Flex alignItems="flex-start" gap={2} w="100%">
        <StepIcon step={3} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">AI and Prospecting</Heading>

          {shouldOfferEnrichment && <EnrichmentSetup play={props.play} />}
          {shouldOfferProspecting && (
            <ProspectingSetup onTargetTypeChange={setTargetType} targetType={targetType} play={props.play} />
          )}

          <AISetup play={props.play} />
        </Stack>
      </Flex>

      <Flex alignItems="flex-start" gap={2} w="100%">
        <StepIcon step={4} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">Set up qualification criteria</Heading>
          <AIQualificationSetup play={props.play} targetType={targetType} />
        </Stack>
      </Flex>

      <Flex alignItems="flex-start" gap={2}>
        <StepIcon step={5} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">Choose who to assign this play to</Heading>

          <LeadRoutingRules
            apps={props.apps}
            targetType={targetType}
            initialRules={initialRules}
            initialFallback={initialFallback}
            initialAccountAffinity={props.play.assignment_config?.maintain_account_affinity}
            setRulesAreValid={props.setFormIsValid}
          />
        </Stack>
      </Flex>

      {canSeeActions && (
        <Flex alignItems="flex-start" gap={2} w="100%">
          <StepIcon step={6} />
          <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
            <HStack>
              <Switch onChange={() => setEnableActions(!enableActions)} isChecked={enableActions} />
              <Heading size="sm">Would you like to add any actions to this play?</Heading>
            </HStack>
            {enableActions && <PlayActions targetType={targetType} play={props.play} apps={props.apps} />}
          </Stack>
        </Flex>
      )}
    </Stack>
  )
}
