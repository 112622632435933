import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { useCurrentProject, projectPath } from '../ui/ProjectsContext'
import type { ProfileRecord } from '../../types/Profile'

export function useIdentifiedVisitors(companyId: string | undefined) {
  const project = useCurrentProject()
  const path = projectPath(`/accounts/${companyId}/identified`)

  return useQuery({
    queryKey: ['identified', { companyId, projectId: project?.id }],
    queryFn: () => concurrentGET<{ identified: ProfileRecord[] }>(path),
    enabled: Boolean(companyId && project?.id)
  })
}
