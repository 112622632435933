import { useMutation, useQuery } from '@tanstack/react-query'
import { concurrentGET, post } from '../../lib/api'
import { Template } from '../../types/Template'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Params {
  templateId: string
  userId?: string
  userIds?: string[]
}

export function useApplyTemplate() {
  return useMutation({
    mutationFn: ({ templateId, userId, userIds }: Params) => {
      const payload: { user?: string; user_ids?: string[] } = {}

      if (Array.isArray(userIds)) {
        payload.user_ids = userIds
      } else {
        payload.user = userId
      }

      const path = projectPath(`/mission-control/templates/${templateId}/apply`)
      return post(path, payload)
    }
  })
}

interface TemplatesResponse {
  templates: Template[]
}

export function useTemplates(opts: { enabled?: boolean } = {}) {
  const project = useCurrentProject()
  const path = projectPath('/mission-control/templates')
  return useQuery<TemplatesResponse>({
    queryKey: ['templates', { projectId: project?.id }],
    queryFn: () => concurrentGET(path),
    enabled: Boolean(project?.id) && opts?.enabled !== false
  })
}
