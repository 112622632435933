import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconArrowRight, IconDragDrop2 } from '@tabler/icons-react'
import { arrayMoveImmutable } from 'array-move'
import React, { useEffect, useMemo, useState } from 'react'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { IntentCadenceMapping } from '../../../../../types/AutoOutbound'
import { useAppDep } from '../../../../data/use-app-dep'
import { Card } from '../../../../ui/Card'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { SignalType } from '../../../kql_definitions/components/SignalType'
import { KqlDefinition } from '../../../kql_definitions/types'
import { SalesloftActionProps } from './salesloft-setup'

type SalesloftCadence = {
  id: string
  name: string
}

export function AddToCadence(props: SalesloftActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.deliveryRules?.salesloft?.add_to_cadence?.enabled))
  const [selectedCadenceId, setSelectedCadenceId] = React.useState<SalesloftCadence['id'] | null>(
    props.deliveryRules?.salesloft?.add_to_cadence?.cadence_id ?? null
  )

  const { data: cadenceData, isLoading: loadingCadences } = useAppDep<'cadences', SalesloftCadence[]>(
    'Salesloft',
    'cadences'
  )

  const [sequenceOption, setCadenceOption] = React.useState(
    props.deliveryRules?.salesloft?.add_to_cadence?.strategy ?? 'single'
  )

  return (
    <Stack w="100%" spacing="6">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.deliveryRules,
              salesloft: {
                ...props.deliveryRules?.salesloft,
                add_to_cadence: {
                  ...props.deliveryRules?.salesloft?.add_to_cadence,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][salesloft][add_to_cadence][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>Adds the current visitor to a cadence and creates the contact if not present.</FormHelperText>
      </FormControl>

      {enabled && !loadingCadences && (
        <Stack w="100%" spacing="6">
          {props.kqlDefinitions && (
            <Stack>
              <Heading size="xs">Cadence Strategy</Heading>
              <RadioGroup
                size="sm"
                defaultValue={sequenceOption}
                onChange={(e) => setCadenceOption(e as 'single' | 'custom')}
                name="follow_rule[delivery_rules][salesloft][add_to_cadence][strategy]"
              >
                <Stack spacing="0.5">
                  <Radio bg="white" value="single">
                    Single Cadence
                  </Radio>
                  <Radio bg="white" value="custom">
                    Custom per Intent Signal
                  </Radio>
                </Stack>
              </RadioGroup>
            </Stack>
          )}

          <Divider />

          {sequenceOption === 'single' && (
            <>
              <FormControl>
                <FormLabel>Cadence</FormLabel>
                {loadingCadences && <Spinner size="sm" />}
                {selectedCadenceId && (
                  <input
                    type="hidden"
                    name="follow_rule[delivery_rules][salesloft][add_to_cadence][cadence_id]"
                    value={selectedCadenceId}
                  />
                )}
                <ComboboxWithSearch
                  items={cadenceData?.data?.cadences ?? []}
                  selectedItem={
                    cadenceData?.data?.cadences?.find((s) => String(s.id) === String(selectedCadenceId)) ?? null
                  }
                  onChange={(selectedItem) => {
                    setSelectedCadenceId(selectedItem?.id ?? null)
                  }}
                  filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                  itemToString={(item) => (item ? `[${item.id}] ${item.name}` : '')}
                  placeholder="Select a cadence"
                />
              </FormControl>
            </>
          )}

          {sequenceOption === 'custom' && (
            <IntentSignalPairs
              cadences={cadenceData?.data?.cadences ?? []}
              mappings={props.deliveryRules?.salesloft?.add_to_cadence?.mappings ?? []}
              kqlDefinitions={props.kqlDefinitions ?? []}
            />
          )}

          {props.kqlDefinitions && sequenceOption === 'single' && (
            <Text fontSize="sm" color="gray.500">
              Every prospect will be added into the same sequence
            </Text>
          )}

          {sequenceOption === 'custom' && (
            <Text fontSize="sm" color="gray.500">
              Each prospect will be added to a specific sequence depending on intent.
            </Text>
          )}
        </Stack>
      )}
    </Stack>
  )
}

interface IntentSignalPairsProps {
  kqlDefinitions: KqlDefinition[]
  mappings: IntentCadenceMapping[]
  cadences?: SalesloftCadence[]
}

function IntentSignalPairs(props: IntentSignalPairsProps) {
  const mappings = useMemo(() => {
    const maps = props.mappings ?? []

    const mapped = props.kqlDefinitions.reduce((acc, kqlDef) => {
      const map = maps.find((m) => m.kql_definition_id === kqlDef.id)

      acc.push({
        kql_definition_id: kqlDef.id!,
        cadence_id: map?.cadence_id
      })

      return acc
    }, [] as IntentCadenceMapping[])

    // sort mapped by the positions in props.mappings
    return mapped.sort((a, b) => {
      const aIndex = props.mappings.findIndex((m) => m.kql_definition_id === a.kql_definition_id)
      const bIndex = props.mappings.findIndex((m) => m.kql_definition_id === b.kql_definition_id)

      return aIndex - bIndex
    })
  }, [props.mappings, props.kqlDefinitions])

  const [localMappings, setLocalMappings] = useState<IntentCadenceMapping[]>(mappings)

  useEffect(() => {
    setLocalMappings(mappings)
  }, [mappings])

  return (
    <>
      <Stack>
        <Heading size="xs">Pair each Intent Signal to a Cadence</Heading>
        <SortableList
          onSortEnd={(oldIndex: number, newIndex: number) => {
            setLocalMappings((array) => arrayMoveImmutable(array, oldIndex, newIndex))
          }}
          draggedItemClassName="dragged"
          style={{
            userSelect: 'none'
          }}
        >
          <Stack>
            {localMappings.map((entry) => {
              const kqlDefinition = props.kqlDefinitions.find((def) => def.id === entry.kql_definition_id)
              if (!kqlDefinition?.id) {
                return null
              }

              const cadenceId = entry.cadence_id

              return (
                <SortableItem key={kqlDefinition.id}>
                  <HStack w="100%">
                    <SortableKnob>
                      <Box cursor={'grab'}>
                        <Tooltip label="Drag to change priorities" placement="top">
                          <IconDragDrop2 color="gray" size="14" />
                        </Tooltip>
                      </Box>
                    </SortableKnob>
                    <IntentCadencePair
                      cadences={props.cadences ?? []}
                      kqlDefinition={kqlDefinition}
                      cadenceId={cadenceId ?? ''}
                    />
                  </HStack>
                </SortableItem>
              )
            })}
          </Stack>
        </SortableList>
      </Stack>
    </>
  )
}

interface IntentSignalPairProps {
  kqlDefinition: KqlDefinition
  cadenceId: SalesloftCadence['id']
  cadences: SalesloftCadence[]
}

function IntentCadencePair(props: IntentSignalPairProps) {
  const [selectedCadenceId, setSelectedCadenceId] = React.useState<SalesloftCadence['id'] | null>(
    props.cadenceId ?? null
  )
  const kqlDefinition = props.kqlDefinition

  return (
    <>
      <HStack w="100%">
        <Flex bg="white" as={Card} p="2" flex="1" fontWeight={'normal'}>
          <SignalType
            label={`${kqlDefinition.name}${kqlDefinition.enabled === false ? ' (paused)' : ''}`}
            signalType={kqlDefinition.signal_type}
            compact
          />
        </Flex>
        <IconArrowRight size="14" />
        <Flex w="100%" flex="1">
          <CadenceSelector
            cadences={props.cadences}
            setSelectedCadenceId={setSelectedCadenceId}
            selectedCadenceId={selectedCadenceId}
          />
        </Flex>
      </HStack>
      {selectedCadenceId && (
        <>
          <input
            type={'hidden'}
            name={`follow_rule[delivery_rules][salesloft][add_to_cadence][mappings][][cadence_id]`}
            value={selectedCadenceId}
          />
          <input
            type={'hidden'}
            name={`follow_rule[delivery_rules][salesloft][add_to_cadence][mappings][][kql_definition_id]`}
            value={kqlDefinition.id}
          />
        </>
      )}
    </>
  )
}

interface CadenceSelectorProps {
  selectedCadenceId?: SalesloftCadence['id'] | null
  setSelectedCadenceId: (id: SalesloftCadence['id'] | null) => void
  cadences: SalesloftCadence[]
}

function CadenceSelector(props: CadenceSelectorProps) {
  const cadences = useMemo(() => {
    return props.cadences ?? []
  }, [props.cadences])

  const selectedItem = useMemo(
    () =>
      cadences.find((s) => {
        return s.id.toString() === props.selectedCadenceId?.toString()
      }) ?? null,
    [cadences, props.selectedCadenceId]
  )

  return (
    <>
      <HStack w="100%">
        <ComboboxWithSearch
          items={cadences}
          selectedItem={selectedItem}
          onChange={(selectedItem) => {
            props.setSelectedCadenceId(selectedItem?.id ?? null)
          }}
          filterItem={(a, val) => a.name.toLowerCase().includes(val)}
          itemToString={(item) => `[${item?.id}] ${item?.name || ''}`}
          placeholder="Select a cadence"
        />
      </HStack>
    </>
  )
}
