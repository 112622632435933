import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { SlackChannel } from '../../types/Slack'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  app_id: string
  connected: boolean
  valid?: boolean
  deps: {
    channels: SlackChannel[]
  }
}

export function useSlack() {
  const project = useCurrentProject()
  const basePath = '/apps/slack.json'

  const path = projectPath(basePath)

  return useQuery<Data>({
    queryKey: ['apps', { projectId: project?.id }],
    queryFn: () => concurrentGET<Data>(path),
    enabled: Boolean(project?.id)
  })
}
