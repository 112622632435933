import { Box, Button, ButtonGroup, Flex, HStack, Icon, IconButton, Link, Stack, Text, Tooltip } from '@chakra-ui/react'
import {
  IconAt,
  IconCopy,
  IconMailSearch,
  IconMailX,
  IconPhoneCheck,
  IconPhonePlus,
  IconPhoneX
} from '@tabler/icons-react'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../lib/api'
import { Apps } from '../../../types/App'
import { Prospect } from '../../data/use-prospects'
import Avatar from '../../ui/Avatar'
import { KoalaIcon, LinkedinBoxIcon, SalesforceIcon } from '../../ui/icons'
import { HubSpotIcon } from '../../ui/icons/HubspotIcons'
import { projectPath } from '../../ui/ProjectsContext'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { profilePath } from '../profiles/lib/path'
import { ActionMenu } from './action-menu'
import { Persona } from './personas'

export function ProspectsList({
  prospects,
  domain,
  isLoading,
  apps,
  actions,
  persona,
  context
}: {
  prospects: Prospect[]
  persona?: Persona
  domain: string
  isLoading?: boolean
  apps: Apps
  actions?: boolean
  context?: 'explore' | 'account_page' | 'triangulation'
}) {
  const [localProspects, setProspects] = useState<Prospect[]>(prospects)
  const [emailLoadingState, setEmailLoadingState] = useState({})
  const [phoneLoadingState, setPhoneLoadingState] = useState({})

  useEffect(() => {
    setProspects(prospects)
  }, [prospects])

  const onUnlockPhone = useCallback(
    (prospect: Prospect) => {
      setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
      post<{ prospect: Prospect }>(projectPath(`/prospects/${prospect.id}/unlock-phone`), {
        context
      })
        .then((res) => {
          setProspects((prev) => {
            return prev.map((p) => {
              if (p.id === prospect.id) {
                return {
                  ...p,
                  ...res.prospect
                }
              }
              return p
            })
          })

          if (res.prospect.phone_state === 'unlocked') {
            toast.success('Phone number unlocked')
          } else if (res.prospect.phone_state === 'not_found') {
            toast.warning('Phone number not found')
          }
        })
        .catch(() => {
          toast.error('Failed to unlock phone number')
        })
        .finally(() => {
          setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
        })
    },
    [context]
  )

  const onUnlock = useCallback(
    (prospect: Prospect) => {
      setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
      let toastId: string | number | undefined

      const timer = setTimeout(() => {
        toastId = toast('Searching for prospect data against multiple data sources. This may take a few seconds...', {
          duration: 10_000,
          dismissible: true
        })
      }, 2500)

      post<{ prospect: Prospect }>(projectPath(`/prospects/${prospect.id}/unlock`), {
        context
      })
        .then((res) => {
          clearTimeout(timer)

          setProspects((prev) => {
            return prev.map((p) => {
              if (p.id === prospect.id) {
                return {
                  ...p,
                  ...res.prospect
                }
              }
              return p
            })
          })

          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          if (res.prospect.email) {
            toast.success('Prospect unlocked')
          } else {
            toast.warning(`Could not find email for ${res.prospect.first_name} ${res.prospect.last_name}`)
          }
        })
        .catch((err) => {
          toast.error("Couldn't find email for prospect: " + err.message)
        })
        .finally(() => {
          clearTimeout(timer)
          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
        })
    },
    [context]
  )

  return (
    <Box pointerEvents={isLoading ? 'none' : undefined} opacity={isLoading ? 0.8 : undefined}>
      {localProspects.map((prospect, index) => {
        const isLocked = prospect.unlock_state === 'locked' || prospect.unlock_state === null
        const isPhoneLocked = prospect.phone_state === 'locked' || prospect.phone_state === null
        const emailAvailable =
          prospect.email && (prospect.unlock_state === 'unlocked' || prospect.unlock_state === 'auto-unlocked')
        const phoneAvailable = prospect.phone_numbers?.mobile && prospect.phone_state === 'unlocked'

        return (
          <Stack
            key={prospect.id}
            py={3}
            borderBottom={index + 1 === localProspects.length ? undefined : '1px solid'}
            borderColor="gray.200"
          >
            <Flex alignItems="flex-start" fontSize="sm" gap={4}>
              <HStack key={prospect.id} alignItems="flex-start" spacing={2.5} flex="1 1 auto" minW="250px" isTruncated>
                <Avatar
                  src={projectPath(`/prospects/${prospect.id}/avatar`)}
                  name={prospect.first_name + ' ' + prospect.last_name}
                  size="40px"
                />

                <Stack spacing="0" minWidth="100px">
                  <HStack spacing={1} mb={0.5}>
                    <Text color="gray.800" fontWeight="semibold" fontSize="sm" lineHeight="20px">
                      {prospect.first_name} {prospect.last_name}
                    </Text>

                    <ButtonGroup size="tiny" variant="ghost" spacing={0.5} py="0">
                      {prospect.linkedin_url && (
                        <IconButton
                          as={Link}
                          icon={<LinkedinBoxIcon color={'linkedin.700'} boxSize="18px" />}
                          aria-label="View LinkedIn Profile"
                          href={`https://${prospect.linkedin_url.replace(/https?:\/\//, '')}`}
                          isExternal
                        />
                      )}

                      {(prospect.salesforce_contact_cache || prospect.salesforce_lead_cache) && (
                        <Tooltip label="View in Salesforce">
                          <IconButton
                            as={Link}
                            icon={<SalesforceIcon color="salesforce.500" boxSize="18px" />}
                            aria-label="View in Salesforce"
                            href={
                              prospect.salesforce_contact_cache?.permalink ?? prospect.salesforce_lead_cache?.permalink
                            }
                            isExternal
                          />
                        </Tooltip>
                      )}

                      {prospect.hubspot_contact_cache &&
                        !(prospect.salesforce_contact_cache || prospect.salesforce_lead_cache) && (
                          <Tooltip label="View in HubSpot">
                            <IconButton
                              as={Link}
                              icon={<HubSpotIcon color="hubspot" boxSize="18px" />}
                              aria-label="View in HubSpot"
                              href={prospect.hubspot_contact_cache?.permalink}
                              isExternal
                            />
                          </Tooltip>
                        )}

                      {prospect.profile && (
                        <Tooltip label="View activity in Koala">
                          <IconButton
                            as={Link}
                            icon={<KoalaIcon color="purple.500" boxSize="18px" />}
                            aria-label="View activity in Koala"
                            href={profilePath(prospect.profile)}
                            isExternal
                          />
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </HStack>

                  <TextEllipsis tooltip maxW="100%" fontSize="13px" lineHeight="20px" color="gray.800">
                    {prospect.title}
                  </TextEllipsis>

                  <TextEllipsis tooltip maxW="100%" fontSize="13px" lineHeight="20px" color="gray.500">
                    {[prospect.city, prospect.region, prospect.country_code || prospect.country]
                      .filter(Boolean)
                      .join(', ')}
                  </TextEllipsis>
                </Stack>
              </HStack>

              {actions !== false && (
                <HStack marginLeft="auto" spacing={1}>
                  {/* {(isPhoneLocked || isLocked) && (
                    <HelpTooltip>
                      <Stack spacing="4" p="2">
                        <Heading size="xs" fontWeight={'semibold'}>
                          Unlock prospect
                        </Heading>
                        <Text whiteSpace={'pre-wrap'} lineHeight="1.4">
                          Use Koala Waterfall enrichment credits to unlock the email address and/or phone numbers for
                          this prospect.
                        </Text>
                        <Text
                          whiteSpace={'pre-wrap'}
                          fontSize="xs"
                          lineHeight="1.4"
                          p="4"
                          bg="purple.50"
                          color="gray.700"
                        >
                          Note: We're offering unlimited Koala Waterfall enrichment credits to all beta testers of
                          Prospecting.
                        </Text>
                        <HStack w="100%">
                          <Button
                            w="100%"
                            size="sm"
                            colorScheme={'gray'}
                            disabled={prospect.unlock_state === 'not_found'}
                            variant={'outline'}
                            onClick={() => onUnlock(prospect)}
                            leftIcon={<IconMailSearch size="18" />}
                            isLoading={emailLoadingState[prospect.id] || isLoading}
                          >
                            Find Email
                          </Button>
                          <Button
                            w="100%"
                            size="sm"
                            colorScheme={'gray'}
                            variant={'outline'}
                            disabled={prospect.phone_state === 'not_found'}
                            onClick={() => {
                              onUnlockPhone(prospect)
                            }}
                            leftIcon={<IconPhonePlus size="18" />}
                            isLoading={phoneLoadingState[prospect.id] || isLoading}
                          >
                            Find Phone
                          </Button>
                        </HStack>
                      </Stack>
                    </HelpTooltip>
                  )} */}
                  <ActionMenu
                    prospect={prospect}
                    domain={domain}
                    isLoading={isLoading}
                    apps={apps}
                    persona={persona}
                    context={context}
                    onEmailLoadingChange={(loading) => {
                      setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: loading }))
                    }}
                    onPhoneLoadingChange={(loading) => {
                      setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: loading }))
                    }}
                    onChange={(updatedProspect) => {
                      setProspects((prev) => {
                        return prev.map((p) => {
                          if (p.id === updatedProspect.id) {
                            return updatedProspect
                          }
                          return p
                        })
                      })
                    }}
                  />
                </HStack>
              )}
            </Flex>
            <HStack spacing={2} paddingLeft={12}>
              <Tooltip label={emailAvailable ? 'Copy to clipboard' : undefined}>
                <Button
                  bg="gray.50"
                  role="group"
                  leftIcon={
                    emailAvailable ? (
                      <IconAt size="14" />
                    ) : prospect.unlock_state === 'not_found' ? (
                      <IconMailX size="14" />
                    ) : (
                      <IconMailSearch size="14" />
                    )
                  }
                  size="xs"
                  variant={'ghost'}
                  _hover={isLocked ? { bg: 'purple.50', color: 'purple.500' } : undefined}
                  colorScheme={prospect.unlock_state === 'not_found' ? 'red' : 'gray'}
                  isLoading={emailLoadingState[prospect.id] || isLoading}
                  onClick={() => {
                    if (emailAvailable) {
                      navigator.clipboard.writeText(prospect.email!)
                      toast.success('Email copied to clipboard', {
                        position: 'bottom-right'
                      })
                      window.ko?.track('Prospect Email Copied', {
                        email: prospect.email,
                        prospected_profile_id: prospect.id,
                        company: domain,
                        context: context
                      })
                      return
                    }

                    onUnlock(prospect)
                  }}
                  rightIcon={
                    emailAvailable ? (
                      <Icon as={IconCopy} boxSize={3.5} color="gray.400" _groupHover={{ color: 'gray.700' }} />
                    ) : undefined
                  }
                >
                  {emailAvailable && prospect.email}
                  {isLocked && 'Find email'}
                  {prospect.unlock_state === 'not_found' && 'Email not found'}
                </Button>
              </Tooltip>

              <Tooltip label={phoneAvailable ? 'Copy to clipboard' : undefined}>
                <Button
                  bg="gray.50"
                  role="group"
                  _hover={isPhoneLocked ? { bg: 'purple.50', color: 'purple.500' } : undefined}
                  leftIcon={
                    prospect.phone_state === 'unlocked' ? (
                      <IconPhoneCheck size="14" />
                    ) : prospect.phone_state === 'not_found' ? (
                      <IconPhoneX size="15" />
                    ) : (
                      <IconPhonePlus size="14" />
                    )
                  }
                  colorScheme={
                    prospect.phone_state === 'unlocked' ? 'gray' : prospect.phone_state === 'not_found' ? 'red' : 'gray'
                  }
                  size="xs"
                  variant={'ghost'}
                  isLoading={phoneLoadingState[prospect.id] || isLoading}
                  onClick={() => {
                    if (phoneAvailable) {
                      navigator.clipboard.writeText(prospect.phone_numbers!.mobile!)
                      toast.success('Phone number copied to clipboard', {
                        position: 'bottom-right'
                      })
                      window.ko?.track('Prospect Phone Copied', {
                        email: prospect.email,
                        prospected_profile_id: prospect.id,
                        company: domain,
                        context: context
                      })
                      return
                    }

                    onUnlockPhone(prospect)
                  }}
                  rightIcon={
                    phoneAvailable ? (
                      <Icon as={IconCopy} boxSize={3.5} color="gray.400" _groupHover={{ color: 'gray.700' }} />
                    ) : undefined
                  }
                >
                  {phoneAvailable && prospect.phone_numbers?.mobile}
                  {isPhoneLocked && 'Find phone'}
                  {prospect.phone_state === 'not_found' && 'Phone not found'}
                </Button>
              </Tooltip>
            </HStack>
          </Stack>
        )
      })}
    </Box>
  )
}
