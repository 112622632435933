import {
  Button,
  ButtonGroup,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Spinner,
  Stack,
  Td,
  Text,
  Th
} from '@chakra-ui/react'
import { IconSearch } from '@tabler/icons-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'
import { useDebouncedCallback } from 'use-debounce'
import { postForm } from '../../../../lib/api'
import { formatNumber } from '../../../../lib/number-format'
import { PageMeta } from '../../../../types/PageMeta'
import { PartialAccount } from '../../../ui/AccountSelector'
import { Card } from '../../../ui/Card'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { JSONTree } from '../../../ui/json-tree'
import { projectPath } from '../../../ui/ProjectsContext'
import { TableFooter } from '../../../ui/TableFooter'
import { FacetFilters } from '../../accounts'
import { HighlightedProfile, ProfileList } from '../../profiles/components/profile-list'
import { AutoProspectingSetting, ProspectorPreview } from '../../prospects/settings'

interface Props {
  formRef: React.RefObject<HTMLFormElement>
}

type AudienceInfo = {
  total_eligible: number
  total_contacted: number
  total_accounts: number
  total_contacted_accounts: number
  contact_filters: FacetFilters
  account_filters: FacetFilters
}

export function RulePreview(
  props: Props & {
    prospectingStrategy: 'anonymous' | 'identified'
    autoProspectingSetting?: AutoProspectingSetting
  }
) {
  if (props.prospectingStrategy === 'anonymous' && props.autoProspectingSetting) {
    return <AnonymousAudiencePreview formRef={props.formRef} setting={props.autoProspectingSetting} />
  }

  return <AudiencePreview formRef={props.formRef} />
}

function AnonymousAudiencePreview(props: {
  setting: AutoProspectingSetting
  formRef: React.RefObject<HTMLFormElement>
}) {
  const [lookback, setLookback] = useState<'week' | 'month'>('week')
  const { isLoading, audienceInfo } = useAudienceInfo(props.formRef, lookback)
  const [selectedAccount, setSelectedAccount] = useState<PartialAccount | null>(null)
  const [isPreviewing, setIsPreviewing] = useState(false)

  return (
    <Stack
      position={['static', 'static', 'sticky']}
      top={`calc(var(--nav-height) + 30px)`}
      flex="1 1 auto"
      as={Card}
      maxWidth="380px"
      minWidth="280px"
      px={4}
      py={4}
      spacing={6}
    >
      <HStack spacing={8} justifyContent="space-between">
        <HStack spacing={1.5}>
          <Heading size="sm">Audience Preview</Heading>
          <HelpTooltip variant="info">
            This will show you a preview of the prospects that will be targeted based on the selected data sources and
            persona.
          </HelpTooltip>
        </HStack>
        <ButtonGroup size="xs" spacing={1}>
          <Button variant="link" isActive={lookback === 'week'} onClick={() => setLookback('week')}>
            7d
          </Button>
          <Button variant="link" isActive={lookback === 'month'} onClick={() => setLookback('month')}>
            30d
          </Button>
        </ButtonGroup>
      </HStack>

      {isLoading ? (
        <Center h="100%" py={6}>
          <Spinner size="md" />
        </Center>
      ) : (
        <>
          {typeof audienceInfo?.total_eligible === 'number' && (
            <Stack fontSize="sm" spacing="4">
              <Stack spacing="0">
                <Flex gap={2} alignItems="baseline">
                  <Heading size="sm" fontWeight="semibold" css={{ fontVariantNumeric: 'tabular-nums' }}>
                    {formatNumber(audienceInfo.total_accounts)}
                  </Heading>
                  <Text fontSize="sm" fontWeight="semibold" color="gray.500">
                    Estimated Reach (Companies)
                  </Text>
                </Flex>
                <Text fontSize="xs" color="gray.600">
                  Companies that match this audience and triggered the selected intent signals in the past{' '}
                  {lookback === 'month' ? '30' : '7'} days.
                </Text>
              </Stack>
            </Stack>
          )}

          {typeof audienceInfo?.total_contacted === 'number' && (
            <Stack fontSize="sm" spacing="4">
              <Stack spacing="0">
                <Flex gap={2} alignItems="baseline">
                  <Heading size="sm" fontWeight="semibold" css={{ fontVariantNumeric: 'tabular-nums' }}>
                    {formatNumber(audienceInfo.total_contacted)}
                  </Heading>
                  <Text fontSize="sm" fontWeight="semibold" color="gray.500">
                    Recently Contacted
                  </Text>
                </Flex>
                <Text fontSize="xs" color="gray.600">
                  Companies in this audience that have been contacted or whose account has been contacted based on
                  "Ignore accounts recently contacted" filter.
                </Text>
              </Stack>
            </Stack>
          )}
        </>
      )}

      <Button
        leftIcon={<IconSearch size="14" />}
        size="sm"
        onClick={() => {
          setIsPreviewing(true)
        }}
        variant="outline"
      >
        Preview
      </Button>

      <Drawer
        isOpen={isPreviewing}
        onClose={() => {
          setIsPreviewing(false)
        }}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Preview Prospects</DrawerHeader>
          <DrawerBody fontSize={'sm'}>
            <ProspectorPreview
              facets={audienceInfo?.account_filters?.[0]}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              autoProspectingSetting={props.setting ?? { data_sources: [], persona_prompt: '' }}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Stack>
  )
}

function useAudienceInfo(formRef: React.RefObject<HTMLFormElement>, lookback: 'week' | 'month') {
  const [audienceInfo, setAudienceInfo] = useState<AudienceInfo | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const controllerRef = useRef<AbortController>()
  const refetch = useDebouncedCallback(() => {
    if (!formRef.current) {
      return
    }

    const data = new FormData(formRef.current)
    setIsLoading(true)

    controllerRef.current?.abort()
    controllerRef.current = new AbortController()

    const path = projectPath(`/auto-outbound/audience-info?lookback=${lookback || 'week'}`)

    postForm<AudienceInfo>(path, data, {
      signal: controllerRef.current.signal
    })
      .then((res) => {
        setAudienceInfo(res)
        setIsLoading(false)
      })
      .catch(() => {})

    return () => {
      controllerRef.current?.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 300)

  useEffect(() => {
    refetch()
  }, [refetch, lookback])

  useEffect(() => {
    const formElement = formRef.current
    if (formElement) {
      const audienceInput = (name: string | undefined) => {
        if (!name) return false
        return (
          name.startsWith('qualification_rules[') || name.startsWith('crm_rules[') || name.startsWith('intent_rules[')
        )
      }

      formElement.addEventListener('change', refetch)

      const mutationCallback = (mutationsList) => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'value' &&
            audienceInput(mutation.target.name)
          ) {
            refetch()
            return
          }

          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach((node) => {
              if (node.type === 'hidden' && audienceInput(node.name)) {
                refetch()
                return
              }
            })

            mutation.removedNodes.forEach((node) => {
              if (node.type === 'hidden' && audienceInput(node.name)) {
                refetch()
                return
              }
            })
          }
        }
      }

      const observer = new MutationObserver(mutationCallback)
      const config = { childList: true, subtree: true, attributes: true }
      observer.observe(formElement, config)

      return () => {
        observer.disconnect()
        formElement.removeEventListener('change', refetch)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch])

  return {
    audienceInfo,
    isLoading
  }
}

export function AudiencePreview(props: Props) {
  const [drawerStep, setDrawerStep] = useState<string | null>(null)
  const [lookback, setLookback] = useState<'week' | 'month'>('week')
  const { isLoading, audienceInfo } = useAudienceInfo(props.formRef, lookback)

  return (
    <>
      <Stack
        position={['static', 'static', 'sticky']}
        top={`calc(var(--nav-height) + 30px)`}
        flex="1 1 auto"
        as={Card}
        maxWidth="380px"
        minWidth="280px"
        px={4}
        py={4}
        spacing={6}
      >
        <HStack spacing={8} justifyContent="space-between">
          <HStack spacing={1.5}>
            <Heading size="sm">Audience Estimate</Heading>
            <HelpTooltip variant="info">
              The estimated number of people that matched your specified intent + eligibility criteria in the past{' '}
              {lookback === 'month' ? '30' : '7'} days
            </HelpTooltip>
          </HStack>
          <ButtonGroup size="xs" spacing={1}>
            <Button variant="link" isActive={lookback === 'week'} onClick={() => setLookback('week')}>
              7d
            </Button>
            <Button variant="link" isActive={lookback === 'month'} onClick={() => setLookback('month')}>
              30d
            </Button>
          </ButtonGroup>
        </HStack>

        {isLoading ? (
          <Center h="100%" py={6}>
            <Spinner size="md" />
          </Center>
        ) : (
          <>
            {typeof audienceInfo?.total_eligible === 'number' && (
              <Stack fontSize="sm" spacing="4">
                <Stack spacing="0">
                  <Flex gap={2} alignItems="baseline">
                    <Heading size="sm" fontWeight="semibold" css={{ fontVariantNumeric: 'tabular-nums' }}>
                      {formatNumber(audienceInfo.total_eligible)}
                    </Heading>
                    <Text fontSize="sm" fontWeight="semibold" color="gray.500">
                      Estimated Reach
                    </Text>
                  </Flex>
                  <Text fontSize="xs" color="gray.600">
                    Visitors that match this audience and triggered the selected intent signals in the past{' '}
                    {lookback === 'month' ? '30' : '7'} days.
                  </Text>
                </Stack>
              </Stack>
            )}

            {typeof audienceInfo?.total_contacted === 'number' && (
              <Stack fontSize="sm" spacing="4">
                <Stack spacing="0">
                  <Flex gap={2} alignItems="baseline">
                    <Heading size="sm" fontWeight="semibold" css={{ fontVariantNumeric: 'tabular-nums' }}>
                      {formatNumber(audienceInfo.total_contacted)}
                    </Heading>
                    <Text fontSize="sm" fontWeight="semibold" color="gray.500">
                      Recently Contacted
                    </Text>
                  </Flex>
                  <Text fontSize="xs" color="gray.600">
                    Visitors in this audience that have been contacted or whose account has been contacted based on
                    "Ignore accounts recently contacted" filter. This also includes visitors that are in active Outreach
                    sequences, when Outreach is configured.
                  </Text>
                </Stack>
              </Stack>
            )}

            <Button
              leftIcon={<IconSearch size="14" />}
              size="sm"
              onClick={() => setDrawerStep('all')}
              variant="outline"
              isDisabled={isLoading || !audienceInfo?.total_eligible}
            >
              Preview
            </Button>
          </>
        )}
      </Stack>

      {drawerStep && !isLoading && (
        <AudiencePreviewDrawer
          formRef={props.formRef}
          step={drawerStep}
          lookback={lookback}
          onClose={() => setDrawerStep(null)}
        />
      )}
    </>
  )
}

interface AudiencePreviewDrawerProps {
  formRef: React.RefObject<HTMLFormElement>
  lookback?: 'week' | 'month'
  step: string
  onClose: () => void
}

function AudiencePreviewDrawer(props: AudiencePreviewDrawerProps) {
  const [profiles, setProfiles] = useState<HighlightedProfile[] | null>(null)
  const [paginationMeta, setPaginationMeta] = useState<PageMeta | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [facetParams, setFacetParams] = useState<Record<string, string> | null>(null)
  const [page, setPage] = useState(1)
  const [sending, setSending] = useState<string>()

  const controllerRef = useRef<AbortController>()

  useEffect(() => {
    if (!props.formRef.current) {
      return
    }

    const data = new FormData(props.formRef.current)
    setIsLoading(true)

    controllerRef.current = new AbortController()

    const path = projectPath(`/auto-outbound/audience-preview?lookback=${props.lookback}&page=${page}`)
    postForm<{ profiles: HighlightedProfile[]; page_meta: PageMeta; facet_params: Record<string, string> }>(
      path,
      data,
      {
        signal: controllerRef.current.signal
      }
    )
      .then((res) => {
        setProfiles(res.profiles)
        setPaginationMeta(res.page_meta)
        setIsLoading(false)
        setFacetParams(res.facet_params)
      })
      .catch(() => {})

    return () => {
      controllerRef.current?.abort()
    }
  }, [props.formRef, props.step, props.lookback, page])

  const onTest = useCallback(
    (profile) => {
      if (!props.formRef.current) {
        return
      }

      const data = new FormData(props.formRef.current)
      setSending(profile.id)

      postForm(projectPath(`/auto-outbound/send-test?profile_id=${profile.id}`), data)
        .then(() => {
          toast.success('Test sent. Check your destination.')
        })
        .catch((e) => {
          toast.error(e?.body?.error ?? 'Something went wrong.')
        })
        .finally(() => {
          setSending(undefined)
        })
    },
    [props.formRef]
  )

  return (
    <Drawer isOpen onClose={props.onClose} size="xl">
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Preview</DrawerHeader>
        <DrawerBody fontSize={'sm'}>
          <Stack spacing="8">
            <HStack>
              <Text>
                Previewing eligible visitors for <strong>{props.step}</strong> conditions.
              </Text>
              {isLoading && <Spinner size="sm" />}
            </HStack>

            {facetParams && (
              <Stack>
                <Heading size="xs">Search Filters</Heading>
                <JSONTree data={facetParams} />
              </Stack>
            )}

            {profiles && (
              <Stack>
                <Heading size="xs">Sample Matching Visitors</Heading>
                <ProfileList
                  profiles={profiles}
                  extraColumns={[
                    {
                      id: 'test',
                      Title: () => <Th></Th>,
                      Cell: ({ record }) => {
                        return (
                          <Td>
                            <Button onClick={() => onTest(record)} size="xs" isLoading={sending === record.id}>
                              Send Test
                            </Button>
                          </Td>
                        )
                      }
                    }
                  ]}
                  columns={['Company']}
                />
              </Stack>
            )}
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <HStack>
            {isLoading && <Spinner size="sm" />}
            {paginationMeta && <TableFooter page={page} setPage={setPage} pageMeta={paginationMeta} />}
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
