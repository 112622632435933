import { Box, ButtonGroup, Divider, Flex, Link, Skeleton, Stack, Tooltip } from '@chakra-ui/react'
import React, { useState } from 'react'
import { App, Apps } from '../../../../types/App'
import { PlayItem } from '../../../../types/Play'
import { useAccountPlayProspects } from '../../../data/use-account-play-prospects'
import { projectPath } from '../../../ui/ProjectsContext'
import { LinkedinBoxIcon } from '../../../ui/icons'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { HighlightedAccount } from '../../icps/types'
import { ProfilePanelV2 } from '../../profiles/components/ProfilePanelV2'
import { profilePath } from '../../profiles/lib/path'
import { CompleteButton } from './CompleteButton'
import { EmailButton } from './EmailButton'
import { QuickDismissButton } from './QuickDismissButton'
import { ResetButton } from './ResetButton'
import { WaterfallButton } from './WaterfallButton'
import Avatar from '../../../ui/Avatar'

interface AccountProspectsProps {
  account: HighlightedAccount
  item: PlayItem
  onUpdateItem?: (itemId: string, updatedItem: PlayItem) => void
  onCompleteItem?: (itemId: string, status: string) => void
  onDismissItem?: (itemId: string, status: string) => void
  onResetItem?: (itemId: string, status: string) => void
  apps: App[]
}

export const AccountProspects = (props: AccountProspectsProps) => {
  const { item, apps } = props
  const { data, isLoading } = useAccountPlayProspects(item)
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null)

  return (
    <Box bg="gray.50" p="4">
      <ProfilePanelV2
        profileId={selectedProfileId || ''}
        isOpen={!!selectedProfileId}
        onClose={() => setSelectedProfileId(null)}
        key={['profile-panel', selectedProfileId].join('-')}
        apps={apps?.reduce((acc, app) => {
          acc[app.title] = app
          return acc
        }, {} as Apps)}
      />

      {isLoading ? (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      ) : (
        <Stack divider={<Divider />} spacing={2} pl="6">
          {data?.play_items?.map((item) => {
            const profile = item.record
            const hasName = Boolean(profile.name || profile.full_name)

            return (
              <Box key={item.id}>
                <Flex flex="1" minW="400px" alignItems="center" justifyContent="space-between" gap={4}>
                  <Flex flex="1" alignItems="center" gap={2.5} isTruncated>
                    <Avatar
                      size="sm"
                      name={profile.name || profile.full_name || profile.display_name}
                      src={
                        profile.image ||
                        (profile.koala_person?.id
                          ? projectPath(`/prospects/${profile.koala_person.id}/avatar`)
                          : undefined)
                      }
                    />
                    <Stack spacing={0.5} isTruncated>
                      <Flex alignItems="center" gap={2} isTruncated>
                        <Link
                          display="inline-flex"
                          href={profilePath(profile)}
                          isExternal
                          _hover={{ textDecoration: 'underline' }}
                          onClick={(e) => {
                            if (e.ctrlKey || e.metaKey || e.shiftKey) {
                              return true
                            }

                            e.preventDefault()
                            setSelectedProfileId(profile.id)
                            return false
                          }}
                        >
                          <TextEllipsis fontSize="sm" fontWeight="medium" maxW="100%" tooltip>
                            {profile.name || profile.full_name || profile.display_name || profile.email || 'Anonymous'}
                          </TextEllipsis>
                        </Link>

                        {profile.linkedin_url && (
                          <Tooltip label={`https://${profile.linkedin_url.replace(/https?:\/\//, '')}`}>
                            <Link
                              display="flex"
                              flex="none"
                              alignItems="center"
                              color="linkedin.700"
                              isExternal
                              href={`https://${profile.linkedin_url.replace(/https?:\/\//, '')}`}
                              onClick={(e) => {
                                e.stopPropagation()
                                window.ko?.track('LinkedIn Profile Visit Action', {
                                  app: 'linkedin',
                                  email: profile.email
                                })
                              }}
                            >
                              <LinkedinBoxIcon boxSize="18px" />
                            </Link>
                          </Tooltip>
                        )}
                      </Flex>
                      {profile.title ? (
                        <TextEllipsis fontSize="13px" color="gray.500" tooltip>
                          {[profile.title, profile.company?.name].filter(Boolean).join(' @ ')}
                        </TextEllipsis>
                      ) : (
                        <TextEllipsis fontSize="13px" color="gray.500" tooltip>
                          {hasName ? profile.email || profile.simple_location : profile.simple_location}
                        </TextEllipsis>
                      )}
                    </Stack>
                  </Flex>

                  <Flex alignItems="center" gap={2} ml="auto">
                    {profile.email && (item.status === 'pending' || item.status === 'snoozed') && (
                      <EmailButton
                        buttonText="Compose"
                        record={profile}
                        item={item}
                        onEmailSent={props.onCompleteItem}
                      />
                    )}

                    {!profile.email &&
                      profile.linkedin_url &&
                      (item.status === 'pending' || item.status === 'snoozed') && (
                        <WaterfallButton
                          record={profile}
                          playItem={item}
                          itemId={item.id}
                          onSuccess={(updatedRecord) => {
                            props.onUpdateItem?.(item.id, {
                              ...item,
                              record: updatedRecord
                            })
                          }}
                        />
                      )}
                    <ButtonGroup size="sm" variant="outline" isAttached>
                      {item.status === 'completed' ? (
                        <ResetButton itemId={item.id} onSuccess={props.onResetItem} />
                      ) : (
                        <CompleteButton itemId={item.id} onSuccess={props.onCompleteItem} />
                      )}
                      {item.status === 'dismissed' ? (
                        <ResetButton itemId={item.id} onSuccess={props.onResetItem} />
                      ) : (
                        <QuickDismissButton itemId={item.id} onSuccess={props.onDismissItem} />
                      )}
                    </ButtonGroup>
                  </Flex>
                </Flex>
              </Box>
            )
          })}
        </Stack>
      )}
    </Box>
  )
}
