import {
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  useDisclosure,
  UseDisclosureProps,
  useOutsideClick
} from '@chakra-ui/react'
import omit from 'lodash/omit'
import React, { useCallback, useRef } from 'react'
import { FilterPreviewProps } from '../../pages/accounts/components/FilterPreview/types'
import { AdvancedFilterBuilder } from './AdvancedFilterBuilder'
import { AdvancedFilters } from './types'

interface AdvancedFilterPopoverProps extends UseDisclosureProps, FilterPreviewProps {
  children: React.ReactNode
  filters: AdvancedFilters
  onChange?: (filters: AdvancedFilters) => void
  onApply?: (filters: AdvancedFilters) => void
  onClear?: () => void
  placement?: PopoverProps['placement']
  width?: string | number
}

export function AdvancedFilterPopover({
  children,
  filters,
  onChange,
  onApply,
  onClear,
  placement = 'bottom-start',
  width = '600px',
  ...rest
}: AdvancedFilterPopoverProps) {
  const { isOpen, onOpen, onClose } = useDisclosure(rest)
  const containerRef = useRef<HTMLDivElement>(null)

  // This is used to close the popover when clicking outside of it
  // closeOnBlur doesn't work here because we have nested Portals with focus elements
  useOutsideClick({
    ref: containerRef,
    enabled: isOpen,
    handler: () => {
      onClose()
    }
  })

  const handleApply = useCallback(
    (newFilters: AdvancedFilters) => {
      onApply?.(newFilters)
      onClose()
    },
    [onApply, onClose]
  )

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={placement}
      closeOnBlur={false}
      isLazy
      lazyBehavior="keepMounted"
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        {/* This div is used to detect clicks outside the popover, we have all the nested Portals inside it */}
        <div ref={containerRef}></div>

        <Portal containerRef={containerRef}>
          <PopoverContent
            width={width}
            maxWidth="95vw"
            shadow="xl"
            minWidth="min(600px, 92vw)"
            maxHeight="min(620px, calc(85vh - var(--header-height)))"
            overflow="auto"
            color="gray.700"
            rootProps={{
              zIndex: 'popover'
            }}
            _focus={{
              outline: 'none',
              boxShadow: 'xl'
            }}
          >
            <AdvancedFilterBuilder
              filters={filters}
              onApplyAdvancedFilters={onApply ? handleApply : undefined}
              onChangeAdvancedFilters={onChange}
              onClearAdvancedFilters={onClear}
              {...(omit(rest, [
                'isOpen',
                'onOpen',
                'onClose',
                'onToggle',
                'isControlled',
                'getButtonProps',
                'getDisclosureProps',
                'onConvertToAdvancedFilters'
              ]) as FilterPreviewProps)}
            />
          </PopoverContent>
        </Portal>
      </Portal>
    </Popover>
  )
}
