import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Table,
  TableColumnHeaderProps,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList
} from '@chakra-ui/react'
import {
  IconCircleCheck,
  IconLineDashed,
  IconProgressCheck,
  IconSelector,
  IconSortAscending,
  IconSortDescending
} from '@tabler/icons-react'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useMemo, useState } from 'react'
import dayjs from '../../../../lib/dayjs'
import { formatNumber } from '../../../../lib/number-format'
import { default as router, default as Router } from '../../../../lib/router'
import { PageMeta } from '../../../../types/PageMeta'
import { useSetAttribution } from '../../../data/use-set-attribution'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { DownloadCsvButton, DownloadCsvIcon } from '../../../ui/DownloadCsvButtons'
import { HubSpotIcon } from '../../../ui/icons/HubspotIcons'
import { SalesforceIcon } from '../../../ui/icons/SalesforceIcons'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../../ui/SettingsHeader'
import { TableFooter } from '../../../ui/TableFooter'
import { TimeAgo } from '../../../ui/TimeAgo'
import { TopBarContent } from '../../../ui/TopBarContext'
import useLocation from '../../../ui/useLocation'
import DateRangePicker from '../../analytics/components/DateRangePicker'
import { mergeParams } from '../../icps/types'
import { Crm } from '@type/Crm'

interface ResearchedOpportunity {
  amount?: number | null
  external_created_at?: string | null
  id: string
  name: string
  salesforce_account_id?: string
  salesforce_id?: string
  stage_name?: string
  updated_at?: string

  payload?: Record<string, any>

  research_activities: ResearchActivityStage[]
  researched_stage_changes: ResearchedStageChange[]
  research_attribution?: ResearchAttribution

  account?: {
    id: string
    company?: {
      id: string
      name?: string
      domain: string
      logo?: string
    }
  }
}

/** Direct attribution for an opportunity via UI or Slack */
interface ResearchAttribution {
  id: string
  stage_name?: string
  impact?: 'influenced' | 'sourced' | 'no_influence'
  source?: 'slack' | 'ui'
  metadata?: {
    [key: string]: any
  }
  updated_at: string
  user?: {
    id: string
    name: string
    email: string
  }
}

/** Stage changes that had research */
interface ResearchedStageChange {
  id?: string
  activity_ids?: any[] | null
  new_stage_name: string
  old_stage_name?: string | null
  opportunity_id?: string
  research_count?: number
  triggered_at?: string
}

interface ResearchActivityStage {
  stage: ResearchedStageChange
  activities: ResearchActivityEvent[]
}

interface ResearchActivityEvent {
  id: string
  event_name: string
  created_at: string
  user?: {
    email: string
    name: string
  }
}

interface CrmField {
  name: string
  label: string
  type: string
}

interface Props {
  page_meta: PageMeta
  total_amount: number
  opps: ResearchedOpportunity[]
  crm: 'Salesforce' | 'HubSpot'
  crms: Crm[]
  crm_fields: CrmField[]
  period: string
  sort_by?: string
  sort_direction?: 'asc' | 'desc'
}

const _impactOptions = [
  { label: 'None', value: 'no_influence', icon: <Icon as={IconLineDashed} boxSize={5} color="gray.400" /> },
  {
    label: 'Influenced',
    value: 'influenced',
    icon: <Icon as={IconProgressCheck} boxSize={5} color="gray.500" />
  },
  {
    label: 'Sourced',
    value: 'sourced',
    icon: <Icon as={IconCircleCheck} boxSize={5} color="green.400" />
  }
]

export default function InfluenceReport(props: Props) {
  const [period, setPeriod] = React.useState(props.period || 'month')
  const sortBy = props.sort_by
  const sortDirection = props.sort_direction

  // supports custom values for Amount
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const amountField = searchParams.get('amount') || 'amount'

  const setAmountField = React.useCallback((field: string | undefined | null) => {
    router.visit(mergeParams(window.location.toString(), { amount: field || undefined }))
  }, [])

  React.useEffect(() => {
    setPeriod(props.period)
  }, [props.period])

  const updatePeriod = React.useCallback(async (period: string | undefined) => {
    if (period) {
      setPeriod(period)

      const path = mergeParams(window.location.toString(), { period, page: '1' })
      router.visit(path)
    }
  }, [])

  return (
    <PageLayout size="full">
      <TopBarContent>
        <Flex width="100%" alignItems="center" gap={2} justifyContent="space-between">
          <SettingsBreadCrumb paths={[{ path: projectPath('/reports/influence'), title: 'Pipeline Influence' }]} />

          <Flex alignItems="center" gap={2}>
            <DateRangePicker period={period} onChange={updatePeriod} min={dayjs().subtract(1, 'year').toDate()} />
            <DownloadCsvButton
              url={`${window.location.pathname}.csv${window.location.search}`}
              leftIcon={<DownloadCsvIcon size={16} />}
              iconSpacing={1.5}
              flex="none"
              size="sm"
              variant="outline"
            >
              Download
            </DownloadCsvButton>
          </Flex>
        </Flex>
      </TopBarContent>

      <SettingsHeader borderBottom="none">
        <Flex alignItems="flex-start" gap={8} justifyContent="space-between">
          <Box flex="1 1 auto">
            <PageTitle>Pipeline Influence</PageTitle>
            <PageDescription>
              Opportunities that have advanced after reps used Koala to help source, influence, or research the deal.
            </PageDescription>
          </Box>

          <StatGroup gap={6} textAlign="right">
            <Stat flexShrink={0}>
              <StatLabel>Total Opps</StatLabel>
              <StatNumber>{props.page_meta.total_count.toLocaleString()}</StatNumber>
            </Stat>
            <Stat flexShrink={0}>
              <StatLabel>Total Influence</StatLabel>
              <StatNumber>${formatNumber(props.total_amount, { maximumFractionDigits: 0 })}</StatNumber>
            </Stat>
          </StatGroup>
        </Flex>

        <Flex gap={4} mt={8} alignItems="flex-start">
          {(props.crms?.length ?? 0) > 1 && (
            <FormControl maxWidth="200px">
              <FormLabel>CRM</FormLabel>
              <ComboboxWithSearch
                selectedItem={{ value: props.crm, label: props.crm }}
                onChange={(crmOption) => {
                  router.visit(mergeParams(window.location.toString(), { crm: crmOption?.value }))
                }}
                items={[
                  { value: 'Salesforce', label: 'Salesforce' },
                  { value: 'HubSpot', label: 'HubSpot' }
                ]}
                itemToString={(item) => item?.label || ''}
                placeholder="Select CRM"
              />
            </FormControl>
          )}
          {!!props.crm_fields && !!props.crm && (
            <FormControl maxWidth="300px">
              <FormLabel>Amount Field</FormLabel>
              <OpportunityAmountFieldPicker
                size="sm"
                selectedField={amountField}
                onChange={setAmountField}
                fields={props.crm_fields}
                app={props.crm.toLowerCase() as 'salesforce' | 'hubspot'}
              />
            </FormControl>
          )}
        </Flex>
      </SettingsHeader>

      <Stack>
        <TableContainer>
          <Table variant="bordered" size="sm">
            <Thead>
              <Tr>
                <SortableHeader sortBy="name" currentSort={sortBy} direction={sortDirection}>
                  Opportunity
                </SortableHeader>
                <SortableHeader isNumeric sortBy="amount" currentSort={sortBy} direction={sortDirection}>
                  Amount
                </SortableHeader>
                <Th>Found in Stage</Th>
                <SortableHeader sortBy="stage_name" currentSort={sortBy} direction={sortDirection}>
                  Current Stage
                </SortableHeader>
                <Th>Research Actions</Th>
                {/* <Th>
                  Koala Impact
                </Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {props.opps.map((opp) => (
                <OpportunityRow key={opp.id} opp={opp} amountField={amountField} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <TableFooter
          pageMeta={props.page_meta}
          page={props.page_meta.current_page}
          nextPath={mergeParams(window.location.toString(), {
            page: String(props.page_meta.next_page)
          })}
          prevPath={mergeParams(window.location.toString(), {
            page: String(props.page_meta.prev_page)
          })}
        />
      </Stack>
    </PageLayout>
  )
}

interface OpportunityRowProps {
  opp: ResearchedOpportunity
  amountField: string
}

function OpportunityRow({ opp, amountField }: OpportunityRowProps) {
  const [_impact, setImpact] = useState<null | string>(opp.research_attribution?.impact || null)
  const [pendingChange, setPendingChange] = useState<null | string>(null)
  const { mutateAsync } = useSetAttribution()

  const overrideImpact = useCallback(
    async (impact: string | null) => {
      if (impact === null) {
        return
      }

      let prevImpact

      // optimistic update
      setImpact((prev) => {
        prevImpact = prev
        return impact
      })
      setPendingChange(null)

      try {
        await mutateAsync({ opportunityId: opp.id, impact: impact })
      } catch (error) {
        setImpact(prevImpact)
      }
    },
    [opp.id, mutateAsync]
  )

  const _onClose = useCallback(() => {
    setPendingChange(null)
  }, [])

  const _confirmOverride = useCallback(() => overrideImpact(pendingChange), [overrideImpact, pendingChange])

  const amount = amountField in opp ? opp[amountField] : opp.payload?.[amountField]

  return (
    <Tr>
      <Td>
        <HStack spacing={3} paddingY={1}>
          <CompanyAvatar size="sm" name={opp.account?.company?.name} domain={opp.account?.company?.domain} />
          <Stack spacing={0.5}>
            <Text fontWeight="medium">{opp.name}</Text>
            <Text fontSize="xs" color="gray.500">
              Created <TimeAgo time={opp.external_created_at} />
            </Text>
          </Stack>
        </HStack>
      </Td>
      <Td isNumeric width="1px">
        {typeof amount === 'number' || (typeof amount === 'string' && amount)
          ? `$${formatNumber(amount, { maximumFractionDigits: 0 })}`
          : '-'}
      </Td>
      <Td width="1px" title={foundInStage(opp)}>
        <Text minWidth="180px" maxWidth="280px" textOverflow="ellipsis" overflow="hidden" whiteSpace="initial">
          {foundInStage(opp)}
        </Text>
      </Td>
      <Td width="1px" title={opp.stage_name}>
        <Text minWidth="180px" maxWidth="280px" textOverflow="ellipsis" overflow="hidden" whiteSpace="initial">
          {opp.stage_name}
        </Text>
      </Td>
      <Td width="1px">
        <Popover trigger="hover" isLazy lazyBehavior="keepMounted">
          <PopoverTrigger>
            <Button variant="ghost" size="sm" mx={-2.5}>
              {totalCount(opp)}x research
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="sm" maxWidth="400px">
              <PopoverArrow />
              <PopoverBody>
                <Stack spacing={6} padding={2}>
                  {groupedResearch(opp.research_activities).map((r) => (
                    <Box key={r.stage.id}>
                      <Text fontSize="xs" color="gray.600">
                        Before {r.stage.old_stage_name ? r.stage.new_stage_name : 'Opportunity Created'}
                      </Text>
                      <UnorderedList fontSize="sm" mt={1}>
                        {r.details.map((d) => (
                          <ListItem key={JSON.stringify(d)} whiteSpace="break-spaces" my={1}>
                            {d.diff} before, {d.count}x research ({d.users.join(', ')})
                          </ListItem>
                        ))}
                      </UnorderedList>
                    </Box>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Td>
      {/* <Td width="1px">
        <ConfirmDowngrade isOpen={!!pendingChange} onConfirm={confirmOverride} onClose={onClose}>
          <Text fontSize="sm">
            Someone has already marked this opportunity as{' '}
            <b>{impactOptions.find((o) => o.value === impact)?.label} by Koala</b>, but you will override the Koala
            impact to <b>{impactOptions.find((o) => o.value === pendingChange)?.label}</b>. Are you sure?
          </Text>
        </ConfirmDowngrade>
        <SelectInput
          size="sm"
          variant="outline"
          triggerProps={{
            borderColor: 'gray.200'
          }}
          popperOptions={{
            placement: 'bottom-end'
          }}
          placeholder={
            <Flex alignItems="center" gap={2} fontSize="13px" fontWeight="normal">
              <Icon as={IconLineDashed} boxSize="18px" color="gray.400" />
              <Text color="gray.500">Select impact</Text>
            </Flex>
          }
          items={impactOptions}
          itemToString={(item) => item.label}
          selectedItem={impactOptions.find((o) => o.value === impact) || null}
          onSelectedItemChange={(event) => {
            const newImpact = event.selectedItem.value

            if (impact === 'sourced' && newImpact !== 'sourced') {
              setPendingChange(newImpact)
            } else if (impact === 'influenced' && newImpact === 'no_influence') {
              setPendingChange(newImpact)
            } else {
              overrideImpact(newImpact)
            }
          }}
          itemRenderer={(item) => {
            return (
              <Flex alignItems="center" gap={2} fontSize="13px">
                {item.icon}
                <Text>{item.label}</Text>
              </Flex>
            )
          }}
        />
      </Td> */}
    </Tr>
  )
}

function totalCount(opp: ResearchedOpportunity) {
  return opp.research_activities.map((s) => s.activities.length).reduce((agg, next) => agg + next, 0)
}

function foundInStage(opp: ResearchedOpportunity) {
  const firstStage = sortBy(opp.researched_stage_changes, ['triggered_at', 'new_stage_name'])[0]
  if (!firstStage) {
    return ''
  }

  return firstStage.old_stage_name ? firstStage.new_stage_name : 'Pre-Opportunity'
}

function groupedResearch(research: ResearchActivityStage[]) {
  return research.map((r) => {
    const stageTs = dayjs(r.stage.triggered_at)
    const grouped = {}
    const keys = new Set<string>()

    for (const a of r.activities) {
      const diff = dayjs.duration(stageTs.diff(a.created_at)).humanize()
      keys.add(diff)
      grouped[diff] = grouped[diff] || { count: 0, users: [] }
      grouped[diff].count += 1
      grouped[diff].users.push(a.user?.name || a.user?.email || 'Deactivated user')
    }

    return {
      stage: r.stage,
      details: Array.from(keys).map((k) => ({
        count: grouped[k]?.count || 0,
        users: Array.from(new Set(grouped[k]?.users || [])),
        diff: k
      }))
    }
  })
}

interface SortableHeaderProps extends TableColumnHeaderProps {
  sortBy: string
  currentSort?: string
  direction?: 'asc' | 'desc'
}

function SortableHeader({ sortBy, direction, currentSort, children, ...props }: SortableHeaderProps) {
  const sortIcons = { asc: IconSortAscending, desc: IconSortDescending }
  const isSorted = currentSort === sortBy
  const SortIcon = direction && isSorted ? sortIcons[direction] : IconSelector

  const onToggleSort = useCallback(() => {
    if (currentSort !== sortBy) {
      Router.visit(
        mergeParams(window.location.toString(), {
          sort: `${sortBy}:asc`
        })
      )
    } else if (direction === 'asc') {
      Router.visit(
        mergeParams(window.location.toString(), {
          sort: `${sortBy}:desc`
        })
      )
    } else {
      Router.visit(
        mergeParams(window.location.toString(), {
          sort: undefined
        })
      )
    }
  }, [sortBy, direction, currentSort])

  return (
    <Th {...props}>
      <Button
        size="xs"
        variant="ghost"
        marginX={-2}
        color={isSorted ? 'gray.900' : 'inherit'}
        role="group"
        _hover={{ color: 'gray.800' }}
        iconSpacing={1}
        rightIcon={
          <Icon
            as={SortIcon}
            boxSize={4}
            color={isSorted ? 'inherit' : 'gray.500'}
            _groupHover={{ color: 'gray.800' }}
            transition="inherit"
          />
        }
        onClick={onToggleSort}
      >
        <Text lineHeight={1}>{children}</Text>
      </Button>
    </Th>
  )
}

interface OpportunityAmountFieldPickerProps {
  size: 'sm' | 'md' | 'lg'
  selectedField: string
  fields: CrmField[]
  app: 'salesforce' | 'hubspot'
  onChange: (field: string | undefined | null) => void
}

function OpportunityAmountFieldPicker({
  size,
  selectedField,
  fields,
  app,
  onChange
}: OpportunityAmountFieldPickerProps) {
  const selected = fields.find((f) => f.name === selectedField)

  return (
    <ComboboxWithSearch
      inputProps={{
        size
      }}
      popperOptions={{
        matchWidth: false,
        placement: 'bottom-end'
      }}
      popoverProps={{
        maxWidth: '300px'
      }}
      items={fields}
      placeholder="Select amount field"
      selectedItem={selected || null}
      onChange={(field) => onChange(field?.name)}
      filterItem={(a, val) =>
        a.label.toLowerCase().includes(val.toLowerCase()) || a.name.toLowerCase().includes(val.toLowerCase())
      }
      itemToString={(item) => item?.label || ''}
      itemRenderer={(item) => {
        return <FieldRow {...item} app={app} />
      }}
      selectButtonRenderer={(item) => {
        return <FieldRow {...item} app={app} isToggleButton />
      }}
    />
  )
}

interface FieldRowProps {
  item?: any
  app?: 'salesforce' | 'hubspot'
  isToggleButton?: boolean
}

function FieldRow({ item, app, isToggleButton }: FieldRowProps) {
  if (!item) {
    return (
      <Text color="gray.700" fontSize="sm">
        Amount
      </Text>
    )
  }

  return (
    <Flex flex="1" width="100%" fontSize="sm" alignItems="center" gap={2}>
      {app === 'salesforce' ? (
        <SalesforceIcon boxSize={4} color="salesforce.500" />
      ) : (
        <HubSpotIcon boxSize={4} color="hubspot" />
      )}
      <Text flex="1 1 auto">{item.label}</Text>
      {!isToggleButton && (
        <Text color="gray.400" fontSize="xs">
          {item.type}
        </Text>
      )}
    </Flex>
  )
}
