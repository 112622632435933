import { usePlayItemAIRecommendationsStream } from '@app/components/data/use-ai-recommendations'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  StackProps,
  Text,
  Tooltip,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconPlayerPlay } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import { AIAgent } from '../../../../types/AIAgent'
import { Company, ProfileRecord } from '../../../../types/Profile'
import { agentMetricOrigin } from '../../../data/use-ai-agent-metrics'
import { useCreateAIAgent, useUpdateAIAgent } from '../../../data/use-ai-agents'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { AutosizedTextarea } from '../../../ui/AutosizedTextarea'
import { GrayCard } from '../../../ui/Card'
import { CompanySelector } from '../../../ui/CompanySelector'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { projectPath } from '../../../ui/ProjectsContext'
import { SegmentedControl } from '../../../ui/SegmentedControl'
import SelectInput from '../../../ui/SelectInput'
import TagsInput from '../../../ui/TagInput'
import { PartialVisitor, VisitorSelector } from '../../../ui/VisitorSelector'
import { Toggle } from '../../accounts/components/Toggle'
import { dataTypeIcons } from '../../field_definitions/components/FieldDefinitionModal'
import { AIAgentResponse } from './AIAgentResponse'
import { ResearchProgress } from './ResearchProgress'

const TARGET_OPTIONS = [
  {
    label: 'Companies',
    value: 'companies',
    info: 'Research companies and organizations'
  },
  {
    label: 'People',
    value: 'people',
    info: 'Research individual people'
  }
]

const RESPONSE_TYPE_OPTIONS = [
  {
    label: 'String',
    value: 'string',
    info: 'Free-form text response'
  },
  {
    label: 'Number',
    value: 'number',
    info: 'Numerical value response'
  },
  {
    label: 'Currency',
    value: 'currency',
    info: 'Monetary value response'
  },
  {
    label: 'Percent',
    value: 'percent',
    info: 'Percentage value response'
  },
  {
    label: 'Boolean',
    value: 'boolean',
    info: 'Yes/No response'
  },
  {
    label: 'Date',
    value: 'date',
    info: 'Date value response'
  },
  {
    label: 'List',
    value: 'list',
    info: 'Response as a bulleted list of items'
  },
  {
    label: 'Picklist',
    value: 'picklist',
    info: 'Selection from predefined options'
  }
]

const MODEL_OPTIONS = [
  {
    label: 'Claude 3.5 Sonnet',
    value: 'claude-3-5-sonnet-latest',
    info: 'Use the Claude 3.5 Sonnet API to search the web for answers. This model is great for complex questions that require more reasoning.'
  },
  {
    label: 'Claude 3.5 Haiku',
    value: 'claude-3-5-haiku-latest',
    info: 'Use the Claude 3.5 Haiku API to search the web for answers. This a well balanced model for most tasks.'
  },
  {
    label: 'GPT 4o (Comprehensive)',
    value: 'gpt-4o',
    info: 'For more complex tasks, or tasks that require better fact checking. Use this for more nuanced research that requires more reasoning.'
  },
  {
    label: 'GPT-4o-mini (Faster)',
    value: 'gpt-4o-mini',
    info: 'Great for most tasks. We recommend using this model in 90% of cases. Responses will return faster.'
  },
  {
    label: 'Perplexity',
    value: 'perplexity',
    info: 'Use the Perplexity API to search the web for answers. This model gives you fast, straightforward answers'
  },
  {
    label: 'Perplexity Reasoning (DeepSeek R1)',
    value: 'perplexity-reasoning',
    info: 'Uses the latest Perplexity model that is backed by DeepSeek R1 to provide more sources and reasoning.'
  },
  {
    label: 'Perplexity Pro',
    value: 'perplexity-pro',
    info: 'Use the Perplexity API to search the web for answers. This model tackles complex questions that need deeper research and provides more sources.'
  }
]

const TestAgentButton = ({ isDisabled, runAgent }: { isDisabled: boolean; runAgent: () => void }) => (
  <Tooltip label="Test the agent using the question and model you provided.">
    <Button
      isDisabled={isDisabled}
      leftIcon={<IconPlayerPlay size={16} />}
      iconSpacing={1.5}
      onClick={runAgent}
      as={Button}
      size="sm"
      variant="outline"
      minH={8}
      colorScheme="purple"
    >
      Test Agent
    </Button>
  </Tooltip>
)

const PreviewResponseContainer = ({ children, ...props }: { children: React.ReactNode } & StackProps) => {
  return (
    <Stack borderWidth="1px" borderRadius="lg" p={4} background="gray.50" spacing={5} marginBottom={4} {...props}>
      <Text fontSize="12px" color="gray.400" textTransform="uppercase" fontWeight="semibold">
        Preview Run
      </Text>
      {children}
    </Stack>
  )
}

interface Props {
  ai_agent: AIAgent
  errors?: {
    [key: string]: string[]
  }
  onSubmit?: (agent: AIAgent) => Promise<void>
  onCancel?: () => void
  showTarget?: boolean
  showDeleteOption?: boolean
  company?: Company
  profile?: ProfileRecord | PartialVisitor
  setSkipCache?: (skipCache: boolean) => void
  layout?: 'vertical' | 'horizontal'
}

export function AIAgentForm({
  ai_agent,
  errors,
  onSubmit,
  onCancel,
  showTarget = true,
  showDeleteOption = true,
  company,
  profile,
  setSkipCache,
  layout = 'vertical'
}: Props) {
  const [agent, setAgent] = useState(ai_agent)
  const [submitting, setSubmitting] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [name, setName] = React.useState(agent?.name ?? '')
  const [model, setModel] = React.useState(agent?.model || 'claude-3-5-sonnet-latest')
  const [target, setTarget] = React.useState(agent?.target || 'companies')
  const [responseType, setResponseType] = React.useState(agent?.response_type || '')
  const [responseOptions, setResponseOptions] = React.useState<string[]>(agent.response_options || [])

  const nameError = name === (agent?.name || '') ? errors?.name?.join(', ') : ''
  const [selectedCompany, setSelectedCompany] = useState(company)
  const [selectedPerson, setSelectedPerson] = useState(profile)

  const [question, setQuestion] = React.useState(agent?.question ?? '')
  const questionError = question === (agent?.question || '') ? errors?.question?.join(', ') : ''

  const [loadingResponse, setLoadingResponse] = useState(false)
  const [response, setResponse] = useState<any>(null)
  const [progressEvents, setProgressEvents] = useState<any[]>([])
  const runId = React.useRef<string>(crypto.randomUUID())

  usePlayItemAIRecommendationsStream(runId.current, {
    onContent: (message) => {
      if (message?.content_type === 'response') {
        setProgressEvents([])
        setLoadingResponse(false)
      } else {
        setProgressEvents((prev) => [...prev, message])
      }
    },
    onTool: (message) => {
      setProgressEvents((prev) => [...prev, message])
    }
  })

  const runAgent = useCallback(async () => {
    setLoadingResponse(true)
    setResponse(null)
    setProgressEvents([])

    try {
      const path = projectPath(`/assistants/preview`)
      const params = {
        question,
        model,
        assistant_id: agent?.id,
        skip_cache: 'true',
        origin: agentMetricOrigin(),
        run_id: runId.current,
        response_type: responseType,
        response_options: responseOptions || []
      } as any

      if (selectedCompany) {
        params.domain = selectedCompany?.domain
      } else if (selectedPerson) {
        params.profile_id = selectedPerson?.id
      }

      await post(path, params).then((res) => {
        setResponse(res)
      })
      setAgent({ ...agent, name, question, model, target })
    } catch (err) {
      toast.error('Error running agent')
      setLoadingResponse(false)
    }
  }, [selectedCompany, question, model, selectedPerson, target, agent, name, responseOptions, responseType])

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      setSubmitting(true)

      if (onSubmit) {
        event.preventDefault()
        await onSubmit({
          id: agent?.id,
          slug: agent?.slug,
          name,
          question,
          target,
          model,
          response_type: responseType,
          response_options: Array.isArray(responseOptions) ? responseOptions : []
        })
      }
      setSubmitting(false)
    },
    [onSubmit, agent, name, question, model, target, responseType, responseOptions]
  )

  return (
    <Stack>
      <Flex gap={5}>
        <Stack w={layout === 'horizontal' ? '50%' : '100%'}>
          <form
            action={projectPath(`/settings/agents/${agent?.id || ''}`)}
            method="POST"
            onSubmit={(event) => {
              event.preventDefault()
              event.stopPropagation()
              handleSubmit(event)
            }}
            id="ai-agent-form"
          >
            <AuthenticityToken />
            {agent?.id && <input type="hidden" name="_method" value="PATCH" />}

            <Stack spacing={5} w="100%">
              <FormControl id="ai_agent[name]" isInvalid={!!nameError} isRequired>
                <FormLabel>Agent goal</FormLabel>
                <Input
                  type="text"
                  bg="white"
                  name="ai_agent[name]"
                  placeholder="e.g. Company Products/Services"
                  size="sm"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                />
                <FormErrorMessage>{nameError}</FormErrorMessage>
              </FormControl>

              <FormControl id="ai_agent[question]" isInvalid={!!questionError} isRequired>
                <FormLabel>Question/Prompt</FormLabel>
                <FormHelperText>
                  Just ask the question you want answered &ndash; no need to provide instructions for how to do the
                  task!
                </FormHelperText>
                <AutosizedTextarea
                  name="ai_agent[question]"
                  placeholder="e.g. What are the company's products and services?"
                  size="sm"
                  bg="white"
                  minRows={4}
                  maxRows={8}
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value)
                    setSkipCache?.(true)
                  }}
                />
                <FormErrorMessage>{questionError}</FormErrorMessage>
              </FormControl>
              {showTarget && (
                <FormControl id="ai_agent[target]" isRequired>
                  <FormLabel>Research type</FormLabel>
                  <SegmentedControl size="sm" display={'flex'}>
                    <Button
                      type="button"
                      isActive={target === 'companies'}
                      onClick={() => {
                        setTarget('companies')
                        setSelectedPerson(undefined)
                      }}
                      flex="1"
                    >
                      Companies
                    </Button>
                    <Button
                      flex="1"
                      type="button"
                      isActive={target === 'people'}
                      onClick={() => {
                        setTarget('people')
                        setSelectedCompany(undefined)
                      }}
                    >
                      People
                    </Button>
                  </SegmentedControl>
                  <input type="hidden" name="ai_agent[target]" value={target} defaultValue={target} />
                  <FormHelperText>{TARGET_OPTIONS.find((option) => option.value === target)?.info}</FormHelperText>
                </FormControl>
              )}

              <FormControl id="ai_agent[response_type]">
                <FormLabel>Response Type</FormLabel>
                <SelectInput
                  size="sm"
                  items={RESPONSE_TYPE_OPTIONS.map((option) => option.value)}
                  selectedItem={responseType}
                  popperOptions={{
                    matchWidth: true
                  }}
                  onSelectedItemChange={({ selectedItem }) => {
                    setResponseType(selectedItem)
                    setSkipCache?.(true)
                  }}
                  itemRenderer={(item) => {
                    const option = RESPONSE_TYPE_OPTIONS.find((opt) => opt.value === item)
                    return (
                      <Flex alignItems="center" gap={2} fontSize="sm">
                        <Icon
                          flex="none"
                          as={dataTypeIcons[item] || dataTypeIcons.string}
                          boxSize={4}
                          color="gray.600"
                        />
                        <Text fontWeight="medium" textTransform="capitalize">
                          {option?.label || item}
                        </Text>
                      </Flex>
                    )
                  }}
                />
                <FormHelperText>
                  {RESPONSE_TYPE_OPTIONS.find((option) => option.value === responseType)?.info ||
                    'Specify how the AI should format its answer'}
                </FormHelperText>

                <Box mt={4}>
                  {responseType === 'picklist' && (
                    <>
                      <FormLabel fontSize="sm">Response Options</FormLabel>
                      <TagsInput
                        initialTags={Array.isArray(responseOptions) ? responseOptions.map(String) : []}
                        onChange={(tags) => {
                          // Set tags directly as strings
                          setResponseOptions(tags)
                          setSkipCache?.(true)
                        }}
                        colorScheme="purple"
                        placeholder="Type and press Enter to add"
                        inputProps={{ size: 'sm' }}
                      />
                    </>
                  )}
                </Box>
              </FormControl>

              <Toggle title={<Heading size="xs">Advanced Options</Heading>}>
                <Stack as={GrayCard} p={4}>
                  <FormControl id="ai_agent[model]" isRequired>
                    <FormLabel>Model</FormLabel>
                    <Select
                      name="ai_agent[model]"
                      size="sm"
                      bg="white"
                      value={model}
                      onChange={(e) => {
                        setModel(e.target.value)
                        setSkipCache?.(true)
                      }}
                    >
                      {MODEL_OPTIONS.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <Text fontSize="sm" color="gray.500" mt={2}>
                    {MODEL_OPTIONS.find((option) => option.value === model)?.info}
                  </Text>
                </Stack>
              </Toggle>
            </Stack>
          </form>

          <DeleteConfirmation
            isOpen={isDeleteOpen}
            onClose={() => setIsDeleteOpen(false)}
            deletePath={projectPath(`/settings/agents/${agent?.id}`)}
            title="Delete AI Agent"
            confirmLabel="Delete Agent"
          >
            Are you sure you want to delete this AI agent? This action cannot be undone.
          </DeleteConfirmation>
        </Stack>

        {layout === 'horizontal' && (
          <PreviewResponseContainer w="50%" position="relative">
            {target === 'companies' && (
              <CompanySelector
                selectedCompany={selectedCompany}
                onChange={(company) => setSelectedCompany(company as Company)}
              />
            )}

            {target === 'people' && (
              <VisitorSelector
                selectedVisitor={selectedPerson as PartialVisitor}
                onChange={(p) => {
                  setSelectedPerson(p as PartialVisitor)
                }}
              />
            )}

            <TestAgentButton
              isDisabled={loadingResponse || !question || !model || (!selectedCompany && !selectedPerson)}
              runAgent={runAgent}
            />

            {loadingResponse && progressEvents.length > 0 && (
              <Box position="relative" mt={2} mb={2} width="100%" overflow="hidden">
                <ResearchProgress toolEvents={progressEvents} />
              </Box>
            )}

            <AIAgentResponse
              response={response}
              isLoading={false}
              agent={agent}
              sourcesPerRow={1}
              overflowY="auto"
              maxH={'500px'}
            />
          </PreviewResponseContainer>
        )}
      </Flex>

      {layout === 'vertical' && (response || loadingResponse) && (
        <PreviewResponseContainer marginBottom={8} marginTop={8}>
          {loadingResponse && progressEvents.length > 0 && (
            <Box position="relative" mt={2} mb={2} width="100%" overflow="hidden">
              <ResearchProgress toolEvents={progressEvents} showTitle={false} />
            </Box>
          )}
          <AIAgentResponse response={response} isLoading={false} agent={agent} />
        </PreviewResponseContainer>
      )}

      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={3}
        pt={5}
        borderTop="1px solid"
        borderColor="gray.200"
      >
        {layout === 'vertical' && (
          <TestAgentButton isDisabled={loadingResponse || !question || !model} runAgent={runAgent} />
        )}

        {agent?.id && showDeleteOption && (
          <Button size="sm" variant="outline" colorScheme="red" onClick={() => setIsDeleteOpen(true)}>
            Delete
          </Button>
        )}

        <Flex alignItems="center" gap={3} marginLeft="auto">
          {onCancel ? (
            <Button size="sm" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          ) : (
            <Button size="sm" variant="outline" as={Link} href={projectPath('/settings/agents')}>
              Cancel
            </Button>
          )}

          <Button
            type="submit"
            size="sm"
            colorScheme="purple"
            isLoading={submitting}
            isDisabled={loadingResponse}
            form="ai-agent-form"
          >
            {agent?.id ? 'Update agent' : 'Create agent'}
          </Button>
        </Flex>
      </Flex>
    </Stack>
  )
}

interface EditAgentModalProps extends UseDisclosureProps {
  ai_agent: AIAgent
  onChange?: (ai_agent: AIAgent) => void
}

export function EditAgentModal({ ai_agent, onChange, ...props }: EditAgentModalProps) {
  const { isOpen, onClose } = useDisclosure(props)
  const { mutateAsync: createAIAgent, isPending: creating } = useCreateAIAgent()
  const { mutateAsync: updateAIAgent, isPending: updating } = useUpdateAIAgent()

  const isLoading = creating || updating

  const onSubmit = useCallback(
    async (agent: AIAgent) => {
      try {
        let res: { ai_agent: AIAgent }

        if (agent?.id) {
          res = await updateAIAgent({
            id: agent.id,
            name: agent.name,
            question: agent.question,
            model: agent.model,
            target: agent.target,
            response_type: agent.response_type,
            response_options: agent.response_options || []
          })
        } else {
          res = await createAIAgent({
            name: agent.name,
            question: agent.question,
            model: agent.model,
            target: agent.target,
            response_type: agent.response_type,
            response_options: agent?.response_options || []
          })
        }
        onChange?.(res.ai_agent)
        onClose()
        toast.success('Agent saved successfully!')
      } catch (err) {
        toast.error('Error saving agent', { description: (err as any)?.message })
      }
    },
    [onClose, createAIAgent, updateAIAgent, onChange]
  )

  return (
    <Modal
      size="4xl"
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      isCentered
    >
      <ModalOverlay zIndex="overlay" />
      <ModalContent zIndex="popover">
        <ModalCloseButton isDisabled={isLoading} />
        <ModalHeader fontSize="lg">{ai_agent?.id ? 'Edit your' : 'Create an'} AI agent</ModalHeader>
        <ModalBody py="4">
          <AIAgentForm ai_agent={ai_agent} onSubmit={onSubmit} onCancel={onClose} layout="horizontal" />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface EditAgentDrawerProps extends UseDisclosureProps {
  ai_agent: AIAgent
  onChange: (agent: AIAgent, skipCache: boolean) => void
  showTarget: boolean
  showDeleteOption: boolean
  company?: Company
  profile?: ProfileRecord | PartialVisitor
}

export const EditAgentDrawer = (props: EditAgentDrawerProps) => {
  const { isOpen, onClose } = useDisclosure(props)
  const { mutateAsync: createAIAgent, isPending: creating } = useCreateAIAgent()
  const { mutateAsync: updateAIAgent, isPending: updating } = useUpdateAIAgent()
  const [skipCache, setSkipCache] = useState(false)

  const isLoading = creating || updating

  const onSubmit = useCallback(
    async (agent: AIAgent) => {
      try {
        let res: { ai_agent: AIAgent }

        if (agent?.id) {
          res = await updateAIAgent({
            id: agent.id,
            name: agent.name,
            question: agent.question,
            model: agent.model,
            target: agent.target,
            response_type: agent.response_type,
            response_options: agent.response_options || []
          })
        } else {
          res = await createAIAgent({
            name: agent.name,
            question: agent.question,
            model: agent.model,
            target: agent.target,
            response_type: agent.response_type,
            response_options: agent.response_options || []
          })
        }
        onClose()
        props.onChange?.(res.ai_agent, skipCache)
        toast.success('Agent saved successfully!')
      } catch (err) {
        toast.error('Error saving agent', { description: (err as any)?.message })
      }
    },
    [onClose, props, updateAIAgent, createAIAgent, skipCache]
  )

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody py={6}>
          <AIAgentForm
            ai_agent={props.ai_agent}
            onSubmit={onSubmit}
            onCancel={onClose}
            showTarget={props.showTarget}
            showDeleteOption={props.showDeleteOption}
            company={props.company}
            profile={props.profile}
            setSkipCache={setSkipCache}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
