import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Code,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Progress,
  Stack,
  Text,
  useClipboard,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowLeft, IconArrowRight, IconChevronRight, IconCopy } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { subscribeToChannel, SubscriptionEmitter } from '../../../../channels/generic_channel'
import dayjs from '../../../../lib/dayjs'
import DOMPurify from 'dompurify'
import { Crm } from '@type/Crm'
import { Project } from '@type/Project'
import { Card } from '@ui/Card'
import GoogleTagManagerLogo from '@ui/GoogleTagManagerLogo.svg'
import { projectPath, useCurrentProject } from '@ui/ProjectsContext'
import { useCurrentUser } from '@ui/UserContext'
import Pulse from '@ui/Pulse'
import SegmentLogo from '@ui/SegmentLogo.svg'
import { ChecksRecord, StepType } from '../show'
import clearbitImg from './clearbit.png'
import { ClearbitForm } from './ClearbitForm'
import crmImg from './crm.png'
import doneImg from './done.png'
import { HeroImage } from './HeroImage'
import { IntegrationButton, IntegrationButtonProps } from './IntegrationButton'
import { InviteModal } from './InviteModal'
import { konfetti } from './konfetti'
import pixelImg from './pixel.png'
import { PixelSnippet } from './PixelSnippet'
import signalsImg from './signals.png'
import { SignalsForm } from './SignalsForm'
import slackImg from './slack.png'
import { SlackForm } from './SlackForm'
import { GoalsStep } from './GoalsStep'
import { OnboardingFlow } from './OnboardingFlow'

interface Props {
  crm?: Crm
  sync_status?: {
    total: number
    synced: number
    percent: number
    last_synced: string
  }
  members?: number
  project?: Pick<Project, 'id' | 'name' | 'slug'>
  authorized_projects?: Array<Pick<Project, 'name' | 'slug' | 'domain' | 'potential_domain' | 'member_count'>>
  suggested_authorized_domain?: string
  catalog: Record<'salesforce' | 'hubspot' | 'slack', IntegrationButtonProps>
  onboarding_checks: ChecksRecord

  flow: OnboardingFlow
}

function syncMessage(sync: Props['sync_status']) {
  if (!sync) {
    return ''
  }

  if (sync.last_synced && sync.percent >= 100) {
    return `Synced - ${dayjs(sync.last_synced).calendar()}`
  } else {
    return `Syncing Data - ${sync.percent}%`
  }
}

function getSyncStatus(status: Props['sync_status']) {
  if (!status || status.percent === 0) {
    return 'not-started'
  }

  if (status.percent >= 100) {
    return 'complete'
  }

  return 'in-progress'
}

export function SetupProject(props: Props) {
  const step = useMemo(() => props.flow.currentStep(), [props.flow])
  const flow = props.flow

  const [selectedGoalsState, setSelectedGoalsState] = React.useState<string[]>(() => {
    const goalsCheck = props.onboarding_checks?.goals_set
    if (goalsCheck?.value && Array.isArray(goalsCheck.value)) {
      return goalsCheck.value as string[]
    }
    return []
  })

  const handleGoalsUpdate = (goals: string[]) => {
    setSelectedGoalsState(goals)
  }
  const { hasCopied, onCopy } = useClipboard(
    (props.project as Project).default_public_api_key?.key ?? props.project?.slug ?? ''
  )

  const crmConnected = props.onboarding_checks.crm_connected
  const pixelInstalled = props.onboarding_checks.pixel_installed
  const clearbitConnected = props.onboarding_checks.clearbit_connected
  const signalsCreated = props.onboarding_checks.signals_created
  const slackConnected = props.onboarding_checks.slack_connected

  const initialDataFlowing = pixelInstalled ? pixelInstalled.complete : false

  const [dataFlowing, setDataFlowing] = React.useState(initialDataFlowing)
  const [syncDetails, setSyncDetails] = React.useState(props.sync_status)
  const [syncStatus, setSyncStatus] = React.useState(getSyncStatus(props.sync_status))
  const project = useCurrentProject()
  const crmStatusSubscription = React.useRef<SubscriptionEmitter>()
  const pixelStatusSubscription = React.useRef<SubscriptionEmitter>()
  const syncProgress = syncDetails?.percent ?? 0
  const { isOpen, onClose, onOpen } = useDisclosure()
  const user = useCurrentUser()

  React.useEffect(() => {
    if (!project?.slug || !props.crm?.clean_module_name || syncStatus === 'complete') {
      return
    }

    crmStatusSubscription.current = subscribeToChannel({
      channel: 'SyncStatusChannel',
      project_slug: project.slug,
      integration: props.crm.clean_module_name
    })

    const onData = (message: any) => {
      if (message.action === 'status') {
        setSyncDetails((prev) => {
          // only update if the percent is going up!
          if (!prev?.percent || message.data.percent >= prev.percent) {
            return message.data
          }
          return prev
        })

        if (message.data.percent === 100) {
          // With a slight delay so users can see progress animate to 100%
          setTimeout(() => {
            setSyncStatus('complete')
          }, 500)
          crmStatusSubscription.current?.off('received', onData)
          crmStatusSubscription.current?.unsubscribe()
          crmStatusSubscription.current = undefined
        } else {
          setSyncStatus('in-progress')
        }
      }
    }

    crmStatusSubscription.current.on('received', onData)

    return () => {
      crmStatusSubscription.current?.off('received', onData)
      crmStatusSubscription.current?.unsubscribe()
      crmStatusSubscription.current = undefined
    }
  }, [project?.slug, props.crm?.clean_module_name, syncStatus])

  React.useEffect(() => {
    if (!project?.slug || syncStatus === 'not-started' || dataFlowing) {
      return
    }

    pixelStatusSubscription.current = subscribeToChannel({
      channel: 'SyncStatusChannel',
      project_slug: project.slug,
      integration: 'koala' // "koala" pixel
    })

    const onData = (message: any) => {
      if (message.action === 'status') {
        setDataFlowing(message.data.connected)

        // unsubscribe once pixel is connected
        if (message.data.connected) {
          pixelStatusSubscription.current?.off('received', onData)
          pixelStatusSubscription.current?.unsubscribe()
          pixelStatusSubscription.current = undefined
        }
      }
    }

    pixelStatusSubscription.current.on('received', onData)

    return () => {
      pixelStatusSubscription.current?.off('received', onData)
      pixelStatusSubscription.current?.unsubscribe()
      pixelStatusSubscription.current = undefined
    }
  }, [project?.slug, dataFlowing, syncStatus])

  React.useEffect(() => {
    const isSetupPage = location.pathname.includes('/setup')
    if (step === 'done' && !isSetupPage) {
      setTimeout(() => konfetti(), 250)
    }
  }, [step])

  if (!project) {
    return null
  }

  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        alignSelf="center"
        gap={8}
        width="100%"
        maxWidth={600}
        paddingTop="40px"
        paddingBottom="40px"
        marginX="auto"
        position="relative"
        zIndex={1}
      >
        {step === 'goals_set' && (
          <GoalsStep flow={flow} selectedGoals={selectedGoalsState} onUpdateGoals={handleGoalsUpdate} />
        )}
        {step === 'crm_connected' && (
          <Step
            cardProps={{ p: 0, maxW: '600px' }}
            onBack={flow.selectedStepIndex() !== 0 ? () => flow.prev() : undefined}
          >
            <HeroImage src={crmImg} h="240px" objectFit="contain" objectPosition="top" />
            <Stack px="8" spacing="8" pb="8">
              <Stack>
                <Heading size="md">{crmConnected.name}</Heading>
                <Text
                  fontSize="sm"
                  color="gray.600"
                  marginY={6}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(crmConnected.description) }}
                ></Text>
              </Stack>
              {props.crm ? (
                <>
                  <Flex direction="column" gap={4}>
                    <Flex gap={4} alignItems="center">
                      <Image src={props.crm.logo} boxSize={8} />
                      <Box>
                        <Text fontSize="sm" fontWeight="medium">
                          Connected to {props.crm.title}
                        </Text>
                        <Text fontSize="xs" color="gray.600">
                          {syncMessage(syncDetails)}
                        </Text>
                      </Box>
                    </Flex>
                    {syncStatus !== 'complete' && (
                      <Progress
                        flex="none"
                        width="100%"
                        marginX="auto"
                        colorScheme="gray"
                        value={syncProgress || 1}
                        max={100}
                        size="xs"
                        rounded="full"
                        isAnimated
                        hasStripe={syncProgress > 0 && syncProgress < 100}
                        isIndeterminate={syncStatus === 'not-started'}
                        css={{
                          '& div[role=progressbar]': {
                            transition: syncProgress > 0 ? 'width 150ms cubic-bezier(0, 0, 0.2, 1)' : undefined
                          }
                        }}
                      />
                    )}
                  </Flex>
                  <Button
                    colorScheme="purple"
                    flex="none"
                    size="lg"
                    fontSize="md"
                    width="full"
                    onClick={() => flow.next()}
                  >
                    Continue
                  </Button>
                </>
              ) : (
                <Flex direction="column" gap={4} w="100%">
                  <HStack w="100%" justifyContent={'center'} spacing="4">
                    <IntegrationButton {...props.catalog['salesforce']} />
                    <IntegrationButton {...props.catalog['hubspot']} />
                  </HStack>
                  <SkipButton
                    step="crm_connected"
                    buttonProps={{
                      variant: 'link',
                      marginTop: 4
                    }}
                  >
                    Connect later
                  </SkipButton>
                </Flex>
              )}
            </Stack>
          </Step>
        )}
        {step === 'pixel_installed' && (
          <Step cardProps={{ maxW: '600px', p: 0, position: 'relative' }} onBack={() => flow.prev()}>
            <HStack position="absolute" top="4" right="4" bg="white" px="3" py="1" rounded="full" shadow={'sm'}>
              <Flex alignItems="center" gap={4}>
                <Text fontSize="sm" color={dataFlowing ? 'green.400' : 'orange.400'}>
                  {dataFlowing ? 'Connected!' : 'Waiting for events'}
                </Text>
              </Flex>
              <Pulse size={2.5} background={dataFlowing ? 'green.300' : 'orange.300'} />
            </HStack>

            <HeroImage src={pixelImg} h="240px" />
            <Stack px="8" spacing="8" pb="8">
              <Stack>
                <Flex justifyContent="space-between" alignItems="center">
                  <Heading size="md">{pixelInstalled.name}</Heading>
                </Flex>
                <Text fontSize="sm" color="gray.600" marginY={6}>
                  {pixelInstalled.description}
                </Text>
              </Stack>
              {dataFlowing ? (
                <>
                  <Text fontSize="sm" color="gray.600" textAlign="center" marginY={2}>
                    🤖 We detected events from your pixel! You're all set.
                  </Text>
                  <Button
                    colorScheme="purple"
                    flex="none"
                    size="lg"
                    fontSize="md"
                    width="full"
                    onClick={() => flow.next()}
                  >
                    Continue
                  </Button>
                </>
              ) : (
                <>
                  <PixelSnippet project={project} />

                  <Flex position="relative" justifyContent="center">
                    <Divider position="absolute" top="50%" left={0} right={0} />

                    <Text
                      display="inline-flex"
                      fontSize="xs"
                      color="gray.500"
                      textAlign="center"
                      paddingX={3}
                      background="white"
                      zIndex={1}
                    >
                      or install with your existing stack
                    </Text>
                  </Flex>

                  <Flex direction="column" gap={3}>
                    <InstallCard
                      title="Install via Segment"
                      description={
                        <>
                          Copy your Public API Key to install without code:{' '}
                          <HStack>
                            <Code fontSize="inherit">{project.default_public_api_key?.key ?? project.slug}</Code>
                            {hasCopied && (
                              <Button
                                size="xs"
                                aria-label="Copy"
                                leftIcon={<IconCopy size={14} />}
                                onClick={(e) => {
                                  e.preventDefault()
                                  onCopy()
                                }}
                              >
                                Copied to clipboard
                              </Button>
                            )}
                            {!hasCopied && (
                              <IconButton
                                size="xs"
                                aria-label="Copy"
                                icon={<IconCopy size={14} />}
                                onClick={(e) => {
                                  e.preventDefault()
                                  onCopy()
                                }}
                              />
                            )}
                          </HStack>
                        </>
                      }
                      icon={<Image src={SegmentLogo} boxSize={5} />}
                      href="https://getkoala.com/docs/integrations/segment"
                    />
                    <InstallCard
                      title="Install via Google Tag Manager"
                      description="Follow our guide for installing Koala via GTM"
                      icon={<Image src={GoogleTagManagerLogo} boxSize={5} />}
                      href="https://getkoala.com/docs/integrations/google-tag-manager"
                    />
                    <SkipButton
                      step="pixel_installed"
                      buttonProps={{
                        w: '100%',
                        variant: 'ghost',
                        marginTop: 4
                      }}
                      rightIcon={<IconChevronRight size={14} />}
                    >
                      Install later
                    </SkipButton>
                  </Flex>
                </>
              )}
            </Stack>
          </Step>
        )}
        {step === 'clearbit_connected' && (
          <Step cardProps={{ maxW: '600px', p: 0, position: 'relative' }} onBack={() => flow.prev()}>
            <HeroImage src={clearbitImg} h="240px" />
            <Stack px="8" spacing="8" pb="8">
              <Flex justifyContent="space-between" alignItems="center">
                <Heading size="md">{clearbitConnected.name}</Heading>
              </Flex>
              <Stack spacing={4}>
                <Text fontSize="sm" color="gray.600">
                  Koala customers can get 10,000 free Clearbit Reveal calls per month and a generous pay-as-you go plan
                  when you need it, via Powered by Clearbit. You'll be able to see which companies are on your site,
                  even if they've never submitted an email.
                </Text>
                <Text fontSize="sm" color="gray.600">
                  If you've already purchased Clearbit Reveal, you'll be able to enter your own key later.
                </Text>
              </Stack>

              <Flex direction="column" gap={4}>
                <ClearbitForm skip={() => flow.next()} />
              </Flex>
            </Stack>
          </Step>
        )}
        {step === 'signals_created' && (
          <Step cardProps={{ p: 0, maxW: '600px' }} onBack={() => flow.prev()}>
            <HeroImage src={signalsImg} h="240px" />

            <Stack px="8" spacing="8" pb="8">
              <Heading size="sm">{signalsCreated.name}</Heading>
              <Text fontSize="sm" color="gray.600" marginY={6}>
                {signalsCreated.description}
              </Text>
              <SignalsForm skip={() => flow.next()} />
            </Stack>
          </Step>
        )}
        {step === 'slack_connected' && (
          <Step cardProps={{ p: 0, maxW: '600px' }} onBack={() => flow.prev()}>
            <HeroImage src={slackImg} h="240px" />
            <Stack px="8" spacing="8" pb="8">
              <SlackForm
                connected={slackConnected?.complete ?? false}
                skip={() => flow.next()}
                catalog={props.catalog}
              />
            </Stack>
          </Step>
        )}
        {step === 'done' && (
          <Step cardProps={{ p: 0 }}>
            <HeroImage src={doneImg} h="240px" />
            <Stack p="8" spacing="8" justifyContent={'center'}>
              <Heading size="lg" textAlign="center">
                You're all set!
              </Heading>
              <Text fontSize="sm" color="gray.600" textAlign="center">
                Nice work! Now Koala can start analyzing your CRM data and real-time visitor intent. We'll start
                overlaying valuable insights for your existing customers and prospects.
              </Text>
              <Button
                as={Link}
                href={user.featureEnabled('newOnboarding') ? projectPath('/setup') : projectPath('/accounts?range=all')}
                flex="none"
                size="lg"
                fontSize="md"
                width="full"
                colorScheme="purple"
              >
                Open Koala
              </Button>
            </Stack>
          </Step>
        )}
        {step !== 'done' && (
          <Text fontSize="sm" color="gray.500">
            Need a hand?{' '}
            <Button size="sm" variant="link" onClick={onOpen} color="purple.500">
              Invite a teammate
            </Button>{' '}
            or{' '}
            <Link href="mailto:support@getkoala.com" isExternal color="purple.500">
              send us a message
            </Link>
            .
          </Text>
        )}
      </Flex>
      <InviteModal project={project} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

type StepProps = React.PropsWithChildren<{}>

export function Step(props: StepProps & { cardProps?: BoxProps; onBack?: () => void }) {
  return (
    <>
      <Card
        w="100%"
        maxW={600}
        p={0}
        {...props.cardProps}
        position="relative"
        shadow={'none'}
        borderWidth={['0', '0', '1px']}
        borderColor={'gray.300'}
        rounded="lg"
      >
        {props.onBack && (
          <IconButton
            position={'absolute'}
            variant={'ghost'}
            _hover={{ bg: 'none' }}
            _active={{ bg: 'none' }}
            aria-label="Back"
            icon={<IconArrowLeft size="16" />}
            onClick={props.onBack}
          />
        )}
        <Flex display="flex" flexDirection="column" gap={8}>
          {props.children}
        </Flex>
      </Card>
    </>
  )
}

interface InstallCardProps {
  title: string
  icon: React.ReactNode
  description: React.ReactNode
  href: string
}

function InstallCard(props: InstallCardProps) {
  return (
    <Flex
      as="a"
      href={props.href}
      target="_blank"
      gap={3}
      alignItems="center"
      border="1px solid"
      borderColor="gray.200"
      paddingY={4}
      paddingX={6}
      rounded="md"
      transition="all 100ms ease-in-out"
      _hover={{
        transform: 'translateY(-2px)',
        shadow: 'sm',
        borderColor: 'gray.300'
      }}
    >
      <Box className="install-icon" flex="none">
        {props.icon}
      </Box>
      <Box flex="1">
        <Text fontSize="sm" fontWeight="medium" color="gray.700">
          {props.title}
        </Text>
        <Text fontSize="xs" color="gray.500">
          {props.description}
        </Text>
      </Box>
      <Icon className="install-go" as={IconArrowRight} boxSize={4} color="purple.500" />
    </Flex>
  )
}

interface SkipButtonProps {
  step: StepType
  children: React.ReactNode
  buttonProps?: ButtonProps
  rightIcon?: React.ReactElement
}

export function SkipButton({ step, children, buttonProps, rightIcon }: SkipButtonProps) {
  return (
    <Button
      as={Link}
      variant="link"
      flex="none"
      alignSelf="center"
      size="md"
      href={`?skip=${step}`}
      {...buttonProps}
      rightIcon={rightIcon}
    >
      {children}
    </Button>
  )
}
