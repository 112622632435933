import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Img,
  Input,
  List,
  ListItem,
  Text,
  ThemeTypings,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { IconChevronsRight, IconPlus, IconRepeatOff, IconX } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { Toggle } from '../../../accounts/components/Toggle'
import { KoalaField, SalesforceField } from '../show'
import { nanoid } from 'nanoid'
import { ComboboxWithSearch } from '@app/components/ui/ComboboxWithSearch'
import { SalesforceIcon } from '@app/components/ui/icons/SalesforceIcons'
import { channelLogos } from '@app/components/pages/follow_rules/components/delivery-setup'

interface Props {
  salesforceLayout: SalesforceField[]
  koalaLayout: KoalaField[]
  fieldPrefix: string
  hardcodedPrefix: string
  mapping?: MappingRow[]
  hardcoded?: HardcodedRow[]
  title?: React.ReactNode
  colorScheme?: ThemeTypings['colorSchemes']
  defaultIsOpen?: boolean
}

interface FieldPreviewProps {
  item: SalesforceField | KoalaField | null
  selectedItem?: SalesforceField | KoalaField | null
  channel?: string
}

export interface MappingRow {
  key?: string
  koala_field?: string
  salesforce_field?: string
}

export interface HardcodedRow {
  key?: string
  value?: string
  salesforce_field?: string
}

// Helper functions for field type compatibility
function getFieldTypeCompatibility(koalaField: KoalaField | null, salesforceField: SalesforceField | null): boolean {
  if (!koalaField || !salesforceField) return true

  // Basic type mapping between Koala and Salesforce
  const typeMap: Record<string, string[]> = {
    string: ['string', 'text', 'textarea', 'url', 'email', 'phone', 'picklist', 'multipicklist'],
    url: ['string', 'text', 'textarea', 'url'],
    number: ['double', 'currency', 'percent', 'int'],
    integer: ['double', 'currency', 'percent', 'int'],
    boolean: ['boolean', 'checkbox'],
    date: ['date', 'datetime'],
    object: ['reference', 'id'],
    array: ['multipicklist']
  }

  // Get the Salesforce compatible types for the Koala field type
  const compatibleTypes = typeMap[koalaField.type] || []
  return compatibleTypes.includes(salesforceField.type)
}

// Check if a Salesforce field is already used in mappings or hardcoded fields
function isFieldAlreadyUsed(
  fieldName: string,
  currentRowKey: string | undefined,
  mappings: MappingRow[],
  hardcoded: HardcodedRow[]
): boolean {
  // Check in mappings (excluding current row)
  const usedInMappings = mappings.some((row) => row.salesforce_field === fieldName && row.key !== currentRowKey)

  // Check in hardcoded fields (excluding current row)
  const usedInHardcoded = hardcoded.some((row) => row.salesforce_field === fieldName && row.key !== currentRowKey)

  return usedInMappings || usedInHardcoded
}

function FieldPreview({ item, channel = 'salesforce' }: FieldPreviewProps) {
  if (!item) {
    return (
      <HStack flex="1" fontSize={'sm'}>
        <IconRepeatOff size={14} />
        <Text color="gray.600">Not selected</Text>
      </HStack>
    )
  }

  return (
    <HStack flex="1" fontSize={'sm'}>
      {channel === 'salesforce' ? (
        <Box>
          <Img src={channelLogos[channel]} w="4" />
        </Box>
      ) : (
        <Img src={channelLogos[channel]} w="4" />
      )}
      <Text>{item.label}</Text>
      <Text color="gray.500" fontSize="xs">
        ({item.type})
      </Text>
    </HStack>
  )
}

function DisabledFieldPreview({
  item,
  channel = 'salesforce',
  reason = 'Incompatible type'
}: FieldPreviewProps & { reason?: string }) {
  const color = 'gray.500'

  if (!item) {
    return (
      <HStack flex="1" fontSize={'sm'}>
        <IconRepeatOff size={14} />
        <Text color="gray.600">Not selected</Text>
      </HStack>
    )
  }

  return (
    <Tooltip label={reason}>
      <List>
        <ListItem opacity={0.3} cursor="not-allowed" pointerEvents="none">
          <HStack flex="1" fontSize={'sm'}>
            {channel === 'salesforce' ? (
              <Box opacity={0.3}>
                <Icon as={SalesforceIcon} boxSize="4" color={color} />
              </Box>
            ) : (
              <Img src={channelLogos[channel]} w="4" opacity={0.3} />
            )}
            <Text color={color}>{item.label}</Text>
            <Text color={color} fontSize="xs">
              ({item.type})
            </Text>
          </HStack>
        </ListItem>
      </List>
    </Tooltip>
  )
}

function AlreadyUsedFieldPreview({ item, channel = 'salesforce' }: FieldPreviewProps) {
  return <DisabledFieldPreview item={item} channel={channel} reason="Already used elsewhere" />
}

export const FieldMapping: React.FunctionComponent<Props> = (props) => {
  const [rows, setRows] = useState<MappingRow[]>((props.mapping ?? []).map((row) => ({ ...row, key: nanoid() })))

  const [hardcoded, setHardcoded] = useState<HardcodedRow[]>(
    (props.hardcoded ?? []).map((row) => ({ ...row, key: nanoid() }))
  )

  const addRow = useCallback(() => {
    setRows((prevRows) => [...prevRows, { key: nanoid() }])
  }, [setRows])

  const removeRow = useCallback(
    (key?: string) => {
      setRows((prevRows) => prevRows.filter((row) => row.key !== key))
    },
    [setRows]
  )

  const addHardcoded = useCallback(() => {
    setHardcoded((prevRows) => [...prevRows, { key: nanoid() }])
  }, [setHardcoded])

  const removeHardcodedRow = useCallback(
    (key?: string) => {
      setHardcoded((prevRows) => prevRows.filter((row) => row.key !== key))
    },
    [setHardcoded]
  )

  return (
    <VStack
      w="100%"
      alignItems={'normal'}
      p="8"
      borderLeftWidth={'medium'}
      borderLeftColor={`${props.colorScheme}.300`}
      bg="gray.50"
    >
      <Toggle title={props.title} defaultIsOpen={props.defaultIsOpen}>
        <VStack px="2" py="4" w="100%" alignItems={'normal'} spacing="4">
          <HStack w="100%" justifyContent={'space-around'}>
            <Text fontWeight={'bold'} textTransform={'uppercase'} fontSize={'xs'} color="gray.500">
              Koala
            </Text>

            <Text fontWeight={'bold'} textTransform={'uppercase'} fontSize={'xs'} color="gray.500">
              Salesforce
            </Text>
          </HStack>

          <Box>
            <Heading size="xs">Mapped Fields</Heading>
          </Box>

          <VStack>
            {rows.map((row) => {
              const koalaField = props.koalaLayout.find((field) => field.name === row.koala_field)
              const salesforceField = props.salesforceLayout.find((field) => field.name === row.salesforce_field)

              return (
                <HStack key={row.key} w="100%" justifyContent={'space-between'}>
                  <Box flex={1}>
                    <input type="hidden" name={`${props.fieldPrefix}[][koala_field]`} value={row.koala_field || ''} />
                    <ComboboxWithSearch
                      items={props.koalaLayout}
                      placeholder="Select Koala field"
                      selectedItem={koalaField || null}
                      onChange={(field) => {
                        setRows((prevRows) =>
                          prevRows.map((r) => (r.key === row.key ? { ...r, koala_field: field?.name || '' } : r))
                        )
                      }}
                      filterItem={(a, val) => a.label.toLowerCase().includes(val.toLowerCase())}
                      itemToString={(item) => item?.label || ''}
                      itemRenderer={(item) => <FieldPreview {...item} channel="koala" />}
                      selectButtonRenderer={(item) => <FieldPreview {...item} channel="koala" />}
                    />
                  </Box>

                  <IconChevronsRight size={14} />

                  <Box flex={1}>
                    <input
                      type="hidden"
                      name={`${props.fieldPrefix}[][salesforce_field]`}
                      value={row.salesforce_field || ''}
                    />
                    <ComboboxWithSearch
                      items={props.salesforceLayout}
                      placeholder="Select Salesforce field"
                      selectedItem={salesforceField || null}
                      onChange={(field) => {
                        setRows((prevRows) =>
                          prevRows.map((r) => (r.key === row.key ? { ...r, salesforce_field: field?.name || '' } : r))
                        )
                      }}
                      filterItem={(a, val) => a.label.toLowerCase().includes(val.toLowerCase())}
                      itemToString={(item) => item?.label || ''}
                      itemRenderer={(item) => {
                        const koalaField = props.koalaLayout.find((field) => field.name === row.koala_field)
                        const isCompatible = getFieldTypeCompatibility(koalaField || null, item.item || null)
                        const isAlreadyUsed = item.item
                          ? isFieldAlreadyUsed(item.item.name, row.key, rows, hardcoded)
                          : false

                        if (!isCompatible) {
                          return <DisabledFieldPreview {...item} />
                        } else if (isAlreadyUsed) {
                          return <AlreadyUsedFieldPreview {...item} />
                        } else {
                          return <FieldPreview {...item} />
                        }
                      }}
                      selectButtonRenderer={(item) => {
                        // If the field is already used elsewhere but this is the current selection,
                        // we still show it but with the "already used" styling
                        if (item.item && isFieldAlreadyUsed(item.item.name, row.key, rows, hardcoded)) {
                          return <AlreadyUsedFieldPreview {...item} />
                        }
                        return <FieldPreview {...item} />
                      }}
                    />
                  </Box>

                  <IconButton
                    aria-label="Remove Field Mapping"
                    size="xs"
                    onClick={() => removeRow(row.key)}
                    variant="ghost"
                    color="gray.400"
                    _hover={{ color: 'gray.800' }}
                    icon={<IconX size={14} />}
                  />
                </HStack>
              )
            })}
          </VStack>

          <HStack justify={'flex-end'} py="4">
            <Button
              colorScheme={props.colorScheme}
              onClick={addRow}
              variant={'outline'}
              leftIcon={<IconPlus size="16" />}
              size="sm"
            >
              Field
            </Button>
          </HStack>

          <Divider />

          <Box>
            <Heading size="xs">Hardcoded Fields</Heading>
          </Box>

          <VStack>
            {hardcoded.length === 0 && <input type="hidden" name={`${props.hardcodedPrefix}`} value="" />}

            {hardcoded.map((row) => {
              const salesforceField = props.salesforceLayout.find((field) => field.name === row.salesforce_field)

              return (
                <HStack key={row.key} w="100%" justifyContent={'space-between'}>
                  <Box flex={1}>
                    <Input
                      type="text"
                      size="sm"
                      required
                      bg="white"
                      value={row.value || ''}
                      rounded="sm"
                      name={`${props.hardcodedPrefix}[][value]`}
                      onChange={(e) => {
                        setHardcoded((prevRows) =>
                          prevRows.map((r) => (r.key === row.key ? { ...r, value: e.target.value } : r))
                        )
                      }}
                    />
                  </Box>

                  <IconChevronsRight size={14} />

                  <Box flex={1}>
                    <input
                      type="hidden"
                      name={`${props.hardcodedPrefix}[][salesforce_field]`}
                      value={row.salesforce_field || ''}
                    />
                    <ComboboxWithSearch
                      items={props.salesforceLayout}
                      placeholder="Select Salesforce field"
                      selectedItem={salesforceField || null}
                      onChange={(field) => {
                        setHardcoded((prevRows) =>
                          prevRows.map((r) => (r.key === row.key ? { ...r, salesforce_field: field?.name || '' } : r))
                        )
                      }}
                      filterItem={(a, val) => a.label.toLowerCase().includes(val.toLowerCase())}
                      itemToString={(item) => item?.label || ''}
                      itemRenderer={(item) => {
                        const isAlreadyUsed = item.item
                          ? isFieldAlreadyUsed(item.item.name, row.key, rows, hardcoded)
                          : false

                        if (isAlreadyUsed) {
                          return <AlreadyUsedFieldPreview {...item} />
                        } else {
                          return <FieldPreview {...item} />
                        }
                      }}
                      selectButtonRenderer={(item) => {
                        // If the field is already used elsewhere but this is the current selection,
                        // we still show it but with the "already used" styling
                        if (item.item && isFieldAlreadyUsed(item.item.name, row.key, rows, hardcoded)) {
                          return <AlreadyUsedFieldPreview {...item} />
                        }
                        return <FieldPreview {...item} />
                      }}
                    />
                  </Box>

                  <IconButton
                    aria-label="Remove Hardcoded Field"
                    size="xs"
                    onClick={() => removeHardcodedRow(row.key)}
                    variant="ghost"
                    color="gray.400"
                    _hover={{ color: 'gray.800' }}
                    icon={<IconX size={14} />}
                  />
                </HStack>
              )
            })}
          </VStack>

          <HStack justify={'flex-end'} py="4">
            <Button
              colorScheme={props.colorScheme}
              onClick={addHardcoded}
              variant={'outline'}
              leftIcon={<IconPlus size="16" />}
              size="sm"
            >
              Field
            </Button>
          </HStack>
        </VStack>
      </Toggle>
    </VStack>
  )
}
