import CodeEditor from '@uiw/react-textarea-code-editor'
import { format } from 'sql-formatter'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Code,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Link,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList
} from '@chakra-ui/react'
import { IconReload, IconSparkles, IconTable, IconX } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { projectPath } from './ProjectsContext'
import { Toggle } from '../pages/accounts/components/Toggle'
import { JSONValue } from '../pages/kql_definitions/types'

export interface DataSync {
  id: string
  enabled: boolean
  query: string
  type: 'traits' | 'events'
  object_type: 'Account' | 'Profile'
}

export type TestResult = {
  result?: Array<Record<string, JSONValue>>
  error?: string | null
}

export function DataSyncSettings(props: {
  dataSyncs: DataSync[]
  appName: string
  setDataSyncs: (dataSyncs: DataSync[]) => void
  onTest: (dataSync: DataSync) => Promise<TestResult>
  lastSuccessfulSyncs?: Record<string, string>
}) {
  return (
    <Stack spacing={8} divider={<Divider />}>
      {props.dataSyncs.length === 0 && <input type="hidden" name={`app_instance_settings[data_syncs][]`} value={''} />}

      {props.dataSyncs.map((dataSync) => (
        <DataSyncSetting
          appName={props.appName}
          onTest={props.onTest}
          key={dataSync.id}
          dataSync={dataSync}
          onUpdate={(updatedDataSync) => {
            const updatedDataSyncs = props.dataSyncs.map((ds) => (ds.id === updatedDataSync.id ? updatedDataSync : ds))
            props.setDataSyncs(updatedDataSyncs)
          }}
          onRemove={() => {
            const updatedDataSyncs = props.dataSyncs.filter((ds) => ds.id !== dataSync.id)
            props.setDataSyncs(updatedDataSyncs)
          }}
          lastSuccessfulSync={props.lastSuccessfulSyncs?.[dataSync.id]}
        />
      ))}
    </Stack>
  )
}

function DataSyncSetting(props: {
  dataSync: DataSync
  appName: string
  onUpdate: (updatedDataSync: DataSync) => void
  onRemove: () => void
  onTest: (dataSync: DataSync) => Promise<TestResult>
  lastSuccessfulSync?: string
}) {
  const handleTypeChange = (value: 'traits' | 'events') => {
    if (value === 'events' && props.dataSync.object_type === 'Account') {
      props.onUpdate({ ...props.dataSync, object_type: 'Profile', type: value })
      return
    }

    props.onUpdate({ ...props.dataSync, type: value })
  }

  const handleObjectTypeChange = (value: 'Account' | 'Profile') => {
    props.onUpdate({ ...props.dataSync, object_type: value })
  }

  const handleQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onUpdate({ ...props.dataSync, query: e.target.value })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [queryResult, setQueryResult] = useState<Array<Record<string, JSONValue>> | null>(null)
  const [queryError, setQueryError] = useState<string | null>(null)

  const handleTestQuery = async () => {
    setIsLoading(true)

    try {
      const result = await props.onTest(props.dataSync)
      setQueryResult(result.result || null)
      setQueryError(null)
    } catch (error) {
      setQueryResult(null)
      setQueryError(JSON.stringify(error, null, 2))
    } finally {
      setIsLoading(false)
    }
  }

  const validationResults = useMemo(() => {
    if (!queryResult) return []
    return validateResults(queryResult, props.dataSync)
  }, [queryResult, props.dataSync])

  const handleEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onUpdate({ ...props.dataSync, enabled: e.target.checked })
  }

  return (
    <Box position="relative">
      <input type="hidden" name={`app_instance_settings[data_syncs][][id]`} value={props.dataSync.id} />
      <input type="hidden" name={`app_instance_settings[data_syncs][][type]`} value={props.dataSync.type} />
      <input
        type="hidden"
        name={`app_instance_settings[data_syncs][][object_type]`}
        value={props.dataSync.object_type}
      />
      <input type="hidden" name={`app_instance_settings[data_syncs][][query]`} value={props.dataSync.query} />
      {props.dataSync.enabled && (
        <input type="hidden" name={`app_instance_settings[data_syncs][][enabled]`} value={'true'} />
      )}
      <Stack spacing={4} w="100%">
        <HStack justifyContent="space-between" w="100%">
          <HStack flex="1" w="100%" alignItems={'flex-start'}>
            <FormControl as="fieldset" flex="2">
              <FormLabel as="legend">Object Type</FormLabel>
              <RadioGroup
                size="sm"
                value={props.dataSync.object_type}
                onChange={handleObjectTypeChange}
                isDisabled={isLoading}
              >
                <Stack direction="column" spacing="0.5">
                  <Radio value="Profile">Profile</Radio>
                  <Radio value="Account">Account</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            <FormControl as="fieldset" flex="2">
              <FormLabel as="legend">Type</FormLabel>
              <RadioGroup size="sm" value={props.dataSync.type} onChange={handleTypeChange} isDisabled={isLoading}>
                <Stack direction="column" spacing="0.5">
                  <Radio value="traits">Traits</Radio>
                  <Radio value="events">Events</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            <FormControl flex="1" gap="2">
              <FormLabel htmlFor={`enabled-${props.dataSync.id}`} mb="0">
                Enabled
              </FormLabel>
              <Switch
                id={`enabled-${props.dataSync.id}`}
                isChecked={props.dataSync.enabled}
                onChange={handleEnabledChange}
                colorScheme="purple"
              />
            </FormControl>
          </HStack>
        </HStack>

        {props.dataSync.type === 'events' && (
          <Toggle
            title={
              <Heading size="xs" fontWeight={'semibold'}>
                💡 Event Schema Tips
              </Heading>
            }
          >
            <Stack spacing={2} fontSize={'sm'} borderLeftWidth="3px" pt="2" pl="8" px="4" ml="10px">
              <UnorderedList spacing="2">
                <ListItem>
                  Each row must contain an <Code>event_name</Code> column to be used to identify the event.
                </ListItem>
                <ListItem>
                  Each row must contain a <Code>timestamp</Code> column. The timestamp is used as a checkpoint between
                  syncs to determine which events were sent after the last sync.
                </ListItem>
                {props.dataSync.object_type === 'Profile' && (
                  <ListItem>
                    Each row must contain an <Code>email</Code> column to identify the profile.
                  </ListItem>
                )}
                {props.dataSync.object_type === 'Account' && (
                  <ListItem>
                    Each row must contain a <Code>domain</Code> column to identify the account.
                  </ListItem>
                )}
                <ListItem>
                  (Optional) Each row can contain a <Code>message_id</Code> column to identify the event. Koala will use
                  this key to deduplicate events. Otherwise it'll use a hash of the event_name + properties + timestamp.
                </ListItem>
                <ListItem>
                  (Optional) Each row can contain a <Code>properties</Code> column to specify event properties. The
                  properties object must either be of the JSON type, or contain a JSON string.
                </ListItem>
                <ListItem>
                  (Optional) Each row can contain a <Code>source</Code> column to specify where the event was sent from.
                </ListItem>
              </UnorderedList>
            </Stack>
          </Toggle>
        )}

        <FormControl>
          <FormLabel>Query</FormLabel>
          <CodeEditor
            value={props.dataSync.query}
            onChange={handleQueryChange}
            language="sql"
            placeholder="Enter your SQL query here"
            padding={15}
            style={{
              backgroundColor: '#f5f5f5',
              color: '#000',
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
            }}
          />
        </FormControl>

        <Text fontSize={'xs'}>
          Last successful sync:{' '}
          {props.lastSuccessfulSync ? dayjs(props.lastSuccessfulSync).format('MMM D, YYYY HH:mm:ss') : '--'}
        </Text>

        <Flex justifyContent={'flex-end'} gap="2">
          {props.lastSuccessfulSync && (
            <Button
              size="sm"
              variant="outline"
              colorScheme="gray"
              as={Link}
              href={projectPath(`/apps/${props.appName}/logs?data_model_id=${props.dataSync.id}`)}
              leftIcon={<IconTable size={16} />}
              isDisabled={isLoading}
            >
              View Logs
            </Button>
          )}
          <Button
            size="sm"
            variant="outline"
            colorScheme="blue"
            onClick={handleTestQuery}
            isLoading={isLoading}
            loadingText="Testing..."
            leftIcon={<IconReload size={16} />}
          >
            Test Model
          </Button>
          <Tooltip label="Prettify SQL">
            <IconButton
              size="sm"
              variant="outline"
              colorScheme="pink"
              icon={<IconSparkles size={16} />}
              aria-label="Prettify SQL"
              onClick={() => {
                const formattedQuery = format(props.dataSync.query, {
                  language: 'sql',
                  keywordCase: 'upper',
                  dataTypeCase: 'upper',
                  functionCase: 'upper',
                  identifierCase: 'lower'
                })
                props.onUpdate({ ...props.dataSync, query: formattedQuery })
              }}
              isDisabled={isLoading}
            />
          </Tooltip>
        </Flex>

        {validationResults.length > 0 && <ValidationResults results={validationResults} />}
        {queryResult && <ResultTable data={queryResult} />}
        {queryError && <Textarea value={queryError} isReadOnly />}
      </Stack>
      <Tooltip label="Remove data model">
        <IconButton
          aria-label="Remove data model"
          icon={<IconX size={14} />}
          size="xs"
          variant="ghost"
          position="absolute"
          top="0"
          right="0"
          onClick={props.onRemove}
          isDisabled={isLoading}
        />
      </Tooltip>
    </Box>
  )
}

function ValidationResults({ results }: { results: string[] }) {
  if (results.length === 0) return null

  return (
    <Stack>
      <Alert status="error" size="sm">
        <AlertIcon />
        <AlertTitle fontSize={'sm'}>Validation Errors</AlertTitle>
        <AlertDescription fontSize={'sm'}>
          The following errors were found in the results of your query:
        </AlertDescription>
      </Alert>
      <UnorderedList p="4" fontSize={'sm'}>
        {results.map((result) => (
          <ListItem key={result} color="red.500">
            {result}
          </ListItem>
        ))}
      </UnorderedList>
    </Stack>
  )
}

function ResultTable({ data }: { data: Array<Record<string, JSONValue>> }) {
  if (!data || data.length === 0) return null

  const columns = Object.keys(data[0])

  return (
    <Stack>
      <TableContainer overflowX="auto" maxHeight="300px" overflowY="auto" sx={{ overscrollBehavior: 'contain' }}>
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column}>{column}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => (
              <Tr key={index}>
                {columns.map((column) => (
                  <Td key={column}>
                    {typeof row[column] === 'object' && row[column] !== null
                      ? JSON.stringify(row[column])
                      : (row[column] as string)}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Text fontSize={'xs'}>Showing {data.length} sample rows</Text>
    </Stack>
  )
}

function validateResults(results: Array<Record<string, JSONValue>>, dataSync: DataSync) {
  if (dataSync.type === 'traits' && dataSync.object_type === 'Profile') {
    return validateProfileTraits(results)
  }

  if (dataSync.type === 'traits' && dataSync.object_type === 'Account') {
    return validateAccountTraits(results)
  }

  if (dataSync.type === 'events' && dataSync.object_type === 'Profile') {
    return validateProfileEvents(results)
  }

  if (dataSync.type === 'events' && dataSync.object_type === 'Account') {
    return validateAccountEvents(results)
  }

  return []
}

function validateAccountEvents(results: Array<Record<string, JSONValue>>) {
  const errors: string[] = []

  const hasDomain = results.every((result) => {
    return 'domain' in result
  })

  if (!hasDomain) {
    errors.push('Account queries must contain a domain column identifier')
  }

  const hasTimestamp = results.every((result) => {
    return (
      'timestamp' in result &&
      typeof result.timestamp === 'string' &&
      dayjs(result.timestamp, 'YYYY-MM-DD HH:mm:ss', true).isValid()
    )
  })

  if (!hasTimestamp) {
    errors.push('Account queries must contain a timestamp column identifier')
  }

  const hasEventName = results.every((result) => {
    return 'event_name' in result
  })

  if (!hasEventName) {
    errors.push('Account queries must contain an event_name column identifier')
  }

  return errors
}

function validateProfileEvents(results: Array<Record<string, JSONValue>>) {
  const errors: string[] = []

  const hasEmail = results.every((result) => {
    return 'email' in result
  })

  if (!hasEmail) {
    errors.push('Profile queries must contain an email column identifier')
  }

  const hasTimestamp = results.every((result) => {
    return (
      'timestamp' in result &&
      typeof result.timestamp === 'string' &&
      dayjs(result.timestamp, 'YYYY-MM-DD HH:mm:ss', true).isValid()
    )
  })

  if (!hasTimestamp) {
    errors.push('Profile queries must contain a timestamp column identifier')
  }

  const hasEventName = results.every((result) => {
    return 'event_name' in result
  })

  if (!hasEventName) {
    errors.push('Profile queries must contain an event_name column identifier')
  }

  return errors
}

function validateAccountTraits(results: Array<Record<string, JSONValue>>) {
  const errors: string[] = []

  const hasDomain = results.every((result) => {
    return 'domain' in result
  })

  if (!hasDomain) {
    errors.push('Account queries must contain a domain column identifier')
  }

  return errors
}

function validateProfileTraits(results: Array<Record<string, JSONValue>>) {
  const errors: string[] = []

  const hasEmail = results.every((result) => {
    return 'email' in result
  })

  if (!hasEmail) {
    errors.push('Profile queries must contain an email column identifier')
  }

  return errors
}
