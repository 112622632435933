import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import { IconArrowDown, IconBell, IconBolt, IconFilter, IconTarget } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { Apps } from '../../../../types/App'
import { FollowRule } from '../../../../types/FollowRule'
import { theme } from '../../../theme'
import { Card } from '../../../ui/Card'
import { AiSparklesIcon } from '../../../ui/icons'
import { SegmentedControl } from '../../../ui/SegmentedControl'
import { useEntitlements } from '../../../ui/useEntitlements'
import { useCurrentUser } from '../../../ui/UserContext'
import { DeliverySetup } from './delivery-setup'
import { NotificationRulePreview } from './preview'
import { TSetup as AudienceSetup } from './territory-setup'
import { TriggerSetup } from './trigger-v2'

interface StepCardProps {
  children: React.ReactNode
  title: React.ReactNode
  logo: React.ReactNode
  wrap?: boolean
}

export function StepCard(props: StepCardProps) {
  return (
    <HStack
      position={'relative'}
      as={Card}
      gap="8"
      w="100%"
      flexWrap={
        props.wrap
          ? {
              lg: 'nowrap',
              base: 'wrap'
            }
          : undefined
      }
      justifyContent={'center'}
    >
      <Stack justifyContent={'center'} alignItems="center" spacing="2" minW="80px">
        {props.logo}
        <Text fontSize="sm" fontWeight={'semibold'}>
          {props.title}
        </Text>
      </Stack>
      <Divider
        orientation="vertical"
        h="8"
        display={{
          base: 'none',
          md: 'unset'
        }}
      />
      {props.children}
    </HStack>
  )
}

interface Props {
  follow_rule?: FollowRule
  apps: Apps
  formRef?: React.RefObject<HTMLFormElement>
  errors?: Record<string, string[]>
}

function StepDivider() {
  return (
    <Flex w="100%" justifyContent={'center'} alignItems="center">
      <IconArrowDown />
    </Flex>
  )
}

interface EnrichmentRules {
  work_emails: boolean
  personal_emails: boolean
  phone_numbers: boolean
  skip_conditions: {
    work_email: boolean
    company: boolean
    linkedin_url: boolean
  }
}

type ContinuationType = 'continue' | 'skip'

export function NotificationRuleForm(props: Props) {
  const currentUser = useCurrentUser()
  const entitlements = useEntitlements()

  const [recipient, _setRecipient] = React.useState(props.follow_rule?.recipient_id ?? currentUser.id)
  const [targetType, setTargetType] = React.useState(props.follow_rule?.target_type ?? 'Profile')

  const [enabled, setEnabled] = React.useState(props.follow_rule?.enabled ?? true)
  const [saveDisabled, setSaveDisabled] = React.useState(true)

  const [includeUnassociatedVisitors, setIncludeUnassociatedVisitors] = React.useState(
    props.follow_rule?.include_unassociated_visitors ?? true
  )

  const enrichmentAvailable = useMemo(
    () => currentUser.isInternalUser && entitlements?.waterfall_enrichment_actions,
    [currentUser.isInternalUser, entitlements?.waterfall_enrichment_actions]
  )

  const [enrichmentRules, setEnrichmentRules] = React.useState<EnrichmentRules>({
    work_emails: props.follow_rule?.enrichment_rules?.work_emails ?? false,
    personal_emails: props.follow_rule?.enrichment_rules?.personal_emails ?? false,
    phone_numbers: props.follow_rule?.enrichment_rules?.phone_numbers ?? false,
    skip_conditions: {
      work_email: props.follow_rule?.enrichment_rules?.skip_conditions?.work_email ?? false,
      company: props.follow_rule?.enrichment_rules?.skip_conditions?.company ?? true,
      linkedin_url: props.follow_rule?.enrichment_rules?.skip_conditions?.linkedin_url ?? true
    }
  })

  const isWaterfallEnriching = useMemo(() => {
    return enrichmentRules.work_emails || enrichmentRules.personal_emails
  }, [enrichmentRules])

  const [continuation, setContinuation] = React.useState<ContinuationType>(
    props.follow_rule?.enrichment_rules?.continuation ?? 'continue'
  )

  return (
    <Stack spacing="8" w="100%" bg="gray.50" p="8" overflowX={'auto'}>
      <StepCard title="Setup" logo={<IconBell size="22" />} wrap>
        <HStack alignItems="flex-start" justifyContent={'space-between'} spacing="12" w="100%">
          <FormControl>
            <FormLabel>Action name</FormLabel>
            <Input required size="sm" rounded={'md'} name="follow_rule[name]" defaultValue={props.follow_rule?.name} />
          </FormControl>
          <FormControl>
            <FormLabel>{enabled ? 'Active' : 'Inactive'}</FormLabel>
            <input type="hidden" name="follow_rule[enabled]" value={enabled ? 'true' : 'false'} />
            <Switch
              size="md"
              id="follow_rule[enabled]"
              isChecked={enabled}
              onChange={(e) => setEnabled(e.target.checked)}
            />
          </FormControl>
        </HStack>
      </StepCard>

      <StepDivider />

      <StepCard logo={<IconTarget size="22" color={theme.colors.pink['300']} />} title="Targeting" wrap>
        <Stack flex="1" spacing={4}>
          <FormControl>
            <FormLabel>This automation will target</FormLabel>

            <Box>
              <SegmentedControl display="inline-flex" width="auto">
                <Button minWidth="180px" isActive={targetType === 'Profile'} onClick={() => setTargetType('Profile')}>
                  People
                </Button>
                <Button minWidth="180px" isActive={targetType === 'Account'} onClick={() => setTargetType('Account')}>
                  Accounts
                </Button>
              </SegmentedControl>
            </Box>

            <FormHelperText>Choose whether to run the automation for a person or account.</FormHelperText>

            <input type="hidden" name="follow_rule[target_type]" value={targetType} />
            <input
              type="hidden"
              name="follow_rule[include_unassociated_visitors]"
              value={String(includeUnassociatedVisitors)}
            />
          </FormControl>

          {targetType === 'Profile' && (
            <FormControl>
              <Checkbox
                isChecked={includeUnassociatedVisitors}
                onChange={(e) => {
                  setIncludeUnassociatedVisitors(e.target.checked)
                }}
              >
                Include visitors that are not associated with a company? (e.g. gmail.com users)
              </Checkbox>
            </FormControl>
          )}
        </Stack>
      </StepCard>

      <StepDivider />

      <StepCard wrap logo={<IconBolt color="orange" size="22" />} title="Trigger">
        <Stack>
          {props.errors?.['intent_rules'] && (
            <Alert status="error">
              <AlertIcon />
              <Stack spacing="-1">
                <AlertTitle>Validation Error</AlertTitle>
                <AlertDescription>{props.errors['intent_rules'].join(', ')}</AlertDescription>
              </Stack>
            </Alert>
          )}
          <TriggerSetup follow_rule={props.follow_rule} targetType={targetType} />
        </Stack>
      </StepCard>

      <StepDivider />

      <input type="hidden" name="follow_rule[recipient_id]" value={recipient} />

      <StepCard title="Filter" logo={<IconFilter color={theme.colors.blue['300']} size="22" />} wrap>
        <FormControl>
          <FormLabel>Refine the audience...</FormLabel>
          {recipient && (
            <Box w="100%">
              <AudienceSetup
                // needs to change with the recipient in order to reset all facet filters
                key={recipient}
                targetType={targetType}
                followRule={props.follow_rule}
                apps={props.apps}
              />
            </Box>
          )}
        </FormControl>
      </StepCard>

      <StepDivider />

      {enrichmentAvailable && (
        <>
          <StepCard title="Enrichment" logo={<AiSparklesIcon size="22" />} wrap>
            <Stack w="100%" spacing="4">
              <FormControl>
                <FormLabel fontWeight="medium" mb={4}>
                  Waterfall Enrichment
                </FormLabel>

                {targetType !== 'Profile' && (
                  <Alert status="warning" size="sm" my="4">
                    <AlertIcon />
                    <AlertTitle fontSize="sm">Waterfall Enrichment is only available when targeting people.</AlertTitle>
                  </Alert>
                )}

                <Stack spacing={6}>
                  <Stack spacing={3}>
                    <Checkbox
                      isChecked={enrichmentRules.work_emails}
                      name="follow_rule[enrichment_rules][work_emails]"
                      isDisabled={targetType !== 'Profile'}
                      value="1"
                      onChange={(e) => setEnrichmentRules({ ...enrichmentRules, work_emails: e.target.checked })}
                    >
                      Work emails
                    </Checkbox>
                    <Checkbox
                      isChecked={enrichmentRules.personal_emails}
                      name="follow_rule[enrichment_rules][personal_emails]"
                      value="1"
                      isDisabled={targetType !== 'Profile'}
                      onChange={(e) => setEnrichmentRules({ ...enrichmentRules, personal_emails: e.target.checked })}
                    >
                      Personal emails
                    </Checkbox>
                    <Checkbox
                      isChecked={enrichmentRules.phone_numbers}
                      name="follow_rule[enrichment_rules][phone_numbers]"
                      value="1"
                      isDisabled
                      opacity={0.6}
                      onChange={(e) => setEnrichmentRules({ ...enrichmentRules, phone_numbers: e.target.checked })}
                    >
                      Phone numbers (Coming soon)
                    </Checkbox>
                  </Stack>

                  <Box bg="gray.50" p={4} rounded="md" border="1px" borderColor="gray.100">
                    <FormHelperText fontSize="sm" mb={0} color="gray.600" lineHeight="1.5" mt={0}>
                      Enrichment credits will be deducted from your account for each match. All the enriched data will
                      be available in enriched profiles in the Koala app. You can also choose to send the enriched data
                      to your destinations.
                    </FormHelperText>
                  </Box>
                </Stack>
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="medium" mb={4}>
                  If enrichment fails
                </FormLabel>
                <RadioGroup
                  size="sm"
                  value={continuation}
                  onChange={(value: ContinuationType) => setContinuation(value)}
                >
                  <Stack spacing={2}>
                    <Radio name="follow_rule[enrichment_rules][continuation]" value="continue">
                      Continue delivery
                    </Radio>
                    <Radio name="follow_rule[enrichment_rules][continuation]" value="skip">
                      Skip delivery
                    </Radio>

                    {continuation === 'skip' && (
                      <Box pl={0} pt={2}>
                        <Box bg="gray.50" p={4} rounded="md" border="1px" borderColor="gray.100">
                          <Stack spacing={2}>
                            <Checkbox
                              isChecked={enrichmentRules.skip_conditions.work_email}
                              name="follow_rule[enrichment_rules][skip_conditions][work_email]"
                              value="1"
                              onChange={(e) =>
                                setEnrichmentRules({
                                  ...enrichmentRules,
                                  skip_conditions: { ...enrichmentRules.skip_conditions, work_email: e.target.checked }
                                })
                              }
                            >
                              Require Work Email
                            </Checkbox>
                            <Checkbox
                              isChecked={enrichmentRules.skip_conditions.company}
                              name="follow_rule[enrichment_rules][skip_conditions][company]"
                              value="1"
                              onChange={(e) =>
                                setEnrichmentRules({
                                  ...enrichmentRules,
                                  skip_conditions: { ...enrichmentRules.skip_conditions, company: e.target.checked }
                                })
                              }
                            >
                              Require Company
                            </Checkbox>
                            <Checkbox
                              isChecked={enrichmentRules.skip_conditions.linkedin_url}
                              name="follow_rule[enrichment_rules][skip_conditions][linkedin_url]"
                              value="1"
                              onChange={(e) =>
                                setEnrichmentRules({
                                  ...enrichmentRules,
                                  skip_conditions: {
                                    ...enrichmentRules.skip_conditions,
                                    linkedin_url: e.target.checked
                                  }
                                })
                              }
                            >
                              Require LinkedIn URL
                            </Checkbox>
                          </Stack>
                          <FormHelperText fontSize="sm" mb={0} color="gray.600" lineHeight="1.5" mt={4}>
                            Koala will skip delivery if the waterfall enrichment fails to find the required data.
                          </FormHelperText>
                        </Box>
                      </Box>
                    )}
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Stack>
          </StepCard>
          <StepDivider />
        </>
      )}

      <DeliverySetup
        targetType={targetType}
        follow_rule={props.follow_rule}
        isWaterfallEnriching={isWaterfallEnriching}
        setSaveDisabled={(isLoading) => {
          setSaveDisabled(isLoading)
        }}
      />

      <HStack justifyContent={'space-between'} flexWrap="wrap">
        {props.formRef && <NotificationRulePreview formRef={props.formRef} />}
        <Button
          type="submit"
          colorScheme={'purple'}
          flex="1"
          isDisabled={saveDisabled}
          isLoading={saveDisabled}
          loadingText="Loading configuration..."
        >
          Save
        </Button>
      </HStack>
    </Stack>
  )
}
