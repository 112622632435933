import React from 'react'
import { Box, Flex, Progress, Stack, Text } from '@chakra-ui/react'
import { TimeAgo } from '../../../ui/TimeAgo'

interface PlaySourceProgressProps {
  status: 'queued' | 'processing' | 'complete'
  totalItems: number | null
  processedItems: number
  updatedAt: string
  error?: string
  showCount?: boolean
  showTotal?: boolean
}

export function PlaySourceProgress({
  status,
  totalItems,
  processedItems,
  updatedAt,
  error,
  showCount = true,
  showTotal = true
}: PlaySourceProgressProps) {
  // If status is null or undefined, default to 'queued'
  const safeStatus = status || 'queued'

  const isComplete = safeStatus === 'complete'
  const isProcessing = safeStatus === 'processing'

  // For LinkedIn, always show as indeterminate processing state
  const isIndeterminate = isProcessing && !processedItems

  const getStatusLabel = () => {
    if (isComplete) return 'done'
    if (isProcessing) return 'started'
    return 'pending'
  }

  const getStatusColor = () => {
    if (isComplete) return 'green'
    if (error) return 'red'
    return 'blue'
  }

  const shouldShowCounter = showCount && processedItems !== undefined && !isNaN(processedItems) && processedItems >= 0

  return (
    <Box px={4} py={4} bg="white" mb={2}>
      <Stack spacing={2.5}>
        <Flex fontSize="sm" fontWeight="medium" alignItems="center" justifyContent="space-between">
          <Text>
            Import {getStatusLabel()}{' '}
            <Text as="span" color="gray.500" fontWeight="normal">
              (<TimeAgo time={updatedAt} />)
            </Text>
          </Text>

          {shouldShowCounter && (
            <Text css={{ fontVariantNumeric: 'tabular-nums' }}>
              {new Intl.NumberFormat('en-US').format(processedItems)}{' '}
              {showTotal && totalItems && !isNaN(totalItems) && (
                <Text as="span" color="gray.400">
                  / {new Intl.NumberFormat('en-US').format(totalItems)}
                </Text>
              )}
            </Text>
          )}
        </Flex>
        <Progress
          size="xs"
          value={processedItems}
          max={totalItems || 100}
          rounded="md"
          isIndeterminate={isIndeterminate}
          hasStripe={isProcessing}
          colorScheme={getStatusColor()}
        />

        {error && (
          <Text fontSize="xs" color="red.500">
            Error: {error}
          </Text>
        )}
      </Stack>
    </Box>
  )
}
