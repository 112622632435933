import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Mailbox {
  id: string
  app_module: string
  created_at: string
  updated_at: string
  connected: boolean
  info?: {
    email?: string
    name?: string
  }
}

type MailboxResponse = Mailbox | null

export function useMailbox() {
  const project = useCurrentProject()
  const path = projectPath('/settings/mailbox')

  return useQuery<MailboxResponse>({
    queryKey: ['mailbox', { projectId: project?.id }],
    queryFn: () => concurrentGET<{ mailbox: MailboxResponse }>(path).then((res) => res.mailbox),
    enabled: Boolean(project?.id)
  })
}
