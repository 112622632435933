import {
  Box,
  Collapse,
  Flex,
  IconButton,
  Stack,
  Text,
  Editable,
  EditableInput,
  EditablePreview,
  Tooltip
} from '@chakra-ui/react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { IconChevronDown, IconChevronUp, IconGripVertical, IconTrash } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { Apps } from '../../../../../types/App'
import { PlayTargetType } from '../../../../../types/Play'
import { Territory } from '../../../../../types/Territory'
import { useFacets } from '../../../../data/use-facets'
import { User } from '../../../../data/use-users'
import { AdvancedFilterBuilder } from '../../../../ui/filters/AdvancedFilterBuilder'
import { RoutingCondition } from '../LeadRoutingRules'
import { StrategyConfiguration } from './StrategyConfiguration'
import { StrategySelector } from './StrategySelector'

interface SortableRuleProps {
  rule: RoutingCondition
  index: number
  toggleExpansion: () => void
  onRemove: () => void
  apps: Apps
  territories: Territory[]
  users: User[]
  targetType: PlayTargetType
  setRule: (rule: RoutingCondition) => void
  isValid: boolean
}

export const SortableRule: React.FC<SortableRuleProps> = ({
  rule,
  index,
  toggleExpansion,
  onRemove,
  apps,
  territories,
  users,
  targetType,
  setRule,
  isValid
}) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: rule.id
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    touchAction: 'none',
    zIndex: isDragging ? 10 : undefined
  }

  const facets = useFacets({
    facet_filters: rule.filters,
    facetCloudPath: targetType === 'Profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud',
    onFilterChange: (filters) => {
      setRule({ ...rule, filters })
    }
  })

  const handleTitleChange = useCallback(
    (newTitle: string) => {
      setRule({
        ...rule,
        title: newTitle
      })
    },
    [rule, setRule]
  )

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      borderWidth="1px"
      borderColor={isDragging ? 'blue.300' : isValid ? 'gray.200' : 'orange.200'}
      borderRadius="md"
      overflow="hidden"
      boxShadow={isDragging ? 'md' : 'none'}
      bg="white"
    >
      <Flex
        py={2}
        px={2}
        gap={2}
        alignItems="center"
        bg={isValid ? 'white' : 'orange.50'}
        borderBottomWidth={rule.expanded ? '1px' : '0'}
        borderBottomColor="gray.200"
        _hover={{ bg: isValid ? 'gray.100' : 'orange.100' }}
        transition="all 0.2s"
        cursor="pointer"
        onClick={toggleExpansion}
      >
        <Box ref={setActivatorNodeRef} {...listeners}>
          <IconButton
            aria-label="Drag to reorder"
            icon={<IconGripVertical size={16} />}
            size="xs"
            variant="ghost"
            cursor={isDragging ? 'grabbing' : 'grab'}
            color="gray.400"
            _hover={{ color: 'gray.600' }}
            onClick={(e) => e.stopPropagation()}
          />
        </Box>

        <Text flex="1" fontSize="sm" fontWeight="medium">
          <Flex align="center" gap={2}>
            <Tooltip label="Click to edit rule name">
              <Editable
                value={rule.title}
                onChange={handleTitleChange}
                fontSize="sm"
                fontWeight="medium"
                placeholder="Enter rule name"
                onClick={(e) => e.stopPropagation()}
              >
                <EditablePreview />
                <EditableInput />
              </Editable>
            </Tooltip>
            {rule.position}
          </Flex>
        </Text>

        <IconButton
          aria-label="Remove rule"
          icon={<IconTrash size={16} />}
          size="sm"
          variant="ghost"
          color="red.500"
          _hover={{ bg: 'red.50' }}
          onClick={(e) => {
            e.stopPropagation()
            onRemove()
          }}
          data-no-dnd="true"
        />

        <IconButton
          aria-label={rule.expanded ? 'Collapse rule' : 'Expand rule'}
          icon={rule.expanded ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
          size="sm"
          variant="ghost"
          color="gray.500"
          data-no-dnd="true"
        />
      </Flex>

      <Collapse in={rule.expanded} animateOpacity>
        <Box>
          {/* If Section */}
          <Box px={1} borderBottomWidth="1px" borderBottomColor="gray.100">
            <AdvancedFilterBuilder
              filters={rule.filters}
              onChangeAdvancedFilters={(filters) => {
                setRule({ ...rule, filters })
              }}
              kind={targetType.toLowerCase() as 'account' | 'profile'}
              convertTopLevelFilters={false}
              apps={apps}
              {...facets}
            />
          </Box>

          {/* Then Section */}
          <Box p={5} bg="white">
            <Stack spacing={4}>
              <Box>
                <Text fontSize="sm" mb={2} color="gray.600">
                  Then use this routing strategy:
                </Text>

                <StrategySelector
                  selectedStrategy={rule.strategy}
                  setSelectedStrategy={(strategy) => setRule({ ...rule, strategy })}
                />
              </Box>

              <StrategyConfiguration
                rule={rule}
                strategy={rule.strategy}
                territories={territories || []}
                users={users || []}
                fieldToSubmit={`play[assignment_config][rules][${index}]`}
                setRule={setRule}
                isValid={isValid}
              />
            </Stack>
          </Box>
        </Box>
      </Collapse>

      <input type="hidden" name={`play[assignment_config][rules][${index}][title]`} value={rule.title} />
      <input type="hidden" name={`play[assignment_config][rules][${index}][position]`} value={index + 1} />
      {rule.filters && (
        <input
          type="hidden"
          name={`play[assignment_config][rules][${index}][filters]`}
          value={JSON.stringify(rule.filters)}
        />
      )}
      <input type="hidden" name={`play[assignment_config][rules][${index}][strategy]`} value={rule.strategy} />
    </Box>
  )
}
