import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { Engagement } from '../../types/Engagement'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface EmailMessagesResponse {
  emails: Engagement[]
}

export function useEmailMessages(recordType: 'Account' | 'Profile', recordId: string) {
  const project = useCurrentProject()

  const typePath = recordType === 'Account' ? 'accounts' : 'profiles'
  const path = projectPath(`/${typePath}/${recordId}/emails`)

  return useQuery<EmailMessagesResponse['emails']>({
    queryKey: ['email_messages', { projectId: project?.id, recordType, recordId }],
    queryFn: () => concurrentGET<EmailMessagesResponse>(path).then((res) => res.emails),
    enabled: Boolean(project?.id)
  })
}
