import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconDotsVertical, IconEdit, IconHighlight, IconHighlightOff, IconTrash } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { toast } from 'sonner'
import { PersistedFieldDefinition, useDeleteFieldDefinition } from '../../../data/use-field-definitions'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'

interface FieldItemRowProps {
  field: PersistedFieldDefinition
  isLoading?: boolean
  onUpdateField: (field: PersistedFieldDefinition) => void
  onFieldDeleted?: (id: string) => void
  onRequestEditDefinition?: () => void
}

export function FieldDefinitionMenu({
  field,
  isLoading,
  onUpdateField,
  onFieldDeleted,
  onRequestEditDefinition
}: FieldItemRowProps) {
  const toggleKeyField = useCallback(() => {
    onUpdateField({ ...field, key_field: !field.key_field })
  }, [field, onUpdateField])

  const deleteFieldModal = useDisclosure()
  const { isPending: isDeletingField, mutateAsync: deleteField } = useDeleteFieldDefinition()

  const onDeleteField = useCallback(
    async (e) => {
      e.preventDefault()
      if (!field) return

      try {
        await deleteField({ id: field.id })
        onFieldDeleted?.(field.id)
      } catch (err) {
        toast.error('There was an issue removing this field', { description: (err as any)?.message })
      }
    },
    [field, onFieldDeleted, deleteField]
  )

  return (
    <>
      <Menu placement="bottom-end" autoSelect={false}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Icon as={IconDotsVertical} boxSize={3.5} />}
          variant="ghost"
          size="xs"
        />
        <Portal>
          <MenuList zIndex="popover">
            <MenuItem
              icon={<IconEdit size={16} />}
              onClick={onRequestEditDefinition}
              isDisabled={!field.is_custom || isLoading || isDeletingField}
            >
              Edit field
            </MenuItem>
            <MenuItem
              icon={field.key_field ? <IconHighlightOff size={16} /> : <IconHighlight size={16} />}
              onClick={toggleKeyField}
              isDisabled={isLoading || isDeletingField}
            >
              {field.key_field ? 'Remove from highlights' : 'Highlight field'}
            </MenuItem>
            <MenuItem
              color="red.500"
              icon={<IconTrash size={16} />}
              onClick={deleteFieldModal.onOpen}
              isDisabled={!field.is_custom || isLoading || isDeletingField}
            >
              Remove field
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>

      <DeleteConfirmation
        title={`Remove ${field?.label}?`}
        confirmLabel="Yes, continue"
        onConfirm={onDeleteField}
        isCentered
        {...deleteFieldModal}
      >
        <Stack spacing={4}>
          <Text>
            Are you sure you want to remove the <strong>{field?.label}</strong> field definition?
          </Text>
          <Text>
            Note: This will <strong>not</strong> delete any of the underlying data, it will only remove its
            schema/definition and remove it from your highlighted fields.
          </Text>
        </Stack>
      </DeleteConfirmation>
    </>
  )
}
