import React, { useMemo, FormEvent, useState } from 'react'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import Avatar from './Avatar'
import {
  useDisclosure,
  UseDisclosureProps,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Button,
  Flex,
  HStack,
  Spinner,
  Stack,
  FormControl,
  FormLabel,
  Heading,
  Link,
  Checkbox,
  Box
} from '@chakra-ui/react'
import { toast } from 'sonner'
import { postForm } from '../../lib/api'
import { Prospect } from '../data/use-prospects'
import { useAppDep } from '../data/use-app-dep'
import { ProfileRecord } from '../../types/Profile'
import { App } from '../../types/App'
import { Sequence } from '../../types/Outreach'
import { Account } from '../../types/Account'
import { TextEllipsis } from './text-ellipsis'
import { projectPath } from './ProjectsContext'
import { ComboboxWithSearch } from './ComboboxWithSearch'
import { useOutreachSequences } from '../data/use-outreach-sequences'
import { useCurrentUser } from './UserContext'
import pluralize from 'pluralize'
import capitalize from 'lodash/capitalize'
import useLocalStorageState from 'use-local-storage-state'

interface AddToOutreachProps extends UseDisclosureProps {
  app: App
  account?: Account | Pick<Account, 'id' | 'domain'>
  selectedProfiles: Prospect[] | ProfileRecord[] | []
  profileType?: 'Profile' | 'ProspectedProfile'
  profileLabel?: string
  skipAnonymousProfiles?: boolean | false
  toastProps?: object
  onMarkPlayItemsAsFinished?: () => void
}

export function AddToOutreachSequenceModal(props: AddToOutreachProps) {
  const { selectedProfiles, skipAnonymousProfiles, profileType, profileLabel, onMarkPlayItemsAsFinished, toastProps } =
    props
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const currentUser = useCurrentUser()
  const [saving, setSaving] = React.useState(false)

  const [selectedSequence, setSelectedSequence] = React.useState<Sequence | null>(null)
  const [selectedOwnerEmail, setSelectedOwnerEmail] = React.useState<string | null>(currentUser?.email ?? null)

  const profilesWithoutEmail = (selectedProfiles as any[]).filter((p) => !p.email)
  const profilesToAdd = skipAnonymousProfiles ? (selectedProfiles as any[]).filter((p) => p.email) : selectedProfiles
  const targetLabel = profileLabel || (profileType == 'Profile' ? 'people' : 'prospects')
  const targetPluralizedLabel = pluralize(targetLabel, profilesToAdd.length, profilesToAdd.length > 1)

  const [markAsFinished, setMarkAsFinished] = useLocalStorageState(
    'koala:mark-play-items-as-finished-after-adding-to-sequence',
    {
      defaultValue: true
    }
  )

  const {
    data: sequenceData,
    isLoading: loadingSequences,
    isError: sequencesError,
    error: sequencesErrorData
  } = useOutreachSequences(props.app.app_instance_id)

  const { data: usersData, isLoading: loadingUsers } = useAppDep<'users', any[]>('Outreach', 'users', { cached: true })

  const { isLoading: loadingScopes, ...scopes } = useAppDep<'scopes', string[]>('Outreach', 'scopes')
  const hasSequenceScope = React.useMemo(() => {
    return scopes.data?.data.scopes.includes('batches.all') || scopes.data?.data.scopes.includes('batches.write')
  }, [scopes.data])

  const selectedOwner = useMemo(
    () =>
      usersData?.data?.users?.find((s) => {
        return s.email.toString() === selectedOwnerEmail?.toString()
      }) ?? null,
    [usersData, selectedOwnerEmail]
  )

  const seq = useMemo(
    () => selectedSequence ?? sequenceData?.outreach_sequences?.[0] ?? null,
    [selectedSequence, sequenceData]
  )

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      if (!seq) return

      setSaving(true)

      const form = e.target as HTMLFormElement
      const data = new FormData(form)
      const profileIds = profilesToAdd.map((p) => p.id) ?? []
      profileIds.forEach((profileId) => data.append('profile_ids[]', profileId))

      postForm(projectPath(`/outreach/actions/bulk-sequences?&profile_type=${profileType ?? 'Profile'}`), data)
        .then(() => {
          toast.success(`${capitalize(targetLabel)} added to Sequence!`, {
            description: `${targetPluralizedLabel} has been added to ${seq.name} in Outreach.`,
            ...(toastProps ?? {})
          })
          setSaving(false)
          onClose()

          if (markAsFinished && onMarkPlayItemsAsFinished) {
            onMarkPlayItemsAsFinished()
          }
        })
        .catch((e) => {
          toast.error(`Error adding ${targetLabel} to sequence`, {
            description: e.message
          })
          setSaving(false)
        })
    },
    [
      seq,
      profilesToAdd,
      profileType,
      onClose,
      targetLabel,
      targetPluralizedLabel,
      toastProps,
      markAsFinished,
      onMarkPlayItemsAsFinished
    ]
  )

  const [showMoreProfiles, setShowMoreProfiles] = useState(false)
  const toggleShowMoreProfiles = () => {
    setShowMoreProfiles(!showMoreProfiles)
  }

  return (
    <Modal {...disclosure} size="md" isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {hasSequenceScope ? (
          <>
            <ModalHeader fontSize="md" display="flex" gap={2} pb={1}>
              <CompanyAvatar size="20px" domain={'outreach.ai'} mr={2} src={props.app?.logo} />
              Add to Outreach sequence
            </ModalHeader>
            <ModalBody pb={6}>
              <form onSubmit={onSubmit}>
                <Stack fontSize="sm" spacing={4}>
                  <FormControl>
                    <>
                      <FormLabel fontWeight="bold">Sequence</FormLabel>
                      {loadingSequences && <Spinner size="sm" />}
                      {sequencesErrorData && (
                        <Text p="4" bg="orange.50" m="2">
                          {' '}
                          {(sequencesErrorData as any)?.body?.error}
                        </Text>
                      )}
                      {!sequencesError && !loadingSequences && (
                        <ComboboxWithSearch
                          items={sequenceData?.outreach_sequences ?? []}
                          selectedItem={seq}
                          onChange={(selectedItem) => {
                            setSelectedSequence(selectedItem)
                          }}
                          filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                          itemToString={(item) => item?.name || ''}
                        />
                      )}
                    </>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="bold">Send Emails From</FormLabel>
                    {loadingUsers && <Spinner size="sm" />}
                    {!loadingUsers && !usersData?.data?.users?.length && (
                      <Text p="4" bg="orange.50" m="2">
                        We couldn't find any email accounts to display. Make sure to check the API key permissions in
                        your Outreach workspace.
                      </Text>
                    )}
                    <ComboboxWithSearch
                      items={usersData?.data.users ?? ['']}
                      selectedItem={selectedOwner}
                      onChange={(selectedItem) => {
                        setSelectedOwnerEmail(selectedItem.email)
                      }}
                      filterItem={(a, val) => (a?.email ?? '').toLowerCase().includes(val)}
                      itemToString={(item) => item?.email || ''}
                      placeholder="Select a sender"
                    />
                  </FormControl>
                </Stack>
                <Stack fontSize="sm" spacing={2} mt={4}>
                  <Text fontSize="xs" color="gray.500" fontWeight="bold">
                    SELECTED {pluralize(targetLabel, profilesToAdd.length).toUpperCase()}:
                  </Text>
                  <Flex direction="column" alignItems="space-between" gap={3}>
                    {profilesWithoutEmail && profilesWithoutEmail.length > 0 && !skipAnonymousProfiles && (
                      <Stack spacing="4" p="2">
                        <Text fontSize="sm" color="gray.600" lineHeight="1.4">
                          You've selected {pluralize(targetLabel, profilesWithoutEmail.length, true)} whose emails have
                          not been enriched yet. They will be enriched if you proceed and will use enrichment credits in
                          the future.
                        </Text>
                        <Text
                          whiteSpace={'pre-wrap'}
                          fontSize="xs"
                          lineHeight="1.4"
                          p="4"
                          bg="purple.50"
                          color="gray.700"
                        >
                          Note: we're currently offering unlimited Koala Waterfall enrichment credits during our open
                          beta.
                        </Text>
                      </Stack>
                    )}
                    {profilesToAdd && (
                      <>
                        {profilesToAdd.length > 5 ? (
                          <>
                            {profilesToAdd.slice(0, 4).map((selectedProfile) => (
                              <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                                <HStack>
                                  <Avatar
                                    size="xs"
                                    name={selectedProfile.name ?? (selectedProfile.full_name || selectedProfile.email)}
                                    src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                                  />
                                  <Text>
                                    {selectedProfile.name ?? (selectedProfile.full_name || selectedProfile.email)}
                                  </Text>
                                </HStack>
                                <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                  {selectedProfile.title}
                                </TextEllipsis>
                              </Flex>
                            ))}
                            {showMoreProfiles &&
                              profilesToAdd.slice(4, profilesToAdd.length).map((selectedProfile) => (
                                <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                                  <HStack>
                                    <Avatar
                                      size="xs"
                                      name={
                                        selectedProfile.name ?? (selectedProfile.full_name || selectedProfile.email)
                                      }
                                      src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                                    />
                                    <Text>
                                      {selectedProfile.name ?? (selectedProfile.full_name || selectedProfile.email)}
                                    </Text>
                                  </HStack>
                                  <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                    {selectedProfile.title}
                                  </TextEllipsis>
                                </Flex>
                              ))}
                            <Text onClick={toggleShowMoreProfiles} color="purple.500" align="center" cursor="pointer">
                              {showMoreProfiles
                                ? `- Hide ${profilesToAdd.length - 4} ${targetLabel}`
                                : `+ Show ${profilesToAdd.length - 4} more ${targetLabel}`}
                            </Text>
                          </>
                        ) : (
                          <>
                            {profilesToAdd.map((selectedProfile) => (
                              <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                                <HStack>
                                  <Avatar
                                    size="xs"
                                    name={selectedProfile.name ?? (selectedProfile.full_name || selectedProfile.email)}
                                    src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                                  />
                                  <Text>
                                    {selectedProfile.name ?? (selectedProfile.full_name || selectedProfile.email)}
                                  </Text>
                                </HStack>
                                <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                  {selectedProfile.title}
                                </TextEllipsis>
                              </Flex>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </Flex>
                </Stack>
                {profilesWithoutEmail && profilesWithoutEmail.length > 0 && skipAnonymousProfiles && (
                  <Stack spacing="4" p="2">
                    <Text fontSize="xs" color="gray.500">
                      {pluralize(`anonymous ${targetLabel}`, profilesWithoutEmail.length, true)} selected won't be
                      sequenced
                    </Text>
                  </Stack>
                )}
                <Flex w="100%" pt="4">
                  <Button
                    w="100%"
                    size="sm"
                    colorScheme="purple"
                    type="submit"
                    isLoading={saving || loadingSequences}
                    isDisabled={sequencesError || !usersData?.data?.users?.length || !profilesToAdd.length}
                  >
                    Add {targetPluralizedLabel} to Outreach Sequence
                  </Button>
                </Flex>
                {profilesToAdd.length > 0 && onMarkPlayItemsAsFinished && (
                  <Stack justifyContent="space-around" gap={3} flexDirection="row">
                    <Box marginTop={4}>
                      <Checkbox
                        isChecked={markAsFinished}
                        onChange={(e) => setMarkAsFinished(e.target.checked)}
                        colorScheme="purple"
                        size="md"
                      >
                        Mark play items as completed
                      </Checkbox>
                    </Box>
                  </Stack>
                )}
                {seq && <input type="hidden" name="sequence[sequence_id]" value={seq.outreach_id} />}
                {props.account && <input type="hidden" name="sequence[account_id]" value={props.account.id} />}
                {selectedOwnerEmail && <input type="hidden" name="sequence[owner_email]" value={selectedOwnerEmail} />}
              </form>
            </ModalBody>
          </>
        ) : (
          <Stack p={4} gap={4}>
            {loadingScopes && <Spinner alignSelf="center" size="sm" />}
            {!loadingScopes && !hasSequenceScope && (
              <>
                <Heading size="sm" fontWeight="semibold">
                  Missing Sequence Management Permissions
                </Heading>
                <Text>
                  Your Outreach connection is missing sequence management permissions. Please reconnect Outreach in
                  order to add support for Sequence Management from within Koala.
                </Text>
                <Button
                  as={Link}
                  variant="outline"
                  href={projectPath(`/apps/outreach?return_to=${encodeURIComponent(window.location.pathname)}`)}
                >
                  Reconnect Outreach
                </Button>
              </>
            )}
          </Stack>
        )}
      </ModalContent>
    </Modal>
  )
}
