import { ConnectionDetail, ConnectionDetails } from '@app/components/pages/apps/components/ConnectionDetails'
import { ConnectOauthAppDialog } from '@app/components/pages/apps/components/ConnectOauthAppDialog'
import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import type { Project } from '@app/types/Project'
import { SlackChannel } from '@app/types/Slack'
import {
  Badge,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList
} from '@chakra-ui/react'
import { IconArrowRight, IconBell, IconRocket } from '@tabler/icons-react'
import React from 'react'
import { FollowRule } from '../../../../types/FollowRule'
import { LightBgCard } from '../../../ui/Card'
import PageDescription from '../../../ui/PageDescription'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { automationsPath, slackAlertsPath } from '../../notifications/lib/path-helper'
import { SlackAlert } from '../../slack_alerts/types'
import { Introduction } from '../components/Introduction'

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  deps: {
    automations: FollowRule[]
    slack_alerts: SlackAlert[]
    channels: SlackChannel[]
  }
  logo: string
  valid?: boolean
  connected?: boolean
  settings: {}
  connection_details: ConnectionDetail[]
}

export default function Show(props: Props) {
  const { automations, slack_alerts } = props.deps
  const hasSlackAlerts = slack_alerts.length > 0
  const hasAutomations = automations.length > 0

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/slack'),
            title: 'Slack'
          }
        ]}
        offscreen
      />
      <HStack>
        <Box>
          <HStack marginRight="auto" alignItems="center" spacing={2}>
            <Image src={props.logo} maxW="6" />
            <PageTitle>{props.title}</PageTitle>
            {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>

        {props.connected && <DisconnectAppDialog appTitle={'Slack'} showRemoveCachesOption={false} />}
      </HStack>

      <Introduction
        app="slack"
        icon={props.logo}
        description="The Koala App for Slack helps you to stay up-to-date with your prospects and customers by delivering notifications about intent happening on your site in real-time. Subscribe to the account and contact lists that matter most and keep your whole team in sync."
        docsLink="https://getkoala.com/docs/integrations/slack"
      />

      {!props.connected && (
        <LightBgCard p={5}>
          <ConnectOauthAppDialog {...props} allowNonAdmins />
        </LightBgCard>
      )}

      {props.connected && (
        <Stack spacing="12">
          <ConnectionDetails appTitle={'Slack'} valid={props.valid} details={props.connection_details} />

          {hasSlackAlerts && (
            <LightBgCard>
              <Stack spacing={4}>
                <Box>
                  <Heading size="sm">Slack Alerts</Heading>
                  <Text fontSize="sm" color="gray.600">
                    Alert your team about the most important intent signals in real-time.
                  </Text>
                </Box>
                <Button
                  alignSelf="flex-start"
                  variant="outline"
                  rightIcon={<IconArrowRight size={14} />}
                  size="sm"
                  as={Link}
                  href={slackAlertsPath()}
                >
                  View your Slack Alerts
                </Button>
              </Stack>
            </LightBgCard>
          )}
          {hasAutomations && (
            <LightBgCard>
              <Stack spacing={4}>
                <Box>
                  <Heading size="sm">Automations</Heading>
                  <Text fontSize="sm" color="gray.600">
                    Trigger workflows into Slack and other integrations to automate your sales processes.
                  </Text>
                </Box>
                <Button
                  alignSelf="flex-start"
                  variant="outline"
                  rightIcon={<IconArrowRight size={14} />}
                  size="sm"
                  as={Link}
                  href={automationsPath()}
                >
                  View your Automations
                </Button>
              </Stack>
            </LightBgCard>
          )}

          {!hasSlackAlerts && (
            <Center p="20" as={Stack} spacing="6" bg="gray.50">
              <HStack>
                <IconBell />
                <Heading size="lg">Set up Slack Alerts</Heading>
              </HStack>
              <Stack>
                <UnorderedList spacing="2" maxW="500" color="gray.600" fontSize={'sm'}>
                  <ListItem>Subscribe to any intent performed by your prospects and customers.</ListItem>
                  <ListItem>Receive notifications in real-time.</ListItem>
                  <ListItem>Watch for any activity in your Territory or Target Accounts.</ListItem>
                </UnorderedList>
              </Stack>
              <HStack w="100%" maxW="500">
                <Button
                  leftIcon={<IconRocket size="20" />}
                  flex={'1'}
                  w="100%"
                  colorScheme={'purple'}
                  as={Link}
                  href={slackAlertsPath('/new')}
                >
                  Get Started
                </Button>
              </HStack>
            </Center>
          )}
        </Stack>
      )}
    </PageLayout>
  )
}
