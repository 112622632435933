import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Spinner,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconAlertCircleFilled, IconArrowUpRight, IconCheck } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useApolloKey } from '../../../data/use-apollo-key'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import { CheckboxCircleIcon } from '../../../ui/icons'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { StepIcon } from '../../../ui/StepIcon'
import { Introduction } from '../components/Introduction'

interface Props {
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  connected?: boolean
  valid?: boolean
  deps: {}
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject, isLoading: isPermissionLoading } = usePermission({
    on: 'project',
    action: 'can_edit'
  })
  const editApiKey = useDisclosure({ defaultIsOpen: true })
  const [apiKey, setApiKey] = useState('')
  const [autoEnrich, setAutoEnrich] = useState(
    props.settings['auto_enrich'] === undefined ? true : Boolean(props.settings['auto_enrich'])
  )
  const [debouncedNewKey] = useDebounce(apiKey, 300)
  const { isLoading, data } = useApolloKey(debouncedNewKey)

  const apiKeyInvalid = useMemo(
    () => (!isLoading && data?.valid === false) || (!props.valid && !apiKey && !!props.settings.api_key),
    [isLoading, data, props.valid, apiKey, props.settings.api_key]
  )

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/apollo'),
            title: 'Apollo'
          }
        ]}
        offscreen
      />

      <Box>
        <HStack>
          <HStack marginRight="auto" alignItems="center" spacing={2}>
            <Image src={props.logo} maxW="6" />
            <PageTitle>{props.title}</PageTitle>
            {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            {props.connected && !props.valid && <Badge colorScheme="orange">Needs attention</Badge>}
          </HStack>
          {props.connected && <DisconnectAppDialog appTitle="Apollo" showRemoveCachesOption={false} />}
        </HStack>
        <PageDescription>{props.description}</PageDescription>
      </Box>

      <Introduction
        app="apollo"
        icon={props.logo}
        description="With Apollo connected, you can search for prospects from any anonymous profile and we'll prefill your search with relevant details from Koala. You'll also be able use Apollo to enrich any contact that has a known email with their title, name, and more."
        docsLink="https://getkoala.com/docs/integrations/apollo"
      />

      {editApiKey.isOpen || !props.valid ? (
        <form method="POST">
          <AuthenticityToken />
          <input type="hidden" name="_method" value="PUT" />

          <LightBgCard gap={6} p={5}>
            <Heading size="sm">Connect your Apollo Account</Heading>

            <Stack fontSize="sm">
              <HStack alignItems="flex-start">
                <StepIcon step={1} />
                <Text>
                  <Link
                    display="inline-flex"
                    gap={0.5}
                    alignItems="center"
                    href="https://app.apollo.io/#/login"
                    isExternal
                  >
                    Log in to Apollo
                    <Icon as={IconArrowUpRight} boxSize={4} color="gray.500" />
                  </Link>{' '}
                  and navigate to the{' '}
                  <Link
                    display="inline-flex"
                    gap={0.5}
                    alignItems="center"
                    href="https://developer.apollo.io/keys/"
                    isExternal
                  >
                    Developer Portal
                    <Icon as={IconArrowUpRight} boxSize={4} color="gray.500" />
                  </Link>
                </Text>
              </HStack>
              <HStack alignItems="flex-start">
                <StepIcon step={2} />
                <Text>
                  Click <strong>"Create New Key"</strong>
                </Text>
              </HStack>
              <HStack alignItems="flex-start">
                <StepIcon step={3} />
                <Text>
                  Give your key a name and ensure <strong>"Set as Master Key"</strong> is enabled. You can optionally
                  select specific APIs for the key instead, but we recommend selecting all of those APIs for the Koala
                  integration to work fully.
                </Text>
              </HStack>
              <HStack alignItems="flex-start">
                <StepIcon step={4} />
                <Text>Copy the API key and paste it here</Text>
              </HStack>
            </Stack>

            <FormControl id="api_key" isRequired isInvalid={apiKeyInvalid}>
              <FormLabel>Your Apollo API key</FormLabel>

              <InputGroup w="100%" size="sm">
                <Input
                  name="app_instance_settings[api_key]"
                  placeholder="Enter your Apollo API key"
                  defaultValue={props.settings['api_key'] ?? ''}
                  onChange={(e) => setApiKey(e.target.value)}
                  type="password"
                  autoComplete="off"
                />
                <InputRightElement color={apiKeyInvalid ? 'red.500' : 'green.400'}>
                  {isLoading ? (
                    <Spinner color="gray.400" size="sm" />
                  ) : apiKeyInvalid ? (
                    <IconAlertCircleFilled size={18} />
                  ) : debouncedNewKey ? (
                    <IconCheck size={18} />
                  ) : null}
                </InputRightElement>
              </InputGroup>

              {apiKeyInvalid && <FormErrorMessage>Please enter a valid API key</FormErrorMessage>}
            </FormControl>

            {!canEditProject && !isPermissionLoading && (
              <>
                <Alert fontSize={'sm'} status="warning">
                  <AlertIcon />
                  <AlertDescription>
                    You don't have enough permissions to perform this action. Please request access to an admin in your
                    workspace.
                  </AlertDescription>
                </Alert>
              </>
            )}

            <FormControl>
              <FormLabel>Automatically enrich known visitors</FormLabel>
              <Checkbox
                defaultChecked
                isChecked={autoEnrich}
                onChange={(e) => {
                  setAutoEnrich(e.target.checked)
                }}
                size="sm"
              >
                When enabled, we'll automatically enrich any known visitor with their title, name, and more.
              </Checkbox>
              <input type="hidden" name="app_instance_settings[auto_enrich]" value={autoEnrich ? 'true' : 'false'} />
            </FormControl>

            <Button
              size="sm"
              colorScheme="purple"
              type="submit"
              w="100%"
              isDisabled={isLoading || !canEditProject || apiKeyInvalid}
            >
              Save
            </Button>
          </LightBgCard>
        </form>
      ) : (
        <LightBgCard gap={6} p={4}>
          <HStack justifyContent="space-between">
            <CheckboxCircleIcon color="green.400" size={18} />
            <Heading flex="1" size="xs" fontWeight="medium">
              Your Apollo account is connected
            </Heading>
            <Button variant="outline" size="sm" onClick={editApiKey.onOpen}>
              Change key
            </Button>
            <DisconnectAppDialog appTitle="Apollo" showRemoveCachesOption={false} />
          </HStack>
        </LightBgCard>
      )}
    </PageLayout>
  )
}
