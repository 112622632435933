import { useMutation, UseMutationOptions, useQuery, UseQueryResult } from '@tanstack/react-query'
import { concurrentGET, post, patch } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'
import { NamedFilter } from '../ui/filters/types'

interface NamedFiltersList {
  named_filters: NamedFilter[]
}

export function useNamedFilterList(targetType?: string): UseQueryResult<NamedFiltersList, Error> {
  const queryParams = new URLSearchParams()
  if (targetType) {
    queryParams.set('target_type', targetType)
  }

  const path = projectPath(`/named-filters?${queryParams.toString()}`)

  return useQuery<NamedFiltersList>({
    queryKey: ['named-filters', queryParams.toString()],
    queryFn: () => concurrentGET<NamedFiltersList>(path)
  })
}

export interface PersistNamedFilterResult {
  named_filter: NamedFilter
  errors: Record<string, string[]>
}

export interface PersistNamedFilterParams {
  id?: string
  attributes: Partial<NamedFilter>
}

export interface PersistNamedFilterError extends Error {
  body: PersistNamedFilterResult
  status: number
}

export function usePersistNamedFilter(
  options?: UseMutationOptions<PersistNamedFilterResult, PersistNamedFilterError, PersistNamedFilterParams>
) {
  return useMutation<PersistNamedFilterResult, PersistNamedFilterError, PersistNamedFilterParams>({
    mutationFn: (params) => {
      const { id, attributes } = params
      if (id) {
        return patch<PersistNamedFilterResult>(projectPath(`/named-filters/${id}`), {
          id: id,
          named_filter: attributes
        })
      } else {
        return post<PersistNamedFilterResult>(projectPath('/named-filters'), {
          named_filter: attributes
        })
      }
    },
    ...options
  })
}
