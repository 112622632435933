import { useQuery } from '@tanstack/react-query'
import { concurrentCachedGET } from '../../lib/api'
import { billingMetricsPath } from '../pages/billing/lib/path-helpers'
import { AllEntitlements, KoalaSubscription } from '../pages/billing/show'
import { Usage } from '../pages/billing/v2'
import { useCurrentProject } from '../ui/ProjectsContext'

interface Options {
  automations?: boolean
}

export function useBillingMetrics(options?: Options) {
  const project = useCurrentProject()
  const path = billingMetricsPath(options?.automations ? '?automations=1' : '')

  return useQuery({
    queryKey: ['billing_metrics', { projectId: project?.id, ...options }],

    queryFn: () =>
      concurrentCachedGET<{ usage?: Usage; entitlements: AllEntitlements; koala_subscription: KoalaSubscription }>(
        path
      ),

    enabled: Boolean(project?.id)
  })
}
