import { ConnectionDetail, ConnectionDetails } from '@app/components/pages/apps/components/ConnectionDetails'
import { ConnectOauthAppDialog } from '@app/components/pages/apps/components/ConnectOauthAppDialog'
import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import { HealthCheck, HealthChecks } from '@app/components/pages/apps/components/health-checks'
import { AuthenticityToken } from '@app/components/ui/AuthenticityToken'
import { ConfigurableAppActions } from '@app/components/ui/ConfigurableAppActions'
import PageDescription from '@app/components/ui/PageDescription'
import PageTitle from '@app/components/ui/PageTitle'
import { usePermission } from '@app/components/ui/PermissionsContext'
import { AppActions } from '@app/types/AppActions'
import { Project } from '@app/types/Project'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Link,
  ListItem,
  OrderedList,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import { IconPackage } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { concurrentGET } from '../../../../lib/api'
import { formatNumber } from '../../../../lib/number-format'
import { useAppDep } from '../../../data/use-app-dep'
import { useBillingMetrics } from '../../../data/use-billing-metrics'
import {
  AccountScoreMapping,
  AccountScoresDefinitionsTable,
  AccountScoresSettings,
  ManagedAccountScores
} from '../../../ui/AccountScoresSettings'
import { FeatureLockout } from '../../../ui/billing-banners/feature-lockout'
import { LightBgCard } from '../../../ui/Card'
import PageLayout from '../../../ui/PageLayout'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { StepIcon } from '../../../ui/StepIcon'
import { Toggle } from '../../accounts/components/Toggle'
import { FieldMapping, HardcodedRow, MappingRow } from './components/FieldMapping'

export interface SalesforcePicklistValue {
  active: boolean
  label: string
  value: string
}

export interface SalesforceField {
  name: string
  label: string
  type: string
  custom: boolean
  picklistValues: SalesforcePicklistValue[]
}

export interface KoalaField {
  name: string
  label: string
  type: string
}

export interface SalesforceObject {
  name: string
  label: string
  labelPlural?: string
  custom?: boolean
  createable?: boolean
  updateable?: boolean
  deletable?: boolean
}

export interface SalesforceDeps {
  sobjects: SalesforceObject[]
  account_layout: SalesforceField[]
  lead_layout: SalesforceField[]
  contact_layout: SalesforceField[]
  koala_company_layout: KoalaField[]
  koala_profile_layout: KoalaField[]
  health_checks: HealthCheck[]
  account_scores_sync: ManagedAccountScores
}

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  logo: string
  valid?: boolean
  connected?: boolean
  actions: AppActions
  settings: {
    account_mapping?: MappingRow[]
    lead_mapping?: MappingRow[]
    contact_mapping?: MappingRow[]

    account_hardcoded_mapping?: HardcodedRow[]
    lead_hardcoded_mapping?: HardcodedRow[]
    contact_hardcoded_mapping?: HardcodedRow[]

    account_scores_sync_enabled?: boolean
    account_scores_sync_mapping?: AccountScoreMapping
  }
  deps: SalesforceDeps
  connection_details: ConnectionDetail[]
  error: Error
}

interface Error {
  message: string
  help_url: string
}

function safeDefaults(sourceLayout: KoalaField[], targetLayout: SalesforceField[], attemptedMapping: MappingRow[]) {
  const targetLayoutNames = targetLayout.map((f) => f.name)
  const sourceLayoutNames = sourceLayout.map((f) => f.name)

  return attemptedMapping.filter(
    (mapping) =>
      targetLayoutNames.includes(mapping.salesforce_field ?? '') &&
      sourceLayoutNames.includes(mapping.koala_field ?? '')
  )
}

export default function Show(props: Props) {
  const metrics = useBillingMetrics()
  const { data: limitsData, isLoading: limitsLoading } = useAppDep<
    'ideal_limits',
    {
      max: number
      remaining: number
      accounts: number
      imported: number
      missing: number
    }
  >('Salesforce', 'ideal_limits')

  const crmBLocked = useMemo(() => {
    if (!metrics.data) {
      return false
    }

    if (metrics.data.entitlements?.crm_limit === undefined) {
      return false
    }

    if (metrics.data.usage?.crms === undefined) {
      return false
    }

    if (metrics.data.usage.crms >= metrics.data.entitlements.crm_limit) {
      return true
    }

    return false
  }, [metrics.data])

  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const idealLimits = useMemo(() => limitsData?.data.ideal_limits, [limitsData])

  const toast = useToast()

  const [refreshingLayouts, setRefreshingLayouts] = React.useState(false)
  const refreshLayouts = React.useCallback(() => {
    setRefreshingLayouts(true)
    concurrentGET(projectPath('/apps/salesforce/dep/fresh_layouts'))
      .then((_res) => {
        toast({
          title: 'Layouts refreshed',
          description: 'Your Salesforce layouts have been refreshed.',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
      })
      .catch((err) => {
        toast({
          title: 'Error refreshing layouts',
          description: 'There was an error refreshing your Salesforce layouts. ' + err.message,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      })
      .finally(() => {
        setRefreshingLayouts(false)
      })
  }, [toast])

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/salesforce'),
            title: 'Salesforce'
          }
        ]}
        offscreen
      />
      {props.error?.message && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            {props.error.message}
            {props.error.help_url && (
              <Text as="span">
                {' '}
                For more information please check{' '}
                <Link isExternal href={props.error.help_url}>
                  this page
                </Link>
                .
              </Text>
            )}
          </AlertDescription>
        </Alert>
      )}
      <Stack>
        <HStack>
          <HStack marginRight="auto" alignItems="center" spacing={2}>
            <Image src={props.logo} maxW="6" />
            <PageTitle>{props.title}</PageTitle>
            {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
          </HStack>

          {props.connected && <DisconnectAppDialog appTitle={'Salesforce'} showRemoveCachesOption={true} />}
        </HStack>
        <PageDescription>{props.description}</PageDescription>
      </Stack>

      <FeatureLockout
        locked={crmBLocked && !props.connected}
        blockTitle="CRM Connection Limit Reached"
        upgradeTo="business"
        blockedChildren={
          <Text>
            Your plan is limited to {metrics.data?.entitlements?.crm_limit} CRM integrations. You can upgrade to unlock
            more CRM integrations, Custom Objects, and Custom Fields for Salesforce and HubSpot.
          </Text>
        }
      >
        <>
          {!props.connected && <ConnectOauthAppDialog {...props} />}
          {props.connected && (
            <Stack spacing="12" divider={<Divider />}>
              <ConnectionDetails
                appTitle={'Salesforce'}
                valid={props.valid}
                allowSwitchUser={true}
                details={props.connection_details}
              >
                {limitsLoading && (
                  <Stack spacing="0.5" fontSize="sm" px="2">
                    <Spinner />
                  </Stack>
                )}
                {idealLimits && Object.keys(idealLimits).length > 0 && (
                  <Stack spacing="0.5" fontSize="sm" px="2">
                    <HStack spacing="4">
                      <Stack spacing="0.5">
                        <HStack spacing="1" fontSize={'sm'}>
                          <Text>Daily API Calls:</Text>
                          <Text>
                            {formatNumber(idealLimits.remaining)}/{formatNumber(idealLimits.max)}
                          </Text>
                        </HStack>

                        <HStack spacing="1" fontSize={'sm'}>
                          <Text>Accounts Synced:</Text>
                          <Text>
                            {formatNumber(idealLimits.imported)}/{formatNumber(idealLimits.accounts)}
                          </Text>
                        </HStack>
                      </Stack>
                    </HStack>
                  </Stack>
                )}
                {props.valid && (
                  <Stack>
                    <Tooltip label="Refresh your Account and Contact layouts. This is useful when you just made changes to your Salesforce schema and want to see them reflected in Koala immediately.">
                      <Button size="sm" variant={'outline'} onClick={refreshLayouts} isLoading={refreshingLayouts}>
                        Refresh Layouts
                      </Button>
                    </Tooltip>
                  </Stack>
                )}
              </ConnectionDetails>

              <HealthChecks appModule={'salesforce'} healthChecks={props.deps.health_checks} />

              <Box
                as="form"
                w="100%"
                method="POST"
                opacity={props.connected ? 1 : 0.4}
                pointerEvents={!props.connected ? 'none' : undefined}
              >
                <AuthenticityToken />
                <input type="hidden" name="_method" value="PUT" />

                <Stack w="100%" spacing="4">
                  <AccountScoresSettings<SalesforceField>
                    accountScore={props.deps.account_scores_sync}
                    settings={props.settings}
                    crmFields={props.deps.account_layout}
                    appModule={'salesforce'}
                    canEdit={canEditProject}
                    reconnectDialog={
                      props.connected &&
                      !props.valid && (
                        <LightBgCard>
                          <ConnectOauthAppDialog {...props} valid={false}>
                            <Stack fontSize={'sm'} spacing="4">
                              <Heading size="sm" fontWeight={'semibold'}>
                                We've encountered an error with your Salesforce integration connection
                              </Heading>
                              <Text>
                                Unfortunately your Salesforce integration is no longer functional and a reconnection is
                                required in order to restore related features. Reconnect Salesforce to start using it
                                again in your workspace. If you have any issues or questions, send us a message at
                                support@getkoala.com.
                              </Text>

                              <Button colorScheme={'blue'} type="submit">
                                Reconnect Salesforce
                              </Button>
                            </Stack>
                          </ConnectOauthAppDialog>
                        </LightBgCard>
                      )
                    }
                  >
                    <Stack py="4">
                      <Heading size="xs">Instructions:</Heading>
                      <HStack spacing="1" fontSize={'sm'} pt="2" pb="6">
                        <Text>
                          The account object in Salesforce needs to have a couple of fields to receive the Account
                          scores. You can either setup them automatically with the provided Managed Package or manually
                          add them.
                        </Text>
                      </HStack>
                      <Heading size="xs">Automatic Setup</Heading>
                      <OrderedList fontSize="sm" color="gray.500" spacing="6">
                        <Stack spacing="1" pt="2" pb="6">
                          <ListItem color="gray.800">
                            <StepIcon step={1} /> Install the package:{' '}
                            <Button
                              size="sm"
                              variant={'link'}
                              rightIcon={<IconPackage size="12" />}
                              as={Link}
                              color="purple.500"
                              isExternal
                              href={props.deps.account_scores_sync?.managed_package.url}
                            >
                              Koala Managed Scores ({props.deps.account_scores_sync?.managed_package.version})
                            </Button>{' '}
                          </ListItem>
                          <ListItem pl="4">
                            <Text fontSize="xs" p="4" bg="gray.50" rounded="lg">
                              <strong>Note:</strong> We only distribute the package internally, so it is safe to
                              acknowledge the install.{' '}
                            </Text>
                          </ListItem>
                          <ListItem color="gray.800">
                            <StepIcon step={2} /> Enable the sync
                          </ListItem>
                        </Stack>
                      </OrderedList>
                      <Heading size="xs">Manual Setup</Heading>
                      <OrderedList fontSize="sm" color="gray.500" spacing="6">
                        <Stack spacing="1" pt="2" pb="6">
                          <ListItem color="gray.800">
                            <StepIcon step={1} /> Manually add the fields (or compatible ones) to the Account object
                            following these definitions:
                          </ListItem>
                          <ListItem>
                            <AccountScoresDefinitionsTable {...props.deps.account_scores_sync?.definitions} />
                          </ListItem>
                          <ListItem color="gray.800">
                            <StepIcon step={2} /> Refresh the layouts
                          </ListItem>
                          <ListItem color="gray.800">
                            <StepIcon step={3} /> Enable the sync
                          </ListItem>
                          <ListItem color="gray.800">
                            <StepIcon step={4} /> Enabled and setup a custom mapping for them
                          </ListItem>
                        </Stack>
                      </OrderedList>
                    </Stack>
                  </AccountScoresSettings>

                  <Divider />

                  {Object.keys(props.actions).length > 0 && (
                    <Box w={'100%'}>
                      <ConfigurableAppActions appActions={props.actions} canEdit={canEditProject} />
                    </Box>
                  )}

                  <Divider />

                  <Toggle
                    title={
                      <Stack justifyContent={'space-between'} w="100%">
                        <Heading size="sm">Default Field Mappings</Heading>
                        <Text fontSize="sm">
                          Define how you map each individual Koala field to a Salesforce field when importing Koala
                          accounts and contacts into Salesforce via the `Import Account/Contact` buttons in People and
                          Account Profiles.
                        </Text>
                      </Stack>
                    }
                  >
                    <Stack w="100%" spacing="4">
                      <FieldMapping
                        salesforceLayout={props.deps.account_layout}
                        koalaLayout={props.deps.koala_company_layout}
                        defaultIsOpen={!props.settings.account_mapping}
                        mapping={
                          props.settings.account_mapping ??
                          safeDefaults(props.deps.koala_company_layout, props.deps.account_layout, [
                            {
                              koala_field: 'name',
                              salesforce_field: 'Name'
                            },
                            {
                              koala_field: 'domain',
                              salesforce_field: 'Website'
                            }
                          ])
                        }
                        hardcoded={props.settings.account_hardcoded_mapping}
                        fieldPrefix={'app_instance_settings[account_mapping]'}
                        hardcodedPrefix={'app_instance_settings[account_hardcoded_mapping]'}
                        colorScheme="purple"
                        title={
                          <Stack spacing={'1'} pl="2">
                            <Heading size={'sm'} fontWeight="semibold">
                              Account Mapping
                              {props.settings.account_mapping ? (
                                <Badge ml="2">
                                  {props.settings.account_mapping.length +
                                    (props.settings.account_hardcoded_mapping?.length ?? 0)}
                                </Badge>
                              ) : (
                                ''
                              )}
                            </Heading>
                            <Text fontSize={'sm'}>
                              Define how to map Koala Company records map to Salesforce Accounts.
                            </Text>
                          </Stack>
                        }
                      />

                      <FieldMapping
                        salesforceLayout={props.deps.lead_layout}
                        koalaLayout={props.deps.koala_profile_layout}
                        colorScheme="green"
                        defaultIsOpen={!props.settings.lead_mapping}
                        mapping={
                          props.settings.lead_mapping ??
                          safeDefaults(props.deps.koala_profile_layout, props.deps.lead_layout, [
                            {
                              koala_field: 'first_name',
                              salesforce_field: 'FirstName'
                            },
                            {
                              koala_field: 'last_name',
                              salesforce_field: 'LastName'
                            },
                            {
                              koala_field: 'email',
                              salesforce_field: 'Email'
                            },
                            {
                              koala_field: 'title',
                              salesforce_field: 'Title'
                            }
                          ])
                        }
                        hardcoded={props.settings.lead_hardcoded_mapping}
                        fieldPrefix={'app_instance_settings[lead_mapping]'}
                        hardcodedPrefix={'app_instance_settings[lead_hardcoded_mapping]'}
                        title={
                          <Stack spacing={'1'} pl="2">
                            <Heading size={'sm'} fontWeight="semibold">
                              Lead Mapping
                              {props.settings.lead_mapping ? (
                                <Badge ml="2">
                                  {props.settings.lead_mapping.length +
                                    (props.settings.lead_hardcoded_mapping?.length ?? 0)}
                                </Badge>
                              ) : (
                                ''
                              )}
                            </Heading>
                            <Text fontSize={'sm'}>
                              Define how to map Koala Profile records map to Salesforce Leads.
                            </Text>
                          </Stack>
                        }
                      />

                      <FieldMapping
                        salesforceLayout={props.deps.contact_layout}
                        koalaLayout={props.deps.koala_profile_layout}
                        colorScheme="orange"
                        defaultIsOpen={!props.settings.contact_mapping}
                        mapping={
                          props.settings.contact_mapping ??
                          safeDefaults(props.deps.koala_profile_layout, props.deps.contact_layout, [
                            {
                              koala_field: 'email',
                              salesforce_field: 'Email'
                            },
                            {
                              koala_field: 'title',
                              salesforce_field: 'Title'
                            }
                          ])
                        }
                        hardcoded={props.settings.contact_hardcoded_mapping}
                        fieldPrefix={'app_instance_settings[contact_mapping]'}
                        hardcodedPrefix={'app_instance_settings[contact_hardcoded_mapping]'}
                        title={
                          <Stack spacing={'1'} pl="2">
                            <Heading size={'sm'} fontWeight="semibold">
                              Contact Mapping{' '}
                              {props.settings.contact_mapping ? (
                                <Badge ml="2">
                                  {props.settings.contact_mapping.length +
                                    (props.settings.contact_hardcoded_mapping?.length ?? 0)}
                                </Badge>
                              ) : (
                                ''
                              )}
                            </Heading>
                            <Text fontSize={'sm'}>
                              Define how to map Koala Profile records map to Salesforce Contacts.
                            </Text>
                          </Stack>
                        }
                      />

                      <Text fontSize="sm" p="4" bg="gray.50" rounded="lg">
                        <strong>Note:</strong> These mappings only apply for manual imports via the "Import" button. Any
                        automations will use the mappings specified in their own configuration instead.
                      </Text>
                    </Stack>
                  </Toggle>
                  <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canEditProject}>
                    Save
                  </Button>
                </Stack>
              </Box>
            </Stack>
          )}
        </>
      </FeatureLockout>
    </PageLayout>
  )
}
