import { useQuery } from '@tanstack/react-query'
import { concurrentCachedGET, concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data<DepName extends string, DepBody> {
  data: {
    [key in DepName]: DepBody
  }
}

export function useAppDep<DepName extends string, DepBody>(
  appId: string,
  dep: DepName,
  depParams: object = {},
  cached = false,
  enabled = true
) {
  const project = useCurrentProject()

  const params = Object.keys(depParams)
    .map((key) => `dep_params[${key}]=${encodeURIComponent(depParams[key])}`)
    .join('&')

  const basePath = `/apps/${appId}/dep/${dep}?${params}`

  const path = projectPath(basePath)
  const getter = cached ? concurrentCachedGET : concurrentGET

  return useQuery<Data<DepName, DepBody>>({
    queryKey: ['app-dep', { projectId: project?.id, path, ...depParams }],
    queryFn: () => getter<Data<DepName, DepBody>>(path),
    enabled: enabled && Boolean(project?.id)
  })
}
