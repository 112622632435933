import {
  Badge,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconShare3, IconWorldShare } from '@tabler/icons-react'
import { compact, uniq } from 'lodash'
import React from 'react'
import type { Account } from '../../../../types/Account'
import { Apps } from '../../../../types/App'
import { KQLByDay } from '../../../../types/KQL'
import { useAccount } from '../../../data/use-account'
import { useEmailMessages } from '../../../data/use-email-messages'
import { AISummaryCard } from '../../../ui/AISummaryCard'
import { AnchoredDrawerLink } from '../../../ui/AnchoredDrawerLink'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { LinkedinBoxIcon } from '../../../ui/icons'
import { LastEngagementHoverCard } from '../../../ui/LastEngagement'
import { MiddotDivider } from '../../../ui/Middot'
import { usePermission } from '../../../ui/PermissionsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useEntitlements } from '../../../ui/useEntitlements'
import { useCurrentUser } from '../../../ui/UserContext'
import { AIAgentCTA } from '../../ai_agents/components/AIAgentCTA'
import { KqlDefinition } from '../../kql_definitions/types'
import { EmailMessages } from '../../profiles/components/EmailMessages'
import { accountPath } from '../lib/account-path'
import { ActivityTab, CompanyDetails } from './DetailsV2/DetailsPage'
import { People } from './DetailsV2/People'
import { CompanyMenu, FieldHighlights } from './DetailsV2/SummaryCard'

interface Props extends UseDisclosureProps {
  apps?: Apps
  accountDomain?: string
  redacted?: boolean
}

export function AccountPanel({ accountDomain, redacted, ...props }: Props) {
  const disclosure = useDisclosure(props)
  const isOpen = disclosure.isOpen

  const accountHref = accountPath({ domain: accountDomain })

  const { data, isLoading: loading } = useAccount(
    { domain: accountDomain },
    {
      enabled: isOpen
    }
  )

  const account = data?.account
  const company = account?.company
  const defaultPeopleColumns = data?.default_columns
  const apps = data?.apps
  const signalsByDay = data?.kqls_by_day
  const signalDefinitions = data?.kql_definitions

  if (!accountDomain) {
    return null
  }

  return (
    <Drawer {...disclosure} placement="right" size="xl">
      <DrawerOverlay />
      <DrawerContent maxWidth="3xl">
        <DrawerCloseButton />

        <DrawerHeader
          display="flex"
          alignItems="center"
          gap={1}
          minHeight="40px"
          py={2}
          borderBottom="1px solid"
          borderColor="border.lightest"
        >
          <Text fontSize="13px" fontWeight="medium" color="gray.500">
            Accounts
          </Text>
          <Text fontSize="13px" fontWeight="medium" color="gray.300">
            /
          </Text>
          <Text fontSize="13px" fontWeight="semibold" color="gray.700">
            {company?.name || company?.domain || 'Company Details'}
          </Text>
          <AnchoredDrawerLink href={accountHref} isExternal>
            Open Full Page
          </AnchoredDrawerLink>
        </DrawerHeader>
        <DrawerBody py={0}>
          {loading ? (
            <Flex alignItems="center" justifyContent="center" height="100%" py={3}>
              <Spinner color="gray.400" size="lg" />
            </Flex>
          ) : account ? (
            <AccountPanelDetails
              account={account}
              apps={apps!}
              signalsByDay={signalsByDay ?? []}
              signalDefinitions={signalDefinitions ?? []}
              defaultPeopleColumns={defaultPeopleColumns}
            />
          ) : (
            <Text fontSize="sm" color="gray.500" py={3}>
              There was an error loading this account.
            </Text>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

interface AccountPanelDetailsProps {
  account: Account
  apps: Apps
  signalsByDay: KQLByDay[]
  signalDefinitions: KqlDefinition[]
  defaultPeopleColumns: string[] | undefined
}

function AccountPanelDetails(props: AccountPanelDetailsProps) {
  const user = useCurrentUser()
  const account = props.account
  const company = account.company
  const sfAccount = (account as any)?.salesforce_account_cache
  const hsCompany = (account as any)?.hubspot_company_cache
  const _geo =
    company?.location || uniq(compact([company?.geo?.city, company?.geo?.stateCode, company?.geo?.country])).join(', ')
  const _employees = company?.metrics?.employeesRange || company?.metrics?.employees

  const { data: emails } = useEmailMessages('Account', account.id)
  const emailCount = emails?.length ?? 0

  // figure out last engagement (whether synced in Koala, or from SF / HS)
  const directActivity = account?.last_activity
  const lastActivity = [directActivity?.created_at, sfAccount?.last_activity_date, hsCompany?.last_activity_date]
    .filter(Boolean)
    .sort()
    .reverse()[0]

  return (
    <Flex flexDirection="column" gap={0} py={2}>
      <Flex direction="column" gap={3}>
        {/* Account Summary */}
        <Stack spacing={0}>
          <Flex flex="none" justifyContent="space-between" gap={4} py={4}>
            <Flex flex="1" alignItems="flex-start" gap={2.5}>
              <CompanyAvatar domain={company?.domain} name={company?.name} size="40px" />
              <Stack spacing={0}>
                <TextEllipsis
                  as={Link}
                  href={accountPath({ domain: account.domain })}
                  isExternal
                  fontSize="15px"
                  fontWeight="semibold"
                  lineHeight="1.4"
                >
                  {company?.name || company?.domain}
                </TextEllipsis>

                {/* only if we are not using the domain above... */}
                <HStack fontSize="sm" spacing={2} divider={<MiddotDivider />}>
                  {company?.name && (
                    <Text fontSize="13px" color="gray.500">
                      {company?.domain}
                    </Text>
                  )}
                  {account.assignee && (
                    <Text color="gray.600">
                      Claimed by{' '}
                      <Text as="span" fontWeight="medium" color="gray.700">
                        {user?.id === account.assignee.id ? 'You' : account.assignee.name || account.assignee.email}
                      </Text>
                    </Text>
                  )}
                </HStack>

                {lastActivity && lastActivity == directActivity?.created_at ? (
                  <LastEngagementHoverCard engagement={directActivity}>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={IconShare3} boxSize={4} color="gray.400" fill="currentColor" />
                      <Text fontSize="13px" color="gray.500">
                        {directActivity?.metadata?.direction === 'inbound' ? 'Replied' : 'Sent message'}{' '}
                        <TimeAgo time={lastActivity} canToggle={false} />
                      </Text>
                    </Flex>
                  </LastEngagementHoverCard>
                ) : lastActivity ? (
                  <Text fontSize="13px" color="gray.500">
                    Last sales activity <TimeAgo time={lastActivity} canToggle={false} />
                  </Text>
                ) : null}
              </Stack>
            </Flex>

            <Flex gap={1} alignItems="center">
              {company?.linkedin_url && (
                <Tooltip label={`https://${company.linkedin_url.replace(/https?:\/\//, '')}`}>
                  <IconButton
                    as={Link}
                    href={`https://${company.linkedin_url.replace(/https?:\/\//, '')}`}
                    isExternal
                    aria-label="LinkedIn"
                    icon={<LinkedinBoxIcon boxSize={18} color="linkedin.700" />}
                    size="sm"
                    variant="outline"
                  />
                </Tooltip>
              )}

              {company?.domain && (
                <Tooltip label={`https://${company.domain}`}>
                  <IconButton
                    as={Link}
                    href={`https://${company.domain}`}
                    isExternal
                    aria-label="Website"
                    icon={<IconWorldShare size={16} />}
                    size="sm"
                    variant="outline"
                  />
                </Tooltip>
              )}
              <CompanyMenu account={account} />
            </Flex>
          </Flex>
        </Stack>
      </Flex>

      <Tabs size="sm" variant="line" isLazy lazyBehavior="keepMounted">
        <TabList position="sticky" top={0} bg="white" zIndex={10} mx={-1} px={1}>
          <Tab px={3}>Overview</Tab>
          <Tab px={3}>AI Research</Tab>
          <Tab px={3}>Activity</Tab>
          <Tab px={3}>
            <Flex alignItems="center" gap={1.5}>
              Emails
              {emailCount > 0 && (
                <Badge variant="pill" colorScheme="gray">
                  {emailCount.toLocaleString()}
                </Badge>
              )}
            </Flex>
          </Tab>
          <Tab px={3}>People</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <OverviewTab {...props} />
          </TabPanel>
          <TabPanel px={0}>
            <AIAgentsTab {...props} />
          </TabPanel>
          <TabPanel px={0}>
            <ActivityTab account={account} signalsByDay={props.signalsByDay} signalDefns={props.signalDefinitions} />
          </TabPanel>
          <TabPanel px={0}>
            <EmailMessages emails={emails ?? []} />
          </TabPanel>
          <TabPanel px={0}>
            <People account={account} defaultColumns={props.defaultPeopleColumns} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

function OverviewTab(props: AccountPanelDetailsProps) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const entitlements = useEntitlements()

  return (
    <Stack spacing={3}>
      <FieldHighlights
        account={props.account}
        canEditHighlights={canEditProject}
        apps={props.apps}
        scoringEnabled={entitlements?.icp_scoring ?? false}
      />
      <CompanyDetails account={props.account} />
    </Stack>
  )
}

function AIAgentsTab(props: AccountPanelDetailsProps) {
  const entitlements = useEntitlements()

  if (entitlements?.ai_agents_beta) {
    return <AISummaryCard account={props.account} target="companies" />
  }

  return <AIAgentCTA />
}
