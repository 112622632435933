import { AssignCrmOwnerModal, AssignToCrmProps } from '@app/components/pages/accounts/components/AssignCrmOwnerModal'
import { usePermission } from '@app/components/ui/PermissionsContext'
import { projectPath } from '@app/components/ui/ProjectsContext'
import { useCurrentUser } from '@app/components/ui/UserContext'
import { put } from '@app/lib/api'
import router from '@app/lib/router'
import type { Account, CRMMatch } from '@app/types/Account'
import type { App, Apps } from '@app/types/App'
import { isActionEnabled, ProjectActions } from '@app/types/AppActions'
import {
  Box,
  Button,
  ButtonProps,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Kbd,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronRight, IconDotsVertical } from '@tabler/icons-react'
import React, { KeyboardEvent, useCallback, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { toast } from 'sonner'
import Router from '../../../../lib/router'
import { useHiddenAccounts, useHideAccount, useRestoreAccount } from '../../../data/use-hide-account'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { AddToListModal } from '../../lists/components/AddToListModal'

interface Props {
  account: Account
  projectActions: ProjectActions
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
  apps: Apps
  hotkeys?: boolean
  inline?: boolean
  onClaim?: (account: Account) => unknown
  onRemoveStatus?: (account: Account) => unknown
  onRequestImportAccount?: (account: Account, app: App) => unknown
  onRequestImportContacts?: (account: Account, app: App) => unknown
  onRequestCreateOutreachTask?: (account: Account, app: App) => unknown
  onClose?: () => unknown
  isOpen?: boolean
  onRefresh?: () => unknown
}

interface Action {
  key: string
  name: string
  flatName: string
  callback: () => void
  hotkey: string
  app?: App
}

function getAccountInApp(appTitle: string, accounts?: CRMMatch[]) {
  return accounts?.find((m) => (m.match_type === 'account_id' || m.match_type == 'domain') && m.crm.title == appTitle)
}

const Shortcut = (props: { action: Action }) => {
  return (
    <Flex gap={1} align={'center'}>
      {props.action.hotkey.split('').map((hk) => {
        return <Kbd key={hk}>{hk}</Kbd>
      })}
    </Flex>
  )
}

function NestedMenuItem(props: { title: string; actions: Action[]; hotkeys?: boolean }) {
  const disclosure = useDisclosure({ defaultIsOpen: true })

  return (
    <>
      <MenuItem
        closeOnSelect={false}
        display="flex"
        onClick={disclosure.onToggle}
        justifyContent="space-between"
        alignItems="center"
        icon={props.actions[0]?.app?.logo ? <CompanyAvatar src={props.actions[0].app.logo} size={'14px'} /> : undefined}
        w="100%"
      >
        <HStack w="100%" justifyContent={'space-between'} spacing="-1">
          <Text>{props.title}</Text>
          {disclosure.isOpen ? <IconChevronDown size={16} /> : <IconChevronRight size={16} />}
        </HStack>
      </MenuItem>
      <Collapse in={disclosure.isOpen}>
        {props.actions.map((a) => {
          return (
            <MenuItem
              paddingLeft={'6'}
              key={a.key}
              fontWeight={'normal'}
              justifyContent="space-between"
              alignItems="center"
              onClick={a.callback}
            >
              <Text>{a.name}</Text>
              {props.hotkeys && a.hotkey && <Shortcut action={a} />}
            </MenuItem>
          )
        })}
      </Collapse>
    </>
  )
}

function MenuWithSubmenus(props: { actions: Map<string, Action[]>; hotkeys?: boolean }) {
  return (
    <>
      {Array.from(props.actions.entries()).map(([title, actions]) => {
        return (
          <Box key={title}>
            <NestedMenuItem title={title} actions={actions} hotkeys={props.hotkeys} />
          </Box>
        )
      })}
    </>
  )
}

function FlatMenu(props: { actions: Map<string, Action[]>; hotkeys?: boolean }) {
  return (
    <>
      {Array.from(props.actions.values())
        .flat()
        .map((a) => {
          return (
            <MenuItem
              paddingY={2}
              paddingX={4}
              key={a.key}
              justifyContent="space-between"
              alignItems="center"
              onClick={a.callback}
            >
              <Text>{a.flatName}</Text>
              {props.hotkeys && <Shortcut action={a} />}
            </MenuItem>
          )
        })}
    </>
  )
}

export function AccountActions(props: Props) {
  const {
    account,
    hotkeys = true,
    onRequestImportAccount,
    onRequestImportContacts,
    onRequestCreateOutreachTask,
    onClaim,
    onRemoveStatus
  } = props

  const user = useCurrentUser()

  const koalaListModal = useDisclosure()

  const [assignToCrmProps, setAssignToCrmProps] = React.useState<AssignToCrmProps>({
    isOpen: false,
    onClose: () => {
      setAssignToCrmProps((p) => {
        return { ...p, isOpen: false }
      })
    }
  })

  const projectActions = React.useMemo(() => {
    return Object.values(props.projectActions).reduce((obj, projectAction) => {
      Object.assign(obj, projectAction)
      return obj
    }, {})
  }, [props.projectActions])

  const alreadyClaimed = React.useMemo(() => {
    return typeof account.assignee?.id === 'string' && account.assignee.id !== user.id
  }, [user, account])

  const claimedByYou = React.useMemo(() => {
    return account.assignee?.id === user.id
  }, [user, account])

  const hiddenAccounts = useHiddenAccounts()
  const hide = useHideAccount()
  const restore = useRestoreAccount()

  const isHidden = useMemo(
    () => hiddenAccounts.data?.filters?.domains?.filters?.facets?.['company.domain']?.['not']?.includes(account.domain),
    [account.domain, hiddenAccounts.data]
  )

  const startImportAccountFlow = React.useCallback(
    (appModule) => {
      return () => {
        onRequestImportAccount?.(account, props.apps[appModule])
      }
    },
    [account, onRequestImportAccount, props.apps]
  )

  const startImportContactsFlow = React.useCallback(
    (appModule) => {
      return () => {
        onRequestImportContacts?.(account, props.apps[appModule])
      }
    },
    [account, onRequestImportContacts, props.apps]
  )

  const startCreateOutreachTaskFlow = React.useCallback(
    (appModule) => {
      return () => {
        onRequestCreateOutreachTask?.(account, props.apps[appModule])
      }
    },
    [account, onRequestCreateOutreachTask, props.apps]
  )

  const removeStatus = React.useCallback(async () => {
    await put(projectPath(`/accounts/${account.company?.domain}`), {
      account: {
        status: null,
        assignee_id: null
      }
    })

    onRemoveStatus?.(account)
  }, [account, onRemoveStatus])

  const assignOwner = React.useCallback((mainAccount: CRMMatch, appModule: string, app: App) => {
    return async () => {
      if (!mainAccount) {
        return
      }

      setAssignToCrmProps((p) => {
        return {
          ...p,
          account: mainAccount,
          appModule: appModule,
          isOpen: true,
          app
        }
      })
    }
  }, [])

  const claimAccount = React.useCallback(async () => {
    try {
      await put(projectPath(`/accounts/${account.company?.domain}`), {
        account: {
          status: 'prospect',
          assignee_id: user.id
        }
      })

      onClaim?.(account)

      toast.success('Account claimed!', {
        description: `You'll find it in your Claimed Accounts list`,
        action: {
          label: 'View',
          onClick: () => {
            Router.visit(projectPath(`/views/mine/claimed`))
          }
        }
      })
    } catch (error) {
      toast.error('There was an issue claiming this account.')
    }
  }, [account, user.id, onClaim])

  const viewInCRM = (link: string) => {
    return () => {
      window.open(link, '_blank', 'noopener,noreferrer')
    }
  }

  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })

  const connectActions: Action[] = React.useMemo(() => {
    if (!canEditProject) {
      return []
    }

    const actions: Action[] = []
    const salesforceApp = props.apps['Apps::Salesforce::App']
    const hubspotApp = props.apps['Apps::Hubspot::App']
    const outreachApp = props.apps['Apps::Outreach::App']

    if (!salesforceApp) {
      actions.push({
        key: 'koala_add_salesforce',
        name: 'Connect Salesforce',
        flatName: 'Connect Salesforce',
        callback: () => router.visit(projectPath('/apps/salesforce')),
        hotkey: 'cs',
        app: salesforceApp
      })
    }
    if (!hubspotApp) {
      actions.push({
        key: 'koala_add_hubspot',
        name: 'Connect HubSpot',
        flatName: 'Connect HubSpot',
        callback: () => router.visit(projectPath('/apps/hubspot')),
        hotkey: 'ch',
        app: hubspotApp
      })
    }
    if (!outreachApp) {
      actions.push({
        key: 'koala_add_outreach',
        name: 'Connect Outreach',
        flatName: 'Connect Outreach',
        callback: () => router.visit(projectPath('/apps/outreach')),
        hotkey: 'co',
        app: outreachApp
      })
    }

    return actions
  }, [props.apps, canEditProject])

  const actions: Map<string, Action[]> = React.useMemo(() => {
    const preferredActionsOrder = ['Apps::Outreach::App', 'Apps::Hubspot::App', 'Apps::Salesforce::App']
    // using a Map as it keeps order
    const allActions: Map<string, Action[]> = new Map()

    const sortedKeys = Object.keys(props.apps)
      .sort((a, b) => preferredActionsOrder.indexOf(a) - preferredActionsOrder.indexOf(b))
      .reverse()

    sortedKeys.forEach((appModule) => {
      const app = props.apps[appModule]
      const [title, prefix] = [app.title, app.action_prefix]
      allActions.set(title, [])

      const accountInApp = getAccountInApp(app.title, [...account.crm_accounts, ...(account.outreach_accounts || [])])

      const importContactsAction = app.actions[`${prefix}_import_contacts`]
      const importAccountsAction = app.actions[`${prefix}_import_accounts`]
      const claimInCrmAction = app.actions[`${prefix}_claim_in_crm`]
      const viewInCrmAction = app.actions[`${prefix}_view_in_crm`]

      if (accountInApp && importContactsAction) {
        allActions.get(title)?.push({
          key: `${prefix}_import_contacts`,
          name: `Import Contacts…`,
          flatName: `Import Contacts to ${title}…`,
          callback: startImportContactsFlow(appModule),
          hotkey: importContactsAction.hotkey,
          app: app
        })

        if (app.user_in_app) {
          claimInCrmAction &&
            allActions.get(title)?.push({
              key: `${prefix}_claim_in_crm`,
              name: `Claim…`,
              flatName: `Claim in ${title}…`,
              callback: assignOwner(accountInApp, appModule, app),
              hotkey: claimInCrmAction.hotkey,
              app: app
            })

          viewInCrmAction &&
            accountInApp.crm_entity.permalink &&
            allActions.get(title)?.push({
              key: `${prefix}_view_in_crm`,
              name: `View`,
              flatName: `View in ${title}`,
              callback: viewInCRM(accountInApp.crm_entity.permalink),
              hotkey: viewInCrmAction.hotkey,
              app: app
            })
        }
      } else {
        if (importAccountsAction) {
          allActions.get(app.title)?.push({
            key: `${prefix}_import_accounts`,
            name: `Import Account…`,
            flatName: `Import Account to ${title}…`,
            callback: startImportAccountFlow(appModule),
            hotkey: importAccountsAction.hotkey,
            app: app
          })
        }
      }
    })

    // koala specific actions
    const koalaClaimAccountAction = props.apps['Apps::Koala::App']?.actions[`koala_claim_account`]

    allActions.set('Koala', [])

    allActions.get('Koala')?.push({
      key: 'koala_add_to_list',
      name: 'Add to List',
      flatName: 'Add to Koala List',
      callback: koalaListModal.onOpen,
      hotkey: 'kl',
      app: props.apps['Apps::Koala::App']
    })

    if (koalaClaimAccountAction && !alreadyClaimed) {
      if (claimedByYou) {
        allActions.get('Koala')?.push({
          key: 'koala_claim_account',
          name: 'Unclaim',
          flatName: 'Unclaim in Koala',
          callback: removeStatus,
          hotkey: koalaClaimAccountAction.hotkey,
          app: props.apps['Apps::Koala::App']
        })
      } else {
        allActions.get('Koala')?.push({
          key: 'koala_claim_account',
          name: 'Claim',
          flatName: 'Claim in Koala',
          callback: claimAccount,
          hotkey: koalaClaimAccountAction.hotkey,
          app: props.apps['Apps::Koala::App']
        })
      }
    }

    if (isHidden) {
      allActions.get('Koala')?.push({
        key: 'koala_restore_account',
        name: 'Restore',
        flatName: 'Unhide Account',
        hotkey: 'kh',
        app: props.apps['Apps::Koala::App'],
        callback: () => {
          restore
            .mutateAsync({
              domain: account.domain
            })
            .then(() => {
              hiddenAccounts.refetch()
              props.onRefresh?.()
            })
        }
      })
    } else {
      allActions.get('Koala')?.push({
        key: 'koala_hide_account',
        name: 'Hide',
        flatName: 'Hide in Koala',
        app: props.apps['Apps::Koala::App'],
        hotkey: 'kh',
        callback: () => {
          hide
            .mutateAsync({
              domain: account.domain
            })
            .then(() => {
              hiddenAccounts.refetch()
              props.onRefresh?.()
            })
        }
      })
    }

    // outreach specific tasks
    const outreach = props.apps['Apps::Outreach::App']
    if (outreach) {
      // if account in app, let users create a task
      const accountInApp = getAccountInApp(outreach.title, account.outreach_accounts || [])
      const createTaskAction = outreach.actions['outreach_create_task']
      if (accountInApp && createTaskAction) {
        allActions.get('Outreach')?.push({
          key: 'outreach_create_task',
          name: 'Create Task…',
          flatName: 'Create Task in Outreach…',
          callback: startCreateOutreachTaskFlow('Apps::Outreach::App'),
          hotkey: createTaskAction.hotkey,
          app: outreach
        })
      }
    }

    // filter out all disabled actions
    for (const [k, v] of allActions) {
      const actions = v.filter((a) => isActionEnabled(projectActions, a.key, true))
      if (actions.length > 0) {
        allActions.set(k, actions)
      } else {
        allActions.delete(k)
      }
    }

    return allActions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.apps,
    alreadyClaimed,
    account.crm_accounts,
    account.outreach_accounts,
    startImportContactsFlow,
    startImportAccountFlow,
    startCreateOutreachTaskFlow,
    assignOwner,
    claimedByYou,
    removeStatus,
    claimAccount,
    projectActions
  ])

  const [keystrokes, setKeystrokes] = React.useState<string>('')
  const maxKeystrokeLength = React.useMemo(() => 2, []) // TODO: calculate this dynamically

  const handleHotkeys = useCallback((key) => {
    if (key.match(/[a-zA-Z]/)) {
      setKeystrokes((p) => p + key)
    }
  }, [])

  React.useEffect(() => {
    if (!hotkeys || keystrokes == '') {
      return
    }
    // clear keystrokes after 2 seconds to make sure the user really meant to perform the shortcut action, otherwise
    // weird things might happen with buffered keystrokes otherwise
    const timer = setTimeout(() => {
      setKeystrokes('')
    }, 2000)

    const action = Array.from(actions.values())
      .flat()
      .concat(connectActions)
      .find((a) => a.hotkey == keystrokes)

    if (action) {
      setKeystrokes('')
      action.callback()
    } else if (keystrokes.length >= maxKeystrokeLength) {
      // we havent found any action for 'keystrokes', so we reset and go again
      setKeystrokes('')
    }

    return () => clearTimeout(timer)
  }, [keystrokes, actions, connectActions, maxKeystrokeLength, hotkeys])

  // Since Chakra stops bubbling up the keyboard events for MenuLists, we need to have something
  // granular control over the events that get called
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      handleHotkeys(e.key)
    },
    [handleHotkeys]
  )

  useHotkeys(
    '*',
    (e) => {
      if (hotkeys) {
        handleHotkeys(e.key)
      }
    },
    [hotkeys]
  )

  const shouldFlatten = React.useMemo(() => {
    const keys = Array.from(actions.keys()).filter((k) => k != 'Koala')
    return keys.length == 1
  }, [actions])

  const ActionsMenu = () => (
    <Menu
      gutter={1}
      size={props.size}
      defaultIsOpen={props.inline ? props.isOpen : undefined}
      onClose={props.onClose}
      closeOnSelect={false}
      placement="bottom-end"
    >
      {props.inline ? (
        <MenuButton
          as={IconButton}
          variant={props.variant ?? 'solid'}
          size={props.size}
          icon={<IconDotsVertical size={15} />}
          color="gray.500"
          _hover={{ color: 'gray.700' }}
        />
      ) : (
        <MenuButton
          as={Button}
          variant={props.variant ?? 'solid'}
          size={props.size}
          rightIcon={<IconChevronDown size={16} />}
        >
          Actions
        </MenuButton>
      )}
      <Portal>
        <MenuList
          maxW={'fit-content'}
          onKeyDown={handleKeyDown}
          zIndex="popover"
          maxH="min(350px, calc(85vh - var(--header-height)))"
          overflow="auto"
        >
          {(shouldFlatten && <FlatMenu actions={actions} hotkeys={hotkeys} />) || (
            <MenuWithSubmenus actions={actions} hotkeys={hotkeys} />
          )}
          {!!connectActions.length && (
            <>
              <MenuDivider />
              {connectActions.map((a) => {
                return (
                  <MenuItem
                    paddingY={2}
                    paddingX={4}
                    key={a.key}
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={a.callback}
                  >
                    <Text>{a.flatName}</Text>
                    {hotkeys && <Shortcut action={a} />}
                  </MenuItem>
                )
              })}
            </>
          )}
          {canEditProject && (
            <>
              <MenuDivider />
              <MenuItem as="a" color="purple.500" href={projectPath('/apps/actions')}>
                Configure visible actions…
              </MenuItem>
            </>
          )}
        </MenuList>
      </Portal>
    </Menu>
  )

  if (Array.from(actions.values()).flat().length > 0) {
    return (
      <>
        <ActionsMenu key={account.id + ':menu'} />
        <AssignCrmOwnerModal {...assignToCrmProps} />
        {koalaListModal.isOpen && <AddToListModal {...koalaListModal} recordId={account.id} kind="account" />}
      </>
    )
  } else {
    return <></>
  }
}
