import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import { IconX, IconChevronDown, IconCheck } from '@tabler/icons-react'
import { nanoid } from 'nanoid'
import React from 'react'
import { Toggle } from '../accounts/components/Toggle'
import { MatcherPattern } from './manager'

export interface PatternRulesProps {
  matcherPatterns: MatcherPattern[]
  setMatcherPatterns: (patterns: MatcherPattern[]) => void
  isLoading: boolean
  type: 'page' | 'event'
}

export function PatternRules(props: PatternRulesProps) {
  const { matcherPatterns, setMatcherPatterns } = props
  const operatorLabel = (operator) => {
    const operatorMap = {
      exact: 'is',
      starts_with: 'starts with',
      ends_with: 'ends with',
      contains: 'contains',
      matches: 'matches',
      regex: 'regex'
    }
    return operatorMap[operator] || operator
  }

  const updateOperator = (id, newOperator) => {
    const newPatterns = [...matcherPatterns]
    const index = newPatterns.findIndex((p) => p.id === id)
    newPatterns[index].operator = newOperator as MatcherPattern['operator']
    setMatcherPatterns(newPatterns)
  }
  return (
    <FormControl bg="gray.50" rounded="md" p="6">
      <Toggle
        title={
          <FormLabel fontSize={'sm'} fontWeight="semibold" mb="0" cursor={'pointer'}>
            Disabled patterns ({matcherPatterns.length})
          </FormLabel>
        }
      >
        <Stack p="1" spacing="4">
          <Stack>
            <FormHelperText pl="2" mb="0">
              Block all {props.type}s matching the following patterns:
            </FormHelperText>

            {matcherPatterns.map((pattern) => (
              <HStack w="100%" key={pattern.id}>
                <input type="hidden" name="id" value={pattern.id} />
                <Box w="40" position="relative">
                  <Menu placement="bottom-start" strategy="fixed">
                    <MenuButton
                      as={Button}
                      rightIcon={<IconChevronDown size={16} />}
                      size="sm"
                      bg="white"
                      width="full"
                      textAlign="left"
                      height="32px"
                      fontWeight="normal"
                      borderWidth="1px"
                      borderRadius="md"
                      disabled={props.isLoading}
                    >
                      {operatorLabel(pattern.operator)}
                    </MenuButton>
                    <Portal>
                      <MenuList minW="280px" boxShadow="lg">
                        <MenuItem
                          onClick={() => updateOperator(pattern.id, 'exact')}
                          position="relative"
                          paddingRight="8"
                        >
                          <VStack align="start" spacing="0" width="full">
                            <Text fontWeight={pattern.operator === 'exact' ? 'medium' : 'normal'}>is</Text>
                            <Text fontSize="xs" color="gray.500" fontWeight="normal">
                              exact match (e.g. /about)
                            </Text>
                            {pattern.operator === 'exact' && (
                              <Box position="absolute" right="3" top="50%" transform="translateY(-50%)">
                                <IconCheck size={16} color="green" />
                              </Box>
                            )}
                          </VStack>
                        </MenuItem>
                        <MenuItem
                          onClick={() => updateOperator(pattern.id, 'starts_with')}
                          position="relative"
                          paddingRight="8"
                        >
                          <VStack align="start" spacing="0" width="full">
                            <Text fontWeight={pattern.operator === 'starts_with' ? 'medium' : 'normal'}>
                              starts with
                            </Text>
                            <Text fontSize="xs" color="gray.500" fontWeight="normal">
                              match beginning (e.g. /blog/)
                            </Text>
                          </VStack>
                          {pattern.operator === 'starts_with' && (
                            <Box position="absolute" right="3" top="50%" transform="translateY(-50%)">
                              <IconCheck size={16} color="green" />
                            </Box>
                          )}
                        </MenuItem>
                        <MenuItem
                          onClick={() => updateOperator(pattern.id, 'ends_with')}
                          position="relative"
                          paddingRight="8"
                        >
                          <VStack align="start" spacing="0" width="full">
                            <Text fontWeight={pattern.operator === 'ends_with' ? 'medium' : 'normal'}>ends with</Text>
                            <Text fontSize="xs" color="gray.500" fontWeight="normal">
                              match ending (e.g. .pdf)
                            </Text>
                          </VStack>
                          {pattern.operator === 'ends_with' && (
                            <Box position="absolute" right="3" top="50%" transform="translateY(-50%)">
                              <IconCheck size={16} color="green" />
                            </Box>
                          )}
                        </MenuItem>
                        <MenuItem
                          onClick={() => updateOperator(pattern.id, 'contains')}
                          position="relative"
                          paddingRight="8"
                        >
                          <VStack align="start" spacing="0" width="full">
                            <Text fontWeight={pattern.operator === 'contains' ? 'medium' : 'normal'}>contains</Text>
                            <Text fontSize="xs" color="gray.500" fontWeight="normal">
                              match substring (e.g. product)
                            </Text>
                          </VStack>
                          {pattern.operator === 'contains' && (
                            <Box position="absolute" right="3" top="50%" transform="translateY(-50%)">
                              <IconCheck size={16} color="green" />
                            </Box>
                          )}
                        </MenuItem>
                        <MenuItem
                          onClick={() => updateOperator(pattern.id, 'matches')}
                          position="relative"
                          paddingRight="8"
                        >
                          <VStack align="start" spacing="0" width="full">
                            <Text fontWeight={pattern.operator === 'matches' ? 'medium' : 'normal'}>matches</Text>
                            <Text fontSize="xs" color="gray.500" fontWeight="normal">
                              use glob patterns such as partial * matches (e.g. /blog/*, /customers/*/page)
                            </Text>
                          </VStack>
                          {pattern.operator === 'matches' && (
                            <Box position="absolute" right="3" top="50%" transform="translateY(-50%)">
                              <IconCheck size={16} color="green" />
                            </Box>
                          )}
                        </MenuItem>
                        <MenuItem
                          onClick={() => updateOperator(pattern.id, 'regex')}
                          position="relative"
                          paddingRight="8"
                        >
                          <VStack align="start" spacing="0" width="full">
                            <Text fontWeight={pattern.operator === 'regex' ? 'medium' : 'normal'}>regex</Text>
                            <Text fontSize="xs" color="gray.500" fontWeight="normal">
                              use regex patterns
                            </Text>
                          </VStack>
                          {pattern.operator === 'regex' && (
                            <Box position="absolute" right="3" top="50%" transform="translateY(-50%)">
                              <IconCheck size={16} color="green" />
                            </Box>
                          )}
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </Box>
                <Input
                  bg="white"
                  size="sm"
                  rounded="md"
                  required
                  disabled={props.isLoading}
                  placeholder="Enter a pattern to ignore."
                  value={pattern.pattern}
                  onChange={(e) => {
                    const newPatterns = [...matcherPatterns]
                    const index = newPatterns.findIndex((p) => p.id === pattern.id)
                    newPatterns[index].pattern = e.target.value
                    setMatcherPatterns(newPatterns)
                  }}
                />
                <IconButton
                  aria-label="Remove"
                  icon={<IconX size={14} />}
                  size="sm"
                  onClick={() => {
                    setMatcherPatterns(matcherPatterns.filter((p) => p.id !== pattern.id))
                  }}
                  variant="ghost"
                />
              </HStack>
            ))}

            <HStack justifyContent={'flex-end'} pr="10">
              <Button
                size="sm"
                colorScheme={'purple'}
                variant="outline"
                disabled={props.isLoading}
                onClick={() => {
                  setMatcherPatterns([
                    ...matcherPatterns,
                    {
                      id: nanoid(),
                      operator: 'contains',
                      pattern: ''
                    }
                  ])
                }}
              >
                New Pattern
              </Button>
            </HStack>
          </Stack>

          <Button variant={'solid'} size="sm" colorScheme={'purple'} type="submit" isLoading={props.isLoading}>
            Save Changes
          </Button>
        </Stack>
      </Toggle>
    </FormControl>
  )
}
