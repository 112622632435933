import {
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  TextProps,
  useOutsideClick
} from '@chakra-ui/react'
import { IconLock, IconLockOpen } from '@tabler/icons-react'
import dayjs from 'dayjs'
import DOMPurify from 'dompurify'
import React, { forwardRef, useContext, useEffect, useMemo } from 'react'
import { Entitlements } from '../pages/billing/show'
import { EntitlementsV2 } from '../pages/billing/v2'
import { openUpgradeFlow } from './billing-banners/accounts-banner'
import GrayAccountContext from './GrayAccountsContext'
import { HoverCard } from './HoverCard'
import { useCurrentProject } from './ProjectsContext'
import { useHover } from './use-hover'

interface Seen {
  last_seen_at?: Date | string
  limited?: boolean
}

const emptyObject = {}

export function useEntitlements() {
  const project = useCurrentProject()
  return project?.koala_subscription?.entitlements
}

export const blockedStyle: BoxProps = {
  pointerEvents: 'none',
  filter: 'grayscale(100%)',
  userSelect: 'none',
  cursor: 'not-allowed',
  shadow: 'sm',
  bg: 'gray.50'
}

type AllEntitlements = Partial<Entitlements> & Partial<EntitlementsV2>

interface RedactedCellProps {
  element: Seen
  showLock?: boolean
  entitlements?: AllEntitlements
  children?: React.ReactNode
  flexProps?: FlexProps
  type?: 'Account' | 'Visitor'
}

interface RedactedTextProps extends TextProps {
  redacted?: boolean
}

export const RedactedText = forwardRef(function RedactedText(
  { redacted, ...props }: RedactedTextProps,
  ref: React.Ref<HTMLDivElement>
) {
  const isRedacted = redacted ?? true
  const content = ' '.repeat(props.children?.toString().length ?? 0)

  const { setIsGrayAccountVisible } = useContext(GrayAccountContext)

  useEffect(() => {
    if (isRedacted) {
      setIsGrayAccountVisible(true)
    }

    return () => {
      if (isRedacted) {
        setIsGrayAccountVisible(false)
      }
    }
  }, [setIsGrayAccountVisible, isRedacted])

  if (!isRedacted) {
    return (
      <Text as="span" ref={ref} {...props}>
        {props.children}
      </Text>
    )
  }

  return (
    <Flex ref={ref} bg="#f3f3f6" px="1">
      <Text as="pre" color="#f3f3f6" {...props}>
        {content}
      </Text>
    </Flex>
  )
})

export function AccountUpgrade(
  props: RedactedCellProps & { colorScheme?: ButtonProps['colorScheme']; type: 'Account' | 'Visitor' }
) {
  const [isRedacted, reason] = isLimitedAccountWithReason(props.entitlements, props.element)

  const reasons = {
    retention: `This ${props.type} falls outside of your Workspace's data retention period of <strong>${props.entitlements?.ui_retention} days.</strongs>`,
    limited: `Your workspace is limited to <strong>${
      props.entitlements?.maa ?? props.entitlements?.accounts
    } Koala Accounts</strong>. Upgrade your plan to unlock this ${props.type}.`
  }

  if (!isRedacted) {
    return null
  }

  return (
    <Stack fontSize={'sm'} spacing="4">
      <HStack>
        <IconLock size="20" />
        <Heading size="sm">Locked {props.type}</Heading>
      </HStack>
      <Text dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reasons[reason!]) }} />
      <Button
        leftIcon={<IconLockOpen size={14} />}
        colorScheme={props.colorScheme}
        size="sm"
        onClick={() => {
          openUpgradeFlow()
        }}
      >
        Unlock {props.type}
      </Button>
    </Stack>
  )
}

export function RedactedAccountCell(props: RedactedCellProps) {
  const [isRedacted, reason] = useMemo(
    () => isLimitedAccountWithReason(props.entitlements, props.element),
    [props.entitlements, props.element]
  )
  const showLock = props.showLock ?? true
  const [cardVisible, setCardVisible] = React.useState(false)
  const [ref, isHovered] = useHover()
  const { setIsGrayAccountVisible, setGrayAccountReason } = useContext(GrayAccountContext)

  useOutsideClick({
    ref: ref,
    handler: () => setCardVisible(false)
  })

  useEffect(() => {
    if (isRedacted) {
      setIsGrayAccountVisible(true)
      setGrayAccountReason(reason ?? undefined)
    }

    return () => {
      if (isRedacted) {
        setIsGrayAccountVisible(false)
        setGrayAccountReason(undefined)
      }
    }
  }, [setIsGrayAccountVisible, isRedacted, reason, setGrayAccountReason])

  if (isRedacted) {
    return (
      <Flex w="100%" h="100%" alignItems={'center'} {...props.flexProps}>
        {showLock && (
          <HoverCard
            isPortal
            isOpen={cardVisible}
            hoverContent={
              <Stack maxW="320px" py={3} px={1}>
                <AccountUpgrade type={props.type ?? 'Account'} colorScheme={'purple'} {...props} />
              </Stack>
            }
          >
            <IconButton
              pointerEvents="all"
              onClick={(e) => {
                e.preventDefault()
                setCardVisible(true)
              }}
              ref={ref as any}
              aria-label="Redacted"
              width="24px"
              height="24px"
              minWidth="24px"
              icon={isHovered ? <IconLockOpen size="16" /> : <IconLock size="16" />}
            />
          </HoverCard>
        )}
        <RedactedText>Redacted</RedactedText>
      </Flex>
    )
  }

  return <>{props.children}</>
}

export type LimitReason = 'retention' | 'limited'

export function isLimitedAccountWithReason(
  entitlements: AllEntitlements | undefined,
  element: Seen
): [boolean, LimitReason | null] {
  if (element.limited) {
    return [true, 'limited']
  }

  if (!entitlements || element.last_seen_at === undefined) {
    return [false, null]
  }

  if (entitlements?.ui_retention) {
    // Add one full day to ui_retention to account for the fact that we're
    // dealing with different timezones, so people don't see data right at the
    // cutoff.
    const retentionDays = entitlements.ui_retention + 1
    const retention = dayjs().subtract(retentionDays, 'day')
    const isPast = dayjs(element.last_seen_at).isBefore(retention)

    if (isPast) {
      return [true, 'retention']
    }
  }

  return [false, null]
}

export function isLimitedAccount(entitlements: AllEntitlements | undefined, element: Seen): boolean {
  return isLimitedAccountWithReason(entitlements, element)[0]
}

export function blocked(entitlements: AllEntitlements | undefined, element: Seen) {
  if (!entitlements || element.last_seen_at === undefined) {
    return emptyObject
  }

  if (isLimitedAccount(entitlements, element)) {
    return blockedStyle
  }

  return emptyObject
}
