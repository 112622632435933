import React, { useMemo } from 'react'
import { useFieldValues } from '../../data/use-field-values'
import { mergeQuery } from '../../pages/accounts/facets/filter-cloud'
import SelectInput from '../SelectInput'
import { useSearchParams } from '../useSearchState'
import { AdvancedFilters } from './types'

interface Props {
  range?: string | null
  facetValuesPath?: string
  facetFilters?: AdvancedFilters
  selectedTerritoryId?: string | null
  onChange?: (territoryId: string | null) => void
}

const allAccounts = { key: '', label: 'All accounts' }

export function TerritoryFilter(props: Props) {
  const rangeParam = useSearchParams('range')
  const range = props.range ?? rangeParam ?? ''
  const facetValuesPath = props.facetValuesPath ?? '/accounts/facet-values'

  // TODO hook up counts for territories...
  // const facetQuery = facetQueryString(props.facetFilters || {})
  // const path = mergeQuery(facetValuesPath, `?${facetQuery.join('&')}&range=${range}`)
  const path = mergeQuery(facetValuesPath, `?range=${range}`)

  const territoryData = useFieldValues('territory', path)

  const territories = useMemo(() => {
    const all = [allAccounts]
    const rest = territoryData.data?.values.map((t) => ({ key: t.key, label: t.label || t.key })) ?? []
    return all.concat(rest)
  }, [territoryData.data?.values])

  const selectedTerritory = useMemo(() => {
    return territories.find((t) => t.key === props.selectedTerritoryId)
  }, [territories, props.selectedTerritoryId])

  if (territories.length <= 1) {
    return null
  }

  return (
    <SelectInput
      size="sm"
      variant="outline"
      placeholder="All accounts"
      items={territories}
      selectedItem={selectedTerritory}
      itemToString={(item) => item?.label || item?.key || 'All accounts'}
      onSelectedItemChange={({ selectedItem }) => props.onChange?.(selectedItem?.key)}
      popperOptions={{
        matchWidth: true
      }}
    />
  )
}
