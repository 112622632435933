import {
  Badge,
  Box,
  Button,
  Flex,
  FlexProps,
  Grid,
  Heading,
  Link,
  Stack,
  Tab,
  Table,
  TableColumnHeaderProps,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IconArrowDown, IconArrowRight, IconArrowUp, IconSelector } from '@tabler/icons-react'
import { capitalize } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { greeting } from '../../../lib/dayjs'
import { User } from '../../../types/Invite'
import Avatar from '../../ui/Avatar'
import { Card } from '../../ui/Card'
import PageLayout from '../../ui/PageLayout'
import PageTitle, { SmallPageHeading } from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import { TopBarContent } from '../../ui/TopBarContext'
import { useCurrentUser } from '../../ui/UserContext'
import { BarGraph } from '../analytics/components/BarChart'
import { mergeParams } from '../icps/types'

interface ByUser {
  user:
    | null
    | (User & {
        active?: boolean
      })
  stats: {
    total: number
    pending: number
    completed: number
    dismissed: number
    reasons: {
      total: number
      [key: string]: number
    }
  }
}

interface StatsTableRow {
  id: string
  name: string
  image?: string
  email?: string
  active?: boolean
  stats: {
    total: number
    pending: number
    completed: number
    dismissed: number
  }
}

interface Props {
  range: 'day' | 'week' | 'month' | 'quarter'
  users: ByUser[]
  stats: Array<{
    date: string
    stats: { total: number; pending: number; completed: number; dismissed: number }
  }>
  reasons: Array<{
    date: string
    stats: { total: number; [key: string]: number }
  }>
  plays: Array<{
    play: {
      id: string
      name: string
      target_type: string
    }
    stats: {
      total: number
      pending: number
      completed: number
      dismissed: number
    }
  }>
}

const groupByOptions = [
  { label: 'By User', value: 'user' },
  { label: 'By Play', value: 'play' }
]

const rowHover = {
  bg: 'gray.50'
}

export default function InboxDashboard(props: Props) {
  const range = props.range
  const currentUser = useCurrentUser()
  const { hasPermission: canViewAsMember } = usePermission({ on: 'project', action: 'can_view_as_member' })
  const [groupBy, setGroupBy] = React.useState<'user' | 'play'>('user')
  const [sortField, setSortField] = React.useState<SortField | null>('completed')
  const [sortDirection, setSortDirection] = React.useState<SortDirection | null>('desc')

  const tableData: StatsTableRow[] = useMemo(() => {
    if (groupBy === 'play') {
      return props.plays.map(({ play, stats }) => ({
        id: play.id,
        name: play.name,
        stats
      }))
    } else {
      return props.users.map(({ user, stats }) => ({
        id: user?.id || 'unassigned',
        name: user?.name || user?.email || 'Unassigned',
        email: user?.email,
        image: user?.image,
        active: user?.active,
        stats
      }))
    }
  }, [props.plays, props.users, groupBy])

  const sortedTableData = useMemo(() => {
    if (!sortField) return tableData

    return [...tableData].sort((a, b) => {
      const multiplier = sortDirection === 'asc' ? 1 : -1

      if (sortField === 'name') {
        return multiplier * a.name.localeCompare(b.name)
      }

      return multiplier * (a.stats[sortField] - b.stats[sortField])
    })
  }, [tableData, sortField, sortDirection])

  const totals = useMemo(() => {
    return props.stats.reduce(
      (acc, curr) => {
        return {
          total: acc.total + curr.stats.total,
          pending: acc.pending + curr.stats.pending,
          completed: acc.completed + curr.stats.completed,
          dismissed: acc.dismissed + curr.stats.dismissed
        }
      },
      { total: 0, pending: 0, completed: 0, dismissed: 0 }
    )
  }, [props.stats])

  const handleSort = useCallback(
    (field: SortField) => {
      if (sortField === field) {
        if (sortDirection === 'desc') {
          setSortDirection('asc')
        } else if (sortDirection === 'asc') {
          setSortField(null)
          setSortDirection('desc')
        }
      } else {
        setSortField(field)
        setSortDirection('desc')
      }
    },
    [sortField, sortDirection]
  )

  return (
    <PageLayout size="full" flush gap={0} maxH="100%" minH="300px">
      <PageTitle skipRendering>Inbox Dashboard</PageTitle>
      <TopBarContent>
        <Flex width="100%" alignItems="center" gap={3} justifyContent="space-between">
          {canViewAsMember ? (
            <Tabs size="sm" variant="subtle" isManual defaultIndex={0} index={0}>
              <TabList>
                <Tab>Dashboard</Tab>
                <Tab as="a" href={projectPath('/inbox')}>
                  Inbox
                </Tab>
                <Tab as="a" href={projectPath('/inbox/unassigned')}>
                  Unassigned
                </Tab>
              </TabList>
            </Tabs>
          ) : (
            <SmallPageHeading>Inbox Dashboard</SmallPageHeading>
          )}
        </Flex>
      </TopBarContent>

      <Flex flexDir="column" height="100%">
        <Stack padding={6} spacing={8} flex="1" overflow="auto">
          <Flex width="100%" alignItems="center" gap={3} justifyContent="space-between">
            <Stack spacing={0}>
              <Text fontSize="sm" fontWeight="semibold">
                {greeting()}, {currentUser.firstName} 👋
              </Text>
              <Text fontSize="sm" color="gray.500">
                Here's the latest across your team's inboxes
              </Text>
            </Stack>

            <SegmentedControl size="sm">
              <Button
                isActive={range === 'day'}
                as={Link}
                href={mergeParams(window.location.toString(), { range: 'day' })}
              >
                Today
              </Button>
              <Button
                isActive={range === 'week'}
                as={Link}
                href={mergeParams(window.location.toString(), { range: 'week' })}
              >
                Last 7 days
              </Button>
              <Button
                isActive={range === 'month'}
                as={Link}
                href={mergeParams(window.location.toString(), { range: 'month' })}
              >
                Last 30 days
              </Button>
            </SegmentedControl>
          </Flex>
          <Grid gridTemplateColumns="1fr 1px 1fr 1px 1fr 1px 1fr" gap={[2, 4, 6, 10]}>
            <StatCard
              title="Pending"
              value={totals.pending}
              series={props.stats.map((day) => ({ timestamp: day.date, count: day.stats.pending }))}
            />
            <Box width="1px" bg="gray.200" />
            <StatCard
              title="Engaged"
              value={totals.completed}
              series={props.stats.map((day) => ({ timestamp: day.date, count: day.stats.completed }))}
            />
            <Box width="1px" bg="gray.200" />
            <StatCard
              title="Dismissed"
              value={totals.dismissed}
              series={props.reasons.map((day) => ({ timestamp: day.date, ...day.stats }))}
            />
            <Box width="1px" bg="gray.200" />
            <StatCard
              title="Total leads"
              value={totals.total}
              series={props.stats.map((day) => ({ timestamp: day.date, count: day.stats.total }))}
            />
          </Grid>

          <Card p={0}>
            <Flex gap={4} alignItems="center" px={2} py={2}>
              <SegmentedControl size="xs">
                {groupByOptions.map((t) => (
                  <Button
                    key={t.value}
                    isActive={groupBy === t.value}
                    onClick={() => setGroupBy(t.value as 'user' | 'play')}
                  >
                    {t.label}
                  </Button>
                ))}
              </SegmentedControl>
            </Flex>
            <TableContainer>
              <Table size="sm" variant="bordered" w="100%" height="1px">
                <Thead>
                  <Tr>
                    <SortableHeader
                      field="name"
                      currentSortField={sortField}
                      currentSortDirection={sortDirection}
                      onSort={handleSort}
                    >
                      {groupBy === 'user' ? 'User' : 'Play'}
                    </SortableHeader>
                    <SortableHeader
                      field="total"
                      currentSortField={sortField}
                      currentSortDirection={sortDirection}
                      onSort={handleSort}
                      isNumeric
                    >
                      Total Leads
                    </SortableHeader>
                    <SortableHeader
                      field="pending"
                      currentSortField={sortField}
                      currentSortDirection={sortDirection}
                      onSort={handleSort}
                      isNumeric
                    >
                      Pending
                    </SortableHeader>
                    <SortableHeader
                      field="completed"
                      currentSortField={sortField}
                      currentSortDirection={sortDirection}
                      onSort={handleSort}
                      isNumeric
                    >
                      Completed
                    </SortableHeader>
                    <SortableHeader
                      field="dismissed"
                      currentSortField={sortField}
                      currentSortDirection={sortDirection}
                      onSort={handleSort}
                      isNumeric
                    >
                      Dismissed
                    </SortableHeader>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr fontWeight="semibold" bg="gray.50">
                    <Td>Totals</Td>
                    <Td width="120px" isNumeric>
                      {totals.total.toLocaleString()}
                    </Td>
                    <Td width="120px" isNumeric>
                      {totals.pending.toLocaleString()}
                    </Td>
                    <Td width="120px" isNumeric>
                      {totals.completed.toLocaleString()}
                    </Td>
                    <Td width="120px" isNumeric>
                      {totals.dismissed.toLocaleString()}
                    </Td>
                  </Tr>
                  {sortedTableData.map((row) => (
                    <Tr key={row.id} _hover={rowHover}>
                      <Td>
                        <Flex gap={2} alignItems="center" justifyContent="space-between">
                          <Flex gap={1.5} alignItems="center">
                            {row.image && <Avatar size="tiny" src={row.image} name={row.name} />}
                            <Text fontWeight="medium" isTruncated>
                              {row.name}
                            </Text>
                          </Flex>
                          <Flex gap={2} alignItems="center">
                            {row.active === false && (
                              <Badge variant="regular" colorScheme="gray" fontSize="xs">
                                Inactive
                              </Badge>
                            )}
                            <Button
                              size="xs"
                              variant="outline"
                              rightIcon={<IconArrowRight size={14} />}
                              iconSpacing={1}
                              as={Link}
                              href={
                                groupBy === 'user'
                                  ? projectPath(`/inbox/${row.email || 'unassigned'}`)
                                  : projectPath(`/plays/${row.id}`)
                              }
                            >
                              View
                            </Button>
                          </Flex>
                        </Flex>
                      </Td>
                      <Td width="120px" isNumeric>
                        {row.stats.total.toLocaleString()}
                      </Td>
                      <Td width="120px" isNumeric>
                        {row.stats.pending.toLocaleString()}
                      </Td>
                      <Td width="120px" isNumeric>
                        {row.stats.completed.toLocaleString()}
                      </Td>
                      <Td width="120px" isNumeric>
                        {row.stats.dismissed.toLocaleString()}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
        </Stack>
      </Flex>
    </PageLayout>
  )
}

const statSizes = {
  sm: {
    label: 'xs',
    value: 'sm'
  },
  md: {
    label: '13px',
    value: 'lg'
  }
}

interface StatCardProps extends FlexProps {
  title: string
  value: number
  size?: 'sm' | 'md'
  series?: BarGraphSeries[]
}

interface BarGraphSeries {
  timestamp: string
  [key: string]: string | number
}

function StatCard({ title, value, size = 'md', series, ...props }: StatCardProps) {
  const yDataKeys = series ? Object.keys(series[0]).filter((key) => key !== 'timestamp') : []
  const label = yDataKeys.length <= 1 ? title : Object.fromEntries(yDataKeys.map((key) => [key, capitalize(key)]))

  return (
    <Flex maxW="100%" minW={series ? '100px' : '60px'} flexDirection="column" gap={1} {...props}>
      <Text fontSize={statSizes[size].label} color="gray.500">
        {title}
      </Text>
      <Heading size={statSizes[size].value} fontWeight="semibold">
        {value?.toLocaleString()}
      </Heading>
      {series && (
        <BarGraph
          label={label}
          data={series}
          height={40}
          xDataKey="timestamp"
          yDataKeys={yDataKeys}
          xAxis={false}
          yAxis={false}
          legend={false}
          colorScheme="purple"
          monochrome
          minPointSize={1}
          // manually specify the y domain so the bars aren't full height when there's no data
          yDomain={!value ? [0, 100] : undefined}
        />
      )}
    </Flex>
  )
}

type SortField = 'name' | 'total' | 'pending' | 'completed' | 'dismissed' | null
type SortDirection = 'asc' | 'desc'

interface SortIconProps {
  field: SortField
  currentSortField: SortField
  currentSortDirection: SortDirection | null
}

function SortIcon({ field, currentSortField, currentSortDirection }: SortIconProps) {
  if (currentSortField !== field) return <IconSelector size={14} />
  return currentSortDirection === 'asc' ? <IconArrowUp size={14} /> : <IconArrowDown size={14} />
}

interface SortableHeaderProps extends TableColumnHeaderProps {
  field: SortField
  currentSortField: SortField | null
  currentSortDirection: SortDirection | null
  children: React.ReactNode
  onSort: (field: SortField) => void
}

function SortableHeader({
  field,
  currentSortField,
  currentSortDirection,
  children,
  onSort,
  ...props
}: SortableHeaderProps) {
  return (
    <Th {...props} cursor="pointer" onClick={() => onSort(field)} _hover={{ bg: 'gray.50', color: 'purple.500' }}>
      <Flex
        gap={1}
        alignItems="center"
        justifyContent={field === 'name' && !props.isNumeric ? 'space-between' : 'flex-end'}
      >
        {children}
        <SortIcon field={field} currentSortField={currentSortField} currentSortDirection={currentSortDirection} />
      </Flex>
    </Th>
  )
}
