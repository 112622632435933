import { MenuItemProps, MenuItem, useDisclosure } from '@chakra-ui/react'
import React, { useCallback, useRef } from 'react'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import { PreviewUserDrawer } from './preview-user-drawer'

interface Props {
  menuItemProps?: MenuItemProps
  formRef: React.RefObject<HTMLFormElement>
}

export function NotificationRulePreviewUser(props: Props) {
  const [loading, setIsLoading] = React.useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const controllerRef = useRef<AbortController>()

  const handleClose = useCallback(() => {
    onClose()

    setTimeout(() => {
      controllerRef.current?.abort()
    }, 0)
  }, [onClose])

  return (
    <>
      <PreviewUserDrawer
        isOpen={isOpen}
        onClose={handleClose}
        isLoading={loading}
        setIsLoading={setIsLoading}
        formRef={props.formRef}
      />

      <MenuItem
        icon={<SearchIcon size="14" />}
        iconSpacing={1.5}
        onClick={onOpen}
        isDisabled={loading}
        flex="1"
        {...props.menuItemProps}
      >
        [Internal] Preview User
      </MenuItem>
    </>
  )
}
