import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList
} from '@chakra-ui/react'

import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import SettingsHeader from '../../ui/SettingsHeader'
import { NamedFilter } from '../../ui/filters/types'
import { SearchBar } from '../accounts/facets/search-bar'
import { IconDotsVertical, IconEdit, IconTrash, IconUsers } from '@tabler/icons-react'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { BuildingIcon } from '../../ui/icons/BuildingIcon'
import { TimeAgo } from '../../ui/TimeAgo'
import { toast } from 'sonner'
import { projectPath } from '../../ui/ProjectsContext'
import { NamedFilterFormModal } from './components/formModal'
import { capitalize } from 'lodash'
import { navigate } from 'wouter/use-browser-location'

interface Props {
  named_filters: NamedFilter[]
  named_filter: NamedFilter
}

export default function Index(props: Props) {
  const [namedFilters, setNamedFilters] = useState<NamedFilter[]>(props.named_filters)
  const [isNamedFilterFormModalOpen, setIsNamedFilterFormModalOpen] = useState(false)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredNamedFilters, setFilteredNamedFilters] = useState<NamedFilter[]>(namedFilters)
  const [localNamedFilter, setLocalNamedFilter] = useState<NamedFilter | null>(null)

  useEffect(() => {
    if (searchQuery) {
      const filteredNamedFilters = namedFilters.filter((namedFilter) =>
        namedFilter.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      setFilteredNamedFilters(filteredNamedFilters)
    } else {
      setFilteredNamedFilters(namedFilters)
    }
  }, [searchQuery, namedFilters])

  useEffect(() => {
    if (props.named_filter) {
      setLocalNamedFilter(props.named_filter)
      setIsNamedFilterFormModalOpen(true)
    }
  }, [props.named_filter])

  return (
    <PageLayout size="full" gap={0}>
      <SettingsHeader divider={true}>
        <PageTitle size="lg">Named Filters</PageTitle>
        <PageDescription>Define filters that could be used in other parts of the app.</PageDescription>
      </SettingsHeader>

      <Box width="100%" position="relative" paddingY={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4} gap={3}>
          <SearchBar
            size="sm"
            placeholder="Search named filters"
            onChange={(value) => setSearchQuery(value)}
            debounce={300}
            inputProps={{ autoCapitalize: 'off', autoComplete: 'off' }}
          />
          <Button
            flex="none"
            size="sm"
            colorScheme="purple"
            onClick={() => {
              setLocalNamedFilter({ target_type: 'Account' } as NamedFilter)
              setIsNamedFilterFormModalOpen(true)
            }}
          >
            Create
          </Button>
        </Flex>

        <TableContainer w="100%">
          <Table size="sm" variant="unstyled">
            <Thead borderBottom="1px solid" borderColor="border.lightest">
              <Tr>
                <Th fontSize="xs" height="32px" pl={9}>
                  Name
                </Th>
                <Th fontSize="xs">Created</Th>
                <Th fontSize="xs"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredNamedFilters.map((namedFilter) => (
                <Tr
                  key={namedFilter.id}
                  bg={'white'}
                  py={1.5}
                  px={1.5}
                  rounded="md"
                  fontSize="sm"
                  shadow={'none'}
                  position="relative"
                  cursor="unset"
                  role="group"
                >
                  <Td>
                    <Flex gap={2} alignItems="center">
                      <Tooltip
                        label={namedFilter.target_type === 'Account' ? 'Companies' : 'People'}
                        shouldWrapChildren
                      >
                        {namedFilter.target_type === 'Account' ? (
                          <Icon as={BuildingIcon} boxSize={4} />
                        ) : (
                          <Icon as={IconUsers} boxSize={4} />
                        )}
                      </Tooltip>
                      <Flex flex="1 1 auto" gap={1.5} alignItems="center">
                        <Text fontWeight="medium" isTruncated>
                          {namedFilter.name}
                        </Text>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td>
                    <TimeAgo time={namedFilter.created_at} />
                  </Td>
                  <Td>
                    <Flex gap={2} alignItems="center">
                      <NamedFilterMenu
                        onEditClick={() => {
                          setLocalNamedFilter(namedFilter)
                          setIsNamedFilterFormModalOpen(true)
                        }}
                        onDeleteClick={() => {
                          setLocalNamedFilter(namedFilter)
                          setIsDeleteConfirmationModalOpen(true)
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <NamedFilterFormModal
        namedFilter={localNamedFilter}
        isOpen={isNamedFilterFormModalOpen}
        onClose={() => {
          setIsNamedFilterFormModalOpen(false)
          navigate(projectPath('/settings/named-filters'))
        }}
        onSave={(namedFilter) => {
          const updatedNamedFilter = namedFilters.find((nf) => nf.id === namedFilter.id)
          if (updatedNamedFilter) {
            setNamedFilters(namedFilters.map((nf) => (nf.id === namedFilter.id ? namedFilter : nf)))
          } else {
            setNamedFilters([...namedFilters, namedFilter])
          }
          setIsNamedFilterFormModalOpen(false)
          toast.success('Named filter successfully updated')
        }}
      />

      <DeleteNamedFilterModal
        namedFilter={localNamedFilter}
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onConfirm={() => {
          setNamedFilters(namedFilters.filter((nf) => nf.id !== localNamedFilter?.id))
          setIsDeleteConfirmationModalOpen(false)
          toast.success('Named filter successfully deleted')
        }}
      />
    </PageLayout>
  )
}

function DeleteNamedFilterModal(props: {
  namedFilter: NamedFilter | null
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}) {
  const { namedFilter, isOpen, onClose, onConfirm } = props

  useEffect(() => {}, [isOpen, namedFilter])

  return (
    <DeleteConfirmation
      key={namedFilter?.id}
      title="Remove named filter?"
      confirmLabel="Yes, continue"
      isCentered
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
      deletePath={projectPath(`/named-filters/${namedFilter?.id}`)}
    >
      <Stack spacing={4}>
        <Text>
          Are you sure you want to remove the <strong>{namedFilter?.name}</strong> named filter?
        </Text>
        {Object.values(namedFilter?.used_in || {}).length > 0 && (
          <Stack spacing={2}>
            <Text>This named filter is used in the following places:</Text>
            {Object.entries(namedFilter?.used_in || {}).map(([key, target]) => (
              <>
                <Text key={key}>
                  <strong>{capitalize(key)}:</strong> {target.length}
                </Text>
                <UnorderedList>
                  {target.map((t) => (
                    <ListItem key={t.id}>
                      <Link href={projectPath(`/${key}/${t.id}`)} isExternal>
                        {t.name}
                      </Link>
                    </ListItem>
                  ))}
                </UnorderedList>
              </>
            ))}
          </Stack>
        )}
      </Stack>
    </DeleteConfirmation>
  )
}

function NamedFilterMenu(props: { onEditClick: () => void; onDeleteClick: () => void }) {
  const { onEditClick, onDeleteClick } = props

  return (
    <Menu placement="bottom-end" autoSelect={false}>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon as={IconDotsVertical} boxSize={3.5} />}
        variant="ghost"
        size="xs"
      />
      <Portal>
        <MenuList zIndex="popover">
          <MenuItem icon={<IconEdit size={16} />} onClick={onEditClick}>
            Edit
          </MenuItem>
          <MenuItem color="red.500" icon={<IconTrash size={16} />} onClick={onDeleteClick}>
            Delete
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}
