import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export function useIcpIdeas(seed = 1) {
  const project = useCurrentProject()
  const basePath = `/ai-icp?seed=${seed}`

  const path = projectPath(basePath)

  return useQuery<{ ideas: string[] }>({
    queryKey: ['ai-icp', { projectId: project?.id, seed }],
    queryFn: () => concurrentGET<{ ideas: string[] }>(path),
    enabled: Boolean(project?.id)
  })
}
