import React, { useMemo, FormEvent, useState } from 'react'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import Avatar from './Avatar'
import {
  useDisclosure,
  UseDisclosureProps,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Button,
  Flex,
  HStack,
  Spinner,
  Stack,
  FormControl,
  FormLabel,
  Box
} from '@chakra-ui/react'
import { toast } from 'sonner'
import { postForm } from '../../lib/api'
import { Prospect } from '../data/use-prospects'
import { useAppDep } from '../data/use-app-dep'
import { ProfileRecord } from '../../types/Profile'
import { App } from '../../types/App'
import { TextEllipsis } from './text-ellipsis'
import { projectPath } from './ProjectsContext'
import { ComboboxWithSearch } from './ComboboxWithSearch'
import { useCurrentUser } from './UserContext'
import pluralize from 'pluralize'
import capitalize from 'lodash/capitalize'

export type ApolloSequence = {
  id: string
  name: string
}

export type ApolloEmailAccount = {
  id: string
  email?: string
}

interface AddToApolloProps extends UseDisclosureProps {
  app?: App
  selectedProfiles: Prospect[] | ProfileRecord[] | []
  profileType?: 'Profile' | 'ProspectedProfile'
  profileLabel?: string
  skipAnonymousProfiles?: boolean | false
}

export function AddToApolloSequenceModal(props: AddToApolloProps) {
  const { selectedProfiles, skipAnonymousProfiles, profileType, profileLabel } = props
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const currentUser = useCurrentUser()
  const [saving, setSaving] = React.useState(false)

  const [selectedSequence, setSelectedSequence] = React.useState<ApolloSequence | null>(null)

  const profilesWithoutEmail = (selectedProfiles as any[]).filter((p) => !p.email)
  const profilesToAdd = skipAnonymousProfiles ? (selectedProfiles as any[]).filter((p) => !!p.email) : selectedProfiles
  const targetLabel = profileLabel || (profileType == 'Profile' ? 'people' : 'prospects')
  const targetPluralizedLabel = pluralize(targetLabel, profilesToAdd.length, profilesToAdd.length > 1)

  const {
    data: sequenceData,
    isLoading: loadingSequences,
    isError: sequencesError,
    error: sequencesErrorData
  } = useAppDep<'sequences', ApolloSequence[]>('Apollo', 'sequences')

  const {
    data: emailAccountData,
    isLoading: loadingEmailAccounts,
    isError: emailAccountsError,
    error: _emailAccountsErrorData
  } = useAppDep<'email_accounts', ApolloEmailAccount[]>('Apollo', 'email_accounts')
  const [selectedEmailAccount, setSelectedEmailAccount] = React.useState<ApolloEmailAccount | null>(null)

  const seq = useMemo(
    () => selectedSequence ?? sequenceData?.data?.sequences?.[0] ?? null,
    [selectedSequence, sequenceData]
  )

  const emailAccount = useMemo(
    () =>
      selectedEmailAccount ??
      emailAccountData?.data?.email_accounts?.find((ea) => ea.email === currentUser.email) ??
      null,
    [selectedEmailAccount, emailAccountData, currentUser]
  )

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      if (!seq) return

      setSaving(true)

      const form = e.target as HTMLFormElement
      const data = new FormData(form)
      const profileIds = profilesToAdd.map((p) => p.id) ?? []
      profileIds.forEach((profileId) => data.append('profile_ids[]', profileId))

      postForm(projectPath(`/apollo/actions/bulk-sequences?&profile_type=${props.profileType ?? 'Profile'}`), data)
        .then(() => {
          toast.success(`${capitalize(targetLabel)} added to Sequence!`, {
            description: `${targetPluralizedLabel} has been added to ${seq.name} in Apollo.`
          })
          setSaving(false)
          onClose()
        })
        .catch((e) => {
          toast.error(`Error adding ${targetLabel} to Sequence`, {
            description: e.message
          })
          setSaving(false)
        })
    },
    [seq, profilesToAdd, props.profileType, onClose, targetLabel, targetPluralizedLabel]
  )

  const [showMoreProfiles, setShowMoreProfiles] = useState(false)
  const toggleShowMoreProfiles = () => {
    setShowMoreProfiles(!showMoreProfiles)
  }

  return (
    <Modal {...disclosure} size="md" isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize="md" display="flex" gap={2} pb={1}>
          <CompanyAvatar
            size="20px"
            domain={'apollo.io'}
            mr={2}
            src={'https://asset.brandfetch.io/ideEin4YhC/id-83Yirn2.png'}
          />
          Add to Apollo sequence
        </ModalHeader>
        <ModalBody pb={6}>
          <form onSubmit={onSubmit}>
            <Stack fontSize="sm" spacing={4}>
              <FormControl>
                <>
                  <FormLabel>Sequence</FormLabel>
                  {loadingSequences && <Spinner size="sm" />}
                  {sequencesErrorData && (
                    <Text p="4" bg="orange.50" m="2">
                      {' '}
                      {(sequencesErrorData as any)?.body?.error}
                    </Text>
                  )}
                  {!sequencesError && !loadingSequences && (
                    <ComboboxWithSearch
                      items={sequenceData?.data?.sequences ?? []}
                      selectedItem={seq}
                      onChange={(selectedItem) => {
                        setSelectedSequence(selectedItem)
                      }}
                      filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                      itemToString={(item) => item?.name || ''}
                    />
                  )}
                </>
              </FormControl>

              <FormControl>
                <FormLabel>Send Emails From</FormLabel>
                {loadingEmailAccounts && <Spinner size="sm" />}
                {!loadingEmailAccounts && (
                  <>
                    {!emailAccountsError ||
                      (!emailAccountData?.data?.email_accounts?.length && (
                        <Text p="4" bg="orange.50" m="2">
                          We couldn't find any email accounts to display. Make sure to check the API key permissions in
                          your Apollo workspace.
                        </Text>
                      ))}
                    <ComboboxWithSearch
                      items={emailAccountData?.data?.email_accounts ?? []}
                      selectedItem={emailAccount}
                      onChange={(selectedItem) => {
                        setSelectedEmailAccount(selectedItem)
                      }}
                      filterItem={(a, val) => (a?.email ?? '').toLowerCase().includes(val)}
                      itemToString={(item) => item?.email || ''}
                      placeholder="Select a sender"
                    />
                  </>
                )}
              </FormControl>
            </Stack>
            <Stack fontSize="sm" spacing={2} mt={4}>
              <Text fontSize="xs" color="gray.500" fontWeight="semibold">
                SELECTED {pluralize(targetLabel, profilesToAdd.length).toUpperCase()}:
              </Text>
              <Flex direction="column" alignItems="space-between" gap={3}>
                {profilesWithoutEmail && profilesWithoutEmail.length > 0 && !skipAnonymousProfiles && (
                  <Stack spacing="4" p="2">
                    <Text fontSize="sm" color="gray.600" lineHeight="1.4">
                      You've selected {pluralize(targetLabel, profilesWithoutEmail.length, true)} whose emails have not
                      been enriched yet. They will be enriched if you proceed and will use enrichment credits in the
                      future.
                    </Text>
                    <Text whiteSpace={'pre-wrap'} fontSize="xs" lineHeight="1.4" p="4" bg="purple.50" color="gray.700">
                      Note: we're currently offering unlimited Koala Waterfall enrichment credits during our open beta.
                    </Text>
                  </Stack>
                )}
                {profilesToAdd && (
                  <>
                    {profilesToAdd.length > 5 ? (
                      <>
                        {profilesToAdd.slice(0, 4).map((selectedProfile) => (
                          <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                            <HStack>
                              <Avatar
                                size="xs"
                                name={selectedProfile.display_name}
                                src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                              />
                              <Text>{selectedProfile.display_name || selectedProfile.email}</Text>
                            </HStack>
                            <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                              {selectedProfile.title}
                            </TextEllipsis>
                          </Flex>
                        ))}
                        {showMoreProfiles &&
                          profilesToAdd.slice(4, profilesToAdd.length).map((selectedProfile) => (
                            <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                              <HStack>
                                <Avatar
                                  size="xs"
                                  name={selectedProfile.name}
                                  src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                                />
                                <Text>{selectedProfile.name}</Text>
                              </HStack>
                              <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                {selectedProfile.title}
                              </TextEllipsis>
                            </Flex>
                          ))}
                        <Text onClick={toggleShowMoreProfiles} color="purple.500" align="center" cursor="pointer">
                          {showMoreProfiles
                            ? `- Hide ${profilesToAdd.length - 4} ${targetLabel}`
                            : `+ Show ${profilesToAdd.length - 4} more ${targetLabel}`}
                        </Text>
                      </>
                    ) : (
                      <>
                        {profilesToAdd.map((selectedProfile) => (
                          <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                            <HStack>
                              <Avatar
                                size="xs"
                                name={selectedProfile.name}
                                src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                              />
                              <Text>{selectedProfile.name}</Text>
                            </HStack>
                            <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                              {selectedProfile.title}
                            </TextEllipsis>
                          </Flex>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Flex>
            </Stack>
            {profilesWithoutEmail && profilesWithoutEmail.length > 0 && skipAnonymousProfiles && (
              <Stack spacing="4" p="2">
                <Text fontSize="xs" color="gray.500">
                  {pluralize(`anonymous ${targetLabel}`, profilesWithoutEmail.length, true)} selected won't be sequenced
                </Text>
              </Stack>
            )}
            <Flex w="100%" pt="4">
              <Button
                w="100%"
                size="sm"
                colorScheme={'purple'}
                type="submit"
                isLoading={saving || loadingSequences}
                isDisabled={
                  sequencesError ||
                  emailAccountsError ||
                  !emailAccount ||
                  !emailAccountData?.data?.email_accounts?.length ||
                  !profilesToAdd.length
                }
              >
                Add {targetPluralizedLabel} to Apollo Sequence
              </Button>
            </Flex>
            {seq && <input type="hidden" name="sequence[sequence_id]" value={seq.id} />}
            {emailAccount && <input type="hidden" name="sequence[email_account_id]" value={emailAccount.id} />}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
