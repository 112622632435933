import { AuthenticityToken } from '@app/components/ui/AuthenticityToken'
import { useDomains } from '@app/components/ui/DomainContext'
import { usePermission } from '@app/components/ui/PermissionsContext'
import { projectPath, useCurrentProject } from '@app/components/ui/ProjectsContext'
import { useCurrentUser } from '@app/components/ui/UserContext'
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useInterval,
  VStack
} from '@chakra-ui/react'
import { snakeCase } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useApp } from '../../../data/use-app'

export interface ConnectOauthAppDialogProps {
  app_id: string
  title: string
  valid?: boolean
  connected?: boolean
  origin?: string
  allowNonAdmins?: boolean
  children?: React.ReactNode
  // an optional action for the form
  action?: string
}

export interface PopupConnectDialogProps {
  app_id: string
  active?: boolean
  onConnected?: (connected: boolean) => void
  children: (props: { onStart: () => void; connected: boolean }) => React.ReactNode
}

export const PopupConnectDialog: React.FunctionComponent<PopupConnectDialogProps> = (props) => {
  const appState = useApp(props.app_id)
  const connected = useMemo(() => appState.data?.connected ?? false, [appState.data?.connected])
  const valid = useMemo(() => appState.data?.valid, [appState.data])
  const active = useMemo(() => props.active, [props.active])

  const ref = useRef<Window | null>(null)

  useInterval(
    useCallback(() => {
      if (active && (!connected || !valid)) {
        appState.refetch()
      }

      if (connected && valid) {
        props.onConnected?.(connected)
        ref.current?.close()
      }
    }, [active, connected, valid, appState, props]),
    5_000
  )

  useEffect(() => {
    if (connected && valid) {
      props.onConnected?.(connected)
    }

    if (connected && ref.current && valid) {
      ref.current.close()
    }
  }, [connected, valid, props])

  const connectFlow = () => {
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,left=500,top=200,noopener,noreferrer`
    const popup = window.open(projectPath(`/apps/${props.app_id}?nav=false`), 'connect-flow', params)
    ref.current = popup
  }

  return <>{props.children({ onStart: connectFlow, connected: connected })}</>
}

export function ConnectOauthAppDialog(props: ConnectOauthAppDialogProps) {
  const { title, valid, connected, origin } = props
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const domains = useDomains()

  const appId = snakeCase(props.app_id)
  const projectSlug = useCurrentProject()?.slug

  const isKoalaProject = projectSlug === 'koala'
  const isKoalaUser = domains.findMatches(useCurrentUser().email).length > 0
  const reconnect = React.useMemo(() => connected && !valid, [connected, valid])

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [koalaAdminIsSure, setKoalaAdminIsSure] = React.useState(false)
  const doubleCheckForKoalaAdmin = isKoalaUser && !isKoalaProject
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  const canConnect = canEditProject || props.allowNonAdmins

  const handleFormSubmit = React.useCallback(
    (e) => {
      if (doubleCheckForKoalaAdmin) {
        onOpen()
        e.preventDefault()
      }
    },
    [doubleCheckForKoalaAdmin, onOpen]
  )

  return (
    <>
      {(!connected || reconnect) && (
        <Stack>
          {!canConnect && (
            <>
              <Alert status="warning" p="6" my="4">
                <Stack>
                  <HStack spacing="0">
                    <AlertIcon />
                    <AlertTitle>Not enough permissions</AlertTitle>
                  </HStack>
                  <AlertDescription fontSize={'sm'} lineHeight="1.5">
                    Your Koala workspace security settings requires that only Workspace Admins are allowed to connect{' '}
                    {title}. Please reach out to an Admin in your organization to get {title} connected.
                  </AlertDescription>
                </Stack>
              </Alert>
            </>
          )}
          <form
            action={
              props.action ??
              `/auth/${appId}?project=${projectSlug}&app_id=${appId}${origin ? `&origin=${origin}` : ''}`
            }
            method="post"
            onSubmit={handleFormSubmit}
          >
            <AuthenticityToken />
            <VStack w="100%" alignItems={'normal'} spacing={4}>
              {props.children}
              {!props.children && (
                <>
                  {(reconnect && (
                    <Alert display={'block'} status={'warning'}>
                      <AlertIcon />
                      <VStack>
                        <AlertTitle mr={2}>Your {title} Credentials are expired!</AlertTitle>
                        <AlertDescription>Please reconnect your {title} Account.</AlertDescription>
                      </VStack>
                    </Alert>
                  )) || (
                    <Box>
                      <Heading size="sm">Connect your {title} Account</Heading>
                      <Text fontSize="sm" color="gray.600">
                        Please connect your {title} account in order to get started.
                      </Text>
                    </Box>
                  )}
                  <Button colorScheme={reconnect ? 'pink' : 'purple'} type="submit" isDisabled={!canConnect}>
                    {reconnect ? 'Reconnect' : `Log in with ${title}`}
                  </Button>
                </>
              )}
            </VStack>
          </form>

          <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Connect {title}
                </AlertDialogHeader>

                <AlertDialogBody paddingY={6}>
                  <Text marginTop={2} marginBottom={2}>
                    You are a Koala admin but this is not a Koala workspace,{' '}
                    <b>
                      are you sure you want to connect {title} for {projectSlug}?
                    </b>
                  </Text>
                  <Checkbox checked={koalaAdminIsSure} onChange={(e) => setKoalaAdminIsSure(e.target.checked)}>
                    yes, I want to connect {title} for {projectSlug}
                  </Checkbox>
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Box
                    as="form"
                    method="post"
                    action={
                      props.action ??
                      `/auth/${appId}?project=${projectSlug}&app_id=${appId}${origin ? `&origin=${origin}` : ''}`
                    }
                  >
                    <AuthenticityToken />
                    <Button ref={cancelRef} variant="outline" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      colorScheme="red"
                      ml={3}
                      isDisabled={!canConnect || (doubleCheckForKoalaAdmin && !koalaAdminIsSure)}
                    >
                      Connect
                    </Button>
                  </Box>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Stack>
      )}
    </>
  )
}
