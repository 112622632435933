import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Link,
  SkeletonCircle,
  Stack,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { FollowRule } from '../../../../types/FollowRule'
import { Notification } from '../../notifications'
import { NotificationEntry } from '../../notifications/components/notification'
import { notificationsPath } from '../../notifications/lib/path-helper'

interface Props {
  notifications: Notification[]
  followRule: FollowRule
  loading: boolean
  isOpen: boolean
  onClose: () => void
}

export function LogsDrawer(props: Props) {
  const { notifications, loading, isOpen, onClose } = props
  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader bg="gray.50" w="100%">
            <HStack w="100%">
              <Text>Recent Automation Runs</Text>
              <Link
                isExternal
                href={notificationsPath(`?rule_id=${props.followRule.id}`)}
                color={'purple.500'}
                fontSize="sm"
              >
                (See all)
              </Link>
            </HStack>
          </DrawerHeader>

          <DrawerBody bg="gray.50" py="8">
            {loading && (
              <Stack spacing="4">
                <HStack bg="white" p="4">
                  <SkeletonCircle size={'8'} />
                </HStack>
                <HStack bg="white" p="4">
                  <SkeletonCircle size={'8'} />
                </HStack>
                <HStack bg="white" p="4">
                  <SkeletonCircle size={'8'} />
                </HStack>
              </Stack>
            )}

            <Stack w="100%" spacing="8" divider={<Divider />}>
              {notifications.map((notification) => {
                return <NotificationEntry key={notification.id} {...notification} isLog />
              })}

              <Button
                as={Link}
                isExternal
                href={notificationsPath(`?rule_id=${props.followRule.id}`)}
                color={'purple.500'}
                fontSize="sm"
              >
                See all
              </Button>
            </Stack>

            {!loading && notifications.length === 0 && (
              <Text>
                We couldn't find any Account or Visitor matching your automation rules in recent sessions for your
                workspace.
              </Text>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
