import {
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  Flex,
  HStack,
  LinkProps,
  Text,
  Tooltip,
  useBreakpointValue
} from '@chakra-ui/react'
import { IconMessageChatbot, IconSettings } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import throttle from 'lodash/throttle'
import React, { PropsWithChildren } from 'react'
import { useMedia } from 'react-use'
import { subscribeToChannel, SubscriptionEmitter } from '../../channels/generic_channel'
import { put } from '../../lib/api'
import { isMacOS } from '../../lib/isMacOS'
import { AccountView } from '../../types/AccountView'
import { NotificationSettings } from '../../types/NotificationSettings'
import { useAccountViews } from '../data/use-account-views'
import { SetupBadge } from '../pages/onboarding/components/SetupBadge'
import { AccountsBanner } from './billing-banners/accounts-banner'
import { FeedbackForm } from './FeedbackForm'
import { AiSparklesIcon } from './icons'
import { SearchIcon } from './icons/SearchIcon'
import KoalaLogo from './KoalaLogo'
import { useOnlineStatus } from './OnlineStatusContext'
import { projectPath, useCurrentProject } from './ProjectsContext'
import { RootCssVar } from './RootCssVar'
import useLocation from './useLocation'
import { useCurrentUser } from './UserContext'
import { UserMenu } from './UserMenu'

declare global {
  interface Window {
    online_visitors: number
    notification_settings?: NotificationSettings
  }
}

interface Route {
  title: string | React.ReactElement
  href: string
  icon?: React.ReactElement
  exact?: boolean
  active?: boolean | ((location: Location) => boolean)
  hide?: boolean
}

interface Props {
  onSearchClick: () => void
  supportMode?: boolean
}

export function useChatStuff() {
  const project = useCurrentProject()
  const user = useCurrentUser()
  const [visitorCount, setVisitorCount] = React.useState<number>(window.online_visitors || 0)
  const subscription = React.useRef<SubscriptionEmitter | undefined>()
  const { status, updateStatus } = useOnlineStatus()

  // Update the user's "online" status when not explicitly "offline"
  // Unsure if we can kill this yet
  React.useEffect(() => {
    let canceled = false

    if (status === 'away') {
      put(`/me`, { user: { status: 'online' } }).then(() => {
        if (canceled) return
        updateStatus('online')
      })
    }

    return () => {
      canceled = true
    }
  }, [status, updateStatus])

  const handleData = React.useCallback(
    async (data?: any) => {
      if (!project?.slug) {
        return
      }

      if (data?.action === 'agent' && data?.agent?.id === user.id) {
        updateStatus(data.agent.status)
        if (data.agent.status === 'away' && subscription.current) {
          await put(`/me`, { user: { status: 'online' } })
        }
      }

      if (data?.action === 'visitor' && typeof data.online === 'number') {
        setVisitorCount(data.online)
      }
    },
    [project?.slug, updateStatus, user.id]
  )

  React.useEffect(() => {
    if (subscription.current || !project?.slug) {
      return
    }

    subscription.current = subscribeToChannel({
      channel: 'UsersChannel',
      project_slug: project?.slug,
      location: 'menu'
    })

    const sub = subscription.current
    const update = throttle(handleData, 2000)
    sub.on('received', update)

    const resub = () => {
      if (!sub?.subscribed) {
        sub?.subscribe()
        sub?.on('received', update)
      }
    }

    const unsub = () => {
      update.cancel()
      sub?.off('received', update)
      sub?.unsubscribe()
    }

    // unsubscribe when the tab is hidden
    const onVisibilityChange = () => {
      if (document.hidden) {
        unsub()
      } else {
        resub()
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      unsub()
      subscription.current = undefined
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [project?.slug, handleData])

  return {
    visitorCount
  }
}

const UnstyledLink = React.forwardRef(function UnstyledLink(
  props: BoxProps & Pick<LinkProps, 'href' | 'target'>,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <Box
      as="a"
      ref={ref as React.ForwardedRef<HTMLDivElement>}
      flex="none"
      display="flex"
      alignItems="center"
      rounded="full"
      cursor="pointer"
      tabIndex={0}
      userSelect="none"
      _focusVisible={{
        boxShadow: 'outline',
        outline: '2px solid transparent',
        outlineOffset: '2px'
      }}
      _focus={{
        outline: 'none'
      }}
      {...props}
    />
  )
})

function isAccountView(path: string, type: 'account' | 'profile', view: AccountView) {
  if (view.kind !== type) {
    return false
  }

  if (path.includes(`/views/${view.slug}/`) || path.endsWith(`/views/${view.slug}`)) {
    return true
  }

  return false
}

export function TopNavBox(props: {
  boxProps?: BoxProps
  links?: React.ReactNode
  centerContent?: React.ReactNode
  rightContent?: React.ReactNode
  hideLogo?: boolean
  supportMode?: boolean
}) {
  const largeScreen = useMedia('(min-width: 768px) and (min-height: 600px)')
  return (
    <Box
      as="nav"
      background="gray.50"
      borderBottom="1px solid"
      borderColor="gray.200"
      position={largeScreen ? 'sticky' : 'static'}
      top={0}
      zIndex="sticky"
      {...props.boxProps}
    >
      <Flex
        maxWidth="var(--max-container-width)"
        paddingX={[4, 6, 6, 8]}
        paddingY={2}
        margin="0 auto"
        alignItems="center"
        overflowX="auto"
        gap={[3, 4, 8]}
      >
        <Flex flex={['none', 'none', 'none', 'none', '1 1 0px']} alignItems="center" gap={[3, 4, 8]}>
          {!props.hideLogo && (
            <UnstyledLink href="/">
              <KoalaLogo kind="mark" size="28px" />
            </UnstyledLink>
          )}

          <Flex flex="1 0 auto" gap={2} paddingY="5px" alignItems="center">
            {props.links}
          </Flex>
        </Flex>

        <Flex flex="1 0 0px" alignItems="center" justifyContent="center">
          {props.centerContent}
        </Flex>

        <Flex flex={['none', 'none', 'none', 'none', '1 1 0px']} gap={4} justifyContent="flex-end" alignItems="center">
          {props.rightContent}
        </Flex>
      </Flex>

      {props.supportMode && (
        <Box position="relative" top="100%" left={0} right={0}>
          <Box
            position="absolute"
            zIndex={6}
            borderTop="2px solid"
            borderColor="orange.200"
            left={0}
            right={0}
            textAlign="center"
            pointerEvents="none"
            lineHeight={0}
          >
            <Text
              display="inline-flex"
              fontWeight="bold"
              fontSize="10px"
              textTransform="uppercase"
              color="orange.700"
              bg="orange.200"
              lineHeight={1}
              px={2}
              py="3px"
              mt="-1px"
              roundedBottom="md"
            >
              Support Mode
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export function TopNav(props: Props) {
  const location = useLocation()
  const project = useCurrentProject()
  const { data: lists } = useAccountViews()

  const { visitorCount } = useChatStuff()

  const hideNavURL = React.useMemo(() => location.search.includes('nav=false'), [location.search])

  const routes: Route[] = React.useMemo(() => {
    if (!project) {
      return []
    }

    return [
      {
        title: 'Live',
        href: projectPath('/live')
      },
      {
        title: 'Accounts',
        href: projectPath('/accounts'),
        active: (loc) =>
          loc.pathname.includes('/accounts') ||
          loc.pathname.includes('/views/mine') ||
          (lists?.account_views?.some((v) => isAccountView(loc.pathname, 'account', v)) ?? false)
      },
      {
        title: 'People',
        href: projectPath('/people'),
        active: (loc) =>
          loc.pathname.includes('/profiles') ||
          loc.pathname.includes('/people') ||
          loc.pathname.includes('/visitors') ||
          (lists?.account_views?.some((v) => isAccountView(loc.pathname, 'profile', v)) ?? false)
      },
      {
        title: (
          <HStack spacing="1">
            <AiSparklesIcon size={16} />
            <Text>Prospector</Text>
          </HStack>
        ),
        href: projectPath('/prospector'),
        active: (loc) => loc.pathname.includes('/prospector')
      },
      // Basic web analytics
      {
        title: 'Analytics',
        href: projectPath('/analytics'),
        hide: project?.slug === 'vercel'
      },
      {
        title: 'Automations',
        href: projectPath('/automations/overview'),
        hide: !project?.show_automations
      }
    ]
  }, [project, lists])

  if (hideNavURL) {
    return null
  }
  if (project?.locked_at) {
    return null
  }

  // Don't show the menu on the confirm pages
  if (location.pathname.startsWith('/confirm') || location.pathname.includes('/welcome')) {
    return null
  }

  return (
    <>
      <RootCssVar property="--nav-height" value="56px" />
      <TopNavBox
        supportMode={props.supportMode}
        links={routes
          .filter((r) => r.href && !r.hide)
          .map((route) => {
            const active = isActive(route)

            let count
            if (route.title === 'Live' && visitorCount > 0) {
              count = format(visitorCount)
            }

            return (
              <UnstyledLink
                key={route.href}
                href={route.href}
                fontSize="sm"
                fontWeight="medium"
                bg={active ? 'purple.50' : undefined}
                color={active ? 'purple.600' : 'gray.700'}
                _hover={{ textDecoration: 'none', bg: active ? 'purple.50' : 'gray.100' }}
                _active={{ outline: 'none' }}
                paddingX={[2, 3]}
                paddingY={[0.5, 1]}
              >
                {route.title}
                {count && <NotificationBadge>{count}</NotificationBadge>}
              </UnstyledLink>
            )
          })}
        centerContent={project && <SearchBar onClick={props.onSearchClick} />}
        rightContent={
          <>
            {project && (
              <>
                <SetupBadge />
                {project.koala_subscription && <AccountsBanner />}
                {project.koala_subscription?.plan !== 'free' && (
                  <FeedbackForm projectSlug={project.slug}>
                    <IconLink title="Feedback/Support" icon={<IconMessageChatbot size={20} />} />
                  </FeedbackForm>
                )}
                <IconLink
                  title="Settings"
                  href={projectPath('/settings')}
                  icon={<IconSettings size={22} />}
                  active={location.pathname.includes('/settings') || location.pathname.includes('/apps')}
                />
              </>
            )}
            <Flex flex="none" alignItems="center">
              <UserMenu />
            </Flex>
          </>
        }
      />
    </>
  )
}

function isActive(route: Partial<Pick<Route, 'active' | 'href' | 'exact'>> = {}) {
  if (typeof route.active === 'boolean') {
    return route.active
  }

  if (typeof route.active === 'function') {
    return route.active(location)
  }

  const [path] = (route.href || '').split('?')

  if (!path) {
    return false
  }

  if (route.exact) {
    return location.pathname === path
  }

  return location.pathname.startsWith(path)
}

function IconLink({ active, exact, href, icon, title, ...rest }: Partial<Route> & BoxProps) {
  active = isActive({ active, href, exact })
  const isSmall = useBreakpointValue({ base: true, sm: true, md: false })

  return (
    <Tooltip label={isSmall ? undefined : title} placement="bottom">
      <UnstyledLink
        as={href ? 'a' : undefined}
        href={href}
        aria-label={title}
        color={active ? 'purple.500' : 'gray.500'}
        rounded="md"
        {...rest}
      >
        {icon}
      </UnstyledLink>
    </Tooltip>
  )
}

export function NotificationBadge(props: { children: React.ReactNode } & BadgeProps) {
  const { children, ...rest } = props

  return (
    <Badge
      flex="none"
      fontSize="x-small"
      borderRadius={999}
      colorScheme="purple"
      variant="solid"
      marginLeft={1}
      marginRight={-0.5}
      css={{ fontVariantNumeric: 'tabular-nums' }}
      {...rest}
    >
      {children}
    </Badge>
  )
}

export function SearchBar({ iconSize, placeholder, ...props }: BoxProps & { iconSize?: number; placeholder?: string }) {
  return (
    <Flex
      flex="1 1 auto"
      minWidth="150px"
      maxWidth="500px"
      ml="auto"
      mr="auto"
      role="group"
      border="1px solid"
      borderColor="gray.200"
      bg="gray.100"
      px={2}
      height="30px"
      rounded="lg"
      alignItems="center"
      color="gray.500"
      cursor="pointer"
      userSelect="none"
      position="relative"
      overflow="hidden"
      fontSize="sm"
      gap={2}
      {...props}
    >
      <SearchIcon size={iconSize || 16} />
      <Box>
        <Text opacity={0.8} isTruncated>
          {placeholder || 'Search for people, accounts, lists...'}
        </Text>
      </Box>
      <Flex
        flex="none"
        gap={0.5}
        marginLeft="auto"
        whiteSpace="nowrap"
        opacity={0}
        _groupHover={{ opacity: 1 }}
        transition="opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
      >
        <KeyboardKey width={isMacOS() ? '18px' : 'auto'}>{isMacOS() ? '⌘' : 'Ctrl'}</KeyboardKey>
        <KeyboardKey>K</KeyboardKey>
      </Flex>
    </Flex>
  )
}

function KeyboardKey(props: PropsWithChildren<BoxProps>) {
  return (
    <Box
      bg="white"
      rounded="md"
      borderWidth="1px"
      fontSize="0.8em"
      whiteSpace="nowrap"
      fontWeight="semibold"
      width="18px"
      height="18px"
      lineHeight={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    />
  )
}
