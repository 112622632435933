import { PageMeta } from '@app/types/PageMeta'
import { BoxProps, Button, ButtonGroup, HStack, Text, useColorModeValue as mode } from '@chakra-ui/react'
import pluralize from 'pluralize'
import React from 'react'
import { scrollToTop } from '../Page'

type BaseTableFooterProps = {
  pageMeta: PageMeta
  page: number | string
  word?: string
  sticky?: boolean
  scrollToTop?: boolean
  isLoading?: boolean
}

interface TableFooterAsLinkProps extends BaseTableFooterProps {
  prevPath: string
  nextPath: string
  setPage?: never
}

interface TableFooterAsStateSetterProps extends BaseTableFooterProps {
  setPage: (page: number) => void
  prevPath?: never
  nextPath?: never
}

type TableFooterProps = (TableFooterAsLinkProps | TableFooterAsStateSetterProps) & { isLoading?: boolean }

export const TableFooter = (props: TableFooterProps & BoxProps) => {
  const textColor = mode('gray.600', 'gray.400')
  const {
    pageMeta,
    page,
    setPage,
    prevPath,
    nextPath,
    word,
    sticky,
    scrollToTop: scrollToTopProps,
    isLoading,
    ...rest
  } = props

  if (pageMeta?.total_pages <= 0) {
    return null
  }

  const meta = pageMeta
  const start = (meta.current_page - 1) * meta.per_page + 1
  const end = Math.min(meta.total_count, meta.current_page * meta.per_page)
  const shouldScroll = scrollToTopProps !== false

  return (
    <HStack
      align="center"
      justify="space-between"
      py={2}
      {...(sticky
        ? {
            position: 'sticky',
            bottom: 0,
            bg: 'white',
            zIndex: 10,
            boxShadow: '0px -3px 3px -3px rgba(48, 49, 61, 0.1)'
          }
        : {})}
      {...rest}
    >
      <Text color={textColor} fontSize="sm" fontWeight="medium">
        {meta.total_count <= meta.per_page ? (
          <>
            {meta.total_count.toLocaleString()} {pluralize(word || 'result', meta.total_count)}
          </>
        ) : (
          <>
            {start.toLocaleString()}-{end.toLocaleString()}{' '}
            <Text as="span" fontWeight="normal" color="gray.500">
              of {meta.total_count.toLocaleString()} {pluralize(word || 'result', meta.total_count)}
            </Text>
          </>
        )}
      </Text>

      <ButtonGroup variant="outline" size="xs">
        {prevPath ? (
          <Button
            as="a"
            rel="prev"
            href={prevPath}
            isDisabled={!pageMeta.prev_page || isLoading}
            onClick={(e) => {
              if (!pageMeta.prev_page) {
                e.preventDefault()
              } else if (shouldScroll) {
                scrollToTop({ behavior: 'smooth' })
              }
            }}
          >
            Previous
          </Button>
        ) : (
          <Button
            isDisabled={!pageMeta.prev_page || isLoading}
            onClick={() => {
              setPage!(pageMeta.prev_page ?? 1)
              if (shouldScroll) {
                scrollToTop({ behavior: 'smooth' })
              }
            }}
          >
            Previous
          </Button>
        )}
        {nextPath ? (
          <Button
            as="a"
            rel="next"
            href={nextPath}
            isDisabled={!pageMeta.next_page || isLoading}
            onClick={(e) => {
              if (!pageMeta.next_page) {
                e.preventDefault()
              } else if (shouldScroll) {
                scrollToTop({ behavior: 'smooth' })
              }
            }}
          >
            Next
          </Button>
        ) : (
          <Button
            isDisabled={!pageMeta.next_page || isLoading}
            onClick={() => {
              setPage!(pageMeta.next_page ?? pageMeta.total_pages ?? 1)
              if (shouldScroll) {
                scrollToTop({ behavior: 'smooth' })
              }
            }}
          >
            Next
          </Button>
        )}
      </ButtonGroup>
    </HStack>
  )
}
