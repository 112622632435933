import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { concurrentCachedGET, expireCachedGET, post, put } from '../../lib/api'
import { AIAgent, PersistedAIAgent } from '../../types/AIAgent'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import { useEntitlements } from '../ui/useEntitlements'

interface AIAgentsResponse {
  agents: AIAgent[]
  my_agents: PersistedAIAgent[]
}

export function useAIAgents(target: 'companies' | 'people' = 'companies') {
  const project = useCurrentProject()
  const basePath = `/settings/agents.json?target=${target}`

  const path = projectPath(basePath)

  return useQuery<AIAgentsResponse>({
    queryKey: ['ai-agents', { projectId: project?.id, target }],
    queryFn: () => concurrentCachedGET<AIAgentsResponse>(path)
  })
}

export function expireAIAgentsCache(target: 'companies' | 'people' = 'companies') {
  expireCachedGET(projectPath(`/settings/agents.json?target=${target}`))
}

interface CreateAIAgentParams {
  name: string
  question: string
  target: string
  slug?: string
  model?: string
  response_type?: string
  response_options: string[]
}

interface CreateAIAgentResponse {
  ai_agent: AIAgent
  errors?: unknown
}

export function useCreateAIAgent() {
  const project = useCurrentProject()
  const queryClient = useQueryClient()

  return useMutation<CreateAIAgentResponse, unknown, CreateAIAgentParams>({
    mutationFn: (params) => post<CreateAIAgentResponse>(projectPath('/settings/agents'), { ai_agent: params }),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ai-agents', { projectId: project?.id }]
      })
    }
  })
}

interface UpdateAIAgentParams {
  id: string
  name: string
  question: string
  target: string
  model?: string
  slug?: string
  response_type?: string
  response_options?: string[]
}

interface UpdateAIAgentResponse {
  ai_agent: PersistedAIAgent
  errors?: unknown
}

export function useUpdateAIAgent() {
  const project = useCurrentProject()
  const queryClient = useQueryClient()

  return useMutation<UpdateAIAgentResponse, unknown, UpdateAIAgentParams>({
    mutationFn: ({ id, ...params }) => put(projectPath(`/settings/agents/${id}`), { ai_agent: params }),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ai-agents', { projectId: project?.id }]
      })
    }
  })
}

export function useAiAgentsEnabled() {
  const { data } = useAIAgents()
  const entitlements = useEntitlements()
  return entitlements?.ai_agents_beta && (data?.my_agents?.length ?? 0) > 0
}
