import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Stack, Text } from '@chakra-ui/react'
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { IconPlus, IconRoute } from '@tabler/icons-react'
import { nanoid } from 'nanoid'
import React, { useCallback, useEffect, useState } from 'react'
import { Apps } from '../../../../types/App'
import { PlayRoutingFallback, PlayRoutingRule, PlayTargetType } from '../../../../types/Play'
import { useTerritories } from '../../../data/use-territories'
import { useUsers } from '../../../data/use-users'
import { GrayCard } from '../../../ui/Card'
import { SortableRule } from './lead-routing-rules/SortableRule'
import { StrategyConfiguration } from './lead-routing-rules/StrategyConfiguration'
import { StrategySelector } from './lead-routing-rules/StrategySelector'

export interface RoutingCondition extends PlayRoutingRule {
  expanded?: boolean
}

function isRuleValid(rule: RoutingCondition | PlayRoutingFallback) {
  if (rule.strategy === 'crm_owner') {
    return true
  }

  if (rule.strategy === 'territory') {
    return (rule.territories || []).length > 0
  }

  if (rule.strategy === 'round_robin' || rule.strategy === 'specific_person') {
    return (rule.assignees || []).length > 0
  }

  return true
}

interface LeadRoutingRulesProps {
  initialRules: RoutingCondition[]
  initialFallback: PlayRoutingFallback
  initialAccountAffinity?: boolean
  apps: Apps
  targetType: PlayTargetType
  setRulesAreValid: (isValid: boolean) => void
}

function rulesWithIds(rules?: RoutingCondition[] | null): RoutingCondition[] {
  if (!rules) {
    return []
  }

  return rules.map((rule) => {
    if (rule.id) {
      return rule
    }

    return { ...rule, id: nanoid() }
  })
}

export default function LeadRoutingRules({
  initialRules,
  initialFallback,
  initialAccountAffinity,
  apps,
  targetType,
  setRulesAreValid
}: LeadRoutingRulesProps) {
  const [rules, setRules] = useState<RoutingCondition[]>(rulesWithIds(initialRules))
  const [fallbackStrategy, setFallbackStrategy] = useState(initialFallback)
  const [accountAffinity, _setAccountAffinity] = useState(initialAccountAffinity ?? false)
  const users = useUsers()
  const { data: territoryData } = useTerritories()

  useEffect(() => {
    if (!rules || !fallbackStrategy) {
      return
    }

    setRulesAreValid(rules.every((rule) => isRuleValid(rule)) && isRuleValid(fallbackStrategy))
  }, [rules, fallbackStrategy, setRulesAreValid])

  const handleAddRule = useCallback(() => {
    setRules((prev) => {
      const newRule = {
        id: nanoid(),
        title: `Rule`,
        position: prev.length + 1,
        expanded: true,
        strategy: 'crm_owner' as const,
        filters: {},
        assignees: [],
        territories: []
      }

      return [...prev, newRule]
    })
  }, [])

  const handleRemoveRule = useCallback((id: string) => {
    setRules((prev) => prev.filter((rule) => rule.id !== id))
  }, [])

  const toggleRuleExpansion = useCallback((id: string) => {
    setRules((prev) =>
      prev.map((rule) => {
        if (rule.id === id) {
          return { ...rule, expanded: !rule.expanded }
        }
        return rule
      })
    )
  }, [])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = useCallback((event: any) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      setRules((prev) => {
        const oldIndex = prev.findIndex((item) => item.id === active.id)
        const newIndex = prev.findIndex((item) => item.id === over.id)
        return arrayMove(prev, oldIndex, newIndex).map((rule, index) => ({ ...rule, position: index + 1 }))
      })
    }
  }, [])

  return (
    <GrayCard flexDirection="column" gap={5}>
      <Flex alignItems="center" gap={4}>
        <Flex flex="none" alignItems="center" justifyContent="center" boxSize={6}>
          <IconRoute size={20} />
        </Flex>
        <Box flex="1 1 auto">
          <FormLabel cursor="pointer" mb={0}>
            Lead Routing Rules
          </FormLabel>
          <Text fontSize="13px" lineHeight="19px" color="gray.500">
            Configure how leads are assigned to team members. Rules are evaluated in order.
          </Text>
        </Box>
      </Flex>

      <input type="hidden" name="play[assignment_config][maintain_account_affinity]" value="false" />

      {rules.length > 0 && (
        <>
          <FormControl>
            <Checkbox
              name="play[assignment_config][maintain_account_affinity]"
              defaultChecked={accountAffinity}
              value="true"
            >
              If an account is already assigned in any Play, continue routing leads to the same person
            </Checkbox>
          </FormControl>

          <Box position="relative">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={rules} strategy={verticalListSortingStrategy}>
                <Stack spacing={2}>
                  {rules.map((rule, index) => (
                    <SortableRule
                      index={index}
                      key={rule.id}
                      rule={rule}
                      toggleExpansion={() => toggleRuleExpansion(rule.id)}
                      onRemove={() => handleRemoveRule(rule.id)}
                      apps={apps}
                      territories={territoryData?.territories || []}
                      users={users.data?.users || []}
                      targetType={targetType}
                      setRule={(updated) => setRules((prev) => prev.map((r) => (r.id === updated.id ? updated : r)))}
                      isValid={isRuleValid(rule)}
                    />
                  ))}
                </Stack>
              </SortableContext>
            </DndContext>
          </Box>
        </>
      )}

      <Button variant="outline" bg="white" size="md" leftIcon={<IconPlus size={16} />} onClick={handleAddRule}>
        Add Rule
      </Button>

      {rules.length > 0 && (
        <Box borderWidth="1px" borderColor="gray.200" borderRadius="md" p={4} bg="white">
          <Stack spacing={4}>
            <Stack spacing={0}>
              <Text fontSize="sm" fontWeight="medium">
                Fallback Strategy
              </Text>
              <Text fontSize="13px" color="gray.500">
                If none of the above rules match, use this strategy:
              </Text>
            </Stack>
            <Box>
              <StrategySelector
                selectedStrategy={fallbackStrategy.strategy}
                setSelectedStrategy={(strategy) => setFallbackStrategy({ ...fallbackStrategy, strategy })}
              />
            </Box>

            <StrategyConfiguration
              rule={{
                ...fallbackStrategy,
                id: nanoid(),
                title: 'Fallback',
                position: 0,
                filters: {}
              }}
              territories={territoryData?.territories || []}
              users={users.data?.users || []}
              fieldToSubmit="play[assignment_config][fallback]"
              strategy={fallbackStrategy.strategy}
              setRule={(rule) => setFallbackStrategy(rule)}
              isValid={isRuleValid(fallbackStrategy)}
            />
          </Stack>
        </Box>
      )}

      <input type="hidden" name="play[assignment_config][fallback][strategy]" value={fallbackStrategy.strategy} />
    </GrayCard>
  )
}
