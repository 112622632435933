import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Res {
  valid: boolean
}

export function useInstantlyKey(key: string) {
  const project = useCurrentProject()

  return useQuery<Res>({
    queryKey: ['instantly-key', { projectId: project?.id, key }],
    queryFn: () => concurrentGET<Res>(projectPath(`/apps/instantly/validate?key=${key}`)),
    enabled: Boolean(project?.id && key)
  })
}
