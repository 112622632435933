import {
  Box,
  Divider,
  HStack,
  IconButton,
  Link,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList
} from '@chakra-ui/react'
import { IconCheck, IconPlayerPlay } from '@tabler/icons-react'
import omit from 'lodash/omit'
import React, { useCallback, useEffect } from 'react'
import { toast } from 'sonner'
import { post } from '../../../lib/api'
import { pluralize } from '../../../lib/pluralize'
import router from '../../../lib/router'
import { Account } from '../../../types/Account'
import { PageMeta } from '../../../types/PageMeta'
import { ProfileRecord } from '../../../types/Profile'
import { AccountSelector, PartialAccount } from '../../ui/AccountSelector'
import { Breadcrumb } from '../../ui/Breadcrumb'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { Iconify } from '../../ui/Iconify'
import { JSONTree } from '../../ui/json-tree'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TableFooter } from '../../ui/TableFooter'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { TimeAgo } from '../../ui/TimeAgo'
import { useSearchParams } from '../../ui/useSearchState'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { PartialVisitor, VisitorSelector } from '../../ui/VisitorSelector'
import { Toggle } from '../accounts/components/Toggle'
import { titleize } from '../accounts/facets/filter-cloud'
import { accountPath } from '../accounts/lib/account-path'
import { mergeParams } from '../icps/types'
import { automationPath, automationsPath, notificationsPath } from '../notifications/lib/path-helper'
import { profilePath } from '../profiles/lib/path'
import { channelLogos } from './components/delivery-setup'
import { FollowRule } from '../../../types/FollowRule'

interface FollowRuleLog {
  id: string
  triggered_at: string
  account?: Account
  profile?: ProfileRecord
  context: any
  skipped_at?: string
  skipped_reason?: string
  delivered: boolean
  status: string
  delivery_errors?: Record<string, string[]>
  intent: Array<{
    summary: string
    data: any
    type: string
    condition: {
      title: string
    }
  }>
}

interface Props {
  logs: FollowRuleLog[]
  follow_rule: FollowRule
  page_meta: PageMeta
}

function IntentLine({ intent }: { intent: FollowRuleLog['intent'] }) {
  const first = intent[0]

  if (!first) {
    return null
  }

  return (
    <Stack fontSize="xs">
      <TextEllipsis maxW="200" tooltip>
        {first.condition.title}
      </TextEllipsis>
      {intent.length > 1 && (
        <Toggle title={`(and ${intent.length - 1} more)`} showIcon={false}>
          <Stack>
            {intent.slice(1).map((i) => (
              <TextEllipsis maxW="180" key={i.type} tooltip>
                {i.condition.title}
              </TextEllipsis>
            ))}
          </Stack>
        </Toggle>
      )}
    </Stack>
  )
}

function NotificationRetry({ log }: { log: FollowRuleLog }) {
  const [isPreviewing, setIsPreviewing] = React.useState(false)
  const [failed, setFailed] = React.useState(false)
  const [delivered, setDelivered] = React.useState(log.delivered)

  useEffect(() => {
    if (failed) {
      const timer = setTimeout(() => setFailed(false), 5000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [failed])

  const onNotificationSend = useCallback((log: FollowRuleLog) => {
    const sentTo = Object.keys(omit(log.context?.delivery_rules ?? {}, ['team_member', 'delay_minutes']))

    setIsPreviewing(true)
    post(notificationsPath(`/${log.id}/retry`))
      .then(() => {
        toast.success(
          `${pluralize(sentTo.length, 'Automation rule', 'Automation rules', false)} ${
            sentTo.length > 0 ? 'sent to:' : 'delivered'
          } ${sentTo.join(', ')}`,
          {
            description: `Check your ${pluralize(sentTo.length, 'inbox', 'inboxes', false)}!`
          }
        )
        setIsPreviewing(false)
        setDelivered(true)
      })
      .catch((error) => {
        setFailed(true)
        setIsPreviewing(false)
        if (!log.delivered) {
          setDelivered(false)
        }

        toast.error('Failed to test automation', {
          description: `${error?.message} ${JSON.stringify(error.body, null, 2)}`
        })
      })
  }, [])

  return (
    <Tooltip label={delivered ? 'Deliver again' : log.status.includes('Pending') ? 'Deliver' : 'Retry delivery'}>
      <IconButton
        size="xs"
        icon={delivered && log.delivered != delivered ? <IconCheck size={12} /> : <IconPlayerPlay size={12} />}
        aria-label="Deliver"
        colorScheme={delivered ? 'green' : log.status.includes('Pending') ? 'gray' : 'orange'}
        variant={'outline'}
        onClick={() => onNotificationSend(log)}
        isLoading={isPreviewing}
        isDisabled={isPreviewing || failed}
      />
    </Tooltip>
  )
}

export default function Logs(props: Props) {
  const targetType = props.follow_rule.target_type
  const [selectedRecord, setSelectedRecord] = React.useState<PartialAccount | PartialVisitor | null>()

  const search = useSearchParams()

  useUpdateEffect(() => {
    router.visit(mergeParams(window.location.toString(), { target_id: selectedRecord?.id }))
  }, [selectedRecord])

  return (
    <PageLayout size="md">
      <Stack w="100%" spacing="8">
        <Breadcrumb
          paths={[
            { path: projectPath('/automations/overview'), title: 'Automations' },
            { path: automationsPath(), title: 'Actions' },
            { path: automationPath(props.follow_rule.id), title: props.follow_rule.name },
            { path: automationPath(props.follow_rule.id, '/logs'), title: 'Logs' }
          ]}
        />

        <Stack>
          <PageTitle>{props.follow_rule.name} Logs</PageTitle>
          <Divider />
        </Stack>

        <RadioGroup
          onChange={(value) => router.visit(mergeParams(window.location.toString(), { status: value }))}
          defaultValue={(search.searchParams.status ?? 'all') as string}
          size="sm"
        >
          <HStack>
            <Radio value="all">All</Radio>
            <Radio value="pending">Pending</Radio>
            <Radio value="delivered">Delivered</Radio>
            <Radio value="skipped">Skipped</Radio>
            <Radio value="failed">Failed</Radio>
          </HStack>
        </RadioGroup>

        {targetType === 'Account' && (
          <AccountSelector
            selectedAccount={selectedRecord as PartialAccount}
            onChange={(account) => setSelectedRecord(account)}
          />
        )}

        {targetType === 'Profile' && (
          <VisitorSelector
            selectedVisitor={selectedRecord as PartialVisitor}
            onChange={(visitor) => setSelectedRecord(visitor)}
          />
        )}

        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Actions</Th>
                <Th>Account</Th>
                <Th>Visitor</Th>
                <Th>Triggered At</Th>
                <Th>Delivered</Th>
                <Th>Intent</Th>
                <Th>Destinations</Th>
                <Th>Full Context</Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.logs.map((log) => (
                <Tr key={log.id}>
                  <Td>
                    <NotificationRetry log={log} />
                  </Td>
                  <Td>
                    <Link href={log.account ? accountPath(log.account) : undefined}>
                      <HStack>
                        <CompanyAvatar size="xs" domain={log.account?.domain} />
                        <TextEllipsis maxW="180" tooltip>
                          {log.account?.domain ?? 'unkown'}
                        </TextEllipsis>
                      </HStack>
                    </Link>
                  </Td>
                  <Td>
                    <Link href={log.profile ? profilePath(log.profile) : undefined}>
                      <TextEllipsis maxW="180" tooltip>
                        {log.profile?.email ?? log.profile?.id}
                      </TextEllipsis>
                    </Link>
                  </Td>
                  <Td>
                    <TimeAgo time={log.triggered_at} />
                  </Td>
                  <Td
                    color={
                      log.delivered
                        ? 'green.500'
                        : log.status.includes('Pending')
                          ? 'gray.500'
                          : log.skipped_at
                            ? 'orange.500'
                            : 'red.500'
                    }
                    fontWeight="semibold"
                  >
                    {log.status === 'Delivered' ? 'Yes' : log.status}
                  </Td>
                  <Td>
                    <IntentLine intent={log.intent} />
                  </Td>
                  <Td maxW={'200px'}>
                    <Stack>
                      {Object.keys(omit(log.context.delivery_rules, 'team_member', 'delay_minutes')).map((key) => {
                        const ctx = log.context.delivery_rules[key]
                        return (
                          <Box key={key}>
                            <HStack spacing={'1'}>
                              <Iconify icon={channelLogos[key]} />
                              <Text fontSize={'xs'} fontWeight={'semibold'}>
                                {titleize(key)}
                              </Text>
                            </HStack>
                            <Stack spacing="4">
                              <UnorderedList fontSize={'xs'} pl="2" pt="0">
                                {!ctx?.delivered && <ListItem>Not delivered</ListItem>}
                                {ctx?.delivery_attempts && (
                                  <ListItem>{ctx?.delivery_attempts.length} attempts</ListItem>
                                )}
                                {ctx?.last_delivery_attempt && (
                                  <ListItem>
                                    Last attempt: <TimeAgo time={ctx.last_delivery_attempt} />
                                  </ListItem>
                                )}
                              </UnorderedList>
                              {log.delivery_errors && log.delivery_errors[key] && (
                                <Stack fontSize={'small'} maxW="200px">
                                  <Divider />
                                  <Text fontWeight={'semibold'}>Errors: ({log.delivery_errors[key].length})</Text>
                                  {log.delivery_errors[key].map((error) => (
                                    <Text
                                      maxW="200px"
                                      key={error}
                                      style={{
                                        // @ts-ignore trust me! This exists
                                        textWrap: 'balance'
                                      }}
                                    >
                                      {error}
                                    </Text>
                                  ))}
                                </Stack>
                              )}
                            </Stack>
                          </Box>
                        )
                      })}
                    </Stack>
                  </Td>
                  <Td>
                    <JSONTree data={{ ctx: omit(log.context, 'matches') }} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <TableFooter
            pageMeta={props.page_meta}
            nextPath={mergeParams(window.location.toString(), { page: (props.page_meta.current_page + 1).toString() })}
            prevPath={mergeParams(window.location.toString(), { page: (props.page_meta.current_page - 1).toString() })}
            page={props.page_meta.current_page}
          />
        </TableContainer>
      </Stack>
    </PageLayout>
  )
}
