import {
  Box,
  Button,
  Checkbox,
  Code,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'
import {
  IconArrowMergeAltRight,
  IconLocation,
  IconChevronLeftPipe,
  IconChevronRightPipe,
  IconCirclePlus,
  IconCirclesRelation,
  IconClick,
  IconEdit,
  IconEye,
  IconFileText,
  IconHeartbeat,
  IconIdBadge,
  IconTriangleSquareCircle,
  IconUserPlus,
  IconWorld,
  IconWorldPlus,
  IconDownload
} from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../lib/api'
import dayjs from '../../../lib/dayjs'
import router from '../../../lib/router'
import Avatar from '../../ui/Avatar'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { CompanyBubble } from '../../ui/CompanyBubble'
import { Copyable } from '../../ui/Copyable'
import { JSONTree } from '../../ui/json-tree'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { StackedField } from '../../ui/StackedField'
import { TimeAgo } from '../../ui/TimeAgo'
import { TopBarContent } from '../../ui/TopBarContext'
import { VirtualList } from '../../ui/VirtualList'
import { humanize } from '../accounts/facets/filter-cloud'
import { accountPath } from '../accounts/lib/account-path'
import { BarGraph } from '../analytics/components/BarChart'
import DateRangePicker, { getDefaultPresets } from '../analytics/components/DateRangePicker'
import { mergeParams } from '../icps/types'

interface TimelineEvent {
  type: string
  timestamp: string
  raw: any
}

interface Props {
  event_types: string[]
  period: string
  profile: any
  prospect: any
  waterfall: any
  account: any
  timeline: TimelineEvent[]
}

function groupEventsByDate(events: TimelineEvent[]): Record<string, TimelineEvent[]> {
  return events.reduce((acc, event) => {
    const date = new Date(event.timestamp).toDateString()
    if (!acc[date]) acc[date] = []
    acc[date].push(event)
    return acc
  }, {})
}

function aggregateEventsByDay(events: TimelineEvent[]) {
  return events.reduce((acc, event) => {
    const date = dayjs(event.timestamp).format('YYYY-MM-DD')
    if (!acc[date]) {
      acc[date] = {}
    }
    if (!acc[date][event.type]) {
      acc[date][event.type] = 0
    }
    acc[date][event.type] += 1
    return acc
  }, {})
}

function transformAggregatedData(aggregatedData) {
  return Object.entries(aggregatedData).map(([date, types]) => ({
    timestamp: date,
    ...(types as any)
  }))
}

const icons = {
  metric: IconHeartbeat,
  event: IconClick,
  page_view: IconWorld,
  form_submission: IconFileText,
  first_seen: IconEye,
  created: IconUserPlus,
  profile_source_first_activity: IconChevronLeftPipe,
  profile_source_last_activity: IconChevronRightPipe,
  profile_trait_created: IconCirclePlus,
  profile_trait_updated: IconEdit,
  profile_merge: IconArrowMergeAltRight,
  account_association_log: IconCirclesRelation,
  profile_session: IconWorldPlus,
  identify: IconIdBadge
}

const names = {
  created: 'Profile Created',
  first_seen: 'First Seen',
  identify: 'Identified',
  form_submission: 'Submitted Form',
  profile_merge: 'Merged Profiles',
  account_association_log: 'Associated Account',
  profile_source_first_activity: 'First Activity From Source',
  profile_source_last_activity: 'Last Activity From Source',
  profile_session: 'Session Started',
  page_view: 'Viewed Page',
  event: 'Event',
  metric: 'Metric',
  profile_trait_created: 'Trait Created',
  profile_trait_updated: 'Trait Updated'
}

const summary = (event: TimelineEvent) => {
  switch (event.type) {
    case 'metric':
      return `${event.raw.name}`
    case 'event':
      return `${event.raw.event}`
    case 'page_view':
      return (
        <>
          Viewed{' '}
          <Link variant="dotted" color="gray.500" href={`https://${event.raw.host + event.raw.path}`} isExternal>
            {event.raw.url.replace('www.', '').replace(/^https?:\/\//, '')}
          </Link>
        </>
      )
    case 'form_submission':
      return (
        <>
          Submitted form on{' '}
          <Link variant="dotted" color="gray.500" href={event.raw.page_url} isExternal>
            {event.raw.page_url.replace('www.', '').replace(/^https?:\/\//, '')}
          </Link>
        </>
      )
    case 'profile_source_first_activity':
      return `First activity on ${event.raw.source}`
    case 'profile_source_last_activity':
      return `Last activity on ${event.raw.source}`
    case 'profile_merge':
      return (
        <>
          Merged Profiles from{' '}
          <Code>
            <Copyable inline copyText={event.raw.from_profile_id}>
              {event.raw.from_profile_id}
            </Copyable>
          </Code>{' '}
          to{' '}
          <Code>
            <Copyable inline copyText={event.raw.to_profile_id}>
              {event.raw.to_profile_id}
            </Copyable>
          </Code>
        </>
      )
    case 'profile_trait_created':
      return (
        <>
          Trait created: <Code>{event.raw.name}</Code> with value <Code>{event.raw.first_value}</Code>
        </>
      )
    case 'profile_trait_updated':
      return (
        <>
          Trait updated: <Code>{event.raw.name}</Code> with value <Code>{event.raw.current_value}</Code> (from{' '}
          <Code>{event.raw.previous_value}</Code>)
        </>
      )
    case 'account_association_log':
      return (
        <>
          Associated to{' '}
          <CompanyBubble
            domain={event.raw.company?.domain}
            name={event.raw.company?.name}
            href={accountPath({ company: event.raw.company })}
            iconRight={false}
            minWidth="40px"
          />{' '}
          via <Code>{event.raw.match_type}</Code>
        </>
      )
    case 'profile_session':
      return (
        <>
          Session started:{' '}
          <Flex as="span" display="inline-flex" alignItems="center" gap={1} flexWrap="wrap">
            {event.raw.ip && (
              <>
                <Code fontSize="13px" display="inline-flex">
                  <Copyable inline copyText={event.raw.ip}>
                    {event.raw.ip}
                  </Copyable>
                  <Link
                    title="Check ip"
                    href={`https://www.iplocation.net/ip-lookup?query=${event.raw.ip}`}
                    isExternal
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconButton
                      aria-label="Check ip"
                      icon={<Icon as={IconLocation} boxSize="15px" />}
                      color="gray.500"
                      size="xs"
                      _hover={{ color: 'purple.500' }}
                    />
                  </Link>
                </Code>
              </>
            )}
            {['city', 'subdivision', 'country_code', 'time_zone']
              .map((k) => event.raw[k])
              .filter(Boolean)
              .join(', ') && (
              <Code fontSize="13px">
                {['city', 'subdivision', 'country_code']
                  .map((k) => event.raw[k])
                  .filter(Boolean)
                  .join(', ')}
                {event.raw.time_zone && ` (${event.raw.time_zone})`}
              </Code>
            )}
            <Code fontSize="13px">
              {['browser', 'os']
                .map((k) => event.raw[k])
                .filter(Boolean)
                .join(', ')}
              {event.raw.device_type && ` (${event.raw.device_type})`}
            </Code>
          </Flex>
        </>
      )
    case 'identify':
      return event.raw.email ? (
        `Identified via ${event.raw.source || 'unknown source'} as ${event.raw.email}`
      ) : (
        <>
          Identify via {event.raw.source || 'unknown source'} with traits{' '}
          <Flex as="span" display="inline-flex" gap={1}>
            {Object.keys(event.raw.traits).map((t) => (
              <Code fontSize="13px" key={t}>
                {t}
              </Code>
            ))}
          </Flex>
        </>
      )
  }
}

interface TimelineProps {
  events: TimelineEvent[]
  selectedEventTypes: string[]
}

function Timeline(props: TimelineProps) {
  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>(props.selectedEventTypes ?? [])
  const [selectedEvent, setSelectedEvent] = useState<TimelineEvent | null>(null)

  useEffect(() => {
    setSelectedEventTypes(props.selectedEventTypes ?? [])
  }, [props.selectedEventTypes])

  const handleEventTypeChange = (eventType: string) => {
    const newEventTypes = selectedEventTypes.includes(eventType)
      ? selectedEventTypes.filter((type) => type !== eventType)
      : [...selectedEventTypes, eventType]

    if (newEventTypes.sort().join(',') !== selectedEventTypes.sort().join(',')) {
      router.visit(mergeParams(window.location.toString(), { event_types: newEventTypes.join(',') }))
      setSelectedEventTypes(newEventTypes)
    }
  }

  const filteredEvents = useMemo(() => {
    if (selectedEventTypes.length === 0) return props.events
    return props.events.filter((event) => selectedEventTypes.includes(event.type))
  }, [props.events, selectedEventTypes])

  const groupedEvents = useMemo(() => groupEventsByDate(filteredEvents), [filteredEvents])

  const flatEvents = useMemo(() => {
    return Object.entries(groupedEvents).flatMap(([date, events]) =>
      events.map((event, index) => ({
        date: index === 0 ? date : null, // Only show date for the first event of each group
        event
      }))
    )
  }, [groupedEvents])

  return (
    <Box>
      <Flex wrap="wrap" rowGap={2} columnGap={4} borderBottom="1px solid" borderColor="border.lightest" pb={4}>
        {Object.keys(names).map((eventType) => (
          <Box key={eventType}>
            <Checkbox
              isChecked={selectedEventTypes.includes(eventType)}
              onChange={() => handleEventTypeChange(eventType)}
            >
              {names[eventType]}
            </Checkbox>
          </Box>
        ))}
      </Flex>

      <Box width="100%" position="relative" fontSize="sm" fontWeight="medium" color="gray.600" mt="-1px">
        <VirtualList
          maxH="500"
          items={flatEvents}
          estimateSize={() => 40}
          renderItem={(item, _index) => {
            const { date, event } = item

            return (
              <Box
                position="relative"
                w="100%"
                key={JSON.stringify(event)}
                _hover={{ bg: 'background.light', '.timeline-date': { opacity: 1 } }}
                borderTop={date ? '1px solid' : 'none'}
                borderColor="border.lightest"
                mt="-1px"
                cursor="pointer"
                onClick={() => setSelectedEvent(event)}
              >
                {!date && (
                  <Box
                    position="absolute"
                    top={0}
                    left="60px"
                    right={0}
                    width="100%"
                    height="1px"
                    bg="background.light"
                  />
                )}
                <Flex alignItems="center" w="100%" isTruncated px={2} py={2.5}>
                  <Text
                    opacity={date ? 1 : 0}
                    className="timeline-date"
                    css={{ minWidth: '60px', fontVariantNumeric: 'tabular-nums' }}
                  >
                    {dayjs(date || event.timestamp).format('MMM D')}
                  </Text>
                  <Flex flex="1 1 auto" alignItems="center" gap={2} isTruncated>
                    <Icon as={icons[event.type] || IconTriangleSquareCircle} boxSize={4} color="gray.500" />
                    <Text flex="1 1 auto" display="flex" alignItems="center" gap={2} fontSize="sm" isTruncated>
                      {summary(event) || names[event.type] || event.type}
                    </Text>
                    <Text flex="none" fontSize="sm" color="gray.500" css={{ fontVariantNumeric: 'tabular-nums' }}>
                      {dayjs(event.timestamp).toDate().toLocaleTimeString()}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            )
          }}
        />
      </Box>

      <Drawer isOpen={!!selectedEvent} onClose={() => setSelectedEvent(null)} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody fontSize="sm">
            {selectedEvent && (
              <Stack spacing={4}>
                <Flex alignItems="center" gap={3} py={2} pr={4}>
                  <CircleIcon
                    bg="gray.100"
                    color="gray.700"
                    padding={1.5}
                    iconSize={4}
                    icon={icons[selectedEvent.type] || IconTriangleSquareCircle}
                  />
                  <Text fontSize="sm" fontWeight="medium">
                    {summary(selectedEvent) || names[selectedEvent.type] || selectedEvent.type}
                  </Text>
                </Flex>

                <StackedField label="Event Type">{humanize(selectedEvent.type)}</StackedField>

                <StackedField label="Timestamp" copyable={false}>
                  <TimeAgo time={selectedEvent.timestamp} />
                </StackedField>

                <StackedField label="Details" copyable={false}>
                  <Box ml="-4" mt="-2">
                    <JSONTree data={selectedEvent.raw} />
                  </Box>
                </StackedField>
              </Stack>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default function Debug(props: Props) {
  const [period, setPeriod] = React.useState(props.period)

  React.useEffect(() => {
    setPeriod(props.period)
  }, [props.period])

  const updatePeriod = React.useCallback(async (period: string | undefined) => {
    if (period) {
      setPeriod(period)

      const path = mergeParams(window.location.toString(), { period })
      router.visit(path)
    }
  }, [])

  const timelineData = useMemo(() => {
    const aggregatedData = aggregateEventsByDay(props.timeline)
    return transformAggregatedData(aggregatedData)
  }, [props.timeline])

  const yDataKeys = useMemo(() => {
    const keys = new Set(timelineData.flatMap((d) => Object.keys(d)))
    keys.delete('timestamp')
    keys.delete('no_events')
    return keys
  }, [timelineData])

  const presets = useMemo(
    () =>
      getDefaultPresets().concat([
        {
          label: 'Past 6 months',
          period: 'six-months',
          range: { from: dayjs().subtract(6, 'months').toDate(), to: dayjs().toDate() }
        },
        {
          label: 'Past year',
          period: 'year',
          range: { from: dayjs().subtract(1, 'year').toDate(), to: dayjs().toDate() }
        }
      ]),
    []
  )

  const [reindexing, setReindexing] = useState(false)
  const reindexProfile = useCallback(() => {
    setReindexing(true)

    const path = projectPath(`/profiles/${props.profile.id}/reindex`)

    post(path).then(() => {
      toast.success('Reindexing profile in the background...', {
        position: 'bottom-right'
      })
      setReindexing(false)
    })
  }, [props.profile.id])

  return (
    <PageLayout size="full">
      <TopBarContent>
        <Flex alignItems="center" gap={4} justifyContent="space-between" w="100%">
          <Breadcrumb
            paths={[
              { path: projectPath('/people'), title: 'People' },
              {
                path: projectPath(`/profiles/${props.profile.email || props.profile.id}`),
                title: props.profile.display_name || 'Anonymous'
              },
              { path: window.location.toString(), title: 'Debug' }
            ]}
          />

          <Flex alignItems="center" gap={2}>
            <Button size="sm" variant="outline" onClick={reindexProfile} isLoading={reindexing}>
              Reindex Profile
            </Button>

            <DateRangePicker
              period={period}
              onChange={updatePeriod}
              presets={presets}
              min={dayjs().subtract(2, 'years').toDate()}
            />

            <Button
              size="sm"
              variant="outline"
              as={Link}
              href={window.location.pathname.replace(/\/debug$/, '/export')}
              target="_blank"
              download={`${props.profile.id}-${dayjs().format('YYYY-MM-DD')}.zip`}
              iconSpacing={1}
              leftIcon={<IconDownload size={16} />}
            >
              Export All Data
            </Button>
          </Flex>
        </Flex>
      </TopBarContent>
      <PageTitle skipRendering>Profile Details</PageTitle>
      <Card p={4}>
        <Flex alignItems="center" gap={3}>
          <Avatar size="md" src={props.profile.image} name={props.profile.name || props.profile.email || 'Anonymous'} />

          <Box>
            <Text fontSize="15px" fontWeight="semibold">
              {props.profile.display_name}
            </Text>
            <Text fontSize="13px" color="gray.500">
              {props.profile.email}
            </Text>
            <Text fontSize="13px" color="gray.500">
              {props.profile.title}
            </Text>
          </Box>
        </Flex>
      </Card>
      <Card p={4}>
        <JSONTree
          data={{
            profile: props.profile,
            prospect: props.prospect,
            waterfall: props.waterfall,
            account: props.account
          }}
        />
      </Card>
      <Card p={4}>
        <BarGraph
          data={timelineData}
          xDataKey="timestamp"
          yDataKeys={Array.from(yDataKeys)}
          label={Object.fromEntries(Array.from(yDataKeys).map((key) => [key, key]))}
          height={300}
          xAxis={true}
          yAxis={true}
        />
      </Card>
      <Box mt={6}>
        <Timeline events={props.timeline} selectedEventTypes={props.event_types} />
      </Box>
    </PageLayout>
  )
}
