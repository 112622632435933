import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  Wrap
} from '@chakra-ui/react'
import { IconCopyPlus, IconExternalLink, IconSparkles, IconTrendingDown, IconTrendingUp } from '@tabler/icons-react'
import { last } from 'lodash'
import React from 'react'
import { formatNumber } from '../../../lib/number-format'
import { Account, CRMMatch } from '../../../types/Account'
import { Apps } from '../../../types/App'
import { Company } from '../../../types/Profile'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { CrunchbaseIcon, LinkedinIcon, SalesforceIcon, TwitterIcon } from '../../ui/icons'
import { HubSpotIcon } from '../../ui/icons/HubspotIcons'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { NewsCard } from '../accounts/components/DetailsV2/NewsCard'
import { TechStackCard } from '../accounts/components/DetailsV2/TechStackCard'
import { humanize } from '../accounts/facets/filter-cloud'
import { grade, LetterGrade } from '../icps/icp/breakdown'
import { AutoProspectCard } from '../profiles/components/AutoProspectCard'

interface Props {
  company: Company
  apps: Apps
  account?: Account
  crm_accounts: Record<'Salesforce' | 'HubSpot', CRMMatch>
  icp_score?: {
    fit_grade: number
    fit_grade_letter: string
    fit_breakdown: Array<{ facet: string; factor: number; value: number }>
  }
}

export default function Show(props: Props) {
  const twitterHandle = props.company.twitter?.handle
  const linkedinHandle = props.company.linkedin?.handle
  const crunchbaseHandle = props.company.crunchbase?.handle

  const searchURL = `https://linkedin.com/search/results/companies/?keywords=${
    props.company.name ?? props.company.domain
  }`
  const linkedin = linkedinHandle
    ? `https://linkedin.com/company/${linkedinHandle.replace(/(linkedin\.com\/|company\/)/gi, '')}`
    : searchURL

  const fit = grade(props.icp_score?.fit_grade, props.icp_score?.fit_grade_letter)

  return (
    <PageLayout>
      <Breadcrumb
        paths={[
          { title: 'Research', path: projectPath('/research') },
          {
            title: props.company.name ?? props.company.domain!,
            path: projectPath(`/research/${props.company.domain}`),
            isCurrentPage: true
          }
        ]}
      />
      <Divider />

      <Stack spacing="12">
        <Stack spacing="8">
          <Stack>
            <HStack justifyContent={'space-between'}>
              <HStack>
                <CompanyAvatar domain={props.company.domain} rounded="full" size="sm" />
                <Stack spacing="-0.5">
                  <PageTitle fontSize={'lg'} fontWeight={'semibold'}>
                    {props.company.name ?? props.company.domain}
                  </PageTitle>
                  <Button
                    variant={'link'}
                    as={Link}
                    rightIcon={<IconExternalLink size={12} />}
                    fontSize={'sm'}
                    href={`https://${props.company.domain}`}
                    color="gray.500"
                    isExternal
                  >
                    {props.company.domain}
                  </Button>
                </Stack>
              </HStack>

              <ButtonGroup size={'sm'} variant={'ghost'} color="gray.500" as={HStack}>
                {props.crm_accounts.Salesforce && (
                  <IconButton
                    icon={<Icon as={SalesforceIcon} color="blue.500" size={14} />}
                    aria-label="View in Salesforce"
                    as={Link}
                    href={props.crm_accounts.Salesforce.crm_entity.permalink}
                    isExternal
                  />
                )}

                {props.crm_accounts.HubSpot && (
                  <IconButton
                    icon={<Icon as={HubSpotIcon} color="orange.500" size={12} />}
                    aria-label="View in HubSpot"
                    as={Link}
                    href={props.crm_accounts.HubSpot.crm_entity.permalink}
                    isExternal
                  />
                )}

                {(linkedin || twitterHandle || crunchbaseHandle) && (
                  <HStack>
                    {linkedin && (
                      <IconButton
                        as={Link}
                        aria-label="View on LinkedIn"
                        flex="none"
                        href={linkedin}
                        isExternal
                        icon={<Icon as={LinkedinIcon} size={14} color="linkedin.700" />}
                      />
                    )}
                    {twitterHandle && (
                      <IconButton
                        as={Link}
                        aria-label="View on Twitter"
                        flex="none"
                        href={`https://twitter.com/${twitterHandle}`}
                        isExternal
                        icon={<TwitterIcon size={14} color="black" />}
                      />
                    )}
                    {crunchbaseHandle && (
                      <IconButton
                        as={Link}
                        aria-label="View on Crunchbase"
                        flex="none"
                        icon={<CrunchbaseIcon color="#146AFF" size={14} />}
                        href={`https://crunchbase.com/${crunchbaseHandle}`}
                        isExternal
                      />
                    )}
                  </HStack>
                )}
              </ButtonGroup>
            </HStack>

            <TextEllipsis fontSize="sm" color="gray.600">
              {props.company.description}
            </TextEllipsis>
          </Stack>

          {props.icp_score && (
            <HStack spacing="8" alignItems={'flex-start'}>
              <Stack spacing="0">
                <Heading size={'xs'} fontWeight={'semibold'} color="gray.500">
                  ICP Fit
                </Heading>

                <HStack spacing="0" display="inline-flex" textAlign="left" gap={1} color={`${fit.color}.500`}>
                  <LetterGrade
                    flex="none"
                    fontSize={'x-large'}
                    display="inline"
                    value={props.icp_score.fit_grade}
                    label={props.icp_score.fit_grade_letter}
                    tooltip={false}
                  />{' '}
                  <Text fontSize={'large'}>({props.icp_score.fit_grade}%)</Text>
                </HStack>
              </Stack>

              {props.icp_score?.fit_breakdown && (
                <Wrap shouldWrapChildren spacing="2" flex="1">
                  {props.icp_score.fit_breakdown.map((breakdown) => (
                    <Card
                      key={breakdown.facet}
                      py="2"
                      px="4"
                      shadow="none"
                      bg={breakdown.factor > 1 ? `${fit.color}.50` : 'gray.50'}
                      borderColor={breakdown.factor > 1 ? `${fit.color}.500` : 'gray.300'}
                    >
                      <Stack spacing="0">
                        <TextEllipsis tooltip maxW="80px" fontSize="sm" fontWeight={'semibold'} color="gray.700">
                          {humanize(last(breakdown.facet.split('.')) ?? '')}
                        </TextEllipsis>
                        <HStack spacing="1" alignItems={'flex-end'}>
                          <Text fontSize="sm" color="gray.600" textAlign={'center'}>
                            {breakdown.value}
                          </Text>
                          <Text fontSize="xs" fontWeight={'light'}>
                            ({breakdown.factor}&times;)
                          </Text>
                          <Icon
                            as={breakdown.factor > 1 ? IconTrendingUp : IconTrendingDown}
                            size={12}
                            color={breakdown.factor > 1 ? 'green.500' : 'gray.500'}
                          />
                        </HStack>
                      </Stack>
                    </Card>
                  ))}
                  <Button
                    colorScheme={'purple'}
                    leftIcon={<IconCopyPlus size={22} />}
                    variant="ghost"
                    size="sm"
                    fontSize={'sm'}
                    borderWidth="thin"
                    borderColor={'gray.300'}
                    h="60px"
                    px="8"
                    shadow={'none'}
                  >
                    Add Field
                  </Button>
                </Wrap>
              )}
            </HStack>
          )}

          {!props.icp_score?.fit_breakdown && (
            <Wrap spacing="2" shouldWrapChildren flex="1">
              <Card shadow="none">
                <Heading size="xs" fontWeight={'semibold'} color="gray.700">
                  Employees
                </Heading>

                <Text fontSize="sm" color="gray.600">
                  {props.company.metrics?.employeesRange}
                </Text>
              </Card>

              <Card shadow="none">
                <Heading size="xs" fontWeight={'semibold'} color="gray.700">
                  Industry
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  {props.company.category?.sector}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  {props.company.category?.industry}
                </Text>
              </Card>

              <Card shadow="none">
                <Heading size="xs" fontWeight={'semibold'} color="gray.700">
                  Founded
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  {props.company.founded_year}
                </Text>
              </Card>

              <Card shadow="none">
                <Heading size="xs" fontWeight={'semibold'} color="gray.700">
                  Est. Revenue
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  {props.company.metrics?.estimatedAnnualRevenue}
                </Text>
              </Card>

              <Card shadow="none">
                <Heading size="xs" fontWeight={'semibold'} color="gray.700">
                  Funding
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  ${formatNumber(props.company.metrics?.raised ?? props.company.metrics?.marketCap ?? 0)}
                </Text>
              </Card>

              <Card shadow="none">
                <Heading size="xs" fontWeight={'semibold'} color="gray.700">
                  Location
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  {[props.company.geo?.city, props.company.geo?.state, props.company.geo?.country]
                    .filter(Boolean)
                    .join(', ')}
                </Text>
              </Card>
            </Wrap>
          )}

          <Divider />
          <Stack spacing="4">
            <Stack spacing="0">
              <HStack>
                <IconSparkles size={20} />
                <Heading size="sm" fontWeight={'semibold'} color="gray.700">
                  AI Research Assistants
                </Heading>
              </HStack>
              <Text fontSize="sm" color="gray.600">
                Ask our AI Research Assistants to find more information about this company.
              </Text>
            </Stack>

            <HStack spacing="2" shouldWrapChildren alignItems={'center'}>
              <Card flex="1" w="200px">
                <Heading size="xs" fontWeight={'semibold'} color="gray.700">
                  Is B2B
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  Yes
                </Text>
              </Card>

              <Card flex="1" w="200px">
                <Heading size="xs" fontWeight={'semibold'} color="gray.700">
                  Is SaaS Company
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  Yes
                </Text>
              </Card>

              <Card flex="1" w="200px">
                <Heading size="xs" fontWeight={'semibold'} color="gray.700">
                  Has PLG Motion?
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  Yes
                </Text>
              </Card>

              <Card bg="gray.50">
                <Button colorScheme={'purple'} leftIcon={<IconSparkles />} variant="ghost">
                  Add question
                </Button>
              </Card>

              <Card bg="gray.50">
                <Button colorScheme={'purple'} leftIcon={<IconSparkles />} variant="ghost">
                  Add question
                </Button>
              </Card>
            </HStack>
          </Stack>

          <Divider />

          <AutoProspectCard company={props.company} apps={props.apps} account={props.account} />

          <Divider />

          <HStack flexWrap={'wrap'} alignItems="flex-start" spacing="4">
            <Stack flex="1">
              <NewsCard company={props.company} />
            </Stack>

            <Flex flex="1">
              <TechStackCard
                account={{
                  company_technologies: props.company.company_technologies ?? []
                }}
              />
            </Flex>
          </HStack>
        </Stack>

        <Divider />

        <Stack>
          <Heading size="sm" fontWeight={'semibold'} color="gray.700">
            Similar Companies
          </Heading>
        </Stack>
      </Stack>
    </PageLayout>
  )
}
