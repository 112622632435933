import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { Apps } from '../../types/App'
import { Crm } from '../../types/Crm'
import { PageMeta } from '../../types/PageMeta'
import { FacetFilters } from '../pages/accounts'
import { HighlightedProfile } from '../pages/profiles/components/profile-list'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import { FacetParams } from './use-facets'

interface Data {
  profiles: HighlightedProfile[]
  page_meta: PageMeta
  crm?: Crm
  apps: Apps
  columns: string[]
  facet_filters: FacetFilters
  sort_by?: string
  selected_range?: FacetParams['range']
  profile_sources?: string[]
}

interface Props {
  domain?: string
  accountId?: string
  range?: FacetParams['range']
  search?: string
  queryString?: string
  columns?: string[]
  sortBy?: string | null
}

export function useProfileSearch({
  columns,
  domain,
  accountId,
  range = 'any',
  sortBy,
  queryString,
  search
}: Props = {}) {
  const project = useCurrentProject()
  const params = new URLSearchParams(queryString)

  // note: when you search for a name/email/id, it uses params[:query]
  if (search) params.set('query', search)
  if (domain) params.set('facets[domain]', domain)
  if (accountId) params.set('facets[account_id]', accountId)
  if (range) params.set('range', range)
  if (columns) params.set('columns', columns.join(','))
  if (sortBy) params.set('sort_by', sortBy)

  const path = projectPath(`/people?${params.toString() || ''}`)

  // TODO get a streamlined response instead of all this
  return useQuery<Data>({
    queryKey: ['profile-search', { projectId: project?.id, path }],
    queryFn: () => concurrentGET<Data>(path),
    enabled: Boolean(project?.id)
  })
}
