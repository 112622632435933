import { projectPath } from '@app/components/ui/ProjectsContext'
import { post } from '@app/lib/api'
import { Account } from '@app/types/Account'
import { App } from '@app/types/App'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Image,
  Input,
  Select,
  Text,
  Textarea,
  useDisclosure,
  UseDisclosureProps,
  VStack
} from '@chakra-ui/react'
import { IconAlertCircle } from '@tabler/icons-react'
import dayjs from 'dayjs'
import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'
import { ProfileRecord } from '../../../../types/Profile'
import { humanize } from '../../accounts/facets/filter-cloud'

interface CreateOutreachTaskSideSheetProps extends UseDisclosureProps {
  account: Account | null
  profile?: ProfileRecord
  app?: App
}

const actionTypes = [
  'linkedin_view_profile',
  'linkedin_interact_with_post',
  'linkedin_send_message',
  'linkedin_send_connection_request',
  'linkedin_other',
  'email'
]

export default function CreateOutreachTaskSideSheet(props: CreateOutreachTaskSideSheetProps) {
  const disclosure = useDisclosure(props)
  const [note, setNote] = React.useState('')
  const [actionType, setActionType] = React.useState('')
  const [dueDate, setDueDate] = React.useState(dayjs().add(1, 'hour').format('YYYY-MM-DDTHH:mm'))

  const onClose = disclosure.onClose

  const createTask = useMutation({
    mutationFn: (postBody: {
      task: {
        account_id: string | undefined
        profile_id: string | undefined
        outreach_id: string | undefined
        action_type: string
        note: string
        due_date?: string
      }
    }) => {
      return post(projectPath('/outreach/actions/tasks'), postBody)
    }
  })

  const handleImport = React.useCallback(() => {
    const outreachAccount = props.account?.outreach_accounts?.find(
      (m) => m.match_type === 'account_id' || m.match_type == 'domain'
    )

    createTask
      .mutateAsync({
        task: {
          account_id: props.account?.id,
          profile_id: props.profile?.id,
          outreach_id: outreachAccount?.crm_entity_id,
          action_type: actionType,
          due_date: dueDate ? dayjs(dueDate).toISOString() : undefined,
          note: note
        }
      })
      .then(() => {
        toast.success('Task Created!', {
          description: 'You can now view the new task in Outreach'
        })
        onClose()
      })
      .catch((err) => {
        toast.error('Task Creation Failed', {
          description:
            err.body?.error ?? 'Something is not quite right. Please reach out so we can help you debug what is wrong.'
        })
      })
      .finally(() => {
        createTask.reset()
      })
  }, [createTask, actionType, note, dueDate, props, onClose])

  return (
    <Drawer size="lg" placement="right" {...disclosure} preserveScrollBarGap>
      <DrawerOverlay zIndex="modal" />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading size="md" display="flex" gap={2} alignItems="center">
            {props.app ? (
              <>
                Create task for{' '}
                {props.profile?.display_name ?? props.account?.company.name ?? props.account?.company.domain} in
                <Image src={props.app.logo} boxSize="5" />
                {props.app.title}
              </>
            ) : (
              <>Connect Outreach to create tasks.</>
            )}
          </Heading>
        </DrawerHeader>

        <DrawerBody paddingTop={0} paddingBottom={10}>
          <VStack alignItems={'left'} width={'100%'} spacing={'6'}>
            <VStack alignItems={'left'}>
              <Text fontSize={'sm'}>Action Type:</Text>
              <Select
                variant={'outline'}
                placeholder="Select..."
                size="sm"
                onChange={(e) => setActionType(e.target.value)}
              >
                {actionTypes.map((t) => (
                  <option key={t} value={t}>
                    {humanize(t)}
                  </option>
                ))}
              </Select>
            </VStack>
            <VStack alignItems={'left'}>
              <Text fontSize={'sm'}>Due Date:</Text>
              <Input
                type="datetime-local"
                size="sm"
                rounded="md"
                variant={'outline'}
                defaultValue={dueDate}
                onSelect={(e) => {
                  const datetimeLocal = e.currentTarget.value
                  if (datetimeLocal !== '') {
                    setDueDate(new Date(datetimeLocal).toISOString())
                  }
                }}
              />
            </VStack>
            <VStack alignItems={'left'}>
              <Text fontSize={'sm'}>Note:</Text>
              <Textarea size="sm" rounded="md" onChange={(e) => setNote(e.target.value)} />
            </VStack>
            <Flex width="100%" gap={2} alignItems="center" justifyContent="end">
              <Button
                colorScheme="purple"
                loadingText="Importing..."
                onClick={handleImport}
                isDisabled={actionType == ''}
                isLoading={createTask.isPending}
                leftIcon={props.app && !props.app.user_in_app ? <IconAlertCircle size={16} /> : undefined}
              >
                Create Task
              </Button>
            </Flex>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
