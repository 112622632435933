import {
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Img,
  Spinner,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import React, { useMemo } from 'react'
import { useAppDep } from '../../../../data/use-app-dep'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { SalesforceField, SalesforceObject } from '../../../apps/salesforce/show'
import { channelLogos } from '../delivery-setup'
import { FieldPreview, SalesforceFieldMapper, SalesforceFieldMapping } from './salesforce-field-mapper'
import { SalesforceActionProps } from './salesforce-setup'

export function CreateRecord(props: SalesforceActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.salesforce?.create_record?.enabled))

  const sobjects = useMemo(
    () => props.deps.sobjects.filter((sobject) => sobject.custom && sobject.createable),
    [props.deps.sobjects]
  )

  const isPersisted = useMemo(
    () => !!props.delivery_rules?.salesforce?.create_record,
    [props.delivery_rules?.salesforce?.create_record]
  )

  const [selectedObject, setSelectedObject] = React.useState(props.delivery_rules?.salesforce?.create_record?.sobject)
  const [uniqueKey, setUniqueKey] = React.useState(props.delivery_rules?.salesforce?.create_record?.unique_key)

  const { data, isLoading } = useAppDep<'object_fields', SalesforceField[]>('Salesforce', 'object_fields', {
    sobject: selectedObject || '',
    writable: true
  })

  const sfFields = useMemo(() => data?.data?.object_fields ?? [], [data])
  const sfFieldNames = useMemo(() => data?.data?.object_fields.map((f) => f.name) ?? [], [data])

  const suggestions: SalesforceFieldMapping[] = useMemo(() => {
    return [
      {
        id: nanoid(),
        koala: 'signal.id',
        salesforce: 'Koala_IntentTriggerId__c'
      },
      {
        id: nanoid(),
        koala: 'signal.name',
        salesforce: 'Name'
      },
      {
        id: nanoid(),
        koala: 'signal.timestamp',
        salesforce: 'Koala_IntentTriggeredAt__c'
      },
      {
        id: nanoid(),
        koala: 'signal.signal_type',
        salesforce: 'Koala_IntentType__c'
      },
      {
        id: nanoid(),
        koala: 'visitor.email',
        salesforce: 'Koala_VisitorEmail__c'
      },
      {
        id: nanoid(),
        koala: 'visitor.id',
        salesforce: 'Koala_VisitorId__c'
      },
      {
        id: nanoid(),
        koala: 'account.company.name',
        salesforce: 'Koala_AccountName__c'
      },
      {
        id: nanoid(),
        koala: 'account.company.domain',
        salesforce: 'Koala_AccountDomain__c'
      }
    ].filter((s) => s.salesforce && sfFieldNames.includes(s.salesforce))
  }, [sfFieldNames])

  return (
    <Stack width="100%">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              salesforce: {
                ...props.delivery_rules?.salesforce,
                create_record: {
                  ...props.delivery_rules?.salesforce?.create_record,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={props.colorScheme}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][salesforce][create_record][enabled]"
        >
          Create Record
        </Switch>
        {!props.compact && <FormHelperText>Create a record whenever this automation runs.</FormHelperText>}
      </FormControl>

      {enabled && (
        <Stack spacing={'8'} width="100%">
          <Divider />

          <FormControl>
            <FormLabel>Salesforce object</FormLabel>
            <FormHelperText>Pick the Salesforce object you want to create.</FormHelperText>
            <Flex>
              <HStack w="100%">
                <ComboboxWithSearch
                  items={sobjects}
                  selectedItem={sobjects.find((o) => o.name === selectedObject) || null}
                  onChange={(item) => setSelectedObject(item?.name)}
                  filterItem={(a, val) =>
                    (a?.label ?? '').toLowerCase().includes(val) || (a?.name ?? '').toLowerCase().includes(val)
                  }
                  itemToString={(item) => item?.label ?? ''}
                  itemRenderer={ObjectPreview}
                  selectButtonRenderer={ObjectPreview}
                />
              </HStack>
              {selectedObject && (
                <input
                  type="hidden"
                  name="follow_rule[delivery_rules][salesforce][create_record][sobject]"
                  value={selectedObject}
                />
              )}
            </Flex>
          </FormControl>

          {selectedObject && props.actionSchema && !isLoading && (
            <>
              {sfFields.length > 0 && (
                <FormControl>
                  <FormLabel>Unique key</FormLabel>
                  <FormHelperText>
                    Specify which field can be used as a unique key to prevent duplicate records from being created.
                  </FormHelperText>
                  <ComboboxWithSearch
                    items={sfFields}
                    selectedItem={sfFields.find((o) => o.name === uniqueKey) || null}
                    onChange={(item) => setUniqueKey(item?.name)}
                    filterItem={(a, val) => (a?.label ?? '').toLowerCase().includes(val)}
                    itemToString={(item) => item?.label ?? ''}
                    itemRenderer={FieldPreview}
                    selectButtonRenderer={FieldPreview}
                  />
                  <input
                    type="hidden"
                    name="follow_rule[delivery_rules][salesforce][create_record][unique_key]"
                    value={uniqueKey || ''}
                  />
                </FormControl>
              )}

              <SalesforceFieldMapper
                actionsSchema={props.actionSchema}
                loadingDeps={props.loadingDeps}
                refetchDeps={props.refetchDeps}
                namespace="follow_rule[delivery_rules][salesforce][create_record]"
                type={selectedObject}
                mappings={props.delivery_rules?.salesforce?.create_record?.fields ?? (isPersisted ? suggestions : [])}
                suggestions={suggestions}
                deps={props.deps}
              />
            </>
          )}
          {selectedObject && isLoading && <Spinner size="sm" color="gray.400" />}
        </Stack>
      )}
    </Stack>
  )
}

function ObjectPreview({ item }: { item: SalesforceObject | null }) {
  if (!item) {
    return (
      <HStack flex="1" fontSize={'sm'}>
        <Img src={channelLogos.salesforce} w="4" />
        <Text color="gray.600">Select a Salesforce object</Text>
      </HStack>
    )
  }

  return (
    <HStack flex="1" fontSize={'sm'}>
      <Img src={channelLogos.salesforce} w="4" />
      <Text>{item.labelPlural || item.label}</Text>
    </HStack>
  )
}
