import { Box, Flex, Icon, useDisclosure } from '@chakra-ui/react'
import { Icon as TablerIcon } from '@tabler/icons-react'
import React, { useCallback, useEffect } from 'react'
import { Facet, FacetMappings } from '../..'
import { FilterArray, FilterGroup } from '../../../../ui/filters/types'
import { Iconify } from '../../../../ui/Iconify'
import FilterPopover from '../../facets/filter-popover'
import { FilterPreviewProps } from '../FilterPreview/types'
import { DropdownIcon } from './DropdownIcon'
import Label from './Label'
import Operator from './Operator'
import RemoveButton from './RemoveButton'
import Values from './Values'
import { AdvancedFilterPopover } from '../../../../ui/filters/AdvancedFilterPopover'

interface Props extends FilterPreviewProps {
  onRemove?: () => void
  facetMappings: FacetMappings
  onClick?: () => void
  facet?: string
  label?: string
  operator?: string
  value: Facet | FilterGroup['_and']
  formatter?: (value: any) => string
  icon?: JSX.Element | TablerIcon | string | typeof Icon
  isRemovable?: boolean
  convertable?: boolean
  maxValuesToDisplay?: number
}

const FilterTag = (props: Props) => {
  const {
    icon,
    label,
    operator,
    value,
    onRemove,
    facet,
    onClick,
    isEditable,
    convertable,
    formatter,
    maxValuesToDisplay,
    ...rest
  } = props

  const iconElement = <Iconify icon={icon} size={16} />
  const colorScheme = 'gray'

  const hasValues = Array.isArray(value) ? value.length > 0 : typeof value !== 'undefined' && value !== null
  const displayDropdown = !hasValues && isEditable && !onRemove
  const disclosure = useDisclosure()

  const onOpen = disclosure.onOpen

  useEffect(() => {
    if (facet === '_and' || facet === '_or') {
      const onOpenAdvancedFilter = () => onOpen()

      window.addEventListener('openadvancedfilter', onOpenAdvancedFilter)

      return () => {
        window.removeEventListener('openadvancedfilter', onOpenAdvancedFilter)
      }
    }
  }, [facet, onOpen])

  const onConvertToAdvancedFilters = useCallback(
    (filters) => {
      if (rest.facetFilters._or) {
        rest.applyFilters({
          [facet!]: undefined,
          _or: (rest.facetFilters._or as FilterArray).concat(filters)
        })
      } else {
        rest.applyFilters({
          [facet!]: undefined,
          _and: ((rest.facetFilters._and as FilterArray) || []).concat(filters)
        })
      }

      setTimeout(() => {
        window.dispatchEvent(new Event('openadvancedfilter'))
      }, 100)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rest.facetFilters, rest.applyFilters, facet]
  )

  const content = (
    <Box
      role="group"
      display="inline-flex"
      alignItems="stretch"
      flexGrow={0}
      cursor={displayDropdown ? 'pointer' : undefined}
      _hover={displayDropdown ? { bg: 'gray.100' } : undefined}
      height="32px"
      color={`${colorScheme}.700`}
      borderColor="gray.200"
      borderRadius={6}
      borderWidth="1px"
      bg="white"
      shadow="sm"
      minWidth="40px"
      maxWidth="100%"
      onClick={onClick}
      userSelect="none"
      __css={{
        '& > span:first-of-type': {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6
        },
        '& > span:last-of-type': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6
        }
      }}
    >
      {(label || iconElement) && (
        <Flex as="span" flex="none" gap={[1, 1.5]} paddingX={[1.5, 2]}>
          {icon && (
            <Box
              as="span"
              display="flex"
              alignItems="center"
              rounded="sm"
              overflow={typeof icon === 'string' ? 'hidden' : undefined}
              flexShrink={0}
            >
              {iconElement}
            </Box>
          )}
          {label && <Label>{label}</Label>}
        </Flex>
      )}

      {label && operator && (
        <Operator hasValues={hasValues} onClick={isEditable ? disclosure.onToggle : undefined}>
          {operator}
        </Operator>
      )}

      {/* Only show values for non-grouped facets */}
      {hasValues && facet !== '_and' && facet !== '_or' ? (
        <Values
          isEditable={isEditable}
          isRemovable={!!onRemove}
          value={value as Facet}
          maxValuesToDisplay={maxValuesToDisplay}
          facet={facet}
          colorScheme={colorScheme}
          formatter={formatter}
          onToggle={isEditable ? disclosure.onToggle : undefined}
          {...rest}
        />
      ) : displayDropdown ? (
        <DropdownIcon />
      ) : null}

      {onRemove && isEditable && <RemoveButton onClick={onRemove} />}
    </Box>
  )

  const isAdvancedFilter = facet === '_and' || facet === '_or'

  if (isAdvancedFilter) {
    return (
      <AdvancedFilterPopover
        {...disclosure}
        {...rest}
        filters={rest.facetFilters}
        onChange={rest.applyFilters}
        onClear={() => {
          rest.applyFilters({ [facet]: undefined })
        }}
      >
        {content}
      </AdvancedFilterPopover>
    )
  }

  if (!isEditable) {
    return content
  }

  return (
    <FilterPopover
      {...disclosure}
      {...rest}
      selectedFacetKey={facet}
      width={facet === 'sources' ? 'auto' : undefined}
      placement="bottom"
      onConvertToAdvancedFilters={convertable && isEditable ? onConvertToAdvancedFilters : undefined}
    >
      {content}
    </FilterPopover>
  )
}

export default FilterTag
