import React, { useRef } from 'react'
import { Apps } from '../../../types/App'
import { FollowRule } from '../../../types/FollowRule'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { automationsPath } from '../notifications/lib/path-helper'
import { NotificationRuleForm } from './components/notification-rule-form'

interface Props {
  apps: Apps
  follow_rule?: FollowRule
  errors?: Record<string, string[]>
}

export default function NewFollowRule(props: Props) {
  const formRef = useRef<HTMLFormElement>(null)

  return (
    <PageLayout size="md">
      <PageTitle skipRendering>New Action</PageTitle>

      <Breadcrumb
        paths={[
          { path: automationsPath('/overview'), title: 'Automations' },
          { path: automationsPath(), title: 'Actions' },
          { path: automationsPath('/new'), title: 'New Action' }
        ]}
      />

      <form id="follow-rule" action={automationsPath()} method="POST" ref={formRef} data-koala-collect="off">
        <AuthenticityToken />
        <NotificationRuleForm {...props} formRef={formRef} errors={props.errors} />
      </form>
    </PageLayout>
  )
}
