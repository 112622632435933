import {
  Badge,
  Box,
  BoxProps,
  Button,
  Checkbox,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Table,
  TableColumnHeaderProps,
  TableContainer,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useId
} from '@chakra-ui/react'
import {
  IconArrowDown,
  IconArrowUp,
  IconChevronDown,
  IconCircleCheckFilled,
  IconEdit,
  IconFilter,
  IconSelector,
  IconX
} from '@tabler/icons-react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowSelectionState,
  TableOptions,
  useReactTable
} from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useMedia } from 'react-use'
import { FieldDefinition } from '../../types/FieldDefinition'
import { useApps } from '../data/use-apps'
import { FilterItem } from '../pages/accounts/facets/categories'
import { isHubSpotField, isSalesforceField, isTraitField } from '../pages/field_definitions'
import { deriveType, FieldDefinitionModal } from '../pages/field_definitions/components/FieldDefinitionModal'
import { HelpTooltip } from './HelpTooltip'
import { usePermission } from './PermissionsContext'
import { useCurrentProject } from './ProjectsContext'
import { useOverflow } from './useOverflow'

interface SortableHeaderProps extends TableColumnHeaderProps {
  info?: string
  sortBy?: string
  currentSort?: string
  columnKey?: string
  onSortChange?: (sortBy: string | undefined) => void
  onRemoveColumn?: (column: string) => void
  onFilterColumn?: (column: string) => void
  tooltip?: boolean
  fieldDefinition?: FieldDefinition
  recordType?: FieldDefinition['record_type']
  columnTitle?: string
  columnType?: string
}

export function SortableHeader({
  info,
  sortBy,
  columnKey,
  currentSort,
  onSortChange,
  onRemoveColumn,
  onFilterColumn,
  children,
  fieldDefinition,
  recordType,
  columnTitle,
  columnType,
  ...props
}: SortableHeaderProps) {
  const sortable = sortBy && typeof onSortChange === 'function'
  const removable = columnKey && typeof onRemoveColumn === 'function'
  const filterable = columnKey && typeof onFilterColumn === 'function'
  const [sortingBy, sortDirection] = currentSort?.split(':') ?? []
  const isSorted = sortingBy && sortingBy === sortBy
  const SortIcon = isSorted ? (sortDirection === 'asc' ? IconArrowUp : IconArrowDown) : IconSelector
  const [fieldToEdit, setFieldToEdit] = useState<FieldDefinition | undefined>()
  const { data: apps } = useApps()
  const project = useCurrentProject()
  const queryClient = useQueryClient()
  const { hasPermission: canEdit } = usePermission({ on: 'project', action: 'can_edit' })

  const canCustomizeColumn = useMemo(() => {
    return canEdit && (isHubSpotField(columnKey) || isSalesforceField(columnKey) || isTraitField(columnKey))
  }, [columnKey, canEdit])

  const onSortAsc = useCallback(() => {
    if (isSorted && sortDirection === 'asc') {
      onSortChange?.(undefined)
    } else {
      onSortChange?.(`${sortBy}:asc`)
    }
  }, [sortBy, isSorted, sortDirection, onSortChange])

  const onSortDesc = useCallback(() => {
    if (isSorted && sortDirection === 'desc') {
      onSortChange?.(undefined)
    } else {
      onSortChange?.(`${sortBy}:desc`)
    }
  }, [sortBy, isSorted, sortDirection, onSortChange])

  const onEditColumnDisplay = useCallback(
    (column) => {
      if (!canCustomizeColumn || !recordType || !columnTitle) return

      if (fieldDefinition) {
        setFieldToEdit(fieldDefinition)
        return
      }

      const newFieldDefinition = {
        data_source: column,
        record_type: recordType,
        label: columnTitle,
        data_type: deriveType(columnType as FilterItem['type']),
        key_field: false,
        is_custom: true,
        position: 0
      }
      setFieldToEdit(newFieldDefinition)
    },
    [canCustomizeColumn, columnTitle, columnType, fieldDefinition, recordType]
  )

  if (sortable || removable) {
    return (
      <Th {...props} _hover={{ bg: 'gray.50', '& .hover-tooltip': { opacity: 0.6 } }} cursor="pointer" px={0}>
        <Menu isLazy lazyBehavior="keepMounted" offset={[4, 6]} autoSelect={false}>
          <Tooltip label={children} placement="top" hasArrow>
            <MenuButton type="button" width="full" height="100%" px={3} fontWeight="inherit">
              <Flex width="100%" height="100%" alignItems="center" justifyContent="space-between" gap={1.5}>
                <Flex alignItems="center" gap={1}>
                  <Text as="span" lineHeight={1.2} color={isSorted ? 'gray.800' : 'inherit'}>
                    {children}
                  </Text>
                  {info && (
                    <HelpTooltip
                      className="hover-tooltip"
                      opacity={0}
                      transition="opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                      variant="info"
                    >
                      {info}
                    </HelpTooltip>
                  )}
                </Flex>
                {sortable && (
                  <Icon
                    as={SortIcon}
                    boxSize={3.5}
                    color={isSorted ? 'color.800' : 'gray.500'}
                    transition="color 200ms cubic-bezier(0.4, 0, 0.2, 1)"
                  />
                )}
              </Flex>
            </MenuButton>
          </Tooltip>
          <Portal>
            <MenuList zIndex="popover">
              {sortable && (
                <>
                  <MenuItem icon={<IconArrowUp size={16} />} iconSpacing={1.5} onClick={onSortAsc}>
                    <Flex alignItems="center" gap={2} justifyContent="space-between">
                      Sort ascending
                      {isSorted && sortDirection === 'asc' && (
                        <Icon as={IconCircleCheckFilled} boxSize={4} color="purple.500" />
                      )}
                    </Flex>
                  </MenuItem>
                  <MenuItem icon={<IconArrowDown size={16} />} iconSpacing={1.5} onClick={onSortDesc}>
                    <Flex alignItems="center" gap={2} justifyContent="space-between">
                      Sort descending
                      {isSorted && sortDirection === 'desc' && (
                        <Icon as={IconCircleCheckFilled} boxSize={4} color="purple.500" />
                      )}
                    </Flex>
                  </MenuItem>
                </>
              )}

              {canCustomizeColumn && apps && recordType && (
                <>
                  <MenuItem
                    icon={<IconEdit size={16} />}
                    iconSpacing={1.5}
                    onClick={() => onEditColumnDisplay(columnKey)}
                  >
                    Edit column display
                  </MenuItem>

                  <FieldDefinitionModal
                    modalTitle="Define field display"
                    isOpen={!!fieldToEdit}
                    field={fieldToEdit}
                    recordType={recordType}
                    apps={apps.apps}
                    showDataSource={false}
                    onClose={() => setFieldToEdit(undefined)}
                    onChange={() => {
                      queryClient.invalidateQueries({
                        queryKey: ['fieldDefinitions', { projectId: project?.id }]
                      })
                    }}
                  />
                </>
              )}

              {filterable && sortable && <MenuDivider />}

              {filterable && (
                <MenuItem icon={<IconFilter size={16} />} iconSpacing={1.5} onClick={() => onFilterColumn(columnKey)}>
                  Filter column
                </MenuItem>
              )}

              {removable && (sortable || filterable) && <MenuDivider />}

              {removable && (
                <MenuItem icon={<IconX size={14} />} iconSpacing={1.5} onClick={() => onRemoveColumn(columnKey)}>
                  Remove column
                </MenuItem>
              )}
            </MenuList>
          </Portal>
        </Menu>
      </Th>
    )
  }

  return <Th {...props}>{children}</Th>
}

const CELL_EXPAND_EVENT = 'cell-expand'

const collapsedOverflowStyle = {
  '& > .chakra-text': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}

interface CellExpandEvent extends CustomEvent {
  detail: { cellId: string }
}

interface ExpandableCellProps extends React.PropsWithChildren<BoxProps> {
  isComfy?: boolean
}

export function ExpandableCell({ children, isComfy = false, ...props }: ExpandableCellProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const cellRef = useRef<HTMLDivElement>(null)

  const cellId = useId(undefined, 'expandable-cell')

  useEffect(() => {
    if (isExpanded) {
      const handleCellExpand = (event: CellExpandEvent) => {
        if (event.detail?.cellId !== cellId) {
          setIsExpanded(false)
        }
      }

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setIsExpanded(false)
        }
      }

      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener(CELL_EXPAND_EVENT, handleCellExpand as EventListener)
      return () => {
        document.removeEventListener(CELL_EXPAND_EVENT, handleCellExpand as EventListener)
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [cellId, isExpanded])

  const handleExpand = useCallback(() => {
    setIsExpanded(true)
    document.dispatchEvent(new CustomEvent(CELL_EXPAND_EVENT, { detail: { cellId } }))
  }, [cellId])

  return (
    <Box display="flex" width="100%" height="100%" position="relative">
      <Box
        ref={cellRef}
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
        onClick={handleExpand}
        whiteSpace={isComfy ? undefined : 'nowrap'}
        overflowX={isComfy ? undefined : 'hidden'}
        overflowY={isComfy ? 'auto' : undefined}
        textOverflow={isComfy ? 'clip' : 'ellipsis'}
        maxH={isComfy ? '300px' : undefined}
        py={isComfy ? 3 : 2}
        px={2}
        lineHeight={5}
        css={isComfy ? undefined : (collapsedOverflowStyle as any)}
        {...props}
      >
        {children}
      </Box>

      {isExpanded && (
        <Box
          {...props}
          position="absolute"
          top={0}
          left={0}
          display="flex"
          width="100%"
          maxWidth="max(100%, 350px)"
          minHeight="100%"
          maxHeight="200px"
          zIndex={1}
          bg="background.light"
          rounded="base"
          flex="1 1 auto"
        >
          <Box
            position="absolute"
            top="-1px"
            right="-1px"
            bottom="-1px"
            left="0px"
            border="1px solid"
            borderColor="purple.400"
            rounded="base"
            pointerEvents="none"
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="stretch"
            flex="1 1 auto"
            width="max-content"
            overflow="auto"
            wordBreak="break-word"
            whiteSpace="pre-wrap"
            flexWrap="wrap"
            py={isComfy ? 3 : 2}
            px={2}
            lineHeight={5}
            {...props}
          >
            {children}
          </Box>
        </Box>
      )}
    </Box>
  )
}

const rowHover = {
  bg: [undefined, 'gray.50']
}

interface CustomTableProps<T extends object> extends Partial<TableOptions<T>> {
  data: T[]
  columns: ColumnDef<T>[]
  selectedRows?: RowSelectionState
  size?: TableProps['size']
  stickyFirstColumn?: boolean
  enableAllSelection?: boolean
  onSelectionModeChange?: (a: string) => void
  selectAllMode?: boolean
  numberOfRecords?: number
}

export function CustomTable<T extends object>({
  data,
  columns,
  selectedRows,
  onSelectionModeChange,
  size = 'sm',
  stickyFirstColumn = false,
  enableRowSelection = false,
  enableAllSelection = false,
  numberOfRecords,
  state,
  selectAllMode = false,
  ...tableOptions
}: CustomTableProps<T>) {
  const largeEnoughScreen = useMedia('(min-width: 768px) and (min-height: 600px)')
  const { scrollRef, overflowLeft, overflowTop } = useOverflow()
  const [rowSelection, setRowSelection] = useState(selectedRows || {})

  useEffect(() => {
    if (selectedRows) {
      setRowSelection(selectedRows)
    }
  }, [selectedRows])

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      ...state
    },
    enableRowSelection,
    onRowSelectionChange: setRowSelection,
    getRowId: (row, index) => (row as any).id || index,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    ...tableOptions,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <TableContainer
      ref={scrollRef}
      position="relative"
      className={`${overflowLeft ? 'scrolled' : ''} ${overflowTop ? 'scrolled-y' : ''}`.trim() || undefined}
      overflowY="auto"
      width="100%"
    >
      <Table className="koala-table" variant="bordered" size={size} height="1px">
        <Thead className="sticky-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <Th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={index === 0 && largeEnoughScreen && stickyFirstColumn ? 'sticky-column' : undefined}
                  {...(header.column.columnDef.meta as any)?.cellProps}
                >
                  <Flex alignItems="center" gap={2.5} height="100%">
                    {enableRowSelection && !enableAllSelection && index === 0 && (
                      <Checkbox
                        isChecked={table.getIsAllRowsSelected()}
                        isIndeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                      />
                    )}
                    {enableRowSelection && enableAllSelection && index === 0 && (
                      <Menu>
                        <MenuButton
                          as={Button}
                          paddingY="2px"
                          paddingX="4px"
                          marginLeft="-4px"
                          height="22px"
                          rightIcon={<IconChevronDown size="12px" />}
                        >
                          <Checkbox
                            isChecked={table.getIsAllRowsSelected()}
                            isIndeterminate={table.getIsSomeRowsSelected()}
                          />
                        </MenuButton>
                        <Portal>
                          <MenuList>
                            <MenuItem
                              onClick={(e) => {
                                onSelectionModeChange && onSelectionModeChange('page')
                                if (!selectAllMode || (selectAllMode && !table.getIsAllRowsSelected())) {
                                  table.getToggleAllRowsSelectedHandler()(e)
                                }
                              }}
                            >
                              <Checkbox mr="4px" isChecked={table.getIsAllRowsSelected() && !selectAllMode} />
                              Select page
                            </MenuItem>
                            {onSelectionModeChange && (
                              <MenuItem
                                onClick={(e) => {
                                  onSelectionModeChange && onSelectionModeChange('all')
                                  if (selectAllMode || (!selectAllMode && !table.getIsAllRowsSelected())) {
                                    table.getToggleAllRowsSelectedHandler()(e)
                                  }
                                }}
                              >
                                <Checkbox mr="4px" isChecked={table.getIsAllRowsSelected() && selectAllMode} />
                                Select all companies{' '}
                                {numberOfRecords && (
                                  <Badge variant="pill" colorScheme="purple" marginLeft="4px">
                                    {numberOfRecords}
                                  </Badge>
                                )}
                              </MenuItem>
                            )}
                          </MenuList>
                        </Portal>
                      </Menu>
                    )}
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              className={row.getIsSelected() ? 'selected-row' : undefined}
              bg={row.getIsSelected() ? 'purple.50' : undefined}
              _hover={row.getIsSelected() ? undefined : rowHover}
            >
              {row.getVisibleCells().map((cell, index) => {
                const width = cell.column.getSize()
                const autoWidth = (cell.column.columnDef.meta as any)?.cellProps?.width === 'auto'

                return (
                  <Td
                    key={cell.id}
                    className={index === 0 && largeEnoughScreen && stickyFirstColumn ? 'sticky-column' : undefined}
                    bg={row.getIsSelected() ? 'purple.50' : undefined}
                    width={autoWidth ? undefined : '1px'}
                    minW={width}
                    maxW={width}
                    {...(cell.column.columnDef.meta as any)?.cellProps}
                  >
                    {/* minW just to make flex truncation work */}
                    <Flex alignItems="center" fontSize="sm" gap={2.5}>
                      {enableRowSelection && index === 0 && (
                        <Checkbox
                          isChecked={row.getIsSelected()}
                          onChange={row.getToggleSelectedHandler()}
                          isDisabled={selectAllMode && table.getIsAllRowsSelected()}
                        />
                      )}
                      <Box flex="1" minW="80px" isTruncated>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Box>
                    </Flex>
                  </Td>
                )
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
