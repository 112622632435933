import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'

interface Params {
  id: string
}

export function useAcceptChange() {
  const onSuccess = React.useCallback(() => {
    toast.success('Successfully accepted and applied the change.')
  }, [])

  const onError = React.useCallback(() => {
    toast.error('There was an issue accepting this change.')
  }, [])

  return useMutation({
    mutationFn: ({ id }: Params) => {
      return post(`/admin/suggestions/${id}/accept`)
    },

    onSuccess,
    onError
  })
}
