import { BubbleTag } from '@app/components/ui/BubbleTag'
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconBan, IconCheck, IconFlare, IconPlus, IconTrash, IconUsers } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { AIAgent } from '../../../../types/AIAgent'
import { Play, QualificationAgentConfig } from '../../../../types/Play'
import { useAIAgents } from '../../../data/use-ai-agents'
import { GrayCard } from '../../../ui/Card'
import { PartialCompany } from '../../../ui/CompanySelector'
import { BuildingIcon } from '../../../ui/icons'
import TagsInput from '../../../ui/TagInput'
import { PartialVisitor } from '../../../ui/VisitorSelector'
import { AgentModal } from '../../ai_agents'
import { AIAgentColumns } from '../../ai_agents/components/AIAgentColumns'

interface AIQualificationSetupProps {
  play: Partial<Play>
  targetType: 'Account' | 'Profile'
}

const STRING_OPERATORS = [
  { value: 'equals', label: 'Equals' },
  { value: 'contains', label: 'Contains' },
  { value: 'not_contains', label: 'Does not contain' },
  { value: 'starts_with', label: 'Starts with' },
  { value: 'ends_with', label: 'Ends with' }
]

const NUMERIC_OPERATORS = [
  { value: 'equals', label: 'Equals' },
  { value: 'greater_than', label: 'Greater than' },
  { value: 'less_than', label: 'Less than' },
  { value: 'greater_than_or_equal', label: 'Greater than or equal' },
  { value: 'less_than_or_equal', label: 'Less than or equal' }
]

const DATE_OPERATORS = [
  { value: 'equals', label: 'Equals' },
  { value: 'greater_than', label: 'After' },
  { value: 'less_than', label: 'Before' },
  { value: 'greater_than_or_equal', label: 'On or after' },
  { value: 'less_than_or_equal', label: 'On or before' }
]

const BOOLEAN_OPERATORS = [{ value: 'equals', label: 'Equals' }]

const LIST_OPERATORS = [
  { value: 'contains', label: 'Contains' },
  { value: 'not_contains', label: 'Does not contain' },
  { value: 'contains_any', label: 'Contains any' },
  { value: 'contains_all', label: 'Contains all' }
]

export function AIQualificationSetup({ play, targetType }: AIQualificationSetupProps) {
  const { data: agents, isLoading, refetch: refetchAgents } = useAIAgents('people')
  const myAgents = useMemo(() => agents?.my_agents ?? [], [agents])

  const [qualificationCriteria, setQualificationCriteria] = useState<QualificationAgentConfig[]>(
    play.qualification_agents_config || []
  )

  // Update criteria when agents data is loaded or play config changes
  useEffect(() => {
    if (myAgents.length > 0 && play.qualification_agents_config) {
      // Filter out criteria for agents that no longer exist
      const validCriteria = play.qualification_agents_config.filter(
        (criteria) => criteria && myAgents.some((agent) => agent.id === criteria.assistant_id)
      ) as QualificationAgentConfig[]

      // Ensure values are properly formatted
      const formattedCriteria = validCriteria.map((criteria) => {
        // For list and picklist, ensure value is an array
        if (
          (criteria.response_type === 'list' || criteria.response_type === 'picklist') &&
          !Array.isArray(criteria.value)
        ) {
          return {
            ...criteria,
            value: criteria.value ? [String(criteria.value)] : []
          }
        }
        return criteria
      })

      setQualificationCriteria(formattedCriteria)
    }
  }, [myAgents, play.qualification_agents_config])

  const [selectedAgent, setSelectedAgent] = React.useState<AIAgent | null>(null)
  const [selectedCompany, setSelectedCompany] = React.useState<PartialCompany | null>(null)
  const [selectedPerson, setSelectedPerson] = React.useState<PartialVisitor | null>(null)

  const getOperatorsForType = (type: string) => {
    switch (type) {
      case 'number':
      case 'currency':
      case 'percent':
        return NUMERIC_OPERATORS
      case 'date':
        return DATE_OPERATORS
      case 'boolean':
        return BOOLEAN_OPERATORS
      case 'list':
      case 'picklist':
        return LIST_OPERATORS
      default:
        return STRING_OPERATORS
    }
  }

  const handleAgentAdd = async (agent: AIAgent) => {
    await refetchAgents()

    // Check if agent already exists in criteria
    if (!qualificationCriteria.some((criteria) => criteria.assistant_id === agent.id)) {
      const newCriteria: QualificationAgentConfig = {
        assistant_id: agent.id || '',
        response_type: agent.response_type || 'string',
        operator: getOperatorsForType(agent.response_type || 'string')[0].value,
        value: ''
      }

      setQualificationCriteria((prev) => [...prev, newCriteria])
    }
  }

  // Removed unused variables

  const handleCriteriaChange = (index: number, field: keyof QualificationAgentConfig, value: any) => {
    const updatedCriteria = [...qualificationCriteria]

    if (field === 'response_type') {
      // Reset operator when response type changes
      updatedCriteria[index] = {
        ...updatedCriteria[index],
        [field]: value,
        operator: getOperatorsForType(value)[0].value,
        // Reset value based on type
        value:
          value === 'list' || value === 'picklist'
            ? []
            : ['number', 'currency', 'percent'].includes(value)
              ? 0
              : value === 'date'
                ? new Date().toISOString().split('T')[0]
                : ''
      }
    } else if (
      field === 'operator' &&
      ['number', 'currency', 'percent'].includes(updatedCriteria[index].response_type)
    ) {
      // When changing operator for numeric types, ensure value is a number
      updatedCriteria[index] = {
        ...updatedCriteria[index],
        [field]: value,
        value:
          typeof updatedCriteria[index].value === 'number'
            ? updatedCriteria[index].value
            : parseFloat(updatedCriteria[index].value as string) || 0
      }
    } else if (field === 'operator' && updatedCriteria[index].response_type === 'date') {
      // When changing operator for date types, ensure value is a valid date string
      updatedCriteria[index] = {
        ...updatedCriteria[index],
        [field]: value,
        value: updatedCriteria[index].value || new Date().toISOString().split('T')[0]
      }
    } else {
      updatedCriteria[index] = {
        ...updatedCriteria[index],
        [field]: value
      }
    }

    setQualificationCriteria(updatedCriteria)
  }

  const handleTagsChange = (index: number, tags: string[]) => {
    const updatedCriteria = [...qualificationCriteria]
    // Set tags directly as strings
    updatedCriteria[index].value = tags
    setQualificationCriteria(updatedCriteria)
  }

  const handleRemoveCriteria = (index: number) => {
    const updatedCriteria = [...qualificationCriteria]
    updatedCriteria.splice(index, 1)
    setQualificationCriteria(updatedCriteria)
  }

  return (
    <GrayCard flexDirection="column">
      <FormControl as={Stack} spacing={6}>
        <Flex alignItems="center" gap={4}>
          <Flex flex="none" alignItems="center" justifyContent="center" boxSize={6} color="purple.500">
            <IconFlare size={20} />
          </Flex>
          <Box flex="1 1 auto">
            <FormLabel cursor="pointer" mb={0}>
              Qualification Agents
            </FormLabel>
            <Text fontSize="13px" lineHeight="19px" color="gray.500">
              Set up agents to automatically qualify accounts and prospects based on specific criteria
            </Text>
          </Box>
        </Flex>

        {qualificationCriteria.length > 0 ? (
          <>
            <Divider />
            <Stack spacing={4}>
              <Heading fontSize="11px" textTransform="uppercase" color="purple.500">
                Qualification Criteria ({qualificationCriteria.length})
              </Heading>

              {qualificationCriteria.map((criteria, index) => {
                const agent = myAgents.find((a) => a.id === criteria.assistant_id)
                if (!agent) return null

                const operators = getOperatorsForType(criteria.response_type)

                return (
                  <Box key={index} borderWidth="1px" borderRadius="md" bg="white" p={4} boxShadow="sm">
                    <Stack spacing={4}>
                      <Flex alignItems="flex-start" justifyContent="space-between">
                        <Stack spacing={1}>
                          <HStack>
                            <Text fontSize="sm" fontWeight="medium">
                              {agent.name}
                            </Text>
                            <Icon
                              as={agent.target === 'companies' ? BuildingIcon : IconUsers}
                              boxSize={3.5}
                              color={agent.target === 'companies' ? 'purple.500' : 'blue.500'}
                            />
                          </HStack>
                          <Text fontSize="xs" color="gray.500" noOfLines={2}>
                            {agent.question}
                          </Text>
                        </Stack>
                        <IconButton
                          aria-label="Remove qualification agent"
                          size="sm"
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => handleRemoveCriteria(index)}
                          icon={<IconTrash size={16} />}
                        />
                      </Flex>

                      <Box mt={4}>
                        <Text fontSize="xs" fontWeight="medium" mb={3}>
                          <Flex alignItems="center" display="inline-flex" gap={1}>
                            <BubbleTag variant="outline" colorScheme="green" fontWeight="semibold">
                              Qualify
                            </BubbleTag>
                            {targetType === 'Profile' ? 'people' : 'companies'} when the answer matches:
                          </Flex>
                        </Text>
                        <Grid templateColumns="1fr 1fr" gap={4}>
                          {criteria.response_type === 'boolean' ? (
                            <FormControl gridColumn="span 2">
                              <Flex gap={3}>
                                <Box
                                  as="button"
                                  type="button"
                                  onClick={() => handleCriteriaChange(index, 'value', true)}
                                  borderWidth="1px"
                                  borderRadius="md"
                                  p={2.5}
                                  flex="1"
                                  bg={criteria.value === true ? 'green.50' : 'white'}
                                  borderColor={criteria.value === true ? 'green.500' : 'gray.200'}
                                  _hover={{ borderColor: criteria.value === true ? 'green.600' : 'gray.300' }}
                                >
                                  <Flex alignItems="center" justifyContent="center" gap={2}>
                                    <Icon
                                      as={IconCheck}
                                      color={criteria.value === true ? 'green.500' : 'gray.400'}
                                      boxSize={4}
                                    />
                                    <Text
                                      fontSize="sm"
                                      fontWeight="medium"
                                      color={criteria.value === true ? 'green.700' : 'gray.600'}
                                    >
                                      Yes
                                    </Text>
                                  </Flex>
                                </Box>
                                <Box
                                  as="button"
                                  type="button"
                                  onClick={() => handleCriteriaChange(index, 'value', false)}
                                  borderWidth="1px"
                                  borderRadius="md"
                                  p={2.5}
                                  flex="1"
                                  bg={criteria.value === false ? 'red.50' : 'white'}
                                  borderColor={criteria.value === false ? 'red.500' : 'gray.200'}
                                  _hover={{ borderColor: criteria.value === false ? 'red.600' : 'gray.300' }}
                                >
                                  <Flex alignItems="center" justifyContent="center" gap={2}>
                                    <Icon
                                      as={IconBan}
                                      color={criteria.value === false ? 'red.500' : 'gray.400'}
                                      boxSize={4}
                                    />
                                    <Text
                                      fontSize="sm"
                                      fontWeight="medium"
                                      color={criteria.value === false ? 'red.700' : 'gray.600'}
                                    >
                                      No
                                    </Text>
                                  </Flex>
                                </Box>
                              </Flex>
                            </FormControl>
                          ) : (
                            <>
                              <FormControl>
                                <Select
                                  size="sm"
                                  value={criteria.operator}
                                  onChange={(e) => handleCriteriaChange(index, 'operator', e.target.value)}
                                >
                                  {operators.map((op) => (
                                    <option key={op.value} value={op.value}>
                                      {op.label}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                          )}

                          {(!criteria.response_type.includes('boolean') && criteria.response_type === 'list') ||
                          criteria.response_type === 'picklist' ? (
                            <FormControl>
                              <TagsInput
                                initialTags={Array.isArray(criteria.value) ? criteria.value.map(String) : []}
                                onChange={(tags) => handleTagsChange(index, tags)}
                                colorScheme="purple"
                                placeholder="Type and press Enter to add"
                                inputProps={{ size: 'sm' }}
                              />
                            </FormControl>
                          ) : (
                            !criteria.response_type.includes('boolean') && (
                              <FormControl>
                                <Input
                                  size="sm"
                                  type={
                                    ['number', 'currency', 'percent'].includes(criteria.response_type)
                                      ? 'number'
                                      : criteria.response_type === 'date'
                                        ? 'date'
                                        : 'text'
                                  }
                                  value={criteria.value?.toString() || ''}
                                  onChange={(e) => {
                                    let value
                                    if (['number', 'currency', 'percent'].includes(criteria.response_type)) {
                                      value = parseFloat(e.target.value)
                                    } else if (criteria.response_type === 'date') {
                                      value = e.target.value // Keep date as string in YYYY-MM-DD format
                                    } else {
                                      value = e.target.value
                                    }
                                    handleCriteriaChange(index, 'value', value)
                                  }}
                                  step={criteria.response_type === 'percent' ? '0.01' : '1'}
                                />
                              </FormControl>
                            )
                          )}
                        </Grid>
                      </Box>
                    </Stack>
                    {/* Hidden inputs for this criteria */}
                    <input
                      type="hidden"
                      name={`play[qualification_agents_config][${index}][assistant_id]`}
                      value={criteria.assistant_id}
                    />
                    <input
                      type="hidden"
                      name={`play[qualification_agents_config][${index}][response_type]`}
                      value={criteria.response_type}
                    />
                    <input
                      type="hidden"
                      name={`play[qualification_agents_config][${index}][operator]`}
                      value={criteria.operator}
                    />
                    {Array.isArray(criteria.value) ? (
                      criteria.value.map((val, valIndex) => (
                        <input
                          key={`criteria-value-${index}-${valIndex}`}
                          type="hidden"
                          name={`play[qualification_agents_config][${index}][value][]`}
                          value={typeof val === 'object' ? JSON.stringify(val) : val}
                        />
                      ))
                    ) : (
                      <input
                        type="hidden"
                        name={`play[qualification_agents_config][${index}][value]`}
                        value={
                          criteria.response_type === 'boolean'
                            ? criteria.value === true || criteria.value === 'true'
                              ? 'true'
                              : 'false'
                            : criteria.value?.toString() || ''
                        }
                      />
                    )}
                  </Box>
                )
              })}
            </Stack>
          </>
        ) : (
          <input type="hidden" name="play[qualification_agents_config][]" value="" />
        )}

        <AIAgentColumns
          target="people"
          onChange={handleAgentAdd}
          onColumnChange={(columns) => {
            // Get the current agent IDs in the criteria
            const currentAgentIds = qualificationCriteria.map((c) => c.assistant_id)

            // Find agents that were added (in columns but not in criteria)
            const addedAgents = columns
              .map((slug) => myAgents.find((a) => a.slug === slug))
              .filter((agent) => agent && !currentAgentIds.includes(agent.id)) as AIAgent[]

            // Add new criteria for added agents
            const newCriteria = addedAgents.map((agent) => ({
              assistant_id: agent.id || '',
              response_type: agent.response_type || 'string',
              operator: getOperatorsForType(agent.response_type || 'string')[0].value,
              value: ''
            }))

            // Find agent IDs that were removed (in criteria but not in columns)
            const selectedAgentSlugs = columns.filter(Boolean)
            const selectedAgentIds = myAgents.filter((a) => selectedAgentSlugs.includes(a.slug)).map((a) => a.id)

            // Filter out removed criteria and add new ones
            setQualificationCriteria((prev) => [
              ...prev.filter((c) => selectedAgentIds.includes(c.assistant_id)),
              ...newCriteria
            ])
          }}
          selectedColumns={qualificationCriteria
            .map((c) => {
              const agent = myAgents.find((a) => a.id === c.assistant_id)
              return agent?.slug || ''
            })
            .filter(Boolean)}
          popoverProps={{
            matchWidth: true,
            placement: 'bottom'
          }}
        >
          <Button isLoading={isLoading} variant="outline" bg="white" size="md" leftIcon={<IconPlus size={16} />}>
            Add Qualification Agent
          </Button>
        </AIAgentColumns>
      </FormControl>

      {selectedAgent && (
        <AgentModal
          isOpen={!!selectedAgent}
          onClose={() => setSelectedAgent(null)}
          selectedAgent={selectedAgent}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          selectedPerson={selectedPerson}
          setSelectedPerson={setSelectedPerson}
        />
      )}
    </GrayCard>
  )
}
