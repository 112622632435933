import { Button, Flex } from '@chakra-ui/react'
import { IconClockPlay } from '@tabler/icons-react'
import omit from 'lodash/omit'
import ms from 'ms'
import React from 'react'
import dayjs from '../../../../../lib/dayjs'
import { getFacetOperator, getFacetOperatorLabel, getFacetValues } from '../../../../data/use-facets'
import { TerritoryFilter } from '../../../../ui/filters/TerritoryFilter'
import { FilterGroup } from '../../../../ui/filters/types'
import { getItemDisplay } from '../../facets/categories'
import { getRangeValues, periodShorthands, quickRanges } from '../../facets/filter-cloud'
import FilterPopover from '../../facets/filter-popover'
import FilterTag from '../FilterTag'
import { FilterPreviewProps } from './types'

export const FilterPreview = (props: FilterPreviewProps) => {
  const {
    apps = {},
    facetFilters,
    setFacetFilters,
    range,
    focusTime,
    setRange,
    applyFilters,
    facetMappings,
    topFilters,
    setPage,
    setFocusTime,
    isEditable = true,
    formatLabel,
    usePortal = true,
    shouldShowSourceFilter,
    shouldShowTerritoryFilter,
    shouldShowLastSeenFilter = true
  } = props

  let colorScheme = props.colorScheme
  if (!colorScheme) {
    colorScheme = props.kind === 'profile' ? 'blue' : 'purple'
  }

  return (
    <Flex flex="1 1 auto" direction="row" alignItems="center" gap={2.5} flexWrap="wrap" maxWidth="100%">
      {isEditable && (
        <>
          <FilterPopover
            apps={apps}
            setRange={setRange}
            setPage={setPage}
            setFocusTime={setFocusTime}
            range={range}
            focusTime={focusTime}
            applyFilters={applyFilters}
            facetMappings={facetMappings}
            topFilters={topFilters}
            facetFilters={facetFilters}
            facetValuesPath={props.facetValuesPath}
            shouldShowCompanyFilters={props.shouldShowCompanyFilters}
            shouldShowUserAttributeFilters={props.shouldShowUserAttributeFilters}
            shouldShowActiveVisitorsFilters={props.shouldShowActiveVisitorsFilters}
            shouldShowIntentFilters={props.shouldShowIntentFilters}
            shouldShow3rdPartyFilters={props.shouldShow3rdPartyFilters}
            shouldShowStaticListFilters={props.shouldShowStaticListFilters}
            shouldShowListFilters={props.shouldShowListFilters}
            shouldShowICPFilters={props.shouldShowICPFilters}
            shouldAllowFreeEntry={props.shouldAllowFreeEntry}
            shouldShowTraits={props.shouldShowTraits}
            formatLabel={formatLabel}
            allowedKeys={props.allowedKeys}
            excludedKeys={props.excludedKeys}
            colorScheme={colorScheme}
            kind={props.kind}
            facetCloudLoading={props.facetCloudLoading}
            usePortal={usePortal}
            hideFacetCounts={props.hideFacetCounts}
          >
            {props.children}
          </FilterPopover>
        </>
      )}
      {shouldShowSourceFilter && (
        <FilterTag
          facet="sources"
          label={formatLabel ? formatLabel(getItemDisplay('sources', Object.values(apps), props.kind)) : 'Source'}
          icon={getItemDisplay('sources', Object.values(apps), props.kind)?.icon}
          maxValuesToDisplay={2}
          operator={
            getFacetValues(facetFilters['sources'])?.length
              ? getFacetOperatorLabel(facetFilters['sources'], facetMappings['sources']?.type)
              : undefined
          }
          value={facetFilters['sources']}
          isEditable={isEditable}
          shouldAllowFreeEntry={false}
          {...props}
        />
      )}
      {shouldShowTerritoryFilter && props.setTerritory && (
        <TerritoryFilter
          range={range}
          facetFilters={facetFilters}
          facetValuesPath={props.facetValuesPath}
          selectedTerritoryId={props.territory}
          onChange={props.setTerritory}
        />
      )}
      {range && range !== 'any' && shouldShowLastSeenFilter && (
        <FilterTag
          label="Last Seen"
          operator="in"
          facet="range"
          isEditable={isEditable}
          value={quickRanges[range]}
          onRemove={() => {
            setRange(null)
          }}
          {...props}
        />
      )}
      {focusTime && (
        <FilterTag
          label="Active Session Time"
          icon={IconClockPlay}
          operator={getFacetOperatorLabel(focusTime)}
          formatter={(value) => {
            const range = getRangeValues(value)

            if (range.eq) {
              return ms(range.eq, { long: true })
            } else if (range.gte && range.lte) {
              return [ms(range.gte), ms(range.lte)].join('-')
            } else if (range.gte) {
              return ms(range.gte, { long: true })
            } else if (range.lte) {
              return ms(range.lte, { long: true })
            } else {
              return ''
            }
          }}
          facet="focus_time"
          isEditable={isEditable}
          value={focusTime}
          onRemove={() => {
            setFocusTime(null)
          }}
          {...props}
        />
      )}
      {Object.entries(facetFilters).map(([filter, values]) => {
        if (shouldShowSourceFilter && filter === 'sources') {
          return null
        }

        // advanced filter groups
        if (filter === '_and' || filter === '_or') {
          // get the total count of filters in the group
          const count = (facetFilters[filter] as FilterGroup['_and'])?.flatMap((f) => {
            if (f._or) {
              return (f._or as NonNullable<FilterGroup['_or']>).flatMap((o) => Object.keys(o))
            }
            if (f._and) {
              return (f._and as NonNullable<FilterGroup['_and']>).flatMap((a) => Object.keys(a))
            }

            return Object.keys(f)
          }).length

          return (
            <FilterTag
              // if we use the filter as the key, it will re-render the entire popover
              // when you toggle from _and to _or or vice versa
              // which will close the popover before you are done using it
              key="advanced-filter"
              facet={filter}
              label={`Advanced filter (${count})`}
              value={facetFilters[filter]}
              {...props}
              facetFilters={facetFilters}
            />
          )
        }

        if (Array.isArray(values) && values.length === 0) {
          return null
        }

        const mapping = facetMappings[filter]
        const dataType = mapping?.type
        const operator = getFacetOperator(values)
        const operatorLabel = getFacetOperatorLabel(values, dataType)
        const display = getItemDisplay(filter, Object.values(apps), props.kind, mapping)
        let displayValues = values

        if (dataType !== 'date' && (operator == 'exists' || operator == 'not_exists')) {
          displayValues = []
        }

        return (
          <FilterTag
            key={filter}
            maxValuesToDisplay={2}
            facet={filter}
            label={formatLabel ? formatLabel(display) : display.label}
            icon={display.icon}
            formatter={
              dataType === 'date'
                ? (values) => {
                    const vals = getFacetValues(values)
                    const val = Array.isArray(vals) ? vals[0] : vals
                    let formatted = periodShorthands[val]

                    if (!formatted) {
                      if (operator === 'exists') {
                        formatted = val === 'true' ? 'anytime' : 'not set'
                      } else if (operator === 'not_exists') {
                        formatted = 'not set'
                      } else {
                        formatted = String(val)

                        const [first, last] = formatted.split('..')
                        if (first && last) {
                          formatted = [first, last].map((d) => dayjs(d).format('MMM D, YYYY')).join(' - ')
                        }
                      }
                    }
                    return formatted
                  }
                : undefined
            }
            operator={operatorLabel}
            value={displayValues}
            isEditable={isEditable}
            convertable
            onRemove={() => {
              setFacetFilters((filters) => omit(filters, filter))
            }}
            {...props}
          />
        )
      })}

      {props.canClearFilters && isEditable && (
        <Button size="sm" variant="link" onClick={props.clearFilters} marginLeft="auto">
          Reset filters
        </Button>
      )}
    </Flex>
  )
}
