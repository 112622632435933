import {
  Box,
  Circle,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Skeleton,
  SkeletonCircle,
  Text,
  UsePopperProps
} from '@chakra-ui/react'
import { IconUsers } from '@tabler/icons-react'
import React, { useCallback, useMemo } from 'react'
import { CrmUser, useCrmUsers } from '../data/use-crm-users'
import { User, useUsers } from '../data/use-users'
import Avatar from './Avatar'
import CircleIcon from './CircleIcon'
import { ComboboxWithSearch } from './ComboboxWithSearch'
import { Iconify } from './Iconify'
import { SalesforceIcon, UserCircleIcon } from './icons'
import { HubSpotIcon } from './icons/HubspotIcons'
import { useCurrentUser } from './UserContext'

const emptyArray = []

interface PartialUser {
  id?: string
  name?: string
  email?: string
  avatar?: string
  you?: boolean
}

export interface UserSelectorProps {
  users?: Array<PartialUser | CrmUser>
  selectedUserId?: string | null
  isReadOnly?: boolean
  includeCrmUsers?: boolean
  showUnassignedOption?: boolean
  showEveryoneOption?: boolean
  onChange?: (userId: string | null | undefined, user?: PartialUser | CrmUser) => void
  triggerProps?: FlexProps
  popoverProps?: FlexProps
  popperOptions?: UsePopperProps
  variant?: 'ghost' | 'outline'
}

const unassigned: PartialUser = {
  id: 'unassigned',
  name: 'No assignee'
}

const everyone: PartialUser = {
  id: '',
  name: 'Everyone'
}

const variantStyles = {
  ghost: {
    px: 1.5,
    py: 1,
    height: 7,
    border: 'none',
    gap: 1,
    _hover: { bg: 'gray.100' }
  },
  outline: {
    py: 1,
    height: 8
  }
}

export function UserSelector({
  selectedUserId,
  users: specificUsers,
  isReadOnly,
  includeCrmUsers,
  showUnassignedOption,
  showEveryoneOption,
  onChange,
  triggerProps,
  popoverProps,
  popperOptions,
  variant = 'ghost'
}: UserSelectorProps) {
  const users = useUsers({ cached: false, enabled: !specificUsers })
  const crm = useCrmUsers({ enabled: includeCrmUsers })
  const currentUser = useCurrentUser()

  const allUsers = useMemo(() => {
    const all: Array<PartialUser | CrmUser> = [...(specificUsers || users.data?.users || emptyArray)].map((u) => ({
      ...u,
      you: u.id === currentUser?.id
    }))

    if (showUnassignedOption) {
      all.unshift(unassigned)
    }

    if (showEveryoneOption) {
      all.unshift(everyone)
    }

    if (!includeCrmUsers) {
      return all
    }

    const emails = all.flatMap((u) => (u as User).emails)

    for (const user of crm.data?.users || emptyArray) {
      if (!emails.includes(user.email)) {
        all.push(user)
        emails.push(user.email)
      }
    }

    return all
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.data?.users, crm.data?.users, specificUsers, includeCrmUsers, showUnassignedOption, showEveryoneOption])

  const selected = useMemo(() => {
    const id = selectedUserId === null ? 'unassigned' : selectedUserId === undefined ? '' : selectedUserId
    return allUsers.find((u) => u.id === id)
  }, [allUsers, selectedUserId])

  const handleChange = useCallback(
    (user) => {
      if (!user) {
        onChange?.(null, undefined)
        return
      }

      // Special handling for everyone/unassigned
      if (user.id === '') {
        onChange?.(undefined, user) // undefined for everyone
      } else if (user.id === 'unassigned') {
        onChange?.(null, user) // null for unassigned
      } else {
        onChange?.(user.id, user) // normal user selection
      }
    },
    [onChange]
  )

  // if (users.isLoading) {
  //   return (
  //     <Flex alignItems="center" minHeight={variant === 'ghost' ? '28px' : '32px'} px={1}>
  //       <Spinner color="gray.300" thickness="1.5px" size="sm" />
  //     </Flex>
  //   )
  // }

  return (
    <Box>
      <ComboboxWithSearch
        items={allUsers}
        selectedItem={selected || null}
        isReadOnly={isReadOnly}
        isLoading={users.isLoading}
        onChange={handleChange}
        filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.email?.toLowerCase().includes(val) || false}
        itemToString={(item) => item?.name || ''}
        itemRenderer={UserRenderer}
        selectButtonRenderer={UserRenderer}
        triggerProps={{
          ...variantStyles[variant],
          ...triggerProps
        }}
        popoverProps={{
          maxW: popperOptions?.matchWidth ? undefined : '280px',
          ...popoverProps
        }}
        popperOptions={{
          matchWidth: false,
          placement: 'bottom-start',
          ...popperOptions
        }}
      />
    </Box>
  )
}

interface UserRendererProps {
  item: PartialUser | CrmUser | null
  selectedItem?: PartialUser | CrmUser | null
  isToggleButton?: boolean
  isLoading?: boolean
}

export function isCrmUser(user?: PartialUser | CrmUser | null): user is CrmUser {
  return !!user && (user as CrmUser).external_id !== undefined
}

export function isUser(user?: PartialUser | CrmUser | null): user is PartialUser {
  return !!user && !('external_id' in user)
}

function UserRenderer(props: UserRendererProps) {
  const user = props.item

  if (props.isLoading) {
    return (
      <Flex flex="1 1 auto" alignItems="center" gap={1.5}>
        <SkeletonCircle size="18px" flex="none" startColor="background.light" endColor="gray.200" />
        <Skeleton
          minWidth="100px"
          width="100%"
          flex="1 1 auto"
          height="16px"
          startColor="background.light"
          endColor="gray.200"
        />
      </Flex>
    )
  }

  return (
    <HStack spacing={1.5} lineHeight="20px" isTruncated>
      {user === unassigned || !user ? (
        <Circle borderWidth="1.5px" borderStyle="dashed" borderColor="gray.300" overflow="hidden">
          <Icon boxSize="15px" bg="gray.300" as={UserCircleIcon} color="white" />
        </Circle>
      ) : user === everyone ? (
        <CircleIcon p={0.5} iconSize="14px" icon={IconUsers} color="gray.500" />
      ) : isCrmUser(user) ? (
        <Iconify
          icon={user.source === 'salesforce' ? SalesforceIcon : HubSpotIcon}
          size={16}
          color={user.source === 'salesforce' ? 'salesforce' : 'hubspot'}
        />
      ) : (
        <Avatar size="tiny" name={user.name || user.email} src={user.avatar || undefined} />
      )}
      {user === unassigned || user === everyone ? (
        <Text fontSize="sm" fontWeight="medium" isTruncated>
          {user.name}
        </Text>
      ) : isUser(user) ? (
        <Text fontSize="sm" fontWeight="medium" isTruncated>
          {user.name || user.email || 'Unknown'}
          {(user as PartialUser).you ? ' (you)' : ''}
        </Text>
      ) : isCrmUser(user) ? (
        <Text fontSize="sm" fontWeight="medium" isTruncated>
          {user.email}
        </Text>
      ) : (
        <Text fontSize="sm" fontWeight="medium" isTruncated>
          {props.isToggleButton ? 'Select a user' : 'Unknown'}
        </Text>
      )}
    </HStack>
  )
}
