import { Box, Button, Flex, Heading, HStack, Tooltip, VStack } from '@chakra-ui/react'
import React from 'react'
import * as ReactIs from 'react-is'

export type Size = 'sm' | 'md' | 'lg'

export interface Props {
  onClick?: () => void
  icon?: React.ElementType | React.ReactElement
  size: Size
  heading: string
  description?: React.ReactNode
  ctaText?: string
  isLoading?: boolean
  isDisabled?: boolean
  ctaTooltip?: string
}

function sizeToStyleProps(size: Size) {
  let sizeProp
  switch (size) {
    case 'lg':
      sizeProp = 36
      break
    case 'md':
      sizeProp = 24
      break
    case 'sm':
      sizeProp = 20
  }

  return { size: sizeProp, padding: Math.ceil(sizeProp / 2) }
}

const EmptyState = ({
  size: sizeProp,
  heading,
  description,
  onClick,
  ctaText,
  isLoading,
  isDisabled,
  icon: Icon,
  ctaTooltip,
  children
}: React.PropsWithChildren<Props>) => {
  const { size, padding } = sizeToStyleProps(sizeProp)

  return (
    <Flex flexDir="column" alignItems="center" width="100%" gap={6} paddingY={10}>
      {ReactIs.isValidElementType(Icon) ? (
        <Box
          backgroundColor="gray.100"
          rounded="full"
          padding={`${padding}px`}
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="gray.500"
        >
          <Icon size={size} />
        </Box>
      ) : React.isValidElement(Icon) ? (
        Icon
      ) : null}

      <VStack spacing={1} textAlign="center">
        <Heading size={sizeProp}>{heading}</Heading>
        {description && (
          <Box fontSize="sm" color="gray.500">
            {description}
          </Box>
        )}
      </VStack>

      {children}

      {ctaText && onClick && (
        <HStack>
          <Tooltip label={ctaTooltip} isDisabled={!ctaTooltip}>
            <Button
              size="sm"
              colorScheme={isDisabled ? 'gray' : 'purple'}
              variant={isDisabled ? 'outline' : 'solid'}
              onClick={onClick}
              isLoading={isLoading}
              isDisabled={isDisabled}
            >
              {ctaText}
            </Button>
          </Tooltip>
        </HStack>
      )}
    </Flex>
  )
}

export default EmptyState
