import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react'
import { closestCenter, DndContext, KeyboardSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { IconFlare, IconLock, IconPlus, IconUser } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import router from '../../../lib/router'
import { AIAgent } from '../../../types/AIAgent'
import { App, Apps } from '../../../types/App'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import {
  ColumnInfo,
  ColumnItem,
  ColumnSelectorDropdown,
  defaultAccountColumns,
  defaultProfileColumns,
  DraggableColumn,
  keysToColumns,
  SmartPointerSensor
} from '../../ui/ColumnSelector'
import { BuildingIcon } from '../../ui/icons'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import SettingsHeader from '../../ui/SettingsHeader'
import SquareIcon from '../../ui/SquareIcon'
import { useEntitlements } from '../../ui/useEntitlements'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { AIAgentColumns } from '../ai_agents/components/AIAgentColumns'

const emptyObject = {}

interface ListLayout {
  id?: string
  name?: string
  list_type: 'account' | 'profile'
  columns?: string[] | null
  sort_by?: string | null
}

interface Props {
  apps?: Apps
  list_layout: ListLayout
  ai_agents?: AIAgent[]
}

export default function Show(props: Props) {
  const index = ['account', 'profile'].indexOf(props.list_layout.list_type)
  const apps: App[] = useMemo(() => Object.values(props.apps || emptyObject), [props.apps])

  return (
    <PageLayout size="full" gap={0}>
      <SettingsHeader border="none">
        <Flex alignItems="center" justifyContent="space-between">
          <PageTitle>List Layouts</PageTitle>
        </Flex>
        <PageDescription>
          Configure the default columns and their order for your lists of accounts or people.
        </PageDescription>
      </SettingsHeader>

      <Box>
        <Tabs
          size="sm"
          variant="line"
          defaultIndex={index || 0}
          onChange={(index) => {
            const listType = index === 0 ? 'accounts' : 'profiles'
            router.visit(projectPath(`/${listType}/layouts`))
          }}
        >
          <TabList>
            <Tab>Accounts</Tab>
            <Tab>People</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <ListLayoutForm
                columns={props.list_layout.columns}
                defaults={defaultAccountColumns.map((c) => c.key)}
                listType="account"
                apps={apps}
                aiAgents={props.ai_agents}
              />
            </TabPanel>
            <TabPanel p={0}>
              <ListLayoutForm
                columns={props.list_layout.columns}
                defaults={defaultProfileColumns.map((c) => c.key)}
                listType="profile"
                apps={apps}
                aiAgents={props.ai_agents}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </PageLayout>
  )
}

interface ListLayoutFormProps {
  columns?: null | string[]
  apps?: App[]
  id?: string
  defaults: string[]
  listType: 'account' | 'profile'
  aiAgents?: AIAgent[]
}

function ListLayoutForm(props: ListLayoutFormProps) {
  const [submitting, setSubmitting] = useState(false)
  const columns = props.columns?.length ? props.columns : props.defaults
  const [selectedColumns, setSelectedColumns] = useState(
    keysToColumns(columns, props.listType, props.apps, props.aiAgents) as Array<ColumnInfo & { id: string }>
  )
  const entitlements = useEntitlements()

  useUpdateEffect(() => {
    setSelectedColumns((prev) => {
      if (columns.join(',') !== prev.map((c) => c.key).join(',')) {
        return keysToColumns(columns, props.listType, props.apps, props.aiAgents) as Array<ColumnInfo & { id: string }>
      }

      return prev
    })
  }, [columns, props.listType, props.apps, props.aiAgents])

  const onRemoveColumn = useCallback((column) => {
    setSelectedColumns((columns) => columns.filter((c) => c.key !== column.key))
  }, [])

  const sensors = useSensors(
    useSensor(SmartPointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event

      if (active.id !== over.id) {
        setSelectedColumns((items) => {
          const oldIndex = items.findIndex((item) => item.id === active.id)
          const newIndex = items.findIndex((item) => item.id === over.id)
          return arrayMove(items, oldIndex, newIndex)
        })
      }
    },
    [setSelectedColumns]
  )

  const hasDiff = useMemo(() => {
    return columns.join(',') !== selectedColumns.map((c) => c.key).join(',')
  }, [columns, selectedColumns])

  const usingDefaults = useMemo(() => {
    return selectedColumns.map((c) => c.key).join(',') === props.defaults.join(',')
  }, [selectedColumns, props.defaults])

  const handleSubmit = useCallback(() => {
    setSubmitting(true)
  }, [])

  return (
    <form action={projectPath(`/${props.listType}s/layouts`)} method="POST" onSubmit={handleSubmit}>
      <input type="hidden" name="_method" value="PUT" />
      <input type="hidden" name="list_layout[name]" value="Default Layout" />
      {usingDefaults ? (
        <input type="hidden" name="list_layout[columns]" value="" />
      ) : (
        selectedColumns.map((c) => <input key={c.key} type="hidden" name="list_layout[columns][]" value={c.key} />)
      )}
      <AuthenticityToken />

      <Box width="100%" position="relative" paddingY={4}>
        <Flex width="100%" alignItems="center" justifyContent="space-between" py={2} marginBottom={4}>
          <Stack spacing={1}>
            <Heading size="xs">{props.listType === 'account' ? 'Accounts Layout' : 'People Layout'}</Heading>
            <Text fontSize="sm" color="gray.600">
              Configure the default columns
            </Text>
          </Stack>
          <Button
            type="submit"
            colorScheme={hasDiff ? 'purple' : 'gray'}
            variant={hasDiff ? 'solid' : 'outline'}
            size="sm"
            isLoading={submitting}
            isDisabled={!hasDiff || submitting}
          >
            Save changes
          </Button>
        </Flex>
        <Flex flexDirection="column" maxWidth="400px" gap={2}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="sm">Default columns:</Text>
            {!usingDefaults && (
              <Button
                size="xs"
                variant="link"
                onClick={() =>
                  setSelectedColumns(
                    keysToColumns(props.defaults, props.listType, props.apps, props.aiAgents) as Array<
                      ColumnInfo & { id: string }
                    >
                  )
                }
              >
                Reset to defaults
              </Button>
            )}
          </Flex>

          <Box
            position="relative"
            flex="1 1 auto"
            minH={0}
            display="flex"
            flexDirection="column"
            gap={1}
            overflow="auto"
          >
            <ColumnItem
              column={{ label: 'Name', key: 'name', icon: props.listType === 'profile' ? IconUser : BuildingIcon }}
              bg="background.light"
              cursor="default"
              border="1px solid"
              borderColor="gray.200"
              rightContent={<SquareIcon icon={IconLock} iconSize={3.5} bg="transparent" p="3px" ml="auto" />}
            />

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={selectedColumns} strategy={verticalListSortingStrategy}>
                {selectedColumns.map((column) => (
                  <DraggableColumn
                    key={column.id || column.key}
                    column={column}
                    onRemoveColumn={onRemoveColumn}
                    border="1px solid"
                    borderColor="gray.200"
                    color={column.color}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </Box>

          <Flex py={2} gap={2}>
            {entitlements?.ai_agents_beta && (
              <AIAgentColumns
                onColumnChange={(columns) => {
                  setSelectedColumns([
                    ...selectedColumns.filter((c) => !c.key.startsWith('ai.')),
                    ...(keysToColumns(
                      columns.map((c) => `ai.${c}`),
                      props.listType,
                      props.apps,
                      props.aiAgents
                    ) as Array<ColumnInfo & { id: string }>)
                  ])
                }}
                selectedColumns={selectedColumns
                  .filter((c) => c.key.startsWith('ai.'))
                  .map((c) => c.key.replace('ai.', ''))}
                target={props.listType === 'account' ? 'companies' : 'people'}
              >
                <Button
                  variant="outline"
                  colorScheme="purple"
                  size="sm"
                  pl={2}
                  iconSpacing={1}
                  leftIcon={<Icon as={IconFlare} color="purple.500" boxSize={4} />}
                >
                  Add AI Column
                </Button>
              </AIAgentColumns>
            )}

            <ColumnSelectorDropdown
              audienceKind={props.listType}
              apps={props.apps}
              selectedColumns={selectedColumns.map((c) => c.key)}
              onChange={(columns) => setSelectedColumns(columns as Array<ColumnInfo & { id: string }>)}
              aiAgents={props.aiAgents}
            >
              <Button variant="outline" size="sm" pl={2} iconSpacing={1} leftIcon={<Icon as={IconPlus} boxSize={4} />}>
                Add column
              </Button>
            </ColumnSelectorDropdown>
          </Flex>
        </Flex>
      </Box>
    </form>
  )
}
