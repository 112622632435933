import { Flex } from '@chakra-ui/react'
import React from 'react'
import { ImportList } from '../../../types/Imports'
import { Breadcrumb } from '../../ui/Breadcrumb'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TopBarContent } from '../../ui/TopBarContext'
import { ImportType } from './components/ImportType'
import { Uploader } from './components/Uploader'

interface Props {
  import: ImportList
}

const labels = {
  accounts: 'Accounts',
  contacts: 'People'
}

export default function New(props: Props) {
  const label = labels[props.import.kind] || 'People'

  return (
    <PageLayout flush p={6}>
      <PageTitle skipRendering>Import {label}</PageTitle>

      <TopBarContent>
        <Flex alignItems="center" gap={3}>
          <Breadcrumb
            paths={[
              { path: projectPath('/imports'), title: 'Imports' },
              { path: window.location.pathname, title: 'New Import' }
            ]}
          />

          <ImportType kind={props.import.kind} />
        </Flex>
      </TopBarContent>

      <Uploader import={props.import} />
    </PageLayout>
  )
}
