import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconArrowRight, IconDragDrop2 } from '@tabler/icons-react'
import { arrayMoveImmutable } from 'array-move'
import React, { useEffect, useMemo, useState } from 'react'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { IntentCampaignMapping } from '../../../../../types/AutoOutbound'
import { FollowRule } from '../../../../../types/FollowRule'
import { useAppDep } from '../../../../data/use-app-dep'
import { Card } from '../../../../ui/Card'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { SignalType } from '../../../kql_definitions/components/SignalType'
import { KqlDefinition } from '../../../kql_definitions/types'

interface Props {
  deps: any
  delivery_rules: FollowRule['delivery_rules']
  setDeliveryRules: React.Dispatch<React.SetStateAction<FollowRule['delivery_rules']>>
  kqlDefinitions?: KqlDefinition[]
}

interface InstantlyCampaign {
  id: string
  name: string
}

export function AddToCampaign({ delivery_rules, setDeliveryRules, kqlDefinitions }: Props) {
  const [enabled, setEnabled] = useState(Boolean(delivery_rules?.instantly?.add_to_campaign?.enabled))
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(
    delivery_rules?.instantly?.add_to_campaign?.campaign_id ?? null
  )

  const { data: campaignData, isLoading: loadingCampaigns } = useAppDep<'campaigns', InstantlyCampaign[]>(
    'instantly',
    'campaigns'
  )

  const [campaignOption, setCampaignOption] = useState(delivery_rules?.instantly?.add_to_campaign?.strategy ?? 'single')

  const handleEnableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setEnabled(isChecked)
    setDeliveryRules({
      ...delivery_rules,
      instantly: {
        ...delivery_rules?.instantly,
        add_to_campaign: {
          ...delivery_rules?.instantly?.add_to_campaign,
          enabled: isChecked
        }
      }
    })
  }

  return (
    <Stack w="100%" spacing="6">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={handleEnableChange}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][instantly][add_to_campaign][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>Adds the current visitor to a campaign and creates the contact if not present.</FormHelperText>
      </FormControl>

      {enabled && (
        <Stack w="100%" spacing="6">
          {loadingCampaigns ? (
            <Stack alignItems="center" justifyContent="center" py={4}>
              <Spinner size="md" />
              <Text fontSize="sm" color="gray.500">
                Loading campaigns...
              </Text>
            </Stack>
          ) : (
            <>
              {kqlDefinitions && kqlDefinitions.length > 0 && (
                <Stack>
                  <Heading size="xs">Campaign Strategy</Heading>
                  <RadioGroup
                    size="sm"
                    defaultValue={campaignOption}
                    onChange={(e) => setCampaignOption(e as 'single' | 'custom')}
                    name="follow_rule[delivery_rules][instantly][add_to_campaign][strategy]"
                  >
                    <Stack spacing="0.5">
                      <Radio bg="white" value="single">
                        Single Campaign
                      </Radio>
                      <Radio bg="white" value="custom">
                        Custom per Intent Signal
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Stack>
              )}

              <Divider />

              {campaignOption === 'single' && (
                <FormControl>
                  <FormLabel>Campaign</FormLabel>
                  {selectedCampaignId && (
                    <input
                      type="hidden"
                      name="follow_rule[delivery_rules][instantly][add_to_campaign][campaign_id]"
                      value={selectedCampaignId}
                    />
                  )}

                  <ComboboxWithSearch
                    items={campaignData?.data?.campaigns ?? []}
                    selectedItem={campaignData?.data?.campaigns?.find((c) => c.id === selectedCampaignId) ?? null}
                    onChange={(selectedItem) => {
                      setSelectedCampaignId(selectedItem?.id ?? null)
                    }}
                    filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                    itemToString={(item) => (item ? `[${item.id}] ${item.name}` : '')}
                  />
                </FormControl>
              )}

              {campaignOption === 'custom' && (
                <IntentSignalPairs
                  campaigns={campaignData?.data?.campaigns ?? []}
                  mappings={delivery_rules?.instantly?.add_to_campaign?.mappings ?? []}
                  kqlDefinitions={kqlDefinitions ?? []}
                />
              )}

              {kqlDefinitions && campaignOption === 'single' && (
                <Text fontSize="sm" color="gray.500">
                  Every prospect will be added into the same campaign
                </Text>
              )}

              {campaignOption === 'custom' && (
                <Text fontSize="sm" color="gray.500">
                  Each prospect will be added to a specific campaign depending on intent.
                </Text>
              )}
            </>
          )}
        </Stack>
      )}
    </Stack>
  )
}

interface IntentSignalPairsProps {
  kqlDefinitions: KqlDefinition[]
  mappings: IntentCampaignMapping[]
  campaigns: InstantlyCampaign[]
}

function IntentSignalPairs(props: IntentSignalPairsProps) {
  const mappings = useMemo(() => {
    const maps = props.mappings ?? []

    const mapped = props.kqlDefinitions.reduce((acc, kqlDef) => {
      const map = maps.find((m) => m.kql_definition_id === kqlDef.id)

      acc.push({
        kql_definition_id: kqlDef.id!,
        campaign_id: map?.campaign_id
      })

      return acc
    }, [] as IntentCampaignMapping[])

    // sort mapped by the positions in props.mappings
    return mapped.sort((a, b) => {
      const aIndex = props.mappings.findIndex((m) => m.kql_definition_id === a.kql_definition_id)
      const bIndex = props.mappings.findIndex((m) => m.kql_definition_id === b.kql_definition_id)

      return aIndex - bIndex
    })
  }, [props.mappings, props.kqlDefinitions])

  const [localMappings, setLocalMappings] = useState<IntentCampaignMapping[]>(mappings)

  useEffect(() => {
    setLocalMappings(mappings)
  }, [mappings])

  return (
    <>
      <Stack>
        <Heading size="xs">Pair each Intent Signal to a Campaign</Heading>
        <SortableList
          onSortEnd={(oldIndex: number, newIndex: number) => {
            setLocalMappings((array) => arrayMoveImmutable(array, oldIndex, newIndex))
          }}
          draggedItemClassName="dragged"
          style={{
            userSelect: 'none'
          }}
        >
          <Stack>
            {localMappings.map((entry) => {
              const kqlDefinition = props.kqlDefinitions.find((def) => def.id === entry.kql_definition_id)
              if (!kqlDefinition?.id) {
                return null
              }

              const campaignId = entry.campaign_id

              return (
                <SortableItem key={kqlDefinition.id}>
                  <HStack w="100%">
                    <SortableKnob>
                      <Box cursor={'grab'}>
                        <Tooltip label="Drag to change priorities" placement="top">
                          <IconDragDrop2 color="gray" size="14" />
                        </Tooltip>
                      </Box>
                    </SortableKnob>
                    <IntentCampaignPair
                      campaigns={props.campaigns}
                      kqlDefinition={kqlDefinition}
                      campaignId={campaignId}
                    />
                  </HStack>
                </SortableItem>
              )
            })}
          </Stack>
        </SortableList>
      </Stack>
    </>
  )
}

interface IntentCampaignPairProps {
  kqlDefinition: KqlDefinition
  campaignId?: InstantlyCampaign['id']
  campaigns: InstantlyCampaign[]
}

function IntentCampaignPair(props: IntentCampaignPairProps) {
  const [selectedCampaignId, setSelectedCampaignId] = React.useState<InstantlyCampaign['id'] | null>(
    props.campaignId ?? null
  )
  const kqlDefinition = props.kqlDefinition

  return (
    <>
      <HStack w="100%">
        <Flex bg="white" as={Card} p="2" flex="1" fontWeight={'normal'}>
          <SignalType
            label={`${kqlDefinition.name}${kqlDefinition.enabled === false ? ' (paused)' : ''}`}
            signalType={kqlDefinition.signal_type}
            compact
          />
        </Flex>
        <IconArrowRight size="14" />
        <Flex w="100%" flex="1">
          <CampaignSelector
            campaigns={props.campaigns}
            setSelectedCampaignId={setSelectedCampaignId}
            selectedCampaignId={selectedCampaignId}
          />
        </Flex>
      </HStack>
      {selectedCampaignId && (
        <>
          <input
            type={'hidden'}
            name={`follow_rule[delivery_rules][instantly][add_to_campaign][mappings][][campaign_id]`}
            value={selectedCampaignId}
          />
          <input
            type={'hidden'}
            name={`follow_rule[delivery_rules][instantly][add_to_campaign][mappings][][kql_definition_id]`}
            value={kqlDefinition.id}
          />
        </>
      )}
    </>
  )
}

interface CampaignSelectorProps {
  selectedCampaignId?: InstantlyCampaign['id'] | null
  setSelectedCampaignId: (id: InstantlyCampaign['id'] | null) => void
  campaigns: InstantlyCampaign[]
}

function CampaignSelector(props: CampaignSelectorProps) {
  const campaigns = useMemo(() => {
    return props.campaigns ?? []
  }, [props.campaigns])

  const selectedItem = useMemo(
    () =>
      campaigns.find((c) => {
        return c.id.toString() === props.selectedCampaignId?.toString()
      }) ?? null,
    [campaigns, props.selectedCampaignId]
  )

  return (
    <>
      <HStack w="100%">
        <ComboboxWithSearch
          items={campaigns}
          selectedItem={selectedItem}
          onChange={(selectedItem) => {
            props.setSelectedCampaignId(selectedItem?.id ?? null)
          }}
          filterItem={(a, val) => a.name.toLowerCase().includes(val)}
          itemToString={(item) => `[${item?.id}] ${item?.name || ''}`}
        />
      </HStack>
    </>
  )
}
