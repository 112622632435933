import React from 'react'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { PlayRoutingConditionType } from '../../../../../types/Play'
import { IconUserOff } from '@tabler/icons-react'
import { IconUser } from '@tabler/icons-react'
import { IconMapSearch } from '@tabler/icons-react'
import { SalesforceIcon } from '../../../../ui/icons'
import { IconReplaceUser } from '@tabler/icons-react'

export const STRATEGY_OPTIONS = [
  {
    type: 'crm_owner',
    title: 'CRM Owner',
    description: 'Assign leads based an ownership field in your CRM.',
    icon: SalesforceIcon
  },
  {
    type: 'round_robin',
    title: 'Round Robin',
    description: 'Assign leads evenly among team members.',
    icon: IconReplaceUser
  },
  {
    type: 'territory',
    title: 'Territory',
    description: 'Assign leads to your reps based on territories.',
    icon: IconMapSearch
  },
  {
    type: 'specific_person',
    title: 'Specific Person',
    description: 'Assign leads to a specific person',
    icon: IconUser
  },
  {
    type: 'unassigned',
    title: 'Unassigned',
    description: 'Don’t automatically assign leads',
    icon: IconUserOff
  }
]

export const StrategyRenderer = ({ item }: { item: (typeof STRATEGY_OPTIONS)[number] }) => {
  if (!item) {
    return null
  }

  return (
    <Flex alignItems="center" gap={3}>
      <Flex flex="none" alignItems="center" justifyContent="center" boxSize={6} color="gray.600">
        <Icon as={item.icon} boxSize={5} />
      </Flex>
      <Box>
        <Text fontSize="sm" fontWeight="medium">
          {item?.title}
        </Text>
        <Text fontSize="xs" color="gray.500">
          {item.description}
        </Text>
      </Box>
    </Flex>
  )
}

interface StrategySelectorProps {
  selectedStrategy: PlayRoutingConditionType | undefined
  setSelectedStrategy: (strategy: PlayRoutingConditionType) => void
}

export const StrategySelector = ({ selectedStrategy, setSelectedStrategy }: StrategySelectorProps) => (
  <Box>
    <ComboboxWithSearch
      items={STRATEGY_OPTIONS}
      selectedItem={STRATEGY_OPTIONS.find((option) => option.type === selectedStrategy) || null}
      onChange={(selectedItem) => {
        setSelectedStrategy(selectedItem?.type as PlayRoutingConditionType)
      }}
      filterItem={(a, val) => !!a?.title?.toLocaleLowerCase().includes(val)}
      itemToString={(item) => item?.title || ''}
      selectButtonRenderer={(props) => <StrategyRenderer item={props.item as (typeof STRATEGY_OPTIONS)[number]} />}
      itemRenderer={StrategyRenderer}
    />
  </Box>
)
