import { useImportToCRM } from '@app/components/data/use-import-to-crm'
import { Card } from '@app/components/ui/Card'
import { projectPath } from '@app/components/ui/ProjectsContext'
import useFuzzyCrmMatches from '@app/components/ui/useFuzzyCrmMatches'
import { Account } from '@app/types/Account'
import { App } from '@app/types/App'
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { IconAlertCircle } from '@tabler/icons-react'
import React from 'react'
import { toast } from 'sonner'
import { CrmMatchSummary } from './CrmMatchSummary'

interface ImportAccountSideSheetProps {
  account: Account | null
  app?: App
  onClose?: () => void
  onExit?: () => void
  onSuccess?: () => void
}

export function ImportAccountSideSheet(props: ImportAccountSideSheetProps) {
  const {
    allAccounts,
    strongMatches,
    fuzzyMatches,
    newAccount,
    setNewAccount,
    mainCrmRecord,
    selectedCrmRecord,
    setSelectedCrmRecord
  } = useFuzzyCrmMatches({ account: props.account, app: props.app, fuzzy: true })

  const importToCrm = useImportToCRM()

  const handleImport = React.useCallback(async () => {
    if (!props.account?.company.domain || !props.app) {
      return
    }

    const companyName = props.account.company.name
    const domain = props.account.company.domain

    try {
      if (!selectedCrmRecord) {
        const account = await importToCrm.mutateAsync({
          domain: domain,
          app_module: props.app.app_module
        })

        setNewAccount({
          id: domain,
          name: companyName!,
          updated_at: new Date().toISOString(),
          ...account
        })
      }

      toast.message(`🎉 ${companyName} imported`, {
        description: `You can now view the imported data in ${props.app.title || 'your CRM'}`
      })

      props.onClose?.()
      props.onSuccess?.()
    } catch (err: any) {
      toast.error(`${companyName} import failed`, {
        description:
          err.body?.error ?? 'Something is not quite right. Please reach out so we can help you debug what is wrong.'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.account?.company.domain,
    props.account?.company.name,
    props.app,
    selectedCrmRecord,
    importToCrm.mutateAsync
  ])

  const isImporting = importToCrm.isPending

  return (
    <Drawer
      size="lg"
      isOpen={Boolean(props.account && props.app)}
      placement="right"
      onClose={() => {
        props.onClose?.()
        props.onExit?.()
      }}
      preserveScrollBarGap
    >
      <DrawerOverlay zIndex="modal" />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading size="md" display="flex" gap={2} alignItems="center">
            {props.app ? (
              <>
                Import {props.account?.company.name} to
                <Image src={props.app.logo} boxSize="5" />
                {props.app.title}
              </>
            ) : (
              <>Connect your CRM to import {props.account?.company.name}</>
            )}
          </Heading>
        </DrawerHeader>

        <DrawerBody>
          <VStack alignItems="stretch" spacing={3}>
            {mainCrmRecord ? (
              <Text fontSize="sm" color="gray.600" fontWeight="medium">
                We found an exact domain match in {props.app?.title || 'the CRM'} for this company.
              </Text>
            ) : allAccounts.length ? (
              <Text fontSize="sm" color="gray.600" fontWeight="medium">
                We found some matches in {props.app?.title || 'the CRM'} for this company.
              </Text>
            ) : (
              <Text fontSize="sm" color="gray.600" fontWeight="medium">
                We couldn't find any matching accounts in {props.app?.title || 'the CRM'} by domain or name. Importing
                will create a new record for this company in {props.app?.title || 'the CRM'}.{' '}
                {props.app?.title === 'Salesforce' && (
                  <>
                    Optionally, you can customize how Koala maps fields to the Account object in the{' '}
                    <Link variant="primary" isExternal href={projectPath('/apps/salesforce')}>
                      Salesforce integration settings.
                    </Link>
                  </>
                )}
              </Text>
            )}

            {!strongMatches.length && (
              <Box>
                <Text fontSize="xs" fontWeight="medium" textTransform="uppercase" color="gray.500">
                  Create a new account
                </Text>
                <Card
                  display="flex"
                  borderWidth={1}
                  borderColor={!selectedCrmRecord ? 'blue.500' : 'gray.200'}
                  padding={4}
                  shadow="sm"
                >
                  {fuzzyMatches.length > 0 && (
                    <Checkbox
                      marginRight={4}
                      isDisabled={isImporting}
                      isChecked={!selectedCrmRecord}
                      onChange={() => setSelectedCrmRecord(undefined)}
                    />
                  )}
                  <CrmMatchSummary
                    name={props.account?.company.name}
                    logo={props.account?.company.logo}
                    domain={props.account?.company.domain}
                    permalink={newAccount?.permalink}
                    crm={props.app}
                  />
                  {isImporting && !selectedCrmRecord && <Spinner color="gray.400" />}
                </Card>
              </Box>
            )}

            {allAccounts
              .filter((a) => a.crm.title == props.app?.title)
              .map((crmAccount) => (
                <Card
                  key={crmAccount.crm_entity_id}
                  display="flex"
                  borderWidth={1}
                  borderColor={selectedCrmRecord === crmAccount.crm_entity_id ? 'blue.500' : 'gray.200'}
                  padding={4}
                  shadow="sm"
                >
                  {(strongMatches.length > 1 || fuzzyMatches.length > 0) && (
                    <Checkbox
                      marginRight={4}
                      isDisabled={isImporting}
                      isChecked={selectedCrmRecord === crmAccount.crm_entity_id}
                      value={crmAccount.crm_entity_id}
                      onChange={(e) => setSelectedCrmRecord(e.target.checked ? e.target.value : undefined)}
                    />
                  )}
                  <CrmMatchSummary
                    name={crmAccount.crm_entity.name}
                    logo={crmAccount.match_type !== 'name' ? props.account?.company.logo : undefined}
                    domain={crmAccount.crm_entity.domain}
                    permalink={crmAccount.crm_entity.permalink}
                    crm={crmAccount.crm}
                    matchType={crmAccount.match_type}
                  />
                  {isImporting && selectedCrmRecord === crmAccount.crm_entity_id && <Spinner color="gray.400" />}
                </Card>
              ))}
          </VStack>
        </DrawerBody>

        <DrawerFooter borderTop="1px solid" borderTopColor="gray.200">
          <Flex width="100%" gap={2} alignItems="center" justifyContent="end">
            <Button
              variant="outline"
              onClick={() => {
                props.onClose?.()
                props.onExit?.()
              }}
            >
              Cancel
            </Button>
            <Tooltip
              label={`Your user does not exist in ${props.app ? props.app?.title : 'the CRM'}. Koala will import ${
                props.account?.company.name ? props.account.company.name : 'the company'
              }, but you won't be assigned as their owner.`}
              p={4}
              isDisabled={props.app?.user_in_app}
            >
              <Button
                colorScheme="purple"
                isDisabled={!props.app || isImporting}
                isLoading={isImporting}
                loadingText="Import"
                onClick={handleImport}
                leftIcon={props.app && !props.app.user_in_app ? <IconAlertCircle size={16} /> : undefined}
              >
                Import Account
              </Button>
            </Tooltip>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
