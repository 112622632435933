import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, getCurrentProject } from '../ui/ProjectsContext'

interface EventDetailsProps {
  event: Record<string, any>
  signal_matches?: Record<string, any>[]
}

export function useEventDetails(eventType: string, eventId: string, enabled: boolean) {
  const project = getCurrentProject()
  const path = projectPath(`/events/details/${eventType}/${eventId}`)

  return useQuery<EventDetailsProps>({
    queryKey: ['event-details', { projectId: project?.id, eventId: eventId, eventType: eventType }],
    queryFn: () => concurrentGET<any>(path),
    enabled: enabled
  })
}
