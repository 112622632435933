import { ChakraProvider, Flex, Heading, Text } from '@chakra-ui/react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import React, { useMemo, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { InstrumentationContext, useInstrumentation } from './context/instrumentation-context'
import Page from './Page'
import { theme as customTheme } from './theme'
import ErrorBoundary from './ui/ErrorBoundary'
import GrayAccountContext from './ui/GrayAccountsContext'
import { OnlineStatusProvider } from './ui/OnlineStatusContext'
import { PermissionsProvider } from './ui/PermissionsContext'
import { LimitReason } from './ui/useEntitlements'

const cache = createCache({ key: 'koala' })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Don't automatically refetch when the window receives focus again
      refetchOnWindowFocus: false,
      // Don't automatically refetch when the network is back online
      refetchOnReconnect: false
    }
  }
})

export default function App() {
  const inst = useInstrumentation()
  const [isGrayAccountVisible, setIsGrayAccountVisible] = useState(false)
  const [grayAccountReason, setGrayAccountReason] = useState<LimitReason>()

  const grayAccountContext = useMemo(
    () => ({ isGrayAccountVisible, setIsGrayAccountVisible, grayAccountReason, setGrayAccountReason }),
    [isGrayAccountVisible, setIsGrayAccountVisible, grayAccountReason, setGrayAccountReason]
  )

  const errorView = (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" gridRowGap={2} minHeight="100vh">
      <Heading as="h1" size="lg">
        Whoops. Something went wrong.
      </Heading>
      <Text fontSize="lg">We've been notified of the error. In the meantime, try reloading the page.</Text>
    </Flex>
  )

  return (
    <CacheProvider value={cache}>
      <ChakraProvider theme={customTheme}>
        <ErrorBoundary errorView={errorView}>
          <QueryClientProvider client={queryClient}>
            <PermissionsProvider>
              <InstrumentationContext.Provider value={inst}>
                <OnlineStatusProvider>
                  <GrayAccountContext.Provider value={grayAccountContext}>
                    <Page />
                  </GrayAccountContext.Provider>
                </OnlineStatusProvider>
              </InstrumentationContext.Provider>
            </PermissionsProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </CacheProvider>
  )
}
