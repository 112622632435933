import { AlternateEmailsList } from '@app/components/ui/AlternateEmailsList'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  StackProps,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconArrowLeft } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import Avatar from '../../ui/Avatar'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { User } from '../../ui/UserContext'

interface FieldGroupProps extends StackProps {
  title?: string
}

export const FieldGroup = (props: FieldGroupProps) => {
  const { title, children, ...flexProps } = props
  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing="6" py="4" {...flexProps}>
      <Box minW="3xs">
        {title && (
          <Heading as="h2" fontSize="lg" flexShrink={0}>
            {title}
          </Heading>
        )}
      </Box>
      {children}
    </Stack>
  )
}

interface ShowProps {
  user: User & { can_change_credentials: boolean }
  errors?: any[]
}

export default function Show(props: ShowProps) {
  const user = props.user
  const showBreadCrumbs = window.location.pathname.includes('/settings/profile')

  return (
    <PageLayout size="sm">
      {showBreadCrumbs ? (
        <SettingsBreadCrumb offscreen />
      ) : (
        <Button
          alignSelf="flex-start"
          leftIcon={<Icon as={IconArrowLeft} boxSize={3.5} />}
          iconSpacing={1.5}
          variant="link"
          size="xs"
          flex="none"
          color="gray.500"
          as="a"
          href="/"
          marginBottom={4}
        >
          Back
        </Button>
      )}

      <SettingsHeader>
        <PageTitle>Profile</PageTitle>
        <PageDescription>Manage your account details.</PageDescription>
      </SettingsHeader>

      <Stack spacing="4" divider={<StackDivider />}>
        <ChangePersonalInfoForm user={props.user} errors={props.errors} />

        {user.can_change_credentials && (
          <FieldGroup title="Password">
            <Stack width="full" spacing="6">
              <Box width="100%">
                <ChangePasswordForm />
              </Box>

              <Text pt="4" fontSize={'sm'}>
                Forgot your password? <Link href={'/me/reset-link'}>Set a brand new password here.</Link>
              </Text>
            </Stack>
          </FieldGroup>
        )}

        <FieldGroup title="Profile Photo">
          <Stack direction="row" spacing="6" align="center" width="full">
            <Avatar size="lg" name={user.name || user.email} src={user.image} />
            <VStack alignItems="flex-start" spacing="sm">
              {user.provider ? (
                <Text>Your profile photo is set from your connected Google account.</Text>
              ) : (
                <Text>
                  When you log in via Google your profile photo will be set from your connected Google account.
                </Text>
              )}
            </VStack>
          </Stack>
        </FieldGroup>
      </Stack>
    </PageLayout>
  )
}

function ChangePersonalInfoForm(props) {
  const { user, errors } = props
  const [altEmail, setAltEmail] = useState('')

  const [emailChanged, setEmailChanged] = useState(false)
  const [altEmailChanged, setAltEmailChanged] = useState(false)
  const [nameChanged, setNameChanged] = useState(false)
  const [_name, setName] = useState(user.name)
  const [newEmail, setEmail] = useState(user.email)

  const altEmailDupe = React.useMemo(() => {
    return props.user.alternate_emails.map((e) => e.email).includes(altEmail)
  }, [altEmail, props.user.alternate_emails])

  const [submitting, setSubmitting] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentPassword, setCurrentPassword] = useState('')
  const initialRef = useRef(null)

  useEffect(() => {
    if (submitting) {
      if (emailChanged) {
        onOpen()
      } else {
        const form = document.getElementById('update_personal_info') as HTMLFormElement
        if (form) {
          form.submit()
        }
      }
    } else {
      onClose()
    }
  }, [submitting, emailChanged, onOpen, onClose])

  return (
    <>
      <Stack>
        {errors &&
          errors.map((message, index) => {
            return (
              <Alert status="error" key={index}>
                <AlertIcon />
                <Stack spacing="-1">
                  <AlertTitle>Validation Error</AlertTitle>
                  <AlertDescription>{message}</AlertDescription>
                </Stack>
              </Alert>
            )
          })}
      </Stack>
      <form id="update_personal_info" action="/me" method="POST" onSubmit={() => setSubmitting(true)}>
        <AuthenticityToken />
        <input type="hidden" name="_method" value="patch" />

        <FieldGroup title="Personal Info">
          <VStack width="full" spacing="6" alignItems="flex-start">
            <FormControl id="name">
              <FormLabel>Name</FormLabel>
              <Input
                size="sm"
                type="text"
                defaultValue={user.name}
                name="user[name]"
                onChange={(e) => {
                  setNameChanged(e.target.value !== user.name)
                  setName(e.target.value)
                }}
              />
            </FormControl>

            <FormControl id="email" size="sm">
              <FormLabel>Primary Email</FormLabel>
              <Input
                size="sm"
                type="email"
                defaultValue={user.email}
                name="user[email]"
                isDisabled={!user.can_change_credentials}
                onChange={(e) => {
                  setEmailChanged(e.target.value !== user.email)
                  setEmail(e.target.value)
                }}
              />
              {newEmail !== user.email && (
                <FormHelperText>
                  {user.can_change_credentials
                    ? 'You will have to confirm your new email before using it to login.'
                    : 'Changing your email is not possible when using SAML SSO.'}
                </FormHelperText>
              )}
            </FormControl>

            {user.can_change_credentials && (
              <>
                <Divider />
                <AlternateEmailsList user={user} showHeader={true} showPrimaryEmail={false} allowDeletion={true} />
                <FormControl>
                  <FormHelperText mb={2}>
                    Optionally add an alternate email. This is useful if your Salesforce or HubSpot accounts use a
                    different email and you want to link them to your account.
                  </FormHelperText>
                  <Input
                    size="sm"
                    placeholder={'New alternate email...'}
                    type="email"
                    name="user[alternate_emails_attributes][][email]"
                    onChange={(e) => {
                      setAltEmailChanged(true)
                      setAltEmail(e.target.value)
                    }}
                  />
                </FormControl>
              </>
            )}
            <Flex alignItems="center" gap={2}>
              <input type="hidden" name="user[current_password]" value={currentPassword} />
              <Button
                onClick={() => setSubmitting(true)}
                colorScheme="purple"
                size="sm"
                isDisabled={altEmailDupe || (!nameChanged && !emailChanged && !altEmailChanged)}
                isLoading={submitting}
              >
                Save Changes
              </Button>
              {altEmailDupe && (
                <Text fontSize={'xs'} color={'red.500'}>
                  Duplicate alternate email
                </Text>
              )}
            </Flex>
          </VStack>
        </FieldGroup>

        <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm your password</ModalHeader>
            <ModalBody pb={6}>
              <FormControl id="current_password">
                <FormLabel>Current Password</FormLabel>
                <Input
                  size="sm"
                  ref={initialRef}
                  type="password"
                  autoComplete="off"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <FormHelperText mb={2}>
                  Using Google OAuth or a Magic Link without a password?{' '}
                  <Link href={'/me/reset-link'}>Set a new password here.</Link>
                </FormHelperText>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={() => setSubmitting(false)} size="sm" variant="outline">
                Cancel
              </Button>
              <Button
                colorScheme="purple"
                ml={3}
                size="sm"
                type="submit"
                form="update_personal_info"
                isDisabled={!currentPassword}
              >
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </form>
    </>
  )
}

function ChangePasswordForm() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = React.useRef<HTMLInputElement | null>(null)

  return (
    <>
      <Button size="sm" onClick={onOpen}>
        Change Password
      </Button>

      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change password</ModalHeader>
          <ModalBody pb={6}>
            <form id="update_password" action="/me/password" method="post" data-koala-collect="off">
              <input type="hidden" name="_method" value="put" />
              <AuthenticityToken />

              <FormControl id="current_password">
                <FormLabel>Current Password</FormLabel>
                <Input size="sm" name="user[current_password]" ref={initialRef} type="password" autoComplete="off" />
              </FormControl>

              <FormControl mt={4} id="password">
                <FormLabel>New password</FormLabel>
                <Input
                  size="sm"
                  name="user[password]"
                  type="password"
                  pattern=".{12,64}"
                  autoComplete="off"
                  minLength={12}
                />
                <FormHelperText>Must be at least 12 characters.</FormHelperText>
              </FormControl>
            </form>
            <Text pt="4" fontSize={'sm'}>
              Using Google OAuth or a Magic Link without a password?{' '}
              <Link href={'/me/reset-link'}>Set a new password here.</Link>
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} size="sm" variant="outline">
              Cancel
            </Button>
            <Button colorScheme="purple" ml={3} size="sm" type="submit" form="update_password">
              Change Password
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
