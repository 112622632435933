import React, { useEffect, useState } from 'react'
import { Box, Heading, Stack } from '@chakra-ui/react'
import { subscribeToChannel, SubscriptionEmitter } from '../../../../channels/generic_channel'
import { PlaySourceProgress } from './PlaySourceProgress'
import { useCurrentProject } from '../../../ui/ProjectsContext'

interface PlaySource {
  id: string
  name: string
  status: 'queued' | 'processing' | 'complete'
  source_type: string
  total_items: number
  processed_items: number
  updated_at: string
  play_id: string
  error?: string
  source_data?: {
    repos?: string[]
    import_list_id?: string
  }
}

interface PlaySourceProgressManagerProps {
  playSources: PlaySource[]
}

export function PlaySourceProgressManager({ playSources }: PlaySourceProgressManagerProps) {
  const [sources, setSources] = useState<PlaySource[]>(playSources)
  const [subscriptions, setSubscriptions] = useState<Record<string, SubscriptionEmitter>>({})
  const importListSubscriptionsRef = React.useRef<Record<string, SubscriptionEmitter>>({})
  const project = useCurrentProject()

  // Filter to only show sources that are in progress or have just started
  // Using useMemo to recalculate when sources change
  const activeSources = React.useMemo(() => {
    return sources.filter((source) => source.status && source.status !== 'complete')
  }, [sources])

  useEffect(() => {
    // Update sources when props change
    setSources(playSources)

    // Setup subscriptions for each source
    if (project) {
      const newSubscriptions: Record<string, any> = {}

      playSources.forEach((source) => {
        // For CSV sources, subscribe to the ImportListsChannel
        if (source.source_type === 'csv' && source.source_data?.import_list_id && source.status !== 'complete') {
          // Clean up any existing subscription
          if (importListSubscriptionsRef.current[source.id]) {
            importListSubscriptionsRef.current[source.id].unsubscribe()
            delete importListSubscriptionsRef.current[source.id]
          }

          const importListId = source.source_data.import_list_id
          const importListSubscription = subscribeToChannel({
            channel: 'ImportListsChannel',
            id: importListId,
            project_slug: project.slug
          })

          importListSubscription.on('received', (data: any) => {
            // Translate ImportList update to PlaySource format
            setSources((prevSources) =>
              prevSources.map((s) => {
                if (s.id === source.id) {
                  // Map the import list status to play source status
                  let status: 'queued' | 'processing' | 'complete' = s.status
                  if (data.status === 'done' || data.action === 'finished') {
                    status = 'complete'
                  } else if (data.status === 'started' || data.action === 'started') {
                    status = 'processing'
                  }

                  return {
                    ...s,
                    status,
                    total_items: data.total_items || s.total_items,
                    processed_items: data.total_complete || s.processed_items
                  }
                }
                return s
              })
            )
          })

          importListSubscriptionsRef.current[source.id] = importListSubscription
        }
        // For all source types, use channel subscription
        else if (source.status !== 'complete' && !subscriptions[source.id]) {
          // Set up channel subscription for status updates
          const subscription = subscribeToChannel({
            channel: 'PlaySourceProgressChannel',
            id: source.id,
            project_slug: project.slug
          })

          subscription.on('received', (data: any) => {
            setSources((prevSources) =>
              prevSources.map((s) =>
                s.id === data.id
                  ? {
                      ...s,
                      status: data.status,
                      total_items: data.total_items,
                      processed_items: data.processed_items,
                      error: data.error
                    }
                  : s
              )
            )
          })

          newSubscriptions[source.id] = subscription
        }
      })

      setSubscriptions((prev) => ({ ...prev, ...newSubscriptions }))

      // Cleanup function
      return () => {
        // Clear all subscriptions
        Object.values(subscriptions).forEach((subscription) => {
          subscription.unsubscribe()
        })

        // Clear all import list subscriptions
        Object.values(importListSubscriptionsRef.current).forEach((subscription) => {
          subscription.unsubscribe()
        })
        importListSubscriptionsRef.current = {}
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playSources, project])

  if (activeSources.length === 0) {
    return null
  }
  return (
    <Box position="relative" bg="white" zIndex="10" p={4} mb={2}>
      <Stack spacing={3}>
        <Heading size="sm">Processing Play</Heading>
        {activeSources.map((source) => (
          <PlaySourceProgress
            key={source.id}
            status={source.status}
            totalItems={source.total_items}
            processedItems={source.processed_items}
            updatedAt={source.updated_at}
            error={source.error}
            showTotal={source.source_type === 'csv'}
            showCount={source.source_type === 'csv'}
          />
        ))}
      </Stack>
    </Box>
  )
}
