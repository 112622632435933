import { Button, Center, Flex, Heading, Img, Spinner, Stack, Text } from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { useApp } from '../../../../data/use-app'
import { useIntentSignals } from '../../../../data/use-intent-signals'
import { PopupConnectDialog } from '../../../apps/components/ConnectOauthAppDialog'
import { ReconnectionRequiredWarning } from '../../../apps/components/ReconnectionRequiredWarning'
import { KqlDefinition } from '../../../kql_definitions/types'
import { FollowRule } from '../../../../../types/FollowRule'
import { channelLogos, isChannelActive } from '../delivery-setup'
import { WrapTask } from '../wrap-task'
import { AddToCadence } from './add_to_cadence'

export interface SalesloftSetupProps {
  deliveryRules: FollowRule['delivery_rules']
  kqlDefinitionIds?: string[]
  actions?: {
    add_to_cadence?: boolean
  }
}

export interface SalesloftActionProps {
  deps: any
  deliveryRules: FollowRule['delivery_rules']
  setDeliveryRules: (rules: FollowRule['delivery_rules']) => void
  kqlDefinitions?: KqlDefinition[]
}

export function SalesloftSetup(props: SalesloftSetupProps) {
  const salesloft = useApp('Salesloft')
  const disconnected = useMemo(() => !salesloft.data?.connected, [salesloft.data])
  const invalid = useMemo(() => !salesloft.data?.valid, [salesloft.data])
  const [deliveryRules, setDeliveryRules] = React.useState(props.deliveryRules)
  const active = isChannelActive(props.deliveryRules, 'salesloft')

  const signals = useIntentSignals()

  const kqlDefinitions = useMemo(() => {
    if (!props.kqlDefinitionIds) {
      return undefined
    }

    const allDefs = signals.data?.definitions ?? []
    return allDefs.filter((def) => def.id && props.kqlDefinitionIds?.includes(def.id))
  }, [signals.data?.definitions, props.kqlDefinitionIds])

  const actions = useMemo(() => {
    if (props.actions) {
      return props.actions
    }

    return {
      add_to_cadence: true
    }
  }, [props.actions])

  const onConnected = useCallback(() => {
    salesloft.refetch()
  }, [salesloft])

  if (salesloft.isLoading) {
    return <Spinner />
  }

  if (salesloft.data && (disconnected || invalid)) {
    return (
      <Center w="100%">
        <Flex py="8">
          {salesloft.data && (disconnected || invalid) && (
            <PopupConnectDialog app_id={'salesloft'} onConnected={onConnected} active={active}>
              {({ onStart }) => (
                <Stack spacing="4">
                  <Stack spacing="0">
                    <Center>
                      <Heading size="sm">Connect your Salesloft Account</Heading>
                    </Center>
                    <Center>
                      <Text fontSize="sm" color="gray.600">
                        Please connect your Salesloft account in order to get started.
                      </Text>
                    </Center>
                  </Stack>
                  <Center>
                    <Button
                      leftIcon={<Img w="4" src={channelLogos.salesloft} />}
                      size="sm"
                      variant={'outline'}
                      onClick={onStart}
                    >
                      Connect Salesloft
                    </Button>
                  </Center>
                </Stack>
              )}
            </PopupConnectDialog>
          )}
        </Flex>
      </Center>
    )
  }

  if (!salesloft.data) {
    return null
  }

  return (
    <Stack spacing="8" py="8">
      <Stack spacing="8">
        {invalid && <ReconnectionRequiredWarning appTitle={'Salesloft'} variant="short" />}

        {actions.add_to_cadence && (
          <WrapTask name={<Text>Add to Cadence</Text>} enabled={deliveryRules?.salesloft?.add_to_cadence?.enabled}>
            <AddToCadence
              deps={salesloft}
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              kqlDefinitions={kqlDefinitions}
            />
          </WrapTask>
        )}
      </Stack>

      {props.deliveryRules?.salesloft && (
        <input type="hidden" name="follow_rule[delivery_rules][salesloft][trigger]" value="every hour" />
      )}
    </Stack>
  )
}
