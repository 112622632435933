import {
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Link
} from '@chakra-ui/react'
import { IconZoom } from '@tabler/icons-react'
import React, { useState } from 'react'
import { AdminBreadcrumb } from '..'
import router from '../../../../lib/router'
import { PageMeta } from '../../../../types/PageMeta'
import { Project } from '../../../../types/Project'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import SelectInput from '../../../ui/SelectInput'
import { TableFooter } from '../../../ui/TableFooter'
import { TimeAgo } from '../../../ui/TimeAgo'
import { mergeParams } from '../../icps/types'
import { JSONTree } from '../../../ui/json-tree'
import { useDebounce } from 'use-debounce'

interface ChangeLog {
  id: number
  action: string
  loggable_type: string
  loggable_id: string
  previous_data: Record<string, any>
  current_data: Record<string, any>
  created_at: string
  user?: {
    id: number
    email: string
    name: string
  }
  project?: {
    id: number
    name: string
    slug: string
  }
}

interface Props {
  projects: Project[]
  project?: null | Project
  page_meta: PageMeta
  logs: ChangeLog[]
}

const loggableTypes = ['All', 'FollowRule', 'PlaySource', 'Play']

export default function Index({ project, projects, logs, page_meta }: Props) {
  const searchParams = new URLSearchParams(window.location.search)
  const [selectedLog, setSelectedLog] = useState<ChangeLog | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loggableId, setLoggableId] = useState(searchParams.get('loggable_id') || '')
  const [debouncedLoggableId] = useDebounce(loggableId, 500)
  const [userId, setUserId] = useState(searchParams.get('user_id') || '')
  const [debouncedUserId] = useDebounce(userId, 500)

  React.useEffect(() => {
    router.visit(
      mergeParams(window.location.toString(), {
        loggable_id: debouncedLoggableId || undefined,
        page: '1'
      })
    )
  }, [debouncedLoggableId])

  React.useEffect(() => {
    router.visit(
      mergeParams(window.location.toString(), {
        user_id: debouncedUserId || undefined,
        page: '1'
      })
    )
  }, [debouncedUserId])

  const handleLogClick = (log: ChangeLog) => {
    setSelectedLog(log)
    onOpen()
  }

  return (
    <PageLayout size="md">
      <AdminBreadcrumb paths={[{ path: '/admin/change_logs', title: 'Change Logs' }]} />
      <PageTitle>Change Logs</PageTitle>
      <Stack spacing={8}>
        <Grid gap={3} gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))">
          <FormControl size="sm">
            <FormLabel>Workspace</FormLabel>
            <ComboboxWithSearch
              items={projects}
              selectedItem={projects.find((s) => s.slug === project?.slug) || null}
              onChange={(item) => {
                const workspace = item?.slug || ''
                const url = mergeParams(window.location.toString(), { workspace })
                router.visit(url)
              }}
              filterItem={(a, val) => a.name.toLowerCase().includes(val) || a.slug.toLowerCase().includes(val)}
              itemToString={(item) => item?.name ?? item?.slug ?? ''}
              itemRenderer={WorkspaceRenderer}
              selectButtonRenderer={WorkspaceRenderer}
              showClearButton={true}
            />
          </FormControl>
          <FormControl size="sm">
            <FormLabel>Record Type</FormLabel>
            <SelectInput
              variant="outline"
              placeholder="All"
              size="sm"
              items={loggableTypes}
              popperOptions={{ matchWidth: true }}
              selectedItem={searchParams.get('loggable_type') || 'All'}
              onSelectedItemChange={(event) => {
                const type = event.selectedItem === 'All' ? undefined : event.selectedItem
                router.visit(mergeParams(window.location.toString(), { loggable_type: type }))
              }}
            />
          </FormControl>
          <FormControl size="sm">
            <FormLabel>Record ID</FormLabel>
            <Input
              size="sm"
              placeholder="Enter ID"
              value={loggableId}
              onChange={(e) => setLoggableId(e.target.value)}
            />
          </FormControl>
          <FormControl size="sm">
            <FormLabel>User ID or Email</FormLabel>
            <Input
              size="sm"
              placeholder="Enter ID or email"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </FormControl>
        </Grid>

        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Time</Th>
                <Th>Action</Th>
                <Th>Record Type</Th>
                <Th>Record ID</Th>
                <Th>User</Th>
                <Th>Project</Th>
                <Th>Changes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {logs.map((log) => (
                <Tr key={log.id} _hover={{ bg: 'gray.50' }}>
                  <Td>
                    <TimeAgo time={log.created_at} />
                  </Td>
                  <Td>
                    <Text textTransform="capitalize">{log.action}</Text>
                  </Td>
                  <Td>
                    <Text>{log.loggable_type}</Text>
                  </Td>
                  <Td>
                    <Link
                      color="blue.500"
                      onClick={() => {
                        router.visit(
                          mergeParams(window.location.toString(), {
                            loggable_type: log.loggable_type,
                            loggable_id: String(log.loggable_id),
                            page: '1'
                          })
                        )
                      }}
                      cursor="pointer"
                    >
                      {log.loggable_id}
                    </Link>
                  </Td>
                  <Td>{log.user?.email}</Td>
                  <Td>{log.project?.name}</Td>
                  <Td>
                    <Icon
                      as={IconZoom}
                      boxSize={4}
                      cursor="pointer"
                      onClick={() => handleLogClick(log)}
                      color="blue.500"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <TableFooter
          pageMeta={page_meta}
          page={page_meta.current_page}
          nextPath={mergeParams(window.location.toString(), {
            page: String(page_meta.next_page)
          })}
          prevPath={mergeParams(window.location.toString(), {
            page: String(page_meta.prev_page)
          })}
        />
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {selectedLog && (
              <Stack spacing={4}>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem>
                    <Text fontWeight="semibold" mb={2}>
                      Previous State:
                    </Text>
                    <JSONTree data={selectedLog.previous_data || {}} />
                  </GridItem>
                  <GridItem>
                    <Text fontWeight="semibold" mb={2}>
                      Current State:
                    </Text>
                    <JSONTree data={selectedLog.current_data || {}} />
                  </GridItem>
                </Grid>
              </Stack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </PageLayout>
  )
}

function WorkspaceRenderer(props: { item: Project | null }) {
  const workspace = props.item

  if (!workspace) {
    return (
      <Text lineHeight="16px" fontSize="sm">
        Select a workspace
      </Text>
    )
  }

  return (
    <Text lineHeight="16px" fontSize="sm" fontWeight="medium">
      {workspace.slug}
    </Text>
  )
}
