import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SkeletonText,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconArrowRight, IconChevronDown, IconEdit, IconInfoCircle, IconRefresh } from '@tabler/icons-react'
import { orderBy } from 'lodash'
import { nanoid } from 'nanoid'
import React, { useEffect, useMemo, useState } from 'react'
import { useAiAgentsEnabled } from '../../../../data/use-ai-agents'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { AiSparklesIcon } from '../../../../ui/icons/AiSparklesIcon'
import { TextEllipsis } from '../../../../ui/text-ellipsis'
import { ActionField } from '../../../actions/components/action-field'
import { HubspotDeps, HubspotProp } from '../../../apps/hubspot/show'
import { AIAgentField } from '../AIAgentField'
import { BypassAllToggleButton } from '../bypass-toggle'
import { channelLogos } from '../delivery-setup'
import { Ingredient, variableFieldOptionsByType } from '../slack-message-builder/ingredient-selector'
import { ActionSchema } from '../slack-message-builder/use-action-schema'
import { NotificationVariables, useNotificationVariables } from '../slack-message-builder/use-notification-variables'

interface FieldMapperProps {
  actionSchema: ActionSchema
  deps: HubspotDeps
  type: 'contact' | 'account'
  namespace?: string
  mappings?: HubspotFieldMapping[]
  suggestedMappings?: HubspotFieldMapping[]
  refetch?: () => void
  loadingDeps?: boolean
  updateSettingEnabled?: boolean
  compact?: boolean
}

interface PropertyEntryProps {
  actionSchema: ActionSchema
  layout: HubspotProp[]
  variables: NotificationVariables
  namespace?: string
  id?: string
  type: string
  koalaField?: string
  hubspotField?: string
  koalaOptions: Array<{
    key: string
    label: string
    humanLabel: string
  }>
  mode: 'mapped' | 'hardcoded' | 'ai'
  bypassUpdate?: boolean
  onBypassUpdateChange: (any) => void
  updateSettingEnabled?: boolean
  value?: string
}

interface FieldPreviewProps {
  item: HubspotProp | null
  selectedItem?: HubspotProp | null
}

function FieldPreview(props: FieldPreviewProps) {
  const item = props.item
  const readonly = item?.readonly

  if (!item) {
    return (
      <HStack flex="1" fontSize="xs">
        <Img src={channelLogos.hubspot} w="4" />
        <Text>Select in HubSpot *</Text>
      </HStack>
    )
  }

  return (
    <Tooltip
      label={readonly ? 'Read only fields cannot be used in mapping, as you cannot write to them' : undefined}
      placement="right"
    >
      <HStack flex="1" fontSize={'sm'}>
        <Img src={channelLogos.hubspot} w="3" />
        <TextEllipsis maxW={'60'} color={readonly ? 'gray.600' : undefined} fontSize="xs" tooltip>
          {readonly && '(Read only) '}
          {item.label}
        </TextEllipsis>
      </HStack>
    </Tooltip>
  )
}

function PropertyEntry(props: PropertyEntryProps) {
  const [selected, setSelected] = useState<HubspotProp | null>(
    props.layout.find((l) => l.name === props.hubspotField) ?? null
  )

  const [selectedKoala, setSelectedKoala] = useState<Ingredient | null>(
    props.koalaOptions.find((o) => o.key === props.koalaField) ?? null
  )

  const orderedLayout = useMemo(() => {
    let layout = props.layout.filter((l) => !l.hidden)

    // sort alphabetically
    layout = orderBy(layout, (l) => l.label.toLowerCase(), 'asc')

    // then push read only fields to the bottom
    layout = orderBy(layout, (l) => l.readonly, 'asc')

    return layout
  }, [props.layout])

  const hasOptions = useMemo(() => {
    return selected && selected.options && selected.options.length
  }, [selected])

  const inputType = useMemo(() => {
    if (selected?.type === 'number') {
      return 'number'
    }

    if (selected?.field_type === 'date') {
      return 'date'
    }

    if (selected?.type === 'datetime') {
      return 'datetime-local'
    }

    if (hasOptions) {
      return 'text'
    }

    return 'text'
  }, [selected, hasOptions])

  const [hardcodedValue, setHardcodedValue] = useState<string>(props.value ?? props.koalaField ?? '')
  const [updateEnabled, setUpdateEnabled] = useState(!props?.bypassUpdate)
  const [aiAgent, setAiAgent] = useState<{ slug: string; answer_type: string; agent_field: string } | null>(
    props.mode === 'ai' ? { slug: '', answer_type: '', agent_field: props.value ?? props.koalaField ?? '' } : null
  )

  // Synchronize updateEnabled state with props.bypassUpdate
  useEffect(() => {
    setUpdateEnabled(!props?.bypassUpdate)
  }, [props.bypassUpdate])

  const handleUpdateEnabledChange = (e) => {
    const isChecked = e.target.checked
    props.onBypassUpdateChange(!isChecked)
  }

  return (
    <Flex w="100%">
      {selected && (
        <>
          {props.mode === 'mapped' && selectedKoala && (
            <input type="hidden" name={`${props.namespace}[fields][][koala]`} value={selectedKoala.key} />
          )}
          {props.mode === 'hardcoded' && hardcodedValue && (
            <input type="hidden" name={`${props.namespace}[fields][][koala]`} value={hardcodedValue} />
          )}
          {props.mode === 'ai' && aiAgent && (
            <input type="hidden" name={`${props.namespace}[fields][][koala]`} value={aiAgent.agent_field} />
          )}
          <input type="hidden" name={`${props.namespace}[fields][][hubspot]`} value={selected.name} />
          <input type="hidden" name={`${props.namespace}[fields][][id]`} value={props.id} />
          <input type="hidden" name={`${props.namespace}[fields][][mode]`} value={props.mode} />
          {!updateEnabled && <input type="hidden" name={`${props.namespace}[fields][][bypass_update]`} value="true" />}
        </>
      )}
      <Stack w="100%">
        <HStack w="100%" justifyContent="space-between" alignItems="center">
          {(props.mode === 'mapped' || !props.mode) && (
            <ActionField
              schema={props.actionSchema}
              selectedItem={selectedKoala}
              type={props.type ?? 'company'}
              onChange={(item) => {
                const selected = props.koalaOptions.find((o) => o.key === item.key) ?? null
                setSelectedKoala(selected)
              }}
            />
          )}

          {props.mode === 'hardcoded' && (
            <InputGroup>
              <InputLeftAddon>
                <Icon as={IconEdit} />
              </InputLeftAddon>
              <Input
                w="calc(100% - 32px)"
                name={`${props.namespace}[fields][][value]`}
                placeholder={`Enter a hardcoded ${selected?.type === 'number' ? 'number' : 'value'}`}
                borderLeft="none"
                value={hardcodedValue}
                onChange={(e) => setHardcodedValue(e.target.value)}
                fontSize={'sm'}
                required
                list={`options-${selected?.name}`}
                pattern={hasOptions ? selected?.options.map((o) => o.value).join('|') : '.*'}
                type={inputType}
              />
              {hasOptions && (
                <datalist id={`options-${selected?.name}`}>
                  {selected?.options?.map((o) => <option key={o.value} value={o.value} />)}
                </datalist>
              )}
            </InputGroup>
          )}

          {props.mode === 'ai' && (
            <HStack w="100%" alignItems="center" spacing="2">
              <AIAgentField
                agent_key={aiAgent?.agent_field ?? ''}
                targetType={props.type === 'contact' ? 'visitor' : 'account'}
                skipArrow
                onChange={({ slug, answer_type, agent_field }) => {
                  setAiAgent({ slug, answer_type, agent_field })
                }}
              />
            </HStack>
          )}
          <ComboboxWithSearch
            items={orderedLayout}
            selectedItem={selected}
            onChange={(item) => {
              const readonly = item && item.readonly
              if (readonly) {
                return false
              }

              setSelected(item)
            }}
            filterItem={(a, val) => a.label.toLowerCase().includes(val)}
            itemToString={(item) => item?.label ?? ''}
            itemRenderer={FieldPreview}
            selectButtonRenderer={FieldPreview}
          />

          {props.updateSettingEnabled && (
            <Flex width="20%" alignItems="center" justifyContent="center">
              <Checkbox isChecked={updateEnabled} onChange={handleUpdateEnabledChange} size="sm" colorScheme="purple" />
            </Flex>
          )}
        </HStack>
      </Stack>
    </Flex>
  )
}

export interface HubspotFieldMapping {
  id?: string
  koala?: string
  hubspot?: string
  mode?: 'mapped' | 'hardcoded' | 'ai'
  bypass_update?: boolean
  value?: string
}

export function FieldMapper(props: FieldMapperProps) {
  const variables = useNotificationVariables(true, false)
  const updateSettingEnabled = props?.updateSettingEnabled ?? false

  const layout = useMemo(() => {
    const opts = props.type === 'contact' ? props.deps.contact_layout : props.deps.company_layout
    return opts?.props.filter((o) => !o.hidden) ?? []
  }, [props.deps, props.type])

  const [mappings, setMappings] = useState<HubspotFieldMapping[]>(props.mappings ?? [])

  const options = useMemo(() => {
    const byType = variableFieldOptionsByType(variables)

    if (props.type === 'contact') {
      return (byType['visitor'] ?? []).concat(byType['signal'] ?? [])
    }

    return (byType['company'] ?? []).concat(byType['account'] ?? []).concat(byType['signal'] ?? [])
  }, [variables, props.type])

  const handleBypassUpdateChange = (id, newBypassUpdate) => {
    setMappings((prevMappings) => prevMappings.map((m) => (m.id === id ? { ...m, bypass_update: newBypassUpdate } : m)))
  }

  const hasAIAgents = useAiAgentsEnabled()

  return (
    <Stack fontSize={'sm'} spacing="4">
      <Stack spacing="1">
        {!props.compact && <Heading size="xs">Field Mappings</Heading>}
        <Text color="gray.500">Define how each Koala field maps to a HubSpot field.</Text>
      </Stack>
      <Stack spacing="4">
        {variables.isLoading ? (
          <SkeletonText noOfLines={Math.max(mappings.length, 2)} />
        ) : (
          <>
            <Stack spacing="2">
              {mappings.length > 0 && (
                <HStack spacing={2} justifyContent="space-between">
                  <Text fontWeight="medium" w={'42%'} textAlign="left">
                    Koala
                  </Text>
                  {/* Placeholder for the arrow icon inside PropertyEntry */}
                  <Text fontWeight="medium" w={'42%'} textAlign="left">
                    CRM
                  </Text>
                  {updateSettingEnabled && (
                    <>
                      <Flex alignItems="center" w="10%" justifyContent="center">
                        <Text fontWeight="medium">Update</Text>
                        <Tooltip
                          label="Should Koala overwrite values for these fields on existing records?"
                          fontSize="sm"
                          hasArrow
                          placement={'top'}
                        >
                          <Box as="span" ml={1}>
                            <IconInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Flex>
                      {/* Placeholder for the arrow icon inside PropertyEntry */}
                      <Box width="3.5%" />
                    </>
                  )}
                </HStack>
              )}

              {mappings.length === 0 && props.suggestedMappings && (
                <Stack bg="gray.50" p="4" rounded="md">
                  <Stack spacing="0.5">
                    <Heading size="xs">No Fields Selected</Heading>
                    <Text color="gray.500">You don't have any fields selected.</Text>
                  </Stack>

                  <Button
                    colorScheme={'purple'}
                    px="2"
                    size="xs"
                    onClick={() => {
                      setMappings(props.suggestedMappings ?? [])
                    }}
                  >
                    Use suggested fields
                  </Button>
                </Stack>
              )}

              {mappings.map((mapping) => {
                return (
                  <HStack key={mapping.id} justifyContent="space-between">
                    <PropertyEntry
                      actionSchema={props.actionSchema}
                      id={mapping.id}
                      namespace={props.namespace}
                      variables={variables}
                      layout={layout}
                      koalaOptions={options}
                      type={props.type}
                      koalaField={mapping.koala}
                      hubspotField={mapping.hubspot}
                      mode={mapping.mode ?? 'mapped'}
                      value={mapping.value}
                      bypassUpdate={mapping.bypass_update}
                      onBypassUpdateChange={(newBypassUpdate) => handleBypassUpdateChange(mapping.id, newBypassUpdate)}
                      updateSettingEnabled={props.updateSettingEnabled}
                    />
                    <Button
                      size="sm"
                      onClick={() => {
                        setMappings(mappings.filter((m) => m.id !== mapping.id))
                      }}
                      variant="ghost"
                      fontSize={'sm'}
                    >
                      &times;
                    </Button>
                  </HStack>
                )
              })}
            </Stack>
            <Flex justifyContent={'flex-end'} gap="2" w="100%">
              <Menu>
                <MenuButton size="xs" variant={'outline'} as={Button} rightIcon={<IconChevronDown size="12" />}>
                  Add field
                </MenuButton>
                <MenuList zIndex="popover">
                  <MenuItem
                    icon={<Icon as={IconArrowRight} />}
                    onClick={() => {
                      setMappings([...mappings, { id: nanoid(), mode: 'mapped' }])
                    }}
                  >
                    Mapped Field
                  </MenuItem>
                  <MenuItem
                    icon={<Icon as={IconEdit} />}
                    onClick={() => {
                      setMappings([...mappings, { id: nanoid(), mode: 'hardcoded' }])
                    }}
                  >
                    Hardcoded Field
                  </MenuItem>
                </MenuList>
              </Menu>

              {hasAIAgents && (
                <Button
                  size="xs"
                  variant="outline"
                  px="2"
                  colorScheme="purple"
                  leftIcon={<Icon as={AiSparklesIcon} />}
                  onClick={() => {
                    setMappings([...mappings, { id: nanoid(), mode: 'ai' }])
                  }}
                >
                  AI Agent
                </Button>
              )}

              <Tooltip label="Refresh the list of properties from HubSpot" placement="top">
                <IconButton
                  variant={'ghost'}
                  size="xs"
                  icon={<IconRefresh size="12" />}
                  onClick={props.refetch}
                  isLoading={props.loadingDeps}
                  aria-label="Refresh Properties"
                />
              </Tooltip>
              {mappings.length > 0 && updateSettingEnabled && (
                <BypassAllToggleButton mappings={mappings} setMappings={setMappings} />
              )}
            </Flex>
          </>
        )}
      </Stack>
    </Stack>
  )
}
