import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { Territory } from '../../types/Territory'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  territories: Territory[]
}

export function useTerritories() {
  const project = useCurrentProject()
  const path = projectPath('/territories')

  return useQuery<Data>({
    queryKey: ['territories', { projectId: project?.id }],
    queryFn: () => concurrentGET<{ territories: Territory[] }>(path),
    enabled: Boolean(project?.id)
  })
}
