import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SkeletonText,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import {
  IconBriefcase,
  IconBriefcaseFilled,
  IconBuilding,
  IconCoin,
  IconHash,
  IconMapPinFilled,
  IconMilitaryRankFilled,
  IconSearch,
  IconStack2
} from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { PlayTargetConfig } from '../../../../types/Play'
import { useCompanies } from '../../../data/use-companies'
import { useFacets } from '../../../data/use-facets'
import { useFilteredProspectsByCompanyFilters } from '../../../data/use-prospects'
import { Card, GrayCard } from '../../../ui/Card'
import { LinkedinBoxIcon } from '../../../ui/icons/LinkedInIcon'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { TableFooter } from '../../../ui/TableFooter'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { FacetFilters } from '../../accounts'
import { FilterMenu as CompanyFilterMenu } from '../../companies/components/ExploreCompaniesView'
import { convertUrlFacetsToPersonaFilters, FilterState } from '../../personas/persona-filters'
import { ProspectFilterMenu } from '../../prospector/components/ExploreProspectsView'

export function ProspectorColdSource({
  targetConfig,
  onTargetTypeChange,
  targetType
}: {
  targetConfig: PlayTargetConfig | undefined
  onTargetTypeChange: (targetType: 'Profile' | 'Account') => void
  targetType: 'Profile' | 'Account'
}) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const companyFacets = useFacets({
    facetCloudPath: '/companies/facet-cloud',
    facet_filters: targetConfig?.config?.company_filters ?? {}
  })

  const prospectFacets = useFacets({
    facet_filters: targetConfig?.config?.person_filters ?? {}
  })

  const [companiesPage, setCompaniesPage] = useState(1)
  const companiesQuery = useCompanies({
    facets: companyFacets.facetFilters as FacetFilters,
    page: companiesPage,
    per_page: 10
  })

  const prospectFilters = useMemo(() => {
    return convertUrlFacetsToPersonaFilters((prospectFacets.facetFilters as FacetFilters) || {})
  }, [prospectFacets.facetFilters])

  const companies = useMemo(() => {
    return companiesQuery?.data?.companies ?? []
  }, [companiesQuery])

  const companyFacetFilters = companyFacets.facetFilters

  const onCompanyFilterChange = useCallback(
    (filters: Record<string, FilterState>) => {
      // convert the filters to the format that the url filters expect
      const facetFilters: FacetFilters = {}

      for (const [facet, filter] of Object.entries(filters)) {
        if (filter.operator === 'must') {
          facetFilters[facet] = filter.values
        } else if (filter.operator === 'must_not') {
          facetFilters[facet] = { not: filter.values }
        }
      }

      companyFacets.applyFilters(facetFilters)
      setCompaniesPage(1)
    },
    [companyFacets]
  )

  const [prospectPage, setProspectPage] = useState(1)
  const prospectsQuery = useFilteredProspectsByCompanyFilters({
    page: prospectPage,
    perPage: 10,
    filters: { ...companyFacets.facetFilters, ...prospectFacets.facetFilters } as FacetFilters
  })

  const prospects = useMemo(() => {
    return prospectsQuery?.data?.prospects ?? []
  }, [prospectsQuery])

  const onProspectFilterChange = useCallback(
    (filters: Record<string, FilterState>) => {
      // convert the filters to the format that the url filters expect
      const facetFilters: FacetFilters = {}

      for (const [facet, filter] of Object.entries(filters)) {
        if (filter.operator === 'must') {
          facetFilters[facet] = filter.values
        } else if (filter.operator === 'must_not') {
          facetFilters[facet] = { not: filter.values }
        }
      }

      prospectFacets.applyFilters(facetFilters)
      setProspectPage(1)
    },
    [prospectFacets]
  )

  const isCompanyPreviewLoading = useMemo(() => {
    return companiesQuery.isLoading || companiesQuery.isFetching || companiesQuery.isRefetching
  }, [companiesQuery])

  const isProspectPreviewLoading = useMemo(() => {
    return prospectsQuery.isLoading || prospectsQuery.isFetching || prospectsQuery.isRefetching
  }, [prospectsQuery])

  const FilteringUI = ({ direction = 'row' }: { direction?: 'row' | 'column' }) => (
    <>
      <FormControl mb="4">
        <FormLabel>I'm prospecting for...</FormLabel>
        <RadioGroup size="sm" as={HStack} spacing={2}>
          <Radio
            isChecked={targetType === 'Profile'}
            onChange={() => {
              onTargetTypeChange('Profile')
              setProspectPage(1)
            }}
          >
            People
          </Radio>
          <Radio
            isChecked={targetType === 'Account'}
            onChange={() => {
              onTargetTypeChange('Account')
              setProspectPage(1)
              setCompaniesPage(1)
            }}
          >
            Companies
          </Radio>
        </RadioGroup>
      </FormControl>

      <Flex w="100%" gap={4} alignItems="flex-start" direction={direction}>
        <Stack spacing={2} w="100%">
          <Heading size="xs">Company Filters</Heading>
          <Card padding={0}>
            <Stack divider={<Divider />} spacing={0}>
              <CompanyFilterMenu
                facet="company.category.industry"
                filters={companyFacetFilters as Record<string, FilterState>}
                icon={IconBriefcase}
                onChange={onCompanyFilterChange}
                usePortal={false}
              >
                Industry
              </CompanyFilterMenu>
              <CompanyFilterMenu
                facet="company.metrics.employeesRange"
                filters={companyFacetFilters as Record<string, FilterState>}
                icon={IconHash}
                onChange={onCompanyFilterChange}
                usePortal={false}
              >
                Employees
              </CompanyFilterMenu>
              <CompanyFilterMenu
                facet="company.metrics.estimatedAnnualRevenue"
                filters={companyFacetFilters as Record<string, FilterState>}
                {...companyFacets}
                icon={IconCoin}
                onChange={onCompanyFilterChange}
                usePortal={false}
              >
                Est. Revenue
              </CompanyFilterMenu>
              <CompanyFilterMenu
                facet="company.tech"
                filters={companyFacetFilters as Record<string, FilterState>}
                {...companyFacets}
                icon={IconStack2}
                onChange={onCompanyFilterChange}
                usePortal={false}
              >
                Tech Stack
              </CompanyFilterMenu>
              <CompanyFilterMenu
                facet="company.geo.city"
                filters={companyFacetFilters as Record<string, FilterState>}
                {...companyFacets}
                icon={IconBuilding}
                onChange={onCompanyFilterChange}
                usePortal={false}
              >
                City
              </CompanyFilterMenu>
              <CompanyFilterMenu
                facet="company.geo.state"
                filters={companyFacetFilters as Record<string, FilterState>}
                {...companyFacets}
                icon={IconBuilding}
                onChange={onCompanyFilterChange}
                usePortal={false}
              >
                State
              </CompanyFilterMenu>
              <CompanyFilterMenu
                facet="company.geo.country"
                filters={companyFacetFilters as Record<string, FilterState>}
                {...companyFacets}
                icon={IconBuilding}
                onChange={onCompanyFilterChange}
                usePortal={false}
              >
                Country
              </CompanyFilterMenu>
            </Stack>
          </Card>
        </Stack>

        {targetType === 'Profile' && (
          <Stack spacing={2} w="100%">
            <Heading size="xs">Person Filters</Heading>
            <Card padding={0}>
              <Stack divider={<Divider />} spacing={0}>
                <ProspectFilterMenu
                  filters={prospectFilters}
                  onChange={onProspectFilterChange}
                  facet="job_title_levels"
                  icon={IconMilitaryRankFilled}
                  usePortal={false}
                >
                  Seniority
                </ProspectFilterMenu>
                <ProspectFilterMenu
                  filters={prospectFilters}
                  onChange={onProspectFilterChange}
                  facet="job_title_role"
                  icon={IconBriefcaseFilled}
                  usePortal={false}
                >
                  Role
                </ProspectFilterMenu>
                <ProspectFilterMenu
                  filters={prospectFilters}
                  onChange={onProspectFilterChange}
                  facet="job_title_sub_role"
                  icon={IconBriefcaseFilled}
                  usePortal={false}
                >
                  Job Function
                </ProspectFilterMenu>
                <ProspectFilterMenu
                  filters={prospectFilters}
                  onChange={onProspectFilterChange}
                  facet="title_keywords"
                  notFacet="not_keywords"
                  inputType="keyword"
                  icon={IconBriefcaseFilled}
                  usePortal={false}
                >
                  Title Keywords
                </ProspectFilterMenu>
                <ProspectFilterMenu
                  filters={prospectFilters}
                  onChange={onProspectFilterChange}
                  facet="location_country"
                  icon={IconMapPinFilled}
                  usePortal={false}
                >
                  Country
                </ProspectFilterMenu>
                <ProspectFilterMenu
                  filters={prospectFilters}
                  onChange={onProspectFilterChange}
                  facet="location_region"
                  icon={IconMapPinFilled}
                  usePortal={false}
                >
                  State / Region
                </ProspectFilterMenu>
                <ProspectFilterMenu
                  filters={prospectFilters}
                  onChange={onProspectFilterChange}
                  facet="location_locality"
                  icon={IconMapPinFilled}
                  usePortal={false}
                >
                  City
                </ProspectFilterMenu>
              </Stack>
            </Card>
          </Stack>
        )}
      </Flex>
    </>
  )

  return (
    <Stack spacing={8} as={GrayCard}>
      <Stack spacing={4}>
        <FilteringUI />

        <HStack justifyContent="space-between" pt="4">
          <HStack>
            <Text fontSize="sm" color="gray.600">
              {targetType === 'Account' && (
                <>
                  {isCompanyPreviewLoading ? (
                    'Estimating audience size...'
                  ) : (
                    <>
                      Matched{' '}
                      <Text as="span" fontWeight="bold">
                        {companiesQuery?.data?.page_meta?.total_count?.toLocaleString() ?? '0'}
                      </Text>{' '}
                      companies
                    </>
                  )}
                </>
              )}
              {targetType === 'Profile' && (
                <>
                  {isProspectPreviewLoading ? (
                    'Estimating audience size...'
                  ) : (
                    <>
                      Matched{' '}
                      <Text as="span" fontWeight="bold">
                        {prospectsQuery?.data?.page_meta?.total_count?.toLocaleString() ?? '0'}
                      </Text>{' '}
                      people
                    </>
                  )}
                </>
              )}
            </Text>

            {(isCompanyPreviewLoading || isProspectPreviewLoading) && <Spinner size="xs" />}
          </HStack>
          <Button
            leftIcon={<IconSearch size={16} />}
            size="sm"
            variant="outline"
            onClick={() => setIsPreviewOpen(!isPreviewOpen)}
          >
            Preview Results
          </Button>
        </HStack>

        <FormControl>
          <FormHelperText>
            <strong>Note:</strong> Koala will load up to 250 prospects per run of this play. Additional prospects will
            be loaded once all pending prospects are worked or dismissed.
          </FormHelperText>
        </FormControl>
      </Stack>
      <input type="hidden" name="play[target_type]" value={targetType} />
      <input
        type="hidden"
        name="play[target_config][config]"
        value={JSON.stringify({ company_filters: companyFacetFilters, person_filters: prospectFacets.facetFilters })}
      />
      <Modal isOpen={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} size="5xl">
        <ModalOverlay />
        <ModalContent maxH="90vh" overflowY="auto">
          <ModalCloseButton />
          <ModalHeader borderBottomWidth="1px">
            <Heading size="sm">Preview Results</Heading>
          </ModalHeader>

          <ModalBody p="6">
            <Stack spacing="8" alignItems="flex-start" w="100%" direction="row">
              <Stack w="100%" maxW="320px" minW="320px" maxH="calc(100vh - 300px)" overflowY="auto">
                <FilteringUI direction="column" />
              </Stack>
              <Stack spacing={8} w="100%" maxW="100%" overflowX="auto">
                {targetType === 'Account' && (
                  <Stack spacing={4}>
                    <TableContainer flex={1}>
                      {isCompanyPreviewLoading && <SkeletonText noOfLines={20} />}
                      {!isCompanyPreviewLoading && companies.length === 0 && <Text>No companies to preview</Text>}
                      {!isCompanyPreviewLoading && companies.length > 0 && (
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>Company</Th>
                              <Th>Website</Th>
                              <Th>Industry</Th>
                              <Th isNumeric>Employees</Th>
                              <Th>Location</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {companies.map((company) => (
                              <Tr key={company.id}>
                                <Td>
                                  <HStack>
                                    <CompanyAvatar size="xs" domain={company?.domain} />
                                    <TextEllipsis maxW="100px" tooltip>
                                      {company?.name ?? company?.domain}
                                    </TextEllipsis>
                                  </HStack>
                                </Td>
                                <Td>
                                  <Link href={`https://${company.domain}`} isExternal>
                                    {company.domain}
                                  </Link>
                                </Td>
                                <Td>
                                  <TextEllipsis maxW="100px" tooltip>
                                    {company.category?.industry}
                                  </TextEllipsis>
                                </Td>
                                <Td isNumeric>
                                  <TextEllipsis maxW="100px" tooltip>
                                    {company.metrics?.employeesRange}
                                  </TextEllipsis>
                                </Td>
                                <Td>
                                  <TextEllipsis maxW="100px" tooltip>
                                    {[company.geo?.city, company.geo?.countryCode].filter(Boolean).join(', ')}
                                  </TextEllipsis>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}
                    </TableContainer>
                    {companiesQuery?.data?.page_meta && (
                      <TableFooter
                        pageMeta={companiesQuery.data.page_meta}
                        page={companiesPage}
                        setPage={setCompaniesPage}
                      />
                    )}
                  </Stack>
                )}

                {targetType === 'Profile' && (
                  <Stack spacing={4}>
                    <TableContainer flex={1} overflowY="auto">
                      {isProspectPreviewLoading && <SkeletonText noOfLines={20} />}
                      {!isProspectPreviewLoading && prospects.length === 0 && <Text>No people to preview</Text>}
                      {!isProspectPreviewLoading && prospects.length > 0 && (
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>Name</Th>
                              <Th>Company</Th>
                              <Th>Title</Th>
                              <Th>Location</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {prospects.map((prospect) => (
                              <Tr key={prospect.id}>
                                <Td>
                                  <HStack>
                                    {prospect.linkedin_url && (
                                      <Tooltip label={`https://${prospect.linkedin_url.replace(/https?:\/\//, '')}`}>
                                        <Link
                                          display="flex"
                                          flex="none"
                                          alignItems="center"
                                          color="linkedin.700"
                                          isExternal
                                          href={`https://${prospect.linkedin_url.replace(/https?:\/\//, '')}`}
                                        >
                                          <LinkedinBoxIcon boxSize="18px" />
                                        </Link>
                                      </Tooltip>
                                    )}
                                    <TextEllipsis maxW="100px" tooltip>
                                      {prospect.name}
                                    </TextEllipsis>
                                  </HStack>
                                </Td>
                                <Td>
                                  <HStack>
                                    <CompanyAvatar size="4" domain={prospect.company?.domain} />
                                    <TextEllipsis
                                      maxW="100px"
                                      tooltip
                                      as={Link}
                                      href={`https://${prospect.company?.domain}`}
                                      isExternal
                                    >
                                      {prospect.company?.name ?? prospect.company?.domain}
                                    </TextEllipsis>
                                  </HStack>
                                </Td>
                                <Td>
                                  <TextEllipsis maxW="200px" tooltip>
                                    {prospect.title}
                                  </TextEllipsis>
                                </Td>
                                <Td>{[prospect.city, prospect.country_code].filter(Boolean).join(', ')}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}
                    </TableContainer>
                    {prospectsQuery?.data?.page_meta && (
                      <TableFooter
                        pageMeta={prospectsQuery.data.page_meta}
                        page={prospectPage}
                        setPage={setProspectPage}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter borderTopWidth="1px">
            <Button size="sm" colorScheme="purple" onClick={() => setIsPreviewOpen(false)}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}
