import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { Team } from '../pages/teams/new'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  teams: Team[]
}

export function useTeams() {
  const project = useCurrentProject()
  const basePath = '/settings/teams'

  const path = projectPath(basePath)

  return useQuery<Data>({
    queryKey: ['teams', { projectId: project?.id }],
    queryFn: () => concurrentGET<{ teams: Team[] }>(path),
    enabled: Boolean(project?.id)
  })
}
