import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import { IconSparkles } from '@tabler/icons-react'
import React, { useState } from 'react'
import { Play } from '../../../../types/Play'
import { GrayCard } from '../../../ui/Card'

export function EnrichmentSetup({ play }: { play?: Partial<Play> }) {
  const [waterfallEnrich, setWaterfallEnrich] = useState(play?.prospecting_config?.waterfall_enrichment ?? true)
  const [enrichmentRules, setEnrichmentRules] = useState(
    play?.prospecting_config?.enrichment_rules ?? {
      emails: true,
      continuation: 'continue',
      phone_numbers: false
    }
  )

  return (
    <>
      <GrayCard>
        <FormControl>
          <Stack spacing="4">
            <Flex alignItems="center" gap={4}>
              <Flex flex="none" alignItems="center" justifyContent="center" boxSize={6} color="gray.500">
                <IconSparkles size={20} />
              </Flex>
              <Box flex="1 1 auto">
                <FormLabel cursor="pointer" mb={0}>
                  Waterfall Enrichment
                </FormLabel>
                <Text fontSize="13px" lineHeight="19px" color="gray.500">
                  Automatically enrich emails, titles and LinkedIn profiles from 27+ sources
                </Text>
              </Box>
              <Box flex="none">
                <Switch isChecked={waterfallEnrich} onChange={(e) => setWaterfallEnrich(e.target.checked)} />
              </Box>

              {waterfallEnrich && (
                <input type="hidden" name="play[prospecting_config][waterfall_enrichment]" value="1" />
              )}
            </Flex>

            {waterfallEnrich && (
              <Stack spacing="4">
                <Divider />
                <Stack>
                  <Checkbox
                    isChecked={enrichmentRules.emails}
                    name="play[prospecting_config][enrichment_rules][emails]"
                    value="1"
                    onChange={(e) => setEnrichmentRules({ ...enrichmentRules, emails: e.target.checked })}
                  >
                    Enrich emails
                  </Checkbox>
                  <Checkbox
                    isChecked={enrichmentRules.phone_numbers}
                    name="play[prospecting_config][enrichment_rules][phone_numbers]"
                    value="1"
                    isDisabled
                    onChange={(e) => setEnrichmentRules({ ...enrichmentRules, phone_numbers: e.target.checked })}
                  >
                    Enrich phone numbers (Soon)
                  </Checkbox>
                </Stack>

                <Divider />

                <FormControl>
                  <FormLabel>If the prospect can't be enriched</FormLabel>
                  <RadioGroup
                    size="sm"
                    value={enrichmentRules.continuation}
                    onChange={(e) => setEnrichmentRules({ ...enrichmentRules, continuation: e as 'continue' | 'skip' })}
                  >
                    <Stack spacing="1">
                      <Radio name="play[prospecting_config][enrichment_rules][continuation]" value="continue">
                        Continue to next step
                      </Radio>
                      <Radio name="play[prospecting_config][enrichment_rules][continuation]" value="skip">
                        Disqualify from play
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Stack>
            )}
          </Stack>
        </FormControl>
      </GrayCard>
    </>
  )
}
