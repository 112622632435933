import {
  Box,
  Center,
  Collapse,
  Flex,
  Heading,
  IconButton,
  Link,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useUpdateEffect
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronRight, IconExternalLink } from '@tabler/icons-react'
import React, { useCallback, useEffect, useState } from 'react'
import { App } from '@type/App'
import { Play, PlayItem } from '@type/Play'
import { Card } from '@ui/Card'
import { projectPath } from '@ui/ProjectsContext'
import useLatestRef from '@ui/useLatestRef'
import { User } from '@ui/UserContext'
import { PlayItemsTable } from '../../plays/components/play-items-table'
import { useQueryClient } from '@tanstack/react-query'
import { usePlay } from '../../../data/use-plays'

interface PlayCardProps {
  play: Play
  apps: App[]
  selectedUser?: Partial<User> | null
  range?: 'day' | 'week' | 'month' | 'all'
  onAction?: (status: string) => void
  onLoading?: (playId: string, isLoading: boolean) => void
}

export function PlayCard({ apps, play, selectedUser, range, onAction, onLoading }: PlayCardProps) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })
  const { data, refetch, isLoading } = usePlay(play.id, {
    userId: selectedUser?.id,
    status: ['pending', 'qualified'],
    range
  })
  const onActionRef = useLatestRef(onAction)

  const queryClient = useQueryClient()

  useEffect(() => {
    onLoading?.(play.id, isLoading)
  }, [isLoading, onLoading, play.id])

  const [items, setItems] = useState<PlayItem[]>(data?.play_items ?? [])
  const [localPendingCount, setLocalPendingCount] = useState(
    (data?.stats?.pending ?? 0) + (data?.stats?.qualified ?? 0)
  )

  useUpdateEffect(() => {
    setItems(data?.play_items ?? [])
    setLocalPendingCount((data?.stats?.pending ?? 0) + (data?.stats?.qualified ?? 0))
  }, [data?.play_items, data?.stats?.pending, data?.stats?.qualified])

  // Remove item when the status is different from the selected status
  // If it's the same status, we don't need to remove it
  const onRemoveItem = useCallback(
    (itemId: string, status: string) => {
      if (status !== 'pending') {
        setItems((items) => items.filter((item) => item.id !== itemId))
        const newPendingCount = Math.max(0, localPendingCount - 1)
        setLocalPendingCount(newPendingCount)

        onActionRef.current?.(status)

        // Invalidate the inbox query to refresh the badge count
        queryClient.invalidateQueries({ queryKey: ['inbox'] }) // This will invalidate all inbox queries
        // If this was the last item, refetch to update the play list in parent components
        if (newPendingCount === 0) {
          queryClient.invalidateQueries({
            queryKey: ['play', play.id],
            exact: false
          })
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [onActionRef, queryClient, localPendingCount, play.id]
  )

  // Only keep items that are assigned to the selected user!
  const onUpdateItem = useCallback(
    (itemId: string, updatedItem: PlayItem) => {
      setItems((items) => {
        return items
          .map((item) => (item.id === itemId ? { ...item, ...updatedItem } : item))
          .filter((item) => (selectedUser?.id === 'unassigned' && !item.user_id) || item.user_id === selectedUser?.id)
      })

      // Invalidate the inbox query to refresh the badge count
      queryClient.invalidateQueries({ queryKey: ['inbox'] }) // This will invalidate all inbox queries
    },
    [selectedUser?.id, queryClient]
  )

  const pendingCount = localPendingCount
  const pendingLabel = pendingCount === 0 ? 'All caught up' : pendingCount.toLocaleString()
  const showRefreshButton = pendingCount > 0 && items.length === 0

  // hide if there are no pending items!
  if (pendingCount === 0) {
    return null
  }

  return (
    <Card p={0}>
      <Flex
        alignItems="center"
        gap={2}
        paddingX={3}
        paddingY={4}
        // borderBottom="1px solid"
        // borderBottomColor="gray.200"
        onClick={onToggle}
        cursor="pointer"
      >
        <IconButton
          size="sm"
          variant="ghost"
          rounded="full"
          aria-label="Toggle"
          icon={isOpen ? <IconChevronDown size={16} /> : <IconChevronRight size={16} />}
          onClick={onToggle}
        />

        <Stack flex="1" spacing={0}>
          <Heading size="sm">
            {play.name} {pendingCount ? `(${pendingLabel})` : ''}
          </Heading>
          <Text fontSize="13px" color="gray.500">
            {play.description}
          </Text>
        </Stack>

        <IconButton
          as={Link}
          size="sm"
          variant="ghost"
          rounded="full"
          aria-label="View play details"
          icon={<IconExternalLink size={16} />}
          href={
            selectedUser?.id && selectedUser.id !== 'unassigned'
              ? projectPath(`/plays/${play.id}?user=${selectedUser.id}`)
              : projectPath(`/plays/${play.id}`)
          }
          isExternal
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      </Flex>

      <Collapse in={isOpen}>
        <Box>
          {isLoading && (
            <Center height="100px">
              <Spinner />
            </Center>
          )}

          {!isLoading && (
            <PlayItemsTable
              apps={apps}
              items={items}
              recordType={play.target_type}
              displayColumns={play.display_columns!}
              status="pending"
              count={pendingCount}
              hideAssigneeColumn
              onRemoveItem={onRemoveItem}
              onUpdateItem={onUpdateItem}
              onFetchMore={showRefreshButton ? refetch : undefined}
              showQualificationColumn={play.qualifiable}
            />
          )}
        </Box>
      </Collapse>
    </Card>
  )
}
