import { EmailComposerPopup } from '@app/components/ui/EmailComposer'
import { InfoBox } from '@app/components/ui/InfoBox'
import {
  Box,
  Button,
  Divider,
  Grid,
  Heading,
  HStack,
  Icon,
  Link,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
  Wrap
} from '@chakra-ui/react'
import {
  IconBoltFilled,
  IconCheck,
  IconFlareFilled,
  IconHelp,
  IconMailSpark,
  IconUser,
  IconX
} from '@tabler/icons-react'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { subscribeToChannel, SubscriptionEmitter } from '../../channels/generic_channel'
import { concurrentCachedGET } from '../../lib/api'
import { Toggle } from '../pages/accounts/components/Toggle'
import { DetailsCard } from './Card'
import { CardHeading } from './CardHeading'
import CircleIcon from './CircleIcon'
import { HoverCard } from './HoverCard'
import { AiSparklesIcon, LinkedinBoxIcon } from './icons'
import MarkdownText from './MarkdownText'
import { projectPath, useCurrentProject } from './ProjectsContext'

export function useRecommendation(domain: string) {
  const project = useCurrentProject()
  const path = projectPath(`/inbox/${domain}/recommendation`)

  return useQuery({
    queryKey: ['ai-recommendation', { projectId: project?.id, domain }],
    queryFn: () => concurrentCachedGET(path),
    enabled: false
  })
}

export function useRecommendationStatus(job_id?: string, domain?: string) {
  const project = useCurrentProject()
  const subscription = React.useRef<SubscriptionEmitter | undefined>()
  const [response, setResponse] = React.useState<any>()

  React.useEffect(() => {
    if (subscription.current || !job_id || !domain) {
      return
    }
    // Reset the response when the job_id or domain changes
    setResponse(undefined)

    subscription.current = subscribeToChannel({
      channel: 'RecommendationChannel',
      project_slug: project?.slug,
      job_id,
      domain
    })

    const onData = (data) => {
      if (data.action === 'status') {
        setResponse(data.data)
      }
    }

    subscription.current.on('received', onData)

    return () => {
      subscription.current?.off('received', onData)
      subscription.current?.unsubscribe()
      subscription.current = undefined
    }
  }, [project?.slug, job_id, domain])

  return response
}

interface AIRecommendationOutputProps {
  output: any
  targetEmail?: string
  linkedin_url?: string
  onEmailSent?: () => void
  children?: React.ReactNode
  accountId: string
}

export default function AIRecommendationOutput(props: AIRecommendationOutputProps) {
  const { output, targetEmail, linkedin_url, children, accountId } = props
  if (!output) return <></>

  return (
    <Stack spacing={8} py={2}>
      {output && (
        <>
          <Stack spacing={4}>
            <HStack>
              <Icon as={IconFlareFilled} size={18} color="purple.500" />
              <Heading size="xs">AI Agent Insights</Heading>
            </HStack>

            <Grid gridTemplateColumns="repeat(auto-fit, minmax(120px, 1fr))" gap={2}>
              {output?.intent_summary && (
                <HoverCard
                  trigger="hover"
                  hoverContent={
                    <Box maxWidth="500px">
                      <Heading size="sm" pb="4" fontWeight="semibold">
                        Intent
                      </Heading>
                      <MarkdownText>{output?.intent_summary}</MarkdownText>
                    </Box>
                  }
                >
                  <DetailsCard maxH="100px" overflow="hidden" p="2" gap={0} flexDir="row" alignItems="center">
                    <Text flex="1" fontSize="13px" fontWeight="semibold">
                      Intent
                    </Text>
                    <Icon as={IconHelp} flex="none" boxSize={4} color="gray.400" />
                  </DetailsCard>
                </HoverCard>
              )}

              {output?.analysis && (
                <HoverCard
                  trigger="hover"
                  hoverContent={
                    <Box maxWidth="500px">
                      <Heading size="sm" pb="4" fontWeight="semibold">
                        Company Profile
                      </Heading>
                      <MarkdownText>{output?.analysis}</MarkdownText>
                    </Box>
                  }
                >
                  <DetailsCard maxH="100px" overflow="hidden" p="2" gap={0} flexDir="row" alignItems="center">
                    <Text flex="1" fontSize="13px" fontWeight="semibold">
                      Company Profile
                    </Text>
                    <Icon as={IconHelp} flex="none" boxSize={4} color="gray.400" />
                  </DetailsCard>
                </HoverCard>
              )}

              {output?.talking_points && (
                <HoverCard
                  trigger="hover"
                  hoverContent={
                    <Box maxWidth="500px">
                      <Heading size="sm" pb="4" fontWeight="semibold">
                        Talking Points
                      </Heading>
                      <MarkdownText>{output?.talking_points}</MarkdownText>
                    </Box>
                  }
                >
                  <DetailsCard maxH="100px" overflow="hidden" p="2" gap={0} flexDir="row" alignItems="center">
                    <Text flex="1" fontSize="13px" fontWeight="semibold">
                      Talking Points
                    </Text>
                    <Icon as={IconHelp} flex="none" boxSize={4} color="gray.400" />
                  </DetailsCard>
                </HoverCard>
              )}

              {output?.plays && (
                <HoverCard
                  trigger="hover"
                  hoverContent={
                    <Box maxWidth="500px">
                      <Heading size="sm" pb="4" fontWeight="semibold">
                        Plays
                      </Heading>
                      <MarkdownText>{output?.plays}</MarkdownText>
                    </Box>
                  }
                >
                  <DetailsCard maxH="100px" overflow="hidden" p="2" gap={0} flexDir="row" alignItems="center">
                    <Text flex="1" fontSize="13px" fontWeight="semibold">
                      Plays
                    </Text>
                    <Icon as={IconHelp} flex="none" boxSize={4} color="gray.400" />
                  </DetailsCard>
                </HoverCard>
              )}
            </Grid>
          </Stack>

          <Divider />
        </>
      )}
      {output?.json_metadata?.session_report?.topics && (
        <Stack>
          <Heading size="xs">Intent Topics</Heading>
          <Wrap>
            {output?.json_metadata?.session_report?.topics.map((topic: { topic: string; activity: string }) => (
              <Popover key={topic.topic} trigger="hover" isLazy lazyBehavior="unmount">
                <PopoverTrigger>
                  <Button
                    key={topic.topic}
                    colorScheme="purple"
                    variant="ghost"
                    bg="purple.50"
                    color="purple.500"
                    p="2"
                    size="xs"
                    rounded="full"
                    style={{
                      opacity: 1
                    }}
                    rightIcon={<IconBoltFilled size={12} />}
                  >
                    {topic.topic}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody fontSize="sm" fontWeight="normal" padding={4}>
                    {topic.activity}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            ))}
          </Wrap>
        </Stack>
      )}
      {children}
      {output.json_metadata && (
        <Stack fontSize="sm" spacing="8">
          <Toggle defaultIsOpen title={<CardHeading icon={<AiSparklesIcon size={18} />}>AI Insights</CardHeading>}>
            <Stack as={DetailsCard} spacing="0">
              {output.json_metadata.recommendation && (
                <Stack spacing={0}>
                  <Heading size="xs">{output.json_metadata.recommendation?.primary_tactic}</Heading>
                  <Text color="gray.500">{output.json_metadata.recommendation?.recommended_tactic}</Text>
                </Stack>
              )}

              {output.json_metadata.account_analysis?.additional_notable_information &&
                output.json_metadata.account_analysis.additional_notable_information.length > 0 && (
                  <UnorderedList pl="4" spacing={1}>
                    {output.json_metadata.account_analysis.additional_notable_information.map((reason) => (
                      <ListItem key={reason}>{reason}</ListItem>
                    ))}
                  </UnorderedList>
                )}

              <Toggle title={<Text color="gray.500">Reasoning</Text>} defaultIsOpen>
                <UnorderedList pl="4" spacing={1}>
                  {output.json_metadata.recommendation?.reasoning?.map((reason) => (
                    <ListItem key={reason}>{reason}</ListItem>
                  ))}
                </UnorderedList>
              </Toggle>
            </Stack>
          </Toggle>

          <Toggle
            title={
              <CardHeading icon={<Icon color="purple.500" as={IconBoltFilled} size="24px" />}>
                AI Signal analysis
              </CardHeading>
            }
          >
            <Stack as={DetailsCard} spacing="-1">
              {output.json_metadata.account_analysis?.signal_analysis?.map((point) => (
                <Stack key={JSON.stringify(point)} spacing={0}>
                  <HStack spacing={1}>
                    {point.present ? (
                      <CircleIcon variant="subtle" colorScheme="green" icon={IconCheck} size={4} />
                    ) : (
                      <CircleIcon variant="subtle" colorScheme="red" icon={IconX} size={4} />
                    )}
                    <Text fontSize="sm" fontWeight="medium">
                      {point.signal_name}
                    </Text>
                  </HStack>
                  <Text color="gray.500">{point.evidence}</Text>
                </Stack>
              ))}
            </Stack>
          </Toggle>

          {output?.json_metadata?.session_report && (
            <Toggle title={<CardHeading icon={<IconUser size={18} />}>Users</CardHeading>}>
              <Stack as={DetailsCard}>
                {output?.json_metadata?.session_report?.users.map(
                  (user: { name: string; title: string; activity: string }) => (
                    <Stack key={user.name} fontSize="sm" spacing={0}>
                      <Stack spacing={-0.5}>
                        <Text fontWeight="semibold">{user.name}</Text>
                        <Text fontSize="sm" color="gray.500">
                          {user.title}
                        </Text>
                      </Stack>
                      <UnorderedList pl="4" spacing={1} pt="1">
                        <ListItem fontSize="sm" color="gray.500">
                          {user.activity}
                        </ListItem>
                      </UnorderedList>
                    </Stack>
                  )
                )}
              </Stack>
            </Toggle>
          )}
        </Stack>
      )}
      <Divider />
      {output.emails?.emails && (
        <Stack spacing={4}>
          <CardHeading icon={<IconMailSpark size={18} />}>Suggested messaging</CardHeading>
          {!targetEmail && linkedin_url && (
            <InfoBox colorScheme="orange">
              <Text>
                We couldn't enrich the email on this lead.{' '}
                <Tooltip label={`https://${linkedin_url.replace(/https?:\/\//, '')}`}>
                  <Text as="span">
                    But you could engage them via
                    <Link
                      ml="1"
                      display="inline"
                      color="linkedin.700"
                      isExternal
                      href={`https://${linkedin_url.replace(/https?:\/\//, '')}`}
                    >
                      LinkedIn <LinkedinBoxIcon boxSize="18px" />
                    </Link>
                  </Text>
                </Tooltip>
              </Text>
            </InfoBox>
          )}
          {output.emails?.emails.map((email) => (
            <DetailsCard key={email.subject}>
              <Heading size="xs" fontWeight={'semibold'}>
                {email.subject}
              </Heading>
              <MarkdownText>{email.body}</MarkdownText>
              <Box ml="auto" display="inline-block">
                <EmailComposerPopup
                  email={{ to: targetEmail, ...email }}
                  onEmailSent={props.onEmailSent}
                  accountId={accountId}
                  itemId={accountId}
                />
              </Box>
            </DetailsCard>
          ))}
        </Stack>
      )}
    </Stack>
  )
}
