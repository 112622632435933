import { useMutation } from '@tanstack/react-query'
import { del, post } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'

interface Params {
  spaceId: string
  userId: string
}

export function useRemoveFromSpace() {
  return useMutation({
    mutationFn: ({ spaceId, userId }: Params) => {
      const path = projectPath(`/spaces/${spaceId}/members/${userId}`)
      return del(path)
    }
  })
}

export function useAddToSpace() {
  return useMutation({
    mutationFn: ({ spaceId, userId }: Params) => {
      const path = projectPath(`/spaces/${spaceId}/members`)
      return post(path, { user_id: userId })
    }
  })
}
