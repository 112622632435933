import { Text } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { toast } from 'sonner'
import dayjs from '../../../../lib/dayjs'
import { Engagement } from '../../../../types/Engagement'
import { PlayItem } from '../../../../types/Play'
import { useCompletePlayItem } from '../../../data/use-plays'
import { EmailComposerPopup, EmailProps } from '../../../ui/EmailComposer'
import { LastEngagementHoverCard } from '../../../ui/LastEngagement'
import { TimeAgo } from '../../../ui/TimeAgo'
import { WarningMessage } from '../../../ui/WarningMessage'

interface EmailButtonProps {
  record: PlayItem['record']
  item: PlayItem
  draftEmail?: EmailProps
  buttonText?: string
  onEmailSent?: (itemId: string, status: string) => void
  defaultIsOpen?: boolean
  partOfSplitButton?: boolean
}

export type RecommendedPlay = {
  play?: string
  name?: string
  reasoning?: string[]
}

export function EmailButton({
  record,
  item,
  buttonText,
  draftEmail,
  onEmailSent,
  defaultIsOpen,
  partOfSplitButton
}: EmailButtonProps) {
  const { mutate: completeItem } = useCompletePlayItem({
    onSuccess: () => {
      onEmailSent?.(item.id, 'completed')
    },
    onError: (error: any) => {
      toast.error('There was an issue marking this lead as complete', {
        description: error?.body?.message || error?.message
      })
    }
  })

  const handleEmailSent = useCallback(() => {
    completeItem({ itemId: item.id })
  }, [item.id, completeItem])

  // TODO: also support SFDC/HS last sales activity dates
  const lastActivity = record.last_activity as Engagement | undefined
  const lastAccountActivity = record.last_account_activity as Engagement | undefined
  const lastActivityDate = record.last_activity_date as string | undefined

  return (
    <EmailComposerPopup
      email={{ to: record.email, ...draftEmail }}
      accountId={record.account_id}
      onEmailSent={handleEmailSent}
      itemId={item.id}
      selectedProspect={item}
      buttonText={buttonText}
      defaultIsOpen={defaultIsOpen}
      partOfSplitButton={partOfSplitButton}
      warningMessage={
        (lastActivity || lastAccountActivity) && messagedRecently(lastActivity || lastAccountActivity) ? (
          <WarningMessage>
            Emailed with{' '}
            <LastEngagementHoverCard engagement={lastActivity || lastAccountActivity}>
              <Text as="span" fontWeight="semibold" textDecoration="underline">
                {lastActivity ? record.name || record.email : record.company?.name}
              </Text>
            </LastEngagementHoverCard>{' '}
            <TimeAgo time={(lastActivity || lastAccountActivity)?.created_at} />. Are you sure you want to send another
            message?
          </WarningMessage>
        ) : lastActivityDate && messagedRecently(lastActivityDate) ? (
          <WarningMessage>
            Last touched <TimeAgo time={lastActivityDate} />. Are you sure you want to send a message?
          </WarningMessage>
        ) : undefined
      }
    />
  )
}

function messagedRecently(lastActivity?: Engagement | string) {
  if (typeof lastActivity === 'string') {
    return dayjs(lastActivity).isAfter(dayjs().subtract(90, 'day'))
  }

  if (lastActivity?.created_at) {
    return dayjs(lastActivity.created_at).isAfter(dayjs().subtract(90, 'day'))
  }

  return false
}
