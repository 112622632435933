import { SimpleGrid } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { OnboardingCard } from './OnboardingCard'
import intersection from 'lodash/intersection'
import map from 'lodash/map'
import { Goal } from '../../../data/use-onboarding-goals'

interface OnboardingGridProps {
  onboardingFocus: string[]
  completedSteps: string[]
  goals: Goal[]
}

export const OnboardingGrid = (props: OnboardingGridProps) => {
  const isEnabled = useCallback((key: string) => props.onboardingFocus.includes(key), [props.onboardingFocus])

  const completedSteps = useCallback(
    (goal) => intersection(map(goal.steps, 'key'), props.completedSteps),
    [props.completedSteps]
  )

  return (
    <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={6}>
      {props.goals.map((goal) => (
        <OnboardingCard
          key={goal.id}
          goal={goal}
          onAction={() => {}}
          isEnabled={isEnabled(goal.id)}
          completedSteps={completedSteps(goal)}
        />
      ))}
    </SimpleGrid>
  )
}
