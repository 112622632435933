import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Res {
  reveal_enabled: boolean
  enrich_enabled: boolean
}

export function useClearbitKey(key: string) {
  const project = useCurrentProject()

  return useQuery<Res>({
    queryKey: ['clearbit-key', { projectId: project?.id, key }],
    queryFn: () => concurrentGET<Res>(projectPath(`/apps/clearbit/validate?key=${key}`)),
    enabled: Boolean(project?.id && key)
  })
}
