import React from 'react'

import { projectPath } from '../../../ui/ProjectsContext'

import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import PageDescription from '../../../ui/PageDescription'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'

import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconExternalLink } from '@tabler/icons-react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import { CheckboxCircleIcon } from '../../../ui/icons'
import { usePermission } from '../../../ui/PermissionsContext'

interface Props {
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  connected?: boolean
  valid?: boolean
  deps: {
    user_info?: {
      id?: string
      name?: string
      email?: string
      organization?: {
        id?: string
        name?: string
      }
    }
  }
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject, isLoading: isPermissionLoading } = usePermission({
    on: 'project',
    action: 'can_edit'
  })
  const editApiKey = useDisclosure({ defaultIsOpen: !props.valid || !props.connected })

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/fireflies'),
            title: 'Fireflies'
          }
        ]}
        offscreen
      />
      <HStack w="100%">
        <Box w="100%">
          <HStack w="100%">
            <HStack marginRight="auto" alignItems="center" spacing={2}>
              <Image src={props.logo} maxW="6" />
              <PageTitle>{props.title}</PageTitle>
              {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
              {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
            </HStack>
            {props.connected && editApiKey.isOpen && (
              <DisconnectAppDialog appTitle="Fireflies" showRemoveCachesOption={false} />
            )}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>
      </HStack>

      {!canEditProject && !isPermissionLoading && (
        <>
          <Alert fontSize={'sm'} status="warning">
            <AlertIcon />
            <AlertDescription>
              You don't have enough permissions to perform this action. Please request access to an admin in your
              workspace.
            </AlertDescription>
          </Alert>
        </>
      )}

      {editApiKey.isOpen || !props.valid ? (
        <form method="POST">
          <AuthenticityToken />
          <input type="hidden" name="_method" value="PUT" />

          <Stack spacing="4" pt="8">
            <Heading size="sm" fontWeight={'semibold'}>
              🔐 Auth Settings
            </Heading>

            <FormControl id="api_key">
              <FormLabel>API Key</FormLabel>

              <Input
                name="app_instance_settings[api_key]"
                placeholder="Enter your Fireflies API key"
                defaultValue={props.settings['api_key'] ?? ''}
                type="password"
                autoComplete="off"
                size="sm"
              />

              <FormHelperText>
                Your Fireflies API key for authentication. You can find this in your Fireflies account settings.
              </FormHelperText>
            </FormControl>

            <Button size="sm" colorScheme="purple" type="submit" w="100%" isDisabled={!canEditProject}>
              Save
            </Button>
          </Stack>
        </form>
      ) : (
        <LightBgCard gap={6} p={4}>
          <HStack justifyContent="space-between">
            <CheckboxCircleIcon color="green.400" size={18} />
            <Heading flex="1" size="xs" fontWeight="medium">
              Your Fireflies account is connected
            </Heading>
            <Button variant="outline" size="sm" onClick={editApiKey.onOpen}>
              Change key
            </Button>
            <DisconnectAppDialog appTitle="Fireflies" showRemoveCachesOption={false} />
          </HStack>

          {props.deps.user_info && (
            <Box pt={2}>
              <Text fontSize="sm" color="gray.600">
                Connected as: {props.deps.user_info.name || props.deps.user_info.email}
                {props.deps.user_info.organization?.name && (
                  <> • Organization: {props.deps.user_info.organization.name}</>
                )}
              </Text>
            </Box>
          )}
        </LightBgCard>
      )}

      <Stack spacing="4">
        <Link href="https://app.fireflies.ai/settings#DeveloperSettings" isExternal color="purple.500">
          <HStack spacing={1}>
            <Text fontSize="sm">Find your API key</Text>
            <IconExternalLink size={14} />
          </HStack>
        </Link>
      </Stack>
    </PageLayout>
  )
}
