import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconFileSpreadsheet, IconFileText, IconMail } from '@tabler/icons-react'
import { orderBy, uniq } from 'lodash'
import React, { useMemo } from 'react'
import { pluralize } from '../../../../../lib/pluralize'
import { Identify, ProfileRecord } from '../../../../../types/Profile'
import { GrayCard } from '../../../../ui/Card'
import CompanyAvatar from '../../../../ui/CompanyAvatar'
import { HelpTooltip } from '../../../../ui/HelpTooltip'
import { DiscordIcon, GitHubIcon, LinkedinBoxIcon, SlackIcon } from '../../../../ui/icons'
import { Rb2bIcon } from '../../../../ui/icons/Rb2bIcon'
import { TimeAgo } from '../../../../ui/TimeAgo'
import { VirtualList } from '../../../../ui/VirtualList'
import { HField } from '../Field'

export interface EmailSource {
  id: string
  project_id: string
  profile_id: string
  source: string
  email: string
  created_at: string
  updated_at: string
  sent_at: string
  url?: string
  traits?: Record<string, any>
}

interface EmailSourcesCardProps {
  profile: ProfileRecord & { email_sources: EmailSource[] }
  initiallyCollapsed?: boolean
}

function SourceIcon(props: { source?: string }) {
  const source = props.source

  if (source === 'identify') {
    return <CompanyAvatar size="16px" domain={'getkoala.com'} />
  }

  if (source === 'form') {
    return <IconFileText size={16} />
  }

  if (source === 'utm_id' || source === 'ko_email') {
    return <IconMail size={16} />
  }

  if (source?.includes('hubspot')) {
    return <CompanyAvatar size="16px" domain="hubspot.com" />
  }

  if (source?.includes('outreach') || source === 'opl') {
    return <CompanyAvatar size="16px" domain="outreach.io" />
  }

  if (source?.includes('segment')) {
    return <CompanyAvatar size="16px" domain="segment.com" />
  }

  if (source?.includes('rudderstack')) {
    return <CompanyAvatar size="16px" domain="rudderstack.com" />
  }

  if (source?.includes('intercom')) {
    return <CompanyAvatar size="16px" domain="intercom.com" />
  }

  if (source?.includes('qualified')) {
    return <CompanyAvatar size="16px" domain="qualified.com" />
  }

  if (source?.includes('navattic')) {
    return <CompanyAvatar size="16px" domain="navattic.com" />
  }

  if (source?.includes('drift')) {
    return <CompanyAvatar size="16px" domain="drift.com" />
  }

  if (source?.includes('rb2b')) {
    return <Rb2bIcon size="16px" />
  }

  if (source?.includes('linkedin')) {
    return <LinkedinBoxIcon size="16px" />
  }

  if (source?.includes('github')) {
    return <GitHubIcon size="16px" />
  }

  if (source?.includes('slack')) {
    return <SlackIcon size="16px" />
  }

  if (source?.includes('discord')) {
    return <DiscordIcon size="16px" />
  }

  if (source?.includes('csv')) {
    return <IconFileSpreadsheet size="16px" />
  }

  return null
}

export function sourceText(source?: string) {
  let text = ''

  switch (source) {
    case 'identify':
      text = 'Koala Identify'
      break
    case 'form':
      text = 'Form Submission'
      break
    case 'utm_id':
      text = 'UTM ID Link'
      break
    case 'hubspot_form':
      text = 'HubSpot Form'
      break
    case 'hubspot_meeting':
      text = 'HubSpot Meetings'
      break
    case 'hubspot_hsq':
      text = 'HubSpot Tracking'
      break
    case 'hubspot_utk':
      text = 'HubSpot UTK'
      break
    case 'ko_email':
      text = 'Koala UTM'
      break
    case 'segment':
      text = 'Segment'
      break
    case 'rudderstack':
      text = 'Rudderstack'
      break
    case 'rudderstack-cloud':
      text = 'Rudderstack Cloud'
      break
    case 'opl':
      text = 'Outreach Plugin'
      break
    case 'apl':
      text = 'Apollo Email Param'
      break
    case 'sl':
      text = 'SalesLoft Email Param'
      break
    case 'hs':
      text = 'HubSpot Email Param'
      break
    case 'dir':
      text = 'Direct Email Param'
      break
    case 'segment-cloud':
      text = 'Segment Cloud Destination'
      break
    case 'segment-browser':
      text = 'Segment Browser Destination'
      break
    case 'drift':
      text = 'Drift'
      break
    case 'intercom':
      text = 'Intercom'
      break
    case 'qualified':
      text = 'Qualified'
      break
    case 'navattic':
      text = 'Navattic'
      break
    case 'cio':
      text = 'Customer.io'
      break
    case 'linkedin_xt':
      text = 'Chrome Extension'
      break
    case 'slack':
      text = 'Slack'
      break
    case 'discord':
      text = 'Discord'
      break
    case 'github':
      text = 'GitHub'
      break
    case 'rb2b':
      text = 'RB2B'
      break
    case 'manual':
      text = 'Manually identified'
      break
    default:
      text = 'Unknown'
  }

  return text
}

export function hasStrongEmailMatch(emailSources: Identify[]) {
  return emailSources.some((identify) => {
    return ['identify', 'segment', 'rudderstack'].includes(identify.source ?? '')
  })
}

export function bestEmailMatch(emailSources: Identify[], profile: ProfileRecord) {
  return orderBy(
    emailSources.filter((id) => id.traits?.email === profile.email),
    (id) => hasStrongEmailMatch([id]),
    'desc'
  )[0]
}

export function EmailSourcesToggle(props: EmailSourcesCardProps) {
  const { isOpen, onToggle, onClose } = useDisclosure()

  const sortedIdentities = useMemo(
    () => orderBy(props.profile.email_sources, 'sent_at', 'desc'),
    [props.profile.email_sources]
  )

  const uniqueEmails = useMemo(() => uniq(sortedIdentities.map((id) => id.traits?.email)), [sortedIdentities])

  const differentEmails = useMemo(
    () => uniqueEmails.filter((email) => email !== props.profile.email),
    [props.profile.email, uniqueEmails]
  )

  const hasMultipleEmails = useMemo(() => differentEmails.length >= 1, [differentEmails])
  const sameEmailIdentifies = useMemo(
    () =>
      orderBy(
        sortedIdentities.filter((id) => id.traits?.email === props.profile.email),
        'sent_at',
        'desc'
      ),
    [props.profile.email, sortedIdentities]
  )

  const summaryText = useMemo(
    () =>
      hasMultipleEmails
        ? `${differentEmails.length} other ${pluralize(differentEmails.length, 'email', 'emails', false)} detected`
        : `Identified via ${sourceText(bestEmailMatch(sameEmailIdentifies, props.profile)?.source)}`,
    [hasMultipleEmails, differentEmails.length, sameEmailIdentifies, props.profile]
  )

  return (
    <>
      <Button size="xs" variant="link" colorScheme="purple" fontWeight="normal" onClick={onToggle}>
        {summaryText}
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <HStack>
              <Heading size="md" fontWeight="semibold">
                Tracked Identities
              </Heading>
              <HelpTooltip>
                <Stack>
                  <Heading fontWeight="semibold" size="xs" mb={2}>
                    What are Tracked Identities?
                  </Heading>
                  <Text fontSize="xs" color="gray.600">
                    Koala identifies your users by their email address.
                  </Text>
                  <Text fontSize="xs" color="gray.600">
                    An email address can be captured in many ways, such as a manual identify call, a form submission, an
                    email tracking link click, or through an integration with your CRM.
                  </Text>
                  <Text fontSize="xs" color="gray.600">
                    Koala will automatically capture all of these email addresses and associate them with the user's
                    profile.
                  </Text>
                </Stack>
              </HelpTooltip>
            </HStack>
          </DrawerHeader>
          <DrawerBody paddingX={0}>
            <Stack spacing={5}>
              <VirtualList
                maxH={'calc(99vh - 80px)'}
                items={sortedIdentities}
                estimateSize={() => 122}
                renderItem={(identify, _index) => {
                  const traits = (identify.traits as any) ?? {}
                  const url = identify.url ? new URL(identify.url) : null

                  if (url) {
                    url.search = ''
                  }

                  return (
                    <Box mx={6} my={2} w="100%" overflow="hidden">
                      <Stack as={GrayCard} key={identify.id} p={4} minW="100%" overflow="hidden">
                        <Flex fontSize="xs" justifyContent="space-between" gap={4}>
                          <HStack spacing={1} alignItems="center" pb="1">
                            <SourceIcon source={identify.source} />
                            <Text fontWeight="semibold">{sourceText(identify.source)}</Text>
                          </HStack>

                          <Text color="gray.500">
                            <TimeAgo time={identify.sent_at ?? identify.created_at} />
                          </Text>
                        </Flex>

                        <Stack spacing={1}>
                          <HField label="Email" value={traits.email ?? identify.email} />

                          <HField
                            label="URL"
                            value={url?.toString()}
                            flex="none"
                            wrap="nowrap"
                            minW="300px"
                            maxW="100%"
                            isTruncated
                          >
                            <Text title={url?.toString()} flex="1 1 auto" minWidth="100px" isTruncated>
                              {url?.toString()}
                            </Text>
                          </HField>
                        </Stack>
                      </Stack>
                    </Box>
                  )
                }}
              />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
