import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Portal,
  Spinner,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList,
  useCheckboxGroup,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconAlertCircleOff,
  IconArrowRight,
  IconArrowUpRight,
  IconCheck,
  IconCircle,
  IconCircleDashed,
  IconBan,
  IconCircleX,
  IconCopy,
  IconDotsVertical,
  IconExternalLink,
  IconPlayerPlay,
  IconReload,
  IconSettings,
  IconSortDescending,
  IconTrash,
  IconX
} from '@tabler/icons-react'
import { flatten, orderBy } from 'lodash'
import pluralize from 'pluralize'
import React, { FormEventHandler, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useMountedState } from 'react-use'
import { toast } from 'sonner'
import { del, post, put } from '../../../lib/api'
import { formatNumber } from '../../../lib/number-format'
import router from '../../../lib/router'
import { AutoOutboundRecord, AutoOutboundRule } from '../../../types/AutoOutbound'
import { PageMeta } from '../../../types/PageMeta'
import { useRemoveAOBRecords } from '../../data/use-auto-outbound-records'
import { useOutboundSequenceStats } from '../../data/use-outbound-sequence-stats'
import Avatar from '../../ui/Avatar'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { BulkActionBar } from '../../ui/BulkActionBar'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { CompanyBubble } from '../../ui/CompanyBubble'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { HelpTooltip } from '../../ui/HelpTooltip'
import { HoverCard } from '../../ui/HoverCard'
import { KoalaIcon, LinkedinBoxIcon, OutreachIcon } from '../../ui/icons'
import { IntentBolt } from '../../ui/IntentBolt'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import SelectInput from '../../ui/SelectInput'
import { TableFooter } from '../../ui/TableFooter'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { TimeAgo } from '../../ui/TimeAgo'
import { useOverflow } from '../../ui/useOverflow'
import { humanize } from '../accounts/facets/filter-cloud'
import { accountPath } from '../accounts/lib/account-path'
import { Trendline } from '../icps/icp/breakdown'
import { mergeParams } from '../icps/types'
import { SignalType } from '../kql_definitions/components/SignalType'
import { KqlDefinition } from '../kql_definitions/types'
import { automationsPath } from '../notifications/lib/path-helper'
import { profilePath } from '../profiles/lib/path'
import {
  autoOutboundEditPath,
  autoOutboundLogsPath,
  autoOutboundNewFromRulePath,
  autoOutboundPath,
  autoOutboundRulePath
} from './lib/path-helpers'
import truncate from 'lodash/truncate'
interface Props {
  auto_outbound_rule: AutoOutboundRule
  prospects: AutoOutboundRecord[]
  intent_signals: Record<string, KqlDefinition>
  page_meta: PageMeta
  has_available_credits: boolean
}

const statusLabels = {
  staged: 'Pending',
  executed: 'Synced',
  failed: 'Error',
  skipped: 'Skipped'
}

const statusColors = {
  executed: 'green.500',
  failed: 'red.500',
  staged: 'gray.500',
  skipped: 'orange.500'
}

const statuses = [
  { label: 'All', value: undefined },
  { label: 'Synced', value: 'executed' },
  { label: 'Pending', value: 'staged' },
  { label: 'Failed', value: 'failed' },
  { label: 'Skipped', value: 'skipped' }
]

const sortOptions = [
  { label: 'Created Date', value: 'created_at' },
  { label: 'Synced Date', value: 'synced_at' }
]

const rowHover = {
  bg: [undefined, 'gray.50']
}

export default function Show(props: Props) {
  const [prospects, setProspects] = useState<AutoOutboundRecord[]>(props.prospects)
  const [rule, setAutoOutboundRule] = useState<AutoOutboundRule>(props.auto_outbound_rule)
  const stats = useOutboundSequenceStats(props.auto_outbound_rule.id)
  const params = new URLSearchParams(window.location.search)
  const [syncing, setSyncing] = useState({})
  const mounted = useMountedState()

  const checkboxes = useCheckboxGroup()
  const selectedRecords = checkboxes.value as string[]

  const hasAvailableCredits = props.has_available_credits

  useEffect(() => {
    setProspects(props.prospects)
  }, [props.prospects])

  const removeFromList = useCallback(
    (recordId: number | number[]) => {
      const recordIds = (Array.isArray(recordId) ? recordId : [recordId]).map(String)
      setProspects((prev) => prev.filter((record) => !recordIds.includes(String(record.id))))
      checkboxes.setValue((prev) => prev.filter((id) => !recordIds.includes(String(id))))
      router.visit(window.location.toString())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkboxes.setValue]
  )

  const syncProspect = useCallback(
    (recordId: number) => {
      setSyncing((syncing) => ({ ...syncing, [recordId]: true }))

      const record_ids = [recordId]

      post(autoOutboundRulePath(rule.id, '/sync'), { record_ids: record_ids })
        .then((_res) => {
          toast.success(`Success! Synced ${pluralize('prospect', record_ids.length, true)} to your destination.`)

          if (!mounted()) {
            return
          }

          setProspects((prev) => {
            return prev.map((record) => {
              if (record.id === recordId) {
                return {
                  ...record,
                  status: 'executed'
                }
              }
              return record
            })
          })
        })
        .catch((e) => {
          toast.error('Failed to sync', {
            description: truncate(e?.body?.error ?? e?.message, { length: 100 })
          })
        })
        .finally(() => {
          if (mounted()) {
            setSyncing((syncing) => ({ ...syncing, [recordId]: false }))
          }
        })
    },
    [mounted, rule.id]
  )

  const { mutateAsync: removeItems } = useRemoveAOBRecords()

  const { scrollRef, overflowLeft } = useOverflow()

  return (
    <PageLayout size={props.prospects.length > 0 ? 'full' : 'lg'}>
      <Breadcrumb
        paths={[
          { path: automationsPath('/overview'), title: 'Automations' },
          { path: autoOutboundPath(), title: 'Auto Outbound' },
          { path: autoOutboundRulePath(rule.id), title: rule.name }
        ]}
      />
      <PageTitle skipRendering>Auto Outbound - {rule.name}</PageTitle>
      <Flex gap={4} alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Stack spacing={0}>
          <Heading flex="none" size="md">
            {rule.name}
          </Heading>
          <Text fontSize="xs" color="gray.600">
            Updated <TimeAgo time={rule.updated_at} />
          </Text>
        </Stack>
        <HStack spacing="4">
          <HStack fontSize={'sm'}>
            <Text>{rule.enabled ? 'Enabled' : 'Disabled'}</Text>
            <Switch
              defaultChecked={rule.enabled}
              value="1"
              onChange={async (e) => {
                await put(autoOutboundRulePath(rule.id, `/status`), {
                  auto_outbound: {
                    enabled: e.target.checked
                  }
                }).then(() => {
                  setAutoOutboundRule((rule) => {
                    return {
                      ...rule,
                      enabled: e.target.checked
                    }
                  })
                  toast.success(`Auto Outbound Playbook ${e.target.checked ? 'enabled' : 'disabled'}`)
                })
              }}
            />
          </HStack>
          <HStack fontSize={'sm'}>
            <Text>{rule.autopilot ? 'Autopilot' : 'Manual'}</Text>
            <HelpTooltip>
              <Stack fontSize="xs">
                <Heading size="xs">Autopilot vs Manual Mode</Heading>
                <Text>You can choose to run this playbook in Autopilot or Manual mode.</Text>
                <Text>Autopilot will automatically sync eligible prospects with your configured destination.</Text>
                <Text>
                  Manual mode requires a human touch. Eligible prospects will be enrolled in the playbooks, but will not
                  automatically sync with your destination. You'll be given the choice to sync individual prospects from
                  the playbook page.
                </Text>
              </Stack>
            </HelpTooltip>
            <Switch
              defaultChecked={rule.autopilot}
              value="1"
              onChange={async (e) => {
                await put(autoOutboundRulePath(rule.id, `/status`), {
                  auto_outbound: {
                    autopilot: e.target.checked
                  }
                })
                  .then(() => {
                    setAutoOutboundRule((rule) => {
                      return {
                        ...rule,
                        autopilot: e.target.checked
                      }
                    })
                    toast.success(`Autopilot ${e.target.checked ? 'enabled' : 'disabled'}`)
                  })
                  .catch((ee) => {
                    toast.error(`Error: ${ee}`)
                  })
              }}
            />
          </HStack>
          <HStack>
            <Button
              size="sm"
              leftIcon={<IconSettings size={15} />}
              as={Link}
              href={autoOutboundEditPath(rule.id)}
              variant="outline"
            >
              Edit Playbook
            </Button>
            <Menu size="sm" autoSelect={false} placement="bottom-end">
              <MenuButton
                as={IconButton}
                aria-label="Actions"
                icon={<IconDotsVertical size={16} />}
                variant="ghost"
                size="xs"
              />
              <MenuList zIndex="popover">
                <MenuItem as="a" icon={<IconCopy size={16} />} href={autoOutboundNewFromRulePath(rule.id)}>
                  Clone
                </MenuItem>
                <MenuItem
                  icon={<IconTrash size={16} />}
                  onClick={(e) => {
                    e.preventDefault()

                    if (confirm('Are you sure?')) {
                      del(autoOutboundRulePath(rule.id)).then(() => {
                        router.visit(autoOutboundPath())
                      })
                    }
                  }}
                >
                  Delete Playbook
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
      </Flex>
      <Card p={0}>
        <Grid width="100%" templateColumns={['repeat(1, 1fr)', 'repeat(auto-fit, minmax(120px, 1fr))']} gap={2}>
          <Stat label="Prospects" value={rule.prospect_stats?.matched} trend={rule.timeseries_stats?.total} />
          <Stat label="Pending" value={rule.prospect_stats?.eligible} trend={rule.timeseries_stats?.staged} />
          <Stat label="Synced" value={rule.prospect_stats?.executed} trend={rule.timeseries_stats?.executed} />
          <Stat label="Failed" value={rule.prospect_stats?.failed} trend={rule.timeseries_stats?.failed} />
        </Grid>
      </Card>
      {stats.isLoading ? (
        <Box paddingY={3}>
          <Spinner size="sm" color="gray.400" thickness="1px" />
        </Box>
      ) : stats.data?.outreach_sequence_stats && stats.data.outreach_sequence_stats.length > 0 ? (
        <Card p={0}>
          <Stack spacing={1} paddingX={4} paddingY={4} borderBottom="1px solid" borderBottomColor="gray.200">
            <Heading size="sm" fontWeight="semibold">
              Connected Sequences ({stats.data.outreach_sequence_stats.length})
            </Heading>
            <Text fontSize="sm" color="gray.500">
              View stats pulled from your connected Outreach Sequences
            </Text>
          </Stack>

          <TableContainer paddingTop={2}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Sequence</Th>
                  <Th isNumeric>Prospects</Th>
                  <Th isNumeric>Delivered</Th>
                  <Th isNumeric>Opened</Th>
                  <Th isNumeric>Clicked</Th>
                  <Th isNumeric>Replied</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {stats.data.outreach_sequence_stats.map((sequence) => (
                  <Tr key={sequence.id}>
                    <Td>
                      <HStack py={2}>
                        <OutreachIcon color="outreach" size={20} />
                        <Link href={sequence.permalink} target="_blank">
                          {sequence.name} (#{sequence.id})
                        </Link>
                      </HStack>
                    </Td>
                    <Td isNumeric>{sequence.stats?.prospects ? formatNumber(sequence.stats.prospects) : '-'}</Td>
                    <Td isNumeric>{sequence.stats?.delivered ? formatNumber(sequence.stats.delivered) : '-'}</Td>
                    <Td isNumeric>{sequence.stats?.opened ? formatNumber(sequence.stats.opened) : '-'}</Td>
                    <Td isNumeric>{sequence.stats?.clicked ? formatNumber(sequence.stats.clicked) : '-'}</Td>
                    <Td isNumeric>{sequence.stats?.replied ? formatNumber(sequence.stats.replied) : '-'}</Td>
                    <Td w="1px" isNumeric pl={10}>
                      <Button
                        variant="outline"
                        size="xs"
                        as={Link}
                        href={sequence.permalink}
                        target="_blank"
                        iconSpacing={1}
                        rightIcon={<IconExternalLink size={14} />}
                      >
                        View Externally
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      ) : null}
      <Card p={0}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={6}
          paddingX={4}
          paddingY={4}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
        >
          <Stack spacing={1}>
            <Heading size="sm" fontWeight="semibold">
              Enrolled Prospects ({(rule.prospect_stats?.total ?? 0).toLocaleString()})
            </Heading>
            <Text fontSize="sm" color="gray.500">
              Prospects that have been matched and are eligible to be synced to your outbound destination based on the
              configuration of this Auto Outbound Playbook.
            </Text>
          </Stack>

          <Button
            as={Link}
            rightIcon={<IconArrowUpRight size={16} />}
            iconSpacing={1}
            flex="none"
            size="sm"
            variant="outline"
            href={autoOutboundLogsPath({ rule_id: rule.id })}
            isExternal
          >
            View logs
          </Button>
        </Flex>

        <Flex
          alignItems="center"
          gap={4}
          justifyContent="space-between"
          paddingX={4}
          paddingY={2.5}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
        >
          <SelectInput
            variant="outline"
            size="sm"
            triggerProps={{ width: 'auto' }}
            items={statuses}
            selectedItem={statuses.find((s) => s.value === (params.get('status') || undefined)) || null}
            onSelectedItemChange={({ selectedItem }) => {
              const status = selectedItem?.value
              const url = mergeParams(window.location.toString(), { status, page: '1' })
              router.visit(url)
            }}
            itemRenderer={(item) => (
              <HStack spacing={2}>
                <StatusIcon status={item.value} />
                <Text color="gray.600" fontWeight="medium" fontSize="sm">
                  {item.label}
                </Text>
              </HStack>
            )}
            itemToString={(item) => item?.label ?? item?.value ?? ''}
          />
          <SelectInput
            variant="outline"
            size="sm"
            triggerProps={{ width: 'auto' }}
            items={sortOptions}
            selectedItem={sortOptions.find((s) => s.value === (params.get('sort_by') || undefined)) || null}
            onSelectedItemChange={({ selectedItem }) => {
              const sort_by = selectedItem?.value
              const url = mergeParams(window.location.toString(), { sort_by, page: '1' })
              router.visit(url)
            }}
            itemRenderer={(item) => (
              <HStack spacing={2}>
                <Icon as={IconSortDescending} boxSize={4} />
                <Text color="gray.600" fontWeight="medium" fontSize="sm">
                  {item.label}
                </Text>
              </HStack>
            )}
            itemToString={(item) => item?.label ?? item?.value ?? ''}
          />
        </Flex>

        {prospects.length > 0 ? (
          <>
            <TableContainer
              ref={scrollRef}
              position="relative"
              className={overflowLeft ? 'scrolled' : undefined}
              width="100%"
              mt="-1px"
            >
              <Table variant="bordered" size="sm" width="100%" height="1px">
                <Thead>
                  <Tr>
                    <Th className="sticky-column">
                      <Flex alignItems="center" gap={2}>
                        <Checkbox
                          size="md"
                          marginRight={1}
                          isChecked={selectedRecords.length === prospects.length}
                          isIndeterminate={selectedRecords.length > 0 && selectedRecords.length !== prospects.length}
                          onChange={() => {
                            checkboxes.setValue((prev) =>
                              prev.length === prospects.length ? [] : prospects.map((p) => p.id)
                            )
                          }}
                        />
                        <Text>Name</Text>
                      </Flex>
                    </Th>
                    <Th>Status</Th>
                    <Th>Source</Th>
                    <Th>Prospect Geo</Th>
                    <Th>Company</Th>
                    <Th>Company HQ</Th>
                    <Th>Industry</Th>
                    <Th>Signal</Th>
                    <Th>Added</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {prospects.map((prospect) => {
                    const profile = prospect.profile || prospect.merged_profile
                    const displayName = profile?.display_name ?? profile?.email
                    const signals = flatten(prospect.context?.rules?.intent?.info?.signals ?? [])
                    const latest: any = orderBy(signals, ['triggered_at'], ['desc'])?.[0]
                    const errors = prospect.context?.errors || []
                    const autoProspected =
                      prospect.context['auto_prospecting.profile_id'] || prospect.profile_type === 'ProspectedProfile'

                    const ineligibile = !prospect.still_eligible

                    return (
                      <Tr key={prospect.id} _hover={rowHover} opacity={ineligibile ? 0.9 : 1}>
                        <Td className="sticky-column" width="1px">
                          <Flex alignItems="center" minW="340px" maxW="400px" gap={2}>
                            <Checkbox
                              size="md"
                              marginRight={1}
                              {...checkboxes.getCheckboxProps({ value: prospect.id })}
                            />

                            <Box py={1.5}>
                              <Avatar size="sm" name={displayName} src={prospect.profile?.avatar} />
                            </Box>

                            <Stack spacing={0.5} flex="1" alignItems="flex-start" w="100%" isTruncated>
                              <HStack spacing="1.5" alignItems={'center'} role="group" w="100%">
                                <Link
                                  fontSize="sm"
                                  fontWeight="medium"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  maxWidth="100%"
                                  href={
                                    autoProspected && prospect.profile?.linkedin_url
                                      ? (`https://${prospect.profile.linkedin_url.replace(/https?:\/\//, '')}` ?? '')
                                      : profilePath({
                                          id: prospect.profile?.id || prospect.profile_id,
                                          email: prospect.profile?.email
                                        })
                                  }
                                >
                                  {displayName ?? prospect.context?.email ?? (
                                    <Text fontSize="sm" color="gray.500">
                                      Anonymous
                                    </Text>
                                  )}
                                </Link>

                                <Flex alignItems="center" gap={1}>
                                  {prospect.profile?.linkedin_url && (
                                    <Tooltip
                                      label={`https://${prospect.profile.linkedin_url.replace(/https?:\/\//, '')}`}
                                    >
                                      <Link
                                        display="flex"
                                        flex="none"
                                        alignItems="center"
                                        color="linkedin.700"
                                        isExternal
                                        href={`https://${prospect.profile.linkedin_url.replace(/https?:\/\//, '')}`}
                                      >
                                        <LinkedinBoxIcon boxSize="18px" />
                                      </Link>
                                    </Tooltip>
                                  )}

                                  {prospect.profile && !autoProspected && (
                                    <Tooltip label="View activity in Koala">
                                      <Link
                                        href={profilePath({
                                          id: prospect.profile?.id || prospect.profile_id,
                                          email: prospect.profile?.email
                                        })}
                                        isExternal
                                      >
                                        <KoalaIcon color="purple.500" boxSize="18px" />
                                      </Link>
                                    </Tooltip>
                                  )}
                                </Flex>
                              </HStack>

                              {prospect.profile?.title ? (
                                <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
                                  {prospect.profile.title}
                                </TextEllipsis>
                              ) : null}
                            </Stack>

                            <Flex gap={2} alignItems="center" ml="4">
                              {ineligibile ? (
                                <HelpTooltip
                                  trigger={
                                    <Button
                                      colorScheme={'red'}
                                      size="sm"
                                      variant="ghost"
                                      leftIcon={<IconAlertCircleOff size="14" />}
                                    >
                                      Ineligible
                                    </Button>
                                  }
                                >
                                  <Stack minH="80px" spacing="4" maxW="300px">
                                    <Heading size="xs" fontWeight="semibold" color="gray.600">
                                      Why is this prospect ineligible?
                                    </Heading>

                                    <UnorderedList pl={4}>
                                      {prospect.eligibility_context_rules
                                        ?.filter((rule) => !rule.eligible)
                                        ?.map((rule) => {
                                          const info =
                                            typeof rule.info === 'string' ? rule.info : JSON.stringify(rule.info)

                                          return (
                                            <ListItem key={rule.key} fontSize="xs" color="gray.500">
                                              <Text fontWeight="semibold">{humanize(rule.key)}:</Text>
                                              {rule.eligible
                                                ? null
                                                : (info ?? '')
                                                    .toString()
                                                    .replace("didn't match", 'no longer matches')
                                                    .replace('does not match', 'no longer matches')}
                                            </ListItem>
                                          )
                                        })}
                                    </UnorderedList>

                                    <Button
                                      size="sm"
                                      colorScheme="gray"
                                      leftIcon={<IconX size="14" />}
                                      onClick={async () => {
                                        try {
                                          await removeItems({ recordIds: [prospect.id], ruleId: prospect.rule_id })
                                          removeFromList(prospect.id)
                                          toast(`Successfully removed ${displayName}!`)
                                        } catch (err) {
                                          toast(`There was an issue removing ${displayName} from this list!`)
                                        }
                                      }}
                                    >
                                      Remove from list
                                    </Button>
                                  </Stack>
                                </HelpTooltip>
                              ) : (
                                <>
                                  {prospect.status === 'staged' && (
                                    <Button
                                      size="sm"
                                      colorScheme="gray"
                                      isDisabled={prospect.executed}
                                      isLoading={syncing[prospect.id]}
                                      leftIcon={<IconPlayerPlay size="14" />}
                                      onClick={() => syncProspect(prospect.id)}
                                    >
                                      Sync
                                    </Button>
                                  )}
                                  {prospect.status === 'skipped' && hasAvailableCredits && (
                                    <Button
                                      size="sm"
                                      colorScheme="gray"
                                      isDisabled={prospect.executed}
                                      isLoading={syncing[prospect.id]}
                                      leftIcon={<IconPlayerPlay size="14" />}
                                      onClick={() => syncProspect(prospect.id)}
                                    >
                                      Sync
                                    </Button>
                                  )}
                                  {prospect.status === 'failed' && (
                                    <Button
                                      size="sm"
                                      colorScheme="gray"
                                      isDisabled={prospect.executed}
                                      isLoading={syncing[prospect.id]}
                                      leftIcon={<IconReload size="14" />}
                                      onClick={() => syncProspect(prospect.id)}
                                    >
                                      Retry
                                    </Button>
                                  )}
                                </>
                              )}

                              <OverflowMenu record={prospect} onRemoved={removeFromList} isProspect={autoProspected} />
                            </Flex>
                          </Flex>
                        </Td>
                        <Td width="1px">
                          <Box>
                            <ErrorHoverCard errors={errors}>
                              <HStack display="inline-flex" spacing={1} color={statusColors[prospect.status]}>
                                <StatusIcon status={prospect.status} />
                                <Text fontSize="sm" fontWeight="medium">
                                  {statusLabels[prospect.status]}
                                </Text>
                              </HStack>
                            </ErrorHoverCard>
                          </Box>
                        </Td>
                        <Td>
                          <Stack>
                            <HStack>
                              {autoProspected ? (
                                <Badge variant="regular" colorScheme={'blue'}>
                                  Persona match
                                </Badge>
                              ) : (
                                <Badge variant="regular" colorScheme={'purple'}>
                                  Direct match
                                </Badge>
                              )}
                            </HStack>
                            {autoProspected && (
                              <>
                                <Text
                                  fontSize={'xs'}
                                  style={{
                                    whiteSpace: 'pre-wrap'
                                  }}
                                  color="gray.500"
                                >
                                  {prospect.context['auto_prospecting.reason']}
                                </Text>
                              </>
                            )}
                          </Stack>
                        </Td>
                        <Td>
                          <Text fontSize="sm" color="gray.500">
                            {profile?.simple_location}
                          </Text>
                        </Td>
                        <Td width="1px" minW="200px" maxW="200px">
                          {profile?.company && (
                            <Box width="100%">
                              <CompanyBubble
                                name={profile.company.name}
                                domain={profile.company.domain}
                                href={accountPath({ company: profile.company })}
                              />
                            </Box>
                          )}
                        </Td>
                        <Td>
                          <Text fontSize="sm" color="gray.500">
                            {profile?.company?.simple_location}
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="sm" color="gray.500">
                            {profile?.company?.industry}
                          </Text>
                        </Td>
                        <Td width="1px">
                          {latest && (
                            <HStack lineHeight="20px" spacing={1.5}>
                              <SignalType
                                label={latest.kql_definition_name}
                                signalType={props.intent_signals[latest.kql_definition_id]?.signal_type}
                                marginRight={1}
                                compact
                                isTruncated
                                fontSize="sm"
                                fontWeight="normal"
                                flexShrink={1}
                                minW="60px"
                              />
                              <IntentBolt count={signals.length} variant="subtle" />
                            </HStack>
                          )}
                        </Td>
                        <Td width="1px">
                          <TimeAgo time={prospect.created_at} mode="relative" canToggle={false} />
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Box px={4}>
              <TableFooter
                pageMeta={props.page_meta}
                page={props.page_meta.current_page || 1}
                prevPath={mergeParams(window.location.toString(), {
                  page: props.page_meta.prev_page?.toString() ?? '1'
                })}
                nextPath={mergeParams(window.location.toString(), {
                  page: props.page_meta.next_page?.toString() ?? '1'
                })}
                scrollToTop={false}
              />
            </Box>
          </>
        ) : rule.prospect_stats?.total ? (
          <Center
            display="flex"
            flexWrap="wrap"
            gap={1}
            maxWidth="80%"
            margin="auto"
            fontSize="sm"
            color="gray.500"
            px={4}
            py={10}
          >
            <Text textAlign="center">
              No prospects match your filter. Try filtering by another status or select "All" to see everything.
            </Text>
            <Button
              size="sm"
              variant="link"
              colorScheme="purple"
              onClick={() => {
                const url = mergeParams(window.location.toString(), { status: undefined, page: '1' })
                router.visit(url)
              }}
            >
              Reset filters
            </Button>
          </Center>
        ) : (
          <Center fontSize="sm" color="gray.500" px={4} py={10}>
            No prospects enrolled in this Auto Outbound list yet. They'll show up here when once some become eligible
            based on your rules.
          </Center>
        )}
      </Card>
      <BulkActionBar selectionCount={selectedRecords.length ?? 0} onRemoveSelection={() => checkboxes.setValue([])}>
        <RemoveFromPlaybook selectedRecords={selectedRecords} ruleId={rule.id} onRemoved={removeFromList} />
      </BulkActionBar>
    </PageLayout>
  )
}

function Stat(props: { label: string; value?: number | null; trend?: Array<{ day: string; value: number }> }) {
  return (
    <Flex flex="1 1 0%" gap={2} direction="column" justifyContent="space-between" px={3} py={3}>
      <Stack spacing={1.5}>
        <Heading size="xs" fontWeight="normal" color="gray.600">
          {props.label}
        </Heading>
        <Text fontSize="xl" fontWeight="semibold" lineHeight={1}>
          {(props.value || 0).toLocaleString()}
        </Text>
        <Trendline
          color="purple"
          range="month"
          trend={{
            trends: props.trend || []
          }}
          height={24}
          svgHeight={24}
        />
      </Stack>
    </Flex>
  )
}

function StatusIcon(props: { status: string }) {
  switch (props.status) {
    case 'executed':
      return <CircleIcon icon={IconCheck} iconSize={3.5} size={5} colorScheme="green" />
    case 'failed':
      return <CircleIcon icon={IconX} iconSize={3.5} size={5} colorScheme="red" />
    case 'staged':
      return <Icon as={IconCircleDashed} boxSize={5} color="gray.300" />
    case 'skipped':
      return <Icon as={IconBan} boxSize={5} color="orange.400" />
    default:
      return <Icon as={IconCircle} boxSize={5} color="gray.400" />
  }
}

interface OverflowMenuProps {
  record: AutoOutboundRecord
  isProspect?: boolean
  onRemoved: (id: number) => void
}

function OverflowMenu({ record, isProspect, onRemoved }: OverflowMenuProps) {
  const disclosure = useDisclosure()
  const deleteDisclosure = useDisclosure()
  const profile = record.profile ?? record.merged_profile
  const displayName = profile?.display_name ?? profile?.email

  const handleRemoval: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()

      const removeEnrollmentPath = autoOutboundRulePath(record.rule_id, `/members/${record.id}`)
      try {
        await del(removeEnrollmentPath)
        onRemoved(record.id)
        toast(`Successfully removed ${displayName}!`)
      } catch (err) {
        toast(`There was an issue removing ${displayName} from this list!`)
      }
    },
    [record, displayName, onRemoved]
  )

  return (
    <>
      <Menu {...disclosure} autoSelect={false}>
        <MenuButton as={IconButton} size="xs" icon={<IconDotsVertical size={16} />} variant="ghost" />
        <Portal>
          <MenuList zIndex="popover">
            {!isProspect && (
              <>
                <MenuItem
                  as="a"
                  icon={<IconArrowRight size={16} />}
                  href={profilePath({
                    id: record.profile?.id || record.profile_id,
                    email: record.profile?.email
                  })}
                >
                  View full profile
                </MenuItem>
                <MenuDivider />
              </>
            )}
            <Tooltip label={record.status === 'executed' ? 'Cannot remove synced prospects' : undefined}>
              <Box>
                <MenuItem
                  isDisabled={record.status === 'executed'}
                  color="red.500"
                  icon={<IconX size={16} />}
                  onClick={deleteDisclosure.onOpen}
                >
                  Remove from list
                </MenuItem>
              </Box>
            </Tooltip>
          </MenuList>
        </Portal>
      </Menu>

      <DeleteConfirmation
        title={`Remove ${displayName}?`}
        confirmLabel="Yes, continue"
        onConfirm={handleRemoval}
        isCentered
        {...deleteDisclosure}
      >
        Are you sure you want to remove {displayName} from this Auto Outbound list?
      </DeleteConfirmation>
    </>
  )
}

function ErrorHoverCard(props: PropsWithChildren<{ errors?: string[] }>): JSX.Element {
  if (props.errors?.length) {
    return (
      <HoverCard
        trigger="hover"
        isPortal
        hoverContent={
          <Stack maxW="380px" overflow="hidden" alignItems="stretch" lineHeight="20px" spacing={1}>
            {props.errors.map((error, index) => (
              <Box key={error + index} maxW="100%">
                <Text fontSize="sm" whiteSpace="normal">
                  {error}
                </Text>
              </Box>
            ))}
          </Stack>
        }
      >
        {props.children}
      </HoverCard>
    )
  }

  return props.children as JSX.Element
}

interface RemoveFromPlaybookProps {
  ruleId: string
  selectedRecords: string[]
  onRemoved?: (ids: number[]) => void
}

function RemoveFromPlaybook({ ruleId, selectedRecords, onRemoved }: RemoveFromPlaybookProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const formId = 'remove-from-aob-form'

  const { isPending: isLoading, mutateAsync: removeItems } = useRemoveAOBRecords()

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      try {
        const res = await removeItems({ ruleId, recordIds: selectedRecords })
        onRemoved?.(res.removed_record_ids)
        toast.success('Removed prospects from list')
        onClose()
      } catch (error: any) {
        toast.error('Failed to remove prospects from this list', { description: error?.message })
        console.error(error)
      }
    },
    [removeItems, onClose, ruleId, selectedRecords, onRemoved]
  )

  return (
    <>
      <Button
        size="sm"
        variant="outline"
        leftIcon={<IconCircleX size={16} />}
        iconSpacing={1.5}
        onClick={onOpen}
        isLoading={isLoading}
      >
        Remove from list
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <ModalBody paddingTop={6}>
            <form id={formId} onSubmit={onSubmit}>
              <Stack spacing={4}>
                <Heading size="sm" fontWeight="semibold">
                  Remove {selectedRecords.length.toLocaleString()} {pluralize('records', selectedRecords.length)}
                </Heading>

                <Text fontSize="sm" color="gray.600">
                  This will remove these prospects from the list. Note: Prospects that were already synced will not be
                  removed.
                </Text>
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button type="button" variant="outline" size="sm" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button type="submit" form={formId} size="sm" colorScheme="red" isLoading={isLoading}>
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
