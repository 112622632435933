import { useQuery } from '@tanstack/react-query'
import { concurrentCachedGET } from '../../lib/api'
import { PlayItem } from '../../types/Play'
import { projectPath } from '../ui/ProjectsContext'

export type AccountPlayProspectResponse = {
  play_items: PlayItem[]
}

export function useAccountPlayProspects(item: PlayItem) {
  const path = projectPath(`/play-items/${item.id}/account-play-prospects`)

  return useQuery<AccountPlayProspectResponse>({
    queryKey: ['account-play-prospects', { itemId: item.id }],
    queryFn: () => concurrentCachedGET<AccountPlayProspectResponse>(path),
    enabled: Boolean(item.id)
  })
}
