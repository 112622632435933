import { Text } from '@chakra-ui/react'
import { keyframes } from '@emotion/react'
import React from 'react'

const pulseKeyframes = keyframes`
  0% { background-position: 100% 50% }
  50% { background-position: 0% 50% }
  100% { background-position: -100% 50% }
`

export interface PulsingTextProps {
  children: React.ReactNode
}

export const PulsingText = ({ children }: PulsingTextProps) => (
  <Text
    as="span"
    background="linear-gradient(90deg, var(--chakra-colors-gray-400) 0%, var(--chakra-colors-gray-700) 20%, var(--chakra-colors-gray-400) 40%, var(--chakra-colors-gray-400) 100%)"
    backgroundSize="300% 100%"
    animation={`${pulseKeyframes} 3s ease infinite`}
    backgroundClip="text"
    sx={{
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      display: 'inline-block'
    }}
  >
    {children}
  </Text>
)
