import { useQuery } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'
import { useActionCableChannel } from '../../channels/use-channel'
import { post, staticCachedGET } from '../../lib/api'
import { RecommendedPlay } from '../pages/plays/components/EmailButton'
import { EmailProps } from '../ui/EmailComposer'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export type AIRecommendationType =
  | 'email'
  | 'session'
  | 'business'
  | 'talking_points'
  | 'plays'
  | 'profile_session'
  | 'account_session'

export type EmailRecommendations = {
  emails: EmailProps[]
  strategy: string
  recommended_play: string
  activity: string
  research_data: Array<{
    question: string
    short_answer: string
  }>
}

export type SessionRecommendation = {
  summary: string
  intent_signals: string[]
  topics: {
    topic: string
    bullet_points: string[]
  }[]
  users: {
    id: string
    name: string
    title: string
    summary: string
  }[]
}

export function usePlayItemAIRecommendations(
  itemId: string,
  {
    recommendationType,
    runId,
    feedback
  }: { recommendationType: AIRecommendationType; runId?: string; feedback?: string[] },
  { enabled = true }: { enabled?: boolean } = {}
) {
  const project = useCurrentProject()
  let basePath = `/play-items/${itemId}/ai-recommendations.json`

  if (recommendationType) {
    basePath += `?recommendation_type=${recommendationType}`

    if (runId) {
      basePath += `&run_id=${runId}`
    }

    if (feedback) {
      feedback.forEach((f) => {
        basePath += `&feedback[]=${f}`
      })
    }
  }

  const path = projectPath(basePath)

  return useQuery<{
    recommendations: {
      email?: EmailRecommendations
      profile_report?: SessionRecommendation
      account_report?: SessionRecommendation
    }
  }>({
    queryKey: ['play-item-ai-recommendations', { projectId: project?.id, itemId, recommendationType, runId, feedback }],
    queryFn: () => staticCachedGET(path),
    enabled
  })
}

export type Message = {
  action: 'content' | 'tool' | 'activity'
  content: string
  content_type?: string
}

export type ToolUsage = {
  action: 'tool'
  tool_name: string
  tool_args: string
  content: string
}

export type Activity = {
  action: 'activity'
  content: string
}

export function usePlayItemAIRecommendationsStream(
  runId: string,
  {
    onContent,
    onTool,
    onActivity,
    onConnected
  }: {
    onContent: (message: Message) => void
    onTool: (message: ToolUsage) => void
    onActivity?: (message: Activity) => void
    onConnected?: () => void
  },
  messageList: Set<string> | undefined = undefined
) {
  const messagesSet = React.useRef(messageList || new Set<string>())

  return useActionCableChannel(
    'AiAgentRunChannel',
    { run_id: runId },
    (message: Message | ToolUsage) => {
      const messageContent =
        message.action === 'content'
          ? message.content
          : message.action === 'tool'
            ? `${(message as ToolUsage).tool_name}:${(message as ToolUsage).tool_args}`
            : message.action === 'activity'
              ? message.content
              : null

      if (!messageContent || messagesSet.current.has(messageContent)) {
        return
      }

      messagesSet.current.add(messageContent)

      if (message.action === 'content') {
        onContent(message as Message)
      } else if (message.action === 'tool') {
        onTool(message as ToolUsage)
      } else if (message.action === 'activity') {
        onActivity?.(message as Activity)
      }
    },
    onConnected
  )
}

export const useEmailRecommendations = (
  itemId: string,
  { runId, feedback }: { runId: string; feedback?: string[] },
  { enabled = true }: { enabled?: boolean } = {}
) => {
  const {
    data: cachedSuggestedEmails,
    refetch: refetchSuggestedEmails,
    isLoading
  } = usePlayItemAIRecommendations(
    itemId,
    {
      recommendationType: 'email',
      runId,
      feedback
    },
    {
      enabled
    }
  )

  const [contentStream, setContentStream] = useState('')
  const [toolStream, setToolStream] = useState<ToolUsage[]>([])
  const [recommendedPlayFromStream, setRecommendedPlayFromStream] = useState<RecommendedPlay>()
  const [activityStream, setActivityStream] = useState<string>('')

  const socket = usePlayItemAIRecommendationsStream(runId, {
    onContent: (msg: { content: string; content_type?: string }) => {
      if (msg.content_type != null) return
      setContentStream((ct) => {
        const newContent = `${ct}${msg.content}`

        // Extract recommended play if we haven't yet
        if (!recommendedPlayFromStream) {
          const playMatch = newContent.match(/<recommended_play>([\s\S]*?)<\/recommended_play>/)
          if (playMatch?.[1]) {
            const playContent = playMatch[1]
            setRecommendedPlayFromStream({
              play: playContent.match(/<play>(.*?)<\/play>/m)?.[1]?.trim(),
              name: playContent.match(/<name>(.*?)<\/name>/m)?.[1]?.trim(),
              reasoning:
                playContent
                  .match(/<reasoning>[\s\S]*?-\s*(.*?)(?:\n|$)/gm)
                  ?.map((r) => r.replace(/.*?-\s*/, '').trim()) || []
            })
          }
        }

        return newContent
      })
    },
    onTool: (msg: ToolUsage) => {
      setToolStream((ts) => [...ts, msg])
    },
    onActivity: (msg: Activity) => {
      setActivityStream((as) => `${as}${msg.content}`)
    }
  })

  const strategyFromStream = useMemo(() => {
    if (cachedSuggestedEmails?.recommendations?.email?.strategy) {
      return cachedSuggestedEmails.recommendations.email.strategy
    }

    // Extract strategy from stream
    const strategyMatch = contentStream.match(/<email_strategy>([\s\S]*?)<\/email_strategy>/)
    if (strategyMatch?.[1]) {
      return strategyMatch[1].trim()
    }

    // If no complete strategy tag found, look for partial strategy
    const partialStrategyMatch = contentStream.match(/<email_strategy>([\s\S]*?)$/)
    if (partialStrategyMatch?.[1]) {
      return partialStrategyMatch[1].trim()
    }

    return undefined
  }, [contentStream, cachedSuggestedEmails])

  const emailsFromStream = useMemo(() => {
    const emails: Array<{
      to?: string
      subject?: string
      body?: string
      isComplete?: boolean
    }> = []

    // Extract complete emails
    const completeEmailMatches = contentStream.matchAll(/<email>([\s\S]*?)<\/email>/g)
    for (const match of completeEmailMatches) {
      const emailContent = match[1]
      const email: Record<string, string | boolean> = { isComplete: true }

      const toMatch = emailContent.match(/<to>(.*?)<\/to>/m)
      const subjectMatch = emailContent.match(/<subject>(.*?)<\/subject>/m)
      const bodyMatch = emailContent.match(/<body>([\s\S]*?)<\/body>/m)

      if (toMatch?.[1]) email.to = toMatch[1].trim()
      if (subjectMatch?.[1]) email.subject = subjectMatch[1].trim()
      if (bodyMatch?.[1]) {
        email.body = bodyMatch[1]
          .trim()
          .replace(/\n+/g, '\n\n')
          .replace(/\n{3,}/g, '\n\n')
      }

      if (Object.keys(email).length > 1) {
        emails.push(email)
      }
    }

    // Extract partial email being written
    const lastEmailStartIndex = contentStream.lastIndexOf('<email>')
    if (lastEmailStartIndex !== -1 && !contentStream.slice(lastEmailStartIndex).includes('</email>')) {
      const partialContent = contentStream.slice(lastEmailStartIndex)
      const email: Record<string, string | boolean> = { isComplete: false }

      const toMatch = partialContent.match(/<to>(.*?)(?:<\/to>|$)/s)
      const subjectMatch = partialContent.match(/<subject>(.*?)(?:<\/subject>|$)/s)
      const bodyStartMatch = partialContent.match(/<body>(.*?)$/s)

      if (toMatch?.[1]) email.to = toMatch[1].trim()
      if (subjectMatch?.[1]) email.subject = subjectMatch[1].trim()
      if (bodyStartMatch?.[1]) {
        email.body = bodyStartMatch[1]
          .trim()
          .replace(/\n+/g, '\n\n')
          .replace(/\n{3,}/g, '\n\n')
      }

      if (Object.keys(email).length > 1) {
        emails.push(email)
      }
    }

    return {
      emails,
      isComplete: contentStream.includes('</emails>')
    }
  }, [contentStream])

  const suggestedEmails = useMemo(() => {
    if (
      cachedSuggestedEmails?.recommendations?.email?.emails &&
      cachedSuggestedEmails.recommendations.email.emails.length > 0
    ) {
      return cachedSuggestedEmails.recommendations.email.emails
    }

    if (emailsFromStream.emails.length > 0) {
      return emailsFromStream.emails
    }

    return []
  }, [cachedSuggestedEmails, emailsFromStream])

  const activity = useMemo(() => {
    if (cachedSuggestedEmails?.recommendations?.email?.activity) {
      return cachedSuggestedEmails.recommendations.email.activity
    }

    return activityStream
  }, [cachedSuggestedEmails, activityStream])

  const researchResults = useMemo(() => {
    if (cachedSuggestedEmails?.recommendations?.email?.research_data) {
      return cachedSuggestedEmails.recommendations.email.research_data
    }

    return []
  }, [cachedSuggestedEmails])

  const recommendedPlay = useMemo(() => {
    if (cachedSuggestedEmails?.recommendations?.email?.recommended_play) {
      return cachedSuggestedEmails.recommendations.email.recommended_play as RecommendedPlay
    }

    return recommendedPlayFromStream
  }, [cachedSuggestedEmails, recommendedPlayFromStream])

  const playTactic = useMemo(() => {
    const playTacticMatch = strategyFromStream?.match(/Play Tactic\n([\s\S]*?)(?=\n\n## Email Approach|$)/i)
    return playTacticMatch?.[1]?.trim()
  }, [strategyFromStream])

  return {
    emailStrategy: strategyFromStream,
    recommendedPlay,
    suggestedEmails,
    toolStream,
    mostRecentTool: toolStream[toolStream.length - 1],
    contentStream,
    isLoading,
    activity,
    researchResults,
    playTactic,
    refetch: () => {
      refetchSuggestedEmails()
    },
    reset: () => {
      socket?.unsubscribe()
      setContentStream('')
      setToolStream([])
      setRecommendedPlayFromStream(undefined)
      setActivityStream('')
    }
  }
}

export function sendComposerFeedback(
  itemId: string,
  feedback: string,
  feedbackType: string,
  context: Record<string, any>
) {
  const path = projectPath(`/play-items/${itemId}/ai-recommendations/feedback`)

  return post(path, {
    ai_feedback: {
      feedback_contents: feedback,
      feedback_type: feedbackType,
      context
    }
  })
}

export const extractKeyInsightsBulletPoints = (emailStrategy: string | undefined): string[] => {
  if (!emailStrategy) return []

  // Find the Key Insights section
  const keyInsightsRegex = /## Key Insights:?\s*([\s\S]*?)(?:##|$)/i
  const keyInsightsMatch = emailStrategy.match(keyInsightsRegex)

  if (!keyInsightsMatch || !keyInsightsMatch[1]) return []

  // Extract bullet points from the Key Insights section
  const keyInsightsText = keyInsightsMatch[1].trim()
  const bulletPoints = keyInsightsText
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => line.startsWith('-'))
    .map((line) => line.substring(1).trim())

  return bulletPoints
}
