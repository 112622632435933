import { useMutation } from '@tanstack/react-query'
import { put } from '../../lib/api'
import { Task } from '../../types/Task'
import { projectPath } from '../ui/ProjectsContext'

interface PartialTask {
  task_type?: string
  assignee_id?: string
  due_at?: string
  resource_id?: string
  resource_type?: string
  completed?: boolean
}

interface Params {
  id: number | string
  task: PartialTask
}

interface Data {
  task: Task
  errors?: string[]
  message?: string
}

export function useUpdateTask() {
  return useMutation<Data, unknown, Params>({
    mutationFn: ({ id, task }: Params) => {
      const path = projectPath(`/tasks/${id}`)
      return put<Data>(path, { task })
    }
  })
}
