import {
  Box,
  Button,
  Collapse,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconCheck, IconChevronDown, IconChevronRight, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { toast } from 'sonner'
import { User } from '../../../../types/Invite'
import { Play, PlayItem } from '../../../../types/Play'
import { useCompletePlayItems, useDismissPlayItems, usePlay } from '../../../data/use-plays'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import DoubleAvatar from '../../../ui/DoubleAvatar'
import { TextEllipsis } from '../../../ui/text-ellipsis'

interface InboxSidebarProps {
  plays: Play[]
  loading: boolean
  selectedId?: string
  onSelect: (item: PlayItem) => void
  playId?: string
  selectedUser?: Partial<User> | null
}

function PlayCard({
  play,
  selectedId,
  userId,
  onSelect,
  isOpen: isOpenProp
}: {
  play: Play
  selectedId: string | undefined
  userId: string | undefined
  onSelect: (item: PlayItem) => void
  isOpen: boolean
}) {
  const [page, setPage] = useState(1)
  const [items, setItems] = useState<PlayItem[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const { data: playData, isLoading, isFetching, isRefetching } = usePlay(play.id, { page, userId })
  const hasNextPage = playData?.page_meta ? playData.page_meta.current_page < playData.page_meta.total_pages : false

  useEffect(() => {
    if (playData?.page_meta?.total_count) {
      setTotalCount(playData.page_meta.total_count)
    }
  }, [playData?.page_meta?.total_count])

  useEffect(() => {
    if (playData?.play_items) {
      if (page === 1) {
        setItems(playData.play_items)
      } else {
        setItems((prev) => {
          const itemMap = new Map(prev.map((item) => [item.id, item]))
          playData.play_items.forEach((item) => {
            itemMap.set(item.id, item)
          })
          return Array.from(itemMap.values())
        })
      }
    }
  }, [playData?.play_items, page])

  const { mutate: completeItems } = useCompletePlayItems()
  const { mutate: dismissItems } = useDismissPlayItems()

  const handleComplete = (itemId: string) => {
    completeItems(
      { itemIds: [itemId] },
      {
        onSuccess: () => {
          setItems((prev) => prev.filter((item) => item.id !== itemId))
          setTotalCount((prev) => prev - 1)
          toast.success('Prospect completed')
        }
      }
    )
  }

  const handleDismiss = (itemId: string) => {
    dismissItems(
      { itemIds: [itemId], reason: 'not_in_icp' },
      {
        onSuccess: () => {
          setItems((prev) => prev.filter((item) => item.id !== itemId))
          setTotalCount((prev) => prev - 1)
          toast.info('Prospect dismissed')
        }
      }
    )
  }

  const handleLoadMore = () => {
    if (hasNextPage && !isLoading) {
      setPage((prev) => prev + 1)
    }
  }
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: isOpenProp })

  return (
    <Box>
      <Button
        w="100%"
        size="sm"
        colorScheme="gray"
        display="flex"
        h="48px"
        justifyContent="flex-start"
        onClick={onToggle}
        _hover={{ bg: 'gray.50' }}
        roundedBottom="none"
        roundedTop="md"
        leftIcon={<Icon as={isOpen ? IconChevronDown : IconChevronRight} boxSize={4} color="gray.500" />}
        rightIcon={
          isLoading ? (
            <Spinner size="sm" />
          ) : (
            <Tag colorScheme="purple" size="sm" rounded="full">
              {totalCount}
            </Tag>
          )
        }
      >
        <TextEllipsis>{play.name}</TextEllipsis>
      </Button>

      <Collapse in={isOpen}>
        {items.map((item) => (
          <Box
            key={item.id}
            py={2}
            px={4}
            bg={selectedId === item.id ? 'blue.50' : undefined}
            _hover={{ bg: selectedId === item.id ? 'blue.100' : 'gray.50' }}
            cursor="pointer"
            onClick={() => onSelect(item)}
            borderLeft="4px"
            borderLeftColor={selectedId === item.id ? 'blue.500' : 'transparent'}
            position="relative"
            role="group"
          >
            <HStack spacing={3}>
              {item.record_type === 'Profile' && (
                <DoubleAvatar
                  domain={item.record?.company?.domain}
                  email={item.record?.email}
                  name={item.record?.name}
                  companyName={item.record?.company?.name}
                />
              )}

              {item.record_type === 'Account' && (
                <CompanyAvatar size="sm" domain={item.record?.company?.domain} name={item.record?.company?.name} />
              )}

              <Stack spacing={-1}>
                <Text fontSize="sm" color="gray.700">
                  {item.record_type === 'Account' && (item.record?.company?.name ?? item.record?.company?.domain)}
                  {item.record_type === 'Profile' && (item.record?.name ?? item.record?.['display_name'])}
                </Text>
                {item.record_type === 'Profile' && (
                  <Text fontSize="xs" color="gray.500">
                    {item.record?.title ?? item.record?.email}
                  </Text>
                )}
              </Stack>
            </HStack>

            <HStack
              position="absolute"
              right={4}
              top="50%"
              transform="translateY(-50%)"
              opacity={0}
              _groupHover={{ opacity: 1 }}
              transition="opacity 0.2s"
              spacing={2}
              bg="white"
              p="2"
              border="1px solid"
              borderColor="gray.200"
              rounded="md"
              shadow="sm"
            >
              <Tooltip label="Complete">
                <IconButton
                  aria-label="Complete"
                  variant="ghost"
                  size="xs"
                  boxSize="5"
                  as={IconCheck}
                  colorScheme="green"
                  cursor="pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleComplete(item.id)
                  }}
                />
              </Tooltip>
              <Tooltip label="Dismiss">
                <IconButton
                  aria-label="Dismiss"
                  variant="ghost"
                  size="xs"
                  boxSize="5"
                  as={IconX}
                  colorScheme="orange"
                  cursor="pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDismiss(item.id)
                  }}
                />
              </Tooltip>
            </HStack>
          </Box>
        ))}
        {hasNextPage && (
          <Button
            size="sm"
            variant="ghost"
            width="100%"
            colorScheme="purple"
            onClick={handleLoadMore}
            isLoading={isLoading || isFetching || isRefetching}
            loadingText="Loading more..."
            spinnerPlacement="end"
          >
            Load more
          </Button>
        )}
      </Collapse>
    </Box>
  )
}

export const InboxSidebar: React.FC<InboxSidebarProps> = ({
  selectedUser,
  plays,
  loading,
  selectedId,
  onSelect,
  playId
}) => {
  const [localPlays, setLocalPlays] = useState<Play[]>(plays)

  useEffect(() => {
    setLocalPlays(plays)
  }, [plays])

  if (loading) {
    return (
      <Box p={4} textAlign="center" h="100%">
        <Spinner />
      </Box>
    )
  }

  return (
    <VStack align="stretch" w="100%" h="100%" overflow="auto" spacing="4" p="4">
      {localPlays.map((play) => (
        <Box key={play.id + selectedUser?.id} w="100%" borderWidth="1px" rounded="md" borderColor="gray.200">
          <PlayCard
            play={play}
            selectedId={selectedId}
            userId={selectedUser?.id}
            onSelect={onSelect}
            isOpen={playId === play.id}
          />
        </Box>
      ))}
    </VStack>
  )
}
