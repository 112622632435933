import { Box, Flex, HStack, IconButton, Link, Text } from '@chakra-ui/react'
import { IconAdjustmentsHorizontal } from '@tabler/icons-react'
import { usePermission } from '@ui/PermissionsContext'
import { useCurrentUser } from '@ui/UserContext'
import { UserSelector } from '@ui/UserSelector'
import React, { useEffect, useState } from 'react'
import router from '../../../lib/router'
import { Apps } from '../../../types/App'
import { PlayItem } from '../../../types/Play'
import { useInbox } from '../../data/use-inbox'
import { usePlay } from '../../data/use-plays'
import { JSONTree } from '../../ui/json-tree'
import { projectPath } from '../../ui/ProjectsContext'
import { useSearchParams } from '../../ui/useSearchState'
import { mergeParams } from '../icps/types'
import { InboxSidebar } from '../plays_v2/components/InboxSidebar'
import { ProspectDetail } from '../plays_v2/components/ProspectDetail'

const PlaysV2Page: React.FC<{ apps: Apps }> = ({ apps }) => {
  const { searchParams } = useSearchParams()
  const [selectedItem, setSelectedItem] = useState<PlayItem | null>(null)
  const currentUser = useCurrentUser()
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(searchParams?.user_id as string)
  const { hasPermission: canViewAsMember } = usePermission({ on: 'project', action: 'can_view_as_member' })

  const { data: playItems } = usePlay(searchParams.play_id as string, {
    status: 'pending',
    range: 'all',
    userId: selectedUserId
  })
  const {
    data: inbox,
    isLoading: loading,
    isFetching: isFetchingInbox,
    isRefetching: isRefetchingInbox
  } = useInbox({
    userId: selectedUserId
  })

  useEffect(() => {
    const playItemId = searchParams.play_item_id

    if (playItemId && playItems?.play_items) {
      const playItem = playItems.play_items.find((item) => item.id === playItemId)

      if (playItem) {
        setSelectedItem(playItem)
      }
    }
  }, [searchParams.play_item_id, playItems?.play_items])

  useEffect(() => {
    if (selectedUserId) {
      const url = mergeParams(window.location.toString(), {
        user_id: selectedUserId
      })

      router.visit(url, { fetch: false })
    } else {
      const url = mergeParams(window.location.toString(), {
        user_id: undefined
      })
      router.visit(url, { fetch: false })
    }
  }, [selectedUserId])

  const handleSelectItem = (item: PlayItem | null) => {
    setSelectedItem(item)

    router.visit(projectPath(`/plays/v2?play_id=${item?.play_id}&play_item_id=${item?.id}&user_id=${selectedUserId}`), {
      fetch: false
    })
  }

  return (
    <Box w="100%" h="100vh" bg="gray.50" p="0" m="0">
      <HStack gap="0" alignItems="stretch" h="100vh">
        <Box w="350px" bg="white" h="100%" display="flex" flexDirection="column">
          <Box flex={1} overflow="auto">
            <InboxSidebar
              plays={inbox?.plays_with_pending || []}
              loading={loading || isFetchingInbox || isRefetchingInbox}
              selectedId={selectedItem?.id}
              onSelect={handleSelectItem}
              playId={searchParams?.play_id as string}
              selectedUser={selectedUserId ? { id: selectedUserId } : undefined}
            />
          </Box>
          {canViewAsMember && (
            <Box p={4} borderTop="1px solid" borderColor="gray.200">
              <Flex alignItems="center" gap={2}>
                <Text fontSize="sm" color="gray.600">
                  View as
                </Text>
                <UserSelector
                  selectedUserId={selectedUserId || currentUser!.id}
                  onChange={(userId) => setSelectedUserId(userId || undefined)}
                  isReadOnly={!canViewAsMember}
                />
                <IconButton
                  aria-label="Settings"
                  icon={<IconAdjustmentsHorizontal size={16} />}
                  variant="outline"
                  size="sm"
                  as={Link}
                  href={projectPath('/settings/ai-instructions')}
                  ml="auto"
                />
              </Flex>
            </Box>
          )}
        </Box>
        <HStack flex={1} position="relative" w="100%" h="100%" justifyContent={'space-between'} alignItems={'stretch'}>
          <Box flex={1} overflow="auto" maxW="container.lg" margin="0 auto">
            {selectedItem && <ProspectDetail playItem={selectedItem} apps={apps} />}
          </Box>
        </HStack>
      </HStack>
    </Box>
  )
}

export default PlaysV2Page
