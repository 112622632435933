import { Button, Flex, FormControl, FormLabel, Input, Stack, Divider, Icon } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { NamedFilter } from '../../../ui/filters/types'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import { AdvancedFilters } from '../../../ui/filters/types'
import { IconUsers } from '@tabler/icons-react'
import { BuildingIcon } from '../../../ui/icons'
import { AdvancedFilterBuilder } from '../../../ui/filters/AdvancedFilterBuilder'
import { useFacets } from '../../../data/use-facets'
import { Apps } from '../../../../types/App'
import {
  usePersistNamedFilter,
  PersistNamedFilterResult,
  PersistNamedFilterError
} from '../../../data/use-named-filters'
import { isEmpty } from 'lodash'

interface NamedFilterFormProps {
  namedFilter?: NamedFilter | null
  targetType?: string
  layout?: 'vertical' | 'horizontal'
  canChangeTargetType?: boolean
  onSave?: (namedFilter: NamedFilter) => void
  onCancel?: () => void
  setIsLoading?: (isLoading: boolean) => void
}

const targetOptions = [
  {
    label: 'Companies',
    icon: <BuildingIcon boxSize={4} />,
    value: 'Account'
  },
  {
    label: 'People',
    icon: <Icon as={IconUsers} boxSize={4} />,
    value: 'Profile'
  }
]

function NamedFilterForm({
  namedFilter,
  targetType,
  layout,
  onSave,
  onCancel,
  setIsLoading,
  canChangeTargetType = true
}: NamedFilterFormProps) {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [isEditing, setIsEditing] = useState<boolean>(!!namedFilter?.id)
  const [localNamedFilter, setLocalNamedFilter] = useState<NamedFilter>(() => {
    if (isEditing) {
      return { ...namedFilter } as NamedFilter
    } else {
      return { ...namedFilter, target_type: targetType || 'Account' } as NamedFilter
    }
  })

  const { mutate: saveNamedFilter, isPending: isLoading } = usePersistNamedFilter({
    onSuccess: (response: PersistNamedFilterResult) => {
      const savedNamedFilter = response.named_filter as NamedFilter
      if (savedNamedFilter) {
        setLocalNamedFilter(savedNamedFilter)
        onSave?.(savedNamedFilter)
      }
    },
    onError: (error: PersistNamedFilterError) => {
      const errorMessages = Object.entries(error.body.errors).reduce(
        (acc, [field, messages]) => {
          acc[field] = messages.join(', ')
          return acc
        },
        {} as Record<string, string>
      )
      setErrors(errorMessages)
    }
  })

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      event.stopPropagation()

      saveNamedFilter({
        id: namedFilter?.id,
        attributes: {
          name: localNamedFilter.name,
          description: localNamedFilter.description,
          target_type: localNamedFilter.target_type,
          filters: localNamedFilter.filters
        }
      })
    },
    [localNamedFilter, namedFilter, saveNamedFilter]
  )

  useEffect(() => {
    setIsEditing(!!localNamedFilter.id)
  }, [localNamedFilter])

  useEffect(() => {
    setIsLoading?.(isLoading)
  }, [isLoading, setIsLoading])

  return (
    <Stack w={layout === 'horizontal' ? '50%' : '100%'} pt={0} minHeight="500px">
      <form onSubmit={handleSubmit} id="named-filter-form">
        <AuthenticityToken />
        {isEditing && <input type="hidden" name="_method" value="PATCH" />}

        {canChangeTargetType && (
          <Stack spacing={5} w="100%" pb={5}>
            <FormControl id="named_filter[target_type]">
              <FormLabel>Target type</FormLabel>
              <CardRadioGroup
                key={'target-type'}
                size="sm"
                gridTemplateColumns="repeat(2, 1fr)"
                value={localNamedFilter?.target_type}
                onChange={(e) => {
                  if (isLoading) return
                  setLocalNamedFilter({ ...localNamedFilter, target_type: e as 'Account' | 'Profile' })
                }}
                options={targetOptions}
                isDisabled={isLoading}
              />
            </FormControl>
          </Stack>
        )}

        <Stack spacing={5} w="100%">
          <FormControl id="named_filter[name]" isRequired>
            <FormLabel>Filter name</FormLabel>
            <Input
              key={'name'}
              type="text"
              bg="white"
              name="named_filter[name]"
              placeholder="e.g. Competitors"
              size="sm"
              defaultValue={localNamedFilter?.name}
              isDisabled={isLoading}
              isInvalid={!!errors.name && isEmpty(localNamedFilter.name)}
              onChange={(e) => {
                setLocalNamedFilter({ ...localNamedFilter, name: e.target.value })
              }}
            />
          </FormControl>
          <FormControl id="named_filter[description]">
            <FormLabel>Description</FormLabel>
            <Input
              key={'description'}
              type="text"
              bg="white"
              name="named_filter[description]"
              defaultValue={localNamedFilter?.description}
              placeholder="Longer description of the filter criteria, for easy identification"
              size="sm"
              isDisabled={isLoading}
              isInvalid={!!errors.description && isEmpty(localNamedFilter.description)}
              onChange={(e) => {
                setLocalNamedFilter({ ...localNamedFilter, description: e.target.value })
              }}
            />
          </FormControl>
        </Stack>

        <Stack w="100%" mt={5} p={0} spacing={0}>
          <FormControl id="named_filter[filters]" isRequired>
            <FormLabel>Filters</FormLabel>
            <Filters
              key={'filters'}
              targetType={localNamedFilter?.target_type}
              advancedFilters={localNamedFilter?.filters}
              setAdvancedFilters={(advancedFilters) => {
                setLocalNamedFilter({ ...localNamedFilter, filters: advancedFilters })
              }}
              isLoading={isLoading}
              isInvalid={!!errors.filters}
            />
          </FormControl>
        </Stack>
      </form>

      <Stack w="100%" mt={5} p={0} px={5} spacing={0} position="absolute" bottom={0} left={0} right={5}>
        <Divider />

        <Flex alignItems="center" gap={3} py={3} marginLeft="auto">
          {onCancel && (
            <Button size="sm" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          )}

          <Button type="submit" size="sm" colorScheme="purple" form="named-filter-form" isLoading={isLoading}>
            {namedFilter?.id ? 'Update named filter' : 'Create named filter'}
          </Button>
        </Flex>
      </Stack>
    </Stack>
  )
}

function Filters({
  targetType,
  advancedFilters,
  setAdvancedFilters,
  apps,
  isLoading,
  isInvalid = false
}: {
  targetType: 'Account' | 'Profile' | string
  advancedFilters: AdvancedFilters
  setAdvancedFilters: (filters: AdvancedFilters) => void
  apps?: Apps
  isLoading?: boolean
  isInvalid?: boolean
}) {
  const facets = useFacets({
    facet_filters: advancedFilters,
    facetCloudPath: targetType === 'Profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud',
    onFilterChange: setAdvancedFilters
  })

  // {"_and" => []} will be considered as empty
  const isEmptyFilters = (filters: AdvancedFilters, level = 0) => {
    if (level >= 3 && isEmpty(filters)) return false // empty group

    return (
      isEmpty(filters) ||
      (Array.isArray(filters) && filters.filter((e) => isEmpty(e)).length === 0) ||
      (typeof filters === 'object' &&
        Object.values(filters || []).filter((array) => isEmptyFilters(array, level + 1)).length == 0)
    )
  }

  const showInvalidIndicator = isInvalid && isEmptyFilters(advancedFilters)

  return (
    <AdvancedFilterBuilder
      key={`filters`}
      filters={advancedFilters}
      kind={targetType.toLowerCase() as 'account' | 'profile'}
      apps={apps}
      convertTopLevelFilters
      onChangeAdvancedFilters={(filters) => {
        if (isLoading) return

        if (isEmptyFilters(filters)) {
          setAdvancedFilters({})
        } else {
          setAdvancedFilters(filters)
        }
      }}
      emptyFiltersMessage={showInvalidIndicator ? 'Choose at least one filter' : 'No conditions added yet'}
      wrapperProps={{
        pt: 0,
        mb: 0,
        gap: 4,
        minHeight: '40px',
        borderRadius: '8px',
        border: showInvalidIndicator ? '2px solid red' : 'none',
        py: showInvalidIndicator ? '4' : '0',
        px: showInvalidIndicator ? '4' : '0'
      }}
      {...facets}
    />
  )
}

export default NamedFilterForm
