import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  Link,
  SkeletonText,
  Stack,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp, IconMessage, IconSparkles, IconUsers } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { Apps } from '../../../../types/App'
import { useEmailRecommendations } from '../../../data/use-ai-recommendations'
import { useProfile } from '../../../data/use-profile'
import { Card } from '../../../ui/Card'
import { AiSparklesIcon } from '../../../ui/icons'
import MarkdownText from '../../../ui/MarkdownText'
import { MiddotDivider } from '../../../ui/Middot'
import { TimeAgo } from '../../../ui/TimeAgo'
import { ProfilePanelV2 } from '../../profiles/components/ProfilePanelV2'
import { profilePath } from '../../profiles/lib/path'
import { KeyPerson } from '../types'
import { GradientBox } from './Data'
import { ViewResearchButton } from './ProspectDetail'

interface KeyPeopleProps {
  apps: Apps
  people: {
    id: string
    name: string
    title: string
    summary: string
  }[]
}

const KeyPersonCard: React.FC<{ person: KeyPerson; apps: Apps }> = ({ person, apps }) => {
  const { data } = useProfile({ id: person.id })
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null)

  const enabled = !!person.id && isExpanded

  const { emailStrategy, isLoading: loadingSuggestions } = useEmailRecommendations(
    person.id,
    {
      runId: person.id
    },
    { enabled }
  )

  const profile = useMemo(() => data?.profile, [data])

  return (
    <Box>
      <ProfilePanelV2
        profileId={selectedProfileId || ''}
        isOpen={!!selectedProfileId}
        onClose={() => setSelectedProfileId(null)}
        key={['profile-panel', selectedProfileId].join('-')}
        apps={apps}
      />
      <HStack justify="space-between" mb={isExpanded ? 4 : 0}>
        <HStack spacing={3} align="flex-start">
          <Avatar name={person.name} size="sm" bg="blue.100" color="blue.600" />
          <Box>
            <HStack spacing={2}>
              <Link
                href={profilePath({ id: person.id })}
                onClick={(e) => {
                  e.preventDefault()
                  setSelectedProfileId(person.id)
                }}
              >
                <Text fontWeight="semibold" fontSize="sm" color="gray.800">
                  {person.name ?? profile?.display_name}
                </Text>
              </Link>
              {person.sources?.[0] && (
                <Badge colorScheme="green" fontSize="xs">
                  {person.sources[0].label}
                </Badge>
              )}
              {person.engagementLevel === 'high' && <Icon as={IconSparkles} color="blue.500" />}
            </HStack>

            <Text fontSize="sm">{person.title ?? profile?.title}</Text>

            <Box my="4" borderLeftColor="blue.200" borderLeftWidth={'3px'} pl={4}>
              <HStack spacing="1">
                <Icon as={IconSparkles} color="blue.500" />
                <Text fontSize="xs" color="gray.600">
                  AI Summary
                </Text>
              </HStack>

              <Text fontSize="sm" pt="1" fontWeight={'normal'}>
                {person.summary}
              </Text>
            </Box>

            <HStack>
              <Text color="gray.600" fontSize="xs" my={2}>
                Last seen: <TimeAgo time={profile?.last_seen_at} />
              </Text>

              {profile?.last_activity_date && (
                <>
                  <MiddotDivider />
                  <Text color="gray.600" fontSize="xs" my={2}>
                    Last contacted: <TimeAgo time={profile?.last_activity_date} />
                  </Text>
                </>
              )}
            </HStack>
          </Box>
        </HStack>
        <HStack>
          <Button
            rightIcon={<Icon as={isExpanded ? IconChevronUp : IconChevronDown} />}
            variant="ghost"
            size="xs"
            colorScheme="blue"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Collapse' : 'Expand'} details
          </Button>
          <ViewResearchButton
            prospectName={person.name}
            prospectId={person.id}
            variant="outline"
            size="sm"
            prospectEmail={person.email}
            prospectTitle={person.title}
          >
            <HStack>
              <Icon as={IconMessage} />
              <Text>Message</Text>
            </HStack>
          </ViewResearchButton>
        </HStack>
      </HStack>

      {isExpanded && (
        <Box ml={12}>
          <GradientBox variant="alternative" position="relative" shadow={'sm'} borderWidth={1} borderColor="gray.200">
            <AiSparklesIcon position="absolute" top={4} right={4} />
            <Stack spacing={6}>
              {loadingSuggestions && emailStrategy === undefined && <SkeletonText noOfLines={4} />}
              {emailStrategy && <MarkdownText>{emailStrategy}</MarkdownText>}

              <HStack alignItems={'center'} wrap={'wrap'} gap={2}>
                <Text fontWeight="semibold" fontSize="xs">
                  Sources:
                </Text>
                {[
                  { label: 'Intent Activity', color: 'green' },
                  { label: 'CRM Data', color: 'blue' },
                  { label: 'Research Agents', color: 'purple' },
                  { label: 'Waterfall Enrichment', color: 'orange' }
                ].map((source) => (
                  <Tag key={source.label} colorScheme={source.color} size="sm">
                    <Text fontSize="xs">{source.label}</Text>
                  </Tag>
                ))}
              </HStack>
            </Stack>
          </GradientBox>
        </Box>
      )}
    </Box>
  )
}

export const KeyPeople: React.FC<KeyPeopleProps> = ({ people, apps }) => {
  const [showAll, setShowAll] = React.useState(false)
  const remainingCount = people.length - 3
  const displayedPeople = showAll ? people : people.slice(0, 3)

  return (
    <Card as={Stack}>
      <HStack justify="space-between" mb={6}>
        <HStack>
          <Center boxSize={10} bg="purple.50" borderRadius="md">
            <Icon as={IconUsers} color="purple.500" boxSize={5} />
          </Center>
          <Heading size="md" fontWeight="semibold">
            Key People
          </Heading>
        </HStack>
      </HStack>

      <VStack spacing={6} align="stretch" divider={<Divider />}>
        {displayedPeople.map((person) => (
          <KeyPersonCard key={person.id} person={person} apps={apps} />
        ))}
      </VStack>

      {remainingCount > 0 && (
        <Button
          variant="link"
          color="purple.500"
          mt={4}
          leftIcon={<Icon as={showAll ? IconChevronUp : IconChevronDown} />}
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? 'Show less' : `Show ${remainingCount} more people`}
        </Button>
      )}
    </Card>
  )
}
