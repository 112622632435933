import { Box, Divider, HStack } from '@chakra-ui/react'
import React from 'react'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import ContentReportForm, { ContentReportFormProps } from './components/ContentReportForm'

interface Props {
  form: ContentReportFormProps
}

export default function New(props: Props) {
  return (
    <PageLayout size="md">
      <SettingsBreadCrumb
        paths={[
          { path: projectPath('/reports/contents'), title: 'Content Reports' },
          { path: projectPath('/reports/contents/new'), title: 'New' }
        ]}
      />
      <Box>
        <HStack>
          <PageTitle>Content Reports</PageTitle>
        </HStack>
        <PageDescription>
          Content Reports can help you understand which content drives conversion. For every piece of content
          considered, we try to understand what percentage of accounts exposed convert within the window selected days
          and rank the content by conversion rate. By default, Koala will filter out any content that did not have at
          least 5 accounts visit and 1 conversion. Raising these thresholds increases statistical significance of the
          result.
        </PageDescription>
      </Box>

      <Divider />

      <ContentReportForm {...props.form} />
    </PageLayout>
  )
}
