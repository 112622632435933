import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { Account } from '../../types/Account'
import { Apps, CatalogApp } from '../../types/App'
import { ProjectActions } from '../../types/AppActions'
import { KQLByDay } from '../../types/KQL'
import { KqlDefinition } from '../pages/kql_definitions/types'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  account: Account
  project_actions: ProjectActions
  apps: Apps
  catalog: CatalogApp[]
  kqls_by_day: KQLByDay[]
  kql_definitions: KqlDefinition[]
  selected_signals?: string[]
  default_columns?: string[]
  recommendation: any
}

interface Props {
  id?: string | null
  domain?: string | null
}

export function useAccount({ id, domain }: Props = {}, options: Partial<UseQueryOptions<Data>> = {}) {
  const project = useCurrentProject()
  const path = projectPath(`/accounts/${id || domain}`)

  return useQuery<Data>({
    ...options,
    queryKey: ['account', { projectId: project?.id, id, domain }],
    queryFn: () => concurrentGET<Data>(path),
    enabled: Boolean(project?.id && (id || domain)) && (options?.enabled ?? true)
  })
}
