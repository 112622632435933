import {
  Box,
  BoxProps,
  Button,
  Code,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  SkeletonText,
  Stack,
  Text,
  TextProps,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowUpRight, IconChevronDown, IconChevronUp, IconHelp, IconInfoCircle } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import { orderBy } from 'lodash'
import groupBy from 'lodash/groupBy'
import range from 'lodash/range'
import sortBy from 'lodash/sortBy'
import ms from 'ms'
import React, { MouseEvent, useCallback, useEffect } from 'react'
import { Sparklines, SparklinesCurve, SparklinesProps } from 'react-sparklines'
import { useMountedState } from 'react-use'
import { concurrentGET } from '../../../../lib/api'
import dayjs from '../../../../lib/dayjs'
import { fillMissingDays } from '../../../../lib/fill-missing-days'
import { ordinalSuffix } from '../../../../lib/ordinal-suffix'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import useLocation from '../../../ui/useLocation'
import { humanize } from '../../accounts/facets/filter-cloud'
import { accountPath } from '../../accounts/lib/account-path'
import { KQLFeed, KQLFeedEntry } from '../../profiles/components/profile-feed'
import { HighlightedAccount, Trend } from '../types'
import { Trait as TraitType } from './types'

export function gradeLetter(pct?: number) {
  if (!pct) {
    return undefined
  }

  if (pct >= 90) {
    return 'A'
  } else if (pct >= 80) {
    return 'B'
  } else if (pct >= 70) {
    return 'C'
  } else if (pct >= 60) {
    return 'D'
  } else if (pct >= 0) {
    return 'F'
  } else {
    return undefined
  }
}

export function grade(pct?: number, label?: string) {
  const letter = label ?? gradeLetter(pct)

  let color

  if (pct === undefined) {
    return {
      letter: '',
      color: 'gray'
    }
  }

  if (letter === 'A') {
    color = 'blue'
  } else if (letter === 'B') {
    color = 'purple'
  } else if (letter === 'C') {
    color = 'orange'
  } else if (letter === 'D') {
    color = 'yellow'
  } else {
    color = 'gray'
  }

  return { letter, color }
}

export function hot(value?: number) {
  let rng = 0
  let bars = 0
  let text = 'No Data'
  let bg = 'gray'

  if (value === undefined) {
    return {
      rng,
      bars,
      text,
      bg
    }
  }

  if (value >= 5) {
    bg = 'red'
    bars = 4
    rng = 100
    text = 'Very High'
  } else if (value === 4) {
    bg = 'red'
    bars = 3
    rng = 80
    text = 'High'
  } else if (value === 3) {
    bars = 2
    bg = 'orange'
    rng = 50
    text = 'Medium'
  } else if (value === 2) {
    bars = 1
    bg = 'yellow'
    rng = 25
    text = 'Low'
  }

  return { rng, bars, text, bg }
}

interface LetterGradeProps extends TextProps {
  value: number
  tooltip?: boolean
  label?: string
}

export function LetterGrade(props: LetterGradeProps) {
  const { value, label, tooltip, ...rest } = props
  const showTooltip = tooltip !== false
  const { letter, color } = grade(value, label)

  return (
    <Tooltip
      label={showTooltip && `This account is in the ${ordinalSuffix(value)} percentile of accounts.`}
      openDelay={400}
    >
      <Text fontWeight="semibold" color={color + '.500'} fontSize="lg" {...rest}>
        {letter}
      </Text>
    </Tooltip>
  )
}

function Bar(props: BoxProps) {
  return <Box w="1" h="6" bg="gray.300" rounded="sm" {...props}></Box>
}

export function Hotness(props: { value?: number; verbose?: boolean; tooltip?: boolean; height?: number }) {
  const value = props.value
  const height = props.height || 16
  const multiplier = height / 4
  const width = Math.max(1, Math.round(height / 5))
  const { bars, bg, text } = hot(value)

  if (text === 'No Data') {
    return <Text fontSize={'xs'}>--</Text>
  }

  return (
    <HStack flexShrink={0} spacing={2} alignItems="baseline">
      {props.verbose && <Text color={`${bg}.500`}>{text}</Text>}
      <HStack spacing="0.5" alignItems="flex-end">
        {range(1, 5).map((n) => (
          <Bar
            key={n}
            w={width + 'px'}
            h={n * multiplier + 'px'}
            maxH={height + 'px'}
            bg={n <= bars ? `${bg}.300` : 'gray.300'}
          />
        ))}
      </HStack>
    </HStack>
  )
}

export interface TrendlineProps {
  trend?: Trend
  range: 'day' | 'week' | 'month' | 'all'
  color: string
}

export function Trendline({ color, range, trend, ...rest }: TrendlineProps & SparklinesProps) {
  const data = React.useMemo(() => {
    return fillMissingDays(sortBy(trend?.trends || [], ['day']), dayjs.utc().subtract(30, 'days'), dayjs.utc(), 'value')
  }, [trend?.trends])

  let limit = 30
  if (range === 'day' || range === 'week') {
    limit = 7
  }

  const line = data.slice(-(limit + 1))
  const noData = line.every((l) => l.value === 0)
  const colorVar = noData ? 'gray-400' : `${color}-500`

  return (
    <Sparklines data={line.map((t) => t.value)} {...rest}>
      <SparklinesCurve style={{ strokeWidth: 1.5 }} color={`var(--chakra-colors-${colorVar})`} />
    </Sparklines>
  )
}

interface Props {
  account: HighlightedAccount & {
    fit_breakdown?: TraitType[]
  }
  kqls: KQLFeedEntry[]
}

export function Breakdown(props: { domain: string; showInfo?: boolean }) {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const [breakdown, setBreakdown] = React.useState<Props | null>(null)
  const [loading, setLoading] = React.useState(false)
  const location = useLocation()
  const mounted = useMountedState()

  const handleToggle = React.useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      onToggle()
    },
    [onToggle]
  )

  useEffect(() => {
    if (!isOpen) {
      return
    }

    const path = accountPath({ company: { domain: props.domain } }, `/breakdown`)
    setLoading(true)
    concurrentGET<Props>(path)
      .then((data) => {
        if (!mounted()) {
          return
        }
        setBreakdown(data)
        setLoading(false)
      })
      .catch(() => {
        if (!mounted()) {
          return
        }
        setLoading(false)
      })
  }, [props.domain, isOpen, location, mounted])

  return (
    <>
      <IconButton
        cursor="pointer"
        variant="ghost"
        aria-label="Inspect"
        size="tiny"
        color="gray.400"
        icon={<IconInfoCircle size={16} />}
        opacity={isOpen || props.showInfo ? 1 : 0}
        pointerEvents={isOpen || props.showInfo ? 'auto' : 'none'}
        _hover={{
          color: 'gray.600'
        }}
        _groupHover={{
          opacity: 1,
          pointerEvents: 'auto'
        }}
        onClick={handleToggle}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg" preserveScrollBarGap>
        <DrawerOverlay zIndex="modal" />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading size="md">Account Fit & Intent Scoring</Heading>
          </DrawerHeader>
          <DrawerBody fontSize="sm" paddingBottom={6}>
            <SkeletonText h={'6'} noOfLines={12} isLoaded={!loading} spacing={'4'} height={'20'}>
              {breakdown && <AccountBreakdown {...breakdown} />}
            </SkeletonText>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

function BreakdownHeader({ children, extra }: { children: React.ReactNode; extra?: React.ReactNode }) {
  return (
    <Flex alignItems="center" justifyContent="space-between" color="gray.500" gap={2}>
      <Heading fontSize="xs" letterSpacing={'wider'} textTransform={'uppercase'}>
        {children}
      </Heading>
      {extra}
    </Flex>
  )
}

const MAX_TRAIT_GROUP_LENGTH = 5

function Trait({ value, multiplier }: { value: string; multiplier: number }) {
  const readableValue = value?.replace(`${value}=`, '')

  return (
    <Flex key={readableValue} gap="2" alignItems="center" maxW="100%" overflow="hidden">
      {multiplier && <Factor value={multiplier} />}
      <Text isTruncated fontSize={'xs'} fontFamily="mono" fontWeight={'normal'} title={readableValue}>
        {readableValue}
      </Text>
    </Flex>
  )
}

function TraitGroup({ heading, traits }: { heading: string; traits: Array<TraitType> }) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const shouldAllowExpanding = traits.length > MAX_TRAIT_GROUP_LENGTH
  const renderedTraits = isOpen ? traits : traits.slice(0, MAX_TRAIT_GROUP_LENGTH)
  const onExpand = useCallback(() => setIsOpen((prevIsOpen) => !prevIsOpen), [])

  return (
    <Flex
      direction="column"
      gap={2}
      px="4"
      pt="4"
      pb={shouldAllowExpanding ? '8' : '4'}
      border="2px solid"
      position="relative"
      borderColor="gray.200"
      borderRadius={8}
    >
      <Heading fontSize="md">{heading}</Heading>
      {renderedTraits.map((facet) => {
        const multiplier = facet.factor
        const key = facet.value
        return <Trait key={key} value={key} multiplier={multiplier} />
      })}

      {shouldAllowExpanding && (
        <Box position="absolute" bottom={0} right={0} display="flex" mt="4">
          <Button
            borderLeftRadius={0}
            borderTopRightRadius={0}
            onClick={onExpand}
            fontWeight="semibold"
            rightIcon={isOpen ? <IconChevronUp size={12} /> : <IconChevronDown size={12} />}
            size={'xs'}
          >
            {isOpen ? 'Show less' : `Show ${traits.length - MAX_TRAIT_GROUP_LENGTH} more`}
          </Button>
        </Box>
      )}
    </Flex>
  )
}

function Factor({ value }: { value: number }) {
  return (
    <Code fontSize={'xs'} fontStyle="mono" fontWeight="bold" px={1} colorScheme={value < 1 ? 'red' : 'green'}>
      {value.toFixed(1)}x
    </Code>
  )
}

function AccountBreakdown(props: Props) {
  const project = useCurrentProject()
  const { account } = props

  const intentGrade = account.intent_grade
  const intent = hot(intentGrade)
  const fit = grade(account.fit_grade, account.fit_grade_letter)

  const fitBreakdown = account.fit_breakdown ?? []

  const groupedFitBreakdown = groupBy(fitBreakdown, 'facet')

  return (
    <Stack spacing={'8'}>
      <Divider />
      <HStack spacing={4} alignItems="flex-start">
        <CompanyAvatar size="40px" name={account.company.name} domain={account.company.domain} />

        <Stack spacing={0} w="100%">
          <Link href={accountPath(account)} fontSize="md" fontWeight="semibold">
            {account.company.name}
          </Link>
          <Link fontSize="sm" color="gray.500" href={`https://${account.domain}`} isExternal>
            {account.domain}
          </Link>
        </Stack>
      </HStack>
      <Divider />
      <Stack spacing={4}>
        <Box display="grid" gridAutoColumns={'minmax(0, 1fr)'} gridAutoFlow="column" alignItems={'flex-start'} gap="6">
          <Stack spacing="4">
            <Stack
              justifyContent={'center'}
              borderRadius={8}
              border="1px solid"
              borderColor={fit.color + '.100'}
              bg={fit.color + '.50'}
              p="4"
              pb={6}
            >
              <BreakdownHeader>Fit</BreakdownHeader>
              <Stack alignItems="center" paddingY={4}>
                {account.fit_grade && (
                  <LetterGrade
                    fontSize="x-large"
                    textAlign="center"
                    value={account.fit_grade}
                    label={account.fit_grade_letter}
                    tooltip={false}
                  />
                )}
                {project?.scoring_enabled || account.fit_grade ? (
                  <Heading fontWeight="bold" fontSize="lg">
                    {account.fit_grade ? `${ordinalSuffix(account.fit_grade)} percentile` : 'No Data'}
                  </Heading>
                ) : (
                  <Button
                    as={Link}
                    size="sm"
                    variant="outline"
                    rightIcon={<IconArrowUpRight size={14} />}
                    href={projectPath('/scoring')}
                    isExternal
                  >
                    Setup Account Scoring
                  </Button>
                )}
              </Stack>
            </Stack>

            {Object.keys(groupedFitBreakdown).map((key) => {
              const group = groupedFitBreakdown[key]
              return (
                <TraitGroup key={key} heading={humanize(key).replace(/(Company|Uniq|Metrics)/g, '')} traits={group} />
              )
            })}
          </Stack>

          <Stack spacing="4">
            <Stack
              bg={intent.bg + '.50'}
              p="4"
              border="1px solid"
              borderColor={intent.bg + '.100'}
              justifyContent={'center'}
              borderRadius={8}
              pb={6}
            >
              <BreakdownHeader>Intent</BreakdownHeader>
              <Stack alignItems="center" paddingY={4}>
                <Flex height="36px">
                  <Hotness value={intentGrade} height={24} />
                </Flex>
                <Heading fontWeight="bold" fontSize="lg">
                  {intent.text} {intent.text !== 'No Data' && 'Intent'}
                </Heading>
              </Stack>
            </Stack>

            <Stack borderRadius={8} border="2px solid" p="4" borderColor="gray.200" spacing="4">
              <Stack>
                <Flex w="100%" justifyContent={'space-between'} gap={2}>
                  <Heading size="sm">Recent Activity (month)</Heading>
                </Flex>
                <Trendline
                  range="month"
                  color="purple"
                  trend={
                    account.focus_time_trend?.month?.current?.value
                      ? account.focus_time_trend
                      : account.page_views_trend
                  }
                  width={264}
                  svgWidth={264}
                  height={36}
                  svgHeight={36}
                />
                <HStack justifyContent={'space-between'}>
                  {Boolean(account.focus_time_trend?.month?.current?.value ?? 0) && (
                    <Text fontSize={'sm'} fontWeight="semibold">
                      {`${ms(account.focus_time_trend?.month?.current?.value ?? 0)}`}{' '}
                      <Text as="span" fontSize="xs" color="gray.600">
                        session time
                      </Text>
                    </Text>
                  )}
                  <Text fontSize={'sm'} fontWeight="semibold">
                    {`${format(account.page_views_trend?.month?.current?.value ?? 0)}`}{' '}
                    <Text as="span" fontSize="xs" color="gray.600">
                      page views
                    </Text>
                  </Text>
                </HStack>
              </Stack>
            </Stack>

            {props.kqls.length > 0 && (
              <Stack borderRadius={8} border="2px solid" p="4" borderColor="gray.200" spacing="4">
                <Heading size="sm">Intent Signals</Heading>
                <KQLFeed feed={orderBy(props.kqls, 'last_triggered_at', 'desc')} showAll />
              </Stack>
            )}
          </Stack>
        </Box>
      </Stack>
      <Flex alignItems="center" mt="4" mb="0" w="100%" py="4" fontSize="xs" bg="purple.50" px="4" gap={4}>
        <Icon as={IconHelp} boxSize={5} color="gray.500" />
        <Text>
          Curious about how we calculate these scores? Head over to the{' '}
          <Link variant="primary" isExternal fontWeight={'semibold'} href={projectPath('/scoring')}>
            Account Scoring
          </Link>{' '}
          page to see how your model is set up.
        </Text>
      </Flex>
    </Stack>
  )
}
