import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  Link,
  Skeleton,
  SkeletonText,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  VStack,
  Wrap
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp, IconCircle, IconSparkles, IconTarget } from '@tabler/icons-react'
import React, { useMemo, useRef, useState } from 'react'
import { Apps } from '../../../../types/App'
import { PlayItem } from '../../../../types/Play'
import { useEmailRecommendations, usePlayItemAIRecommendations } from '../../../data/use-ai-recommendations'
import { Card } from '../../../ui/Card'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { LinkedinBoxIcon, SalesforceIcon } from '../../../ui/icons'
import { HubSpotIcon } from '../../../ui/icons/HubspotIcons'
import MarkdownText from '../../../ui/MarkdownText'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useProspectRecommendations } from '../hooks/useProspectRecommendations'
import { EligiblePlayItem } from '../types'
import { Data, GradientBox } from './Data'
import { KeyPeople } from './KeyPeople'
import { ResearchModal } from './ResearchModal'
import { SessionSummary } from './SessionSummary'

interface ProspectDetailProps {
  playItem: PlayItem
  apps: Apps
}

const InfoItem: React.FC<{ label: string; value: string | number | undefined; isLoading?: boolean }> = ({
  label,
  value,
  isLoading
}) => (
  <Box>
    <Text color="gray.600" fontSize="sm">
      {label}
    </Text>
    <Skeleton isLoaded={!isLoading}>
      <Text fontSize={'sm'} fontWeight="semibold">
        {value}
      </Text>
    </Skeleton>
  </Box>
)

export const ViewResearchButton: React.FC<{
  prospectName: string
  prospectTitle?: string
  prospectEmail?: string
  prospectId: string
  variant?: string
  size?: string
  colorScheme?: string
  children: React.ReactNode
}> = ({
  prospectName,
  prospectId,
  variant = 'outline',
  size = 'sm',
  colorScheme = 'purple',
  prospectEmail,
  prospectTitle,
  children
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant={variant} size={size} onClick={onOpen} colorScheme={colorScheme}>
        {children}
      </Button>
      <ResearchModal
        isOpen={isOpen}
        onClose={onClose}
        prospectName={prospectName}
        prospectId={prospectId}
        prospectEmail={prospectEmail}
        prospectTitle={prospectTitle}
      />
    </>
  )
}

const EligiblePlayCard: React.FC<{ play: EligiblePlayItem }> = ({ play }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const runId = useRef<string>(play.id)

  const {
    emailStrategy,
    recommendedPlay,
    contentStream: _contentStream,
    isLoading: loadingSuggestions,
    playTactic: _playTactic
  } = useEmailRecommendations(
    play.id,
    {
      runId: runId.current
    },
    { enabled: !!play.id && isExpanded }
  )

  return (
    <Stack>
      <HStack position="relative">
        <TimeAgo time={play.created_at} />
        <Box position="absolute" left={'-39px'} top={0} bottom={0}>
          <Icon as={IconCircle} color="purple.500" boxSize={3} bg="white" />
        </Box>
      </HStack>
      <HStack
        justify="space-between"
        alignItems="center"
        _hover={{ bg: 'gray.50', cursor: 'pointer' }}
        onClick={() => setIsExpanded(!isExpanded)}
        px="4"
        py="2"
        rounded={'md'}
        ml="-4"
      >
        <Text fontWeight="medium">{play.play.name}</Text>
        <HStack alignItems={'center'}>
          <Button
            rightIcon={<Icon as={isExpanded ? IconChevronUp : IconChevronDown} />}
            variant="ghost"
            size="xs"
            colorScheme="blue"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Collapse' : 'Expand'} details
          </Button>
          <ViewResearchButton
            prospectName={play.play.name}
            prospectId={play.id}
            variant="solid"
            size="sm"
            prospectEmail={play.record?.email}
            prospectTitle={play.record?.title}
          >
            <HStack>
              <Icon as={IconSparkles} />
              <Text>Run Play</Text>
            </HStack>
          </ViewResearchButton>
        </HStack>
      </HStack>
      {isExpanded && (
        <Stack spacing={4} ml="-4">
          {loadingSuggestions && recommendedPlay === undefined && <SkeletonText noOfLines={3} />}
          {recommendedPlay && emailStrategy && (
            <Stack as={GradientBox}>
              {_playTactic && (
                <Box>
                  <Text>{_playTactic}</Text>
                </Box>
              )}
              {!_playTactic && recommendedPlay && (
                <>
                  <Text fontWeight="semibold">{recommendedPlay.name}</Text>
                  <MarkdownText>{recommendedPlay.reasoning?.join('\n') ?? ''}</MarkdownText>
                </>
              )}
            </Stack>
          )}
          <TableContainer w="100%" border="1px solid" borderColor="gray.200" borderRadius="md">
            <Table size="sm">
              <Tbody>
                {play.play.display_columns.map((column) => (
                  <Tr key={column.id}>
                    <Td>{column.id}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </Stack>
  )
}

const EligiblePlaysCard: React.FC<{ eligiblePlayItems: EligiblePlayItem[] }> = ({ eligiblePlayItems }) => {
  const [showAll, setShowAll] = useState(false)
  const displayedPlays = showAll ? eligiblePlayItems : eligiblePlayItems.slice(0, 3)
  const hasMorePlays = eligiblePlayItems.length > 3

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2} mb={4}>
        <Center boxSize={10} bg="purple.50" borderRadius="md">
          <Icon as={IconTarget} color="purple.500" boxSize={5} />
        </Center>

        <Heading size="md" fontWeight="semibold">
          Eligible Plays
        </Heading>
      </Box>
      <VStack spacing={0} align="stretch" pl="4">
        {displayedPlays.map((play) => (
          <Stack
            key={play.id}
            fontSize="sm"
            borderLeft="2px solid"
            borderColor="purple.50"
            pl={8}
            pt="2"
            pb="2"
            spacing={6}
          >
            <EligiblePlayCard play={play} />
          </Stack>
        ))}
      </VStack>
      {hasMorePlays && (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setShowAll(!showAll)}
          mt={4}
          leftIcon={<Icon as={showAll ? IconChevronUp : IconChevronDown} />}
        >
          {showAll ? 'Show less' : `Show ${eligiblePlayItems.length - 3} more plays`}
        </Button>
      )}
    </Box>
  )
}

export const ProspectDetail: React.FC<ProspectDetailProps> = ({ playItem, apps }) => {
  const { loading, recommendations, error } = useProspectRecommendations(playItem.id)
  const { data: sessionRecommendations, isLoading: sessionRecommendationsLoading } = usePlayItemAIRecommendations(
    playItem.id,
    {
      recommendationType: 'profile_session'
    }
  )

  const { data: accountRecommendations, isLoading: accountRecommendationsLoading } = usePlayItemAIRecommendations(
    playItem.id,
    {
      recommendationType: 'account_session'
    }
  )

  const crmStages = useMemo(() => recommendations?.crm_data?.crm_stages ?? [], [recommendations])
  const hasOpp = useMemo(() => (crmStages?.length ?? 0) > 0, [crmStages])

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        Failed to load prospect recommendations
      </Alert>
    )
  }

  return (
    <VStack spacing={6} align="stretch" w="100%">
      <Stack as={Card} mt="4" spacing={4} p="4">
        <HStack px="2">
          <Skeleton isLoaded={!loading}>
            <HStack spacing={2}>
              <CompanyAvatar domain={playItem.record?.company?.domain} size="lg" borderRadius="lg" />
              <Heading size="lg" fontWeight="semibold">
                {playItem.record?.company?.name}
              </Heading>
            </HStack>
          </Skeleton>
          <SkeletonText isLoaded={!loading} noOfLines={1}>
            {hasOpp && (
              <Wrap>
                {crmStages.map((stage) => (
                  <Badge
                    key={JSON.stringify(stage)}
                    colorScheme={stage.open ? 'green' : stage.is_won ? 'blue' : 'gray'}
                  >
                    {stage.stage_name}
                  </Badge>
                ))}
              </Wrap>
            )}
            {!hasOpp && <Badge colorScheme="gray">No Open Opportunities</Badge>}
          </SkeletonText>
        </HStack>

        <HStack spacing={8} align="flex-start" justify="space-between" px="2">
          <InfoItem label="Account Owner" value={recommendations?.crm_data?.account_owner} isLoading={loading} />
          <InfoItem
            label="Employees"
            value={recommendations?.company_data?.metrics?.employeesRange}
            isLoading={loading}
          />
          <InfoItem label="Location" value={recommendations?.company_data?.location} isLoading={loading} />
          <InfoItem
            label="Revenue"
            value={recommendations?.company_data?.metrics?.estimatedAnnualRevenue}
            isLoading={loading}
          />
          <InfoItem label="Website" value={recommendations?.company_data?.domain} isLoading={loading} />
          <Box>
            <Text color="gray.600" fontSize="sm">
              Links
            </Text>
            <Skeleton isLoaded={!loading}>
              <HStack gap={2}>
                {recommendations?.company_data?.linkedin_url && (
                  <Link href={recommendations?.company_data?.linkedin_url} isExternal>
                    <Icon as={LinkedinBoxIcon} boxSize={5} color="linkedin.500" />
                  </Link>
                )}
                {recommendations?.crm_data?.permalink && (
                  <Link href={recommendations?.crm_data?.permalink} isExternal>
                    <Icon
                      as={recommendations?.crm_data?.crm === 'salesforce' ? SalesforceIcon : HubSpotIcon}
                      boxSize={5}
                      color={recommendations?.crm_data?.crm === 'salesforce' ? 'salesforce.500' : 'orange.500'}
                    />
                  </Link>
                )}
              </HStack>
            </Skeleton>
          </Box>
        </HStack>
      </Stack>

      <Stack as={Card} p="6">
        <SessionSummary
          account_report={accountRecommendations?.recommendations?.account_report}
          profile_report={sessionRecommendations?.recommendations?.profile_report}
          account_name={playItem.record?.company?.name}
          prospect_name={playItem.record?.display_name}
          profileLoading={sessionRecommendationsLoading}
          accountLoading={accountRecommendationsLoading}
        />
      </Stack>

      {recommendations?.eligible_plays && recommendations?.eligible_plays.length > 0 && (
        <Stack as={Card} p="6">
          <EligiblePlaysCard eligiblePlayItems={recommendations?.eligible_plays} />
        </Stack>
      )}

      <Skeleton isLoaded={!accountRecommendationsLoading}>
        {accountRecommendations?.recommendations?.account_report && (
          <KeyPeople people={accountRecommendations?.recommendations?.account_report?.users || []} apps={apps} />
        )}
      </Skeleton>

      <Data recommendations={recommendations ?? undefined} loading={loading} apps={apps} />
    </VStack>
  )
}
