import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useColorModeValue,
  Spinner,
  Center,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  TableContainer
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { format as formatNumber } from 'friendly-numbers'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip as RechartsTooltip, XAxis, YAxis } from 'recharts'
import dayjs from 'dayjs'
import { Card } from '@app/components/ui/Card'
import { get } from '@app/lib/api'
import { projectPath } from '@app/components/ui/ProjectsContext'
import { MonthlyPlanSummary } from './MonthlyPlanSummary'
import { IconCalendar, IconChevronDown, IconCreditCard, IconMail, IconFileInvoice } from '@tabler/icons-react'
import { useCurrentUser } from '@app/components/ui/UserContext'

interface CreditBalance {
  granted_credits: number
  used_credits: number
  remaining_credits: number
  expires_at: string
  granted_at: string
}

interface OperationUsage {
  units: number
  credits_per_unit: number
  total_credits: number
}

interface DailyUsage {
  date: string
  credits_used: string
}

interface PlayDailyUsage {
  date: string
  play_name: string
  credits_used: string
}

interface CreditReportData {
  credit_balance: CreditBalance
  usage_by_operation: Record<string, OperationUsage>
  historical_usage: DailyUsage[]
  historical_usage_by_play: PlayDailyUsage[]
}

interface KoalaSubscription {
  plan: string
  entitlements: {
    members: number
  }
  features: {
    seats: number
  }
}

// Define operation names and colors
const OperationNames: Record<string, string> = {
  ai_agent_run: 'AI Agent',
  email_writer_run: 'Email Writer',
  clearbit_reveal: 'IP Enrichment',
  email_waterfall_enrichment: 'Email Enrichment',
  phone_waterfall_enrichment: 'Phone Enrichment',
  linkedin_prospect: 'AI Research',
  email_verification: 'Email Verification'
}

const OperationColors: Record<string, string> = {
  ai_agent_run: '#9F7AEA', // More vibrant purple
  email_writer_run: '#3182CE', // More vibrant blue
  clearbit_reveal: '#38A169', // More vibrant green
  email_waterfall_enrichment: '#00B5D8', // More vibrant cyan
  phone_waterfall_enrichment: '#E53E3E', // More vibrant red
  linkedin_prospect: '#F6AD55', // More vibrant orange
  email_verification: '#4A5568' // Darker gray for better contrast
}

export function CreditReport() {
  const user = useCurrentUser()

  // Return null if user is not internal
  if (!user.isInternalUser) {
    return null
  }

  const [reportData, setReportData] = useState<CreditReportData | null>(null)
  const [subscriptionData, setSubscriptionData] = useState<KoalaSubscription | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [dateRange, setDateRange] = useState({
    start: dayjs().subtract(30, 'day').startOf('day'),
    end: dayjs().endOf('day')
  })

  const tooltipBgColor = useColorModeValue('white', 'gray.700')

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        setError(null)

        const url = projectPath('settings/billing/credit-report.json')
        const params = new URLSearchParams({
          start_date: dateRange.start.toISOString().split('T')[0],
          end_date: dateRange.end.toISOString().split('T')[0]
        })

        const response = await get<{ credit_report: CreditReportData; koala_subscription: KoalaSubscription }>(
          `${url}?${params}`
        )
        setReportData(response.credit_report)
        setSubscriptionData(response.koala_subscription)
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred')
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [dateRange])

  if (isLoading) {
    return (
      <Center p={6} h="250px">
        <VStack spacing={3}>
          <Spinner thickness="3px" speed="0.65s" emptyColor="gray.200" color="purple.500" size="md" />
          <Text fontSize="xs" color="gray.600">
            Loading credit report...
          </Text>
        </VStack>
      </Center>
    )
  }

  if (error) {
    return (
      <Box p={3} color="red.500" fontSize="sm">
        Error: {error}
      </Box>
    )
  }

  if (!reportData) {
    return (
      <Box p={3} borderWidth="1px" borderRadius="md" borderColor="gray.200">
        <Heading size="xs" mb={1}>
          No Credit Data Available
        </Heading>
        <Text fontSize="xs">
          Your plan may not include enrichment credits, or there might be an issue retrieving your credit information.
          Please contact support if you believe this is incorrect.
        </Text>
      </Box>
    )
  }

  // Sort operations by credit usage (highest first)
  const sortedOperations = Object.entries(reportData.usage_by_operation).sort(
    ([, a], [, b]) => b.total_credits - a.total_credits
  )

  // Transform data for stacked bar chart
  const prepareStackedChartData = () => {
    // Create a map of dates and their corresponding operations
    const dateMap: Record<string, Record<string, any>> = {}

    // Initialize with all dates in the range and convert credits_used to number
    reportData.historical_usage.forEach((item) => {
      const formattedDate = dayjs(item.date).format('MMM D')
      dateMap[formattedDate] = {
        date: formattedDate,
        total: parseFloat(item.credits_used),
        value: parseFloat(item.credits_used) // Adding this for the bar chart to use
      }
    })

    // For each operation type, distribute the credits across days proportionally
    Object.entries(reportData.usage_by_operation).forEach(([operation, usage]) => {
      const totalCredits = usage.total_credits
      const totalHistoricalCredits = reportData.historical_usage.reduce(
        (sum, item) => sum + parseFloat(item.credits_used),
        0
      )

      // Calculate the proportion of credits this operation represents
      const proportion = totalCredits / totalHistoricalCredits

      // Distribute the credits across days based on the proportion
      reportData.historical_usage.forEach((item) => {
        const formattedDate = dayjs(item.date).format('MMM D')
        dateMap[formattedDate][operation] = parseFloat(item.credits_used) * proportion
      })
    })

    // Convert the map to an array and sort by date
    return Object.values(dateMap).sort((a, b) => dayjs(a.date, 'MMM D').diff(dayjs(b.date, 'MMM D')))
  }

  const stackedChartData = prepareStackedChartData()

  // Calculate top sources
  const calculateTopSources = () => {
    return sortedOperations.slice(0, 3).map(([op, usage]) => ({
      name: OperationNames[op] || op,
      credits: usage.total_credits,
      color: OperationColors[op]
    }))
  }

  const topSources = calculateTopSources()

  // Format date range for display
  const formattedDateRange = `${dayjs(dateRange.start).format('MMM D, YYYY')} - ${dayjs(dateRange.end).format('MMM D, YYYY')}`

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box bg={tooltipBgColor} p={2} borderRadius="md" boxShadow="md" border="1px solid" borderColor="gray.200">
          <Text fontWeight="bold" mb={1} fontSize="xs">
            {label}
          </Text>
          <VStack align="start" spacing={0.5}>
            {payload.map(
              (entry: any) =>
                entry.value > 0 && (
                  <Flex key={entry.dataKey} w="100%" justify="space-between" align="center">
                    <HStack>
                      <Box w="8px" h="8px" bg={entry.fill} borderRadius="full" />
                      <Text fontSize="xs">{OperationNames[entry.dataKey] || entry.dataKey}</Text>
                    </HStack>
                    <Text fontSize="xs" fontWeight="medium">
                      {formatNumber(entry.value)} credits
                    </Text>
                  </Flex>
                )
            )}
            <Flex w="100%" justify="space-between" pt={1} borderTopWidth="1px" mt={1}>
              <Text fontSize="xs" fontWeight="bold">
                Total
              </Text>
              <Text fontSize="xs" fontWeight="bold">
                {formatNumber(payload.reduce((sum: number, item: any) => sum + (item.value || 0), 0))} credits
              </Text>
            </Flex>
          </VStack>
        </Box>
      )
    }
    return null
  }

  // Add preparePlayChartData function
  const preparePlayChartData = () => {
    // Create a map of dates and their corresponding plays
    const dateMap: Record<string, Record<string, any>> = {}

    // Initialize with all dates in the range
    reportData.historical_usage.forEach((item) => {
      const formattedDate = dayjs(item.date).format('MMM D')
      dateMap[formattedDate] = {
        date: formattedDate,
        total: parseFloat(item.credits_used),
        value: parseFloat(item.credits_used)
      }
    })

    // Group play data by date
    reportData.historical_usage_by_play.forEach((item) => {
      const formattedDate = dayjs(item.date).format('MMM D')
      if (!dateMap[formattedDate]) {
        dateMap[formattedDate] = {
          date: formattedDate,
          total: 0,
          value: 0
        }
      }
      dateMap[formattedDate][item.play_name] = parseFloat(item.credits_used)
    })

    // Convert the map to an array and sort by date
    return Object.values(dateMap).sort((a, b) => dayjs(a.date, 'MMM D').diff(dayjs(b.date, 'MMM D')))
  }

  // Add function to calculate top plays
  const calculateTopPlays = () => {
    const playTotals: Record<string, number> = {}

    reportData.historical_usage_by_play.forEach((item) => {
      if (!playTotals[item.play_name]) {
        playTotals[item.play_name] = 0
      }
      playTotals[item.play_name] += parseFloat(item.credits_used)
    })

    return Object.entries(playTotals)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3)
      .map(([name, credits]) => ({
        name,
        credits,
        color:
          OperationColors[
            Object.keys(OperationColors)[Object.keys(playTotals).indexOf(name) % Object.keys(OperationColors).length]
          ]
      }))
  }

  return (
    <VStack spacing={3} align="stretch" width="100%">
      {/* Monthly Plan Summary */}
      {reportData?.credit_balance && subscriptionData && (
        <MonthlyPlanSummary
          plan={subscriptionData.plan}
          creditPackPrice={500}
          extraLicensesPrice={300}
          includedLicenses={subscriptionData.entitlements.members}
          extraLicenses={Math.max(0, subscriptionData.features.seats - subscriptionData.entitlements.members)}
          licensePrice={75}
          totalCredits={reportData.credit_balance.remaining_credits}
          usedCredits={reportData.credit_balance.used_credits}
          maxCredits={reportData.credit_balance.granted_credits}
          creditsRefreshDate={reportData.credit_balance.expires_at}
          totalLicenses={subscriptionData.features.seats}
          maxLicenses={subscriptionData.entitlements.members}
        />
      )}

      <Card>
        <VStack align="stretch" spacing={3} width="100%">
          {/* Header with date range and filter */}
          <Flex justify="space-between" align="center" w="100%">
            <HStack spacing={2}>
              <Icon as={IconCalendar} boxSize="0.9em" />
              <Heading size="sm">Credit Usage</Heading>
            </HStack>

            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<IconChevronDown size={14} />}
                variant="outline"
                size="sm"
                fontSize="xs"
              >
                {formattedDateRange}
              </MenuButton>
              <MenuList fontSize="xs">
                <MenuItem
                  onClick={() =>
                    setDateRange({ start: dayjs().subtract(30, 'day').startOf('day'), end: dayjs().endOf('day') })
                  }
                >
                  Last 30 Days
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    setDateRange({ start: dayjs().subtract(90, 'day').startOf('day'), end: dayjs().endOf('day') })
                  }
                >
                  Last 90 Days
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    setDateRange({ start: dayjs().subtract(180, 'day').startOf('day'), end: dayjs().endOf('day') })
                  }
                >
                  Last 180 Days
                </MenuItem>
                <MenuItem onClick={() => setDateRange({ start: dayjs().startOf('year'), end: dayjs().endOf('day') })}>
                  Year to Date
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          {/* Tabs for source/play view */}
          <Tabs variant="line" colorScheme="purple" size="sm">
            <TabList>
              <Tab fontSize="xs">By Source</Tab>
              <Tab fontSize="xs">By Play</Tab>
            </TabList>

            <TabPanels>
              {/* By Source Tab */}
              <TabPanel p={0} pt={2}>
                <Box height="250px">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={stackedChartData} margin={{ top: 10, right: 15, left: 0, bottom: 10 }}>
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="date" tick={{ fontSize: 10 }} />
                      <YAxis
                        tick={{ fontSize: 10 }}
                        tickFormatter={(value) => formatNumber(value)}
                        domain={[0, 'auto']} // This ensures the chart scales properly
                      />
                      <RechartsTooltip content={<CustomTooltip />} />

                      {/* First add the total bar */}
                      <Bar dataKey="value" fill="#E2E8F0" stackId="a" />

                      {/* Then add the operation-specific bars */}
                      {Object.keys(OperationNames).map((op) => (
                        <Bar key={op} dataKey={op} stackId="a" fill={OperationColors[op]} />
                      ))}
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                {/* Top Sources */}
                <Box pt={4}>
                  <Heading size="xs" mb={2}>
                    Top Sources
                  </Heading>
                  <VStack spacing={2} align="stretch">
                    {topSources.map((source, index) => (
                      <Flex
                        key={index}
                        p={2}
                        bg={`${source.color}20`}
                        borderRadius="md"
                        justify="space-between"
                        align="center"
                      >
                        <HStack>
                          <Box w="8px" h="8px" borderRadius="full" bg={source.color} />
                          <Text fontSize="xs" fontWeight="medium">
                            {source.name}
                          </Text>
                        </HStack>
                        <Text fontSize="xs" fontWeight="bold">
                          {formatNumber(source.credits)} credits
                        </Text>
                      </Flex>
                    ))}
                  </VStack>
                </Box>
              </TabPanel>

              {/* By Play Tab - Similar structure as Source tab */}
              <TabPanel p={0} pt={2}>
                <Box height="250px">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={preparePlayChartData()} margin={{ top: 10, right: 15, left: 0, bottom: 10 }}>
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="date" tick={{ fontSize: 10 }} />
                      <YAxis
                        tick={{ fontSize: 10 }}
                        tickFormatter={(value) => formatNumber(value)}
                        domain={[0, 'auto']}
                      />
                      <RechartsTooltip content={<CustomTooltip />} />

                      {/* Remove the value bar and only show play-specific bars */}
                      {reportData.historical_usage_by_play
                        .map((item) => item.play_name)
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .map((playName, index) => (
                          <Bar
                            key={playName}
                            dataKey={playName}
                            stackId="a"
                            fill={Object.values(OperationColors)[index % Object.keys(OperationColors).length]}
                            name={playName} // Add name prop for tooltip
                          />
                        ))}
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                {/* Top Plays */}
                <Box pt={4}>
                  <Heading size="xs" mb={2}>
                    Top Plays
                  </Heading>
                  <VStack spacing={2} align="stretch">
                    {calculateTopPlays().map((play, index) => (
                      <Flex
                        key={index}
                        p={2}
                        bg={`${play.color}20`}
                        borderRadius="md"
                        justify="space-between"
                        align="center"
                      >
                        <HStack>
                          <Box w="8px" h="8px" borderRadius="full" bg={play.color} />
                          <Text fontSize="xs" fontWeight="medium">
                            {play.name}
                          </Text>
                        </HStack>
                        <Text fontSize="xs" fontWeight="bold">
                          {formatNumber(play.credits)} credits
                        </Text>
                      </Flex>
                    ))}
                  </VStack>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Card>

      {/* Billing Details Section */}
      <Card p={4}>
        {/* <Flex justify="space-between" align="center" w="100%" mb={3}>
          <Heading size="sm">Billing Details</Heading>
          <HStack spacing={2}>
            <Button leftIcon={<IconCreditCard size={14} />} variant="outline" size="sm" fontSize="xs">
              Update card
            </Button>
            <Button leftIcon={<IconMail size={14} />} variant="outline" size="sm" fontSize="xs">
              Update email
            </Button>
          </HStack>
        </Flex> */}

        <VStack align="stretch" spacing={3}>
          {/* Next Payment */}
          <Box>
            <Text color="gray.500" fontSize="xs">
              Next Payment
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              ${(reportData.credit_balance.granted_credits * 0.01).toFixed(2)} on{' '}
              {dayjs(reportData.credit_balance.expires_at).format('MMM D, YYYY')}
            </Text>
          </Box>

          {/* Billing Email */}
          <Box>
            <Text color="gray.500" fontSize="xs">
              Billing Email at
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              {/* format date to be like 2024-03-31 12:00:00 */}
              {dayjs(reportData.credit_balance.granted_at).format('MMM D, YYYY')}
            </Text>
          </Box>

          {/* Past Invoices */}
          {/* <Box mt={2}>
            <HStack mb={2}>
              <Icon as={IconFileInvoice} color="gray.500" boxSize="0.9em" />
              <Heading size="xs">Past Invoices</Heading>
            </HStack>

            <TableContainer borderWidth="1px" borderRadius="md">
              <Table variant="simple" size="sm">
                <Thead bg="gray.50">
                  <Tr>
                    <Th fontSize="xs">DATE</Th>
                    <Th fontSize="xs">AMOUNT</Th>
                    <Th fontSize="xs">STATUS</Th>
                    <Th fontSize="xs">DOWNLOAD</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td fontSize="xs">{dayjs(reportData.credit_balance.granted_at).format('MMM D, YYYY')}</Td>
                    <Td fontSize="xs">${(reportData.credit_balance.granted_credits * 0.01).toFixed(2)}</Td>
                    <Td>
                      <Badge colorScheme="green" px={1.5} py={0.5} borderRadius="full" fontSize="2xs">
                        Paid
                      </Badge>
                    </Td>
                    <Td>
                      <Link href="#" color="purple.500" fontWeight="medium" fontSize="xs">
                        Download
                      </Link>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Box> */}
        </VStack>
      </Card>
    </VStack>
  )
}
