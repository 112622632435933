import {
  Box,
  Popover,
  PopoverArrow,
  PopoverArrowProps,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'

interface Props extends React.PropsWithChildren<PopoverProps> {
  hoverContent: React.ReactNode
  isPortal?: boolean
  popoverContentProps?: PopoverContentProps
  arrowProps?: PopoverArrowProps
  hasArrow?: boolean
  hasCloseButton?: boolean
}

export const HoverCard: React.FunctionComponent<Props> = (props) => {
  const { children, hoverContent, hasArrow = true, hasCloseButton = false, ...rest } = props
  const PortalTag = props.isPortal ? Portal : React.Fragment
  const disclosure = useDisclosure(props)

  return (
    <Popover matchWidth lazyBehavior="unmount" isLazy {...rest} {...disclosure}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PortalTag>
        <PopoverContent
          minW={{ base: '100%', lg: 'max-content' }}
          maxH={window.innerHeight * 0.45}
          {...props.popoverContentProps}
        >
          {hasArrow && <PopoverArrow {...props.arrowProps} />}
          {hasCloseButton && <PopoverCloseButton />}
          <PopoverBody bg="transparent" px={0} overflowY="auto">
            <Box px={3}>{hoverContent}</Box>
          </PopoverBody>
        </PopoverContent>
      </PortalTag>
    </Popover>
  )
}
