import { UserAppInstance } from '@type/UserAppInstance'
import {
  Badge,
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Portal,
  Spinner,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconAdjustmentsHorizontal,
  IconCalendar,
  IconCheck,
  IconChevronDown,
  IconChevronRight,
  IconDotsVertical,
  IconFlare,
  IconMailSpark,
  IconX
} from '@tabler/icons-react'
import { motion } from 'framer-motion'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { toast } from 'sonner'
import useLocalStorageState from 'use-local-storage-state'
import { patch } from '@lib/api'
import dayjs, { greeting } from '@lib/dayjs'
import router from '@lib/router'
import { Apps } from '@type/App'
import { InboxItem } from '@type/Inbox'
import { Play } from '@type/Play'
import Avatar from '@ui/Avatar'
import { Card } from '@ui/Card'
import CircleIcon from '@ui/CircleIcon'
import CompanyAvatar from '@ui/CompanyAvatar'
import PageLayout from '@ui/PageLayout'
import PageTitle, { SmallPageHeading } from '@ui/PageTitle'
import { usePermission } from '@ui/PermissionsContext'
import { projectPath } from '@ui/ProjectsContext'
import { SegmentedControl } from '@ui/SegmentedControl'
import { TextEllipsis } from '@ui/text-ellipsis'
import { TimeAgo } from '@ui/TimeAgo'
import { TopBarContent } from '@ui/TopBarContext'
import { useCurrentUser, User } from '@ui/UserContext'
import { UserSelector } from '@ui/UserSelector'
import { useSearchParams } from '@ui/useSearchState'
import useUpdateEffect from '@ui/useUpdateEffect'
import NoPixel from '../accounts/components/empty-states/no-pixel.svg'
import { mergeParams } from '../icps/types'
import { signalTypes } from '../kql_definitions/components/triggers'
import { ProspectBadge, WarmLeadBadge } from './components/Badges'
import { PlayCard } from './components/PlayCard'
import { ReassignModal } from './components/ReassignModal'
import { SelectedItemPanel } from './components/SelectedItem'
import { useQueryClient } from '@tanstack/react-query'

interface WeekDay {
  date: string
  day_name: string
  future: boolean
  today: boolean
  completed: number
  dismissed: number
  total: number
  has_activity: boolean
}

interface Props {
  plays: Play[]
  plays_with_pending: Play[]
  selected_user?: User
  apps: Apps
  total?: number
  pending?: number
  range?: 'day' | 'week' | 'all'
  streak?: {
    current_streak: number
    last_action_date: string
    current_week: WeekDay[]
  }
  has_worked_items?: boolean
}

export default function Inbox(props: Props) {
  const currentUser = useCurrentUser()
  const range = props.range
  const [streak, setStreak] = useState(props.streak)
  const apps = useMemo(() => Object.values(props.apps ?? {}), [props.apps])
  const [selectedUser, setSelectedUser] = useState<Partial<User> | null>(props.selected_user || null)
  const { hasPermission: canViewAsMember } = usePermission({ on: 'project', action: 'can_view_as_member' })

  const [hasSeenIntro, setHasSeenIntro] = useLocalStorageState('koala:inbox-intro', {
    defaultValue: false
  })

  const handleCloseIntro = useCallback(() => {
    setHasSeenIntro(true)
  }, [setHasSeenIntro])

  const isUnassigned = useMemo(() => window.location.pathname.endsWith('/unassigned'), [])

  const queryClient = useQueryClient()

  const handleAction = useCallback(
    (status: string) => {
      if (currentUser?.id !== selectedUser?.id) return

      // update the current day streak!
      setStreak((prev) => {
        if (!prev) return prev

        const today = dayjs().format('YYYY-MM-DD')

        // only update the streak if the last action was not already from today!
        const current = prev.last_action_date === today ? prev.current_streak : (prev.current_streak || 0) + 1

        return {
          ...prev,
          current_week: prev.current_week.map((day) => {
            if (day.date === today) {
              if (status === 'completed') {
                return { ...day, has_activity: true, completed: day.completed + 1, total: day.total + 1 }
              } else if (status === 'dismissed') {
                return { ...day, has_activity: true, dismissed: day.dismissed + 1, total: day.total + 1 }
              }
            }
            return day
          }),
          current_streak: current,
          last_action_date: today
        }
      })
      // Invalidate the inbox query to refresh the badge count
      queryClient.invalidateQueries({ queryKey: ['inbox'] }) // This will invalidate all inbox queries
    },
    [currentUser?.id, selectedUser?.id, queryClient]
  )

  useUpdateEffect(() => {
    const user = selectedUser && selectedUser.id !== currentUser.id ? selectedUser : undefined
    const email = user?.email

    if (email !== props.selected_user?.email) {
      const path = projectPath(`/inbox/${email || ''}`)
      router.visit(path)
    }
  }, [selectedUser, currentUser.id, props.selected_user?.email])

  const [isLoading, setIsLoading] = useState<Record<string, boolean>>(
    // start with all plays as loading
    props.plays_with_pending.reduce(
      (acc, play) => {
        acc[play.id] = true
        return acc
      },
      {} as Record<string, boolean>
    )
  )

  const onPlayLoading = useCallback((playId: string, isLoading: boolean) => {
    setIsLoading((prev) => ({
      ...prev,
      [playId]: isLoading
    }))
  }, [])

  const anyLoading = useMemo(() => {
    return Object.values(isLoading).some((loading) => loading)
  }, [isLoading])

  const totalAssigned = props.pending || 0

  return (
    <PageLayout size="full" flush gap={0} bg="white" maxH="100%" minH="300px">
      <PageTitle skipRendering>Inbox</PageTitle>
      <TopBarContent>
        <Flex width="100%" alignItems="center" gap={3} justifyContent="space-between">
          {canViewAsMember ? (
            <Tabs size="sm" variant="subtle" isManual defaultIndex={isUnassigned ? 2 : 1}>
              <TabList>
                <Tab as="a" href={projectPath('/inbox/dashboard')}>
                  Dashboard
                </Tab>
                <Tab as="a" href={projectPath('/inbox')}>
                  Inbox
                </Tab>
                <Tab as="a" href={projectPath('/inbox/unassigned')}>
                  Unassigned
                </Tab>
              </TabList>
            </Tabs>
          ) : (
            <SmallPageHeading>{isUnassigned ? 'Unassigned Inbox' : 'Inbox'}</SmallPageHeading>
          )}

          {canViewAsMember && (
            <Flex flex="none" alignItems="center" gap={2}>
              <Flex alignItems="center" gap={1}>
                <Text fontSize="sm" color="gray.600">
                  View as
                </Text>
                <UserSelector
                  selectedUserId={selectedUser?.id || currentUser!.id}
                  onChange={(_userId, user) => setSelectedUser(user as Partial<User>)}
                  isReadOnly={!canViewAsMember}
                />
              </Flex>

              <IconButton
                aria-label="Settings"
                icon={<IconAdjustmentsHorizontal size={16} />}
                variant="outline"
                size="sm"
                as={Link}
                href={projectPath('/settings/ai-instructions')}
              />
            </Flex>
          )}
        </Flex>
      </TopBarContent>

      <Flex height="100%">
        <Stack padding={6} spacing={8} flex="1" overflow="auto">
          <Flex width="100%" alignItems="center" gap={3} justifyContent="space-between">
            <Stack spacing={0}>
              <Text fontSize="sm" fontWeight="semibold">
                {greeting()}, {props.selected_user?.first_name || currentUser.firstName} 👋
              </Text>
              {totalAssigned > 0 ? (
                <Text fontSize="sm" color="gray.500">
                  We've found {totalAssigned.toLocaleString()}{' '}
                  {pluralize(isUnassigned ? 'unassigned lead' : 'lead', totalAssigned)} for you to review.{' '}
                  <StreakEncouragement streak={streak} pendingCount={totalAssigned} />
                </Text>
              ) : (
                <Text fontSize="sm" color="gray.500">
                  All caught up! <StreakEncouragement streak={streak} pendingCount={totalAssigned} />
                </Text>
              )}
              <CurrentWeekStreak streak={streak} />
            </Stack>

            <SegmentedControl size="sm">
              <Button
                isActive={range === 'day'}
                as={Link}
                href={mergeParams(window.location.toString(), { range: 'day' })}
              >
                Today
              </Button>
              <Button
                isActive={range === 'week'}
                as={Link}
                href={mergeParams(window.location.toString(), { range: 'week' })}
              >
                Last week
              </Button>
              <Button
                isActive={range === 'all'}
                as={Link}
                href={mergeParams(window.location.toString(), { range: 'all' })}
              >
                All time
              </Button>
            </SegmentedControl>
          </Flex>
          <Stack spacing={6}>
            {props.plays_with_pending.map((play) => (
              <PlayCard
                key={play.id}
                apps={apps}
                play={play}
                selectedUser={isUnassigned ? { id: 'unassigned' } : selectedUser}
                range={range}
                onAction={handleAction}
                onLoading={onPlayLoading}
              />
            ))}

            {props.plays_with_pending.length === 0 && !anyLoading && <InboxEmptyState />}

            {anyLoading && (
              <Flex justifyContent="center" alignItems="center" height="100%">
                <Spinner size="md" thickness="1.5px" color="gray.400" />
              </Flex>
            )}
          </Stack>
        </Stack>
      </Flex>

      <Modal
        // only show the intro if the user has not seen it and they have worked items
        isOpen={!hasSeenIntro && !props.has_worked_items}
        onClose={handleCloseIntro}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Center aspectRatio={16 / 9}>
              <Image src={NoPixel} maxW="600" />
            </Center>
            <Stack spacing={4}>
              <Heading size="sm">Welcome to Your Inbox! 👋</Heading>
              <Text fontSize="sm" color="gray.600">
                This is your personalized inbox where Koala surfaces the most relevant leads assigned to you from all of
                your plays. You can create new plays to try out new GTM strategies and add them to your inbox.
              </Text>
              <Stack fontSize="sm" spacing={2} color="gray.600">
                <Flex gap={2.5} alignItems="center">
                  <CircleIcon icon={IconCheck} colorScheme="gray" iconSize={4} padding={1.5} />
                  <Text>Review and take action on your assigned leads</Text>
                </Flex>
                <Flex gap={2.5} alignItems="center">
                  <CircleIcon icon={IconCalendar} colorScheme="gray" iconSize={4} padding={1.5} />
                  <Text>Filter by time range to see the most recent leads</Text>
                </Flex>
                <Flex gap={2.5} alignItems="center">
                  <CircleIcon icon={IconFlare} colorScheme="gray" iconSize={4} padding={1.5} />
                  <Text>
                    Use AI Research Agents to qualify leads and automate account research relevant to each play
                  </Text>
                </Flex>
                <Flex gap={2.5} alignItems="center">
                  <CircleIcon icon={IconMailSpark} colorScheme="gray" iconSize={4} padding={1.5} />
                  <Text>Create personalized signal-based outreach using Koala's AI composer</Text>
                </Flex>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <Button size="md" width="full" colorScheme="purple" onClick={handleCloseIntro}>
              Get Started
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </PageLayout>
  )
}

function getSignalColorScheme(signalType: string) {
  return signalTypes.find((s) => s.kind === signalType)?.colorScheme
}

function groupItemsBySignalAndCompany(items: any[]): Record<string, Record<string, any[]>> {
  return items.reduce(
    (acc, item) => {
      // Get the primary signal or 'no-signal' if none exists
      const signalKey = item.signals?.[0]?.name || 'Prospected'
      const companyId = item.target?.company?.id || 'Other'

      // Initialize signal group if it doesn't exist
      if (!acc[signalKey]) {
        acc[signalKey] = {}
      }

      // Initialize company group within signal if it doesn't exist
      if (!acc[signalKey][companyId]) {
        acc[signalKey][companyId] = []
      }

      acc[signalKey][companyId].push(item)
      return acc
    },
    {} as Record<string, Record<string, any[]>>
  )
}

const hoverProps = { bg: 'background.light' }

interface InboxItemGroupsProps {
  inboxItems: InboxItem[]
  setInboxItems: React.Dispatch<React.SetStateAction<InboxItem[]>>
  selectedUser: Partial<User> | null
  apps: UserAppInstance[]
}

function _InboxItemGroups({ inboxItems, setInboxItems, selectedUser }: InboxItemGroupsProps) {
  const { searchParams } = useSearchParams()
  const isUnassigned = useMemo(() => window.location.pathname.endsWith('/unassigned'), [])

  const currentUser = useCurrentUser()

  const [selectedItem, setSelectedItem] = useState(
    inboxItems.find((item) => item.id === searchParams.lead_id) || inboxItems[0] || null
  )

  const [itemsToReassign, setItemsToReassign] = useState<InboxItem[]>([])

  const groupedBySignalAndCompany = useMemo(() => groupItemsBySignalAndCompany(inboxItems), [inboxItems])
  const itemsInGroupOrder = useMemo(
    () => Object.values(groupedBySignalAndCompany).flatMap((companies) => Object.values(companies).flat()),
    [groupedBySignalAndCompany]
  )

  const handleSelectItem = useCallback(
    (item: any) => {
      setSelectedItem(item)

      if (item?.id && item.user_id === currentUser?.id) {
        patch(projectPath(`/inbox-items/${item.id}/update_last_read`), {})
      }
    },
    [setSelectedItem, currentUser]
  )

  const onPrevious = useCallback(() => {
    const index = itemsInGroupOrder.findIndex((item) => item.id === selectedItem?.id)
    const previous = itemsInGroupOrder[index - 1]
    if (previous) {
      handleSelectItem(previous)
    }
  }, [itemsInGroupOrder, selectedItem, handleSelectItem])

  const onNext = useCallback(() => {
    const index = itemsInGroupOrder.findIndex((item) => item.id === selectedItem?.id)
    const next = itemsInGroupOrder[index + 1]
    if (next) {
      handleSelectItem(next)
    }
  }, [itemsInGroupOrder, selectedItem, handleSelectItem])

  const hasPrevious = useMemo(() => {
    return itemsInGroupOrder.findIndex((item) => item.id === selectedItem?.id) > 0
  }, [itemsInGroupOrder, selectedItem])

  const hasNext = useMemo(() => {
    return itemsInGroupOrder.findIndex((item) => item.id === selectedItem?.id) < itemsInGroupOrder.length - 1
  }, [itemsInGroupOrder, selectedItem])

  const itemRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    if (selectedItem) {
      const itemRef = itemRefs.current[selectedItem.id] as undefined | HTMLDivElement
      if (itemRef) {
        if ((itemRef as any).scrollIntoViewIfNeeded) {
          // @ts-expect-error untyped
          itemRef.scrollIntoViewIfNeeded(false)
        } else {
          itemRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      }
    }
  }, [selectedItem])

  const actOnItem = useCallback(
    (item: any, direction: 'left' | 'right' | 'up') => {
      const actedId = item?.id
      const index = itemsInGroupOrder.findIndex((item) => item.id === actedId)

      // Determine the next selected item
      const nextItem = itemsInGroupOrder[index + 1] || itemsInGroupOrder[index - 1] || null

      const itemRef = itemRefs.current[actedId]
      if (itemRef) {
        const transformDirection = {
          left: 'translateX(-100%)',
          right: 'translateX(+100%)',
          up: 'translateY(-100%)'
        }
        // Apply animation styles
        itemRef.style.transition = 'background-color 0.3s ease, transform 0.5s ease, opacity 0.5s ease'
        itemRef.style.backgroundColor = '#e2e8f0'
        itemRef.style.transform = transformDirection[direction]
        itemRef.style.opacity = '0'
      }

      // Update the inbox items
      setTimeout(() => {
        setInboxItems((prev) => prev.filter((item) => item.id !== actedId))
        setTimeout(() => handleSelectItem(nextItem), 0)
      }, 500)
    },
    [itemsInGroupOrder, handleSelectItem, setInboxItems]
  )

  const onDismiss = useCallback(
    (item: any) => {
      actOnItem(item, 'left')
    },
    [actOnItem]
  )

  const onSnooze = useCallback(
    (item: any) => {
      actOnItem(item, 'up')
    },
    [actOnItem]
  )

  const onComplete = useCallback(
    (item: any) => {
      actOnItem(item, 'right')
    },
    [actOnItem]
  )

  const onReassign = useCallback((items: InboxItem[]) => {
    setItemsToReassign(items)
  }, [])

  useEffect(() => {
    if (selectedItem) {
      let path = projectPath('/inbox')
      path += isUnassigned ? '/unassigned' : `/${selectedUser?.email}`

      const url = mergeParams(path, {
        lead_id: selectedItem.id
      })

      router.visit(url, {
        fetch: false
      })
    }
  }, [selectedItem, selectedUser?.email, isUnassigned])

  const onShare = useCallback((item: any) => {
    const url = mergeParams(projectPath('/inbox'), {
      lead_id: item.id
    })
    navigator.clipboard.writeText(url)
    toast.success('Link copied to clipboard')
  }, [])

  // Add keyboard navigation
  useHotkeys('up,left,k', onPrevious, { filterPreventDefault: true }, [onPrevious])
  useHotkeys('down,right,j', onNext, { filterPreventDefault: true }, [onNext])

  return (
    <>
      {Object.entries(groupedBySignalAndCompany).map(([signalName, companies]) => (
        <InboxItemGroup
          key={signalName}
          signalName={signalName}
          companies={companies}
          selectedItem={selectedItem}
          itemRefs={itemRefs}
          handleSelectItem={handleSelectItem}
          onReassign={onReassign}
        />
      ))}
      {selectedItem && (
        <Flex alignItems="stretch" justifyContent="stretch" maxW="1080px" width="50%" minW="340px">
          <SelectedItemPanel
            key={selectedItem.id}
            item={selectedItem}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            onNext={onNext}
            onPrev={onPrevious}
            onDismiss={onDismiss}
            onSnooze={onSnooze}
            onComplete={onComplete}
            onShare={onShare}
          />
        </Flex>
      )}

      <ReassignModal
        isOpen={!!itemsToReassign?.length}
        onClose={() => setItemsToReassign([])}
        items={itemsToReassign}
        onReassign={(items) => {
          items.forEach((item) => onDismiss(item))
        }}
      />
    </>
  )
}

interface PlayInboxGroupProps {
  signalName: string
  companies: Record<string, InboxItem[]>
  selectedItem: InboxItem | null
  itemRefs: React.MutableRefObject<(HTMLDivElement | null)[]>
  handleSelectItem: (item: InboxItem) => void
  onReassign: (items: InboxItem[]) => void
}

function InboxItemGroup({
  signalName,
  companies,
  selectedItem,
  itemRefs,
  handleSelectItem,
  onReassign
}: PlayInboxGroupProps) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })
  const signalType = Object.values(companies)[0]?.[0]?.signals[0]?.signal_type

  return (
    <Card p={0}>
      <Box borderBottom="1px solid" borderColor="gray.200" pl={2} pr={3} py={2} onClick={onToggle} cursor="pointer">
        <Flex alignItems="center" gap={1}>
          <IconButton
            aria-label="Toggle section"
            variant="ghost"
            size="tiny"
            icon={<Icon as={isOpen ? IconChevronDown : IconChevronRight} boxSize={3.5} />}
          />
          <Badge
            variant="regular"
            rounded="full"
            px={2}
            py={0.5}
            fontSize="13px"
            colorScheme={getSignalColorScheme(signalType)}
            isTruncated
          >
            {signalName}
          </Badge>
        </Flex>
      </Box>

      <Collapse in={isOpen}>
        <Stack spacing={0} divider={<Divider />}>
          {Object.entries(companies).map(([_companyId, items], index, allItems) => {
            if (!items[0]?.target) return null

            return items.map((item) => (
              <Box
                key={item.id}
                ref={(el) => (itemRefs.current[item.id] = el)}
                shadow="sm"
                cursor="pointer"
                transition="all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                bg={selectedItem?.id === item.id ? 'gray.50' : 'white'}
                _hover={hoverProps}
                roundedBottom={index === allItems.length - 1 ? 'md' : undefined}
                onClick={() => handleSelectItem(item)}
              >
                <Flex px={3} py={2} alignItems="flex-start" gap={4}>
                  <Flex flex="1" alignItems="flex-start" gap={2}>
                    <Box flex="none" position="relative" p={1.5}>
                      <CompanyAvatar domain={item.target.company?.domain} size="36px" />
                      <Avatar
                        src={
                          item.target.image || item.target.koala_prospect?.id
                            ? projectPath(`/prospects/${item.target.koala_prospect.id}/avatar`)
                            : undefined
                        }
                        name={item.target.name || item.target.full_name || item.target.display_name}
                        size="xs"
                        position="absolute"
                        right={0}
                        bottom={0}
                        border="1.5px solid white"
                      />
                    </Box>
                    <Stack spacing={0}>
                      <Flex alignItems="center" gap={2}>
                        <TextEllipsis fontSize="sm" fontWeight="medium" maxW="100%" tooltip>
                          {item.target.name || item.target.full_name || item.target.display_name || item.target.email}
                        </TextEllipsis>

                        {item.target_type === 'Profile' && <WarmLeadBadge />}
                        {item.target_type === 'ProspectedProfile' && <ProspectBadge personas={item.context.personas} />}
                      </Flex>
                      <Text fontSize="13px" color="gray.500">
                        {[item.target.title, item.target.company?.name].filter(Boolean).join(' @ ')}
                      </Text>

                      {item.snoozed_at && (
                        <Text fontSize="13px" fontWeight="semibold" color="orange.700">
                          Snoozed <TimeAgo time={item.snoozed_at} canToggle={false} />
                        </Text>
                      )}
                    </Stack>
                  </Flex>

                  <Flex alignItems="center" gap={2} fontSize="13px">
                    <Text flex="none" fontSize="13px" color="gray.400">
                      <TimeAgo time={item.created_at} canToggle={false} />
                    </Text>

                    <Menu placement="bottom-end">
                      <MenuButton
                        as={IconButton}
                        size="xs"
                        variant="ghost"
                        aria-label="Actions"
                        icon={<IconDotsVertical size={14} />}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      />
                      <Portal>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              onReassign([item])
                            }}
                          >
                            Reassign…
                          </MenuItem>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Flex>
                </Flex>
              </Box>
            ))
          })}
        </Stack>
      </Collapse>
    </Card>
  )
}

function InboxEmptyState() {
  return (
    <Stack textAlign="center" bg="white" p="12" maxW="600" mx="auto" spacing="4" borderWidth="1px" rounded="xl">
      <Center>
        <Image src={NoPixel} maxW="600" />
      </Center>
      <Heading size="sm" fontWeight="semibold">
        No new leads yet
      </Heading>
      <Text color="gray.500" fontSize="sm">
        Check back later, Koala will automatically surface leads for all your plays.
      </Text>
    </Stack>
  )
}

function CurrentWeekStreak({ streak }: { streak: Props['streak'] }) {
  if (!streak) return null

  return (
    <Flex fontSize="10px" alignItems="center" gap={2} pt={2}>
      {streak.current_week.map((day) => (
        <DayCircle key={day.date} day={day} />
      ))}
    </Flex>
  )
}

const weekDayStyles = {
  activity: {
    bgGradient: `linear(to-b, purple.400, purple.500)`,
    color: 'white'
  },
  today: {
    border: '2px solid',
    borderColor: 'gray.600',
    color: 'gray.800'
  },
  future: {
    border: '2px solid',
    borderColor: 'gray.100',
    color: 'gray.400'
  },
  missed: {
    bgGradient: `linear(to-b, gray.300, gray.400)`,
    color: 'white'
  }
}

const MotionCenter = motion(Center)
const MotionIcon = motion(Icon)

function DayCircle({ day }: { day: WeekDay }) {
  const isFirstRender = useRef(true)
  const initialHasActivity = useRef(day.has_activity)
  const shouldAnimate = day.has_activity && !initialHasActivity.current && !isFirstRender.current

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    }

    initialHasActivity.current = day.has_activity
  }, [day.has_activity])

  const styles = day.has_activity
    ? weekDayStyles.activity
    : day.today
      ? weekDayStyles.today
      : day.future
        ? weekDayStyles.future
        : weekDayStyles.missed

  return (
    <Box position="relative">
      <Tooltip
        label={
          <Stack spacing={1} p={1}>
            <Text fontWeight="medium">{dayjs(day.date).format('dddd, MMM D')}</Text>
            {!day.future && (
              <>
                <Flex gap={2} alignItems="center">
                  <Icon as={IconCheck} color="green.400" />
                  <Text>{day.completed} completed</Text>
                </Flex>
                <Flex gap={2} alignItems="center">
                  <Icon as={IconX} color="red.400" />
                  <Text>{day.dismissed} dismissed</Text>
                </Flex>
              </>
            )}
          </Stack>
        }
        hasArrow
        placement="bottom"
      >
        <MotionCenter
          boxSize={6}
          borderRadius="full"
          {...styles}
          // When has_activity is true, animate the circle with a pop effect.
          initial={{ scale: 1 }}
          animate={shouldAnimate ? { scale: [1, 1.2, 1] } : { scale: 1 }}
          transition={{ duration: 0.4 }}
        >
          {day.has_activity ? (
            <MotionIcon
              as={IconCheck}
              boxSize={4}
              // Animate the check icon in with a bounce.
              initial={{ scale: shouldAnimate ? 0 : 1 }}
              animate={{ scale: 1 }}
              transition={
                shouldAnimate
                  ? {
                      delay: 0.2,
                      type: 'spring',
                      stiffness: 300,
                      damping: 20
                    }
                  : {}
              }
            />
          ) : (
            <Text fontWeight="semibold">{day.day_name[0]}</Text>
          )}
        </MotionCenter>
      </Tooltip>
    </Box>
  )
}

function StreakEncouragement({ streak, pendingCount }: { streak: Props['streak']; pendingCount: number }) {
  if (!streak) return null

  // If they've got a streak currently, encourage them to keep it up
  if (streak.current_streak) {
    // if they've already taken action today...
    if (streak.last_action_date === dayjs().format('YYYY-MM-DD')) {
      if (streak.current_streak === 1) {
        return <>Nice work today! 🔥</>
      } else {
        return <>Nice work maintaining your streak! 🔥</>
      }
    } else if (pendingCount > 0) {
      return <>Keep up your {streak.current_streak} day streak! 🔥</>
    }
  }

  // If they don't have a streak currently, encourage them to start one
  if (pendingCount > 0) {
    return <>Check out a few today!</>
  }

  return <>Check back later.</>
}
