import React from 'react'
import { Icon } from '@chakra-ui/react'
import { IconChartBar, IconDatabase, IconSearch, IconTarget, IconUsers } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { concurrentCachedGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import { OnboardingStep } from './use-onboarding-steps'

export interface Goal {
  id: string
  title: string
  description: string
  timeApproximation: number
  icon: React.ReactElement
  steps: OnboardingStep[]
}

export const GOALS_ICONS = {
  identify_signals: <Icon as={IconChartBar} />,
  engage_warm_leads: <Icon as={IconUsers} />,
  qualify_target_accounts: <Icon as={IconTarget} />,
  streamline_prospecting: <Icon as={IconSearch} />,
  automate_dataflow: <Icon as={IconDatabase} />
}

export function useOnboardingGoals() {
  const project = useCurrentProject()
  const basePath = '/setup/goals.json'

  const path = projectPath(basePath)

  return useQuery<Goal[]>({
    queryKey: ['onboarding-goals', { projectId: project?.id }],
    queryFn: () => concurrentCachedGET<Goal[]>(path),
    initialData: [],
    select(data) {
      return data.map((item) => ({
        ...item,
        icon: GOALS_ICONS[item.id]
      }))
    }
  })
}
