import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Progress,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowRight, IconCheck, IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import DOMPurify from 'dompurify'
import React, { useMemo } from 'react'
import { AuthenticityToken } from '@ui/AuthenticityToken'
import CircleIcon from '@ui/CircleIcon'
import PageDescription from '@ui/PageDescription'
import PageLayout from '@ui/PageLayout'
import PageTitle from '@ui/PageTitle'
import { projectPath } from '@ui/ProjectsContext'
import { SettingsBreadCrumb } from '@ui/SettingsBreadCrumb'
import SettingsHeader from '@ui/SettingsHeader'
import { OnboardingTask } from './components/SetupBadge'
import { OnboardingGrid } from '../welcome/components/OnboardingGrid'
import { useCurrentUser } from '@ui/UserContext'
import map from 'lodash/map'
import { useOnboardingGoals } from '../../data/use-onboarding-goals'

interface Props {
  checks: OnboardingTask[]
  done: OnboardingTask[]
  onboarding_focus: string[]
}

export default function Show(props: Props) {
  const done = props.checks.filter((check) => check.status.complete).length
  const total = props.checks.length
  const user = useCurrentUser()
  const { data: goals } = useOnboardingGoals()

  const isNewOnboarding = useMemo(() => user.featureEnabled('newOnboarding'), [user])

  return (
    <PageLayout size={isNewOnboarding ? 'md' : 'sm'}>
      <SettingsBreadCrumb paths={[{ title: 'Workspace Setup', path: projectPath('/setup') }]} offscreen />

      <SettingsHeader>
        <PageTitle>Set up your Koala workspace</PageTitle>
        <PageDescription>Get the most out of Koala by completing these tasks.</PageDescription>
      </SettingsHeader>

      {isNewOnboarding ? (
        <OnboardingGrid
          onboardingFocus={props.onboarding_focus || []}
          completedSteps={map(props.done, 'key') || []}
          goals={goals}
        />
      ) : (
        <Stack spacing={10}>
          <Stack>
            <Flex justifyContent="space-between">
              <Heading size="sm">Progress…</Heading>
              <Text>{Math.round((100 * done) / total)}%</Text>
            </Flex>
            <Progress value={done} min={0} max={total} size="md" rounded="sm" colorScheme="purple" />
          </Stack>

          <Stack>
            {props.checks.map((check) => (
              <Check check={check} key={check.key} />
            ))}
          </Stack>
        </Stack>
      )}
    </PageLayout>
  )
}

export function Check(props: { check: OnboardingTask }) {
  const check = props.check
  const [done, total] = check.status.progress || [check.status.complete ? 1 : 0, 1]
  const { isOpen, onToggle } = useDisclosure()
  const [skipping, setSkipping] = React.useState(false)

  return (
    <Box rounded="md" border="1px solid" borderColor="gray.200" shadow="sm" role="group">
      <HStack
        spacing={4}
        justifyContent="space-between"
        onClick={onToggle}
        cursor="pointer"
        userSelect="none"
        paddingY={3}
        paddingX={4}
      >
        <HStack spacing={4}>
          {check.status.complete ? (
            <CircleIcon icon={IconCheck} size={8} color="white" bg="purple.500" />
          ) : (
            <CircularProgress
              display="flex"
              flex="none"
              value={done}
              max={total}
              color="purple.600"
              size={8}
              thickness="6px"
            />
          )}
          <Stack spacing={0.5}>
            <Heading size="sm">{check.name}</Heading>
            {check.status.skipped && !check.required && (
              <Text fontSize="xs" color="gray.500">
                (Skipped)
              </Text>
            )}
          </Stack>
        </HStack>
        <Icon as={isOpen ? IconChevronUp : IconChevronDown} color="gray.500" boxSize={5} />
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Box paddingX={4}>
          <Stack alignItems="flex-start" paddingLeft={12} paddingRight={10} spacing={4} paddingBottom={6}>
            {check.description && (
              <Text
                fontSize="sm"
                color="gray.500"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(check.description) }}
              />
            )}
            <HStack spacing={8}>
              {check.href && (
                <Button
                  as={Link}
                  href={check.href.replace('/goto', projectPath())}
                  size="sm"
                  variant="outline"
                  rightIcon={<IconArrowRight size={16} />}
                >
                  {check.status.complete ? 'Review' : 'Continue'}
                </Button>
              )}
              {!check.status.complete && !check.required && !check.status.skipped && (
                <form action={projectPath('/setup/skip')} method="POST" onSubmit={() => setSkipping(true)}>
                  <AuthenticityToken />
                  <input type="hidden" name="check[key]" value={check.key} />
                  <Button type="submit" variant="link" size="sm" isDisabled={skipping}>
                    Skip for now
                  </Button>
                </form>
              )}
            </HStack>
          </Stack>
        </Box>
      </Collapse>
    </Box>
  )
}
