import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconDragDrop2, IconPlus, IconTrashX, IconUserCheck, IconUserSearch } from '@tabler/icons-react'
import { arrayMoveImmutable } from 'array-move'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { toast } from 'sonner'
import { put } from '../../../../lib/api'
import { App, Apps } from '../../../../types/App'
import {
  AdvancedRoutingRules,
  AutoOutboundRule,
  CadenceRules,
  CRMRules,
  IntentRules,
  QualificationRules,
  RoutingRules,
  SlackRules,
  TriggerRules
} from '../../../../types/AutoOutbound'
import { useFacets } from '../../../data/use-facets'
import { useSlack } from '../../../data/use-slack'
import { useTeams } from '../../../data/use-teams'
import { User, useUsers } from '../../../data/use-users'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import Avatar from '../../../ui/Avatar'
import { Card, GrayCard } from '../../../ui/Card'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { OutreachUserSelector } from '../../../ui/OutreachUserSelector'
import { FacetFilters } from '../../accounts'
import { FilterPreview } from '../../accounts/components/FilterPreview'
import { ChannelPicker } from '../../account_view_subscriptions/components/Slack'
import { PopupConnectDialog } from '../../apps/components/ConnectOauthAppDialog'
import { OutreachSequence } from '../../follow_rules/components/outreach/add-to-sequence'
import { AutoProspectingSetting } from '../../prospects/settings'
import { TriggerMultiSelect } from '../../slack_alerts/components/DefinitionForm'
import { autoOutboundPath, autoOutboundRulePath } from '../lib/path-helpers'
import { RulePreview } from './audience-preview'
import { AutoProspect } from './auto-prospect'
import { CampaignDestinations } from './campaign-destinations'

interface SectionTitleProps {
  title?: string
  description?: string
}

export function SectionTitle({ children, title, description }: React.PropsWithChildren<SectionTitleProps>) {
  return (
    <Flex flexDir="column" gap={1}>
      {title && <Heading size="sm">{title}</Heading>}
      {description && (
        <Text fontSize="sm" color="gray.600">
          {description}
        </Text>
      )}
      <Box mt={2}>{children}</Box>
    </Flex>
  )
}

interface DeduplicationRulesProps extends CadenceRules {
  ruleId?: string
}

function DeduplicationRulesSetup(props: DeduplicationRulesProps) {
  return (
    <Stack spacing="4">
      <SectionTitle
        title="Re-enrollment and exclusivity"
        description="Define how often a prospect can be enrolled across this and other playbooks"
      />

      <GrayCard>
        <Stack spacing={6}>
          <FormControl>
            <FormLabel>Prospects can be enrolled...</FormLabel>
            <Select size="sm" bg="white" name="cadence_rules[cadence]" defaultValue={props.cadence ?? 'quarter'}>
              <option value="week">No more than once per week</option>
              <option value="month">No more than once per month</option>
              <option value="quarter">No more than once every 3 months</option>
              <option value="ever">Only once ever</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Should enrolled prospects be allowed to enroll in other Auto Outbound playbooks?</FormLabel>
            <Select
              size="sm"
              bg="white"
              name="cadence_rules[exclusivity]"
              defaultValue={props.exclusivity ?? 'not_exclusive'}
            >
              <option value="not_exclusive">Yes, prospects can be enrolled here and in other playbooks</option>
              <option value="exclusive">No, enrolled prospects should be exclusive to this playbooks</option>
            </Select>
          </FormControl>
        </Stack>
      </GrayCard>
    </Stack>
  )
}

interface GlobalEligibilityProps extends QualificationRules {
  ruleId?: string
  apps: Apps
  autoProspectSetting?: AutoProspectingSetting
  onAutoProspectSettingChange: (setting: AutoProspectingSetting) => void
  onProspectingStrategyChange: (strategy: 'identified' | 'anonymous') => void
  prospectingStrategy: 'identified' | 'anonymous'
}

function GlobalEligibilitySetup(props: GlobalEligibilityProps) {
  const [workEmailsOnly, setWorkEmailsOnly] = useState<boolean | undefined>(props.work_emails_only ?? false)
  const [excludeInternalUsers, setExcludeInternalUsers] = useState<boolean | undefined>(
    props.exclude_internal_users ?? false
  )

  const accountRules = useFacets({
    facet_filters: props.account_rules
  })

  return (
    <Stack spacing="8">
      <Stack>
        <SectionTitle
          title="Select your audience"
          description="Define the accounts/people you want to run this playbook on"
        />

        <GrayCard>
          <Stack width="100%" spacing={3}>
            <Box>
              <Heading size="xs">Accounts</Heading>
              <Text fontSize="sm" color="gray.600">
                Select the accounts that you want to target with this playbook.
              </Text>
            </Box>
            <FilterPreview apps={props.apps ?? {}} usePortal={false} kind="account" {...accountRules}>
              <Button
                size="sm"
                variant="outline"
                bg="white"
                leftIcon={<IconPlus size={14} />}
                iconSpacing={1.5}
                rounded="md"
              >
                Add filter
              </Button>
            </FilterPreview>
          </Stack>
        </GrayCard>

        <GrayCard>
          <Stack width="100%" spacing={3}>
            <Box>
              <Heading size="xs">People</Heading>
              <Text fontSize="sm" color="gray.600">
                Select the people that you want to target with this playbook.
              </Text>
            </Box>
            <IdentifiedProspects
              apps={props.apps ?? {}}
              excludeInternalUsers={excludeInternalUsers}
              setExcludeInternalUsers={setExcludeInternalUsers}
              workEmailsOnly={workEmailsOnly}
              setWorkEmailsOnly={setWorkEmailsOnly}
              profile_rules={props.profile_rules}
            />
          </Stack>
        </GrayCard>
      </Stack>

      <Divider />

      <Stack spacing="8">
        <SectionTitle
          title="Prospecting"
          description="Do you want to prospect for other people or only enroll the people that triggered this playbook?"
        >
          <CardRadioGroup
            size="sm"
            direction="row"
            value={props.prospectingStrategy}
            onChange={(e) => {
              props.onProspectingStrategyChange(e as 'identified' | 'anonymous')
            }}
            showUncheckedIcon
            colorScheme="purple"
            options={[
              {
                label: (
                  <Stack>
                    <HStack spacing="2">
                      <IconUserCheck size="20" />
                      <Text fontWeight={'semibold'} fontSize="sm">
                        Identified People
                      </Text>
                    </HStack>
                    <Text fontSize="sm" color="gray.500">
                      People who directly triggered this playbook and have an identified email.
                    </Text>
                  </Stack>
                ),
                value: 'identified'
              },
              {
                label: (
                  <Stack>
                    <HStack spacing="2">
                      <IconUserSearch size="20" />
                      <Text fontWeight="semibold" fontSize="sm">
                        Prospect for new people
                      </Text>
                    </HStack>
                    <Text fontSize="sm" color="gray.500">
                      Automatically prospect for people at the companies in this audience.
                    </Text>
                  </Stack>
                ),
                value: 'anonymous'
              }
            ]}
          />
        </SectionTitle>

        {props.prospectingStrategy === 'anonymous' && (
          <GrayCard as={Stack}>
            <AutoProspect onChange={props.onAutoProspectSettingChange} setting={props.autoProspectSetting} />
          </GrayCard>
        )}

        <input
          type="hidden"
          name="qualification_rules[account_rules]"
          value={JSON.stringify(accountRules.facetFilters)}
        />
      </Stack>
    </Stack>
  )
}

interface CRMRulesProps extends CRMRules {
  ruleId?: string
  apps: Apps

  crmApps: App[]
  selectedCRM: App | null
  setSelectedCRM: (app: App | null) => void

  prospectingStrategy: 'identified' | 'anonymous'
}

function CRMRulesSetup(props: CRMRulesProps) {
  const { crmApps, selectedCRM, setSelectedCRM } = props

  const [noActiveDeals, setNoActiveDeals] = useState<boolean | undefined>(props.rules?.no_active_deals ?? false)
  const [isNotCustomer, setIsNotCustomer] = useState<boolean | undefined>(props.rules?.is_not_customer ?? false)
  const [ignoreContactedSinceToggle, setIgnoreContactedSinceToggle] = useState<boolean | undefined>(
    !!props.rules?.ignore_contacted_since
  )

  const advancedAccountRules = useFacets({
    facet_filters: props.advanced_rules
  })

  const advancedContactRules = useFacets({
    facet_filters: props.advanced_contact_rules,
    facetCloudPath: '/profiles/facet-cloud'
  })

  const usingSalesforce = useMemo(() => selectedCRM?.app_module.includes('Salesforce'), [selectedCRM])
  const usingHubspot = useMemo(() => selectedCRM?.app_module.includes('Hubspot'), [selectedCRM])

  return (
    <Box>
      <Stack spacing="4">
        <SectionTitle title="CRM Rules" description="Define who is allowed to be outbounded based on CRM rules" />

        <Stack as={GrayCard} spacing="8">
          {crmApps.length > 1 && (
            <Stack as={FormControl}>
              <FormLabel>Select your CRM</FormLabel>

              <CardRadioGroup
                size="sm"
                direction="row"
                value={selectedCRM?.app_module ?? ''}
                onChange={(e) => {
                  const app = crmApps.find((app) => app.app_module === e)
                  setSelectedCRM(app ?? null)
                }}
                colorScheme="purple"
                options={crmApps.map((app) => {
                  return {
                    label: (
                      <HStack spacing="2">
                        <Avatar src={app.logo} size="xs" />
                        <Text fontWeight={'semibold'} fontSize="sm">
                          {app.title}
                        </Text>
                      </HStack>
                    ),
                    value: app.app_module
                  }
                })}
              />
            </Stack>
          )}

          <Stack spacing="4">
            <Heading size="xs">Rule Presets</Heading>

            <FormControl>
              <HStack alignItems="flex-start">
                <Checkbox
                  id="crm_rules[no_active_deals]"
                  name="crm_rules[no_active_deals]"
                  marginTop="2px"
                  value={noActiveDeals ? '1' : '0'}
                  isChecked={noActiveDeals}
                  onChange={(e) => {
                    setNoActiveDeals(e.target.checked)
                  }}
                />
                <Stack alignItems="flex-start" spacing={0}>
                  <FormLabel marginBottom={1} htmlFor="crm_rules[no_active_deals]">
                    Ignore active deals
                  </FormLabel>
                  {usingSalesforce && (
                    <Text fontSize="sm" color="gray.500">
                      Excludes accounts that have open opportunities.
                    </Text>
                  )}
                  {usingHubspot && (
                    <Text fontSize="sm" color="gray.500">
                      Excludes companies that have open deals.
                    </Text>
                  )}
                </Stack>
              </HStack>
            </FormControl>

            <FormControl>
              <HStack alignItems="flex-start">
                <Checkbox
                  id="crm_rules[is_not_customer]"
                  name="crm_rules[is_not_customer]"
                  marginTop="2px"
                  value={isNotCustomer ? '1' : '0'}
                  isChecked={isNotCustomer}
                  onChange={(e) => {
                    setIsNotCustomer(e.target.checked)
                  }}
                />
                <Stack alignItems="flex-start" spacing={0}>
                  <FormLabel marginBottom={1} htmlFor="crm_rules[is_not_customer]">
                    Ignore customers
                  </FormLabel>
                  {usingSalesforce && (
                    <Text fontSize="sm" color="gray.500">
                      Excludes any accounts whose `Account Type` field is `Customer` or has any Closed Won
                      opportunities.
                    </Text>
                  )}

                  {usingHubspot && (
                    <Text fontSize="sm" color="gray.500">
                      Excludes any companies whose LifeCycle Stage is equal to `Customer`.
                    </Text>
                  )}
                </Stack>
              </HStack>
            </FormControl>

            <FormControl>
              <HStack alignItems="flex-start">
                <Checkbox
                  id="ignore_contacted_since_toggle"
                  marginTop="2px"
                  isChecked={ignoreContactedSinceToggle}
                  onChange={(e) => {
                    setIgnoreContactedSinceToggle(e.target.checked)
                  }}
                />
                <Stack alignItems="flex-start" spacing={0}>
                  <FormLabel marginBottom={1} htmlFor="ignore_contacted_since_toggle">
                    Ignore accounts recently contacted
                  </FormLabel>

                  {usingSalesforce && (
                    <Text fontSize="sm" color="gray.500" paddingBottom={2.5}>
                      Excludes accounts that have been contacted in the last N days based on the LastActivityDate field
                      on the Salesforce Account object.
                    </Text>
                  )}
                  {usingHubspot && (
                    <Text fontSize="sm" color="gray.500" paddingBottom={2.5}>
                      Excludes companies that have been contacted in the last N days based on the `notes_last_contacted`
                      field on the HubSpot Company object.
                    </Text>
                  )}
                  {ignoreContactedSinceToggle && (
                    <Flex>
                      <Select
                        defaultValue={props.rules?.ignore_contacted_since ?? '7'}
                        name="crm_rules[ignore_contacted_since]"
                        size="xs"
                        bg="white"
                        maxW="400"
                        rounded="md"
                      >
                        <option value="7">In the past 7 days</option>
                        <option value="30">In the past 30 days</option>
                      </Select>
                    </Flex>
                  )}
                </Stack>
              </HStack>
            </FormControl>
          </Stack>

          {/* only show this for previously set up playbooks that already used it, but avoid showing it for net-new */}
          {Object.keys(props.advanced_rules || {}).length > 0 && (
            <Stack spacing="1">
              <Heading size="xs">Additional Account Conditions</Heading>
              <FilterPreview
                kind="account"
                {...advancedAccountRules}
                usePortal={false}
                apps={props.apps}
                shouldShowActiveVisitorsFilters={false}
                shouldShowLastSeenFilter={false}
                shouldAllowFreeEntry={false}
                shouldShowCompanyFilters={false}
                shouldShowUserAttributeFilters={false}
                shouldShowStaticListFilters={false}
                shouldShowIntentFilters={false}
                shouldShow3rdPartyFilters={false}
                shouldShowICPFilters={false}
                shouldShowListFilters={false}
              />
            </Stack>
          )}

          {/* only show this for previously set up playbooks that already used it, but avoid showing it for net-new */}
          {Object.keys(props.advanced_contact_rules || {}).length > 0 && props.prospectingStrategy === 'identified' && (
            <Stack spacing="1">
              <Heading size="xs">[Contact] Advanced Rules</Heading>
              <FilterPreview
                kind="profile"
                {...advancedContactRules}
                apps={props.apps}
                shouldShowActiveVisitorsFilters={false}
                shouldShowLastSeenFilter={false}
                shouldAllowFreeEntry={false}
                shouldShowCompanyFilters={false}
                shouldShowUserAttributeFilters={false}
                shouldShowIntentFilters={false}
                shouldShowStaticListFilters={false}
                shouldShowICPFilters={false}
                shouldShowListFilters={false}
                shouldShow3rdPartyFilters={false}
              />
            </Stack>
          )}
        </Stack>
      </Stack>

      {selectedCRM && <input type="hidden" name="crm_rules[crm_app_module]" value={selectedCRM?.app_module} />}

      <input type="hidden" name="crm_rules[advanced_rules]" value={JSON.stringify(advancedAccountRules.facetFilters)} />
      <input
        type="hidden"
        name="crm_rules[advanced_contact_rules]"
        value={JSON.stringify(advancedContactRules.facetFilters)}
      />
    </Box>
  )
}

interface IntentRulesProps extends IntentRules {
  ruleId?: string
  initialKQLDefinitionIds: string[]
  setSelectedKQLDefinitionIds: (ids: string[]) => void
}

function IntentRulesSetup(props: IntentRulesProps) {
  const [kqlIds, setKqlIds] = useState<string[]>(props.initialKQLDefinitionIds)
  const [isAnyActivity, setIsAnyActivity] = useState<boolean>(props.any_intent?.toString() === 'true')
  const [isAnyIntentSignal, setIsAnyIntentSignal] = useState<boolean>(props.any_signal?.toString() === 'true')

  return (
    <Box>
      <Stack spacing="4">
        <SectionTitle
          title="Intent Trigger"
          description="Pick the signals that should add eligible accounts/people to this playbook"
        />

        <Stack w="100%" as={GrayCard} borderWidth="thin">
          <TriggerMultiSelect
            onChange={(incomingKQLIDs, anyActivity, anyIntentSignal) => {
              if (anyActivity && !isAnyActivity) {
                setKqlIds([])
                setIsAnyActivity(true)
                setIsAnyIntentSignal(false)
                props.setSelectedKQLDefinitionIds([])
                return
              } else if (anyIntentSignal && !isAnyIntentSignal) {
                setKqlIds([])
                setIsAnyActivity(false)
                setIsAnyIntentSignal(true)
                props.setSelectedKQLDefinitionIds([])
                return
              } else if (anyActivity || anyIntentSignal) {
                return
              }

              setIsAnyActivity(false)
              setIsAnyIntentSignal(false)
              if (incomingKQLIDs.length === 0 && kqlIds.length === 0) {
                return
              }

              setKqlIds(incomingKQLIDs)
              props.setSelectedKQLDefinitionIds(incomingKQLIDs)
            }}
            skipForms
            skipCreation
            selectedSignalIds={kqlIds}
            anyActivity={isAnyActivity}
            anyIntentSignal={isAnyIntentSignal}
          />
        </Stack>
      </Stack>

      {!isAnyActivity && <input type="hidden" name="intent_rules[any_intent]" value="0" />}
      {isAnyActivity && <input type="hidden" name="intent_rules[any_intent]" value="1" />}

      {!isAnyIntentSignal && <input type="hidden" name="intent_rules[any_signal]" value="0" />}
      {isAnyIntentSignal && <input type="hidden" name="intent_rules[any_signal]" value="1" />}

      {kqlIds.map((kqlId) => {
        return <input key={kqlId} type="hidden" name="intent_rules[kql_ids][]" value={kqlId} />
      })}
    </Box>
  )
}

interface TriggerRulesProps extends TriggerRules {
  ruleId?: string
}

function TriggerRulesSetup(props: TriggerRulesProps) {
  return (
    <FormControl as={Stack} spacing="4">
      <SectionTitle
        title="Delay"
        description="Optionally delay the execution of this playbook by a certain number of minutes after the intent trigger happens"
      />

      <Stack w="100%" as={GrayCard} borderWidth="thin">
        <Heading size="xs">Delay (minutes)</Heading>
        <Input
          bg="white"
          size="sm"
          type="number"
          name="trigger_rules[delay_minutes]"
          defaultValue={props.delay_minutes || ''}
        />
      </Stack>
    </FormControl>
  )
}

export interface RoutingRulesProps extends RoutingRules {
  apps: Apps
  app?: string
  selectedCRM: App | null
  onSkipChange: (skipping: boolean) => void
}

export function RoutingRulesSetup(props: RoutingRulesProps) {
  const { selectedCRM } = props
  const [routingOption, setRoutingOption] = React.useState(props.routing_option ?? 'crm-owner')
  const [emailFallback, setEmailFallback] = React.useState<string | null>(props.email_fallback ?? null)

  const { data: usersData, isLoading: loadingUsers } = useUsers({ cached: true })
  const [selectedOwnerEmail, setSelectedOwnerEmail] = React.useState<string | null>(props.owner_email ?? null)
  const [selectedOutreachUserId, setSelectedOutreachUserId] = React.useState<string | number | null>(
    props.outreach_user_id ?? null
  )

  const { data: teamsData, isLoading: loadingTeams } = useTeams()
  const teams = useMemo(() => teamsData?.teams ?? [], [teamsData])
  const [selectedTeamId, setSelectedTeamId] = React.useState<string | null>(props.team_id ?? null)

  const [advancedRules, setAdvancedRules] = React.useState<AdvancedRoutingRules[]>(props.advanced ?? [])

  const usingSalesforce = useMemo(() => selectedCRM?.app_module.includes('Salesforce'), [selectedCRM])
  const usingHubspot = useMemo(() => selectedCRM?.app_module.includes('Hubspot'), [selectedCRM])
  const hasOutreach = useMemo(() => props.apps?.['Apps::Outreach::App']?.connected, [props.apps])

  return (
    <Stack spacing="4">
      <Heading size="xs">Sender</Heading>
      <RadioGroup
        value={routingOption}
        isDisabled={props.skip}
        onChange={(value) =>
          setRoutingOption(value as 'crm-owner' | 'outreach-owner' | 'user' | 'round-robin' | 'advanced')
        }
        size="sm"
        fontSize="xs"
        name="routing_rules[routing_option]"
        position={'relative'}
      >
        <Stack bg="white" rounded="lg" p="4" borderWidth={'thin'} w="100%">
          {props.skip && (
            <>
              <Text>Skipping sender for Auto Outbound. Koala will not select a sender for this rule.</Text>
              <Divider />
            </>
          )}
          <Radio value="crm-owner">
            {usingSalesforce && 'Salesforce Contact Owner'}
            {usingHubspot && 'HubSpot Contact Owner'}
            {!props.selectedCRM && 'CRM Owner'}
          </Radio>
          {(usingSalesforce || usingHubspot) && (
            <Radio value="crm-account-owner">
              {usingSalesforce && 'Salesforce Account Owner'}
              {usingHubspot && 'HubSpot Account Owner'}
            </Radio>
          )}
          {hasOutreach && props.app === 'outreach' && <Radio value="outreach-owner">Outreach Assigned Owner</Radio>}
          {hasOutreach && props.app === 'outreach' && <Radio value="outreach-user">Select an Outreach User</Radio>}
          {(props.app !== 'outreach' || props.routing_option === 'user') && (
            <Radio value="user">Select a Koala User</Radio>
          )}
          <Radio value="round-robin">Round Robin</Radio>
          <Radio value="advanced">Advanced</Radio>
        </Stack>
      </RadioGroup>

      {routingOption === 'crm-owner' && (
        <>
          {usingSalesforce && (
            <Text fontSize="sm" pt="2">
              The sender will be inherited from the owner of the Contact / Lead in Salesforce, then proceed to look for
              the Account Owner as a fallback.{' '}
              {emailFallback === null && (
                <strong>Note: this means Unowned Accounts will not be slated for outbounding.</strong>
              )}
            </Text>
          )}

          {usingHubspot && (
            <Text fontSize="sm" pt="2">
              The sender will be inherited from the owner of the Contact in HubSpot, then proceed to look for the
              Company Owner as a fallback.{' '}
              {emailFallback === null && (
                <strong>Note: this means unowned companies will not be slated for outbounding.</strong>
              )}
            </Text>
          )}
        </>
      )}

      {routingOption === 'outreach-owner' && props.app === 'outreach' && (
        <Text fontSize="sm" pt="2">
          The sender will be inherited from the owner of the Prospect in Outreach, then proceed to look for the Account
          Owner as a fallback.{' '}
          {emailFallback === null && (
            <strong>Note: this means unowned accounts will not be slated for outbounding.</strong>
          )}
        </Text>
      )}

      {routingOption === 'outreach-user' && props.app === 'outreach' && (
        <FormControl>
          <FormLabel pt="2">Select an Outreach user</FormLabel>
          <OutreachUserSelector
            selectedOutreachUserId={selectedOutreachUserId}
            onChange={(outreachUser) => setSelectedOutreachUserId(outreachUser?.id ?? null)}
          />
          {selectedOutreachUserId && (
            <input type="hidden" name="routing_rules[outreach_user_id]" value={selectedOutreachUserId} />
          )}
        </FormControl>
      )}

      {routingOption === 'user' && (
        <FormControl>
          <FormLabel pt="2">Select a User</FormLabel>
          {loadingUsers && <Spinner size="sm" />}
          <ComboboxWithSearch
            items={usersData?.users ?? []}
            selectedItem={usersData?.users?.find((u) => u.email === selectedOwnerEmail) ?? null}
            onChange={(selectedItem) => {
              setSelectedOwnerEmail(selectedItem?.email ?? null)
            }}
            filterItem={(a, val) => a.name.toLowerCase().includes(val)}
            itemToString={(item) => `${item?.name || 'Unknown User'} (${item?.email || 'unknown email'})`}
            placeholder="Select a User"
          />
          {selectedOwnerEmail && <input type={'hidden'} name="routing_rules[owner_email]" value={selectedOwnerEmail} />}
          <FormHelperText>
            Koala will find corresponding senders using this user's email address in Koala.
          </FormHelperText>
        </FormControl>
      )}

      {routingOption === 'round-robin' && (
        <FormControl>
          <FormLabel pt="2">Choose a Team to round robin</FormLabel>
          {loadingUsers && <Spinner size="sm" />}
          {selectedTeamId && <input type="hidden" name="routing_rules[team_id]" value={selectedTeamId} />}
          {loadingTeams ? (
            <Spinner />
          ) : (
            <Box>
              <ComboboxWithSearch
                items={teams}
                selectedItem={teams.find((t) => selectedTeamId && t.id === selectedTeamId) ?? null}
                onChange={(selectedItem) => {
                  setSelectedTeamId(selectedItem?.id ?? null)
                }}
                filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                itemToString={(item) => item?.name ?? 'Unknown Team'}
                placeholder="Select a Team"
              />
              <FormHelperText>
                Koala will round-robin the users on this team that are also in your Outreach instance for each prospect
                added to the sequence.
              </FormHelperText>
            </Box>
          )}
        </FormControl>
      )}

      {routingOption === 'advanced' && (
        <>
          <FormControl>
            <FormLabel pt="2">Advanced Routing</FormLabel>
            <FormHelperText>
              You can use advanced routing rules to map a specific set of filters to a specific user.
            </FormHelperText>
          </FormControl>

          {loadingUsers && <Spinner size="sm" />}

          <SortableList
            onSortEnd={(oldIndex: number, newIndex: number) => {
              setAdvancedRules((array) => arrayMoveImmutable(array, oldIndex, newIndex))
            }}
            draggedItemClassName="dragged"
            style={{
              userSelect: 'none'
            }}
          >
            <Stack>
              {advancedRules.map((rule) => {
                return (
                  <SortableItem key={JSON.stringify(rule)}>
                    <HStack w="100%">
                      <SortableKnob>
                        <Box cursor={'grab'}>
                          <Tooltip label="Drag to change priorities" placement="top">
                            <IconDragDrop2 color="gray" size="14" />
                          </Tooltip>
                        </Box>
                      </SortableKnob>
                      <AdvancedRoutingRule
                        onRemove={() => {
                          setAdvancedRules(advancedRules.filter((r) => r !== rule))
                        }}
                        filters={rule.filters}
                        ownerEmail={rule.owner_email}
                        users={usersData?.users ?? []}
                        apps={props.apps}
                      />
                    </HStack>
                  </SortableItem>
                )
              })}
            </Stack>
          </SortableList>

          <Flex justifyContent={'flex-end'}>
            <Button
              colorScheme="purple"
              size="sm"
              leftIcon={<IconPlus size="14" />}
              onClick={() => {
                setAdvancedRules([
                  ...advancedRules,
                  {
                    owner_email: '',
                    filters: {}
                  }
                ])
              }}
            >
              Add Sender
            </Button>
          </Flex>
        </>
      )}

      {!['user', 'advanced', 'round-robin', 'outreach-user'].includes(routingOption) && (
        <FormControl>
          <Flex>
            <FormLabel>
              <Switch
                onChange={(e) => {
                  if (e.target.checked) {
                    setEmailFallback('')
                  } else {
                    setEmailFallback(null)
                  }
                }}
                isChecked={emailFallback !== null}
                size="sm"
              >
                Sender fallback
              </Switch>
            </FormLabel>
          </Flex>
          <Input
            size="sm"
            bg="white"
            isDisabled={emailFallback === null}
            type="email"
            name="routing_rules[email_fallback]"
            value={emailFallback ?? ''}
            onChange={(e) => setEmailFallback(e.target.value)}
          />
          <Stack spacing="0" pt="2">
            <FormHelperText>
              In case an owner can't be determined by the rules above, Koala will send the email from the address you
              supply here.{' '}
            </FormHelperText>
            <FormHelperText>
              Note: The email you provide here needs to be set up with a valid mailbox in your Sales Engagement
              Platform.
            </FormHelperText>
          </Stack>
        </FormControl>
      )}
    </Stack>
  )
}

interface AdvancedRoutingRuleProps {
  users: User[]
  apps: Apps
  filters?: FacetFilters
  ownerEmail?: string
  onRemove?: () => void
}

function AdvancedRoutingRule(props: AdvancedRoutingRuleProps) {
  const facets = useFacets({
    facet_filters: props.filters
  })

  const users = props.users
  const [selectedOwnerEmail, setSelectedOwnerEmail] = React.useState<string | null>(props.ownerEmail ?? null)

  return (
    <>
      <Stack as={Card} spacing="4" shadow="none" w="100%">
        <Stack w="100%">
          <HStack w="100%">
            <Heading size="xs" color="gray.500" flex="1">
              Routing rules:
            </Heading>
            <IconButton
              size="xs"
              variant="ghost"
              colorScheme="red"
              aria-label="Remove"
              icon={<IconTrashX size="14" />}
              onClick={() => {
                props.onRemove?.()
              }}
            />
          </HStack>
          <FilterPreview kind="profile" {...facets} apps={props.apps}>
            <Button
              size="sm"
              variant="outline"
              bg="white"
              leftIcon={<IconPlus size={14} />}
              iconSpacing={1.5}
              rounded="md"
            >
              Add filter
            </Button>
          </FilterPreview>
        </Stack>

        <Stack>
          <Heading size="xs" color="gray.500">
            Sender:
          </Heading>
          <ComboboxWithSearch
            items={users ?? []}
            selectedItem={users.find((u) => u.email === selectedOwnerEmail) ?? null}
            onChange={(selectedItem) => {
              setSelectedOwnerEmail(selectedItem?.email ?? null)
            }}
            filterItem={(a, val) => a.name.toLowerCase().includes(val)}
            itemToString={(item) => `${item?.name || 'Unknown User'} (${item?.email || 'unknown email'})`}
          />
        </Stack>
      </Stack>

      {selectedOwnerEmail && (
        <input type={'hidden'} name="routing_rules[advanced][][owner_email]" value={selectedOwnerEmail} />
      )}
      <input type={'hidden'} name="routing_rules[advanced][][filters]" value={JSON.stringify(facets.facetFilters)} />
    </>
  )
}

interface SendToSlackSetupProps extends SlackRules {
  ruleId?: string
  apps: Apps
}

function SendToSlackSetup(props: SendToSlackSetupProps) {
  const slackData = useSlack()
  const [enabled, setEnabled] = useState<boolean | undefined>(props.enabled ?? false)
  const [newChannelName, setNewChannelName] = React.useState<string | undefined>(
    props.channel_id ? undefined : props.channel_name
  )
  const [selectedChannel, setSelectedChannel] = React.useState<string | undefined>(props.channel_id)
  const [channelName, setChannelName] = React.useState<string | undefined>(props.channel_name)

  const channels = useMemo(() => slackData.data?.deps?.channels ?? [], [slackData.data?.deps?.channels])

  const onSlackConnected = useCallback(() => {
    slackData.refetch()
  }, [slackData])

  return (
    <Box>
      {selectedChannel && (
        <>
          <input type="hidden" name="slack_rules[channel_id]" value={selectedChannel} />
          <input type="hidden" name="slack_rules[channel_name]" value={channelName} />
        </>
      )}

      {newChannelName && <input type="hidden" name="slack_rules[channel_name]" value={newChannelName} />}

      <Stack spacing="4">
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <SectionTitle
            title="Slack notifications (optional)"
            description="Get notified in Slack when this playbook runs for a new prospect or encounters a failure"
          />

          <input type="hidden" name="slack_rules[enabled]" value={enabled ? 'true' : 'false'} />
          <Switch
            size="md"
            id="slack_rules[enabled]"
            isChecked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
          />
        </Flex>

        {slackData.isLoading ? (
          <Box minH="32px">
            <Spinner color="gray.400" thickness="1.5px" size="sm" />
          </Box>
        ) : enabled ? (
          <Stack w="100%" as={GrayCard} borderWidth="thin" spacing="4">
            <Heading size="xs">Slack Channel</Heading>

            <ChannelPicker
              channels={channels}
              setChannel={(channel, inputValue) => {
                if (channel?.id) {
                  setSelectedChannel(channel?.id)
                  setChannelName(channel?.name)
                  setNewChannelName(undefined)
                } else {
                  setNewChannelName(inputValue ?? undefined)
                  setChannelName(undefined)
                  setSelectedChannel(undefined)
                }
              }}
              isNewChannelEntered={!!newChannelName}
              channel={
                channels.find((c) => c.id === selectedChannel) ||
                (!selectedChannel && props.channel_name ? { id: '', name: props.channel_name } : undefined)
              }
              chakraInputProps={{
                size: 'sm',
                rounded: 'md',
                bg: 'white',
                isRequired: true,
                isDisabled: false
              }}
            />
          </Stack>
        ) : slackData.data && !slackData.data?.connected ? (
          <Stack w="100%" as={GrayCard} borderWidth="thin" spacing="4">
            <PopupConnectDialog app_id={slackData.data.app_id} onConnected={onSlackConnected}>
              {({ onStart }) => (
                <Button size="sm" onClick={onStart} colorScheme="purple" variant="outline">
                  Connect Slack to select a channel
                </Button>
              )}
            </PopupConnectDialog>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  )
}

interface DefinitionProps {
  apps: Apps
  auto_outbound_rule?: AutoOutboundRule
  outreach_sequences?: OutreachSequence[]
}

export function DefinitionForm(props: DefinitionProps) {
  const [autoOutboundRule, setAutoOutboundRule] = useState<AutoOutboundRule | undefined>(props.auto_outbound_rule)

  const [selectedKQLDefinitionIds, setSelectedKQLDefinitionIds] = useState<string[]>(
    props.auto_outbound_rule?.rules?.intent?.kql_ids ?? []
  )

  const crmApps = useMemo(() => {
    return Object.values(props.apps).filter((app) => app.categories.includes('CRM'))
  }, [props.apps])

  const [selectedCRM, setSelectedCRM] = useState<App | null>(
    crmApps.length === 1
      ? crmApps[0]
      : (crmApps.find((app) => app.app_module === props.auto_outbound_rule?.rules.crm?.crm_app_module) ?? null)
  )

  const [prospectingStrategy, setProspectingStrategy] = useState<'identified' | 'anonymous'>(
    props.auto_outbound_rule?.rules?.auto_prospecting?.persona_id ? 'anonymous' : 'identified'
  )

  const [autoProspectSetting, setAutoProspectSetting] = useState<AutoProspectingSetting | undefined>(
    props.auto_outbound_rule?.rules?.auto_prospecting
  )

  const formRef = useRef<HTMLFormElement>(null)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [saveDisabled, setSaveDisabled] = React.useState(false)
  const path = props.auto_outbound_rule?.id ? autoOutboundRulePath(props.auto_outbound_rule?.id) : autoOutboundPath()

  return (
    <form
      action={path}
      method="POST"
      ref={formRef}
      onSubmit={() => {
        setIsSubmitting(true)
      }}
      data-koala-collect="off"
    >
      <AuthenticityToken />
      {props.auto_outbound_rule?.id && <input type={'hidden'} name={'_method'} value={'PATCH'} />}

      <Stack spacing="8">
        <Stack divider={<Divider />} spacing="8">
          <HStack w="100%" as={Stack} flex="1">
            <FormControl>
              <Input
                defaultValue={props.auto_outbound_rule?.name}
                isRequired
                name="auto_outbound[name]"
                fontSize={'lg'}
                fontWeight="semibold"
                maxW={'400px'}
                placeholder="New Playbook..."
              />
            </FormControl>
            {autoOutboundRule && (
              <HStack spacing="4">
                <HStack fontSize={'sm'}>
                  <Text>{autoOutboundRule.enabled ? 'Enabled' : 'Disabled'}</Text>
                  <Switch
                    defaultChecked={autoOutboundRule.enabled}
                    value="1"
                    onChange={async (e) => {
                      await put(autoOutboundRulePath(props.auto_outbound_rule!.id, `/status`), {
                        auto_outbound: {
                          enabled: e.target.checked
                        }
                      }).then(() => {
                        setAutoOutboundRule((rule) => {
                          if (!rule) {
                            return rule
                          }

                          return {
                            ...rule,
                            enabled: e.target.checked
                          }
                        })
                        toast.success(`Auto Outbound Playbook ${e.target.checked ? 'enabled' : 'disabled'}`)
                      })
                    }}
                  />
                </HStack>
                <HStack fontSize={'sm'}>
                  <Text>{autoOutboundRule.autopilot ? 'Autopilot' : 'Manual'}</Text>
                  <HelpTooltip>
                    <Stack fontSize={'xs'}>
                      <Heading size="xs">Autopilot vs Manual Mode</Heading>
                      <Text>You can choose to run this playbook in Autopilot or Manual mode.</Text>
                      <Text>
                        Autopilot will automatically sync eligible prospects with your configured destination.
                      </Text>
                      <Text>
                        Manual mode requires a human touch. Eligible prospects will be enrolled in the playbooks, but
                        will not automatically sync with your destination. You'll be given the choice to sync individual
                        prospects from the playbook page.
                      </Text>
                    </Stack>
                  </HelpTooltip>
                  <Switch
                    defaultChecked={autoOutboundRule.autopilot}
                    value="1"
                    onChange={async (e) => {
                      await put(autoOutboundRulePath(props.auto_outbound_rule!.id, `/status`), {
                        auto_outbound: {
                          autopilot: e.target.checked
                        }
                      })
                        .then(() => {
                          setAutoOutboundRule((rule) => {
                            if (!rule) {
                              return rule
                            }

                            return {
                              ...rule,
                              autopilot: e.target.checked
                            }
                          })
                          toast.success(`Autopilot ${e.target.checked ? 'enabled' : 'disabled'}`)
                        })
                        .catch((ee) => {
                          toast.error(`Error: ${ee}`)
                        })
                    }}
                  />
                </HStack>
              </HStack>
            )}
          </HStack>

          <Flex alignItems="flex-end" gap={8} flexWrap="wrap-reverse">
            <Stack flex="1 1 340px" minWidth="340px" divider={<Divider />} spacing="8">
              <GlobalEligibilitySetup
                ruleId={props.auto_outbound_rule?.id}
                apps={props.apps}
                {...props.auto_outbound_rule?.rules.qualification}
                autoProspectSetting={autoProspectSetting}
                onAutoProspectSettingChange={setAutoProspectSetting}
                prospectingStrategy={prospectingStrategy}
                onProspectingStrategyChange={(strategy) => {
                  setProspectingStrategy(strategy)
                }}
              />

              <CRMRulesSetup
                ruleId={props.auto_outbound_rule?.id}
                apps={props.apps}
                crmApps={crmApps}
                selectedCRM={selectedCRM}
                setSelectedCRM={setSelectedCRM}
                prospectingStrategy={prospectingStrategy}
                {...props.auto_outbound_rule?.rules.crm}
              />
              <DeduplicationRulesSetup
                ruleId={props.auto_outbound_rule?.id}
                {...props.auto_outbound_rule?.rules.cadence}
              />
              <IntentRulesSetup
                ruleId={props.auto_outbound_rule?.id}
                {...props.auto_outbound_rule?.rules.intent}
                initialKQLDefinitionIds={selectedKQLDefinitionIds}
                setSelectedKQLDefinitionIds={setSelectedKQLDefinitionIds}
              />
              <TriggerRulesSetup ruleId={props.auto_outbound_rule?.id} {...props.auto_outbound_rule?.rules.trigger} />

              <CampaignDestinations
                apps={props.apps}
                {...props.auto_outbound_rule?.rules.outreach}
                outreachSequences={props.outreach_sequences}
                kqlDefinitionIds={selectedKQLDefinitionIds}
                autoOutboundRule={props.auto_outbound_rule}
                selectedCRM={selectedCRM}
                setSaveDisabled={setSaveDisabled}
              />

              <SendToSlackSetup
                ruleId={props.auto_outbound_rule?.id}
                apps={props.apps}
                {...props.auto_outbound_rule?.rules?.slack}
              />

              <Button type="submit" colorScheme="purple" isDisabled={saveDisabled} isLoading={isSubmitting}>
                Save
              </Button>
            </Stack>

            <RulePreview
              formRef={formRef}
              prospectingStrategy={prospectingStrategy}
              autoProspectingSetting={autoProspectSetting}
            />
          </Flex>
        </Stack>
      </Stack>
    </form>
  )
}

interface IdentifiedProspectsProps {
  apps: Apps
  workEmailsOnly: boolean | undefined
  setWorkEmailsOnly: (value: boolean | undefined) => void
  excludeInternalUsers: boolean | undefined
  setExcludeInternalUsers: (value: boolean | undefined) => void
  profile_rules: FacetFilters | undefined
}

function IdentifiedProspects(props: IdentifiedProspectsProps) {
  const { workEmailsOnly, setWorkEmailsOnly, excludeInternalUsers, setExcludeInternalUsers, profile_rules } = props

  const profileRules = useFacets({
    facet_filters: profile_rules,
    facetCloudPath: '/profiles/facet-cloud'
  })

  return (
    <Stack flex="1" width="100%" spacing="5">
      <FilterPreview
        kind="profile"
        apps={props.apps ?? {}}
        usePortal={false}
        {...profileRules}
        shouldShowCompanyFilters={false}
        shouldShowICPFilters={false}
      >
        <Button size="sm" variant="outline" bg="white" leftIcon={<IconPlus size={14} />} iconSpacing={1.5} rounded="md">
          Add filter
        </Button>
      </FilterPreview>

      <Divider />

      <Stack spacing={3}>
        <Checkbox
          name="qualification_rules[work_emails_only]"
          value={workEmailsOnly ? '1' : '0'}
          isChecked={workEmailsOnly}
          onChange={(e) => {
            setWorkEmailsOnly(e.target.checked)
          }}
        >
          Work Emails Only (recommended)
        </Checkbox>

        <Checkbox
          name="qualification_rules[exclude_internal_users]"
          value={excludeInternalUsers ? '1' : '0'}
          isChecked={excludeInternalUsers}
          onChange={(e) => {
            setExcludeInternalUsers(e.target.checked)
          }}
        >
          Exclude internal and test users
        </Checkbox>

        <input
          type="hidden"
          name="qualification_rules[profile_rules]"
          value={JSON.stringify(profileRules.facetFilters)}
        />
      </Stack>
    </Stack>
  )
}
