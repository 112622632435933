import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  HStack,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import React, { useMemo } from 'react'
import { SalesforceFieldMapper, SalesforceFieldMapping } from './salesforce-field-mapper'
import { SalesforceActionProps } from './salesforce-setup'

export function ImportLead(
  props: SalesforceActionProps & {
    targetType?: 'Profile' | 'Account'
  }
) {
  const contactEnabled = props.delivery_rules?.salesforce?.import_contact?.enabled
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.salesforce?.import_lead?.enabled))
  const sfFieldNames = useMemo(() => props.deps.lead_layout.map((f) => f.name), [props.deps.lead_layout])

  const isPersisted = useMemo(
    () => !!props.delivery_rules?.salesforce?.import_lead?.fields?.length,
    [props.delivery_rules?.salesforce?.import_lead]
  )

  const suggestions: SalesforceFieldMapping[] = useMemo(() => {
    if (props.suggestedMappings) {
      return props.suggestedMappings
    }

    return [
      {
        id: nanoid(),
        koala: 'visitor.email',
        salesforce: 'Email'
      },
      {
        id: nanoid(),
        koala: 'visitor.first_name',
        salesforce: 'FirstName'
      },
      {
        id: nanoid(),
        koala: 'visitor.last_name',
        salesforce: 'LastName'
      },
      {
        id: nanoid(),
        koala: 'visitor.title',
        salesforce: 'Title'
      },
      {
        id: nanoid(),
        koala: 'visitor.email_domain',
        salesforce: 'Website'
      },
      {
        id: nanoid(),
        koala: 'visitor.email_domain',
        salesforce: 'Company'
      },
      {
        id: nanoid(),
        koala: 'visitor.geo.city',
        salesforce: 'City'
      },
      {
        id: nanoid(),
        koala: 'visitor.geo.state',
        salesforce: 'State'
      },
      {
        id: nanoid(),
        koala: 'visitor.geo.country',
        salesforce: 'Country'
      }
    ].filter((s) => s.salesforce && sfFieldNames.includes(s.salesforce))
  }, [sfFieldNames, props.suggestedMappings])

  return (
    <Stack w="100%">
      <FormControl>
        <Switch
          disabled={props.targetType === 'Account'}
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              salesforce: {
                ...props.delivery_rules?.salesforce,
                import_lead: {
                  ...props.delivery_rules?.salesforce?.import_lead,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={enabled && !contactEnabled ? props.colorScheme : 'gray'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][salesforce][import_lead][enabled]"
        >
          Import Lead
        </Switch>
        {!props.compact && (
          <FormHelperText>
            Import the current Visitor as a Lead in Salesforce if it doesn't already exist. (Requires an identified
            visitor.)
          </FormHelperText>
        )}
      </FormControl>

      {props.targetType === 'Account' && (
        <Box bg="orange.50" p="4" rounded="md" borderWidth="thin" borderColor={'orange.500'}>
          <Text fontSize={'sm'}>
            <strong>Note:</strong> Your Action is set up to target Accounts. You can only import Contacts when targeting
            Visitors in your Action.
          </Text>
        </Box>
      )}

      {enabled && contactEnabled && (
        <Alert status="warning" fontSize="sm">
          <Stack>
            <HStack spacing="0">
              <AlertIcon />
              <AlertTitle>Incompatible Actions</AlertTitle>
            </HStack>
            <AlertDescription lineHeight={1.4}>
              You have enabled both "Import Contact" and "Import Lead" actions. A Salesforce Contact cannot be both a
              Contact and Lead at the same time. This means the "Import Lead" action will not be executed.
            </AlertDescription>
          </Stack>
        </Alert>
      )}

      {enabled && !contactEnabled && props.actionSchema && (
        <Stack spacing={'8'} my={props.compact ? '4' : undefined}>
          {!props.compact && <Divider />}
          <SalesforceFieldMapper
            compact={props.compact}
            actionsSchema={props.actionSchema}
            loadingDeps={props.loadingDeps}
            refetchDeps={props.refetchDeps}
            namespace="follow_rule[delivery_rules][salesforce][import_lead]"
            type="lead"
            mappings={props.delivery_rules?.salesforce?.import_lead?.fields ?? (isPersisted ? [] : suggestions)}
            suggestions={suggestions}
            deps={props.deps}
          />
        </Stack>
      )}
    </Stack>
  )
}
