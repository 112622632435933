import { Box, Button, Collapse, FormControl, FormLabel, Icon, Image, Spinner, Stack, Wrap } from '@chakra-ui/react'
import { Icon as TablerIcon, IconWebhook } from '@tabler/icons-react'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import { FollowRule } from '../../../../types/FollowRule'
import { Iconify } from '../../../ui/Iconify'
import { CheckboxBlankCircleIcon, CheckboxCircleIcon } from '../../../ui/icons'
import { humanize } from '../../accounts/facets/filter-cloud'
import { ApolloSetup } from './apollo/apollo-setup'
import { EmailSetup } from './email/email-setup'
import { HubspotSetup } from './hubspot/hubspot-setup'
import { InstantlySetup } from './instantly/instantly-setup'
import { StepCard } from './notification-rule-form'
import { OutreachSetup } from './outreach/outreach-setup'
import { SalesforceSetup } from './salesforce/salesforce-setup'
import { SlackSetup } from './slack/slack-setup'
import { WebhookSetup } from './webhook/webhook-setup'

interface Props {
  follow_rule?: FollowRule
  targetType: 'Account' | 'Profile'
  setSaveDisabled?: (value: boolean) => void
  isWaterfallEnriching?: boolean
}

const defaultRules = {
  slack: {
    trigger: 'immediate'
  },
  email: {
    trigger: 'immediate'
  },
  webhook: {
    trigger: 'immediate'
  },
  hubspot: {
    trigger: 'immediate'
  },
  salesforce: {},
  outreach: {},
  apollo: {},
  instantly: {}
}

export const channelLogos = {
  slack: 'https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg',
  email: 'https://cdn.worldvectorlogo.com/logos/mail-ios.svg',
  webhook: IconWebhook,
  hubspot: 'https://cdn.cdnlogo.com/logos/h/24/hubspot.svg',
  salesforce: 'https://cdn.cdnlogo.com/logos/s/3/salesforce.svg',
  outreach: 'https://www.outreach.io/_resources/img/logomark_sm.min.svg',
  apollo: 'https://asset.brandfetch.io/ideEin4YhC/id-83Yirn2.png',
  instantly: 'https://cdn.prod.website-files.com/63860c8c65e7bef4a1eeebeb/63f62e4d1df86f1bf7f133d5_cleaned_rounded.png',
  salesloft: 'https://static.cdnlogo.com/logos/s/72/salesloft.svg'
}

type Channel = keyof typeof defaultRules

export const isChannelActive = (delivery_rules, channel) => {
  return delivery_rules?.[channel] !== undefined
}

interface RuleProps {
  deliveryRules: FollowRule['delivery_rules']
  setDeliveryRules: React.Dispatch<React.SetStateAction<FollowRule['delivery_rules']>>
  children: React.ReactNode
  title: string
  icon: string | TablerIcon
  channel: Channel
  setSaveDisabled?: (value: boolean) => void
  enabled?: boolean
}

function DeliveryRule(props: RuleProps) {
  const deliveryRules = props.deliveryRules
  const channel = props.channel

  const logo = typeof props.icon === 'string' ? <Image src={props.icon} w="6" /> : <Icon as={props.icon} boxSize={6} />

  if (!props.enabled) {
    return null
  }

  return (
    <StepCard wrap title={props.title} logo={logo}>
      <Stack
        w="100%"
        aria-disabled={!deliveryRules?.[channel]}
        _disabled={{
          opacity: 0.4,
          pointerEvents: 'none'
        }}
      >
        {props.children}
      </Stack>
    </StepCard>
  )
}

export function DeliverySetup(props: Props) {
  const [deliveryRules, setDeliveryRules] = useState<FollowRule['delivery_rules']>(props.follow_rule?.delivery_rules)
  const channels = Object.keys(defaultRules) as Channel[]

  const [selectedChannels, setSelectedChannels] = useState<Channel[]>(
    Object.keys(props.follow_rule?.delivery_rules ?? {}).filter((channel) =>
      channels.includes(channel as Channel)
    ) as Channel[]
  )

  const [loadingChannels, setLoadingChannels] = useState<Record<Channel, boolean>>(
    Object.fromEntries(selectedChannels.map((channel) => [channel, true])) as Record<Channel, boolean>
  )

  // Check if any channel is loading and update the save button state
  const { setSaveDisabled } = props

  useEffect(() => {
    const isAnyChannelLoading = Object.values(loadingChannels).some((isLoading) => isLoading)
    if (setSaveDisabled) {
      setSaveDisabled(isAnyChannelLoading)
    }
  }, [loadingChannels, setSaveDisabled])

  const setChannelLoading = (channel: Channel, isLoading: boolean) => {
    if (channel) {
      setLoadingChannels((prev) => {
        // Only update if the loading state actually changed
        if (prev[channel] === isLoading) {
          return prev
        }
        return {
          ...prev,
          [channel]: isLoading
        }
      })
    }
  }

  return (
    <FormControl>
      <FormLabel>Deliver to</FormLabel>

      <Wrap gap="2" pt="0" pb="8">
        {channels.map((channel) => {
          const isActive = isChannelActive(deliveryRules, channel)
          return (
            <Button
              key={channel}
              variant="outline"
              leftIcon={<Iconify icon={channelLogos[channel]} size={16} />}
              rightIcon={
                loadingChannels[channel] ? (
                  <Spinner size="sm" color="purple.600" />
                ) : isActive ? (
                  <Icon flex="none" as={CheckboxCircleIcon} boxSize="18px" color={'purple.600'} />
                ) : (
                  <Icon flex="none" as={CheckboxBlankCircleIcon} boxSize="18px" color={'gray.300'} />
                )
              }
              borderColor={isActive ? 'purple.500' : 'gray.300'}
              bg={isActive ? 'white' : undefined}
              isDisabled={loadingChannels[channel]}
              onClick={() => {
                if (isActive) {
                  setSelectedChannels(selectedChannels.filter((c) => c !== channel))
                  setDeliveryRules({
                    ...deliveryRules,
                    [channel]: undefined
                  })
                } else {
                  setSelectedChannels((channels) => {
                    return [...channels, channel]
                  })

                  setDeliveryRules({
                    ...deliveryRules,
                    [channel]: deliveryRules?.[channel] ?? defaultRules[channel]
                  })
                }
              }}
            >
              {humanize(channel)}
            </Button>
          )
        })}
      </Wrap>

      <Box>
        <Collapse unmountOnExit in={!!(selectedChannels.includes('slack') && deliveryRules?.slack)}>
          <Box py={4}>
            <DeliveryRule
              channel="slack"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Slack'}
              icon={channelLogos.slack}
              enabled={selectedChannels.includes('slack')}
            >
              <SlackSetup
                targetType={props.targetType}
                delivery_rules={deliveryRules}
                follow_rule={props.follow_rule}
                setSaveDisabled={(isLoading) => {
                  if (loadingChannels['slack'] !== isLoading) {
                    setChannelLoading('slack', isLoading)
                  }
                }}
              />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse unmountOnExit in={!!(selectedChannels.includes('email') && deliveryRules?.email)}>
          <Box py={4}>
            <DeliveryRule
              channel="email"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Email'}
              icon={channelLogos.email}
              enabled={selectedChannels.includes('email')}
            >
              <EmailSetup
                delivery_rules={deliveryRules}
                setSaveDisabled={(isLoading) => {
                  if (loadingChannels['email'] !== isLoading) {
                    setChannelLoading('email', isLoading)
                  }
                }}
              />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse unmountOnExit in={!!(selectedChannels.includes('webhook') && deliveryRules?.webhook)}>
          <Box py={4}>
            <DeliveryRule
              channel="webhook"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Webhook'}
              icon={channelLogos.webhook}
              enabled={selectedChannels.includes('webhook')}
            >
              <WebhookSetup
                delivery_rules={deliveryRules}
                targetType={props.targetType}
                setSaveDisabled={(isLoading) => {
                  if (loadingChannels['webhook'] !== isLoading) {
                    setChannelLoading('webhook', isLoading)
                  }
                }}
                suggestedMappings={
                  props.targetType === 'Profile' && props.isWaterfallEnriching
                    ? [
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.recommended_email',
                          webhook_key: 'enrichment.email',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.recommended_work_email',
                          webhook_key: 'enrichment.work_email',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.recommended_personal_email',
                          webhook_key: 'enrichment.personal_email',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.title',
                          webhook_key: 'enrichment.title',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.first_name',
                          webhook_key: 'enrichment.first_name',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.last_name',
                          webhook_key: 'enrichment.last_name',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.linkedin_url',
                          webhook_key: 'enrichment.linkedin_url',
                          mode: 'mapped'
                        },
                        {
                          id: nanoid(),
                          koala_key: 'visitor.enrichment.department',
                          webhook_key: 'enrichment.department',
                          mode: 'mapped'
                        }
                      ]
                    : undefined
                }
              />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse unmountOnExit in={!!(selectedChannels.includes('hubspot') && deliveryRules?.hubspot)}>
          <Box py={4}>
            <DeliveryRule
              channel="hubspot"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'HubSpot'}
              icon={channelLogos.hubspot}
              enabled={selectedChannels.includes('hubspot')}
            >
              <HubspotSetup
                delivery_rules={deliveryRules}
                targetType={props.targetType}
                compact
                setSaveDisabled={(isLoading) => {
                  if (loadingChannels['hubspot'] !== isLoading) {
                    setChannelLoading('hubspot', isLoading)
                  }
                }}
                suggestedMappings={
                  props.isWaterfallEnriching
                    ? {
                        contact: [
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.recommended_email',
                            hubspot: 'email',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.recommended_work_email',
                            hubspot: 'work_email',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.title',
                            hubspot: 'jobtitle',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.department',
                            hubspot: 'job_function',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.seniority',
                            hubspot: 'seniority',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.first_name',
                            hubspot: 'firstname',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.last_name',
                            hubspot: 'lastname',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.linkedin_url',
                            hubspot: 'hs_linkedin_url',
                            mode: 'mapped'
                          }
                        ]
                      }
                    : undefined
                }
              />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse unmountOnExit in={!!(selectedChannels.includes('salesforce') && deliveryRules?.salesforce)}>
          <Box py={4}>
            <DeliveryRule
              channel="salesforce"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Salesforce'}
              icon={channelLogos.salesforce}
              enabled={selectedChannels.includes('salesforce')}
            >
              <SalesforceSetup
                delivery_rules={deliveryRules}
                targetType={props.targetType}
                skipBoxes
                setSaveDisabled={(isLoading) => {
                  if (loadingChannels['salesforce'] !== isLoading) {
                    setChannelLoading('salesforce', isLoading)
                  }
                }}
                suggestedMappings={
                  props.isWaterfallEnriching
                    ? {
                        contact: [
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.recommended_email',
                            salesforce: 'Email',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.title',
                            salesforce: 'Title',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.first_name',
                            salesforce: 'FirstName',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.last_name',
                            salesforce: 'LastName',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.linkedin_url',
                            salesforce: 'LinkedinUrl',
                            mode: 'mapped'
                          },
                          {
                            id: nanoid(),
                            koala: 'visitor.enrichment.department',
                            salesforce: 'Department',
                            mode: 'mapped'
                          }
                        ]
                      }
                    : undefined
                }
              />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse unmountOnExit in={!!(selectedChannels.includes('outreach') && deliveryRules?.outreach)}>
          <Box py={4}>
            <DeliveryRule
              channel="outreach"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Outreach'}
              icon={channelLogos.outreach}
              enabled={selectedChannels.includes('outreach')}
            >
              <OutreachSetup
                delivery_rules={deliveryRules}
                setSaveDisabled={(isLoading) => {
                  if (loadingChannels['outreach'] !== isLoading) {
                    setChannelLoading('outreach', isLoading)
                  }
                }}
              />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse unmountOnExit in={!!(selectedChannels.includes('apollo') && deliveryRules?.apollo)}>
          <Box py={4}>
            <DeliveryRule
              channel="apollo"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Apollo'}
              icon={channelLogos.apollo}
              enabled={selectedChannels.includes('apollo')}
            >
              <ApolloSetup
                delivery_rules={deliveryRules}
                setSaveDisabled={(isLoading) => {
                  if (loadingChannels['apollo'] !== isLoading) {
                    setChannelLoading('apollo', isLoading)
                  }
                }}
              />
            </DeliveryRule>
          </Box>
        </Collapse>

        <Collapse unmountOnExit in={!!(selectedChannels.includes('instantly') && deliveryRules?.instantly)}>
          <Box py={4}>
            <DeliveryRule
              channel="instantly"
              deliveryRules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              title={'Instantly'}
              icon={channelLogos.instantly}
              enabled={selectedChannels.includes('instantly')}
            >
              <InstantlySetup
                delivery_rules={deliveryRules}
                setSaveDisabled={(isLoading) => {
                  if (loadingChannels['instantly'] !== isLoading) {
                    setChannelLoading('instantly', isLoading)
                  }
                }}
              />
            </DeliveryRule>
          </Box>
        </Collapse>
      </Box>
    </FormControl>
  )
}
