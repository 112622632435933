import { useMutation } from '@tanstack/react-query'
import { put } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'

interface Data {
  errors?: string[]
  message?: string
  success?: boolean
}

interface Setting {
  key: string
  setting_type: string
  value: any
}

function emitSettingsChangedEvent(setting: Setting) {
  window.dispatchEvent(new CustomEvent('settingschanged', { detail: setting }))
}

export function useUpdateSetting() {
  return useMutation<Data, unknown, Setting>({
    mutationFn: (setting: Setting) => {
      const path = projectPath(`/user-settings`)
      return put<Data>(path, { setting }).then((res) => {
        emitSettingsChangedEvent(setting)
        return res
      })
    }
  })
}
