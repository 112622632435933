import { useMutation } from '@tanstack/react-query'
import { post } from '../../lib/api'
import { StaticList } from '../../types/StaticList'
import { projectPath } from '../ui/ProjectsContext'
import { FacetFilters } from '../pages/accounts'

interface ListItemParams {
  listId: string
  recordIds: string[]
}

export function useRemoveListItems() {
  return useMutation({
    mutationFn: ({ listId, recordIds }: ListItemParams) => {
      const path = projectPath(`/lists/${listId}/records/remove`)
      return post(path, {
        record_ids: recordIds
      })
    }
  })
}

interface CreateAndAddListParams {
  listId?: string | null
  list?: {
    name: string
    description?: string
    kind: 'account' | 'profile'
  }
  recordIds?: string[]
  facets?: FacetFilters
}

interface AddListItemsResponse {
  list: StaticList
  success?: boolean
}

export function useAddListItems() {
  return useMutation<AddListItemsResponse, unknown, CreateAndAddListParams>({
    mutationFn: ({ facets, recordIds, listId, list }) => {
      const path = listId ? projectPath(`/lists/${listId}/records`) : projectPath('/lists')

      const payload = {
        facets: facets,
        record_ids: recordIds || [],
        static_list: list
      }

      return post(path, payload)
    }
  })
}
