import { useState, useEffect } from 'react'
import { concurrentCachedGET } from '../../../../lib/api'
import { projectPath } from '../../../ui/ProjectsContext'
import { ProspectRecommendations } from '../types'

const MOCK_RECOMMENDATIONS: ProspectRecommendations = {
  dealRisks: [
    { description: 'Explored AI content generation capabilities and templates' },
    { description: 'Viewed multilingual content creation features' },
    { description: 'Engaged with collaboration workflow documentation' }
  ],
  engagementRecords: [
    {
      personName: 'Jennifer Chen',
      activities: [
        'Explored team collaboration features and workflows',
        'Reviewed content template customization options',
        'Engaged with brand voice configuration settings'
      ]
    },
    {
      personName: 'Jennifer Chen',
      activities: [
        'Reviewed enterprise collaboration capabilities',
        'Explored content performance analytics',
        'Engaged with multilingual content features'
      ]
    },
    {
      personName: 'Jennifer Chen',
      activities: [
        'Explored AI content generation capabilities and templates',
        'Viewed multilingual content creation features',
        'Engaged with collaboration workflow documentation'
      ]
    }
  ],
  techStack: [
    { name: 'Salesforce', isPresent: true },
    { name: 'Hubspot', isPresent: true },
    { name: 'Outreach', isPresent: true }
  ]
}

export const useProspectRecommendations = (playItemId: string) => {
  const [loading, setLoading] = useState(true)
  const [recommendations, setRecommendations] = useState<ProspectRecommendations | null>(null)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        setLoading(true)
        // Simulate API call
        await concurrentCachedGET<ProspectRecommendations>(projectPath(`/play-items/${playItemId}/v2`)).then((res) => {
          setRecommendations({
            ...MOCK_RECOMMENDATIONS,
            ...res
          } as ProspectRecommendations)
          setError(null)
        })

        setError(null)
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to fetch recommendations'))
        setRecommendations(null)
      } finally {
        setLoading(false)
      }
    }

    if (playItemId) {
      fetchRecommendations()
    }

    return () => {
      // Cleanup if needed
    }
  }, [playItemId])

  return { loading, recommendations, error }
}
