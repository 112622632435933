import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { Apps } from '../../types/App'
import { ProjectActions } from '../../types/AppActions'
import { KQLByDay } from '../../types/KQL'
import { ProfileRecord } from '../../types/Profile'
import { KqlDefinition } from '../pages/kql_definitions/types'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  profile: ProfileRecord
  apps: Apps
  kqls_by_day: KQLByDay[]
  kql_definitions: KqlDefinition[]
  project_actions: ProjectActions
}

interface Props {
  id?: string | null
  email?: string | null
}

export function useProfile({ id, email }: Props = {}) {
  const project = useCurrentProject()

  const path = projectPath(`/profiles/${id || email}`)

  return useQuery<Data>({
    queryKey: ['profile', { projectId: project?.id, id, email }],
    queryFn: () => concurrentGET<Data>(path),
    enabled: Boolean(project?.id && (id || email))
  })
}
