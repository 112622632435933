import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { CrmUser, useCrmUsers } from '../../data/use-crm-users'
import { User, useUsers } from '../../data/use-users'
import { projectPath } from '../../ui/ProjectsContext'
import { UserSelector } from '../../ui/UserSelector'

interface NewMissionControlModalProps extends UseDisclosureProps {
  existingUserIds: string[]
  existingEmails?: string[]
}

const emptyArray = []

export function NewMissionControlModal(props: NewMissionControlModalProps) {
  const disclosure = useDisclosure(props)
  const users = useUsers()
  const crm = useCrmUsers()

  const allUsers = useMemo(() => {
    const all: Array<User | CrmUser> = [...(users.data?.users || emptyArray)]
    const emails = all.flatMap((u) => (u as User).emails)

    const crmUsers = crm.data?.users || emptyArray
    for (const user of crmUsers) {
      if (!emails.includes(user.email)) {
        all.push(user)
        emails.push(user.email)
      }
    }

    return all
  }, [users.data?.users, crm.data?.users])

  const remainingUsers = useMemo(() => {
    return allUsers.filter(
      (user) => !props.existingUserIds.includes(user.id) && !props.existingEmails?.includes(user.email)
    )
  }, [props.existingUserIds, props.existingEmails, allUsers])

  const [userId, setUserId] = useState<string | null>(null)
  const user = allUsers.find((user) => user.id === userId)

  return (
    <Modal {...disclosure} size="lg" preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Set up Mission Control</ModalHeader>
        <ModalBody>
          <Stack spacing={6}>
            <FormControl>
              <FormLabel>Whose Mission Control do you want to set up?</FormLabel>
              <UserSelector
                users={remainingUsers}
                selectedUserId={userId}
                onChange={(newUserId) => setUserId(newUserId || null)}
                variant="outline"
                popperOptions={{
                  matchWidth: true
                }}
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button
            size="sm"
            colorScheme="purple"
            as="a"
            href={user?.email ? projectPath(`/mission-control/${user?.email}`) : undefined}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
