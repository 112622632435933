import { Box, Button, Flex, FormControl, FormLabel, Stack, Icon, IconButton, Switch, Text } from '@chakra-ui/react'
import { IconPlus, IconFilter, IconUsers, IconExternalLink } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Apps } from '../../../../types/App'
import { PlayExclusionConfig, PlayTargetType } from '../../../../types/Play'
import { GrayCard } from '../../../ui/Card'
import { useNamedFilterList } from '../../../data/use-named-filters'
import { projectPath } from '../../../ui/ProjectsContext'
import { NamedFilterFormModal } from '../../named_filters/components/formModal'
import { NamedFilter } from '../../../ui/filters/types'
import { BuildingIcon } from '../../../ui/icons'

interface ExclusionFiltersSetupProps {
  apps?: Apps
  targetType: PlayTargetType
  setTargetType: (targetType: PlayTargetType) => void
  exclusionConfig?: PlayExclusionConfig
}

export function ExclusionFiltersSetup({ targetType = 'Account', setTargetType, ...props }: ExclusionFiltersSetupProps) {
  const [namedFiltersIds] = useState<string[]>(props.exclusionConfig?.named_filter_ids || [])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [namedFilters, setNamedFilters] = useState<NamedFilter[]>([])
  const [selectedNamedFilters, setSelectedNamedFilters] = useState<NamedFilter[]>([])

  const filterSelectedNamedFilters = (namedFilters: NamedFilter[], ids: string[]): NamedFilter[] => {
    return namedFilters.filter((filter) => ids.includes(filter.id))
  }

  const { isLoading: isLoading, data: namedFiltersList } = useNamedFilterList(targetType)

  useEffect(() => {
    if (isLoading) return

    setNamedFilters(namedFiltersList?.named_filters || [])
    setSelectedNamedFilters(filterSelectedNamedFilters(namedFiltersList?.named_filters || [], namedFiltersIds))
  }, [namedFiltersList, namedFiltersIds, isLoading])

  return (
    <GrayCard flexDirection="column" gap={5}>
      <input type="hidden" name="play[exclusion_config][target_type]" value={targetType} />

      <FormControl as={Stack} spacing={6}>
        <Flex alignItems="center" gap={4}>
          <Flex flex="none" boxSize={6}>
            <IconFilter size={20} />
          </Flex>
          <Box flex="1 1 auto">
            <FormLabel cursor="pointer" mb={0}>
              {`Choose the filters to exclude matching ${targetType == 'Account' ? 'companies' : 'people'}`}
            </FormLabel>
            <Text fontSize="13px" lineHeight="19px" color="gray.500">
              {`Prevent ${targetType == 'Account' ? 'companies' : 'people'} from being enrolled if they match one of the chosen filters`}
            </Text>
          </Box>

          <Button
            isLoading={isLoading}
            variant="outline"
            bg="white"
            size="sm"
            leftIcon={<IconPlus size={16} />}
            onClick={() => setIsModalOpen(true)}
          >
            New
          </Button>
        </Flex>

        <Flex
          flexDirection="column"
          gap={2}
          bg="white"
          p={4}
          pb={2}
          borderRadius={6}
          border="1px solid"
          borderColor="gray.200"
        >
          {namedFilters.map((filter) => (
            <Flex key={filter.id} flexDirection="row" gap={2} alignItems="top" justifyContent="start">
              <Switch
                size="sm"
                pt={1}
                name="play[exclusion_config][named_filter_ids][]"
                id={`named_filter_${filter.id}`}
                value={filter.id}
                isChecked={selectedNamedFilters.includes(filter)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedNamedFilters([...selectedNamedFilters, filter])
                  } else {
                    setSelectedNamedFilters(selectedNamedFilters.filter((f) => f.id !== filter.id))
                  }
                }}
                colorScheme="purple"
              />

              <Flex flexDirection="column" gap={2} pl={2} alignItems="flex-start" w="100%">
                <FormLabel
                  fontSize="sm"
                  fontWeight="medium"
                  htmlFor={`named_filter_${filter.id}`}
                  cursor="pointer"
                  pt={0}
                >
                  {filter.name}{' '}
                  {filter.target_type === 'Account' ? <BuildingIcon size={16} /> : <Icon as={IconUsers} size={16} />}
                  <Flex flexDirection="row" gap={2} alignItems="center">
                    <Text fontSize="xs" color="gray.500">
                      {filter.description ||
                        `Exclude ${filter.target_type === 'Account' ? 'companies' : 'people'} that match the filter criteria`}
                    </Text>
                  </Flex>
                </FormLabel>
              </Flex>

              <Flex flexDirection="column" gap={2} alignItems="center">
                <IconButton
                  aria-label="View filter"
                  icon={<IconExternalLink size={16} />}
                  size="sm"
                  variant="ghost"
                  color="gray.500"
                  _hover={{ bg: 'gray.50' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    window.open(projectPath(`/settings/named-filters?id=${filter.id}`), '_blank')
                  }}
                />
              </Flex>
            </Flex>
          ))}
        </Flex>
      </FormControl>

      <NamedFilterFormModal
        targetType={targetType}
        namedFilter={null}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={(addedNamedFilter) => {
          setNamedFilters((prev) => [...prev, addedNamedFilter])
          setSelectedNamedFilters((prev) => [...prev, addedNamedFilter])
          setIsModalOpen(false)
        }}
        canChangeTargetType={false}
        modalTitle="Create Exclusion Filter"
      />
    </GrayCard>
  )
}
