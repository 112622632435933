import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text
} from '@chakra-ui/react'
import { IconCheck, IconX } from '@tabler/icons-react'
import React from 'react'
import { dataTypeIcons } from '../../field_definitions/components/FieldDefinitionModal'
import { QualificationResult } from '../../../../types/Play'

interface QualificationResultsDrawerProps {
  isOpen: boolean
  onClose: () => void
  results: QualificationResult[]
}

const operatorLabels: Record<string, string> = {
  equals: 'equals',
  contains: 'contains',
  not_contains: 'does not contain',
  starts_with: 'starts with',
  ends_with: 'ends with',
  greater_than: 'is greater than',
  less_than: 'is less than',
  greater_than_or_equal: 'is greater than or equal to',
  less_than_or_equal: 'is less than or equal to',
  contains_any: 'contains any of',
  contains_all: 'contains all of'
}

export const QualificationResultsDrawer: React.FC<QualificationResultsDrawerProps> = ({ isOpen, onClose, results }) => {
  if (!results || results.length === 0) {
    return null
  }

  const formatValue = (value: any, type: string) => {
    if (value === null || value === undefined) return 'N/A'
    
    if (type === 'boolean') {
      return value ? 'Yes' : 'No'
    }
    
    if (Array.isArray(value)) {
      return value.join(', ')
    }
    
    return String(value)
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Qualification Results</DrawerHeader>
        <DrawerBody>
          <Stack spacing={6} py={4}>
            {results.map((result) => (
              <Box key={result.id} p={4} borderWidth="1px" borderRadius="md" bg="white">
                <Stack spacing={3}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <HStack>
                      <Icon
                        as={dataTypeIcons[result.response_type] || dataTypeIcons.string}
                        boxSize={4}
                        color="gray.600"
                      />
                      <Text fontWeight="bold">{result.name}</Text>
                    </HStack>
                    <Tag
                      size="sm"
                      colorScheme={result.matches ? 'green' : 'red'}
                      borderRadius="full"
                      variant="subtle"
                    >
                      <TagLeftIcon boxSize="12px" as={result.matches ? IconCheck : IconX} />
                      <TagLabel>{result.matches ? 'Match' : 'No Match'}</TagLabel>
                    </Tag>
                  </Flex>

                  <Stack spacing={1}>
                    <Text fontSize="sm" color="gray.600">
                      Answer: <Text as="span" fontWeight="medium">{formatValue(result.answer, result.response_type)}</Text>
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      Criteria: Answer {operatorLabels[result.operator] || result.operator}{' '}
                      <Text as="span" fontWeight="medium">{formatValue(result.expected, result.response_type)}</Text>
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
