import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CloseButton,
  Code,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  ListItem,
  Select,
  Spinner,
  Stack,
  Text,
  Tooltip,
  UnorderedList
} from '@chakra-ui/react'
import { IconArrowRight, IconCirclePlus, IconCodePlus, IconExternalLink } from '@tabler/icons-react'
import { default as React, useCallback, useMemo } from 'react'
import { SlackChannel } from '../../../../../types/Slack'
import { useSlack } from '../../../../data/use-slack'
import { HelpTooltip } from '../../../../ui/HelpTooltip'
import { HoverCard } from '../../../../ui/HoverCard'
import { ChannelPicker } from '../../../account_view_subscriptions/components/Slack'
import { PopupConnectDialog } from '../../../apps/components/ConnectOauthAppDialog'
import { ReconnectionRequiredWarning } from '../../../apps/components/ReconnectionRequiredWarning'
import { MessageBuilder } from '../slack-message-builder/message-builder'
import { SlackMessageTemplate } from '../slack-message-builder/types'
import { TimeTrigger } from '../time-trigger'
import { FollowRule } from '../../../../../types/FollowRule'

export const availableVariables = (targetType: 'Account' | 'Profile') => {
  return [
    '{{actor}}',
    '{{notification.name}}',
    `{{account.name}}`,
    '{{account.domain}}',
    `{{visitor.display_name}}`,
    `{{visitor.email}}`,
    `{{visitor.title}}`,
    `{{visitor.location}}`
  ].filter((entry) => {
    if (targetType === 'Account') {
      return !entry.includes('visitor')
    } else {
      return entry
    }
  })
}

interface SlackSetupProps {
  delivery_rules?: FollowRule['delivery_rules']
  follow_rule?: FollowRule
  targetType: 'Account' | 'Profile'
  setSaveDisabled?: (value: boolean) => void
}

export function SlackSetup(props: SlackSetupProps) {
  const slackData = useSlack()

  const disconnected = useMemo(() => !slackData.isLoading && !slackData.data?.connected, [slackData])
  const invalid = useMemo(() => !slackData.isLoading && !slackData.data?.valid, [slackData])
  const channels = useMemo(() => slackData.data?.deps?.channels ?? [], [slackData])

  const onSlackConnected = useCallback(() => {
    slackData.refetch()
  }, [slackData])

  if (slackData.isLoading) {
    props.setSaveDisabled?.(true)
    return <Spinner />
  } else {
    props.setSaveDisabled?.(false)
  }

  return (
    <>
      {slackData.data && disconnected && (
        <PopupConnectDialog app_id={slackData.data?.app_id} onConnected={onSlackConnected}>
          {({ onStart }) => (
            <Stack spacing="2">
              <Stack spacing="0">
                <Heading size="sm">Connect your Slack Account</Heading>
                <Text fontSize="sm" color="gray.600">
                  Please connect your Slack account in order to get started.
                </Text>
              </Stack>
              <Button size="sm" onClick={onStart} colorScheme={'purple'}>
                Connect
              </Button>
            </Stack>
          )}
        </PopupConnectDialog>
      )}

      {slackData && !disconnected && (
        <>
          <SlackChannelSetup {...props} invalid={invalid} channels={channels} />
        </>
      )}
    </>
  )
}

function SlackChannelSetup(props: SlackSetupProps & { invalid: boolean; channels: SlackChannel[] }) {
  const { channels, invalid } = props

  const [selectedChannel, setSelectedChannel] = React.useState(props.delivery_rules?.slack?.channel_id)
  const [messageOption, setMessageOption] = React.useState(
    props.delivery_rules?.slack?.custom_message_template ? 'custom' : 'default'
  )

  const [newChannelName, setNewChannelName] = React.useState<string>()

  const [atMentions, setAtMentions] = React.useState<boolean>(
    props.delivery_rules?.slack?.mentions?.toString() === 'true'
  )

  const [customTitle, setCustomTitle] = React.useState(
    props.delivery_rules?.slack?.custom_message_template?.title ??
      props.delivery_rules?.slack?.title ??
      '{{actor}} triggered {{notification.name}}'
  )
  const [customSlackMessage, setCustomSlackMessage] = React.useState<SlackMessageTemplate | undefined>(
    props.delivery_rules?.slack?.custom_message_template ?? undefined
  )

  const [displayBanner, setDisplayBanner] = React.useState(true)

  return (
    <Box w="100%">
      <Collapse in={displayBanner}>
        <Flex py="4" pb="8">
          <Alert status="info" fontSize={'sm'} bg="blue.50" p="8" variant={'left-accent'} position="relative">
            <CloseButton position={'absolute'} right="4" top="4" onClick={() => setDisplayBanner(false)} />
            <Stack>
              <HStack spacing="0">
                <AlertIcon />
                <AlertTitle>There's a simpler way to integrate Koala and Slack</AlertTitle>
              </HStack>
              <Stack spacing="1">
                <Text>
                  The recommended way to set up Slack Alerts is by going <Link href="/goto/slack-alerts">here</Link>.
                </Text>
                <Text>Should you need an advanced Slack alert with custom variables, this is the right spot.</Text>
              </Stack>
              <Flex justifyContent={'flex-end'}>
                <Button
                  size="sm"
                  colorScheme={'blue'}
                  rightIcon={<IconArrowRight size="14" />}
                  as={Link}
                  href={'/goto/slack-alerts'}
                >
                  Manage Slack Alerts
                </Button>
              </Flex>
            </Stack>
          </Alert>
        </Flex>
      </Collapse>
      <Stack>
        {invalid && <ReconnectionRequiredWarning appTitle={'Slack'} variant="short" />}
        {channels.length > 0 && (
          <Stack spacing="4">
            <FormControl>
              <FormLabel>Slack channel</FormLabel>
              <FormHelperText>What channel do you want to receive this notification in?</FormHelperText>

              <>
                {selectedChannel && (
                  <input
                    type="hidden"
                    name="follow_rule[delivery_rules][slack][channel_id]"
                    value={selectedChannel}
                    disabled={!props.delivery_rules?.slack}
                  />
                )}

                {newChannelName && (
                  <input
                    type="hidden"
                    name="slack[channel_name]"
                    value={newChannelName}
                    disabled={!props.delivery_rules?.slack}
                  />
                )}
                <HStack w="100%" alignItems={'flex-start'}>
                  <Stack w="100%">
                    <ChannelPicker
                      isNewChannelEntered={!!newChannelName}
                      channels={channels}
                      channel={channels.find((c) => c.id === selectedChannel)}
                      setChannel={(channel, inputValue) => {
                        if (channel?.id) {
                          setSelectedChannel(channel?.id)
                          setNewChannelName(undefined)
                        } else {
                          setNewChannelName(inputValue!)
                          setSelectedChannel(undefined)
                        }
                      }}
                      chakraInputProps={{
                        size: 'sm',
                        rounded: 'md',
                        isRequired: !!props.delivery_rules?.slack,
                        isDisabled: !props.delivery_rules?.slack
                      }}
                    />

                    {newChannelName && (
                      <Stack fontSize="xs" color="gray.500">
                        <Text>
                          <b>Note:</b> a new public channel named{' '}
                          <Code fontSize="xs" fontWeight="semibold">
                            #{newChannelName}
                          </Code>{' '}
                          will be created.
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                </HStack>
              </>
            </FormControl>

            <TimeTrigger
              namespace="follow_rule[delivery_rules][slack]"
              trigger={props.delivery_rules?.slack?.trigger}
              disabled={!props.delivery_rules?.slack}
            />

            <FormControl>
              <FormLabel>Title</FormLabel>
              <FormHelperText fontSize="xs" marginBottom={2} color="gray.600">
                Describe the notification as a sentence or phrase. You can use{' '}
                <HoverCard
                  hoverContent={
                    <Stack fontSize={'sm'} p="4" color="gray.900">
                      <HStack>
                        <Icon as={IconCodePlus} color="purple.500" boxSize={4} />
                        <Heading size="xs">Variables</Heading>
                      </HStack>
                      <Divider />
                      <Stack pt="2">
                        <UnorderedList pl="4" spacing="0" fontSize={'xs'}>
                          {availableVariables(props.targetType).map((variable) => (
                            <ListItem
                              key={variable}
                              _hover={{
                                bg: 'gray.50',
                                fontWeight: 'semibold'
                              }}
                              p="1"
                              cursor={'pointer'}
                            >
                              <Tooltip hasArrow label={`Add ${variable} to title`} placement="right">
                                <HStack
                                  justifyContent="space-between"
                                  onClick={() => {
                                    setCustomTitle((prev) =>
                                      !prev.length || /\s+$/.test(prev) ? prev + variable : prev + ' ' + variable
                                    )
                                  }}
                                >
                                  <Text>{variable}</Text>
                                  <IconButton
                                    icon={<IconCirclePlus size={12} />}
                                    aria-label="Add"
                                    size="xs"
                                    variant="ghost"
                                  />
                                </HStack>
                              </Tooltip>
                            </ListItem>
                          ))}
                        </UnorderedList>
                      </Stack>
                    </Stack>
                  }
                >
                  <Link fontWeight="semibold" color="gray.700" textDecoration="underline" textDecorationStyle="dashed">
                    variables
                  </Link>
                </HoverCard>{' '}
                in your title and message body.
              </FormHelperText>
              <Input
                size="sm"
                rounded="md"
                name="follow_rule[delivery_rules][slack][title]"
                value={customTitle}
                onChange={(e) => setCustomTitle(e.target.value)}
                isDisabled={!props.delivery_rules?.slack}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Message</FormLabel>
              <Stack>
                <Select
                  size="sm"
                  rounded="md"
                  value={messageOption}
                  onChange={(e) => setMessageOption(e.target.value)}
                  isDisabled={!props.delivery_rules?.slack}
                >
                  <option value="default">Default Message</option>
                  <option value="custom">Custom Message</option>
                </Select>
                {messageOption === 'custom' && (
                  <>
                    <MessageBuilder
                      targetType={props.targetType}
                      onChange={setCustomSlackMessage}
                      template={{ ...customSlackMessage, title: customTitle }}
                    />
                    {customSlackMessage && (
                      <input
                        type="hidden"
                        name="follow_rule[delivery_rules][slack][custom_message_template]"
                        value={JSON.stringify(customSlackMessage)}
                        disabled={!props.delivery_rules?.slack}
                      />
                    )}
                  </>
                )}
              </Stack>
            </FormControl>

            <FormControl>
              <input
                value={atMentions ? 'true' : 'false'}
                name="follow_rule[delivery_rules][slack][mentions]"
                type="hidden"
              />
              <Checkbox isChecked={atMentions} onChange={(e) => setAtMentions(e.target.checked)} marginTop={4}>
                <HStack>
                  <Text fontSize="sm">@mention Account Owners in Slack</Text>
                  <HelpTooltip mode="popover">
                    <Stack>
                      <Text>
                        We will mention the Account Owner in Slack using their email address from your CRM. The
                        mentioned user will also be notified about the reference in Slack.
                      </Text>
                      <Link
                        display="flex"
                        alignItems="center"
                        gap={1}
                        href="https://slack.com/help/articles/205240127-Use-mentions-in-Slack"
                        color="purple.500"
                        isExternal
                      >
                        Learn more about mentions in Slack <Icon as={IconExternalLink} boxSize={3.5} />
                      </Link>
                    </Stack>
                  </HelpTooltip>
                </HStack>
              </Checkbox>
            </FormControl>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
