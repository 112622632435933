import React from 'react'
import { Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { App } from '../../../../types/App'
import { HighlightedAccount } from '../../icps/types'
import { PlayItem } from '../../../../types/Play'
import { useAccountPlayProspects } from '../../../data/use-account-play-prospects'
import { SelectProspectsScreen } from './AccountEmailButton'
import { AddToOutreachSequenceModal } from '../../../ui/AddToOutreachSequenceModal'

interface SelectProspectsAndAddToSequenceModalProps {
  onClose: () => void
  outreachApp: App
  selectedAccount: HighlightedAccount & { item: PlayItem }
}

export const SelectProspectsAndAddToSequenceModal = ({
  onClose,
  outreachApp,
  selectedAccount
}: SelectProspectsAndAddToSequenceModalProps) => {
  const { data, isLoading } = useAccountPlayProspects(selectedAccount.item)

  const [selectedProspectsForSequence, setSelectedProspectsForSequence] = React.useState<PlayItem[]>([])

  const disclosure = useDisclosure({ defaultIsOpen: true })

  React.useEffect(() => {
    disclosure.onOpen()
  }, [selectedAccount.id, disclosure])

  const handleSelectMultipleProspects = (prospects: PlayItem[]) => {
    if (prospects && prospects.length > 0) {
      setSelectedProspectsForSequence(prospects)
      disclosure.onClose()
    }
  }

  const handleModalClose = () => {
    disclosure.onClose()
    if (selectedProspectsForSequence.length === 0) {
      onClose()
    }
  }

  return (
    <>
      {selectedProspectsForSequence.length === 0 && (
        <Modal isOpen={disclosure.isOpen} onClose={handleModalClose} size="lg" isCentered scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent overflowY="auto" overflowX="hidden" width="unset">
            <SelectProspectsScreen
              onClose={handleModalClose}
              isLoading={isLoading}
              prospects={data?.play_items || []}
              onUpdateItem={(itemId, item) => {
                console.log(itemId, item)
              }}
              onSelectMultipleProspects={handleSelectMultipleProspects}
              multiple
            />
          </ModalContent>
        </Modal>
      )}

      {selectedProspectsForSequence.length > 0 && (
        <AddToOutreachSequenceModal
          isOpen
          app={outreachApp}
          profileType="Profile"
          selectedProfiles={selectedProspectsForSequence.map((prospect) => prospect.record)}
          onClose={() => {
            onClose()
            setSelectedProspectsForSequence([])
          }}
        />
      )}
    </>
  )
}
