import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  BoxProps
} from '@chakra-ui/react'
import { IconPlus, IconRepeat, IconDotsVertical } from '@tabler/icons-react'
import { deepEqual } from 'fast-equals'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import omit from 'lodash/omit'
import React, { useCallback, useMemo, useState } from 'react'
import dayjs from '../../../lib/dayjs'
import { getFacetOperator, getFacetOperatorLabel, getFacetValues } from '../../data/use-facets'
import { FilterPreviewProps } from '../../pages/accounts/components/FilterPreview/types'
import FilterTag from '../../pages/accounts/components/FilterTag'
import { getItemDisplay } from '../../pages/accounts/facets/categories'
import { periodShorthands } from '../../pages/accounts/facets/filter-cloud'
import FilterPopover from '../../pages/accounts/facets/filter-popover'
import useLatestRef from '../useLatestRef'
import useUpdateEffect from '../useUpdateEffect'
import { AdvancedFilters, Facet, FacetFilters, FilterArray, FilterGroup } from './types'

interface Props extends FilterPreviewProps {
  filters: AdvancedFilters
  onChangeAdvancedFilters?: (filters: AdvancedFilters) => void
  onApplyAdvancedFilters?: (filters: AdvancedFilters) => void
  onClearAdvancedFilters?: () => void
  convertTopLevelFilters?: boolean
  wrapperProps?: BoxProps
  emptyFiltersMessage?: string
}

// converts objects like this
// {
//   _or: [
//     { foo: true, bar: true },
//     { baz: true}
//   ]
// }
//
// to this:
// {
//   _or: [
//     {
//       _and: [
//         { foo: true },
//         { bar: true }
//       ]
//     },
//     { baz: true }
//   ]
// }
function convertToAdvancedFilters(obj: unknown, requireOperator = false): AdvancedFilters {
  // Return empty array for non-objects
  if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
    return {}
  }

  // remove undefined values
  let cloned: AdvancedFilters = omitBy(obj, isUndefined)

  if (requireOperator && Object.keys(cloned).length > 1) {
    const wrapped: FilterArray = []

    for (const [key, value] of Object.entries(cloned)) {
      if (!value) continue

      if (key === '_and') {
        wrapped.push(...(value as FilterArray))
      } else {
        wrapped.push({
          [key]: value
        })
      }
    }

    cloned = { _and: wrapped }
  }

  // Handle special operators (_and, _or)
  if ('_and' in cloned || '_or' in cloned) {
    const operator = '_and' in cloned ? '_and' : '_or'
    const filters = cloned[operator] as FilterArray

    // Process each item in the array to ensure they're properly formatted
    if (Array.isArray(filters)) {
      cloned[operator] = filters.map((filter) => {
        if (filter && typeof filter === 'object' && Object.keys(filter).length > 1) {
          const arrayified = Object.entries(filter).map(([key, value]) => ({ [key]: value }))
          return {
            _and: arrayified
          }
        }

        return filter
      })
    }
  }

  return cloned
}

const emptyArray = []

export function AdvancedFilterBuilder({
  filters,
  onChangeAdvancedFilters,
  onApplyAdvancedFilters,
  onClearAdvancedFilters,
  convertTopLevelFilters = false,
  wrapperProps,
  emptyFiltersMessage = 'No conditions added yet',
  ...rest
}: Props) {
  const [localFilters, setLocalFilters] = useState<AdvancedFilters>(
    convertToAdvancedFilters(filters, convertTopLevelFilters)
  )

  // default to _and if no operator is set
  const operator = localFilters._or ? '_or' : '_and'

  const filterGroup = useMemo(() => {
    return (localFilters[operator] as FilterGroup['_and']) || []
  }, [localFilters, operator])

  const onApplyRef = useLatestRef(onApplyAdvancedFilters)
  const onClearRef = useLatestRef(onClearAdvancedFilters)
  const onChangeRef = useLatestRef(onChangeAdvancedFilters)
  const filtersRef = useLatestRef(filters)

  useUpdateEffect(() => {
    // only update if actually changed
    if (deepEqual(localFilters, filtersRef.current)) {
      return
    }

    onChangeRef.current?.(localFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFilters])

  useUpdateEffect(() => {
    setLocalFilters(convertToAdvancedFilters(filters, convertTopLevelFilters))
  }, [convertTopLevelFilters, filters])

  const handleApply = useCallback(() => {
    onApplyRef.current?.(localFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFilters])

  const handleClear = useCallback(() => {
    setLocalFilters({})
    onClearRef.current?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleParentOperator = useCallback(() => {
    setLocalFilters((prev) => {
      const current = prev._or ? '_or' : '_and'
      const operator = prev._or ? '_and' : '_or'

      return {
        ...prev,
        [current]: undefined,
        [operator]: prev[current] || []
      }
    })
  }, [])

  const toggleGroupOperator = useCallback((filterIndex: number) => {
    setLocalFilters((prev) => {
      const currentOperator = prev._or ? '_or' : '_and'

      return {
        ...prev,
        [currentOperator]:
          (prev[currentOperator] as any)?.map((filter, index) => {
            if (index === filterIndex) {
              const nestedOperator = filter._or ? '_or' : '_and'
              const newOperator = filter._or ? '_and' : '_or'
              const existing = Array.from(filter[nestedOperator] || [])
              return omit({ ...filter, [newOperator]: existing }, nestedOperator)
            }
            return filter
          }) || []
      }
    })
  }, [])

  const addGroup = useCallback(() => {
    setLocalFilters((prev) => {
      const currentOperator = prev._or ? '_or' : '_and'
      const currentFilters = (prev[currentOperator] as FilterGroup['_and']) || []

      return {
        ...prev,
        [currentOperator]: [
          ...currentFilters,
          { _and: [] } // Add a new empty group with AND operator
        ]
      }
    })
  }, [])

  const addFilterToGroup = useCallback((filters: FacetFilters, groupIndex: number) => {
    setLocalFilters((prev) => {
      const currentOperator = prev._or ? '_or' : '_and'
      const currentFilters = (prev[currentOperator] as FilterGroup['_and']) || []

      return {
        ...prev,
        [currentOperator]: currentFilters.map((existing, index) => {
          if (index === groupIndex) {
            const nestedOperator = '_and' in existing ? '_and' : '_or' in existing ? '_or' : undefined

            if (nestedOperator) {
              const nestedFilters = (existing[nestedOperator] || []) as FacetFilters[]

              return {
                ...existing,
                [nestedOperator]: nestedFilters.concat(filters)
              }
            }
          }
          return existing
        })
      }
    })
  }, [])

  const updateFilterInGroup = useCallback((filters: FacetFilters, groupIndex: number, groupItemIndex?: number) => {
    setLocalFilters((prev) => {
      const currentOperator = prev._or ? '_or' : '_and'
      const currentFilters = Array.from((prev[currentOperator] as FilterGroup['_and']) || [])

      if (typeof groupItemIndex === 'number') {
        // Handle updating a filter within a nested group
        const group = currentFilters[groupIndex]
        const groupOperator = '_and' in group ? '_and' : '_or'
        const nestedFilters = Array.from((group[groupOperator] as FacetFilters[]) || [])

        // Replace the filter at the specified index
        nestedFilters[groupItemIndex] = filters

        // Update the group with the new nested filters
        currentFilters[groupIndex] = {
          ...group,
          [groupOperator]: nestedFilters
        }
      } else {
        // Handle updating a top-level filter
        currentFilters[groupIndex] = filters
      }

      return {
        ...prev,
        [currentOperator]: currentFilters
      }
    })
  }, [])

  const removeGroup = useCallback((groupIndex: number) => {
    setLocalFilters((prev) => {
      const currentOperator = prev._or ? '_or' : '_and'
      const currentFilters = (prev[currentOperator] as FilterGroup['_and']) || []

      // Create a new array without the group at the specified index
      const updatedFilters = currentFilters.filter((_, index) => index !== groupIndex)

      return {
        ...prev,
        [currentOperator]: updatedFilters
      }
    })
  }, [])

  const removeFilter = useCallback((groupIndex: number, facet: string, groupItemIndex?: number) => {
    setLocalFilters((prev) => {
      const currentOperator = prev._or ? '_or' : '_and'
      const currentFilters = Array.from((prev[currentOperator] as FilterGroup['_and']) || [])

      if (typeof groupItemIndex === 'number') {
        // Handle nested group filter removal
        const group = currentFilters[groupIndex]
        const groupOperator = '_and' in group ? '_and' : '_or'
        const nestedFilters = Array.from((group[groupOperator] as FacetFilters[]) || [])

        // Find and remove the filter containing this facet
        const updatedNestedFilters = nestedFilters.filter((_filter, i) => i !== groupItemIndex)

        // If there are no filters left in the nested group, remove the entire group
        if (updatedNestedFilters.length > 0) {
          currentFilters[groupIndex] = {
            ...group,
            [groupOperator]: updatedNestedFilters
          }
        } else {
          currentFilters.splice(groupIndex, 1)
        }
      } else {
        // Handle top-level filter removal
        // If this is the only filter in the group, remove the entire group
        if (Object.keys(currentFilters[groupIndex]).length === 1) {
          return {
            ...prev,
            [currentOperator]: currentFilters.filter((_, i) => i !== groupIndex)
          }
        }

        // Otherwise, just remove this specific filter from the group
        const updatedFilter = { ...currentFilters[groupIndex] }
        delete updatedFilter[facet]

        currentFilters[groupIndex] = updatedFilter
      }

      return {
        ...prev,
        [currentOperator]: currentFilters
      }
    })
  }, [])

  const convertToGroup = useCallback((groupIndex: number) => {
    setLocalFilters((prev) => {
      const currentOperator = prev._or ? '_or' : '_and'
      const currentFilters = Array.from((prev[currentOperator] as FilterGroup['_and']) || [])

      // Get the filter to convert to a group
      const filterToConvert = currentFilters[groupIndex]

      // Create a new group with the filter inside it
      const newGroup = {
        _and: [filterToConvert]
      }

      // Replace the original filter with the new group
      const updatedFilters = [...currentFilters]
      updatedFilters[groupIndex] = newGroup

      return {
        ...prev,
        [currentOperator]: updatedFilters
      }
    })
  }, [])

  const ungroupFilter = useCallback((groupIndex: number, groupItemIndex: number) => {
    setLocalFilters((prev) => {
      const currentOperator = prev._or ? '_or' : '_and'
      const currentFilters = Array.from((prev[currentOperator] as FilterGroup['_and']) || [])

      // Get the group and its items
      const group = currentFilters[groupIndex]
      const groupOperator = '_and' in group ? '_and' : '_or'
      const nestedFilters = Array.from((group[groupOperator] as FacetFilters[]) || [])

      // Extract the filter to ungroup
      const filterToUngroup = nestedFilters[groupItemIndex]

      // Remove the filter from the group
      const updatedNestedFilters = nestedFilters.filter((_, i) => i !== groupItemIndex)

      // Update the group with remaining filters
      const updatedFilters = [...currentFilters]

      if (updatedNestedFilters.length > 0) {
        updatedFilters[groupIndex] = {
          ...group,
          [groupOperator]: updatedNestedFilters
        }
        // Add the ungrouped filter at the end of the top level
        updatedFilters.push(filterToUngroup)
      } else {
        // If group is now empty, replace it with the ungrouped filter
        updatedFilters[groupIndex] = filterToUngroup
      }

      return {
        ...prev,
        [currentOperator]: updatedFilters
      }
    })
  }, [])

  return (
    <Box
      flex="1 1 auto"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      minHeight="120px"
      padding={4}
      gap={6}
      {...wrapperProps}
    >
      {filterGroup.length === 0 ? (
        <Text color="gray.500" fontSize="13px" py={1}>
          {emptyFiltersMessage}
        </Text>
      ) : (
        <Stack spacing={2}>
          {filterGroup.map((filter, groupIndex) => {
            const groupOperator = '_and' in filter ? '_and' : '_or' in filter ? '_or' : undefined
            const multipleFilters = Object.keys(filter).length > 1

            return (
              <Flex key={groupIndex} gap={4} alignItems="center">
                <Box flex="none">
                  {groupIndex === 0 ? (
                    <Button
                      size="sm"
                      width="70px"
                      border="1px dashed"
                      borderColor="gray.300"
                      color="gray.500"
                      cursor="default"
                      variant="unstyled"
                    >
                      Where
                    </Button>
                  ) : groupIndex === 1 ? (
                    <Button
                      size="sm"
                      width="70px"
                      variant="outline"
                      rightIcon={<IconRepeat size={14} />}
                      iconSpacing={2}
                      onClick={toggleParentOperator}
                    >
                      {operator === '_and' ? 'And' : 'Or'}
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      width="70px"
                      border="1px dashed"
                      borderColor="gray.300"
                      color="gray.500"
                      cursor="default"
                      variant="unstyled"
                    >
                      {operator === '_and' ? 'And' : 'Or'}
                    </Button>
                  )}
                </Box>

                {/* Render the actual filter content */}
                <Flex
                  width="100%"
                  gap={2}
                  alignItems="center"
                  flexWrap="wrap"
                  rounded="lg"
                  flex="1 1 auto"
                  minWidth="100px"
                  {...(multipleFilters || groupOperator
                    ? {
                        bg: 'gray.50',
                        borderWidth: '1px',
                        borderColor: 'gray.100',
                        padding: 3
                      }
                    : {})}
                >
                  {groupOperator ? (
                    <Stack spacing={2} width="100%">
                      {((filter[groupOperator] as FacetFilters[]) || emptyArray).map((c, groupItemIndex) => (
                        <Flex
                          key={`${groupIndex}:${groupItemIndex}`}
                          gap={3}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box flex="none">
                            {groupItemIndex === 0 ? (
                              <Button
                                size="sm"
                                width="70px"
                                border="1px dashed"
                                borderColor="gray.300"
                                color="gray.500"
                                cursor="default"
                                variant="unstyled"
                              >
                                Where
                              </Button>
                            ) : groupItemIndex === 1 ? (
                              <Button
                                size="sm"
                                width="70px"
                                variant="outline"
                                rightIcon={<IconRepeat size={14} />}
                                iconSpacing={2}
                                onClick={() => toggleGroupOperator(groupIndex)}
                              >
                                {groupOperator === '_and' ? 'And' : 'Or'}
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                width="70px"
                                border="1px solid"
                                borderColor="gray.300"
                                color="gray.500"
                                cursor="default"
                                variant="unstyled"
                              >
                                {groupOperator === '_and' ? 'And' : 'Or'}
                              </Button>
                            )}
                          </Box>

                          {Object.entries(c).map(([facet, values]) => (
                            <Box
                              key={facet}
                              flex="1"
                              minWidth="50px"
                              display="flex"
                              alignItems="center"
                              gap={2}
                              justifyContent="space-between"
                            >
                              <FilterRenderer
                                key={`${groupIndex}:${groupItemIndex}:${facet}`}
                                filter={facet}
                                values={values}
                                {...rest}
                                // this needs to be the filter object for this item in the filters array
                                facetFilters={
                                  localFilters?.[operator]?.[groupIndex]?.[groupOperator]?.[groupItemIndex] ?? {}
                                }
                                applyFilters={(filters) =>
                                  updateFilterInGroup(filters as FacetFilters, groupIndex, groupItemIndex)
                                }
                                onRemove={() => removeFilter(groupIndex, facet, groupItemIndex)}
                              />

                              <Menu isLazy lazyBehavior="keepMounted">
                                <MenuButton
                                  as={IconButton}
                                  aria-label="More options"
                                  icon={<IconDotsVertical size={14} />}
                                  variant="ghost"
                                  size="xs"
                                  ml="auto"
                                />
                                <MenuList>
                                  <MenuItem onClick={() => ungroupFilter(groupIndex, groupItemIndex)}>Ungroup</MenuItem>
                                </MenuList>
                              </Menu>
                            </Box>
                          ))}
                        </Flex>
                      ))}

                      <Flex gap={2} justifyContent="space-between">
                        <FilterPopover
                          {...rest}
                          placement="bottom-start"
                          // always start with a new object
                          facetFilters={{}}
                          applyFilters={(filters) => addFilterToGroup(filters as FacetFilters, groupIndex)}
                        >
                          <Button
                            size="sm"
                            variant="outline"
                            leftIcon={<IconPlus size={14} />}
                            iconSpacing={1.5}
                            onClick={() => {}}
                          >
                            Filter
                          </Button>
                        </FilterPopover>

                        <Button size="sm" variant="ghost" onClick={() => removeGroup(groupIndex)}>
                          Remove group
                        </Button>
                      </Flex>
                    </Stack>
                  ) : (
                    <>
                      {Object.entries(filter).map(([facet, values]) => (
                        <Box
                          key={`${groupIndex}:${facet}`}
                          width="100%"
                          flex="1"
                          minWidth="50px"
                          display="flex"
                          alignItems="center"
                          gap={2}
                          justifyContent="space-between"
                        >
                          <FilterRenderer
                            key={`${groupIndex}:${facet}`}
                            filter={facet}
                            values={values}
                            {...rest}
                            // this needs to be the filter object for this item in the filters array
                            facetFilters={localFilters?.[operator]?.[groupIndex] ?? {}}
                            applyFilters={(filters) => updateFilterInGroup(filters as FacetFilters, groupIndex)}
                            onRemove={() => removeFilter(groupIndex, facet)}
                          />

                          <Menu isLazy lazyBehavior="keepMounted">
                            <MenuButton
                              as={IconButton}
                              aria-label="More options"
                              icon={<IconDotsVertical size={14} />}
                              variant="ghost"
                              size="xs"
                              ml="auto"
                            />
                            <MenuList>
                              <MenuItem onClick={() => convertToGroup(groupIndex)}>Convert to group</MenuItem>
                            </MenuList>
                          </Menu>
                        </Box>
                      ))}
                    </>
                  )}
                </Flex>
              </Flex>
            )
          })}
        </Stack>
      )}

      {/* Top-level action buttons */}
      <Flex alignItems="center" justify="space-between" gap={4} mt="auto">
        <Flex gap={2}>
          <FilterPopover
            {...rest}
            placement="bottom-start"
            // always start with a new object
            facetFilters={{}}
            applyFilters={(filters) => {
              setLocalFilters((prev) => {
                const currentFilters = (prev[operator] as FilterGroup['_and']) || []

                return {
                  ...prev,
                  [operator]: currentFilters.concat(filters)
                }
              })
            }}
          >
            <Button leftIcon={<IconPlus size={14} />} iconSpacing={1.5} size="sm" variant="outline">
              Filter
            </Button>
          </FilterPopover>

          <Button leftIcon={<IconPlus size={14} />} iconSpacing={1.5} size="sm" variant="outline" onClick={addGroup}>
            Group
          </Button>
        </Flex>

        <Flex gap={2}>
          {onClearAdvancedFilters && (
            <Button size="sm" variant="ghost" onClick={handleClear}>
              Clear
            </Button>
          )}

          {onApplyAdvancedFilters && (
            <Button colorScheme="purple" size="sm" onClick={handleApply} isDisabled={!filterGroup.length}>
              Apply
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

interface FilterRendererProps extends FilterPreviewProps {
  filter: string
  values: Facet
  onRemove?: () => void
}

function FilterRenderer({ filter, values, onRemove, ...rest }: FilterRendererProps) {
  if (Array.isArray(values) && values.length === 0) {
    return null
  }

  const { facetMappings, apps = {}, kind, formatLabel } = rest

  const mapping = facetMappings[filter]
  const dataType = mapping?.type
  const operator = getFacetOperator(values)
  const operatorLabel = getFacetOperatorLabel(values, dataType)
  const display = getItemDisplay(filter, Object.values(apps), kind, mapping)
  let displayValues = values

  if (dataType !== 'date' && (operator == 'exists' || operator == 'not_exists')) {
    displayValues = []
  }

  return (
    <FilterTag
      maxValuesToDisplay={2}
      facet={filter}
      label={formatLabel ? formatLabel(display) : display.label}
      icon={display.icon}
      convertable={false}
      formatter={
        dataType === 'date'
          ? (values) => {
              const vals = getFacetValues(values)
              const val = Array.isArray(vals) ? vals[0] : vals
              let formatted = periodShorthands[val]

              if (!formatted) {
                if (operator === 'exists') {
                  formatted = val === 'true' ? 'anytime' : 'not set'
                } else if (operator === 'not_exists') {
                  formatted = 'not set'
                } else {
                  formatted = String(val)

                  const [first, last] = formatted.split('..')
                  if (first && last) {
                    formatted = [first, last].map((d) => dayjs(d).format('MMM D, YYYY')).join(' - ')
                  }
                }
              }
              return formatted
            }
          : undefined
      }
      operator={operatorLabel}
      value={displayValues}
      onRemove={onRemove}
      isEditable
      {...rest}
    />
  )
}
