import {
  Badge,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Tooltip
} from '@chakra-ui/react'
import { IconArchive, IconCopy, IconDots, IconEdit, IconPlus, IconEye, IconEyeOff } from '@tabler/icons-react'
import { orderBy } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { usePermission } from '../../../ui/PermissionsContext'
import { toast } from 'sonner'
import { concurrentGET, post, postForm } from '../../../../lib/api'
import { formatDate } from '../../../../lib/dayjs'
import { PrivateAPIKey } from '../../../../types/PrivateAPIKey'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { projectPath } from '../../../ui/ProjectsContext'
import { useCopyToClipboard } from '../../../ui/useCopyToClipboard'

function APIModal(props: {
  selectedKey: Partial<PrivateAPIKey>
  onClose: () => void
  setSelectedKey: (key: PrivateAPIKey) => void
  onArchive: (key: PrivateAPIKey) => void
}) {
  const [loading, setLoading] = React.useState(false)

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      const form = e.target as HTMLFormElement
      const data = new FormData(form)

      try {
        setLoading(true)
        const response = await postForm<{ private_api_key: PrivateAPIKey; errors: Record<string, string[]> }>(
          form.action,
          data
        )

        if (!response.private_api_key) return

        if (response.private_api_key && Object.keys(response.errors).length === 0) {
          props.setSelectedKey(response.private_api_key)
        }

        props.onClose()

        toast.success(`Successfully ${props.selectedKey.id ? 'updated' : 'created'} Private API Key!`)
      } catch (error: any) {
        if (error?.body?.error) {
          toast.error(error.body.error)
        } else {
          toast.error(`Failed to ${props.selectedKey.id ? 'update' : 'create'} Private API Key`)
        }
      } finally {
        setLoading(false)
      }
    },
    [props]
  )

  return (
    <Modal
      size="2xl"
      isOpen
      onClose={() => {
        props.onClose()
      }}
    >
      <AuthenticityToken />
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit} method="post" action={projectPath('/settings/api-keys')}>
          {props.selectedKey.id && <input type="hidden" name="private_api_key[id]" value={props.selectedKey.id} />}
          <ModalHeader px="8">
            {props.selectedKey.archived && <Badge>Disabled</Badge>}
            <Heading size="md" mb="2">
              {props.selectedKey.name ?? 'Create a private key'}
            </Heading>
            <Divider />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px="8">
            <Stack spacing="4" w="100%">
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  isDisabled={loading}
                  name="private_api_key[name]"
                  size="sm"
                  required
                  defaultValue={props.selectedKey.name}
                />
                <FormHelperText>What should we name your key?</FormHelperText>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter p="8">
            <HStack justifyContent={'space-between'} w="100%">
              {props.selectedKey.id && (
                <Button
                  size="sm"
                  variant={'outline'}
                  leftIcon={<IconArchive size="14" />}
                  onClick={() => props.onArchive(props.selectedKey as PrivateAPIKey)}
                >
                  {props.selectedKey.archived ? 'Enable' : 'Disable'} this key
                </Button>
              )}
              <HStack marginLeft="auto">
                <Button size="sm" onClick={props.onClose} variant="outline">
                  Cancel
                </Button>
                <Button size="sm" type="submit" colorScheme="purple" isLoading={loading}>
                  {props.selectedKey.id ? 'Update key' : 'Create key'}
                </Button>
              </HStack>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export function PrivateAPIKeys(props: { keys: PrivateAPIKey[] }) {
  const [selectedKey, setSelectedKey] = React.useState<Partial<PrivateAPIKey> | null>(null)
  const [keys, setKeys] = React.useState<PrivateAPIKey[]>(orderBy(props.keys ?? [], 'updated_at', 'desc'))
  const [loading, setLoading] = React.useState(false)
  const [revealKey, setRevealKey] = useState<string | null>()
  const [tab, setTab] = useState<'active' | 'disabled'>('active')
  const { copy } = useCopyToClipboard()
  const { hasPermission: canCreatePrivateKey } = usePermission({ on: 'project', action: 'can_edit' })

  const activeKeys = useMemo(() => keys.filter((k) => !k.archived), [keys])
  const disabledKeys = useMemo(() => keys.filter((k) => k.archived), [keys])

  const copyToClipboard = useCallback(
    (key: PrivateAPIKey, mode: 'key' | 'snippet') => {
      copy(key.key)

      toast(`Copied ${mode} to clipboard`)
    },
    [copy]
  )

  const refresh = useCallback(() => {
    setLoading(true)
    concurrentGET<{ api_keys: PrivateAPIKey[] }>(projectPath('/settings/api-keys')).then((response) => {
      setKeys(response.api_keys)
      setLoading(false)
    })
  }, [])

  const toggleArchive = useCallback(
    async (key: PrivateAPIKey, changeTab?: boolean) => {
      setLoading(true)

      await post(projectPath('/settings/api-keys'), {
        private_api_key: {
          id: key.id,
          archived: !key.archived
        }
      })
        .then(() => {
          toast.success(`${key.name} has been disabled`)
          setSelectedKey(null)
          if (changeTab) {
            if (key.archived) {
              setTab('active')
            } else {
              setTab('disabled')
            }
          }
        })
        .catch((err) => {
          toast.error(`Failed to archive ${key.name}`, {
            description: err.message
          })
        })
      refresh()
    },
    [refresh]
  )

  const displayedKeys = tab === 'active' ? activeKeys : disabledKeys

  return (
    <>
      {selectedKey && (
        <APIModal
          selectedKey={selectedKey}
          onArchive={(key) => {
            toggleArchive(key, true)
            refresh()
          }}
          onClose={() => {
            setSelectedKey(null)
            refresh()
          }}
          setSelectedKey={(key) => {
            setSelectedKey(key)
            refresh()
          }}
        />
      )}
      <Stack gap={4}>
        <Flex w="100%" flexWrap="wrap" gap={4} alignItems="flex-start" justifyContent="space-between">
          <Stack flex="1" minW="300px" spacing={1}>
            <Heading size="sm">Private keys</Heading>
            <Text fontSize="sm" color="gray.600">
              Authenticate securely with Koala APIs using private keys. These are secret, so keep them safe. These
              should not be used on the open web or in browsers &ndash; they are only meant to authenticate
              server-to-server with Koala's APIs.
            </Text>
          </Stack>
          <Tooltip
            label="You are not authorized to perform this action"
            isDisabled={canCreatePrivateKey}
            shouldWrapChildren
          >
            <Button
              flex="none"
              size="sm"
              variant="outline"
              onClick={() => {
                setSelectedKey({})
              }}
              isDisabled={!canCreatePrivateKey || loading}
              leftIcon={<IconPlus size="16" />}
              iconSpacing={1.5}
              cursor={!canCreatePrivateKey ? 'not-allowed' : 'pointer'}
            >
              Create private key
            </Button>
          </Tooltip>
        </Flex>

        <Tabs size="sm" marginTop={6} index={tab === 'disabled' ? 1 : 0}>
          <TabList>
            <Tab onClick={() => setTab('active')}>Live keys ({activeKeys.length})</Tab>
            <Tab onClick={() => setTab('disabled')}>Disabled keys ({disabledKeys.length})</Tab>
          </TabList>
        </Tabs>

        {tab === 'disabled' && disabledKeys.length > 0 && (
          <Stack w="100%" borderLeftWidth="thick" p="4" borderLeftColor="purple.500" bg="purple.50">
            <Text fontSize="sm">
              These are your disabled private API keys. This means that requests performed using this key are going to
              be ignored. You can use this feature in case you need to rotate your keys or disable the usage of this
              key.
            </Text>
          </Stack>
        )}

        {displayedKeys.length === 0 ? (
          <Center padding={5}>
            <Text color="gray.500" fontSize="sm">
              Your workspace doesn't have any {tab === 'disabled' ? 'disabled' : 'active'} private keys yet
            </Text>
          </Center>
        ) : (
          <TableContainer>
            <Table size={'sm'}>
              <Thead>
                <Tr>
                  <Th pl={0}>Name</Th>
                  <Th>Key</Th>
                  <Th>Modified</Th>
                  <Th isNumeric>{loading && <Spinner size="xs" />}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayedKeys.map((key) => (
                  <Tr key={key.id} role="group">
                    <Td pl={0} fontSize="xs">
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          setSelectedKey(key)
                        }}
                      >
                        {key.name}
                      </Link>
                    </Td>
                    <Td fontSize="xs">
                      <HStack w="100%" spacing={1}>
                        <input
                          type={revealKey === key.id ? 'text' : 'password'}
                          value={key.key}
                          readOnly
                          style={{
                            flex: '1 0 auto',
                            background: 'transparent',
                            fontFamily: 'var(--chakra-fonts-mono)'
                          }}
                        />
                        {!key.archived && (
                          <IconButton
                            visibility={'hidden'}
                            onClick={() => copyToClipboard(key, 'key')}
                            aria-label="Copy"
                            icon={<IconCopy size="14" />}
                            size="xs"
                            variant={'ghost'}
                            _groupHover={{ visibility: 'visible' }}
                          />
                        )}
                        <IconButton
                          visibility="hidden"
                          onClick={() => setRevealKey((prev) => (prev === key.id ? null : key.id))}
                          aria-label="Copy"
                          icon={revealKey === key.id ? <IconEyeOff size={14} /> : <IconEye size={14} />}
                          size="xs"
                          variant="ghost"
                          _groupHover={{ visibility: 'visible' }}
                        />
                      </HStack>
                    </Td>
                    <Td fontSize="xs">{formatDate(key.updated_at)}</Td>
                    <Td width="1px" textAlign="end" paddingRight={0}>
                      <Menu size="sm" placement="bottom-end" autoSelect={false}>
                        <MenuButton
                          size="xs"
                          variant="ghost"
                          as={IconButton}
                          aria-label="more"
                          icon={<IconDots size={16} />}
                        />
                        <MenuList zIndex="popover">
                          <MenuItem
                            icon={<IconEdit size={16} />}
                            onClick={() => {
                              setSelectedKey(key)
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            icon={<IconArchive size={16} />}
                            color={key.archived ? undefined : 'red.500'}
                            onClick={() => {
                              toggleArchive(key, false)
                            }}
                          >
                            {key.archived ? 'Enable' : 'Disable'}
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  )
}
