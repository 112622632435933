import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { concurrentCachedGET } from '../../lib/api'

export interface OmnisearchCompany {
  id?: string
  domain: string
  name?: string
  logo?: string
}

interface Data {
  companies: OmnisearchCompany[]
}

export function useCompanySearch(query: string | undefined, limit?: number, opts?: Partial<UseQueryOptions<Data>>) {
  const search = new URLSearchParams({ query: query || '*', all: '1' })

  if (typeof limit === 'number') {
    search.set('limit', limit.toString())
  }

  const path = `/web/companies/autocomplete?${search.toString()}`

  return useQuery<Data>({
    queryKey: ['omnisearch-companies', { query, limit }],
    queryFn: () => concurrentCachedGET<any>(path),
    ...opts,
    enabled: opts?.enabled !== false && Boolean(query)
  })
}
