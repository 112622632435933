import { Box, Button, Flex, FormControl, FormLabel, Icon, Stack, Text } from '@chakra-ui/react'
import { IconBinoculars, IconList, IconTargetArrow, IconUpload, IconUsers } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Apps } from '../../../../types/App'
import { PlayConfigPreset, PlayTargetConfig, PlayTargetType, PlayTriggerConfig } from '../../../../types/Play'
import { useFacets } from '../../../data/use-facets'
import { GrayCard } from '../../../ui/Card'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import CircleIcon from '../../../ui/CircleIcon'
import { AdvancedFilterBuilder } from '../../../ui/filters/AdvancedFilterBuilder'
import { filtersForType } from '../../../ui/filters/filters-for-type'
import { AdvancedFilters } from '../../../ui/filters/types'
import { BuildingIcon, GitHubIcon, LinkedinIcon } from '../../../ui/icons'
import { SmartListSetup } from '../../follow_rules/components/territory-setup'
import { IntentTrend } from '../../kql_definitions/components/SelectSignalsModal'
import { TriggerMultiSelect } from '../../slack_alerts/components/DefinitionForm'
import { CSVColdSource } from './csv-cold-source'
import { GitHubColdSource } from './github-cold-source'
import { LinkedinColdSource } from './linkedin-cold-source'
import { ProspectorColdSource } from './prospector-cold-source'

interface SourceSetupProps {
  targetType: PlayTargetType
  setTargetType: (targetType: PlayTargetType) => void
  apps?: Apps
  targetConfig?: PlayTargetConfig
  triggerConfig?: PlayTriggerConfig
  onSourceTypeChange?: (sourcePreset: PlayConfigPreset) => void
}

const enrollmentOptions = [
  {
    label: 'Whenever they match a saved list',
    icon: <CircleIcon icon={IconList} iconSize={4} bg="gray.100" color="gray.800" p={2} />,
    value: 'smart-list',
    description: 'Automatically enroll records when they are added to a specific list.'
  },
  {
    label: 'Whenever they match filter conditions',
    icon: <CircleIcon icon={IconTargetArrow} iconSize={4} bg="gray.100" color="gray.800" p={2} />,
    value: 'custom',
    description: 'Automatically enroll records when they match your specified criteria.'
  },
  {
    label: 'Manually add records from a CSV',
    value: 'csv',
    icon: <CircleIcon icon={IconUpload} iconSize={4} bg="gray.100" color="gray.800" p={2} />,
    description: 'Upload a CSV with the records you want to enroll.'
  },
  {
    label: 'Prospector Search',
    value: 'prospects',
    icon: <CircleIcon icon={IconBinoculars} iconSize={4} bg="gray.100" color="gray.800" p={2} />,
    description: `Automatically enroll prospects from Koala's contact database based on a set of filters.`
  },
  {
    label: 'LinkedIn interactions',
    value: 'linkedin',
    icon: <CircleIcon icon={LinkedinIcon} iconSize={4} bg="linkedin.50" color="linkedin.700" p={2} />,
    description: 'Automatically enroll people interacting with a LinkedIn post or page.'
  },
  {
    label: 'GitHub Repo interactions',
    value: 'github',
    icon: <CircleIcon icon={GitHubIcon} iconSize={4} bg="gray.100" color="gray.800" p={2} />,
    description: 'Automatically enroll people interacting with a GitHub repository.'
  }
]

const targetOptions = [
  {
    label: 'Companies',
    icon: <BuildingIcon boxSize={4} />,
    value: 'Account',
    description: `Enroll companies in the play`
  },
  {
    label: 'People',
    icon: <Icon as={IconUsers} boxSize={4} />,
    value: 'Profile',
    description: `Enroll people in the play`
  }
]

const isSourceCold = (source: string | undefined) =>
  source === 'csv' || source === 'prospects' || source === 'linkedin' || source === 'github'

const getOption = (value: string | undefined) => {
  return enrollmentOptions.find((opt) => opt.value === value)
}

export function SourceSetup({ targetType = 'Account', setTargetType, ...props }: SourceSetupProps) {
  const [intentChanges, setIntentChanges] = useState<IntentTrend[]>(props.triggerConfig?.intentChanges ?? [])
  const [kqlIds, setKqlIds] = useState<string[]>(props.triggerConfig?.kqlIds ?? [])
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilters>(props.targetConfig?.config?.facets ?? {})

  useEffect(() => {
    setAdvancedFilters(props.targetConfig?.config?.facets ?? {})
  }, [props.targetConfig?.config?.facets])

  useEffect(() => {
    if (advancedFilters) {
      const filtered = filtersForType(advancedFilters, targetType)
      setAdvancedFilters(filtered)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetType])

  const [listId, setListId] = React.useState(props.targetConfig?.config?.smart_list)
  const [source, setSource] = React.useState<PlayConfigPreset | undefined>(props.targetConfig?.source)

  useEffect(() => {
    setSource(props.targetConfig?.source)
  }, [props.targetConfig?.source])

  const formParams: PlayTargetConfig['config'] = useMemo(() => {
    if (source === 'smart-list') {
      return {
        smart_list: listId
      }
    }

    if (source === 'custom') {
      return { facets: advancedFilters || {} }
    }

    return null
  }, [source, advancedFilters, listId])

  const isColdSource = isSourceCold(source)

  return (
    <Stack spacing={6} as={GrayCard}>
      {!source ? (
        <CardRadioGroup
          size="sm"
          value={source}
          direction="column"
          maxWidth="1400px"
          onChange={(e) => {
            setSource(e as PlayConfigPreset)
            props.onSourceTypeChange?.(e as PlayConfigPreset)
          }}
          options={enrollmentOptions}
        />
      ) : (
        <Box bg="white" borderWidth="1.5px" borderRadius="md" px={3} py={3} maxWidth="700px">
          <Flex justify="space-between" align="center">
            <Flex alignItems="center" gap={2}>
              <Flex px={1.5}>{getOption(source)?.icon}</Flex>
              <Stack spacing={0.5}>
                <Text fontSize="sm" fontWeight="medium" lineHeight="18px">
                  {getOption(source)?.label}
                </Text>
                <Text fontSize="xs" color="gray.500">
                  {getOption(source)?.description}
                </Text>
              </Stack>
            </Flex>
            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                setSource(undefined)
              }}
            >
              Change
            </Button>
          </Flex>
        </Box>
      )}

      {source === 'custom' && (
        <>
          <FormControl>
            <FormLabel>Who do you want to target?</FormLabel>
            <CardRadioGroup
              size="sm"
              gridTemplateColumns="repeat(2, 1fr)"
              value={targetType}
              onChange={(e) => {
                setTargetType(e as 'Account' | 'Profile')
              }}
              options={targetOptions}
            />

            <input type="hidden" name="play[target_type]" value={targetType} />
          </FormControl>

          <Box bg="white" rounded="md" border="1px solid" borderColor="gray.200" p={0}>
            {/* key is used to force a re-render of the filters when the target type changes */}
            {/* This is so we can reset the props for useFacets */}
            <Filters
              key={targetType}
              targetType={targetType}
              advancedFilters={advancedFilters}
              setAdvancedFilters={setAdvancedFilters}
              apps={props.apps}
            />
          </Box>
        </>
      )}

      {source === 'smart-list' && (
        <>
          <SmartListSetup
            listId={listId}
            allowEveryone={false}
            onListChange={(listId) => {
              setListId(listId)
            }}
            onTargetTypeChange={setTargetType}
          />
          <input type="hidden" name="play[target_type]" value={targetType} />
        </>
      )}

      {source === 'csv' && (
        <Stack>
          <CSVColdSource targetConfig={props.targetConfig} targetType={targetType} onTargetTypeChange={setTargetType} />
        </Stack>
      )}

      {source === 'prospects' && (
        <>
          <ProspectorColdSource
            targetConfig={props.targetConfig}
            targetType={targetType}
            onTargetTypeChange={setTargetType}
          />
        </>
      )}

      {source === 'linkedin' && (
        <>
          <LinkedinColdSource
            targetConfig={props.targetConfig}
            targetType={targetType}
            onTargetTypeChange={setTargetType}
          />
        </>
      )}

      {source === 'github' && (
        <>
          <GitHubColdSource
            targetConfig={props.targetConfig}
            targetType={targetType}
            onTargetTypeChange={setTargetType}
          />
        </>
      )}

      {/* show the trigger config once an enrollment source is added */}
      {!!source && !isColdSource && (
        <FormControl>
          <FormLabel>Signal triggers (optional)</FormLabel>
          <TriggerMultiSelect
            onChange={(incomingKQLIDs, _isAnyActivity, _isAnyIntentSignal, intentChanges) => {
              setIntentChanges(intentChanges)

              if (incomingKQLIDs.length === 0 && kqlIds.length === 0) {
                return
              }
              setKqlIds(incomingKQLIDs)
            }}
            intentChanges={intentChanges}
            selectedSignalIds={kqlIds}
            selectedAudienceKind={targetType.toLowerCase() as 'account' | 'profile'}
            skipForms
            skipCreation
            skipAnyActivity
          />
        </FormControl>
      )}

      <input type="hidden" name="play[target_config][source]" value={source || ''} />
      {formParams && <input type="hidden" name="play[target_config][config]" value={JSON.stringify(formParams)} />}

      <input
        type="hidden"
        name="play[trigger_config]"
        value={JSON.stringify({
          kqlIds,
          intentChanges
        })}
      />
    </Stack>
  )
}

function Filters({
  targetType,
  advancedFilters,
  setAdvancedFilters,
  apps
}: {
  targetType: PlayTargetType
  advancedFilters: AdvancedFilters
  setAdvancedFilters: (filters: AdvancedFilters) => void
  apps?: Apps
}) {
  const facets = useFacets({
    facet_filters: advancedFilters,
    facetCloudPath: targetType === 'Profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud',
    onFilterChange: setAdvancedFilters
  })

  return (
    <AdvancedFilterBuilder
      filters={advancedFilters}
      onChangeAdvancedFilters={(filters) => {
        setAdvancedFilters(filters)
      }}
      kind={targetType.toLowerCase() as 'account' | 'profile'}
      apps={apps}
      // when we only use the advanced filter builder, we need to wrap the top level filters in an _and operator
      convertTopLevelFilters
      {...facets}
    />
  )
}
