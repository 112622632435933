import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { useCurrentProject } from '../ui/ProjectsContext'

export function useData<Data>(id: string, path: string) {
  const project = useCurrentProject()

  return useQuery<Data>({
    queryKey: [id, { projectId: project?.id }],
    queryFn: () => concurrentGET<Data>(path),
    enabled: Boolean(project?.id)
  })
}
