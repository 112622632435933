import { Button, FormControl, FormLabel, HStack, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { PlayTargetConfig } from '../../../../types/Play'
import { GrayCard } from '../../../ui/Card'
import { SelectedFile } from '../../imports/components/SelectedFile'
import { Uploader } from '../../imports/components/Uploader'

function deriveTargetType(uniqIds: string[] | null) {
  return uniqIds?.some((id) => id.includes('@')) ? 'Profile' : 'Account'
}

export function CSVColdSource({
  targetConfig,
  targetType,
  onTargetTypeChange
}: {
  targetConfig: PlayTargetConfig | undefined
  targetType: 'Profile' | 'Account' | null
  onTargetTypeChange: (targetType: 'Profile' | 'Account') => void
}) {
  const [uniqIds, setUniqIds] = React.useState<string[] | null>(targetConfig?.config?.uniqIds ?? null)
  const [csvData, setCsvData] = React.useState<Record<string, string>[] | null>(null)
  const [fileName, setFileName] = React.useState<string | null>(targetConfig?.config?.file_name ?? null)
  const [file, setFile] = React.useState<File | null>(null)
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const [existingFile, setExistingFile] = React.useState<boolean>(targetConfig?.config?.file_name ? true : false)

  useEffect(() => {
    if (file && fileInputRef.current) {
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      fileInputRef.current.files = dataTransfer.files
    }
  }, [file])

  return (
    <Stack spacing={4}>
      {existingFile && uniqIds && fileName && (
        <>
          <GrayCard w="100%" justifyContent="center">
            <SelectedFile
              rows={uniqIds.map((id) => ({ id }))}
              file={
                {
                  name: fileName,
                  size: new Blob([uniqIds.join('\n')]).size,
                  type: 'text/csv',
                  webkitRelativePath: fileName,
                  lastModified: new Date().getTime()
                } as File
              }
              onReset={() => {
                setExistingFile(false)
              }}
            />
          </GrayCard>
          <Button size="sm" colorScheme={'blue'} onClick={() => setExistingFile(false)}>
            Replace file
          </Button>
        </>
      )}

      {!existingFile && (
        <Stack>
          <FormControl>
            <FormLabel>I'm uploading a list of...</FormLabel>
            <RadioGroup size="sm" as={HStack} spacing={2}>
              <Radio isChecked={targetType === 'Profile'} onChange={() => onTargetTypeChange('Profile')}>
                People
              </Radio>
              <Radio isChecked={targetType === 'Account'} onChange={() => onTargetTypeChange('Account')}>
                Companies
              </Radio>
            </RadioGroup>
          </FormControl>
          <Uploader
            onChange={({ uniqIds, files, csvData }) => {
              setUniqIds(uniqIds)
              setCsvData(csvData)

              if (files.length > 0) {
                setFileName(files[0].name)
                setFile(files[0])
              }
            }}
            import={{
              name: 'CSV',
              kind: targetType === 'Profile' ? 'contacts' : 'accounts'
            }}
          />
        </Stack>
      )}

      {uniqIds && fileName && uniqIds.length > 0 && (
        <>
          <input
            type="hidden"
            name="play[target_config][config]"
            value={JSON.stringify({
              uniqIds,
              file_name: fileName,
              csvData,
              identifier: targetType === 'Profile' ? 'email' : 'domain'
            })}
          />
          <input type="hidden" name="play[target_type]" value={targetType ?? deriveTargetType(uniqIds)} />
        </>
      )}

      {file && <input type="file" ref={fileInputRef} name="import[csv_file]" hidden />}
    </Stack>
  )
}
