import {
  Avatar,
  Box,
  Circle,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  Button
} from '@chakra-ui/react'
import { IconChevronRight, IconClock, IconMessageCircle, IconX } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { toast } from 'sonner'
import dayjs from '../../../../lib/dayjs'
import { pluralize } from '../../../../lib/pluralize'
import { Engagement } from '../../../../types/Engagement'
import { PlayItem } from '../../../../types/Play'
import { useAccountPlayProspects } from '../../../data/use-account-play-prospects'
import { useCompletePlayItem } from '../../../data/use-plays'
import { Card } from '../../../ui/Card'
import { EmailComposerPopup, EmailProps } from '../../../ui/EmailComposer'
import { LinkedinBoxIcon } from '../../../ui/icons'
import { LastEngagementHoverCard } from '../../../ui/LastEngagement'
import { projectPath } from '../../../ui/ProjectsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { WarningMessage } from '../../../ui/WarningMessage'
import { profilePath } from '../../profiles/lib/path'
import { WaterfallButton } from './WaterfallButton'

interface AccountEmailButtonProps {
  record: PlayItem['record']
  item: PlayItem
  draftEmail?: EmailProps
  buttonText?: string
  onEmailSent?: (itemId: string, status: string) => void
  defaultIsOpen?: boolean
  partOfSplitButton?: boolean
}

export type RecommendedPlay = {
  play?: string
  name?: string
  reasoning?: string[]
}

function messagedRecently(lastActivity?: Engagement | string) {
  if (typeof lastActivity === 'string') {
    return dayjs(lastActivity).isAfter(dayjs().subtract(90, 'day'))
  }

  if (lastActivity?.created_at) {
    return dayjs(lastActivity.created_at).isAfter(dayjs().subtract(90, 'day'))
  }

  return false
}

export function AccountEmailButton({
  record,
  item,
  buttonText,
  draftEmail,
  onEmailSent,
  defaultIsOpen,
  partOfSplitButton
}: AccountEmailButtonProps) {
  const itemId = item.id

  const { mutate: completeItem } = useCompletePlayItem({
    onSuccess: () => {
      const id = selectedProspect?.id ?? itemId
      onEmailSent?.(id, 'completed')
    },
    onError: (error: any) => {
      toast.error('There was an issue marking this lead as complete', {
        description: error?.body?.message || error?.message
      })
    }
  })

  // TODO: also support SFDC/HS last sales activity dates
  const lastActivity = record.last_activity as Engagement | undefined
  const lastAccountActivity = record.last_account_activity as Engagement | undefined
  const lastActivityDate = record.last_activity_date as string | undefined

  const { data, isLoading } = useAccountPlayProspects(item)
  const [selectedProspect, setSelectedProspect] = useState<PlayItem | null>(null)

  const handleEmailSent = useCallback(() => {
    if (selectedProspect) {
      completeItem({ itemId: selectedProspect.id })
    } else {
      completeItem({ itemId })
    }
  }, [itemId, completeItem, selectedProspect])

  return (
    <Stack>
      <EmailComposerPopup
        email={{ to: record.email, ...draftEmail }}
        accountId={record.account_id}
        onEmailSent={handleEmailSent}
        itemId={itemId}
        buttonText={buttonText}
        prospects={data?.play_items ?? []}
        isLoadingProspects={isLoading}
        selectedProspect={selectedProspect}
        onSelectProspect={setSelectedProspect}
        onUpdateItem={(itemId, item) => {
          setSelectedProspect(item)
        }}
        partOfSplitButton={partOfSplitButton}
        defaultIsOpen={defaultIsOpen}
        warningMessage={
          (lastActivity || lastAccountActivity) && messagedRecently(lastActivity || lastAccountActivity) ? (
            <WarningMessage>
              Emailed with{' '}
              <LastEngagementHoverCard engagement={lastActivity || lastAccountActivity}>
                <Text as="span" fontWeight="semibold" textDecoration="underline">
                  {lastActivity ? record.name || record.email : record.company.name}
                </Text>
              </LastEngagementHoverCard>{' '}
              <TimeAgo time={(lastActivity || lastAccountActivity)?.created_at} />. Are you sure you want to send
              another message?
            </WarningMessage>
          ) : lastActivityDate && messagedRecently(lastActivityDate) ? (
            <WarningMessage>
              Last touched <TimeAgo time={lastActivityDate} />. Are you sure you want to send a message?
            </WarningMessage>
          ) : undefined
        }
      />
    </Stack>
  )
}

export function SelectProspectsScreen({
  onClose,
  isLoading,
  prospects,
  onSelectProspect,
  onUpdateItem,
  multiple = false,
  onSelectMultipleProspects
}: {
  onClose: () => void
  isLoading: boolean
  prospects: PlayItem[]
  onSelectProspect?: (prospect: PlayItem | null) => void
  onUpdateItem: (itemId: string, item: PlayItem) => void
  multiple?: boolean
  onSelectMultipleProspects?: (prospects: PlayItem[]) => void
}) {
  const [selectedProspects, setSelectedProspects] = useState<PlayItem[]>([])

  const handleSelectProspect = (prospect: PlayItem) => {
    if (!multiple) {
      onSelectProspect?.(prospect)
      return
    }

    setSelectedProspects((prev) => {
      const isSelected = prev.some((p) => p.id === prospect.id)

      if (isSelected) {
        return prev.filter((p) => p.id !== prospect.id)
      } else {
        return [...prev, prospect]
      }
    })
  }

  const handleConfirmSelection = () => {
    if (multiple && onSelectMultipleProspects && selectedProspects.length > 0) {
      onSelectMultipleProspects(selectedProspects)
    }
  }

  return (
    <Box
      width={'480px'}
      minWidth={'auto'}
      maxWidth="calc(100vw - 32px)"
      height={'full'}
      bg="white"
      border="1px solid"
      borderColor="gray.200"
      shadow="heavy"
      roundedTop="md"
      display="flex"
      flexDirection="column"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        pl={4}
        pr={2}
        py={2}
        borderBottomWidth="1px"
        borderColor="gray.200"
        bg="gray.50"
        roundedTop="md"
        cursor="pointer"
      >
        <Heading size="xs" fontWeight="semibold">
          {multiple ? 'Select prospects to add to sequence' : 'Suggested prospects'}
        </Heading>
        <IconButton
          icon={<Icon as={IconX} boxSize={4} />}
          size="xs"
          variant="ghost"
          aria-label="Close"
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
        />
      </Flex>

      <Box flex="1" overflow="auto" bg={isLoading ? 'white' : 'gray.50'}>
        {isLoading && (
          <Stack p="6" spacing="4">
            <Skeleton height="40px" />
            <Skeleton height="40px" />
            <Skeleton height="40px" />
          </Stack>
        )}

        {!isLoading && prospects.length === 0 && (
          <Flex height="100%" alignItems="center" justifyContent="center" p={6}>
            <Text color="gray.500">No prospects found</Text>
          </Flex>
        )}

        {!isLoading && prospects.length > 0 && (
          <Stack spacing={4} p="6">
            {prospects.map((item) => {
              const profile = item.record
              const hasName = Boolean(profile.name || profile.full_name)
              const isSelected = multiple && selectedProspects.some((p) => p.id === item.id)

              return (
                <Box
                  key={item.id}
                  as={Card}
                  p="4"
                  cursor="pointer"
                  onClick={() => handleSelectProspect(item)}
                  _hover={{ bg: 'gray.50' }}
                  bg={isSelected ? 'purple.50' : undefined}
                  borderColor={isSelected ? 'purple.300' : undefined}
                  transition="all 0.2s"
                >
                  <Flex flex="1" minW="400px" alignItems="center" justifyContent="space-between" gap={4}>
                    <Flex flex="1" alignItems="center" gap={2.5} isTruncated>
                      <Avatar
                        size="sm"
                        name={profile.name || profile.full_name || profile.display_name}
                        src={
                          profile.image ||
                          (profile.koala_person?.id
                            ? projectPath(`/prospects/${profile.koala_person.id}/avatar`)
                            : undefined)
                        }
                      />
                      <Stack spacing={0.5} isTruncated>
                        <Flex alignItems="center" gap={2} isTruncated>
                          <Link
                            display="inline-flex"
                            href={profilePath(profile)}
                            isExternal
                            _hover={{ textDecoration: 'underline' }}
                          >
                            <TextEllipsis fontSize="sm" fontWeight="medium" maxW="100%" tooltip>
                              {profile.name ||
                                profile.full_name ||
                                profile.display_name ||
                                profile.email ||
                                'Anonymous'}
                            </TextEllipsis>
                          </Link>

                          {profile.linkedin_url && (
                            <Tooltip label={`https://${profile.linkedin_url.replace(/https?:\/\//, '')}`}>
                              <Link
                                display="flex"
                                flex="none"
                                alignItems="center"
                                color="linkedin.700"
                                isExternal
                                href={`https://${profile.linkedin_url.replace(/https?:\/\//, '')}`}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  window.ko?.track('LinkedIn Profile Visit Action', {
                                    app: 'linkedin',
                                    email: profile.email
                                  })
                                }}
                              >
                                <LinkedinBoxIcon boxSize="18px" />
                              </Link>
                            </Tooltip>
                          )}
                        </Flex>
                        {profile.title ? (
                          <TextEllipsis fontSize="13px" color="gray.500" tooltip>
                            {[profile.title, profile.company?.name].filter(Boolean).join(' @ ')}
                          </TextEllipsis>
                        ) : (
                          <TextEllipsis fontSize="13px" color="gray.500" tooltip>
                            {hasName ? profile.email || profile.simple_location : profile.simple_location}
                          </TextEllipsis>
                        )}
                      </Stack>
                    </Flex>

                    <Flex alignItems="center" gap={2} ml="auto">
                      {profile.email && (item.status === 'pending' || item.status === 'snoozed') && !multiple && (
                        <IconButton
                          variant="ghost"
                          size="sm"
                          aria-label="Compose"
                          icon={<Icon as={IconChevronRight} boxSize={4} />}
                          onClick={(e) => {
                            e.stopPropagation()
                            if (onSelectProspect) {
                              onSelectProspect(item)
                            }
                          }}
                        />
                      )}

                      {!profile.email &&
                        profile.linkedin_url &&
                        (item.status === 'pending' || item.status === 'snoozed') && (
                          <WaterfallButton
                            record={profile}
                            playItem={item}
                            itemId={item.id}
                            onSuccess={(updatedRecord) => {
                              onUpdateItem(item.id, {
                                ...item,
                                record: updatedRecord
                              })
                            }}
                          />
                        )}
                    </Flex>
                  </Flex>

                  <Divider py="1" />

                  <Stack spacing={2} fontSize="xs" color="gray.500" pt="2" w="100%">
                    <Stack spacing={2}>
                      <HStack spacing={1} w="100%" justifyContent="space-between" alignItems="flex-start">
                        {(item.context?.personas?.length ?? 0) === 0 &&
                          item.record?.last_seen_at &&
                          dayjs(item.record.last_seen_at).isAfter(dayjs().subtract(30, 'days')) && (
                            <HStack spacing={1}>
                              <Circle size="6px" bg="orange.500" />
                              <Text fontSize="xs" color="gray.500" fontWeight={'medium'}>
                                Warm
                              </Text>
                            </HStack>
                          )}

                        {(item.context?.personas?.length ?? 0) === 0 &&
                          (!item.record?.last_seen_at ||
                            dayjs(item.record.last_seen_at).isBefore(dayjs().subtract(30, 'days'))) && (
                            <HStack spacing={1}>
                              <Circle size="6px" bg="blue.500" />
                              <Text fontSize="xs" color="gray.500" fontWeight={'medium'}>
                                {item.record?.initial_source === 'Prospector' ? 'Prospected' : 'Cold prospect'}
                              </Text>
                            </HStack>
                          )}

                        {item.context.personas?.length && item.context.personas?.length > 0 && (
                          <HStack spacing={1} flexWrap="wrap">
                            <Text>Prospected from </Text>
                            <Text as="span" fontWeight="semibold">
                              {item.context.personas.map((persona) => persona.name).join(', ')}
                            </Text>
                            <Text>{pluralize(item.context.personas?.length ?? 0, 'persona', 'personas', false)}.</Text>
                          </HStack>
                        )}

                        <Stack spacing={0.5} alignItems="flex-end">
                          {item.record.last_activity_date && (
                            <HStack spacing={1}>
                              <Icon as={IconMessageCircle} boxSize={3} />
                              <Text>
                                Last contacted <TimeAgo time={item.record.last_activity_date} />.
                              </Text>
                            </HStack>
                          )}
                          {item.record.last_seen_at && (
                            <HStack spacing={1}>
                              <Icon as={IconClock} boxSize={3} />
                              <Text>
                                Last seen <TimeAgo time={item.record.last_seen_at} />.
                              </Text>
                            </HStack>
                          )}
                        </Stack>
                      </HStack>
                    </Stack>
                  </Stack>
                </Box>
              )
            })}
          </Stack>
        )}
      </Box>

      {multiple && (
        <Flex
          p={4}
          borderTop="1px solid"
          borderColor="gray.200"
          justifyContent="flex-end"
          position="sticky"
          bottom={0}
          bg="white"
        >
          <HStack spacing={3}>
            <Text fontSize="sm" fontWeight="medium" color="gray.600">
              {pluralize(selectedProspects.length, 'prospect', 'prospects')} selected
            </Text>
            <Button
              colorScheme="purple"
              size="sm"
              onClick={handleConfirmSelection}
              isDisabled={selectedProspects.length === 0}
            >
              Confirm selection
            </Button>
          </HStack>
        </Flex>
      )}
    </Box>
  )
}
