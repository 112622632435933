import { keepPreviousData, useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query'
import { concurrentGET, patch, post } from '../../lib/api'
import { PageMeta } from '../../types/PageMeta'
import { Play, PlayItem } from '../../types/Play'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface PlayResponse {
  play_items: PlayItem[]
  play: Play
  page_meta: PageMeta
  stats: {
    total: number
    pending: number
    completed: number
    dismissed: number
    qualified: number
  }
}

interface UsePlayOptions {
  userId?: string
  status?: string | string[]
  range?: 'day' | 'week' | 'month' | 'all'
  page?: number
  perPage?: number
}

export function usePlay(playId: string, options: UsePlayOptions = {}) {
  const project = useCurrentProject()
  const { userId, status, range, page = 1, perPage = 10 } = options
  const searchParams = new URLSearchParams()
  if (userId) {
    searchParams.set('user', userId)
  }

  if (status) {
    if (Array.isArray(status)) {
      status.forEach((s) => searchParams.append('status[]', s))
    } else {
      searchParams.set('status', status)
    }
  }

  if (range) {
    searchParams.set('range', range)
  }

  searchParams.set('page', page.toString())
  searchParams.set('per_page', perPage.toString())

  const path = projectPath(`/plays/${playId}?${searchParams.toString()}`).toString()

  return useQuery<PlayResponse, Error>({
    queryKey: [
      'play',
      {
        projectId: project?.id,
        playId,
        userId,
        status: Array.isArray(status) ? status.join(',') : status,
        range,
        page,
        perPage
      }
    ],
    queryFn: () => concurrentGET<PlayResponse>(path),
    enabled: Boolean(playId),

    // Keep the previous data visible while fetching new data
    placeholderData: keepPreviousData
  })
}

interface DismissItemParams {
  itemId: string
  reason: string
  feedback?: string
}

export function useDismissPlayItem(options?: UseMutationOptions<void, Error, DismissItemParams>) {
  return useMutation<void, Error, DismissItemParams>({
    mutationFn: async ({ itemId, reason, feedback }) => {
      await patch(projectPath(`/play-items/${itemId}/dismiss`), {
        reason,
        feedback: reason === 'other' ? feedback : ''
      })
    },

    ...options
  })
}

interface DismissItemsParams {
  itemIds: string[]
  reason: string
  feedback?: string
}

export function useDismissPlayItems(options?: UseMutationOptions<void, Error, DismissItemsParams>) {
  return useMutation<void, Error, DismissItemsParams>({
    mutationFn: async ({ itemIds, reason, feedback }) => {
      await post(projectPath(`/play-items/dismiss`), {
        item_ids: itemIds,
        reason,
        feedback: reason === 'other' ? feedback : ''
      })
    },

    ...options
  })
}

interface ResetItemParams {
  itemId: string
}

export function useResetPlayItem(options?: UseMutationOptions<void, Error, ResetItemParams>) {
  return useMutation<void, Error, ResetItemParams>({
    mutationFn: async ({ itemId }) => {
      await patch(projectPath(`/play-items/${itemId}/reset`), {})
    },

    ...options
  })
}

interface SnoozeItemParams {
  itemId: string
  until: string
}

export function useSnoozePlayItem(options?: UseMutationOptions<void, Error, SnoozeItemParams>) {
  return useMutation<void, Error, SnoozeItemParams>({
    mutationFn: async ({ itemId, until }) => {
      await patch(projectPath(`/play-items/${itemId}/snooze`), {
        until
      })
    },

    ...options
  })
}

interface SnoozeItemsParams {
  itemIds: string[]
  until: string
}

export function useSnoozePlayItems(options?: UseMutationOptions<void, Error, SnoozeItemsParams>) {
  return useMutation<void, Error, SnoozeItemsParams>({
    mutationFn: async ({ itemIds, until }) => {
      await post(projectPath(`/play-items/snooze`), { item_ids: itemIds, until })
    },

    ...options
  })
}

interface CompleteItemParams {
  itemId: string
}

export function useCompletePlayItem(options?: UseMutationOptions<void, Error, CompleteItemParams>) {
  return useMutation<void, Error, CompleteItemParams>({
    mutationFn: async ({ itemId }) => {
      await patch(projectPath(`/play-items/${itemId}/complete`), {})
    },

    ...options
  })
}

interface CompleteItemsParams {
  itemIds: string[]
}

export function useCompletePlayItems(options?: UseMutationOptions<void, Error, CompleteItemsParams>) {
  return useMutation<void, Error, CompleteItemsParams>({
    mutationFn: async ({ itemIds }) => {
      await post(projectPath(`/play-items/complete`), { item_ids: itemIds })
    },

    ...options
  })
}

interface AssignItemParams {
  itemId: string
  userId: string | null
}

export function useAssignPlayItem(options?: UseMutationOptions<PlayItem, Error, AssignItemParams>) {
  return useMutation<PlayItem, Error, AssignItemParams>({
    mutationFn: async ({ itemId, userId }) => {
      const response = await patch<{ play_item: PlayItem }>(projectPath(`/play-items/${itemId}/assign`), {
        user_id: userId
      })
      return response.play_item
    },

    ...options
  })
}

interface AssignMultipleItemsParams {
  itemIds: string[]
  userId: string | null
}

interface AssignMultipleItemsResponse {
  play_items: PlayItem[]
}

export function useAssignMultiplePlayItems(
  options?: UseMutationOptions<AssignMultipleItemsResponse, Error, AssignMultipleItemsParams>
) {
  return useMutation<AssignMultipleItemsResponse, Error, AssignMultipleItemsParams>({
    mutationFn: async ({ itemIds, userId }) => {
      return await patch<AssignMultipleItemsResponse>(projectPath(`/play-items/assign`), {
        item_ids: itemIds,
        user_id: userId
      })
    },

    ...options
  })
}

interface UpdatePlayParams {
  playId: string
  attributes: Partial<Play>
}

export function useUpdatePlay(options?: UseMutationOptions<Play, Error, UpdatePlayParams>) {
  return useMutation<Play, Error, UpdatePlayParams>({
    mutationFn: async ({ playId, attributes }) => {
      const response = await patch<{ play: Play }>(projectPath(`/plays/${playId}`), {
        play: attributes
      })
      return response.play
    },

    ...options
  })
}
