import { concurrentCachedGET } from '@lib/api'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { Play } from '@type/Play'
import { UserAppInstance } from '@type/UserAppInstance'
import { projectPath, useCurrentProject } from '@ui/ProjectsContext'
import { useCurrentUser } from '@ui/UserContext'

interface InboxResponse {
  plays: Play[]
  plays_with_pending: Play[]
  pending: number
  total: number
  apps: UserAppInstance[]
}
export const inboxQueryKey = (projectId?: string, userId?: string) => ['inbox', { projectId, userId }]

export function useInbox({ userId }: { userId?: string } = {}) {
  const project = useCurrentProject()
  const currentUser = useCurrentUser()
  const path = projectPath(`/inbox?user=${userId ?? currentUser?.id}`)

  return useQuery<InboxResponse>({
    queryKey: ['inbox', { projectId: project?.id, userId: userId ?? currentUser?.id }],
    queryFn: () => concurrentCachedGET<InboxResponse>(path),
    enabled: Boolean(project?.id),

    // Keep the previous data visible while fetching new data
    placeholderData: keepPreviousData
  })
}
