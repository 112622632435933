import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import { useCurrentUser } from '../ui/UserContext'

interface Data {
  settings: Record<string, any>
}

export function useUserSettings() {
  const project = useCurrentProject()
  const user = useCurrentUser()

  return useQuery<Data>({
    queryKey: ['user-settings', { projectId: project?.id, userId: user?.id }],
    queryFn: () => concurrentGET<Data>(projectPath(`/user-settings`)),
    enabled: Boolean(project?.id && user?.id)
  })
}
