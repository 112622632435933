import { post } from '@app/lib/api'
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Tooltip,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconChevronDown,
  IconCopy,
  IconExternalLink,
  IconMinus,
  IconSettings,
  IconSparkles,
  IconTrash,
  IconX
} from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import useLocalStorageState from 'use-local-storage-state'
import { PlayItem } from '../../types/Play'
import { apolloSingleSend, useApolloSingleSendSettings } from '../data/use-apollo-single-send'
import { useMailbox } from '../data/use-mailbox'
import { outreachSingleSend, useOutreachSingleSendSettings } from '../data/use-outreach-single-send'
import GoogleLogo from '../pages/devise/registrations/components/google.svg'
import { SelectProspectsScreen } from '../pages/plays/components/AccountEmailButton'
import { AutosizedTextarea } from './AutosizedTextarea'
import { RewritePanel } from './composer/RewritePanel'
import { EmailPreferencesPanel } from './composer/EmailPreferencesPanel'
import { AiSparklesIcon, ApolloIcon, LinkedinBoxIcon, OutreachIcon } from './icons'
import { projectPath, useCurrentProject } from './ProjectsContext'
import { useCopyToClipboard } from './useCopyToClipboard'
import { useCurrentUser } from './UserContext'

function createGmailComposeUrl(email: EmailProps, fromEmail: string) {
  const params = new URLSearchParams({
    to: email.to || '',
    su: email.subject || '',
    body: email.body || ''
  })
  return `https://mail.google.com/mail/u/${fromEmail || '0'}/?tf=cm&fs=1&${params.toString()}`
}

export interface EmailProps {
  to?: string
  subject?: string
  body?: string
}

interface EmailComposerProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  email?: EmailProps
  togglePreferencesPanel: () => void
  toggleSuggestionsPanel: () => void
  suggestionsPanelOpen: boolean
  preferencesPanelOpen: boolean
}

const EmailComposer = ({
  onChange,
  email,
  togglePreferencesPanel,
  toggleSuggestionsPanel,
  suggestionsPanelOpen,
  preferencesPanelOpen
}: EmailComposerProps) => {
  const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    onChange({
      target: {
        name: 'body',
        value
      }
    } as React.ChangeEvent<HTMLTextAreaElement>)
  }

  return (
    <Flex flex="1 0 auto" flexDirection="column" gap={4} fontSize="sm" h="100%">
      <FormControl>
        <FormLabel fontSize="xs">To</FormLabel>
        <Input
          size="sm"
          fontWeight="medium"
          name="to"
          value={email?.to || ''}
          placeholder="recipient@example.com"
          isReadOnly
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="xs">Subject</FormLabel>
        <Input
          size="sm"
          fontWeight="medium"
          name="subject"
          value={email?.subject || ''}
          onChange={onChange}
          placeholder="Email subject"
        />
      </FormControl>
      <FormControl display="flex" flexDirection="column" flex="1 1 auto">
        <Flex justify="space-between" align="center" mb={1.5}>
          <FormLabel fontSize="xs" mb={0}>
            Message
          </FormLabel>
          <Flex gap={2}>
            <Tooltip label="AI suggestions for your email">
              <Box>
                <Button
                  size="xs"
                  variant="ghost"
                  colorScheme="purple"
                  leftIcon={<Icon as={IconSparkles} boxSize={3.5} />}
                  iconSpacing={1.5}
                  onClick={toggleSuggestionsPanel}
                  isActive={suggestionsPanelOpen}
                >
                  AI Suggestions
                </Button>
              </Box>
            </Tooltip>
            <Tooltip label="Your email writing preferences">
              <Box>
                <Button
                  size="xs"
                  variant="ghost"
                  leftIcon={<Icon as={IconSettings} boxSize={3.5} />}
                  iconSpacing={1.5}
                  onClick={togglePreferencesPanel}
                  isActive={preferencesPanelOpen}
                >
                  AI Preferences
                </Button>
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
        <AutosizedTextarea
          size="sm"
          name="body"
          value={email?.body || ''}
          onChange={handleBodyChange}
          placeholder="Type your message here..."
          minHeight="100px"
          h="100%"
          flex="1 1 auto"
        />
      </FormControl>
    </Flex>
  )
}

type SendAction = 'send-gmail' | 'send-outreach' | 'send-apollo' | 'compose-gmail'

interface EmailComposerPopupProps {
  email?: EmailProps
  disabled?: boolean
  onOpen?: () => void
  onClose?: () => void
  onEmailSent?: () => void
  accountId?: string
  buttonProps?: ButtonProps
  buttonText?: string
  warningMessage?: React.ReactNode
  itemId: string
  prospects?: PlayItem[]
  selectedProspect?: PlayItem | null
  onSelectProspect?: (prospect: PlayItem | null) => void
  onUpdateItem?: (itemId: string, item: PlayItem) => void
  isLoadingProspects?: boolean
  defaultIsOpen?: boolean
  partOfSplitButton?: boolean
}

export function EmailComposerPopup({
  email,
  onOpen: onOpenProp,
  onClose: onCloseProp,
  disabled,
  onEmailSent,
  accountId,
  buttonText = 'Compose email',
  buttonProps,
  warningMessage,
  onUpdateItem,
  prospects,
  selectedProspect,
  onSelectProspect,
  isLoadingProspects,
  defaultIsOpen = false,
  partOfSplitButton = false
}: EmailComposerPopupProps) {
  const project = useCurrentProject()
  const user = useCurrentUser()
  const [suggestionsPanelOpen, setSuggestionsPanelOpen] = useState<boolean>(false)
  const [preferencesPanelOpen, setPreferencesPanelOpen] = useState<boolean>(false)
  const [isMinimized, setIsMinimized] = useState(false)
  const [sendingGmail, setSendingGmail] = useState(false)
  const [sendingOutreach, setSendingOutreach] = useState(false)
  const [sendingApollo, setSendingApollo] = useState(false)

  const sending = sendingGmail || sendingOutreach || sendingApollo

  const [lastAction, setLastAction] = useLocalStorageState<SendAction | null>(
    `koala:composer:last-action:${project?.slug}`,
    {
      defaultValue: null
    }
  )

  // detect if they have a gmail mailbox connected
  const mailbox = useMailbox()
  const gmailConnected = mailbox.data?.connected ?? false

  // detect if they have outreach connected and if they have the necessary scopes
  const { canSendOutreach, outreachEnabled, isLoading: outreachLoading } = useOutreachSingleSendSettings()

  // detect if they have apollo connected
  const { canSendApollo, apolloEnabled, isLoading: apolloLoading } = useApolloSingleSendSettings()

  const toggleSuggestionsPanel = useCallback(() => setSuggestionsPanelOpen((prev) => !prev), [])
  const togglePreferencesPanel = useCallback(() => setPreferencesPanelOpen((prev) => !prev), [])
  const openSuggestionsPanel = useCallback(() => setSuggestionsPanelOpen(true), [])
  const closeSuggestionsPanel = useCallback(() => setSuggestionsPanelOpen(false), [])
  const closePreferencesPanel = useCallback(() => setPreferencesPanelOpen(false), [])

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: defaultIsOpen,
    onOpen: () => {
      onOpenProp?.()
      setIsMinimized(false)
      openSuggestionsPanel()
    },
    onClose: () => {
      onCloseProp?.()
      closeSuggestionsPanel()
      closePreferencesPanel()
    }
  })

  const [runId, setRunId] = useState<string | undefined>()

  const [currentEmail, setCurrentEmail] = useState(email)

  // const requestGmailScope = async () => {
  //   try {
  //     const returnTo = encodeURIComponent(window.location.pathname)
  //     window.location.href = `/auth/gmail?project=${project?.slug}&app_id=gmail&state=${returnTo}`
  //   } catch (error) {
  //     console.error('Error requesting Gmail scope:', error)
  //     toast.error('Failed to request Gmail permissions')
  //   }
  // }

  const requestOutreachScope = async () => {
    try {
      const returnTo = encodeURIComponent(window.location.pathname)
      window.location.href = projectPath(`/apps/outreach?return_to=${returnTo}`)
    } catch (error) {
      console.error('Error requesting Outreach scope:', error)
      toast.error('Failed to request Outreach permissions')
    }
  }

  const requestApolloScope = async () => {
    try {
      const returnTo = encodeURIComponent(window.location.pathname)
      window.location.href = projectPath(`/apps/apollo?return_to=${returnTo}`)
    } catch (error) {
      console.error('Error requesting Apollo scope:', error)
      toast.error('Failed to request Apollo permissions')
    }
  }

  const handleSendOutreach = useCallback(() => {
    setLastAction('send-outreach')

    if (!outreachEnabled) {
      toast.error('You do not have permission to send emails with Outreach')
      setSendingOutreach(false)
      return
    }

    if (!currentEmail) {
      toast.error('Please enter a recipient email')
      setSendingOutreach(false)
      return
    }

    if (!currentEmail.subject || !currentEmail.body) {
      toast.warning('Please enter an email subject and body')
      setSendingOutreach(false)
      return
    }

    setSendingOutreach(true)
    outreachSingleSend(
      { email: currentEmail!.to!, account_id: accountId! },
      {
        subject: currentEmail!.subject!,
        body: currentEmail!.body!
      }
    )
      .then(() => {
        toast.success('Email sent!')
        onEmailSent?.()
        setSendingOutreach(false)
      })
      .catch((error) => {
        const errorMessage = error.body?.message || error.body?.error || 'An unexpected error occurred'
        toast.error('Failed to send email', { description: errorMessage })
      })
      .finally(() => {
        setSendingOutreach(false)
      })
  }, [outreachEnabled, currentEmail, accountId, onEmailSent, setLastAction])

  const handleSendApollo = useCallback(() => {
    setLastAction('send-apollo')

    if (!apolloEnabled) {
      toast.error('You do not have permission to send emails with Apollo')
      setSendingApollo(false)
      return
    }

    if (!currentEmail) {
      toast.error('Please enter a recipient email')
      setSendingApollo(false)
      return
    }

    if (!currentEmail.subject || !currentEmail.body) {
      toast.warning('Please enter an email subject and body')
      setSendingApollo(false)
      return
    }

    setSendingApollo(true)
    apolloSingleSend(
      { email: currentEmail!.to! },
      {
        subject: currentEmail!.subject!,
        body: currentEmail!.body!
      }
    )
      .then(() => {
        toast.success('Email sent!')
        onEmailSent?.()
        setSendingApollo(false)
      })
      .catch((error) => {
        const errorMessage = error.body?.message || error.body?.error || 'An unexpected error occurred'
        toast.error('Failed to send email', { description: errorMessage })
      })
      .finally(() => {
        setSendingApollo(false)
      })
  }, [apolloEnabled, currentEmail, onEmailSent, setLastAction])

  const handleSend = useCallback(async () => {
    setLastAction('send-gmail')

    if (!currentEmail?.subject || !currentEmail?.body) {
      toast.warning('Please enter an email subject and body')
      setSendingGmail(false)
      return
    }

    setSendingGmail(true)

    await post(projectPath('/apps/gmail'), {
      email: {
        to: currentEmail?.to,
        subject: currentEmail?.subject,
        body: currentEmail?.body
      },
      account_id: accountId
    })
      .then(() => {
        toast.success('Email sent!')
        onEmailSent?.()
        // reset state and close the composer!
        setCurrentEmail((prev) => ({ ...prev, subject: '', body: '' }))
        setSuggestionsPanelOpen(false)
        setPreferencesPanelOpen(false)
        setIsMinimized(false)
        onClose()
      })
      .catch((error) => {
        const errorMessage = error.body?.message || error.body?.error || 'An unexpected error occurred'
        toast.error('Failed to send email', { description: errorMessage })
      })
      .finally(() => {
        setSendingGmail(false)
      })
  }, [currentEmail, accountId, onEmailSent, onClose, setLastAction])

  const handleComposerChange = useCallback((e) => {
    const { name, value } = e.target
    setCurrentEmail((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleReplaceEmail = useCallback(
    (email: EmailProps) => {
      setCurrentEmail(email)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEmail]
  )

  const { copy } = useCopyToClipboard()

  const handleCopyToClipboard = useCallback(() => {
    if (currentEmail?.body) {
      copy(currentEmail.body)
      toast(`Copied message body to clipboard`)
    }
  }, [copy, currentEmail?.body])

  const handleLinkedin = useCallback(() => {
    if (currentEmail?.body) {
      copy(currentEmail.body)
      toast(`Copied message body to clipboard`)
      // open linkedin in new tab
      window.open(selectedProspect?.record?.linkedin_url, '_blank', 'noopener,noreferrer')
    }
  }, [copy, currentEmail?.body, selectedProspect?.record?.linkedin_url])

  const actions = useMemo(() => {
    const availableActions: string[] = []

    if (gmailConnected) {
      availableActions.push('send-gmail')
    }

    if (canSendOutreach) {
      availableActions.push('send-outreach')
    } else if (outreachEnabled) {
      availableActions.push('connect-outreach')
    }

    if (canSendApollo) {
      availableActions.push('send-apollo')
    } else if (apolloEnabled) {
      availableActions.push('connect-apollo')
    }

    availableActions.push('compose-gmail')

    return availableActions
  }, [gmailConnected, canSendOutreach, outreachEnabled, canSendApollo, apolloEnabled])

  const maxH = useBreakpointValue({ base: '95vh', sm: '90vh', md: '85vh', lg: '80vh' })

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) {
        onClose()
      }
    }

    document.addEventListener('keydown', handleEscape)
    return () => document.removeEventListener('keydown', handleEscape)
  }, [isOpen, onClose])

  const actionData = {
    'send-gmail': {
      label: 'Send w/ Gmail',
      icon: <Image src={GoogleLogo} boxSize="16px" />,
      onClick: handleSend,
      isLoading: sendingGmail,
      isDisabled: disabled || sending || mailbox.isLoading
    },
    'send-outreach': {
      label: 'Send w/ Outreach',
      icon: <OutreachIcon color="outreach" boxSize={4} />,
      onClick: handleSendOutreach,
      isLoading: sendingOutreach,
      isDisabled: disabled || sending || outreachLoading
    },
    'send-apollo': {
      label: 'Send w/ Apollo',
      icon: <ApolloIcon color="blue.500" boxSize={4} />,
      onClick: handleSendApollo,
      isLoading: sendingApollo,
      isDisabled: disabled || sending || apolloLoading
    },
    'connect-outreach': {
      label: 'Connect Outreach',
      icon: <OutreachIcon color="outreach" boxSize={4} />,
      onClick: requestOutreachScope,
      isDisabled: disabled
    },
    'connect-apollo': {
      label: 'Connect Apollo',
      icon: <ApolloIcon color="blue.500" boxSize={4} />,
      onClick: requestApolloScope,
      isDisabled: disabled
    },
    'compose-gmail': {
      label: 'Open in Gmail',
      icon: <Image src={GoogleLogo} boxSize="16px" />,
      href: createGmailComposeUrl(currentEmail || {}, user?.email || ''),
      onClick: () => setLastAction('compose-gmail')
    },
    'copy-linkedin': {
      label: 'Copy + open LinkedIn',
      icon: <Icon as={LinkedinBoxIcon} boxSize={4} color="linkedin.700" />,
      onClick: handleLinkedin
    }
  }

  return (
    <Box>
      <Button
        onClick={onOpen}
        title="Start a draft"
        variant="outline"
        size="sm"
        isDisabled={disabled}
        leftIcon={<Icon as={AiSparklesIcon} boxSize={4} />}
        iconSpacing={1.5}
        bg="white"
        borderRadius={partOfSplitButton ? '4px 0 0 4px' : 'md'}
        borderRight={partOfSplitButton ? '0' : '1px solid'}
        borderColor="gray.200"
        {...buttonProps}
      >
        {buttonText}
      </Button>

      {isOpen && (
        <Portal>
          <Flex
            position="fixed"
            bottom={0}
            right={4}
            gap={2}
            height={isMinimized ? 'auto' : maxH}
            maxHeight={maxH}
            zIndex="popover"
          >
            {!selectedProspect && onSelectProspect && (
              <SelectProspectsScreen
                onClose={() => {
                  onSelectProspect?.(null)
                  onClose()
                }}
                onUpdateItem={(itemId, item) => {
                  onUpdateItem?.(itemId, item)
                }}
                isLoading={isLoadingProspects ?? false}
                prospects={prospects ?? []}
                onSelectProspect={onSelectProspect}
              />
            )}

            {selectedProspect && !isMinimized && suggestionsPanelOpen && (
              <RewritePanel
                itemId={selectedProspect.id}
                onClose={closeSuggestionsPanel}
                onSelect={handleReplaceEmail}
                prospects={prospects ?? []}
                onSelectProspect={onSelectProspect}
                selectedProspect={selectedProspect}
                runId={runId}
              />
            )}

            {!isMinimized && (
              <Box
                width={isMinimized ? 'auto' : '580px'}
                minWidth={isMinimized ? '300px' : 'auto'}
                maxWidth="calc(100vw - 32px)"
                height={isMinimized ? 'auto' : 'full'}
                bg="white"
                border="1px solid"
                borderColor="gray.200"
                shadow="heavy"
                roundedTop="md"
                display="flex"
                flexDirection="column"
              >
                {/* Header */}
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                  pl={4}
                  pr={2}
                  py={2}
                  borderBottomWidth="1px"
                  borderColor="gray.200"
                  bg="gray.50"
                  roundedTop="md"
                  cursor="pointer"
                  onClick={() => setIsMinimized((prev) => !prev)}
                >
                  <Heading size="xs" fontWeight="semibold">
                    {preferencesPanelOpen ? 'Email preferences' : 'New message'}
                  </Heading>

                  <Flex alignItems="center" gap={2}>
                    <IconButton
                      icon={<Icon as={IconMinus} boxSize={4} />}
                      size="xs"
                      variant="ghost"
                      aria-label={isMinimized ? 'Maximize' : 'Minimize'}
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsMinimized((prev) => !prev)
                        if (!isMinimized) {
                          // Close side panels when minimizing
                          setSuggestionsPanelOpen(false)
                          setPreferencesPanelOpen(false)
                        }
                      }}
                    />
                    <IconButton
                      icon={<Icon as={IconX} boxSize={4} />}
                      size="xs"
                      variant="ghost"
                      aria-label="Close"
                      onClick={(e) => {
                        e.stopPropagation()
                        onClose()
                      }}
                    />
                  </Flex>
                </Flex>

                {/* Only show body and footer when not minimized */}
                {!isMinimized && (
                  <>
                    {/* Existing body section */}
                    <Flex flex="1 1 auto" flexDirection="column" minHeight="200px" overflow="auto">
                      {preferencesPanelOpen && (
                        <EmailPreferencesPanel
                          onClose={closePreferencesPanel}
                          onSave={() => {
                            closePreferencesPanel()
                            // This forces the email suggestions to be refetched
                            setRunId(crypto.randomUUID())
                          }}
                        />
                      )}
                      {!preferencesPanelOpen && (
                        <Stack spacing={0} flex="1 1 auto" overflow="auto">
                          <Box px={4} py={2} h="full" flex="1 1 auto" overflow="auto">
                            <EmailComposer
                              onChange={handleComposerChange}
                              email={currentEmail}
                              togglePreferencesPanel={togglePreferencesPanel}
                              toggleSuggestionsPanel={toggleSuggestionsPanel}
                              suggestionsPanelOpen={suggestionsPanelOpen}
                              preferencesPanelOpen={preferencesPanelOpen}
                            />
                          </Box>

                          {warningMessage && <Box flex="none">{warningMessage}</Box>}

                          {/* Footer section */}
                          <Flex borderTop="1px solid" borderColor="gray.200" p={4} justify="flex-end" gap={2}>
                            {actions.length > 1 ? (
                              <ButtonGroup variant="outline" size="sm" isAttached>
                                {lastAction === 'send-gmail' && gmailConnected && (
                                  <Button
                                    leftIcon={actionData['send-gmail'].icon}
                                    onClick={actionData['send-gmail'].onClick}
                                    isLoading={actionData['send-gmail'].isLoading}
                                    isDisabled={actionData['send-gmail'].isDisabled}
                                  >
                                    {actionData['send-gmail'].label}
                                  </Button>
                                )}

                                {lastAction === 'send-outreach' && canSendOutreach && (
                                  <Button
                                    leftIcon={actionData['send-outreach'].icon}
                                    onClick={actionData['send-outreach'].onClick}
                                    isLoading={actionData['send-outreach'].isLoading}
                                    isDisabled={actionData['send-outreach'].isDisabled}
                                  >
                                    {actionData['send-outreach'].label}
                                  </Button>
                                )}

                                {lastAction === 'send-apollo' && canSendApollo && (
                                  <Button
                                    leftIcon={actionData['send-apollo'].icon}
                                    onClick={actionData['send-apollo'].onClick}
                                    isLoading={actionData['send-apollo'].isLoading}
                                    isDisabled={actionData['send-apollo'].isDisabled}
                                  >
                                    {actionData['send-apollo'].label}
                                  </Button>
                                )}

                                {lastAction === 'compose-gmail' && (
                                  <Button
                                    as="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    roundedRight="0"
                                    href={actionData['compose-gmail'].href}
                                    leftIcon={actionData['compose-gmail'].icon}
                                    onClick={actionData['compose-gmail'].onClick}
                                  >
                                    {actionData['compose-gmail'].label}
                                  </Button>
                                )}

                                {!lastAction && actionData[actions[0]] && (
                                  <Button
                                    // include href props if there is one
                                    {...(actionData[actions[0]].href
                                      ? {
                                          as: 'a',
                                          target: '_blank',
                                          rel: 'noopener noreferrer',
                                          roundedRight: '0',
                                          href: actionData[actions[0]].href
                                        }
                                      : {})}
                                    leftIcon={actionData[actions[0]].icon}
                                    onClick={actionData[actions[0]].onClick}
                                    isLoading={actionData[actions[0]].isLoading}
                                    isDisabled={actionData[actions[0]].isDisabled}
                                  >
                                    {actionData[actions[0]].label}
                                  </Button>
                                )}

                                <Menu>
                                  <MenuButton
                                    as={IconButton}
                                    icon={<Icon as={IconChevronDown} boxSize={4} />}
                                    variant="outline"
                                    size="sm"
                                    ml="-px"
                                    roundedLeft="0"
                                    isDisabled={disabled}
                                  />
                                  <MenuList>
                                    {
                                      gmailConnected ? (
                                        <MenuItem
                                          icon={actionData['send-gmail'].icon}
                                          onClick={actionData['send-gmail'].onClick}
                                          isDisabled={actionData['send-gmail'].isDisabled}
                                        >
                                          {actionData['send-gmail'].label}
                                        </MenuItem>
                                      ) : null
                                      // TODO add this back when we have approval!
                                      // <MenuItem
                                      //   icon={<Image src={GoogleLogo} boxSize="16px" />}
                                      //   onClick={requestGmailScope}
                                      // >
                                      //   Connect Gmail
                                      // </MenuItem>
                                    }

                                    {/* Show compose in Gmail when no integrations are connected */}
                                    <MenuItem
                                      as="a"
                                      icon={actionData['compose-gmail'].icon}
                                      href={actionData['compose-gmail'].href}
                                      onClick={actionData['compose-gmail'].onClick}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Flex alignItems="center" justifyContent="space-between" gap={2}>
                                        {actionData['compose-gmail'].label}
                                        <IconExternalLink size={14} />
                                      </Flex>
                                    </MenuItem>

                                    {canSendOutreach ? (
                                      <MenuItem
                                        icon={actionData['send-outreach'].icon}
                                        onClick={actionData['send-outreach'].onClick}
                                        isDisabled={actionData['send-outreach'].isDisabled}
                                      >
                                        {actionData['send-outreach'].label}
                                      </MenuItem>
                                    ) : outreachEnabled ? (
                                      <MenuItem
                                        icon={actionData['connect-outreach'].icon}
                                        onClick={actionData['connect-outreach'].onClick}
                                      >
                                        {actionData['connect-outreach'].label}
                                      </MenuItem>
                                    ) : null}

                                    {canSendApollo ? (
                                      <MenuItem
                                        icon={actionData['send-apollo'].icon}
                                        onClick={actionData['send-apollo'].onClick}
                                        isDisabled={actionData['send-apollo'].isDisabled}
                                      >
                                        {actionData['send-apollo'].label}
                                      </MenuItem>
                                    ) : apolloEnabled ? (
                                      <MenuItem
                                        icon={actionData['connect-apollo'].icon}
                                        onClick={actionData['connect-apollo'].onClick}
                                      >
                                        {actionData['connect-apollo'].label}
                                      </MenuItem>
                                    ) : null}

                                    <MenuItem
                                      icon={actionData['copy-linkedin'].icon}
                                      onClick={actionData['copy-linkedin'].onClick}
                                      isDisabled={!selectedProspect?.record?.linkedin_url}
                                    >
                                      <Flex alignItems="center" justifyContent="space-between" gap={2}>
                                        {actionData['copy-linkedin'].label}
                                        <IconExternalLink size={14} />
                                      </Flex>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </ButtonGroup>
                            ) : actions[0] === 'compose-gmail' ? (
                              <Button
                                leftIcon={actionData['compose-gmail'].icon}
                                rightIcon={<IconExternalLink size={14} />}
                                iconSpacing={1.5}
                                size="sm"
                                variant="outline"
                                as="a"
                                href={actionData['compose-gmail'].href}
                                onClick={actionData['compose-gmail'].onClick}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {actionData['compose-gmail'].label}
                              </Button>
                            ) : actions[0] && actionData[actions[0]] ? (
                              <Button
                                leftIcon={actionData[actions[0]].icon}
                                onClick={actionData[actions[0]].onClick}
                                isLoading={actionData[actions[0]].isLoading}
                                isDisabled={actionData[actions[0]].isDisabled}
                              >
                                {actionData[actions[0]].label}
                              </Button>
                            ) : null}

                            <Flex gap={1}>
                              <Tooltip label="Copy and open on LinkedIn">
                                <IconButton
                                  aria-label="Copy and open on LinkedIn"
                                  icon={<LinkedinBoxIcon boxSize="18px" color="linkedin.700" />}
                                  size="sm"
                                  variant="ghost"
                                  colorScheme="linkedin"
                                  onClick={handleLinkedin}
                                  isDisabled={!selectedProspect?.record?.linkedin_url}
                                />
                              </Tooltip>

                              <Tooltip label="Copy email to clipboard">
                                <IconButton
                                  aria-label="Copy email"
                                  icon={<Icon as={IconCopy} boxSize={4} />}
                                  size="sm"
                                  variant="ghost"
                                  onClick={handleCopyToClipboard}
                                />
                              </Tooltip>
                            </Flex>

                            <Tooltip label="Discard draft">
                              <Box ml="auto">
                                <IconButton
                                  aria-label="Discard draft"
                                  size="sm"
                                  variant="ghost"
                                  onClick={onClose}
                                  ml="auto"
                                  icon={<Icon as={IconTrash} boxSize={4} />}
                                />
                              </Box>
                            </Tooltip>
                          </Flex>
                        </Stack>
                      )}
                    </Flex>
                  </>
                )}
              </Box>
            )}
          </Flex>
        </Portal>
      )}
    </Box>
  )
}
