import {
  Box,
  Button,
  Code,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Link,
  ListItem,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useDisclosure
} from '@chakra-ui/react'
import { IconExternalLink, IconTableDown } from '@tabler/icons-react'
import { truncate } from 'lodash'
import React, { useMemo } from 'react'
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { formatNumber } from '../../../lib/number-format'
import router from '../../../lib/router'
import { PageMeta } from '../../../types/PageMeta'
import { theme } from '../../theme'
import { Card } from '../../ui/Card'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { DownloadCsvIcon, DownloadCsvTooltip } from '../../ui/DownloadCsvButtons'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { TableFooter } from '../../ui/TableFooter'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { TimeAgo } from '../../ui/TimeAgo'
import { HField } from '../accounts/components/Field'
import { humanize, titleize } from '../accounts/facets/filter-cloud'
import DateRangePicker from '../analytics/components/DateRangePicker'
import { friendlyNumber } from '../billing/v3'
import { mergeParams } from '../icps/types'

export interface Utms {
  click_id: UtmCategory
  source: UtmCategory
  medium: UtmCategory
  term: UtmCategory
  content: UtmCategory
  campaign: UtmCategory
}

export interface UtmCategory {
  entries: UtmEntry[]
  page_meta: PageMeta
}

export interface UtmEntry {
  property: string
  total: number
  total_visitors: number
  total_accounts: number
  last_visit: string
}

export interface PageViewEntry {
  id: string
  url: string
  account_domain: string | null
  email: string | null
  profile_id: string
  focus_time: number
  visit_start: string
  referrer: string | null
  utm_source: string | null
  utm_medium: string | null
  utm_term: string | null
  utm_content: string | null
  utm_campaign: string | null
  fit_grade_letter: string | null
  profile_url: 'https://app.getkoala.com/projects/koala/profiles/56b153fe-ba94-4981-828a-19ce532e2e9c'
  account_url: 'https://app.getkoala.com/projects/koala/accounts/qawolf.com'
}

interface Props {
  entries: PageViewEntry[]
  page_meta: PageMeta
  utms: Utms
  filters: {
    period: 'day' | 'week' | 'month'
  }
}

export default function TrafficSources(props: Props) {
  const period = props.filters.period
  const project = useCurrentProject()

  return (
    <PageLayout size="md">
      <SettingsBreadCrumb paths={[{ title: 'UTM Reports', path: projectPath('/utm-reports') }]} />

      <SettingsHeader>
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Stack>
            <PageTitle>UTM Reports</PageTitle>

            <PageDescription>
              Here you can see the traffic sources of your website, including all the UTM parameters.
            </PageDescription>
          </Stack>

          <DateRangePicker
            period={period}
            onChange={(period) => {
              router.visit(
                mergeParams(window.location.toString(), {
                  period: period,
                  page: '1'
                })
              )
            }}
          />
        </HStack>
      </SettingsHeader>

      {props.entries.length === 0 && <Text>No page views containing UTMs for the selected time period.</Text>}

      {props.entries.length > 0 && (
        <Stack spacing="8">
          <Box display="grid" gridGap={4} gridTemplateColumns="repeat(auto-fill, minmax(500px, 1fr))">
            <UTMTable
              property="source"
              entries={props.utms.source.entries}
              pageMeta={props.utms.source.page_meta}
              period={period}
            />
            <UTMTable
              property="medium"
              entries={props.utms.medium.entries}
              pageMeta={props.utms.medium.page_meta}
              period={period}
            />
            <UTMTable
              property="term"
              entries={props.utms.term.entries}
              pageMeta={props.utms.term.page_meta}
              period={period}
            />
            <UTMTable
              property="content"
              entries={props.utms.content.entries}
              pageMeta={props.utms.content.page_meta}
              period={period}
            />
            <UTMTable
              property="campaign"
              entries={props.utms.campaign.entries}
              pageMeta={props.utms.campaign.page_meta}
              period={period}
            />
            <UTMTable
              property="click_id"
              entries={props.utms.click_id.entries}
              pageMeta={props.utms.click_id.page_meta}
              period={period}
            />
          </Box>
          <Divider />

          <Stack>
            <HStack justifyContent="space-between">
              <Heading size="sm">Recent Page Views</Heading>
              <DownloadCSVButton
                basePath={projectPath(`/utm-reports.csv`)}
                pageMeta={props.page_meta}
                trigger={(onClick) => (
                  <DownloadCsvTooltip>
                    <Button
                      size="sm"
                      onClick={onClick}
                      variant="outline"
                      isDisabled={!project?.can_export_data}
                      leftIcon={<DownloadCsvIcon size={14} />}
                      iconSpacing={1.5}
                    >
                      Download CSV
                    </Button>
                  </DownloadCsvTooltip>
                )}
                title={<Heading size="sm">Recent Page Views containing UTMs</Heading>}
                description={
                  <Text fontSize={'sm'}>
                    This CSV contains the recent page views containing any UTMs. CSV downloads are limited to the most
                    recent 10,000 page views per page downloaded.
                  </Text>
                }
                period={period}
              />
            </HStack>
            <TableContainer>
              <Table size="sm" variant={'striped'}>
                <Thead>
                  <Tr>
                    <Th>URL</Th>
                    <Th>Account</Th>
                    <Th>Account Score</Th>
                    <Th>Profile Id</Th>
                    <Th>Source</Th>
                    <Th>Medium</Th>
                    <Th>Term</Th>
                    <Th>Content</Th>
                    <Th>Campaign</Th>
                    <Th>Referring Domain</Th>
                    <Th>Timestamp</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {props.entries.map((entry) => (
                    <Tr key={entry.id}>
                      <Td>
                        <Link href={entry.url} isExternal fontWeight={'normal'}>
                          <HStack>
                            <TextEllipsis maxW="340px" tooltip>
                              {entry.url}
                            </TextEllipsis>
                            <IconExternalLink size="12" />
                          </HStack>
                        </Link>
                      </Td>
                      <Td>
                        <Link href={entry.account_domain ?? ''}>
                          <HStack>
                            {entry.account_domain && <CompanyAvatar domain={entry.account_domain} size="xs" />}
                            <TextEllipsis maxW="100px">{entry.account_domain}</TextEllipsis>
                          </HStack>
                        </Link>
                      </Td>
                      <Td>{entry.fit_grade_letter}</Td>
                      <Td>
                        <Link href={entry.profile_url}>{entry.email ?? entry.profile_id}</Link>
                      </Td>
                      <Td>{entry.utm_source}</Td>
                      <Td>{entry.utm_medium}</Td>
                      <Td>{entry.utm_term}</Td>
                      <Td>{entry.utm_content}</Td>
                      <Td>{entry.utm_campaign}</Td>
                      <Td>
                        <TextEllipsis tooltip maxW="150px">
                          {entry.referrer}
                        </TextEllipsis>
                      </Td>
                      <Td>
                        <TimeAgo time={entry.visit_start} />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <TableFooter
              scrollToTop={false}
              pageMeta={props.page_meta}
              page={props.page_meta.current_page}
              nextPath={mergeParams(window.location.toString(), {
                page: `${props.page_meta.current_page + 1}`
              })}
              prevPath={mergeParams(window.location.toString(), {
                page: `${props.page_meta.current_page - 1}`
              })}
            />
          </Stack>
        </Stack>
      )}
    </PageLayout>
  )
}

export function UTMTable({
  property,
  entries,
  pageMeta,
  period
}: {
  property: string
  entries: UtmEntry[]
  pageMeta: PageMeta
  period: 'day' | 'week' | 'month'
}) {
  const project = useCurrentProject()

  if (entries.length === 0) {
    return null
  }

  return (
    <Stack as={Card} flex="1" p={5} pt={0} spacing="4" alignItems={'space-between'} justifyContent={'space-between'}>
      <Flex gap={4} justifyContent="space-between" py={5}>
        <Heading size="sm">{titleize(property)}</Heading>

        <HStack justifyContent={'flex-end'} spacing={4}>
          <Link href={projectPath(`/utm-reports/utm/${property}`)} fontSize="sm" color={'purple.500'}>
            View all
          </Link>

          <DownloadCSVButton
            basePath={projectPath(`/utm-reports/utm/${property}.csv`)}
            pageMeta={pageMeta}
            trigger={(onClick) => (
              <DownloadCsvTooltip>
                <Button
                  size="sm"
                  onClick={onClick}
                  variant="outline"
                  isDisabled={!project?.can_export_data}
                  leftIcon={<DownloadCsvIcon size={14} />}
                  iconSpacing={1.5}
                >
                  Download CSV
                </Button>
              </DownloadCsvTooltip>
            )}
            title={
              <Heading size="sm">
                Recent Page Views for{' '}
                <Code fontWeight={'semibold'} px="2" py="1">
                  utm_{property}
                </Code>
              </Heading>
            }
            description={
              <Text fontSize={'sm'}>
                This CSV contains the recent page views for utm_{property}. CSV downloads are limited to the most recent
                100,000 page views per download.
              </Text>
            }
            period={period}
          />
        </HStack>
      </Flex>

      {entries.length > 1 && (
        <ResponsiveContainer width="100%" height={280}>
          <ComposedChart
            data={(entries ?? []).slice(0, 10).map((f) => {
              return {
                ...f,
                fill: theme.colors['purple']['400']
              }
            })}
            layout="vertical"
            barCategoryGap={10}
          >
            <Tooltip
              contentStyle={{
                color: theme.colors.gray['700'],
                fontSize: 14,
                border: 'none',
                borderRadius: '4px',
                boxShadow: `0px 1px 1px 0px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 12%)`
              }}
              allowEscapeViewBox={{ y: true }}
              formatter={(value: string) => formatNumber(value)}
              wrapperStyle={{ outline: 'none', zIndex: 1000 }}
            />
            <CartesianGrid strokeDasharray="4 4" vertical={false} stroke={theme.colors.gray['200']} />

            <YAxis
              dataKey={'property'}
              tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
              tickFormatter={(v: string) => truncate(v, { length: 10 })}
              stroke={theme.colors.gray['200']}
              type="category"
              interval="preserveStartEnd"
              domain={['dataMin', 'dataMax']}
            />
            <XAxis
              width={42}
              dataKey={'total'}
              tickFormatter={(v) => formatNumber(v)}
              type="number"
              tickLine={false}
              axisLine={false}
              interval="preserveStartEnd"
              tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
            />

            <Bar
              stackId={'a'}
              key={'total'}
              dataKey={'total'}
              label={{
                position: 'right',
                fontSize: 11,
                fill: theme.colors.gray['600'],
                formatter: (value: string) => formatNumber(value)
              }}
              minPointSize={20}
              layout="vertical"
              radius={[0, 4, 4, 0]}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}

      <TableContainer>
        <Table size="sm" variant={'striped'}>
          <Thead>
            <Tr>
              <Th>Property</Th>
              <Th isNumeric>Visits</Th>
              <Th isNumeric>Visitors</Th>
              <Th isNumeric>Accounts</Th>
            </Tr>
          </Thead>
          <Tbody>
            {entries.map((entry) => (
              <Tr key={entry.property}>
                <Td>
                  <Link href={projectPath(`/utm-reports/utm/${property}/${entry.property}`)}>{entry.property}</Link>
                </Td>
                <Td isNumeric>{friendlyNumber(entry.total)}</Td>
                <Td isNumeric>{friendlyNumber(entry.total_visitors)}</Td>
                <Td isNumeric>{friendlyNumber(entry.total_accounts)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

interface DownloadCSVButtonProps {
  period: 'day' | 'week' | 'month'
  pageMeta: PageMeta
  title: React.ReactNode
  description: React.ReactNode
  basePath: string
  trigger?: (onClick: () => void) => React.ReactNode
}

export function DownloadCSVButton(props: DownloadCSVButtonProps) {
  const disclosure = useDisclosure()
  const downloadPages = useMemo(() => {
    const totalPages = Math.ceil(props.pageMeta.total_count / 100_000)

    return Array.from({ length: totalPages }, (_, i) => {
      return mergeParams(props.basePath, {
        page: `${i + 1}`,
        period: props.period
      })
    })
  }, [props.basePath, props.pageMeta, props.period])
  const project = useCurrentProject()

  return (
    <Box>
      <Drawer size="lg" isOpen={disclosure.isOpen} placement="right" onClose={disclosure.onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p="4">
            <Stack spacing="6">
              <HStack>
                <IconTableDown size="20" />
                {props.title}
              </HStack>

              {props.description}

              <Stack spacing="0">
                <HField label="Entries:" value={friendlyNumber(props.pageMeta.total_count)} />
                <HField label="Pages:" value={friendlyNumber(downloadPages.length)} />
                <HField label="Range:" value={humanize(props.period.toString())} />
              </Stack>

              <Stack spacing="1">
                <Heading size="xs">Download URLs</Heading>
                <UnorderedList pl="6">
                  {downloadPages.map((path) => (
                    <ListItem key={path}>
                      <Link fontSize="sm" href={path} color="green.500" isExternal>
                        {path}
                      </Link>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Stack>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {props.trigger && props.trigger(disclosure.onOpen)}
      {!props.trigger && (
        <DownloadCsvTooltip>
          <Button
            size="sm"
            onClick={disclosure.onOpen}
            colorScheme={'green'}
            variant="outline"
            isDisabled={!project?.can_export_data}
            leftIcon={<DownloadCsvIcon size={14} />}
          >
            Download CSV
          </Button>
        </DownloadCsvTooltip>
      )}
    </Box>
  )
}
