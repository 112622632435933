import { post } from '@app/lib/api'
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  SkeletonText,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Textarea,
  VStack
} from '@chakra-ui/react'
import {
  IconBrandLinkedin,
  IconChevronDown,
  IconCopy,
  IconExternalLink,
  IconMail,
  IconMailBolt,
  IconPhone,
  IconSettings,
  IconSparkles,
  IconX
} from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'sonner'
import useLocalStorageState from 'use-local-storage-state'
import {
  extractKeyInsightsBulletPoints,
  sendComposerFeedback,
  useEmailRecommendations
} from '../../../data/use-ai-recommendations'
import { apolloSingleSend, useApolloSingleSendSettings } from '../../../data/use-apollo-single-send'
import { outreachSingleSend, useOutreachSingleSendSettings } from '../../../data/use-outreach-single-send'
import GoogleLogo from '../../../pages/devise/registrations/components/google.svg'
import { EmailSuggestions, ToolStream } from '../../../ui/composer/email-suggestions'
import { EmailPreferencesPanel } from '../../../ui/composer/EmailPreferencesPanel'
import { PulsingText } from '../../../ui/composer/PulsingText'
import { EmailProps } from '../../../ui/EmailComposer'
import { AiSparklesIcon, ApolloIcon, OutreachIcon } from '../../../ui/icons'
import MarkdownText from '../../../ui/MarkdownText'
import { projectPath } from '../../../ui/ProjectsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { useCopyToClipboard } from '../../../ui/useCopyToClipboard'
import { useCurrentUser } from '../../../ui/UserContext'
import { GradientBox } from './Data'

function createGmailComposeUrl(email: EmailProps, fromEmail: string) {
  const params = new URLSearchParams({
    to: email.to || '',
    su: email.subject || '',
    body: email.body || ''
  })
  return `https://mail.google.com/mail/u/${fromEmail || '0'}/?tf=cm&fs=1&${params.toString()}`
}

interface WritingAssistantProps {
  selectedItems: string[]
  onClearSelection: () => void
  onSubmit: (feedback: string, selectedItems: string[]) => void
  isLoading: boolean
}

// Writing Assistant component
const WritingAssistant: React.FC<WritingAssistantProps> = ({
  selectedItems,
  onClearSelection,
  onSubmit,
  isLoading
}) => {
  const [instructions, setInstructions] = useState('')
  const [localSelectedItems, setLocalSelectedItems] = useState<string[]>(selectedItems)

  // Update local state when parent prop changes
  React.useEffect(() => {
    setLocalSelectedItems(selectedItems)
  }, [selectedItems])

  return (
    <Stack w="100%" p="1">
      <Textarea
        placeholder="Give the AI additional context or instructions..."
        color="gray.500"
        fontSize="sm"
        bg="white"
        value={instructions}
        isDisabled={isLoading}
        onChange={(e) => setInstructions(e.target.value)}
      />

      {localSelectedItems.length > 0 && (
        <Box bg="purple.50" p={3} borderRadius="md" position="relative" mb={4}>
          <HStack justify="space-between" w="full">
            <Text fontSize="sm" color="purple.700">
              {localSelectedItems.length} data points selected
            </Text>
            <Button
              size="xs"
              variant="ghost"
              colorScheme="purple"
              p={1}
              minW="auto"
              h="auto"
              onClick={() => {
                onClearSelection()
                setLocalSelectedItems([])
              }}
              aria-label="Clear selection"
            >
              <Icon as={IconX} boxSize={4} />
            </Button>
          </HStack>
        </Box>
      )}

      <Button
        leftIcon={<Icon as={IconSparkles} />}
        colorScheme="purple"
        variant="solid"
        size="md"
        w="full"
        isDisabled={localSelectedItems.length === 0 && !instructions}
        onClick={() => onSubmit(instructions, localSelectedItems)}
        isLoading={isLoading}
      >
        Rewrite
      </Button>
    </Stack>
  )
}

interface ResearchModalProps {
  isOpen: boolean
  onClose: () => void
  prospectName: string
  prospectId: string
  prospectEmail?: string
  prospectTitle?: string
}

export const ResearchModal: React.FC<ResearchModalProps> = ({
  isOpen,
  onClose,
  prospectName,
  prospectId,
  prospectEmail,
  prospectTitle
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [selectedEmail, setSelectedEmail] = useState<EmailProps | null>(null)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [isKeyInfoOpen, setIsKeyInfoOpen] = useState(true)
  const [isSuggestedEmailsOpen, setIsSuggestedEmailsOpen] = useState(true)
  const [isWritingAssistantOpen, setIsWritingAssistantOpen] = useState(false)
  const [isResearchOpen, setIsResearchOpen] = useState(false)
  const [expandedResults, setExpandedResults] = useState<Record<number, boolean>>({})
  const [runId, setRunId] = useState<string | null>(prospectId)
  const [writerFeedback, setWriterFeedback] = useState<string[]>([])
  // Track selected key information items for display purposes only
  const [selectedKeyInfo, setSelectedKeyInfo] = useState<string[]>([])
  // Track the key information items that were actually submitted for API calls
  const [submittedKeyInfo, setSubmittedKeyInfo] = useState<string[]>([])
  // Store original key insights to preserve them across rewrites
  const [originalKeyInsights, setOriginalKeyInsights] = useState<string[]>([])
  const user = useCurrentUser()
  const [sendingGmail, setSendingGmail] = useState(false)
  const [sendingOutreach, setSendingOutreach] = useState(false)
  const [sendingApollo, setSendingApollo] = useState(false)
  const { canSendOutreach, outreachEnabled, isLoading: outreachLoading } = useOutreachSingleSendSettings()
  const { canSendApollo, apolloEnabled, isLoading: apolloLoading } = useApolloSingleSendSettings()
  const { copy } = useCopyToClipboard()
  const [lastAction, setLastAction] = useLocalStorageState<
    'send-gmail' | 'send-outreach' | 'send-apollo' | 'compose-gmail' | null
  >(`koala:research:last-action`, {
    defaultValue: null
  })

  const fullInstructions = useMemo(() => {
    const feedback = writerFeedback

    if (submittedKeyInfo.length > 0) {
      feedback.push(`Use the following key information to rewrite the email:\n ${submittedKeyInfo.join('\n')}`)
    }

    return feedback
  }, [writerFeedback, submittedKeyInfo])

  const {
    emailStrategy,
    suggestedEmails,
    isLoading: loadingSuggestions,
    toolStream,
    mostRecentTool,
    researchResults
  } = useEmailRecommendations(
    prospectId,
    {
      runId: runId || prospectId,
      feedback: fullInstructions
    },
    { enabled: !!prospectId && isOpen }
  )

  // Extract key insights only once from the initial emailStrategy
  const keyInsights = useMemo(() => {
    const extractedInsights = extractKeyInsightsBulletPoints(emailStrategy)
    // Store the original insights if we haven't already
    if (extractedInsights?.length && originalKeyInsights.length === 0 && !loadingSuggestions) {
      setOriginalKeyInsights(extractedInsights)
    }
    // Always use the original insights if available
    return originalKeyInsights.length > 0 ? originalKeyInsights : extractedInsights
  }, [emailStrategy, originalKeyInsights, loadingSuggestions])

  const onSubmitRewrite = useCallback((instructions: string, selectedItems: string[]) => {
    setWriterFeedback([instructions])
    setSubmittedKeyInfo(selectedItems)
    setRunId(crypto.randomUUID())
    // Automatically show suggestions when rewriting
    setIsSuggestedEmailsOpen(true)
  }, [])

  // Toggle selection of key information item
  const toggleKeyInfoSelection = (item: string) => {
    setSelectedKeyInfo((prev) => {
      if (prev.includes(item)) {
        return prev.filter((existing) => existing !== item)
      } else {
        return [...prev, item]
      }
    })
  }

  // Clear all selected items
  const clearKeyInfoSelection = () => {
    setSelectedKeyInfo([])
  }

  const onSubmitFeedback = useCallback(
    (feedbackType: string, feedbackComment: string) => {
      setShowFeedbackModal(false)

      sendComposerFeedback(prospectId, feedbackComment, feedbackType, {
        email: selectedEmail?.body || ''
      })

      toast.info('Thanks for your feedback!', {
        description: 'We will use this to improve email suggestions for your team.',
        dismissible: true,
        closeButton: true,
        position: 'top-center'
      })
    },
    [prospectId, selectedEmail]
  )

  const getCurrentEmail = useCallback(() => {
    if (selectedEmail) {
      return selectedEmail
    }
    if (suggestedEmails && suggestedEmails.length > 0) {
      return suggestedEmails[0]
    }
    return { to: '', subject: '', body: '' }
  }, [selectedEmail, suggestedEmails])

  const handleSendGmail = useCallback(async () => {
    const currentEmail = getCurrentEmail()

    if (!currentEmail?.subject || !currentEmail?.body) {
      toast.warning('Please enter an email subject and body')
      setSendingGmail(false)
      return
    }

    setSendingGmail(true)

    try {
      await post(projectPath('/apps/gmail'), {
        email: {
          to: currentEmail?.to ?? prospectEmail,
          subject: currentEmail?.subject,
          body: currentEmail?.body
        },
        account_id: prospectId
      })

      toast.success('Email sent!')
      // Reset state
      setSelectedEmail({ to: '', subject: '', body: '' })
      onClose()
    } catch (error: any) {
      const errorMessage = error.body?.message || error.body?.error || 'An unexpected error occurred'
      toast.error('Failed to send email', { description: errorMessage })
    } finally {
      setSendingGmail(false)
    }
  }, [getCurrentEmail, prospectId, onClose, prospectEmail])

  const handleSendOutreach = useCallback(async () => {
    const currentEmail = getCurrentEmail()

    if (!outreachEnabled) {
      toast.error('You do not have permission to send emails with Outreach')
      setSendingOutreach(false)
      return
    }

    if (!currentEmail?.subject || !currentEmail?.body) {
      toast.warning('Please enter an email subject and body')
      setSendingOutreach(false)
      return
    }

    setSendingOutreach(true)

    try {
      await outreachSingleSend(
        { email: currentEmail.to!, account_id: prospectId },
        {
          subject: currentEmail.subject!,
          body: currentEmail.body!
        }
      )

      toast.success('Email sent!')
      // Reset state
      setSelectedEmail({ to: prospectEmail, subject: '', body: '' })
      onClose()
    } catch (error: any) {
      const errorMessage = error.body?.message || error.body?.error || 'An unexpected error occurred'
      toast.error('Failed to send email', { description: errorMessage })
    } finally {
      setSendingOutreach(false)
    }
  }, [getCurrentEmail, prospectId, onClose, outreachEnabled, prospectEmail])

  const handleSendApollo = useCallback(async () => {
    const currentEmail = getCurrentEmail()

    if (!apolloEnabled) {
      toast.error('You do not have permission to send emails with Apollo')
      setSendingApollo(false)
      return
    }

    if (!currentEmail?.subject || !currentEmail?.body) {
      toast.warning('Please enter an email subject and body')
      setSendingApollo(false)
      return
    }

    setSendingApollo(true)

    try {
      await apolloSingleSend(
        { email: currentEmail.to! },
        {
          subject: currentEmail.subject!,
          body: currentEmail.body!
        }
      )

      toast.success('Email sent!')
      // Reset state
      setSelectedEmail({ to: '', subject: '', body: '' })
      onClose()
    } catch (error: any) {
      const errorMessage = error.body?.message || error.body?.error || 'An unexpected error occurred'
      toast.error('Failed to send email', { description: errorMessage })
    } finally {
      setSendingApollo(false)
    }
  }, [getCurrentEmail, onClose, apolloEnabled])

  const handleCopyToClipboard = useCallback(() => {
    const currentEmail = getCurrentEmail()
    if (currentEmail?.body) {
      copy(currentEmail.body)
      toast.success('Copied email to clipboard')
    }
  }, [copy, getCurrentEmail])

  const getActionButton = useCallback(() => {
    const currentEmail = getCurrentEmail()
    const gmailComposeUrl = createGmailComposeUrl(currentEmail, user?.email || '')
    const isLoading = sendingGmail || sendingOutreach || sendingApollo

    const getButtonContent = () => {
      if (isLoading) {
        return 'Sending...'
      }

      switch (lastAction) {
        case 'send-outreach':
          return 'Send w/ Outreach'
        case 'send-apollo':
          return 'Send w/ Apollo'
        case 'compose-gmail':
        default:
          return 'Open in Gmail'
      }
    }

    return (
      <HStack spacing={2}>
        <ButtonGroup variant="outline" size="sm" isAttached>
          <Button
            leftIcon={
              lastAction === 'send-outreach' ? (
                <OutreachIcon color="outreach" boxSize={4} />
              ) : lastAction === 'send-apollo' ? (
                <ApolloIcon color="blue.500" boxSize={4} />
              ) : (
                <Image src={GoogleLogo} boxSize={4} />
              )
            }
            onClick={() => {
              if (lastAction === 'compose-gmail' || !lastAction) {
                window.open(gmailComposeUrl, '_blank')
              } else {
                switch (lastAction) {
                  case 'send-gmail':
                    handleSendGmail()
                    break
                  case 'send-outreach':
                    handleSendOutreach()
                    break
                  case 'send-apollo':
                    handleSendApollo()
                    break
                }
              }
            }}
            as={lastAction === 'compose-gmail' || !lastAction ? Link : Button}
            rel="noopener noreferrer"
            roundedRight="0"
            isLoading={isLoading}
            // @ts-ignore this is a bit of a hack to get the correct type for the button
            target="_blank"
            // @ts-ignore this is a bit of a hack to get the correct type for the button
            href={lastAction === 'compose-gmail' || !lastAction ? gmailComposeUrl : undefined}
          >
            {getButtonContent()}
          </Button>

          <Menu>
            <MenuButton
              as={IconButton}
              icon={<Icon as={IconChevronDown} boxSize={4} />}
              variant="outline"
              size="sm"
              ml="-px"
              roundedLeft="0"
              isDisabled={isLoading}
            />
            <MenuList>
              <MenuItem
                icon={<Image src={GoogleLogo} boxSize={4} />}
                onClick={() => {
                  setLastAction('send-gmail')
                  handleSendGmail()
                }}
                isDisabled={sendingGmail}
              >
                Send w/ Gmail
              </MenuItem>

              {canSendOutreach && (
                <MenuItem
                  icon={<OutreachIcon color="outreach" boxSize={4} />}
                  onClick={() => {
                    setLastAction('send-outreach')
                    handleSendOutreach()
                  }}
                  isDisabled={sendingOutreach || outreachLoading}
                >
                  Send w/ Outreach
                </MenuItem>
              )}

              {canSendApollo && (
                <MenuItem
                  icon={<ApolloIcon color="blue.500" boxSize={4} />}
                  onClick={() => {
                    setLastAction('send-apollo')
                    handleSendApollo()
                  }}
                  isDisabled={sendingApollo || apolloLoading}
                >
                  Send w/ Apollo
                </MenuItem>
              )}

              <MenuItem icon={<Image src={GoogleLogo} boxSize={4} />} onClick={() => setLastAction('compose-gmail')}>
                <Flex alignItems="center" justifyContent="space-between" gap={2}>
                  Open in Gmail
                  <IconExternalLink size={14} />
                </Flex>
              </MenuItem>

              <MenuItem icon={<Icon as={IconCopy} boxSize={4} />} onClick={handleCopyToClipboard}>
                <Flex alignItems="center" justifyContent="space-between" gap={2}>
                  Copy to clipboard
                  <IconExternalLink size={14} />
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>
      </HStack>
    )
  }, [
    getCurrentEmail,
    user,
    handleSendGmail,
    sendingGmail,
    handleSendOutreach,
    sendingOutreach,
    canSendOutreach,
    outreachLoading,
    handleSendApollo,
    sendingApollo,
    canSendApollo,
    apolloLoading,
    handleCopyToClipboard,
    lastAction,
    setLastAction
  ])

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" blockScrollOnMount>
        <ModalOverlay overflow={'hidden'} />
        <ModalContent height="90vh" borderRadius="xl" overflow="hidden">
          <ModalBody p={0} display="flex" flexDirection="column" height="100%" overflow="hidden">
            {showSettingsModal && (
              <EmailPreferencesPanel
                onClose={() => setShowSettingsModal(false)}
                onSave={() => {
                  setShowSettingsModal(false)
                  // This forces the email suggestions to be refetched
                  setRunId(crypto.randomUUID())
                }}
              />
            )}

            {!showFeedbackModal && (
              <Box display="flex" flex={1} minHeight={0}>
                {/* Left Sidebar */}
                <Box
                  width="480px"
                  borderRight="1px"
                  borderColor="gray.200"
                  display="flex"
                  flexDirection="column"
                  minHeight={0}
                >
                  <Box p="4">
                    <Heading fontWeight="semibold" size="sm">
                      {prospectName}
                    </Heading>
                    <Text fontSize="xs" color="gray.500">
                      {prospectTitle}
                    </Text>
                  </Box>

                  <Box flex={1} display="flex" flexDirection="column" minHeight={0}>
                    <Box flex={1} overflowY="auto" p={4}>
                      <VStack spacing={4} align="stretch">
                        {/* Research Widget - Now above Key Information */}
                        {(toolStream?.length > 0 || researchResults?.length > 0) && (
                          <Stack p="4" borderRadius="md" borderWidth={1} borderColor="purple.100">
                            <HStack
                              onClick={() => setIsResearchOpen(!isResearchOpen)}
                              cursor="pointer"
                              _hover={{ color: 'purple.600' }}
                              mb={isResearchOpen ? 4 : 0}
                            >
                              <Icon as={IconSparkles} color="purple.400" boxSize={5} />
                              <Heading fontWeight="semibold" size="xs">
                                {loadingSuggestions ? (
                                  <PulsingText>Researching...</PulsingText>
                                ) : (
                                  `Research ${researchResults?.length > 0 ? `(${researchResults.length})` : ''}`
                                )}
                              </Heading>
                              <Icon
                                as={IconChevronDown}
                                transform={isResearchOpen ? 'rotate(0deg)' : 'rotate(-90deg)'}
                                transition="transform 0.2s"
                              />
                            </HStack>

                            <Collapse in={isResearchOpen} animateOpacity>
                              {researchResults?.length > 0 ? (
                                <Stack spacing={4} px="2">
                                  {researchResults.map((result, index) => (
                                    <Stack key={result.question} spacing="0">
                                      <TextEllipsis fontSize="sm" fontWeight="medium" color="gray.700" maxW="100%">
                                        {index + 1}. {result.question}
                                      </TextEllipsis>
                                      <Box>
                                        <HStack spacing={1} align="start">
                                          <Box flex={1}>
                                            {!expandedResults[index] && (
                                              <TextEllipsis
                                                fontSize="sm"
                                                color="gray.600"
                                                noOfLines={expandedResults[index] ? undefined : 1}
                                              >
                                                {result.short_answer}
                                              </TextEllipsis>
                                            )}
                                            <Collapse in={expandedResults[index]}>
                                              <Text fontSize="sm" color="gray.600" mt={1}>
                                                {result.short_answer}
                                              </Text>
                                            </Collapse>
                                          </Box>
                                        </HStack>
                                        <Button
                                          variant="link"
                                          size="xs"
                                          _hover={{ color: 'purple.600' }}
                                          onClick={() =>
                                            setExpandedResults((prev) => ({
                                              ...prev,
                                              [index]: !prev[index]
                                            }))
                                          }
                                          minW="auto"
                                          h="auto"
                                          p={0}
                                        >
                                          {expandedResults[index] ? 'Show less' : 'Read more'}
                                        </Button>
                                      </Box>
                                    </Stack>
                                  ))}
                                </Stack>
                              ) : (
                                toolStream?.length > 0 && (
                                  <ToolStream
                                    toolStream={toolStream}
                                    isLoading={loadingSuggestions}
                                    mostRecentTool={mostRecentTool}
                                  />
                                )
                              )}
                            </Collapse>
                          </Stack>
                        )}

                        <GradientBox>
                          {/* Key Information Card */}
                          <HStack
                            onClick={() => setIsKeyInfoOpen(!isKeyInfoOpen)}
                            cursor="pointer"
                            _hover={{ color: 'purple.600' }}
                            mb={isKeyInfoOpen ? 4 : 0}
                          >
                            <AiSparklesIcon color="purple.400" boxSize={5} />
                            <Heading fontWeight="semibold" size="xs">
                              {loadingSuggestions ? (
                                <PulsingText>{suggestedEmails.length > 0 ? 'Writing...' : 'Thinking...'}</PulsingText>
                              ) : (
                                'Key Information'
                              )}
                            </Heading>
                            <Icon
                              as={IconChevronDown}
                              transform={isKeyInfoOpen ? 'rotate(0deg)' : 'rotate(-90deg)'}
                              transition="transform 0.2s"
                            />
                          </HStack>

                          <Collapse in={isKeyInfoOpen} animateOpacity>
                            <List spacing={0.5}>
                              {keyInsights?.map((item, index) => {
                                const isSelected = selectedKeyInfo.includes(item)
                                return (
                                  <ListItem key={index} position="relative">
                                    <Box
                                      display="flex"
                                      borderRadius="md"
                                      bg={isSelected ? 'whiteAlpha.500' : 'transparent'}
                                      p={2}
                                      pr={8}
                                      position="relative"
                                      cursor="pointer"
                                      onClick={() => toggleKeyInfoSelection(item)}
                                      _hover={{ bg: isSelected ? 'whiteAlpha.500' : 'purple.50' }}
                                      transition="background 0.2s"
                                      borderWidth={1}
                                      borderColor={isSelected ? 'purple.200' : 'transparent'}
                                      color={isSelected ? 'purple.500' : undefined}
                                      fontWeight={isSelected ? 'medium' : 'normal'}
                                    >
                                      <Text
                                        fontSize="sm"
                                        as="span"
                                        mr={2}
                                        color={isSelected ? 'purple.500' : 'gray.400'}
                                      >
                                        •
                                      </Text>
                                      <MarkdownText>{item}</MarkdownText>
                                      {isSelected && (
                                        <Button
                                          position="absolute"
                                          right={1}
                                          top="50%"
                                          transform="translateY(-50%)"
                                          size="xs"
                                          variant="ghost"
                                          colorScheme="purple"
                                          minW="auto"
                                          h="auto"
                                          p={1}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            toggleKeyInfoSelection(item)
                                          }}
                                          aria-label="Remove item"
                                        >
                                          <Icon as={IconX} boxSize={3} color="purple.500" />
                                        </Button>
                                      )}
                                    </Box>
                                  </ListItem>
                                )
                              })}
                            </List>

                            {keyInsights?.length > 0 && (
                              <Box mt={4}>
                                <Heading fontWeight="semibold" size="xs">
                                  Sources:
                                </Heading>
                                <HStack spacing={1.5} wrap="wrap">
                                  <Badge
                                    bg="purple.100"
                                    color="purple.700"
                                    fontSize="xs"
                                    py={0.5}
                                    px={1.5}
                                    borderRadius="md"
                                  >
                                    CRM Notes
                                  </Badge>
                                  <Badge
                                    bg="green.100"
                                    color="green.700"
                                    fontSize="xs"
                                    py={0.5}
                                    px={1.5}
                                    borderRadius="md"
                                  >
                                    Intent Activity
                                  </Badge>
                                  <Badge
                                    bg="orange.100"
                                    color="orange.700"
                                    fontSize="xs"
                                    py={0.5}
                                    px={1.5}
                                    borderRadius="md"
                                  >
                                    LinkedIn Research
                                  </Badge>
                                  <Badge
                                    bg="gray.100"
                                    color="gray.700"
                                    fontSize="xs"
                                    py={0.5}
                                    px={1.5}
                                    borderRadius="md"
                                  >
                                    Tech Stack Analysis
                                  </Badge>
                                </HStack>
                              </Box>
                            )}
                          </Collapse>
                        </GradientBox>
                      </VStack>
                    </Box>

                    {/* Writing Assistant - Now outside the scrollable area */}
                    <Box p={4} borderTop="1px" borderColor="gray.200" bg="gray.50">
                      <HStack
                        onClick={() => setIsWritingAssistantOpen(!isWritingAssistantOpen)}
                        cursor="pointer"
                        _hover={{ color: 'purple.600' }}
                        mb={isWritingAssistantOpen ? 4 : 0}
                      >
                        <Icon as={IconSparkles} color="purple.400" boxSize={5} />
                        <Heading size="xs" fontWeight="semibold">
                          Writing Assistant
                        </Heading>
                        <Icon
                          as={IconChevronDown}
                          transform={isWritingAssistantOpen ? 'rotate(0deg)' : 'rotate(180deg)'}
                          transition="transform 0.2s"
                        />
                      </HStack>

                      <Collapse in={isWritingAssistantOpen} animateOpacity>
                        <WritingAssistant
                          selectedItems={selectedKeyInfo}
                          onClearSelection={clearKeyInfoSelection}
                          onSubmit={onSubmitRewrite}
                          isLoading={loadingSuggestions}
                        />
                      </Collapse>
                    </Box>
                  </Box>
                </Box>

                {/* Main Content Area */}
                <Box flex={1} display="flex" flexDirection="column" minHeight={0}>
                  {/* Top Tabs + Actions */}
                  <HStack borderBottom="1px" borderColor="gray.200" px={4} py={1.5} justify="space-between">
                    <Tabs onChange={setSelectedTabIndex} variant="unstyled" width="auto">
                      <TabList>
                        <Tab
                          color={selectedTabIndex === 0 ? 'purple.600' : 'gray.500'}
                          borderBottom="2px"
                          borderColor={selectedTabIndex === 0 ? 'purple.600' : 'transparent'}
                          fontSize="sm"
                          _hover={{ color: 'purple.600' }}
                        >
                          <Icon as={IconMail} mr={2} />
                          Email
                        </Tab>
                        <Tab
                          color={selectedTabIndex === 1 ? 'purple.600' : 'gray.500'}
                          borderBottom="2px"
                          borderColor={selectedTabIndex === 1 ? 'purple.600' : 'transparent'}
                          fontSize="sm"
                          _hover={{ color: 'purple.600' }}
                          isDisabled
                        >
                          <Icon as={IconBrandLinkedin} mr={2} />
                          LinkedIn
                        </Tab>
                        <Tab
                          color={selectedTabIndex === 2 ? 'purple.600' : 'gray.500'}
                          borderBottom="2px"
                          borderColor={selectedTabIndex === 2 ? 'purple.600' : 'transparent'}
                          fontSize="sm"
                          _hover={{ color: 'purple.600' }}
                          isDisabled
                        >
                          <Icon as={IconPhone} mr={2} />
                          Call Script
                        </Tab>
                      </TabList>
                    </Tabs>

                    <HStack>
                      <IconButton
                        aria-label="Settings"
                        icon={<Icon as={IconSettings} />}
                        variant="ghost"
                        size="sm"
                        onClick={() => setShowSettingsModal(true)}
                      />
                      <IconButton
                        aria-label="Close"
                        icon={<Icon as={IconX} />}
                        variant="ghost"
                        size="sm"
                        onClick={onClose}
                      />
                    </HStack>
                  </HStack>

                  {/* Content Panels */}
                  <Box flex={1} display="flex" flexDirection="column" position="relative">
                    {selectedTabIndex === 0 && (
                      <Box p={6} flex={1} display="flex" flexDirection="column" overflow="auto" position="relative">
                        <Stack spacing="2" mb="6">
                          <HStack align="center">
                            <Text color="gray.500" fontSize="sm">
                              To
                            </Text>
                            <Input
                              value={getCurrentEmail().to}
                              onChange={(e) => {
                                setSelectedEmail({ ...getCurrentEmail(), to: e.target.value })
                              }}
                              variant="unstyled"
                              size="sm"
                              fontWeight="semibold"
                              placeholder="To"
                              _placeholder={{ color: 'gray.400' }}
                              color="inherit"
                            />
                          </HStack>

                          <Divider />

                          <HStack align="center">
                            <Text color="gray.500" fontSize="sm">
                              Subject
                            </Text>
                            <Input
                              value={getCurrentEmail().subject}
                              onChange={(e) => {
                                setSelectedEmail({
                                  ...getCurrentEmail(),
                                  subject: e.target.value
                                })
                              }}
                              variant="unstyled"
                              size="sm"
                              fontWeight="semibold"
                              placeholder="Email Subject"
                              _placeholder={{ color: 'gray.400' }}
                              color="inherit"
                            />
                          </HStack>
                          <Divider />
                        </Stack>

                        <Textarea
                          value={getCurrentEmail().body}
                          onChange={(e) => {
                            setSelectedEmail({
                              ...getCurrentEmail(),
                              body: e.target.value
                            })
                          }}
                          fontSize="sm"
                          flex={1}
                          variant="unstyled"
                          resize="none"
                          placeholder="Start typing your email..."
                          p={0}
                          sx={{
                            '&::placeholder': {
                              color: 'gray.400'
                            }
                          }}
                        />
                      </Box>
                    )}

                    {selectedTabIndex === 1 && (
                      <Box p={6} flex={1} display="flex" flexDirection="column" overflow="auto">
                        <Textarea
                          value={''}
                          flex={1}
                          variant="unstyled"
                          resize="none"
                          fontSize="sm"
                          placeholder="Start typing your LinkedIn message..."
                          p={0}
                        />
                      </Box>
                    )}

                    {/* Suggested Emails Panel - Now at the bottom of the right panel */}
                    <Box borderTop="1px" borderColor="gray.200" bg="gray.50">
                      <HStack
                        onClick={() => setIsSuggestedEmailsOpen(!isSuggestedEmailsOpen)}
                        cursor="pointer"
                        _hover={{ color: 'purple.600' }}
                        p={4}
                        justify="space-between"
                      >
                        <HStack>
                          <Icon as={IconMailBolt} color="purple.400" boxSize={5} />
                          <Heading size="xs" fontWeight="semibold">
                            {loadingSuggestions && !suggestedEmails[0]?.body ? (
                              <Text color="purple.600" fontWeight="medium">
                                <PulsingText>Writing...</PulsingText>
                              </Text>
                            ) : (
                              [
                                'Suggested Emails',
                                suggestedEmails.length > 0 ? `(${suggestedEmails.length})` : ''
                              ].join(' ')
                            )}
                          </Heading>
                        </HStack>
                        <Icon
                          as={IconChevronDown}
                          transform={isSuggestedEmailsOpen ? 'rotate(0deg)' : 'rotate(180deg)'}
                          transition="transform 0.2s"
                        />
                      </HStack>

                      <Collapse in={isSuggestedEmailsOpen} animateOpacity>
                        <Box p={4}>
                          {loadingSuggestions && !suggestedEmails[0]?.body ? (
                            <Box textAlign="center">
                              <SkeletonText noOfLines={4} spacing={2} />
                            </Box>
                          ) : (
                            <EmailSuggestions
                              skipHeader
                              onReplaceEmail={(email) => {
                                setSelectedEmail(email)
                              }}
                              onSelectEmail={(email, feedbackType) => {
                                setSelectedEmail(email)

                                if (feedbackType === 'negative') {
                                  setShowFeedbackModal(true)
                                } else {
                                  onSubmitFeedback(feedbackType, '')
                                }
                              }}
                              mode="horizontal"
                              itemId={prospectId}
                              suggestedEmails={suggestedEmails}
                            />
                          )}
                        </Box>
                      </Collapse>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {!showFeedbackModal && !showSettingsModal && (
              <ModalFooter borderTop="1px" borderColor="gray.200" p={4} mt="auto">
                <HStack spacing={4}>
                  <Button variant="ghost" onClick={onClose}>
                    Cancel
                  </Button>
                  {getActionButton()}
                </HStack>
              </ModalFooter>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
