import { Center, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { LightBgCard as Card } from '../../../ui/Card'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import comingSoon from './assets/coming-soon.png'

export default function NoDataAvailable() {
  return (
    <>
      <PageLayout size="full">
        <Flex
          py={3}
          px={[4, 4, 6, 8]}
          mx={[-4, -4, -6, -8]}
          bg="white"
          zIndex="1"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={4}
        >
          <Flex flex={['1 1 100%', '1 1 100%', 'none']} alignItems="baseline" gap={4}>
            <PageTitle>Analytics</PageTitle>
          </Flex>
          <Card padding={12} borderWidth="0" rounded="lg">
            <Center minHeight="300px">
              <Stack alignItems="center" spacing={6}>
                <Stack maxWidth="600px" textAlign="center" spacing={1}>
                  <Heading size="md">Waiting for data to be available</Heading>
                  <Text fontSize="md" color="gray.500">
                    Unfortunately we don't have enough data to generate the analytics dashboard yet, check back soon!
                  </Text>
                </Stack>
                <Image src={comingSoon} />
              </Stack>
            </Center>
          </Card>
        </Flex>
      </PageLayout>
    </>
  )
}
