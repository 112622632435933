import * as React from 'react'
import { datadogRum } from '@datadog/browser-rum'

interface Props {
  errorView?: React.ReactNode
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

const defaultErrorView = <h1>Oh no. Something went wrong.</h1>

export default class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps = {
    errorView: defaultErrorView
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // @ts-ignore this isnt typed yet
    datadogRum.addError(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return this.props.errorView ?? defaultErrorView
    }

    return this.props.children
  }
}
