import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import pluralize from 'pluralize'
import React, { useCallback, useState } from 'react'
import { toast } from 'sonner'
import { Account } from '../../../../types/Account'
import { App } from '../../../../types/App'
import { useAssignAccounts } from '../../../data/use-claim-account'
import { UserCircleDashedIcon } from '../../../ui/icons'
import { User } from '../../../ui/UserContext'
import { UserSelector } from '../../../ui/UserSelector'

interface AssignOwnerModalProps extends UseDisclosureProps {
  selectedRecords: string[] | string
  selectedRecord?: Account
  onChangeAssignee?: (assignee?: User) => void
}

export function AssignOwnerModal(props: AssignOwnerModalProps) {
  const { selectedRecords, selectedRecord, onChangeAssignee } = props
  const { isOpen, onClose } = useDisclosure(props)
  const formId = 'assign-koala-owners-form'

  const [assigneeId, setAssigneeId] = useState<string | null>(selectedRecord?.assignee?.id || null)
  const [assignee, setAssignee] = useState<any>(selectedRecord?.assignee || null)

  const recordIds = Array.isArray(selectedRecords) ? selectedRecords : [selectedRecords]
  const { isPending: isLoading, mutateAsync: assignAccounts } = useAssignAccounts()

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      const accountIds = Array.isArray(selectedRecords) ? selectedRecords : [selectedRecords]

      const action = assigneeId ? 'assign' : 'unassign'

      try {
        await assignAccounts({ accountIds, assigneeId })
        onChangeAssignee?.(assigneeId ? assignee : undefined)
        onClose()
      } catch (error: any) {
        toast.error(`Failed to ${action} owner`, { description: error?.message })
        console.error(error)
      }
    },
    [assigneeId, assignAccounts, onClose, assignee, selectedRecords, onChangeAssignee]
  )

  const label = props.selectedRecord
    ? props.selectedRecord.company?.name || props.selectedRecord.company?.domain
    : `${recordIds.length > 1 ? recordIds.length.toLocaleString() : 'this'} ${pluralize('record', recordIds.length)}`

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalBody paddingTop={6}>
          <form id={formId} onSubmit={onSubmit}>
            <Stack spacing={4}>
              <Heading size="sm" fontWeight="semibold">
                Assign an owner for {label}
              </Heading>

              <Text fontSize="sm" color="gray.600">
                This will assign ownership in Koala for these accounts, but it will not make changes in your CRM.
              </Text>

              <FormControl size="sm">
                <FormLabel>Assign to...</FormLabel>

                <UserSelector
                  variant="outline"
                  selectedUserId={assigneeId}
                  showUnassignedOption
                  onChange={(newAssigneeId, assignee) => {
                    setAssigneeId(newAssigneeId || null)
                    setAssignee(assignee)
                  }}
                  popperOptions={{ matchWidth: true }}
                />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button type="button" variant="outline" size="sm" onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button type="submit" form={formId} size="sm" colorScheme="purple" isLoading={isLoading}>
            Assign
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface AssignKoalaOwnerProps extends AssignOwnerModalProps {
  app?: App
}

export function AssignKoalaOwner(props: AssignKoalaOwnerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure(props)
  const isDisabled = props.app?.actions?.koala_claim_account?.enabled === false

  return (
    <>
      <Tooltip label={isDisabled ? 'This action is disabled in your workspace' : undefined}>
        <Button
          size="sm"
          variant="outline"
          leftIcon={<UserCircleDashedIcon size={16} />}
          iconSpacing={1.5}
          onClick={onOpen}
          isDisabled={isDisabled}
        >
          Assign owners
        </Button>
      </Tooltip>

      <AssignOwnerModal isOpen={isOpen} onClose={onClose} selectedRecords={props.selectedRecords} />
    </>
  )
}
