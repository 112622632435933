import React, { FormEvent, useCallback, useRef } from 'react'
import {
  Button,
  Heading,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { Persona } from '../../prospects/personas'
import { IconUserCircle } from '@tabler/icons-react'
import { useSavePersona } from '../../../data/use-personas'

interface CreatePersonaModalProps extends UseDisclosureProps {
  persona: Partial<Persona>
  onSave?: (persona: Persona) => void
}

export function CreatePersonaModal({ persona, onSave, ...props }: CreatePersonaModalProps) {
  const disclosure = useDisclosure(props)
  const nameRef = useRef<HTMLInputElement>(null)
  const { isPending: isLoading, mutateAsync } = useSavePersona()

  const onClose = disclosure.onClose

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      const name = nameRef.current?.value
      if (!name) return

      const res = await mutateAsync({ name, ...persona })
      onClose()
      onSave?.(res.persona)
    },
    [persona, onSave, mutateAsync, onClose]
  )

  return (
    <Modal {...disclosure} size="lg" isCentered>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(3px)" />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <HStack>
            <Icon as={IconUserCircle} size={14} color="purple.500" />
            <Heading fontWeight="semibold" size="sm">
              Save Persona
            </Heading>
          </HStack>
        </ModalHeader>

        <ModalBody>
          <form id="create-new-persona" onSubmit={onSubmit}>
            <Stack spacing={4}>
              <Text fontSize="sm" color="gray.600">
                Save these filters as a Persona so you can easily find new prospects that match later.
              </Text>

              <Input ref={nameRef} placeholder="Name your persona" size="sm" />
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button form="create-new-persona" type="submit" isLoading={isLoading} size="md" colorScheme="purple">
            Save Persona
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
