import { IconButton, Tooltip } from '@chakra-ui/react'
import { IconArrowBackUp } from '@tabler/icons-react'
import React from 'react'
import { toast } from 'sonner'
import { useResetPlayItem } from '../../../data/use-plays'

interface ResetButtonProps {
  itemId: string
  onSuccess?: (itemId: string, status: string) => void
}

export function ResetButton({ itemId, onSuccess }: ResetButtonProps) {
  const { mutate: resetItem, isPending: isLoading } = useResetPlayItem({
    onSuccess: () => {
      onSuccess?.(itemId, 'pending')
    },
    onError: (error: any) => {
      toast.error('There was an issue resetting this lead', { description: error?.body?.message || error?.message })
    }
  })

  return (
    <Tooltip label="Undo">
      <IconButton
        aria-label="Reset"
        icon={<IconArrowBackUp size={16} />}
        bg="white"
        onClick={() => resetItem({ itemId })}
        isLoading={isLoading}
      />
    </Tooltip>
  )
}
