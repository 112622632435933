import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  PopoverTrigger,
  Popover,
  Stack,
  Text,
  Wrap,
  UnorderedList,
  PopoverBody,
  PopoverContent,
  PopoverArrow,
  ListItem,
  SkeletonText,
  Spinner
} from '@chakra-ui/react'
import { IconBoltFilled, IconBuilding, IconBulb, IconUser } from '@tabler/icons-react'
import React from 'react'
import { SessionRecommendation } from '../../../data/use-ai-recommendations'

interface SessionSummaryProps {
  profile_report?: SessionRecommendation
  account_report?: SessionRecommendation
  prospect_name?: string
  account_name?: string
  profileLoading?: boolean
  accountLoading?: boolean
}

const LoadingTopics = () => (
  <Wrap pt={2}>
    <SkeletonText noOfLines={3} />
    {[1, 2, 3].map((i) => (
      <Button
        key={i}
        colorScheme="gray"
        variant="ghost"
        bg="gray.50"
        color="gray.400"
        p="2"
        size="xs"
        rounded="full"
        rightIcon={<IconBoltFilled size={12} />}
      >
        <HStack spacing={1}>
          <Spinner size="xs" />
          <Text>Loading...</Text>
        </HStack>
      </Button>
    ))}
  </Wrap>
)

const TopicButton = ({
  topic,
  colorScheme
}: {
  topic: { topic: string; bullet_points: string[] }
  colorScheme: 'blue' | 'purple'
}) => (
  <Popover key={topic.topic} trigger="hover" isLazy lazyBehavior="unmount">
    <PopoverTrigger>
      <Button
        colorScheme={colorScheme}
        variant="ghost"
        bg={`${colorScheme}.50`}
        color={`${colorScheme}.500`}
        p="2"
        size="xs"
        rounded="full"
        style={{ opacity: 1 }}
        rightIcon={<IconBoltFilled size={12} />}
      >
        {topic.topic}
      </Button>
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverBody fontSize="sm" fontWeight="normal" padding={4}>
        <UnorderedList>
          {topic.bullet_points.map((point) => (
            <ListItem key={point}>{point}</ListItem>
          ))}
        </UnorderedList>
      </PopoverBody>
    </PopoverContent>
  </Popover>
)

export const SessionSummary: React.FC<SessionSummaryProps> = ({
  profile_report,
  account_report,
  prospect_name,
  account_name,
  profileLoading,
  accountLoading
}) => {
  return (
    <Stack spacing="4">
      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <Center boxSize={10} bg="purple.50" borderRadius="md">
          <Icon as={IconBulb} color="purple.500" boxSize={5} />
        </Center>
        <Heading size="md" fontWeight="semibold">
          TL;DR
        </Heading>
      </Box>
      <Stack spacing="6">
        <Stack spacing="1">
          <HStack spacing="1">
            <Icon as={IconUser} color="gray.700" boxSize={4} />
            <Heading size="xs" fontWeight="semibold">
              {prospect_name ?? 'Prospect'}
            </Heading>
          </HStack>
          <Box>
            {!profileLoading ? (
              <>
                <Text color="gray.700" fontSize="sm">
                  {profile_report?.summary}
                </Text>
                <Wrap pt={2}>
                  {profile_report?.topics.map((topic) => (
                    <TopicButton key={topic.topic} topic={topic} colorScheme="blue" />
                  ))}
                </Wrap>
              </>
            ) : (
              <LoadingTopics />
            )}
          </Box>
        </Stack>

        <Stack spacing="1">
          <HStack spacing="1">
            <Icon as={IconBuilding} color="gray.700" boxSize={4} />
            <Heading size="xs" fontWeight="semibold">
              {account_name ?? 'Account'}
            </Heading>
          </HStack>
          <Box>
            {!accountLoading ? (
              <>
                <Text color="gray.700" fontSize="sm">
                  {account_report?.summary}
                </Text>
                <Wrap pt={2}>
                  {account_report?.topics.map((topic) => (
                    <TopicButton key={topic.topic} topic={topic} colorScheme="purple" />
                  ))}
                </Wrap>
              </>
            ) : (
              <LoadingTopics />
            )}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
