import { useMemo } from 'react'
import { post } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'
import { useApp } from './use-app'

export function useApolloSingleSendSettings() {
  const { data: apolloData, isLoading } = useApp<{ api_key: string }>('Apollo')

  const apolloEnabled = useMemo(() => {
    return apolloData?.connected && apolloData?.valid
  }, [apolloData])

  // Apollo doesn't use scopes like Outreach, so we just check if it's enabled
  const canSendApollo = useMemo(() => {
    return apolloEnabled
  }, [apolloEnabled])

  return { apolloEnabled, canSendApollo, isLoading }
}

export type ApolloSingleSend = {
  subject: string
  body: string
  email_account_id?: string
}

export function apolloSingleSend(profile: { email: string }, singleSend: ApolloSingleSend) {
  const path = projectPath(`apollo/actions/single-send`)
  let body = singleSend.body

  // if it's not html
  if (!body.includes('<')) {
    body = `<html><body>${body.replace(/\n/g, '<br />')}</body></html>`
  }

  return post(path, {
    single_send: {
      subject: singleSend.subject,
      body
    },
    profile_email: profile.email
  })
}
