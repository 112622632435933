import { useMutation } from '@tanstack/react-query'
import { post } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'

interface Data {
  errors?: string[]
  message?: string
  success?: boolean
}

interface Params {
  step: string
  category: string
  status: 'skipped' | 'completed'
}

export function useUpdateOnboarding() {
  return useMutation<Data, unknown, Params>({
    mutationFn: (params: Params) => {
      const path = projectPath(`/onboarding-steps`)
      return post<Data>(path, { onboarding_step: params })
    }
  })
}
