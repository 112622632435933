import React from 'react'
import { Stack, Box, Text, TagLabel } from '@chakra-ui/react'
import { BubbleTag } from '../../../ui/BubbleTag'
import { TextEllipsis } from '../../../ui/text-ellipsis'

interface ResponseOption {
  text: string
  color?: string
}

interface CastAnswerProps {
  response: {
    cast_answer: string | boolean | string[] | null | undefined
    response_type?: string
    response_options?: ResponseOption[]
  }
  fontSize?: string
  compact?: boolean
  maxItems?: number
}

// Helper to uniquely count array items
const uniqueAndCount = (items: any[]): [any, number][] => {
  const counts = new Map<any, number>()

  for (const item of items) {
    const key = typeof item === 'object' ? JSON.stringify(item) : item
    counts.set(key, (counts.get(key) || 0) + 1)
  }

  return Array.from(counts.entries()).map(([key, count]) => {
    const value = typeof key === 'string' && key.startsWith('{') ? JSON.parse(key) : key
    return [value, count]
  })
}

export const CastAnswer: React.FC<CastAnswerProps> = ({ response, fontSize, compact = false, maxItems }) => {
  const { cast_answer, response_type } = response

  // Handle null or undefined values
  if (cast_answer === null || cast_answer === undefined) {
    return (
      <Box mt={compact ? 0 : 2}>
        <Text fontSize={fontSize ?? 'sm'} color="gray.500">
          Unknown
        </Text>
      </Box>
    )
  }

  // Helper function to format numbers with commas for thousands and dots for decimals
  const formatNumber = (value: number | string | boolean | string[]): string => {
    // Handle non-numeric types
    if (typeof value === 'boolean' || Array.isArray(value)) {
      return String(value)
    }

    // Check if it's a valid number
    const num = typeof value === 'string' ? parseFloat(value) : value
    if (isNaN(num)) return String(value)

    // Format with commas for thousands and dots for decimals (US style)
    return num.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: Number.isInteger(num) ? 0 : 2
    })
  }

  // Helper function to format dates
  const formatDate = (value: string | any): string => {
    if (typeof value !== 'string') return String(value)

    try {
      const date = new Date(value)
      if (isNaN(date.getTime())) return value
      return date.toLocaleDateString()
    } catch (e) {
      return value
    }
  }

  // If response_type is provided, use it for rendering
  if (response_type) {
    return (
      <Box mt={compact ? 0 : 2}>
        {response_type === 'number' && (
          <Text fontSize={fontSize ?? 'sm'}>{formatNumber(cast_answer as string | number)}</Text>
        )}

        {response_type === 'currency' && (
          <Text fontSize={fontSize ?? 'sm'}>${formatNumber(cast_answer as string | number)}</Text>
        )}

        {response_type === 'percent' && (
          <Text fontSize={fontSize ?? 'sm'}>
            {formatNumber(parseFloat(cast_answer as string) * 100 || cast_answer)}%
          </Text>
        )}

        {response_type === 'date' && <Text fontSize={fontSize ?? 'sm'}>{formatDate(cast_answer)}</Text>}

        {response_type === 'string' &&
          (Array.isArray(cast_answer) ? (
            <Stack direction="row" spacing={2} flexWrap="wrap">
              {cast_answer.map((value: string, index: number) => (
                <Box
                  key={index}
                  fontSize={fontSize ?? 'sm'}
                  p={compact ? 1 : 1.5}
                  borderRadius={compact ? 'sm' : 'md'}
                  display="inline-block"
                  borderColor="gray.200"
                >
                  <Text>{value}</Text>
                </Box>
              ))}
            </Stack>
          ) : (
            <Text fontSize={fontSize ?? 'sm'}>
              {typeof cast_answer === 'string' ? cast_answer : String(cast_answer)}
            </Text>
          ))}

        {response_type === 'boolean' && <Text fontSize={fontSize ?? 'sm'}>{cast_answer ? 'Yes' : 'No'}</Text>}

        {response_type === 'list' && cast_answer && (
          <Stack direction="row" spacing={2} flexWrap="wrap">
            {Array.isArray(cast_answer)
              ? (() => {
                  const items = cast_answer
                  const visibleItems = maxItems ? items.slice(0, maxItems) : items
                  const remainingCount = maxItems ? items.length - maxItems : 0

                  return (
                    <>
                      {visibleItems.map((value: string, index: number) => (
                        <BubbleTag key={index} title={value} variant="subtleBorder" value={value}>
                          <TagLabel isTruncated minWidth="10px" maxWidth="100%">
                            {value}
                          </TagLabel>
                        </BubbleTag>
                      ))}
                      {remainingCount > 0 && (
                        <BubbleTag key="more" title={`+${remainingCount} more`} variant="subtleBorder" value="more">
                          <TagLabel isTruncated minWidth="10px" maxWidth="100%">
                            +{remainingCount}
                          </TagLabel>
                        </BubbleTag>
                      )}
                    </>
                  )
                })()
              : typeof cast_answer === 'string' &&
                (() => {
                  const items = cast_answer.split(',').map((v) => v.trim())
                  const visibleItems = maxItems ? items.slice(0, maxItems) : items
                  const remainingCount = maxItems ? items.length - maxItems : 0

                  return (
                    <>
                      {visibleItems.map((value: string, index: number) => (
                        <BubbleTag key={index} title={value} variant="subtleBorder" value={value}>
                          <TagLabel isTruncated minWidth="10px" maxWidth="100%">
                            {value}
                          </TagLabel>
                        </BubbleTag>
                      ))}
                      {remainingCount > 0 && (
                        <BubbleTag key="more" title={`+${remainingCount} more`} variant="subtleBorder" value="more">
                          <TagLabel isTruncated minWidth="10px" maxWidth="100%">
                            +{remainingCount}
                          </TagLabel>
                        </BubbleTag>
                      )}
                    </>
                  )
                })()}
          </Stack>
        )}

        {response_type === 'picklist' && cast_answer && (
          <Stack direction="row" spacing={2} flexWrap="wrap">
            {Array.isArray(cast_answer)
              ? (() => {
                  const items = cast_answer
                  const visibleItems = maxItems ? items.slice(0, maxItems) : items
                  const remainingCount = maxItems ? items.length - maxItems : 0

                  return (
                    <>
                      {visibleItems.map((value: string, index: number) => {
                        // Find matching option to get the color
                        const option = response.response_options?.find((opt: ResponseOption) => opt.text === value)
                        const colorName = option?.color?.split('.')[0] || 'gray'
                        return (
                          <BubbleTag key={index} title={value} variant="subtleBorder" value={value} color={colorName}>
                            <TagLabel isTruncated minWidth="10px" maxWidth="100%">
                              {value}
                            </TagLabel>
                          </BubbleTag>
                        )
                      })}
                      {remainingCount > 0 && (
                        <BubbleTag key="more" title={`+${remainingCount} more`} variant="subtleBorder" value="more">
                          <TagLabel isTruncated minWidth="10px" maxWidth="100%">
                            +{remainingCount}
                          </TagLabel>
                        </BubbleTag>
                      )}
                    </>
                  )
                })()
              : typeof cast_answer === 'string' && (
                  <BubbleTag
                    title={cast_answer}
                    variant="subtleBorder"
                    value={cast_answer}
                    color={
                      response.response_options
                        ?.find((opt: ResponseOption) => opt.text === cast_answer)
                        ?.color?.split('.')[0] || 'gray'
                    }
                  >
                    <TagLabel isTruncated minWidth="10px" maxWidth="100%">
                      {cast_answer}
                    </TagLabel>
                  </BubbleTag>
                )}
          </Stack>
        )}
      </Box>
    )
  }

  // If no response_type, try to infer the type
  return (
    <Box mt={compact ? 0 : 2}>
      {typeof cast_answer === 'boolean' ? (
        <Text fontSize={fontSize ?? 'sm'}>{cast_answer ? 'Yes' : 'No'}</Text>
      ) : typeof cast_answer === 'string' ? (
        <TextEllipsis maxW="100%" fontSize={fontSize ?? 'sm'} tooltip>
          {cast_answer}
        </TextEllipsis>
      ) : Array.isArray(cast_answer) ? (
        <Stack direction="row" spacing={2} flexWrap="wrap">
          {(() => {
            const items = uniqueAndCount(cast_answer.filter((v) => typeof v === 'string' && v !== ''))
            const visibleItems = maxItems ? items.slice(0, maxItems) : items
            const remainingCount = maxItems ? items.length - maxItems : 0

            return (
              <>
                {visibleItems.map(([item, count], index) => (
                  <BubbleTag
                    key={JSON.stringify({ item, index })}
                    title={item?.toString()}
                    variant="subtleBorder"
                    value={item}
                    count={count}
                  >
                    <TagLabel isTruncated minWidth="10px" maxWidth="100%">
                      {item?.toString()}
                    </TagLabel>
                  </BubbleTag>
                ))}
                {remainingCount > 0 && (
                  <BubbleTag key="more" title={`+${remainingCount} more types`} variant="subtleBorder" value="more">
                    <TagLabel isTruncated minWidth="10px" maxWidth="100%">
                      +{remainingCount}
                    </TagLabel>
                  </BubbleTag>
                )}
              </>
            )
          })()}
        </Stack>
      ) : (
        <Text fontSize={fontSize ?? 'sm'} color="gray.500">
          Unknown
        </Text>
      )}
    </Box>
  )
}
