import { useQuery } from '@tanstack/react-query'
import { concurrentGET } from '../../lib/api'
import { useCurrentProject, projectPath } from '../ui/ProjectsContext'
import type { Sequence } from '@app/types/Outreach'

export function useOutreachSequences(appId: string) {
  const project = useCurrentProject()

  return useQuery({
    queryKey: ['outreachSequences', { projectId: project?.id, appId }],
    queryFn: () => concurrentGET<{ outreach_sequences: Sequence[] }>(projectPath(`/apps/${appId}/outreach-sequences`)),
    enabled: Boolean(project?.id)
  })
}
