import { IconButton, Tooltip } from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { toast } from 'sonner'
import { useDismissPlayItem } from '../../../data/use-plays'

interface QuickDismissButtonProps {
  itemId: string
  onSuccess?: (itemId: string, status: string) => void
}

export function QuickDismissButton({ itemId, onSuccess }: QuickDismissButtonProps) {
  const { mutate: dismissItem, isPending: isLoading } = useDismissPlayItem({
    onSuccess: () => {
      onSuccess?.(itemId, 'dismissed')
    },
    onError: (error: any) => {
      toast.error('There was an issue dismissing this lead', { description: error?.body?.message || error?.message })
    }
  })

  const handleDismiss = useCallback(() => {
    dismissItem({ itemId, reason: 'other', feedback: '' })
  }, [dismissItem, itemId])

  return (
    <Tooltip label="Dismiss">
      <IconButton
        aria-label="Dismiss"
        icon={<IconX size={16} />}
        bg="white"
        onClick={handleDismiss}
        isLoading={isLoading}
        ml="-px"
      />
    </Tooltip>
  )
}
