import AIRecommendationOutput, {
  useRecommendation,
  useRecommendationStatus
} from '@app/components/ui/AIRecommendationOutput'
import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  Tooltip
} from '@chakra-ui/react'
import {
  IconAlarmSnooze,
  IconArrowRight,
  IconBan,
  IconBriefcase,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconLink,
  IconShare3,
  IconSparkles,
  IconUsers,
  IconWorldPin,
  IconWorldShare
} from '@tabler/icons-react'
import Autolinker from 'autolinker'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { useHotkeys } from 'react-hotkeys-hook'
import { toast } from 'sonner'
import { patch } from '../../../../lib/api'
import dayjs from '../../../../lib/dayjs'
import { flatGet } from '../../../../lib/flatGet'
import { formatFriendlyCurrency, formatPercent } from '../../../../lib/number-format'
import { PersistedFieldDefinition, useFieldDefinitions } from '../../../data/use-field-definitions'
import Avatar from '../../../ui/Avatar'
import { BubbleTag } from '../../../ui/BubbleTag'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { CompanyBubble } from '../../../ui/CompanyBubble'
import { HoverCard } from '../../../ui/HoverCard'
import { Iconify } from '../../../ui/Iconify'
import { LinkedinBoxIcon, SalesforceIcon, SfOpportunityIcon } from '../../../ui/icons'
import { HubSpotIcon } from '../../../ui/icons/HubspotIcons'
import { LastEngagementHoverCard } from '../../../ui/LastEngagement'
import { LoadingPhrases } from '../../../ui/LoadingPhrases'
import { MiddotDivider } from '../../../ui/Middot'
import { projectPath } from '../../../ui/ProjectsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useCurrentUser } from '../../../ui/UserContext'
import { usePersistedGroup } from '../../accounts/components/DetailsV2/SummaryCard'
import { Toggle } from '../../accounts/components/Toggle'
import { accountPath } from '../../accounts/lib/account-path'
import { profilePath } from '../../profiles/lib/path'
import { ProspectBadge, WarmLeadBadge } from './Badges'

const emptyArray = []

interface SelectedItemPanelProps {
  item: any
  hasNext?: boolean
  hasPrevious?: boolean
  onNext?: () => void
  onPrev?: () => void
  onDismiss: (item: any) => void
  onSnooze: (item: any) => void
  onComplete: (item: any) => void
  onShare?: (item: any) => void
  isPreview?: boolean
}

function arrayWrap<T>(item: T): Array<T> {
  if (Array.isArray(item)) {
    return item
  }

  if (item === undefined || item === null || item === '') {
    return []
  }

  return [item]
}

export function SelectedItemPanel({
  item,
  hasNext,
  hasPrevious,
  onNext,
  onPrev,
  onDismiss,
  onSnooze,
  onComplete,
  onShare,
  isPreview
}: SelectedItemPanelProps) {
  const currentUser = useCurrentUser()
  const company = item.target.company
  const opportunities = item.account?.salesforce_opportunity_caches || emptyArray
  const sfAccount = item.account?.salesforce_account_cache
  const hsCompany = item.account?.hubspot_company_cache
  const hsDeals = item.account?.hubspot_deal_caches || emptyArray

  const sfOwner = sfAccount?.account_owner
  const hsOwner = hsCompany?.account_owner
  const isOwner = [sfOwner, hsOwner].some((owner) => {
    return (
      owner?.toLowerCase() === currentUser?.email?.toLowerCase() ||
      owner?.toLowerCase() === currentUser?.name?.toLowerCase()
    )
  })
  const _hasOtherOwner = (sfOwner || hsOwner) && !isOwner

  const directActivity = item.account?.last_activity

  const lastActivity = [directActivity?.created_at, sfAccount?.last_activity_date, hsCompany?.last_activity_date]
    .filter(Boolean)
    .sort()
    .reverse()[0]

  const targetEmail = item.target.email

  const domain = company?.domain
  const geo =
    company.location || uniq(compact([company.geo?.city, company.geo?.stateCode, company.geo?.country])).join(', ')
  const employees = company.metrics?.employeesRange || company.metrics?.employees

  const hasOpportunities = opportunities.length > 0
  const hasDeals = hsDeals.length > 0

  const [openOpportunities, closedOpportunities] = useMemo(() => {
    return opportunities.reduce(
      ([open, closed], opp) => {
        if (opp.is_closed) {
          return [open, [...closed, opp]]
        }
        return [[...open, opp], closed]
      },
      [[], []] as [typeof opportunities, typeof opportunities]
    )
  }, [opportunities])

  const [openDeals, closedDeals] = useMemo(() => {
    return hsDeals.reduce(
      ([open, closed], deal) => {
        if (deal.is_closed) {
          return [open, [...closed, deal]]
        }
        return [open, closed]
      },
      [[], []] as [typeof hsDeals, typeof hsDeals]
    )
  }, [hsDeals])

  const mostRecentClosedOpportunity = useMemo(() => {
    return closedOpportunities.reduce((latest, opp) => {
      null
      const oppDate = new Date(opp.payload.LastModifiedDate)
      const latestDate = new Date(latest.payload.LastModifiedDate)
      return oppDate > latestDate ? opp : latest
    }, closedOpportunities[0])
  }, [closedOpportunities])

  const opportunitiesToShow = useMemo(() => {
    if (openOpportunities.length > 0) {
      return [...openOpportunities, mostRecentClosedOpportunity].filter(Boolean)
    }
    return mostRecentClosedOpportunity ? [mostRecentClosedOpportunity] : []
  }, [openOpportunities, mostRecentClosedOpportunity])

  const mostRecentClosedDeal = useMemo(() => {
    return closedDeals.reduce((latest, deal) => {
      const dealDate = new Date(deal.last_modified_at)
      const latestDate = new Date(latest.last_modified_at)
      return dealDate > latestDate ? deal : latest
    }, closedDeals[0])
  }, [closedDeals])

  const dealsToShow = useMemo(() => {
    if (openDeals.length > 0) {
      return [...openDeals, mostRecentClosedDeal].filter(Boolean)
    }
    return mostRecentClosedDeal ? [mostRecentClosedDeal] : []
  }, [openDeals, mostRecentClosedDeal])

  const { data: response, isLoading, refetch } = useRecommendation(domain)
  const job_id = (response as any)?.job_id || item.recommendation?.job_id
  const recommendation = useRecommendationStatus(job_id, domain)
  const output = item.recommendation?.output || recommendation?.output

  if (output?.json_metadata && typeof output.json_metadata === 'string') {
    try {
      output.json_metadata = JSON.parse(output.json_metadata)
    } catch (error) {
      output.json_metadata = {}
      console.error('Error parsing JSON metadata', error)
    }
  }

  const loading = isLoading || (!output && job_id)

  const handleComplete = () => {
    if (isPreview) return
    patch(projectPath(`/inbox-items/${item.id}/complete`), {})
      .then(() => {
        onComplete(item)
      })
      .catch((error) => {
        toast.error(`There was an issue completing this lead`, { description: error?.message })
      })
  }

  useHotkeys('c', handleComplete, { filterPreventDefault: true, enabled: !isPreview }, [handleComplete])

  return (
    <Flex
      flexDirection="column"
      flex="1"
      maxW="100%"
      bg="white"
      shadow="lg"
      borderLeft="1px solid"
      borderColor="gray.200"
    >
      <Flex
        borderBottom="1px solid"
        borderColor="gray.200"
        flex="none"
        justifyContent="space-between"
        gap={4}
        px={4}
        py={4}
      >
        <Flex flex="1" alignItems="flex-start" gap={2.5}>
          <Box display="flex" flex="none" position="relative">
            <Avatar
              src={
                item.target.image || item.target.koala_prospect?.id
                  ? projectPath(`/prospects/${item.target.koala_prospect.id}/avatar`)
                  : undefined
              }
              name={item.target.name || item.target.full_name || item.target.display_name}
              size="40px"
            />
            <Box position="absolute" transform="translate(18%, 18%)" right={0} bottom={0}>
              <CompanyAvatar
                domain={item.target.company?.domain}
                size="18px"
                rounded="full"
                boxShadow="0 0 0 2px white"
                background="white"
                objectFit="contain"
              />
            </Box>
          </Box>
          <Stack spacing={0}>
            <Flex alignItems="center" gap={1.5}>
              <TextEllipsis
                as={!item.target.email ? Text : Link}
                href={profilePath(item.target)}
                isExternal
                fontSize="sm"
                fontWeight="semibold"
                maxWidth="140px"
                tooltip
              >
                {item.target.name || item.target.full_name || item.target.display_name || item.target.email}
              </TextEllipsis>
              {item.target.linkedin_url && (
                <Tooltip label={`https://${item.target.linkedin_url.replace(/https?:\/\//, '')}`}>
                  <Link
                    display="flex"
                    flex="none"
                    alignItems="center"
                    color="linkedin.700"
                    isExternal
                    href={`https://${item.target.linkedin_url.replace(/https?:\/\//, '')}`}
                  >
                    <LinkedinBoxIcon boxSize="18px" />
                  </Link>
                </Tooltip>
              )}
              {/* TODO only show this if this was the signal *actor* */}
              {item.target_type === 'Profile' && <WarmLeadBadge />}
              {item.target_type === 'ProspectedProfile' && <ProspectBadge personas={item.context.personas} />}
            </Flex>
            <Text fontSize="13px" color="gray.500">
              {[item.target.title, item.target.company?.name].filter(Boolean).join(' @ ')}
            </Text>

            <HStack alignItems="center" spacing={1.5} flexWrap="wrap" divider={<MiddotDivider />}>
              {item.snoozed_at && (
                <Text fontSize="13px" fontWeight="semibold" color="orange.700">
                  Snoozed <TimeAgo time={item.snoozed_at} canToggle={false} />
                </Text>
              )}

              {lastActivity && lastActivity == directActivity?.created_at ? (
                <LastEngagementHoverCard engagement={directActivity}>
                  <Flex alignItems="center" gap={1}>
                    <Icon as={IconShare3} boxSize={4} color="gray.400" fill="currentColor" />
                    <Text fontSize="13px" color="gray.500">
                      {directActivity?.metadata?.direction === 'inbound' ? 'Replied' : 'Sent message'}{' '}
                      <TimeAgo time={lastActivity} canToggle={false} />
                    </Text>
                  </Flex>
                </LastEngagementHoverCard>
              ) : lastActivity ? (
                <Text fontSize="13px" color="gray.500">
                  Last sales activity <TimeAgo time={lastActivity} canToggle={false} />
                </Text>
              ) : null}
            </HStack>
          </Stack>
        </Flex>

        <Flex flex="none" gap={2}>
          {item.target_type == 'Profile' && (
            <Button
              as={Link}
              href={profilePath(item.target)}
              isExternal
              variant="outline"
              size="sm"
              rightIcon={<IconArrowRight size={15} />}
              iconSpacing={1.5}
            >
              View profile
            </Button>
          )}

          {onShare && (
            <Tooltip label="Share a link to this lead with your team">
              <IconButton
                aria-label="Share"
                icon={<IconLink size={16} />}
                variant="outline"
                size="sm"
                colorScheme="gray"
                onClick={() => {
                  onShare?.(item)
                }}
              />
            </Tooltip>
          )}

          <Flex gap={0}>
            <IconButton
              aria-label="Previous"
              icon={<IconChevronLeft size={15} />}
              variant="ghost"
              size="sm"
              onClick={onPrev}
              isDisabled={!hasPrevious}
            />
            <IconButton
              aria-label="Next"
              icon={<IconChevronRight size={15} />}
              variant="ghost"
              size="sm"
              onClick={onNext}
              isDisabled={!hasNext}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex flex="1" flexDirection="column" gap={4} px={4} py={5} overflowX="hidden" overflowY="auto">
        <Box bg="gray.50" border="1px solid" borderColor="gray.200" p={3} pl={3.5} rounded="lg">
          <Stack spacing={3}>
            <Flex width="100%" gap={2} alignItems="center">
              <CompanyBubble
                domain={company?.domain}
                name={company?.name}
                href={accountPath({ company })}
                target="_blank"
              />

              <HStack spacing={2} ml="auto">
                {!hasOpportunities && !hasDeals && (
                  <Badge
                    variant="regular"
                    colorScheme="green"
                    border="1px solid"
                    borderColor="green.200"
                    rounded="full"
                    px={1.5}
                    py={0.5}
                  >
                    New opportunity
                  </Badge>
                )}

                <Flex alignItems="center" gap={0}>
                  {hsCompany?.permalink && (
                    <Tooltip label={hsCompany.permalink}>
                      <IconButton
                        as={Link}
                        href={hsCompany.permalink}
                        isExternal
                        aria-label="View HubSpot Account"
                        icon={<HubSpotIcon boxSize={4} color="hubspot" />}
                        size="xs"
                        variant="ghost"
                      />
                    </Tooltip>
                  )}
                  {sfAccount?.permalink && (
                    <Tooltip label={sfAccount.permalink}>
                      <IconButton
                        as={Link}
                        href={sfAccount.permalink}
                        isExternal
                        aria-label="View Salesforce Account"
                        icon={<SalesforceIcon boxSize={4} color="salesforce.500" />}
                        size="xs"
                        variant="ghost"
                      />
                    </Tooltip>
                  )}
                  {company?.linkedin_url && (
                    <Tooltip label={`https://${company.linkedin_url.replace(/https?:\/\//, '')}`}>
                      <IconButton
                        as={Link}
                        href={`https://${company.linkedin_url.replace(/https?:\/\//, '')}`}
                        isExternal
                        aria-label="LinkedIn"
                        icon={<LinkedinBoxIcon boxSize={4} color="linkedin.700" />}
                        size="xs"
                        variant="ghost"
                      />
                    </Tooltip>
                  )}
                  {company?.domain && (
                    <Tooltip label={`https://${company.domain}`}>
                      <IconButton
                        as={Link}
                        href={`https://${company.domain}`}
                        isExternal
                        aria-label="Website"
                        icon={<IconWorldShare size={16} />}
                        size="xs"
                        variant="ghost"
                      />
                    </Tooltip>
                  )}
                </Flex>
              </HStack>
            </Flex>

            {company?.description && (
              <Text fontSize="13px" color="gray.600" noOfLines={3}>
                {company.description}
              </Text>
            )}

            <Flex columnGap={5} rowGap={2} color="gray.600" flexWrap="wrap">
              {geo && (
                <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                  <IconWorldPin size={16} />
                  <Text>{geo}</Text>
                </HStack>
              )}
              {company?.category?.industry && (
                <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                  <IconBriefcase size={16} />
                  <Text>{company.category.industry}</Text>
                </HStack>
              )}
              {employees && (
                <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                  <IconUsers size={16} />
                  <Text>{employees} employees</Text>
                </HStack>
              )}

              {sfAccount?.account_owner && (
                <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                  <SalesforceIcon color="salesforce.500" boxSize={4} />
                  <Text>Owner: {sfAccount.account_owner}</Text>
                </HStack>
              )}

              {hsCompany?.account_owner && (
                <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                  <HubSpotIcon color="hubspot" boxSize={4} />
                  <Text>Owner: {hsCompany.account_owner}</Text>
                </HStack>
              )}

              {lastActivity && lastActivity == directActivity?.created_at ? (
                <HoverCard
                  trigger="hover"
                  hoverContent={
                    <Stack padding={2} maxW="300px">
                      <Stack spacing={0}>
                        <Text fontSize="xs" fontWeight="medium">
                          From:
                        </Text>
                        <Text fontSize="13px">{directActivity.metadata?.from}</Text>
                      </Stack>
                      <Stack spacing={0}>
                        <Text fontSize="xs" fontWeight="medium">
                          To:
                        </Text>
                        {arrayWrap(directActivity.metadata?.to)?.map((addr, idx) => (
                          <Text key={`to:${addr}:${idx}`} fontSize="13px">
                            {addr}
                          </Text>
                        ))}
                        {arrayWrap(directActivity.metadata?.cc)?.map((addr, idx) => (
                          <Text key={`cc:${addr}:${idx}`} fontSize="13px">
                            {addr}
                          </Text>
                        ))}
                      </Stack>
                    </Stack>
                  }
                >
                  <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                    <Icon as={IconShare3} boxSize={4} />
                    <Text>
                      {directActivity?.metadata?.direction === 'inbound' ? 'Replied' : 'Sent message'}{' '}
                      <TimeAgo time={lastActivity} canToggle={false} />
                    </Text>
                  </HStack>
                </HoverCard>
              ) : lastActivity ? (
                <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                  <Icon as={IconArrowRight} boxSize={4} />
                  <Text>
                    Last touch <TimeAgo time={lastActivity} canToggle={false} />
                  </Text>
                </HStack>
              ) : null}
            </Flex>

            {hasOpportunities && (
              <>
                <Divider />

                {opportunitiesToShow.map((opportunity) => (
                  <Flex key={opportunity.payload.Id} alignItems="center" gap={2.5} py={1}>
                    <Center height="20px">
                      <Iconify icon={SfOpportunityIcon} size={18} />
                    </Center>

                    <HStack flex="1" fontSize="13px" spacing={1} divider={<MiddotDivider />}>
                      <Text fontWeight="medium" color="gray.700" lineHeight="20px">
                        {opportunity.payload.Name}: ({opportunity.payload.StageName})
                      </Text>

                      {opportunity.payload.Amount && (
                        <Text color="gray.500">{formatFriendlyCurrency(opportunity.payload.Amount)}</Text>
                      )}
                      {opportunity.payload.Probability && !opportunity.payload.IsClosed && (
                        <Text color="gray.500">{`${opportunity.payload.Probability}% probability`}</Text>
                      )}
                    </HStack>

                    {/* show the time ago */}
                    <Text fontSize="13px" color="gray.500">
                      <TimeAgo time={opportunity.payload.LastModifiedDate} canToggle={false} />
                    </Text>
                  </Flex>
                ))}
              </>
            )}

            {hasDeals && (
              <>
                <Divider />

                {dealsToShow.map((deal) => (
                  <Flex key={deal.payload.Id} alignItems="center" gap={2.5} py={1}>
                    <Center height="20px">
                      <Iconify icon={HubSpotIcon} color="orange.300" size={18} />
                    </Center>

                    <HStack flex="1" fontSize="13px" spacing={1} divider={<MiddotDivider />}>
                      <Text fontWeight="medium" color="gray.700" lineHeight="20px">
                        {deal.name}: ({deal.stage_name})
                      </Text>

                      {deal.amount && <Text color="gray.500">{formatFriendlyCurrency(deal.amount)}</Text>}
                    </HStack>

                    {/* show the time ago */}
                    <Text fontSize="13px" color="gray.500">
                      <TimeAgo time={deal.last_modified_at} canToggle={false} />
                    </Text>
                  </Flex>
                ))}
              </>
            )}
          </Stack>
        </Box>

        {item.account && <HighlightedFields account={item.account} />}

        {!output && !loading && (
          <Button
            flex="none"
            variant="outline"
            leftIcon={<IconSparkles size={16} />}
            isLoading={loading}
            onClick={() => refetch()}
          >
            Fetch Recommendation
          </Button>
        )}

        {loading && <LoadingPhrases isLoading={loading} />}

        <AIRecommendationOutput
          output={output}
          targetEmail={targetEmail!}
          linkedin_url={item.target.linkedin_url}
          accountId={item.target.account_id}
          onEmailSent={handleComplete}
        />
      </Flex>
      <Flex
        flex="none"
        alignItems="center"
        gap={4}
        px={4}
        pt={3}
        pb={4}
        justifyContent="space-between"
        borderTop="1px solid"
        borderColor="gray.200"
      >
        <Flex alignItems="center" gap={0}>
          <DismissMenu item={item} onDismiss={onDismiss} isDisabled={isPreview} />
        </Flex>

        <Flex alignItems="center" gap={2}>
          <SnoozeMenu item={item} onSnooze={onSnooze} isDisabled={isPreview} />
          <Button variant="solid" size="md" colorScheme="purple" onClick={handleComplete} isDisabled={isPreview}>
            Mark as completed
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

const dismissReasons: [string, string][] = [
  ['Wrong territory', 'not_my_territory'],
  ['Not ICP', 'not_in_icp'],
  ['Bad lead', 'bad_lead'],
  ['Bad signal', 'bad_signal']
]

interface DismissMenuProps {
  item: any
  onDismiss: (item: any) => void
  isDisabled?: boolean
}

function DismissMenu(props: DismissMenuProps) {
  const [feedback, setFeedback] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const menuButtonRef = useRef<HTMLButtonElement>(null)

  const handleDismiss = (reason: string) => {
    patch(projectPath(`/inbox-items/${props.item?.id}/dismiss`), {
      reason: reason,
      feedback: reason === 'other' ? feedback : ''
    })
      .then(() => {
        setFeedback('')
        setIsModalOpen(false)
        props.onDismiss(props.item)
      })
      .catch((error) => {
        toast.error(`There was an issue dismissing this lead`, { description: error?.message })
      })
  }

  useHotkeys(
    'd',
    () => {
      menuButtonRef.current?.click()
    },
    { filterPreventDefault: true, enabled: !props.isDisabled },
    [menuButtonRef]
  )

  return (
    <>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              aria-label="Bad result"
              size="md"
              color="gray.500"
              _hover={{ color: 'gray.700', bg: 'gray.100' }}
              leftIcon={<IconBan size={16} />}
              rightIcon={isOpen ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
              variant="ghost"
              ref={menuButtonRef}
              isDisabled={props.isDisabled}
            >
              Dismiss
            </MenuButton>
            <MenuList>
              {dismissReasons.map(([label, reason]) => (
                <MenuItem key={reason} onClick={() => handleDismiss(reason)}>
                  {label}
                </MenuItem>
              ))}
              <MenuDivider />
              <MenuItem onClick={() => setIsModalOpen(true)}>Other</MenuItem>
            </MenuList>
          </>
        )}
      </Menu>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove lead from your inbox</ModalHeader>
          <ModalBody>
            <Stack spacing={4}>
              <Text fontSize="sm" color="gray.700">
                Totally optional, we'd love to know why you're dismissing this lead.
              </Text>
              <Textarea
                placeholder="Share your feedback..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                size="sm"
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="purple" onClick={() => handleDismiss('other')} ml={3}>
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

interface SnoozeMenuProps {
  item: any
  onSnooze: (item: any) => void
  isDisabled?: boolean
}

const snoozePeriods = [
  {
    label: 'in 1 hour',
    value: 'in 1 hour'
  },
  {
    label: 'Tomorrow',
    value: 'tomorrow, 9am',
    // show 9am for the 3-letter day
    preview: () => `${dayjs().add(1, 'day').format('ddd')}, 9:00am`
  },
  {
    label: 'Next week',
    value: 'next week, Mon 9am',
    preview: () => `Mon, 9:00am`
  },
  {
    label: 'Next month',
    value: 'next month, 9am',
    preview: () => `${dayjs().add(1, 'month').format('ddd, MMM DD')}`
  }
]

function SnoozeMenu({ item, onSnooze, isDisabled }: SnoozeMenuProps) {
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const [isPickerOpen, setIsPickerOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)

  const handleSnooze = useCallback(
    (until: string) => {
      patch(projectPath(`/inbox-items/${item?.id}/snooze`), {
        until: until
      })
        .then(() => {
          onSnooze(item)
        })
        .catch((error) => {
          toast.error(`There was an issue snoozing this lead`, { description: error?.message })
        })
    },
    [item, onSnooze]
  )

  const handleDateSelect = useCallback(() => {
    if (!selectedDate) return

    // Set to 9am on the selected date
    const snoozeDate = dayjs(selectedDate).hour(9).minute(0).second(0)
    handleSnooze(snoozeDate.format())
    setIsPickerOpen(false)
  }, [selectedDate, handleSnooze])

  useHotkeys(
    's',
    () => {
      menuButtonRef.current?.click()
    },
    { filterPreventDefault: true, enabled: !isDisabled },
    [menuButtonRef]
  )

  return (
    <>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              aria-label="Snooze"
              size="md"
              leftIcon={<IconAlarmSnooze size={16} />}
              rightIcon={isOpen ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
              iconSpacing={1.5}
              variant="outline"
              ref={menuButtonRef}
              isDisabled={isDisabled}
            >
              Snooze
            </MenuButton>
            <MenuList>
              {snoozePeriods.map((option) => (
                <MenuItem key={option.label} onClick={() => handleSnooze(option.value)}>
                  {option.label}
                  <Text color="gray.400" ml="auto">
                    {option.preview?.()}
                  </Text>
                </MenuItem>
              ))}
              <MenuDivider />
              <MenuItem onClick={() => setIsPickerOpen(true)}>Pick a date</MenuItem>
            </MenuList>
          </>
        )}
      </Menu>

      <Modal size="sm" isOpen={isPickerOpen} onClose={() => setIsPickerOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Snooze until...</ModalHeader>
          <ModalBody pb={6}>
            <DayPicker
              mode="single"
              defaultMonth={dayjs().add(1, 'day').toDate()}
              fromDate={dayjs().add(1, 'day').toDate()}
              toDate={dayjs().add(90, 'days').toDate()}
              selected={selectedDate}
              onSelect={setSelectedDate}
              components={{
                IconLeft: () => <IconChevronLeft size={16} />,
                IconRight: () => <IconChevronRight size={16} />
              }}
              numberOfMonths={1}
            />
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="outline" onClick={() => setIsPickerOpen(false)} mr={3}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="purple" onClick={handleDateSelect}>
              Snooze
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function getFieldValue(record: any, selectedGroup: string | null, field: PersistedFieldDefinition) {
  // TODO support crm lookups too (we don't have the data on the account object currently)
  // We need to reshape the lookup key for traits since OS differs a bit from how we shape the actual model on the account
  const lookup_key = field.data_source?.replace(
    /(?:account|profile)_traits_values\.(\w+)(\.value)?$/,
    (_match, p1, _p2) => {
      const full = ['traits']

      const [root, ...rest] = p1.split('__')

      if (root) {
        if (root.endsWith('.value')) {
          full.push(root)
        } else {
          full.push(`${root}.value`)
        }
      }

      if (rest?.length) {
        full.push(...rest)
      }

      return full.join('.')
    }
  )

  let data = record

  if (lookup_key?.includes('traits')) {
    const traits = record.grouped_traits || record.traits
    data = (Array.isArray(traits) ? traits : []).filter((group) => {
      return group.group_id == selectedGroup || group.name === selectedGroup || (!selectedGroup && !group.group_id)
    })
  }

  // nothing to look up
  if (!lookup_key || !data) return

  const values = flatGet(data, lookup_key)

  // just grab the first value
  // todo handle multiple values...
  return Array.isArray(values) ? values[0] : values
}

function useHighlightedFields(record: any, recordType: 'account' | 'profile') {
  const { data: fieldDefns } = useFieldDefinitions(recordType)

  const traitGroups = record.grouped_traits || record.traits || emptyArray
  const [selectedGroupId, setSelectedGroupId] = usePersistedGroup(record.id, traitGroups[0]?.group_id || null)

  const selectedGroup = useMemo(
    () =>
      traitGroups.find((g) => {
        if (g.group_id === selectedGroupId) return true
        if (g.name === selectedGroupId) return true
        if (!g.group_id && !selectedGroupId) return true
        return false
      }),
    [traitGroups, selectedGroupId]
  )

  const keyFields = useMemo(() => {
    const fields = (fieldDefns?.fields || []).filter((field) => field.key_field)

    return fields.map((field) => {
      const value = getFieldValue(record, selectedGroupId, field)

      return {
        ...field,
        value
      }
    })
  }, [fieldDefns, record, selectedGroupId])

  return { keyFields, selectedGroup, setSelectedGroupId }
}

interface HighlightedFieldsProps {
  account: any
}

function HighlightedFields(props: HighlightedFieldsProps) {
  const { keyFields } = useHighlightedFields(props.account, 'account')
  const [isExpanded, setIsExpanded] = useState(false)

  const nonEmptyFields = keyFields.filter((field) => {
    const { value } = field
    if (typeof value === 'undefined' || value === null || (typeof value === 'string' && value.trim() === '')) {
      return false
    }
    return true
  })

  if (nonEmptyFields.length === 0) return null

  // Show 2 rows of 4 fields
  const visibleFields = isExpanded ? nonEmptyFields : nonEmptyFields.slice(0, 8)

  return (
    <Toggle defaultIsOpen title={<Heading size="xs">Highlights</Heading>}>
      <Stack width="100%" spacing={3} border="1px solid" borderColor="gray.200" borderRadius="md" py={3} px={3}>
        <Grid
          gridAutoRows="auto"
          gridTemplateColumns={['1fr', 'repeat(auto-fill, minmax(140px, 1fr))']}
          columnGap={3}
          rowGap={3}
        >
          {visibleFields.map((field) => {
            const { value, data_type } = field

            return (
              <Box key={field.id}>
                <Text fontSize="xs" color="gray.600" isTruncated>
                  {field.label}
                </Text>
                <Box fontSize="13px" fontWeight="semibold" isTruncated>
                  {data_type === 'number' ? (
                    <Text css={{ fontVariantNumeric: 'tabular-nums' }}>{parseNumber(value)}</Text>
                  ) : data_type === 'currency' ? (
                    <Text css={{ fontVariantNumeric: 'tabular-nums' }}>{formatFriendlyCurrency(value)}</Text>
                  ) : data_type === 'percent' ? (
                    <Text css={{ fontVariantNumeric: 'tabular-nums' }}>{formatPercent(value)}</Text>
                  ) : data_type === 'boolean' ? (
                    <BubbleTag
                      variant="subtleBorder"
                      colorScheme={['true', 't', '1'].includes(String(value)) ? 'green' : 'red'}
                    >
                      {['true', 't', '1'].includes(String(value)) ? 'Yes' : 'No'}
                    </BubbleTag>
                  ) : data_type === 'date' ? (
                    <TextEllipsis maxWidth="100%" tooltip value={dayjs(value).format('ddd, MMM D, YYYY')}>
                      <TimeAgo time={value} />
                    </TextEllipsis>
                  ) : (
                    <Text
                      display="flex"
                      sx={{
                        '.url-description': {
                          textDecoration: 'underline',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          flex: 1,
                          maxWidth: '100%'
                        }
                      }}
                      isTruncated
                      dangerouslySetInnerHTML={{
                        __html: Autolinker.link(String(value), {
                          truncate: { length: 64, location: 'end' },
                          newWindow: true,
                          sanitizeHtml: true,
                          stripPrefix: false,
                          stripTrailingSlash: true,
                          email: false,
                          phone: false,
                          mention: false,
                          hashtag: false,
                          urls: {
                            ipV4Matches: false,
                            tldMatches: true,
                            schemeMatches: true
                          },
                          className: 'url-description'
                        })
                      }}
                    />
                  )}
                </Box>
              </Box>
            )
          })}
        </Grid>

        {nonEmptyFields.length > visibleFields.length && (
          <Button
            alignSelf="flex-start"
            variant="link"
            size="xs"
            onClick={() => setIsExpanded(!isExpanded)}
            leftIcon={isExpanded ? <IconChevronDown size={14} /> : <IconChevronRight size={14} />}
            iconSpacing={1}
            color="gray.600"
          >
            {isExpanded ? 'Show less' : `Show more details`}
          </Button>
        )}
      </Stack>
    </Toggle>
  )
}

function parseNumber(value: any) {
  try {
    return Number(value).toLocaleString()
  } catch {
    return String(value)
  }
}
