import { EmailPreferencesResponse, useEmailPreferences } from '@app/hooks/use-email-preferences'
import { Box, Button, Flex, FormControl, FormLabel, Heading, Icon, IconButton, Stack, Text } from '@chakra-ui/react'
import { IconArrowLeft, IconSettings, IconSparkles, IconX } from '@tabler/icons-react'
import React, { useCallback, useEffect, useState } from 'react'
import { AutosizedTextarea } from '../AutosizedTextarea'

export interface EmailPreferencesPanelProps {
  onClose: () => void
  onSave: () => void
}

export const EmailPreferencesPanel = ({ onClose, onSave }: EmailPreferencesPanelProps) => {
  const [isSaving, setIsSaving] = useState(false)

  const { preferences, isLoading: preferencesLoading, savePreferences } = useEmailPreferences()
  const [localPreferences, setLocalPreferences] = useState<Partial<EmailPreferencesResponse>>(preferences ?? {})

  useEffect(() => {
    if (preferences && !preferencesLoading) {
      setLocalPreferences(preferences)
    }
  }, [preferences, preferencesLoading])

  const handlePreferencesChange = useCallback(
    (field: keyof EmailPreferencesResponse, value: string) => {
      const newPreferences = { ...localPreferences, [field]: value }
      setLocalPreferences(newPreferences)
    },
    [localPreferences]
  )

  const handleSave = useCallback(async () => {
    setIsSaving(true)

    try {
      await savePreferences(localPreferences)
      onSave()
    } finally {
      setIsSaving(false)
    }
  }, [localPreferences, savePreferences, onSave])

  if (preferencesLoading) {
    return (
      <Box
        height="full"
        bg="white"
        border="1px solid"
        borderColor="gray.200"
        shadow="heavy"
        roundedTop="md"
        display="flex"
        flexDirection="column"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          pl={4}
          pr={2}
          py={2}
          borderBottomWidth="1px"
          borderColor="gray.200"
          bg="gray.50"
          roundedTop="md"
        >
          <Heading size="xs" fontWeight="semibold" display="flex" alignItems="center" gap={2}>
            <Icon as={IconSettings} boxSize={4} />
            Email Preferences
          </Heading>
          <IconButton
            icon={<Icon as={IconX} boxSize={4} />}
            size="xs"
            variant="ghost"
            aria-label="Close"
            onClick={onClose}
          />
        </Flex>
        <Stack flex="1 1 auto" p={4} justify="center" align="center">
          <Text>Loading preferences...</Text>
        </Stack>
      </Box>
    )
  }

  return (
    <Stack
      height="full"
      bg="white"
      display="flex"
      flexDirection="column"
      as="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleSave()
      }}
    >
      <Stack flex="1 1 auto" overflow="auto" spacing={4} px={4} py={2}>
        <FormControl>
          <FormLabel fontSize="13px">Default Greeting</FormLabel>
          <AutosizedTextarea
            size="sm"
            value={localPreferences.default_greeting}
            onChange={(e) => handlePreferencesChange('default_greeting', e.target.value)}
            placeholder="Hi {{name}},"
          />
          <Text fontSize="xs" color="gray.500" mt={1}>
            Use {'{'}
            {'{'} name {'}'}
            {'}'} to insert the recipient's name
          </Text>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="13px">Signature</FormLabel>
          <AutosizedTextarea
            size="sm"
            value={localPreferences.signature}
            onChange={(e) => handlePreferencesChange('signature', e.target.value)}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize="13px">Email Writing Instructions</FormLabel>
          <AutosizedTextarea
            size="sm"
            value={localPreferences.email_instructions}
            onChange={(e) => handlePreferencesChange('email_instructions', e.target.value)}
            placeholder="Add any specific instructions for email writing..."
            minHeight="100px"
          />
          <Text fontSize="xs" color="gray.500" mt={1}>
            Any additional instructions you want to add to the email generation process.
          </Text>
        </FormControl>
      </Stack>

      <Flex borderTop="1px solid" borderColor="gray.200" p={4} bg="gray.50" justify="flex-end" gap={2}>
        <Button leftIcon={<Icon as={IconArrowLeft} boxSize={4} />} onClick={onClose} size="sm" variant="ghost">
          Back to message
        </Button>

        <Button
          type="button"
          size="sm"
          colorScheme="purple"
          variant="solid"
          isLoading={isSaving}
          loadingText="Saving..."
          onClick={handleSave}
          leftIcon={<Icon as={IconSparkles} boxSize={3.5} />}
        >
          Save
        </Button>
      </Flex>
    </Stack>
  )
}
