import { Box, BoxProps, Flex, Image, ImageProps, useBreakpointValue, useTheme } from '@chakra-ui/react'
import { Icon } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import * as ReactIs from 'react-is'
import { readableColorIsBlack } from '../../lib/colors'
import { getInitials } from '../../lib/get-initials'
import { hashCode } from '../../lib/string-to-hash'
import { UserCircleIcon } from './icons'
import usePreloadedImage from './usePreloadedImage'

function getSize(size: string) {
  switch (size) {
    case 'tiny':
      return '18px'
    case 'xs':
      return '24px'
    case 'sm':
      return '32px'
    case 'md':
      return '48px'
    case 'lg':
      return '56px'
    case 'xl':
      return '72px'
    case '2xl':
      return '88px'
    default:
      return size
  }
}

export interface AvatarProps extends Omit<BoxProps, 'size' | 'boxSize'> {
  name?: string | null
  hashId?: string | null
  src?: string | null
  size?: 'xs' | 'sm' | 'md' | string | string[]
  badge?: React.ReactNode
  badgePosition?: 'top' | 'bottom'
  monochrome?: boolean
  imageProps?: ImageProps
  fallbackIcon?: React.ReactNode | React.FunctionComponent
}

export default React.forwardRef(function Avatar(props: AvatarProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const {
    badge,
    badgePosition = 'bottom',
    name,
    hashId,
    size = 'md',
    src,
    monochrome,
    fallbackIcon,
    imageProps,
    ...boxProps
  } = props

  const [imageUnavailable, setImageUnavailable] = React.useState(!src)

  useEffect(() => {
    setImageUnavailable(!src)
  }, [src])

  usePreloadedImage(src)

  const initials = React.useMemo(() => getInitials(name), [name])
  const hashInput = hashId || name
  const hash = React.useMemo(() => hashCode(hashInput), [hashInput])
  const theme = useTheme()
  const breakpointSize = useBreakpointValue(Array.isArray(size) ? size : [size])

  const bgColorKeys = Object.keys(theme.colors).filter((key) => {
    return ['gray', 'red', 'orange', 'yellow', 'green', 'teal', 'blue', 'cyan', 'purple', 'pink'].includes(key)
  })

  let backgroundColor = 'transparent'
  if (imageUnavailable) {
    if (monochrome || !hashInput) {
      backgroundColor = theme.colors.gray['100']
    } else if (hashInput) {
      backgroundColor = theme.colors[bgColorKeys[hash % bgColorKeys.length]]['100']
    }
  }

  const color = readableColorIsBlack(backgroundColor) ? 'gray.900' : 'white'
  const anonColor = readableColorIsBlack(backgroundColor) ? 'gray.400' : 'white'

  const resolvedSize = getSize(breakpointSize || 'md')
  const resolvedSizeInt = parseInt(resolvedSize as string, 10)

  let icon = (fallbackIcon || (
    <UserCircleIcon opacity={0.75} color={hashInput ? backgroundColor : anonColor} size={resolvedSizeInt} />
  )) as React.ReactNode | Icon
  if (ReactIs.isValidElementType(icon)) {
    icon = React.createElement(icon, { size: Math.floor(resolvedSizeInt / 2.25) })
  }

  return (
    <Flex
      ref={ref}
      flex="none"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      boxSize={resolvedSize}
      borderRadius="100%"
      color={color}
      backgroundColor={backgroundColor}
      borderColor="white"
      userSelect="none"
      title={name ?? undefined}
      fontSize={Math.floor(resolvedSizeInt / (resolvedSizeInt >= 36 ? 3 : 2.25))}
      fontWeight="500"
      {...boxProps}
    >
      {imageUnavailable && (
        <Box display="flex" color={initials ? 'inherit' : 'gray.500'} textTransform="uppercase" opacity={0.9}>
          {initials || (icon as any)}
        </Box>
      )}
      {src && !imageUnavailable && (
        <Image
          alt={name || ''}
          objectFit="contain"
          boxSize={resolvedSize}
          overflow="hidden"
          borderRadius="inherit"
          src={src}
          onError={() => setImageUnavailable(true)}
          {...imageProps}
        />
      )}
      {badge && (
        <Box
          position="absolute"
          flex="none"
          maxWidth="90%"
          top={badgePosition === 'top' ? 0 : undefined}
          bottom={badgePosition === 'bottom' ? 0 : undefined}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          transform="scale(0.33)"
          transformOrigin={`${badgePosition} right`}
        >
          {badge}
        </Box>
      )}
    </Flex>
  )
})
