import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconAlarmSnooze, IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { toast } from 'sonner'
import dayjs from '../../../../lib/dayjs'
import { useSnoozePlayItem } from '../../../data/use-plays'

export const snoozePeriods = [
  {
    label: 'in 1 hour',
    value: 'in 1 hour'
  },
  {
    label: 'Tomorrow',
    value: 'tomorrow, 9am',
    // show 9am for the 3-letter day
    preview: () => `${dayjs().add(1, 'day').format('ddd')}, 9:00am`
  },
  {
    label: 'Next week',
    value: 'next week, Mon 9am',
    preview: () => `Mon, 9:00am`
  },
  {
    label: 'Next month',
    value: 'next month, 9am',
    preview: () => `${dayjs().add(1, 'month').format('ddd, MMM DD')}`
  }
]

interface SnoozeButtonProps {
  itemId: string
  onSuccess?: (itemId: string, status: string) => void
}

export function SnoozeButton({ itemId, onSuccess }: SnoozeButtonProps) {
  const [isPickerOpen, setIsPickerOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)

  const { mutate: snoozeItem, isPending: isLoading } = useSnoozePlayItem({
    onSuccess: () => {
      onSuccess?.(itemId, 'snoozed')
    },
    onError: (error: any) => {
      toast.error('There was an issue snoozing this lead', { description: error?.body?.message || error?.message })
    }
  })

  const handleDateSelect = useCallback(() => {
    if (!selectedDate) return

    // Set to 9am on the selected date
    const snoozeDate = dayjs(selectedDate).hour(9).minute(0).second(0)
    snoozeItem({ itemId, until: snoozeDate.format() })
    setIsPickerOpen(false)
  }, [selectedDate, snoozeItem, itemId])

  return (
    <>
      <Menu>
        <Tooltip label="Snooze">
          <MenuButton
            as={IconButton}
            aria-label="Snooze"
            icon={<IconAlarmSnooze size={16} />}
            bg="white"
            mx="-px"
            isLoading={isLoading}
          />
        </Tooltip>
        <Portal>
          <MenuList zIndex="popover">
            {snoozePeriods.map((option) => (
              <MenuItem key={option.label} onClick={() => snoozeItem({ itemId, until: option.value })}>
                {option.label}
                <Text color="gray.400" ml="auto">
                  {option.preview?.()}
                </Text>
              </MenuItem>
            ))}
            <MenuDivider />
            <MenuItem onClick={() => setIsPickerOpen(true)}>Pick a date</MenuItem>
          </MenuList>
        </Portal>
      </Menu>

      <Modal size="sm" isOpen={isPickerOpen} onClose={() => setIsPickerOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Snooze until...</ModalHeader>
          <ModalBody pb={6}>
            <DayPicker
              mode="single"
              defaultMonth={dayjs().add(1, 'day').toDate()}
              fromDate={dayjs().add(1, 'day').toDate()}
              toDate={dayjs().add(90, 'days').toDate()}
              selected={selectedDate}
              onSelect={setSelectedDate}
              components={{
                IconLeft: () => <IconChevronLeft size={16} />,
                IconRight: () => <IconChevronRight size={16} />
              }}
              numberOfMonths={1}
            />
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="outline" onClick={() => setIsPickerOpen(false)} mr={3}>
              Cancel
            </Button>
            <Button size="sm" variant="solid" colorScheme="purple" onClick={handleDateSelect}>
              Snooze
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
