import { Circle, SquareProps } from '@chakra-ui/react'
import { keyframes } from '@emotion/react'
import React, { forwardRef } from 'react'

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.8;
  }

  70% {
    transform: scale(3);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
`

interface PulseProps extends SquareProps {
  paused?: boolean
  speed?: `${number}s` | `${number}ms`
}

export default forwardRef<HTMLDivElement, PulseProps>(function Pulse({ paused, speed, ...props }: PulseProps, ref) {
  return (
    <Circle
      ref={ref}
      position="relative"
      size={5}
      background="green.300"
      {...props}
      transform="scale(1)"
      _before={{
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        background: 'inherit',
        borderRadius: 'inherit',
        transform: 'scale(1)',
        opacity: 1,
        animation: paused ? undefined : `${pulse} ${speed || '2s'} infinite`
      }}
    />
  )
})
