import keyBy from 'lodash/keyBy'
import React from 'react'
import { concurrentCachedGET } from '../../lib/api'
import { Facet, FacetMapping, FacetMappings, NumericFilter } from '../pages/accounts'
import { AdvancedFilters } from '../ui/filters/types'
import { projectPath } from '../ui/ProjectsContext'
import { InputParams, useSearchParams } from '../ui/useSearchState'
import { convertEqToGte } from './use-facets'

interface Props {
  initialFacets?: AdvancedFilters
  initialRange?: 'day' | 'week' | 'month' | 'all' | 'any' | null
  initialFocusTime?: NumericFilter | null
  initialSortBy?: string
  initialPage?: number | string
  initialQuery?: string
  // any additional (untyped) props that we dont necessarily expect but should be managed in the url state
  extra?: InputParams
  facetCloudPath?: string
  onClearFilters?: () => void
}

interface ParsedFilterParams {
  page?: string
  range?: 'day' | 'week' | 'month' | 'all' | 'any'
  territory?: string | null
  query?: string
  focus_time?: NumericFilter | null
  facets: {
    [key: string]: Facet
  }
  sort_by?: string
}

export interface FacetCloudResponse {
  mappings: FacetMapping[]
  top_filters: string[]
}

export type UrlFilterParams = ReturnType<typeof useUrlFilters>

const emptyObject = {}

function hasComplexFilters(filters: AdvancedFilters | undefined | null) {
  if (filters && ('_and' in filters || '_or' in filters)) {
    return true
  }

  return false
}

function encodeFacets(filters: AdvancedFilters | undefined | null): string | any {
  if (hasComplexFilters(filters)) {
    return JSON.stringify(filters)
  }

  return filters
}

function decodeFacets(filters: any): AdvancedFilters {
  try {
    if (typeof filters === 'string' && filters && filters.startsWith('{')) {
      return JSON.parse(filters)
    }

    return filters
  } catch (error) {
    console.error('Failed to parse JSON-encoded facets', error)
    return filters
  }
}

/**
 * Keeps local state in sync from url params as the source of truth.
 * Updating state is to navigate to a new url.
 * There is a lot of code here for interop/backwards-compat with use-facets
 */
export function useUrlFilters(props: Props) {
  const initialFacetsRef = React.useRef(props.initialFacets ?? {})

  const { searchParams, setSearchParams, setSearchParam, onlyDefaults } = useSearchParams({
    page: props.initialPage ?? 1,
    range: props.initialRange !== null ? props.initialRange || 'all' : null,
    query: props.initialQuery,
    focus_time: convertEqToGte(props.initialFocusTime),
    facets: encodeFacets(props.initialFacets),
    sort_by: props.initialSortBy,
    ...props.extra
  })

  const clearFilters = React.useCallback(() => {
    setSearchParams((prev) => {
      return {
        ...prev,
        page: undefined,
        range: undefined,
        territory: undefined,
        query: undefined,
        focus_time: undefined,
        facets: undefined,
        sort_by: undefined
      }
    })
  }, [setSearchParams])

  const updateAndResetPage = React.useCallback(
    (key: string, value: any) => {
      setSearchParams((prev) => {
        const changes = {
          [key]: value
        }

        if (prev.page && Number(prev.page) > 1) {
          changes.page = 1
        }

        return {
          ...prev,
          ...changes
        }
      })
    },
    [setSearchParams]
  )

  const setTerritory = React.useCallback(
    (territoryId: string | null) => {
      if (territoryId === null) {
        updateAndResetPage('territory', undefined)
      } else {
        updateAndResetPage('territory', territoryId)
      }
    },
    [updateAndResetPage]
  )

  const setRange = React.useCallback(
    (range) => {
      if (range === null) {
        updateAndResetPage('range', 'any')
      } else {
        updateAndResetPage('range', range)
      }
    },
    [updateAndResetPage]
  )

  const setQuery = React.useCallback(
    (value) => {
      updateAndResetPage('query', value)
    },
    [updateAndResetPage]
  )

  const setPage = React.useCallback(
    (value) => {
      setSearchParam('page', value)
    },
    [setSearchParam]
  )

  const setSortBy = React.useCallback(
    (value) => {
      updateAndResetPage('sort_by', value)
    },
    [updateAndResetPage]
  )

  const setFocusTime = React.useCallback(
    (value) => {
      updateAndResetPage('focus_time', value)
    },
    [updateAndResetPage]
  )

  // This only works on `facets` params (not other top-level params)
  const applyFilters = React.useCallback(
    (appliedFilters: AdvancedFilters) => {
      setSearchParams((prev) => {
        const currentFacets = decodeFacets(prev.facets) || {}
        const updatedFacets = {
          ...currentFacets,
          ...appliedFilters
        }

        return {
          ...prev,
          // Only update the page in the query string if it has changed
          // This avoids unnecessarily adding to the query string
          page: prev.page && Number(prev.page) > 1 ? 1 : undefined,
          facets: encodeFacets(updatedFacets)
        }
      })
    },
    [setSearchParams]
  )

  // This only works on `facets` params (not other top-level params)
  const setFacetFilters = React.useCallback(
    (next: AdvancedFilters | ((prev: AdvancedFilters) => AdvancedFilters)) => {
      setSearchParams((prev) => {
        const currentFacets = decodeFacets(prev.facets) || {}
        let facets: any = typeof next === 'function' ? next(currentFacets as AdvancedFilters) : next

        // Send an empty param (which gets converted from `''` to `{}` in the backend)
        // This is important when the absence of facets should override the default facets
        if (Object.keys(facets).length === 0 && Object.keys(initialFacetsRef.current ?? {}).length) {
          facets = ''
        }

        return {
          ...prev,
          // Only update the page in the query string if it has changed
          // This avoids unnecessarily adding to the query string
          page: prev.page && Number(prev.page) > 1 ? 1 : undefined,
          facets: encodeFacets(facets)
        }
      })
    },
    // we only want the initial facets param
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchParams]
  )

  const facetCloudPath = props.facetCloudPath ?? '/accounts/facet-cloud'
  const [facetMappings, setFacetMappings] = React.useState<FacetMappings>({})
  const [facetCloudLoading, setFacetCloudLoading] = React.useState(true)
  const [topFilters, setTopFilters] = React.useState<string[]>([])

  // Load once
  React.useEffect(() => {
    let canceled = false
    setFacetCloudLoading(true)

    concurrentCachedGET<FacetCloudResponse>(projectPath(facetCloudPath))
      .then((res) => {
        // Dont update the state if this effect has been unloaded
        if (canceled) {
          return
        }

        setFacetMappings(keyBy(res.mappings || [], 'facet'))
        setTopFilters(res.top_filters || [])
        setFacetCloudLoading(false)
      })
      .catch((_error) => {
        setFacetCloudLoading(false)
      })

    return () => {
      canceled = true
    }
  }, [facetCloudPath])

  const {
    page,
    range,
    territory,
    query,
    focus_time,
    facets = emptyObject,
    sort_by
  } = searchParams as unknown as ParsedFilterParams

  const decodedFacets = decodeFacets(facets) || {}
  const isFiltering = Object.keys(decodedFacets).length > 0 || !!range || !!query || !!focus_time

  return {
    raw: searchParams,
    page: parseInt(page ?? '1', 10),
    range,
    territory,
    query,
    focusTime: convertEqToGte(focus_time) || null,
    sortBy: sort_by,
    facetFilters: decodedFacets,
    facetMappings,
    topFilters,
    facetCloudLoading,

    onlyDefaults,
    isFiltering,
    canClearFilters: !onlyDefaults,
    clearFilters,
    setTerritory,
    setRange,
    setQuery,
    setPage,
    setSortBy,
    setFocusTime,
    applyFilters,
    setFacetFilters,
    updateAndResetPage
  }
}
