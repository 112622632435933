import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconPlus } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React, { useEffect, useMemo } from 'react'
import { toast } from 'sonner'
import router from '../../../../lib/router'
import { useAddListItems } from '../../../data/use-list-items'
import { useLists } from '../../../data/use-lists'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { projectPath } from '../../../ui/ProjectsContext'
import { FacetFilters } from '../../accounts'

interface AddToListModalProps extends UseDisclosureProps {
  recordId?: string
  recordIds?: string[]
  kind: 'account' | 'profile'
  onSuccess?: () => void
  selectAllMode?: boolean
  facets?: FacetFilters
}

const emptyArray = []

export function AddToListModal(props: AddToListModalProps) {
  const recordIds = useMemo(
    () => (props.recordId ? [props.recordId] : (props.recordIds ?? emptyArray)),
    [props.recordId, props.recordIds]
  )

  const { selectAllMode, facets } = props
  const disclosure = useDisclosure(props)
  const { data, isLoading } = useLists({ kind: props.kind })
  const [listId, setListId] = React.useState<string | null>(null)
  const [showNewList, setShowNewList] = React.useState(false)
  const [newListName, setNewListName] = React.useState<string>('')

  const { isPending: isSubmitting, mutateAsync: addItem } = useAddListItems()

  const onClose = disclosure.onClose
  const onSuccess = props.onSuccess

  const facetFilters = facets && Object.keys(facets).map((key) => [key, facets[key]])

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault()

      if (!recordIds.length && !selectAllMode) return
      if (!listId && !newListName) return

      const payload: Record<string, any> = {
        listId: listId,
        list: newListName ? { name: newListName, kind: props.kind } : undefined
      }

      if (selectAllMode) {
        payload.facets = facets
      } else {
        payload.recordIds = recordIds
      }

      try {
        const res = await addItem(payload)
        const listPath = res.list?.id ? projectPath(`/lists/${res.list.id}`) : null
        toast.success(
          `Added ${pluralize(props.kind, recordIds.length)} to list!`,
          listPath
            ? {
                action: {
                  label: 'View List',
                  onClick: () => router.visit(listPath)
                }
              }
            : undefined
        )
        onSuccess?.()
        onClose()
      } catch (error) {
        console.error(error)
        toast.error(`Failed to add ${pluralize(props.kind, recordIds.length)} to list`)
      }
    },
    [recordIds, selectAllMode, listId, newListName, facets, props.kind, addItem, onSuccess, onClose]
  )

  const lists = useMemo(() => data?.lists ?? [], [data?.lists])

  useEffect(() => {
    if (!isLoading && lists.length === 0) {
      setShowNewList(true)
    }
  }, [isLoading, lists])

  const formId = 'add-to-list-form'

  return (
    <Modal {...disclosure} size="md" preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Add to a list</ModalHeader>
        <ModalBody
          as="form"
          action={projectPath(`/lists/${listId}/records`)}
          method="POST"
          id={formId}
          onSubmit={onSubmit}
        >
          <AuthenticityToken />

          {!selectAllMode && (
            <>
              {recordIds.map((recordId) => (
                <input key={recordId} type="hidden" name="record[record_ids][]" value={recordId} />
              ))}
            </>
          )}
          {selectAllMode && facetFilters && (
            <>
              {facetFilters.map((facet) => {
                return <input key={facet[0]} type="hidden" name={`facets[${facet[0]}][]`} value={facet} />
              })}
            </>
          )}

          {isLoading ? (
            <Flex height="32px" alignItems="center">
              <Spinner color="gray.400" size="sm" thickness="1.5px" />
            </Flex>
          ) : (
            <Stack spacing={6}>
              <FormControl size="sm">
                <FormLabel>Select a list:</FormLabel>
                <ComboboxWithSearch
                  items={lists}
                  selectedItem={lists.find((l) => l.id === listId) ?? null}
                  placeholder="Select a list…"
                  onChange={(selectedItem) => {
                    setListId(selectedItem?.id || null)
                  }}
                  filterItem={(a, val) => (a.name || '').toLowerCase().includes(val)}
                  itemToString={(item) => item?.name || ''}
                />
              </FormControl>

              {showNewList ? (
                <FormControl size="sm">
                  <FormLabel>Or create a new list:</FormLabel>
                  <input type="hidden" name="list[kind]" value={props.kind} />
                  <Input
                    size="sm"
                    name="list[name]"
                    placeholder="Enter a name for your list"
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                  />
                </FormControl>
              ) : (
                <Flex alignItems="center" gap={2}>
                  <Text fontSize="sm">Or create a new list</Text>
                  <Button
                    size="sm"
                    variant="link"
                    colorScheme="purple"
                    leftIcon={<IconPlus size={14} />}
                    iconSpacing={0.5}
                    onClick={() => {
                      setShowNewList(true)
                    }}
                  >
                    New List
                  </Button>
                </Flex>
              )}
            </Stack>
          )}
        </ModalBody>

        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button
            size="sm"
            type="submit"
            colorScheme="purple"
            form={formId}
            isLoading={isSubmitting}
            isDisabled={(!listId && !newListName) || (!recordIds.length && !selectAllMode)}
          >
            {newListName ? 'Create and add to list' : 'Add to list'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
