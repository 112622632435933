import {
  AvatarGroup,
  Badge,
  Box,
  Button,
  ButtonProps,
  Center,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  Spinner,
  Square,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList,
  useDisclosure,
  UseDisclosureReturn,
  useNumberInput,
  useTheme
} from '@chakra-ui/react'
import {
  IconAlertTriangle,
  IconArrowRight,
  IconChevronDown,
  IconChevronUp,
  IconMinus,
  IconPlus,
  IconTrendingDown,
  IconTrendingUp,
  IconX,
  IconZoomExclamation
} from '@tabler/icons-react'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import omitBy from 'lodash/omitBy'
import partition from 'lodash/partition'
import pick from 'lodash/pick'
import sumBy from 'lodash/sumBy'
import uniq from 'lodash/uniq'
import pluralize from 'pluralize'
import React, { useMemo } from 'react'
import { Bar, BarChart, CartesianGrid, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { toast } from 'sonner'
import { post } from '../../../lib/api'
import dayjs from '../../../lib/dayjs'
import Router from '../../../lib/router'
import { App, Apps } from '../../../types/App'
import { useFacets } from '../../data/use-facets'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import Avatar from '../../ui/Avatar'
import { FeatureLockout } from '../../ui/billing-banners/feature-lockout'
import { LightBgCard } from '../../ui/Card'
import { ComboboxWithSearch } from '../../ui/ComboboxWithSearch'
import EmptyState from '../../ui/EmptyState'
import { CheckboxBlankCircleIcon, CheckboxCircleIcon } from '../../ui/icons'
import { InfoBox } from '../../ui/InfoBox'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { StepIcon } from '../../ui/StepIcon'
import { useEntitlements } from '../../ui/useEntitlements'
import { useCurrentUser } from '../../ui/UserContext'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { FacetFilters } from '../accounts'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { getItemDisplay } from '../accounts/facets/categories'
import FilterPopover from '../accounts/facets/filter-popover'
import { SyncStatus } from './components/SyncStatus'
import img from '../../ui/billing-banners/assets/icp-scoring-banner.svg'

const noop = () => {}

interface FeatureBucket {
  value: string
  population: number
  population_percent: number
  converted: number
  converted_percent: number
  factor: number
  confidence: number | null
  recommended: boolean
}

interface Feature {
  population_coverage: number
  population_percent: number
  converted_coverage: number
  converted_percent: number
  buckets: FeatureBucket[]
}

interface Multipliers {
  [feature: string]: number
}

interface Boost {
  value: string
  factor: number
}

interface Boosts {
  [feature: string]: Boost[]
}

interface SampleAccount {
  id: string
  grade: string
  company: {
    name?: string
    logo?: string
    domain: string
  }
}

type Catalog = Record<
  'salesforce' | 'hubspot',
  {
    title: string
    description: string
    logo: string
    module: string
    clean_module_name: string
    categories: string[]
  }
>

interface Props {
  crm: App
  apps: Apps
  catalog: Catalog
  last_updated_at?: string
  last_run_at?: string
  rescore_enabled?: boolean
  population_type?: string
  population_filter: FacetFilters
  group_operator?: 'any' | 'all'
  conversion?: string
  population_stats: {
    [key: string]: number
  }
  sample_accounts: {
    [grade: string]: SampleAccount[]
  }
  population?: {
    total: number
    converted: number
    population_filter: FacetFilters
    applied_population_filter: FacetFilters
    conversion_filter: FacetFilters
    applied_converted_filter: FacetFilters
  }
  min_confidence?: number
  min_conversions?: number
  features?: {
    [feature: string]: Feature
  }
  converted_buckets?: ScoreDistributionProps['converted_buckets']
  scores?: ScoreDistributionProps['scores']
  multipliers?: Multipliers
  recommended?: Boosts
}

function flattenBoosts(features?: Boosts): Multipliers | undefined {
  if (!features) {
    return
  }

  const flat = {}

  Object.keys(features).forEach((feature) => {
    const buckets = features[feature] || []
    buckets.forEach((bucket) => {
      flat[`${feature}:${bucket.value}`] = bucket.factor || 1
    })
  })

  return flat
}

function nestBoosts(multipliers: Multipliers): Boosts {
  const nested = {}

  for (const [key, factor] of Object.entries(multipliers)) {
    const [feature, value] = key.split(':')
    nested[feature] = nested[feature] || []
    nested[feature].push({ value, factor })
  }

  return nested
}

export default function Features(props: Props) {
  const user = useCurrentUser()
  const entitlements = useEntitlements()

  const icpEnabled = useMemo(() => {
    // if we have a last_updated_at, then we know the user has already enabled icp
    if (props.last_updated_at) {
      return true
    }

    return entitlements?.icp_scoring ?? false
  }, [entitlements, props.last_updated_at])

  const initialUniverseRef = React.useRef(props.population_type)
  const [universe, setUniverse] = React.useState<string | undefined>(props.population_type)
  const [closedWon, setClosedWon] = React.useState<string | undefined>(props.conversion)
  const primaryCrm = props.crm
  const standardClosedWon =
    primaryCrm?.title === 'HubSpot' ? 'lifecycle stage is "Customer"' : 'opportunity stage of "Closed Won"'

  const [groupOperator, setGroupOperator] = React.useState(props.group_operator || 'all')

  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })

  React.useEffect(() => {
    setGroupOperator(props.group_operator || 'all')
  }, [props.group_operator])

  const population = useFacets({
    facet_filters: props.population_type === 'kdb' ? props.population_filter || {} : {},
    facetCloudPath: '/companies/facet-cloud'
  })

  const converted = useFacets({
    facet_filters: props.population?.conversion_filter,
    facetCloudPath: '/accounts/facet-cloud?seen=0&range=all'
  })

  const [selectedFeatures, setFeatures] = React.useState<string[]>(Object.keys(props.features || {}))
  const [multipliers, setMultipliers] = React.useState<Multipliers>(props.multipliers ?? {})
  const featureMenu = useDisclosure()

  React.useEffect(() => {
    const keys = Object.keys(props.features || {})
    if (keys.length) {
      setFeatures((prev) => (prev.length ? prev : keys))
    }
  }, [props.features])

  const [_recommended, setRecommended] = React.useState<Boosts>(props.recommended ?? {})

  // Convert multiplier object into proper format (for searchkick and backend classes)
  const boosts = React.useMemo(() => nestBoosts(multipliers), [multipliers])

  const [boostedFeatures, otherFeatures] = React.useMemo(() => {
    const all = uniq(selectedFeatures.concat(Object.keys(boosts)))
    return partition(all, (feat) => feat in boosts)
  }, [selectedFeatures, boosts])

  const [loading, setLoading] = React.useState(false)

  // React.useEffect(() => {
  //   const newValue = props.multipliers || flattenBoosts(props.recommended)
  //   if (newValue) {
  //     setMultipliers(newValue)
  //   }
  // }, [props.multipliers, props.recommended])

  React.useEffect(() => {
    const update = () => setLoading(false)
    window.addEventListener('pagefetch', update)

    return () => {
      window.removeEventListener('pagefetch', update)
    }
  }, [])

  useUpdateEffect(() => {
    let basePath = `${window.location.pathname}`

    const conversionQs = closedWon === 'default' ? `conversion=${closedWon}` : ''
    // replace `facets[]` with `cf[]`
    const conversionFilter =
      closedWon === 'default' ? '' : converted.queryString.replace(/facets\[/g, 'cf[').replace(/^\?/, '') || 'cf='

    const populationFilter =
      universe === 'kdb' ? population.queryString.replace(/facets\[/g, 'pf[').replace(/^\?/, '') || 'pf=' : ''

    const groupOperatorQs = universe === 'kdb' ? `group_operator=${groupOperator}` : ''
    const populationQs = universe ? `population=${universe}` : ''
    const featuresQs = selectedFeatures.length ? `features=${selectedFeatures.join(',')}` : ''
    const queryString = [populationQs, populationFilter, conversionQs, conversionFilter, featuresQs, groupOperatorQs]
      .filter(Boolean)
      .join('&')

    if (queryString.length > 0) {
      basePath = `${basePath}?${queryString}`
    }

    const current = `${location.pathname}${location.search}`

    if (basePath !== current) {
      setLoading(true)
      Router.visit(basePath)
    }
  }, [universe, closedWon, converted.queryString, population.queryString, selectedFeatures, groupOperator])

  const [saving, setSaving] = React.useState(false)
  const [rescoring, setRescoring] = React.useState(false)
  const [calculating, setCalculating] = React.useState(false)
  const [convertedBuckets, setConvertedBuckets] = React.useState(props.converted_buckets ?? [])
  const [sampleAccounts, setSampleAccounts] = React.useState(props.sample_accounts)
  const [scores, setScores] = React.useState(props.scores)
  const [lastSavedAt, setLastSavedAt] = React.useState(props.last_updated_at)
  const [lastStartedAt, setLastStartedAt] = React.useState<string | undefined>(props.last_run_at)
  const [rescoringEnabled, setRescoringEnabled] = React.useState(props.rescore_enabled ?? true)

  const calculateScores = React.useCallback(async (boosts) => {
    setCalculating(true)

    try {
      const res = await post<Props>(projectPath(`/scoring/calculate`) + window.location.search, { boosts })

      if (res.converted_buckets) {
        setConvertedBuckets(res.converted_buckets)
      }

      if (res.sample_accounts) {
        setSampleAccounts(res.sample_accounts)
      }

      if (res.scores) {
        setScores(res.scores)
      }

      setRecommended(res.recommended || {})
    } finally {
      setCalculating(false)
    }
  }, [])

  React.useEffect(() => {
    if (universe && closedWon && Object.keys(boosts).length) {
      calculateScores(boosts)
    }
  }, [universe, population.facetFilters, closedWon, boosts, calculateScores])

  const saveSettings = React.useCallback(async () => {
    setSaving(true)

    try {
      await post<Props>(projectPath(`/scoring`) + window.location.search, { boosts })
      setLastSavedAt(new Date().toISOString())
      toast.success('Saved your scoring settings!')
    } catch (_error) {
      toast.error('Failed to save your scoring settings')
    } finally {
      setSaving(false)
    }
  }, [boosts])

  const rescore = React.useCallback(async () => {
    setRescoring(true)

    try {
      await post<Props>(projectPath(`/scoring/rescore`))
      setLastStartedAt(new Date().toISOString())
      toast.success('Rescoring your accounts!')
    } catch (error: any) {
      let message = 'Failed to enqueue new scores'
      if (typeof error?.body?.error === 'string') {
        message = error.body.error
      }
      if (error?.statusCode === 422) {
        setRescoringEnabled(false)
      }
      toast.error(message)
    } finally {
      setRescoring(false)
    }
  }, [])

  const [selectedFeature, setSelectedFeature] = React.useState<string | null>(null)
  const { isOpen, onClose, onOpen } = useDisclosure()

  const selectFeature = React.useCallback(
    (feature: string) => {
      setSelectedFeature(feature)
      onOpen()
    },
    [onOpen]
  )

  const deselectFeature = React.useCallback(() => {
    setSelectedFeature(null)
    onClose()
  }, [onClose])

  const filteredCompanies = Object.keys(props.population_filter || {}).length > 0 ? (props.population?.total ?? 0) : 0
  const allCompanies = props.population_stats.all_companies ?? 0

  const [adminMode, setAdminMode] = React.useState(false)
  const showKdb = initialUniverseRef.current === 'kdb' || adminMode

  const populationOptions = React.useMemo(() => {
    const options: PopulationOpt[] = []

    if (showKdb) {
      options.push({
        title: 'All Companies',
        filtered: filteredCompanies,
        total: allCompanies,
        description: 'Includes the entire population of companies in Koala.',
        value: 'kdb'
      })
    }

    options.push(
      {
        title: 'Seen Accounts',
        total: props.population_stats.seen ?? 0,
        description: `Use for live scoring. Includes accounts that we've seen on your website across all time.`,
        value: 'seen'
      },
      {
        title: 'CRM Accounts',
        total: props.population_stats.crm ?? 0,
        description: 'Includes accounts that are already in your CRM.',
        value: 'crm'
      }
    )

    return options
  }, [showKdb, filteredCompanies, allCompanies, props.population_stats.seen, props.population_stats.crm])

  const closedWonOptions = React.useMemo(() => {
    return [
      {
        title: `${standardClosedWon} ${props.population_stats.default_closed_won >= 25 ? '(recommended)' : ''}`,
        description: `Includes all accounts from your CRM with a ${primaryCrm?.title} ${standardClosedWon}.`,
        total: props.population_stats.default_closed_won ?? 0,
        isDisabled: !props.population_stats.default_closed_won,
        value: 'default'
      },
      {
        title: 'Custom',
        description: `Find accounts in Koala that resemble your target accounts. Select this if you use a custom field for Closed Won customers or if your customer population is too small.`,
        total: props.population?.converted ?? 0,
        value: 'custom'
      }
    ]
  }, [standardClosedWon, primaryCrm, props.population_stats.default_closed_won, props.population?.converted])

  const project = useCurrentProject()

  // free plan users can't set up scoring
  const canEditScores =
    (project?.koala_subscription?.product_sku !== 'free_plan' && canEditProject) || user.isInternalUser

  return (
    <PageLayout size="full">
      {!canEditScores && (
        <HStack
          position="sticky"
          top="12px"
          fontSize="sm"
          padding={3}
          rounded="lg"
          border="1px solid"
          borderColor="orange.300"
          bg="orange.50"
          color="orange.800"
          marginBottom={6}
          zIndex="overlay"
        >
          <Icon as={IconAlertTriangle} color="orange.500" boxSize={5} />
          <Text>You don't have permission to update scoring settings. This page is in read-only mode.</Text>
        </HStack>
      )}

      <SettingsBreadCrumb offscreen paths={[{ title: 'Scoring', path: projectPath('/scoring') }]} />

      <SettingsHeader>
        <HStack w="100%" alignItems="flex-start" justifyContent="space-between" spacing={8}>
          <Box>
            <PageTitle>ICP Scoring</PageTitle>
            {(icpEnabled || adminMode) && (
              <PageDescription maxWidth="628px">
                Prioritize the best accounts active on your site. We'll help you analyze which features tend to convert
                more than others based on your closed won customers. Use your own intuition and business context to
                boost characteristics that look like your best accounts.
              </PageDescription>
            )}
            {lastSavedAt && (
              <Text fontSize="xs" marginTop={4}>
                Last updated: {dayjs(lastSavedAt).calendar()}
              </Text>
            )}
          </Box>
          {user.isInternalUser && (
            <FormControl flex="none" w="auto" size="sm" display="flex" alignItems="center" gap={2}>
              <FormLabel htmlFor="admin-mode" m="0">
                Admin Mode
              </FormLabel>
              <Switch id="admin-mode" onChange={(e) => setAdminMode(e.target.checked)} />
            </FormControl>
          )}
        </HStack>
      </SettingsHeader>

      <FeatureLockout
        locked={!icpEnabled && !adminMode}
        blockTitle="ICP Scoring is not available on your plan"
        upgradeTo="business"
        img={img}
        blockedChildren={
          <Text>
            Prioritize the best accounts showing intent. We'll help you analyze your ICP based on your closed won
            customers. Use your own intuition and business context to boost characteristics that look like your best
            accounts.
          </Text>
        }
      >
        <Stack spacing={8} paddingBottom="200px" divider={<Divider />}>
          {showKdb && (
            <HStack alignItems="flex-start">
              <StepIcon step={1} current={!universe} />
              <Stack spacing={4} maxWidth="600px">
                <Stack spacing={2}>
                  <Heading size="sm">Define your population</Heading>
                  <Text fontSize="sm" color="gray.600">
                    Your total population determines which accounts we use as a baseline to compare how unique certain
                    characteristics are amongst your converted customers.
                  </Text>
                </Stack>
                <Box maxWidth="600px">
                  <ComboboxWithSearch
                    hideSearchBar
                    items={populationOptions}
                    selectedItem={populationOptions.find((p) => p.value === universe) || null}
                    onChange={(item) => {
                      if (item?.value === 'kdb') {
                        // only support `company.*` facets for kdb
                        setFeatures((prev) => prev.filter((p) => p.startsWith('company.')))
                      }
                      setUniverse(item?.value)
                    }}
                    itemToString={(item) => item?.title || ''}
                    itemRenderer={PopulationOption}
                    selectButtonRenderer={PopulationOption}
                  />
                </Box>
                {universe === 'kdb' && (
                  <Stack spacing={4} paddingTop={6}>
                    <Stack spacing={1}>
                      <Heading size="xs" fontWeight="medium">
                        Who is eligible?
                      </Heading>
                      <Text fontSize="sm" color="gray.600">
                        What part of the population is eligible to convert? Narrow your base population to only the
                        companies that make sense for your product.
                      </Text>
                    </Stack>

                    <Stack spacing={1}>
                      <Box fontSize="sm">
                        Companies that match{' '}
                        <Select
                          size="xs"
                          display="inline-flex"
                          width="auto"
                          value={groupOperator}
                          onChange={(e) => {
                            setGroupOperator(e.target.value as 'any' | 'all')
                          }}
                        >
                          <option value="any">any</option>
                          <option value="all">all</option>
                        </Select>{' '}
                        of the following filters:
                      </Box>
                      <FilterPreview
                        {...population}
                        kind="account"
                        facetValuesPath="/companies/facet-values"
                        canClearFilters={false}
                        shouldShowICPFilters={false}
                        shouldShowIntentFilters={false}
                        shouldShow3rdPartyFilters={false}
                        shouldShowUserAttributeFilters={false}
                        shouldShowActiveVisitorsFilters={false}
                        shouldShowTerritoryFilter={false}
                        excludedKeys={['domain', 'company.name', 'geo.countryCode', 'geo.stateCode']}
                      />
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </HStack>
          )}

          {universe && (
            <HStack alignItems="flex-start">
              <StepIcon step={showKdb ? 2 : 1} current={!closedWon} />
              <Stack spacing={4} maxWidth="600px">
                <Stack spacing={2}>
                  <Heading size="sm">Define your Closed Won customers</Heading>
                  <Text fontSize="sm" color="gray.600">
                    We'll analyze your “Closed Won” customers to determine which features are statistically significant
                    and recommended to use in your model.
                  </Text>
                </Stack>
                <Box maxWidth="600px">
                  {primaryCrm ? (
                    <ComboboxWithSearch
                      hideSearchBar
                      items={closedWonOptions}
                      selectedItem={closedWonOptions.find((p) => p.value === closedWon) || null}
                      onChange={(item) => {
                        setClosedWon(item?.value)
                      }}
                      itemToString={(item) => item?.title || ''}
                      itemRenderer={PopulationOption}
                      selectButtonRenderer={PopulationOption}
                    />
                  ) : (
                    <InfoBox>
                      <Stack alignItems="flex-start" fontSize="sm">
                        <Text>Connect your CRM to Koala to select your "Closed Won" customers.</Text>
                        <HStack>
                          <IntegrationButton
                            appId={props.catalog.salesforce.clean_module_name}
                            title="Salesforce"
                            logo={props.catalog.salesforce.logo}
                          />
                          <IntegrationButton
                            appId={props.catalog.hubspot.clean_module_name}
                            title="HubSpot"
                            logo={props.catalog.hubspot.logo}
                          />
                        </HStack>
                      </Stack>
                    </InfoBox>
                  )}
                </Box>
                {closedWon === 'custom' && (
                  <FilterPreview
                    {...converted}
                    apps={props.apps}
                    kind="account"
                    facetValuesPath="/accounts/facet-values?seen=0"
                    canClearFilters={false}
                    shouldShowICPFilters={false}
                    shouldShowIntentFilters={false}
                    shouldShow3rdPartyFilters={false}
                    shouldShowUserAttributeFilters={false}
                    shouldShowActiveVisitorsFilters={false}
                    shouldShowTerritoryFilter={false}
                  />
                )}
              </Stack>
            </HStack>
          )}

          {closedWon && (
            <>
              <HStack alignItems="flex-start">
                <StepIcon step={showKdb ? 3 : 2} current={!(convertedBuckets && scores)} />
                <Stack spacing={4} maxWidth="600px" minWidth="250px">
                  <Stack spacing={1}>
                    <Heading size="sm" lineHeight={1}>
                      Build your Ideal Customer Profile (ICP)
                    </Heading>
                    <Text fontSize="sm" color="gray.600">
                      Select the company attributes that represent your ideal customers.
                    </Text>
                  </Stack>
                  <Stack width="100%" maxWidth="600px">
                    {boostedFeatures.map((feature) => (
                      <FeatureCard
                        key={feature}
                        feature={feature}
                        apps={props.apps}
                        boosts={boosts[feature]}
                        onSelect={selectFeature}
                        onRemove={(feat) => {
                          // remove all the previous values for this feature
                          setMultipliers((prev) => omitBy(prev, (_v, k) => k.startsWith(`${feat}:`)))
                          setFeatures((prev) => prev.filter((p) => p !== feat))
                        }}
                      />
                    ))}
                    {otherFeatures.map((feature) => (
                      <FeatureCard
                        key={feature}
                        feature={feature}
                        apps={props.apps}
                        onSelect={selectFeature}
                        onRemove={(feat) => {
                          // remove all the previous values for this feature
                          setMultipliers((prev) => omitBy(prev, (_v, k) => k.startsWith(`${feat}:`)))
                          setFeatures((prev) => prev.filter((p) => p !== feat))
                        }}
                      />
                    ))}
                    <FilterPopover
                      apps={pick(props.apps, ['Apps::Clearbit::App'])}
                      facetFilters={{}}
                      facetMappings={converted.facetMappings}
                      setFocusTime={noop}
                      setRange={noop}
                      setPage={noop}
                      applyFilters={noop}
                      kind="account"
                      excludedKeys={Object.keys(props.features ?? {}).concat(
                        'tld',
                        'company.name',
                        'company.domain',
                        'company.geo.countryCode',
                        'company.geo.stateCode',
                        'company.metrics.raised',
                        'company.metrics.annualRevenue',
                        'company.linkedin_url',
                        'company.description',
                        'claimed',
                        'claimed_by'
                      )}
                      shouldShowStaticListFilters={false}
                      shouldShowTraits={false}
                      shouldShowIntentFilters={false}
                      shouldShow3rdPartyFilters={false}
                      shouldShowICPFilters={false}
                      shouldShowActiveVisitorsFilters={false}
                      shouldShowUserAttributeFilters={false}
                      isOpen={featureMenu.isOpen}
                      onOpen={featureMenu.onOpen}
                      onClose={featureMenu.onClose}
                      onSelectFacet={(facet) => {
                        featureMenu.onClose()
                        setFeatures((prev) => Array.from(new Set([...prev, facet])))
                        selectFeature(facet)
                      }}
                    >
                      <Button
                        size="lg"
                        fontSize="sm"
                        paddingX={4}
                        justifyContent="flex-start"
                        variant="outline"
                        borderStyle="dashed"
                        leftIcon={<IconPlus size={16} />}
                        iconSpacing={3}
                        isDisabled={selectedFeatures.length >= 15}
                      >
                        Add scoring attributes
                      </Button>
                    </FilterPopover>
                  </Stack>
                </Stack>
              </HStack>
              {selectedFeature && (
                <FeatureModal
                  isOpen={isOpen}
                  onOpen={onOpen}
                  feature={selectedFeature}
                  boosts={boosts}
                  loading={loading}
                  stats={props.features?.[selectedFeature]}
                  onClose={deselectFeature}
                  onApply={(multipliers) => {
                    setMultipliers((prev) => ({
                      // remove all the previous values for this feature
                      ...omitBy(prev, (_v, k) => k.startsWith(`${selectedFeature}:`)),
                      ...multipliers
                    }))
                  }}
                />
              )}
            </>
          )}
          {props.population && convertedBuckets && scores && (
            <ScoreDistribution
              scores={scores}
              totalPopulation={props.population?.total}
              converted_buckets={convertedBuckets}
              sample={sampleAccounts}
              loading={calculating}
              showPopulationOptions={showKdb}
              stats={props.population_stats || {}}
            />
          )}
          <HStack>
            <Button
              size="md"
              colorScheme="purple"
              onClick={saveSettings}
              isLoading={saving}
              isDisabled={!icpEnabled || !universe || !closedWon || !canEditScores}
            >
              {props.last_updated_at ? 'Save Changes' : 'Publish'}
            </Button>
            {/* Only show if already persisted when loading the page */}
            {props.last_updated_at && (
              <Button
                size="md"
                onClick={rescore}
                isLoading={rescoring}
                isDisabled={saving || !canEditScores || !rescoringEnabled}
              >
                Rescore Accounts
              </Button>
            )}
            {Boolean(lastSavedAt) && <SyncStatus started={lastStartedAt} saved={lastSavedAt} />}
          </HStack>
        </Stack>
      </FeatureLockout>
    </PageLayout>
  )
}

interface PopulationOpt {
  title: string
  description: string
  filtered?: number
  total: number
  value: string
}

interface PopulationOptionProps {
  item: PopulationOpt | null
  isSelected?: boolean
}

function PopulationOption({ item, isSelected }: PopulationOptionProps) {
  if (!item) {
    return <Text fontSize="sm">Select a population</Text>
  }

  const { title, description, value, filtered, total } = item

  return (
    <Flex gap="2" alignItems="flex-start" padding={1}>
      <Icon
        flex="none"
        as={isSelected ? CheckboxCircleIcon : CheckboxBlankCircleIcon}
        boxSize="18px"
        color={isSelected ? `purple.600` : `gray.300`}
      />
      <Stack spacing={1} width="full">
        <HStack spacing={1} fontWeight="medium" fontSize="sm" lineHeight="18px">
          <Text textTransform="capitalize">{title}</Text>
          <Text>
            ({filtered && filtered < total ? filtered.toLocaleString() + ' / ' : ''}
            {total.toLocaleString()} {value === 'kdb' ? 'companies' : 'accounts'})
          </Text>
        </HStack>
        <Text fontSize="xs" color="gray.600">
          {description}
        </Text>
      </Stack>
    </Flex>
  )
}

interface FeatureModalProps extends Pick<UseDisclosureReturn, 'isOpen' | 'onOpen' | 'onClose'> {
  feature: string
  boosts: Boosts
  loading?: boolean
  stats?: Feature
  onApply: (multipliers: Multipliers) => void
}

function FeatureModal({ feature, boosts, stats, onApply, onClose, ...props }: FeatureModalProps) {
  const display = feature ? getItemDisplay(feature, []) : ({} as any)
  const buckets = stats?.buckets ?? []
  const [localState, setLocalState] = React.useState<Multipliers>(
    flattenBoosts({ [feature]: boosts[feature] || [] }) ?? {}
  )

  const apply = React.useCallback(() => {
    onApply(localState)
    onClose()
  }, [localState, onApply, onClose])

  const recommended = buckets.filter((b) => b.recommended)
  const selectedValues = Object.keys(localState).map((key) => key.split(':')[1])

  const applyRecommendations = React.useCallback(() => {
    const recs = flattenBoosts({ [feature]: recommended }) || {}
    setLocalState(recs)
  }, [feature, recommended])

  const summary: string[] = [pluralize('traits', buckets.length, true)]

  if (recommended.length) {
    summary.push(`${recommended.length} recommended`)
  }

  const allChecked = buckets.length > 0 && buckets.every((b) => `${feature}:${b.value}` in localState)

  return (
    <Modal size="lg" onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent width="960px" maxW="95vw">
        <ModalHeader fontWeight="medium">{display?.label}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {props.loading ? (
            <Center p="4">
              <Spinner color="gray.400" thickness="1.5px" size="sm" />
            </Center>
          ) : (
            <Stack spacing={6}>
              {!(feature && stats) ? (
                <EmptyState
                  size="sm"
                  heading="There is no data available for this feature"
                  description=""
                  icon={IconZoomExclamation}
                />
              ) : (
                <>
                  {recommended.length > 0 ? (
                    <Text maxWidth="800px">
                      There {recommended.length === 1 ? 'is' : 'are'}{' '}
                      {pluralize('recommendation', recommended.length, true)} based on a frequency test between your
                      population and converted audience. Recommendations are generated for any trait with a p95 or
                      higher confidence.
                    </Text>
                  ) : (
                    <Text maxWidth="800px">
                      There aren't any statistically significant traits based on a frequency test between your
                      population and converted audience. You may not have enough conversions &ndash; you can update your
                      definition of Closed Won, or you can still select any traits and tweak their weights that may be
                      representative of ideal customers.
                    </Text>
                  )}
                  <Text fontSize="sm" color="gray.600" maxWidth="800px">
                    Note: there may be other traits, but their frequency is too low (&lt; 1%) across your population to
                    have meaningful impact to your scoring.
                  </Text>
                  <Table flex="1 0 65%" size="sm" height="100%">
                    <Thead>
                      <Tr>
                        <Th>Feature</Th>
                        <Th width="150px" textAlign="center">
                          Boost
                        </Th>
                        <Th isNumeric>Frequency</Th>
                        <Th width="1px" isNumeric>
                          Confidence
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td width="100%" fontSize="sm">
                          <HStack
                            spacing={3}
                            minWidth="100px"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            <Checkbox
                              spacing={3}
                              isChecked={allChecked}
                              onChange={() => {
                                setLocalState(() => {
                                  if (allChecked) {
                                    return {}
                                  } else {
                                    return flattenBoosts({ [feature]: buckets }) || {}
                                  }
                                })
                              }}
                            >
                              <Text fontSize="sm">Select all</Text>
                            </Checkbox>
                            <Text whiteSpace="nowrap" color="gray.500">
                              ({summary.join(', ')})
                            </Text>
                          </HStack>
                        </Td>
                        <Td width="150px" isNumeric></Td>
                        <Td isNumeric fontSize="xs">
                          <Stack spacing={0}>
                            <Flex alignItems="center" justifyContent="flex-end" gap={2}>
                              <Text whiteSpace="nowrap">
                                {stats.population_coverage.toLocaleString()} in population
                              </Text>
                              <Progress
                                size="xs"
                                colorScheme="gray"
                                rounded="sm"
                                width="150px"
                                value={stats.population_percent}
                              />
                              <Text width="36px">{Math.round(stats.population_percent)}%</Text>
                            </Flex>
                            <Flex alignItems="center" justifyContent="flex-end" gap={2}>
                              <Text whiteSpace="nowrap">{stats.converted_coverage.toLocaleString()} in converted</Text>
                              <Progress
                                size="xs"
                                colorScheme="purple"
                                rounded="sm"
                                width="150px"
                                value={stats.converted_percent}
                              />
                              <Text width="36px">{Math.round(stats.converted_percent)}%</Text>
                            </Flex>
                          </Stack>
                        </Td>
                        <Td width="1px"></Td>
                      </Tr>
                      {buckets.map((bucket) => {
                        const key = `${feature}:${bucket.value}`
                        return (
                          <FeatureRow
                            key={key}
                            stats={bucket}
                            factor={localState[key]}
                            selected={selectedValues.includes(bucket.value)}
                            onChange={(factor) =>
                              setLocalState((prev) => ({
                                ...prev,
                                [key]: factor
                              }))
                            }
                            onToggle={() => {
                              setLocalState((prev) => {
                                const copy = { ...prev }
                                if (key in copy) {
                                  delete copy[key]
                                } else {
                                  copy[key] = bucket.factor
                                }
                                return copy
                              })
                            }}
                          />
                        )
                      })}
                    </Tbody>
                  </Table>
                </>
              )}
            </Stack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            onClick={applyRecommendations}
            isDisabled={recommended.length === 0}
            variant="outline"
            mr="auto"
          >
            Apply Recommendations
          </Button>
          <Button size="sm" onClick={onClose} variant="outline" mr={3}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="purple" onClick={apply}>
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface FeatureRowProps {
  stats: FeatureBucket
  factor?: number
  selected?: boolean
  onChange: (factor: number) => void
  onToggle: () => void
}

function FeatureRow({ stats, factor, selected, onChange, onToggle }: FeatureRowProps) {
  return (
    <Tr _hover={{ bg: 'gray.50' }} fontSize="sm">
      <Td fontFamily="mono" fontWeight="medium" borderColor="gray.200" paddingY={0}>
        <Checkbox width="100%" paddingY={4} isChecked={selected} onChange={onToggle} spacing={3} alignItems="center">
          <Text fontSize="sm">{stats.value}</Text>
        </Checkbox>
      </Td>
      <Td width="150px" isNumeric>
        <Multiple
          factor={factor || stats.factor}
          recommended={stats.recommended || selected}
          editable={selected}
          onChange={onChange}
          onClick={onToggle}
        />
      </Td>
      <Td isNumeric fontSize="xs" borderColor="gray.200">
        <Stack spacing={0}>
          <Flex alignItems="center" justifyContent="flex-end" gap={2}>
            <Text whiteSpace="nowrap">{stats.population.toLocaleString()}</Text>
            <Progress height="3px" colorScheme="gray" rounded="sm" width="150px" value={stats.population_percent} />
            <Text width="36px">{Math.round(stats.population_percent)}%</Text>
          </Flex>
          <Flex alignItems="center" justifyContent="flex-end" gap={2}>
            <Text whiteSpace="nowrap">{stats.converted.toLocaleString()}</Text>
            <Progress height="3px" colorScheme="purple" rounded="sm" width="150px" value={stats.converted_percent} />
            <Text width="36px">{Math.round(stats.converted_percent)}%</Text>
          </Flex>
        </Stack>
      </Td>
      {/* only show the confidence if they are using the recommended factor */}
      {stats.confidence !== null && (!factor || stats.factor === factor) ? (
        <Td width="1px" isNumeric borderColor="gray.200">
          {parseFloat((100 * stats.confidence).toFixed(1))}%
        </Td>
      ) : (
        <Td width="1px" isNumeric color="gray.400" borderColor="gray.200">
          &ndash;
        </Td>
      )}
    </Tr>
  )
}

interface ScoreDistributionProps {
  loading?: boolean
  showPopulationOptions?: boolean
  converted_buckets: Array<{
    percentile: string
    score: number
    grade: string
    doc_count: number
  }>
  sample: Props['sample_accounts']
  scores: {
    [key: string]: number
  }
  totalPopulation: number
  stats?: Props['population_stats']
}

function ScoreDistribution(props: ScoreDistributionProps) {
  const theme = useTheme()
  const debug = useDisclosure()
  const rawScores = Object.values(props.scores)
  const max = Math.max(...rawScores)
  const min = Math.min(...rawScores)

  const sum = sumBy(props.converted_buckets, 'doc_count')
  const byGrade = groupBy(props.converted_buckets, 'grade')
  const avgConversion = sum / props.totalPopulation

  const grades = keyBy(
    Object.entries(byGrade).map(([grade, buckets]) => {
      const count = sumBy(buckets, 'doc_count')
      // TODO this will have to change to be dynamic if we allow custom fit label buckets (that aren't evenly distributed)
      const conv = count / (props.totalPopulation * 0.25)
      const lift = Math.round((10 * conv) / avgConversion) / 10
      return { grade, count, lift, percent: `${Math.round((100 * count) / sum)}%` }
    }),
    'grade'
  )

  if (props.loading) {
    return (
      <HStack alignItems="flex-start">
        <StepIcon step={props.showPopulationOptions ? 4 : 3} current={Boolean(max)} />
        <Stack spacing={4}>
          <Heading size="sm">Review: Scores for Closed Won Accounts</Heading>
          <Center p="4">
            <Spinner color="gray.400" thickness="1.5px" size="sm" />
          </Center>
        </Stack>
      </HStack>
    )
  }

  const total = props.stats?.total_converted || sum
  // const excluded = props.stats?.in_population_and_exclusions ?? 0
  const subset = props.stats?.in_population ?? 0

  return (
    <HStack alignItems="flex-start">
      <StepIcon step={props.showPopulationOptions ? 4 : 3} current={Boolean(max)} />
      <Stack spacing={4} minWidth="250px">
        <Stack spacing={2}>
          <Heading size="sm">Review: Scores for Closed Won Accounts</Heading>
          <Text fontSize="sm" color="gray.600">
            This chart shows the distribution of scores for the subset of accounts you designated as Closed Won.
            Generally, you want your converted accounts to be more concentrated in the A-B range (top 50% of accounts)
            and fewer in the C-D range (bottom 50% of accounts).
          </Text>
        </Stack>

        <Stack spacing={4}>
          <Box
            display={['flex', 'flex', 'grid']}
            gridTemplateColumns="repeat(auto-fit, minmax(210px, 1fr))"
            flexDirection={['column', 'column', 'row']}
            gap={3}
            fontSize="sm"
            fontWeight="medium"
            css={{ fontVariantNumeric: 'tabular-nums' }}
          >
            <LightBgCard p={3} gap={4}>
              <HStack spacing={2}>
                <Text fontWeight="bold" color="blue.400">
                  Grade A
                </Text>
                <Text>{grades.A?.count?.toLocaleString() || 0}</Text>
                <Text color="gray.500">({grades.A?.percent || '0%'})</Text>
                <StatChange value={grades.A?.lift} />
              </HStack>
              <SampleAccounts accounts={props.sample['A']} />
            </LightBgCard>
            <LightBgCard p={3} gap={4}>
              <HStack spacing={2}>
                <Text fontWeight="bold" color="purple.500">
                  Grade B
                </Text>
                <Text>{grades.B?.count?.toLocaleString() || 0}</Text>
                <Text color="gray.500">({grades.B?.percent || '0%'})</Text>
                <StatChange value={grades.B?.lift} />
              </HStack>
              <SampleAccounts accounts={props.sample['B']} />
            </LightBgCard>
            <LightBgCard p={3} gap={4}>
              <HStack spacing={2}>
                <Text fontWeight="bold" color="orange.400">
                  Grade C
                </Text>
                <Text>{grades.C?.count?.toLocaleString() || 0}</Text>
                <Text color="gray.500">({grades.C?.percent || '0%'})</Text>
                <StatChange value={grades.C?.lift} />
              </HStack>
              <SampleAccounts accounts={props.sample['C']} />
            </LightBgCard>
            <LightBgCard p={3} gap={4}>
              <HStack spacing={2}>
                <Text fontWeight="bold" color="yellow.400">
                  Grade D
                </Text>
                <Text>{grades.D?.count?.toLocaleString() || 0}</Text>
                <Text color="gray.500">({grades.D?.percent || '0%'})</Text>
                <StatChange value={grades.D?.lift} />
              </HStack>
              {props.sample['D'] ? (
                <SampleAccounts accounts={props.sample['D']} />
              ) : (
                <Text fontSize="sm" color="gray.500">
                  No accounts
                </Text>
              )}
            </LightBgCard>
          </Box>

          <LightBgCard paddingTop={8} paddingX={3}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={500}
                height={300}
                data={props.converted_buckets}
                margin={{
                  top: 15,
                  right: 40,
                  left: 8,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="4 4" horizontal={false} stroke={theme.colors.gray['200']} />
                <XAxis
                  dataKey="percentile"
                  domain={[min, max]}
                  reversed
                  minTickGap={30}
                  tickMargin={10}
                  interval="preserveStartEnd"
                  stroke={theme.colors.gray['200']}
                  tickFormatter={(p) => String(Math.round(props.scores[p] * 1000) / 1000)}
                  tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
                />
                <YAxis
                  width={32}
                  dataKey="doc_count"
                  domain={['dataMin', 'dataMax']}
                  tickLine={false}
                  axisLine={false}
                  allowDecimals={false}
                  interval="preserveStartEnd"
                  tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
                />
                <Bar dataKey="doc_count" fill={theme.colors.purple['500']} />
                {/* These reference lines will need to change if we make fit labels customizable */}
                <ReferenceLine
                  x="1.0"
                  stroke="black"
                  strokeDasharray="3 3"
                  label={{ position: 'top', value: 'D', fill: 'black', fontSize: 14 }}
                />
                <ReferenceLine
                  x="25.0"
                  stroke="black"
                  strokeDasharray="3 3"
                  label={{ position: 'top', value: 'C', fill: 'black', fontSize: 14 }}
                />
                <ReferenceLine
                  x="50.0"
                  stroke="black"
                  strokeDasharray="3 3"
                  label={{ position: 'top', value: 'B', fill: 'black', fontSize: 14 }}
                />
                <ReferenceLine
                  x="75.0"
                  stroke="black"
                  strokeDasharray="3 3"
                  label={{ position: 'top', value: 'A', fill: 'black', fontSize: 14 }}
                />
              </BarChart>
            </ResponsiveContainer>

            <Divider />

            <Box>
              <HStack
                justifyContent="space-between"
                paddingTop={3}
                paddingX={2}
                onClick={debug.onToggle}
                cursor="pointer"
              >
                <Heading size="xs" fontWeight="medium">
                  Additional details
                </Heading>
                <IconButton
                  aria-label="Toggle debug"
                  size="xs"
                  variant="ghost"
                  icon={debug.isOpen ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
                />
              </HStack>
              <Collapse in={debug.isOpen} unmountOnExit>
                <Stack paddingTop={3} paddingX={3}>
                  <Text fontSize="sm">
                    We are showing scores for{' '}
                    <b>{sum === total ? `all ${total}` : `${sum.toLocaleString()} of ${total.toLocaleString()}`}</b>{' '}
                    Closed won accounts:
                  </Text>
                  <UnorderedList paddingLeft={4}>
                    {subset < total && (
                      <>
                        <ListItem fontSize="sm">
                          <b>{(total - subset).toLocaleString()}</b> were omitted by your base population (or they don't
                          have enough data to be scored)
                        </ListItem>
                      </>
                    )}
                    {/* {excluded && (
                      <ListItem fontSize="sm">
                        <b>{excluded.toLocaleString()}</b> were excluded by your{' '}
                        <Link href={projectPath(`settings/excluded-accounts`)} isExternal color="purple.500">
                          global exclusion filters
                        </Link>
                      </ListItem>
                    )} */}
                  </UnorderedList>
                </Stack>
              </Collapse>
            </Box>
          </LightBgCard>
        </Stack>
      </Stack>
    </HStack>
  )
}

interface MultipleProps {
  factor: number
  recommended?: boolean
  editable?: boolean
  onChange?: (value: number) => void
  onClick?: () => void
}

function Multiple(props: MultipleProps) {
  const [factor, setFactor] = React.useState<number | string>(props.factor)

  React.useEffect(() => {
    setFactor(props.factor)
  }, [props.factor])

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    onChange: (v) => {
      const value = v === '' ? '' : Number(v)
      setFactor(value)
      props.onChange?.(value || 1)
    },
    clampValueOnBlur: true,
    onBlur: (e: any) => {
      if (e.target.value === '') {
        setFactor(1)
        props.onChange?.(1)
      }
    },
    format: (v) => (v === '' ? v : parseFloat(String(v)) + 'x'),
    step: 0.25,
    value: factor,
    min: 0.25,
    max: 100,
    precision: 2
  })

  return (
    <HStack
      spacing={1}
      border={props.editable ? '1px solid' : undefined}
      borderColor="gray.200"
      rounded="lg"
      justifyContent="center"
      onClick={!props.editable ? props.onClick : undefined}
      cursor={!props.editable && props.onClick ? 'pointer' : undefined}
    >
      {props.editable && (
        <IconButton
          variant="ghost"
          size="xs"
          aria-label="Decrease"
          {...getDecrementButtonProps()}
          icon={<Icon as={IconMinus} boxSize={3} color="gray.500" />}
        />
      )}
      <Input
        readOnly={!props.editable}
        size="sm"
        width="50px"
        variant="unstyled"
        flexShrink={0}
        color={
          props.factor > 1
            ? props.recommended
              ? 'green.400'
              : 'gray.400'
            : props.factor < 1 && props.recommended
              ? 'red.400'
              : 'gray.400'
        }
        fontWeight="semibold"
        fontFamily="mono"
        letterSpacing="tighter"
        textAlign="center"
        fontSize="sm"
        {...getInputProps()}
      />
      {props.editable && (
        <IconButton
          variant="ghost"
          size="xs"
          aria-label="Increase"
          {...getIncrementButtonProps()}
          icon={<Icon as={IconPlus} boxSize={3} color="gray.500" />}
        />
      )}
    </HStack>
  )
}

type StatChangeProps = React.PropsWithChildren<{ value: number }>

function StatChange(props: StatChangeProps) {
  return (
    <Badge
      display="inline-flex"
      gap={1}
      textTransform="none"
      fontWeight="medium"
      alignItems="center"
      colorScheme={props.value >= 1 ? 'green' : 'red'}
      variant="subtle"
    >
      <Icon as={props.value >= 1 ? IconTrendingUp : IconTrendingDown} boxSize={3} /> {props.value}x
    </Badge>
  )
}

interface SampleAccountsProps {
  accounts?: SampleAccount[]
}

function SampleAccounts(props: SampleAccountsProps) {
  return (
    <AvatarGroup size="sm" max={5} spacing={-1}>
      {props.accounts?.map((sample) => (
        <TooltipAvatar
          key={sample.id}
          src={sample.company.logo}
          name={sample.company.name || sample.company.domain}
          backgroundColor="white"
          shadow="base"
          boxSizing="content-box"
          borderWidth="2px"
        />
      ))}
    </AvatarGroup>
  )
}

function TooltipAvatar(props: any) {
  return (
    <Tooltip label={props.name}>
      <Avatar {...props} />
    </Tooltip>
  )
}

interface FeatureCardProps {
  feature: string
  apps: Apps
  boosts?: Boost[]
  onSelect: (feature: string) => void
  onRemove: (feature: string) => void
}

function FeatureCard(props: FeatureCardProps) {
  const display = getItemDisplay(props.feature, Object.values(props.apps))
  const boosted = (props.boosts ?? []).map((b) => b.value).join(', ')
  return (
    <LightBgCard
      key={props.feature}
      padding={3}
      cursor="pointer"
      transition="all 150ms cubic-bezier(0, 0, 0.2, 1)"
      _hover={{ transform: 'translateY(-1px)', shadow: 'md' }}
      onClick={() => props.onSelect(props.feature)}
    >
      <HStack justifyContent="space-between" spacing={4}>
        <HStack flex="1" minW="150px">
          <Square padding={1}>
            {typeof display.icon === 'string' ? (
              <Image src={display.icon} boxSize={5} />
            ) : (
              <Icon as={display.icon || IconPlus} boxSize={5} color="purple.500" />
            )}
          </Square>
          <Text fontSize="sm" fontWeight="medium">
            {display.label}
          </Text>
          {boosted && (
            <Text
              flexShrink={1}
              minWidth="100px"
              fontSize="sm"
              color="gray.500"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              &ndash;&nbsp;&nbsp;{boosted}
            </Text>
          )}
        </HStack>
        <HStack>
          <Button size="xs" variant="outline">
            {boosted ? 'Change' : 'Add'}
          </Button>

          <IconButton
            aria-label="Remove"
            size="xs"
            variant="outline"
            icon={<IconX size={14} />}
            onClick={(e) => {
              e.stopPropagation()
              props.onRemove(props.feature)
            }}
          />
        </HStack>
      </HStack>
    </LightBgCard>
  )
}

interface IntegrationButtonProps extends ButtonProps {
  title: string
  logo: string
  appId: string
}

function IntegrationButton({ title, logo, appId, ...rest }: IntegrationButtonProps) {
  const project = useCurrentProject()

  if (!project) {
    return null
  }

  const query = new URLSearchParams({
    project: project.slug,
    app_id: appId,
    origin: window.location.href
  })

  return (
    <form method="POST" action={`/auth/${appId}?${query.toString()}`}>
      <AuthenticityToken />
      <Button
        type="submit"
        variant="outline"
        size="sm"
        justifyContent="space-between"
        marginLeft="auto"
        width="full"
        flex="1 1 100%"
        fontWeight="medium"
        bg="white"
        leftIcon={<Image src={logo} boxSize={4} />}
        rightIcon={<Icon as={IconArrowRight} boxSize={4} color="gray.500" />}
        {...rest}
      >
        Connect {title}
      </Button>
    </form>
  )
}
