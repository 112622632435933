import React, { useCallback } from 'react'
import { Box, Stack, Text, Flex, FormControl, FormLabel, Checkbox, IconButton } from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import { PlayRoutingRule, PlayRoutingConditionType } from '../../../../../types/Play'
import { Territory } from '../../../../../types/Territory'
import { User } from '../../../../data/use-users'
import { UserSelector } from '../../../../ui/UserSelector'
import { UsersAvatarGroup } from '../../../../ui/UsersAvatarGroup'

interface StrategyConfigurationProps {
  rule: PlayRoutingRule
  strategy: PlayRoutingConditionType
  territories: Territory[] | undefined
  users: User[]
  fieldToSubmit: string
  setRule: (rule: PlayRoutingRule) => void
  isValid: boolean
}

export const StrategyConfiguration: React.FC<StrategyConfigurationProps> = ({
  rule,
  strategy,
  territories,
  users,
  fieldToSubmit,
  setRule,
  isValid
}) => {
  const handleTerritorySelect = useCallback(
    (territoryId: string) => {
      setRule({
        ...rule,
        territories: rule.territories?.includes(territoryId)
          ? rule.territories?.filter((id) => id !== territoryId)
          : [...(rule.territories || []), territoryId]
      })
    },
    [rule, setRule]
  )

  const handleAssigneeChange = useCallback(
    (index: number, userId: string | null) => {
      const newUserIds = [...(rule.assignees || [])]
      if (userId) {
        newUserIds[index] = userId
      } else {
        newUserIds.splice(index, 1)
      }
      setRule({ ...rule, assignees: newUserIds })
    },
    [rule, setRule]
  )

  const handleRemoveAssignee = useCallback(
    (index: number) => {
      const newUserIds = [...(rule.assignees || [])]
      newUserIds.splice(index, 1)
      setRule({ ...rule, assignees: newUserIds })
    },
    [rule, setRule]
  )

  // TODO implement crm owner field selector
  if (strategy === 'crm_owner') return null

  if (strategy === 'territory') {
    return (
      <Box>
        <Stack>
          <Text fontSize="sm" fontWeight="medium">
            Select the territories you want to use.
          </Text>
          {territories?.map((territory) => (
            <TerritoryOption
              key={territory.id}
              territory={territory}
              isSelected={rule.territories?.includes(territory.id)}
              onSelect={() => handleTerritorySelect(territory.id)}
            />
          ))}
        </Stack>
        {rule.territories?.map((id) => (
          <input type="hidden" key={id} name={`${fieldToSubmit}[territories][]`} value={id} />
        ))}
        {!isValid && (
          <Text color="orange.500" fontSize="sm" mt={2}>
            Please select at least one territory for this rule.
          </Text>
        )}
      </Box>
    )
  }

  if (strategy === 'round_robin') {
    return (
      <>
        <RoundRobinConfiguration
          rule={rule}
          users={users}
          fieldToSubmit={fieldToSubmit}
          onAssigneeChange={handleAssigneeChange}
          onRemoveAssignee={handleRemoveAssignee}
        />
        {!isValid && (
          <Text color="orange.500" fontSize="sm" mt={2}>
            Please select at least one user for this rule.
          </Text>
        )}
      </>
    )
  }

  if (strategy === 'specific_person') {
    return (
      <>
        <SpecificPersonConfiguration rule={rule} users={users} fieldToSubmit={fieldToSubmit} setRule={setRule} />

        {!isValid && (
          <Text color="orange.500" fontSize="sm" mt={2}>
            Please select one user for this rule.
          </Text>
        )}
      </>
    )
  }

  return null
}

// Sub-components
const TerritoryOption: React.FC<{
  territory: Territory
  isSelected: boolean
  onSelect: () => void
}> = ({ territory, isSelected, onSelect }) => (
  <Flex alignItems="center" rounded="lg" border="1px solid" borderColor="gray.200" bg="white" paddingRight={3}>
    <Checkbox flex="1 1 auto" padding={3} gap={2} isRequired={false} isChecked={isSelected} onChange={onSelect}>
      <Text fontSize="sm" fontWeight="medium">
        {territory.name}
      </Text>
    </Checkbox>
    <UsersAvatarGroup users={territory.assignees} />
  </Flex>
)

const RoundRobinConfiguration: React.FC<{
  rule: PlayRoutingRule
  users: User[]
  fieldToSubmit: string
  onAssigneeChange: (index: number, userId: string | null) => void
  onRemoveAssignee: (index: number) => void
}> = ({ rule, users, fieldToSubmit, onAssigneeChange, onRemoveAssignee }) => (
  <FormControl isRequired>
    <FormLabel>Selected users</FormLabel>
    <Stack spacing={2}>
      {[...(rule.assignees || []), ''].map((assigneeId, idx) => (
        <Flex width="100%" key={`${assigneeId}:${idx}`} alignItems="center" gap={3}>
          <Box flex="1 1 auto">
            <UserSelector
              users={users}
              selectedUserId={assigneeId}
              onChange={(newAssigneeId) => onAssigneeChange(idx, newAssigneeId || null)}
              popperOptions={{ matchWidth: true }}
              variant="outline"
            />
          </Box>
          <IconButton
            aria-label="Remove user"
            size="xs"
            variant="ghost"
            color="gray.500"
            _hover={{ color: 'gray.800' }}
            icon={<IconX size={16} />}
            isDisabled={idx >= (rule.assignees || []).length}
            onClick={() => onRemoveAssignee(idx)}
          />
        </Flex>
      ))}
    </Stack>
    {(rule.assignees || []).map((id) => (
      <input type="hidden" key={id} name={`${fieldToSubmit}[assignees][]`} value={id} />
    ))}
  </FormControl>
)

const SpecificPersonConfiguration: React.FC<{
  rule: PlayRoutingRule
  users: User[]
  fieldToSubmit: string
  setRule: (rule: PlayRoutingRule) => void
}> = ({ rule, users, fieldToSubmit, setRule }) => (
  <FormControl isRequired>
    <FormLabel>Select a person</FormLabel>
    <UserSelector
      users={users}
      selectedUserId={rule.assignees?.[0]}
      onChange={(newAssigneeId) => setRule({ ...rule, assignees: [newAssigneeId || ''] })}
      popperOptions={{ matchWidth: true }}
      variant="outline"
    />
    <input type="hidden" name={`${fieldToSubmit}[assignees][]`} value={rule.assignees?.[0] || ''} />
  </FormControl>
)
